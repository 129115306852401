import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { ActionSheet, useActionSheet } from '../components/ActionSheet';
import { CustomerScanOverlayTapNGo } from '../components/CustomerScanOverlayTapNGo';
import { CustomerScanOverlayUserBalances } from '../components/CustomerScanOverlayUserBalances';
import ScanPage from '../components/ScanPage';
import { getCustomerTags } from '../microservices/legacy-app';
import { routes, withParams } from '../shared/router';
import { showInfo } from '../shared/toasts';
import { parse } from 'query-string';
import { useApiQuery } from '@orderx/http';
import { useHistory } from 'react-router-dom';

const Wrapper = styled.div``;

const TagScanType = {
    UserBalances: 'USER_BALANCES',
    TapNGo: 'TAP_N_GO',
    Menu: 'MENU',
};

export function CustomerScan() {
    const history = useHistory();
    const [queryParamTag] = useState(parse(window.location.search).tag || '');
    const [tagScan, setTagScan] = useState();
    const [tagParams, setTagParams] = useState();
    const actionSheet = useActionSheet();

    const { run: loadTag, response: tagAction, isLoading } = useApiQuery(getCustomerTags);

    useEffect(() => {
        if (tagParams) {
            loadTag(tagParams);
        }
    }, [tagParams]);

    useEffect(() => {
        if (queryParamTag) {
            setTagParams({ tagValue: queryParamTag });
        }
    }, [queryParamTag]);

    useEffect(() => {
        if (tagAction) {
            switch (tagAction.type) {
                case TagScanType.TapNGo:
                case TagScanType.UserBalances:
                    setTagScan(tagAction);
                    actionSheet.open();
                    break;
                case TagScanType.Menu:
                    history.push(withParams(routes.menu, { barId: tagAction.bar.id }));
                    break;
                default:
                    showInfo('Nothing interesting found');
                    break;
            }
        }
    }, [tagAction]);

    async function onTagRead(tag, type) {
        setTagParams({ tagValue: tag, tagType: type });
    }

    return (
        <>
            <ScanPage isLoading={isLoading} onTagRead={onTagRead} />

            {actionSheet.isOpen && (
                <ActionSheet {...actionSheet} cover>
                    {tagScan.type === TagScanType.UserBalances && (
                        <CustomerScanOverlayUserBalances
                            userBalances={tagScan.userBalances}
                            actionSheet={actionSheet}
                        />
                    )}

                    {tagScan.type === TagScanType.TapNGo && (
                        <CustomerScanOverlayTapNGo
                            createdOrder={tagScan.order}
                            products={tagScan.products}
                            tagValue={tagParams && tagParams.tagValue}
                            tagType={tagParams && tagParams.tagType}
                            actionSheet={actionSheet}
                        />
                    )}
                </ActionSheet>
            )}
        </>
    );
}
