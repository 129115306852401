import MomentUtils from '@date-io/moment';
import { MuiThemeProvider } from '@material-ui/core/styles';
import { MuiPickersUtilsProvider } from 'material-ui-pickers';
import React, { useEffect } from 'react';
import { ToastContainer } from 'react-toastify';
import styled from 'styled-components';
import { createAsyncComponent } from '../AsyncComponent';
import { NfcScanEffect } from '../NfcScanEffect';
import { OrdersPlaced } from '../OrdersPlaced';
import { PushNotifications } from '../PushNotifications';
import { SwitchRedirect } from '../SwitchRedirect';
import { System } from '../system/System';
import { SystemSnackbarProvider } from '../system/SystemSnackbarProvider';
import { getUrlToAsset } from '../../shared/assets';
import { authenticate, refresh } from '../../shared/aws-amplify';
import { useOnWindowFocus } from '../../shared/browser';
import { sync } from '../../shared/code-push';
import { useNfc } from '../../shared/nfc';
import { routes } from '../../shared/router';
import { useConnectivity } from '../../stores/connectivity';
import { useSubscribeToMediaQueryChanges } from '../../stores/media';
import { materialDesignTheme } from '../../themes/material-design';
import { Demo } from '../../pages/Demo';
import { useUser } from '../../stores/user';
import { Authenticated } from '../../pages/Authenticated';
import { PosPage } from '../../@v2/pages/pos/PosPage';
import { useApiJwtToken } from '../../stores/api-jwt-token';
import { Api } from '@orderx/http';
import { OrderIdPage } from '../../@v2/pages/order/id/OrderIdPage';
import { TagPage } from '../../pages/TagPage';
import { UiExample } from '@orderx/ui';
import './App.scss';
import { useDeviceFingerprint } from '../../@v2/hooks/device-fingerprint';
import { useOrdersPlaced } from '../../stores/order-placed';
import { useTermsAndConditions } from '../../@v2/hooks/terms-and-conditions';
import { ZonePage } from '../../@v2/pages/zone/ZonePage';
import { V2OrderIdPage } from '../../@v2/pages/v2-order-id/V2OrderIdPage';
import { ResetDiscountPage } from '../../@v2/pages/reset-discount/ResetDiscountPage';
import { BamboraCallbackV2Page } from '../../pages/bambora-callback-v2/BamboraCallbackV2Page';

const AsyncOrderGroup = createAsyncComponent(() => import('../../pages/order-group/OrderGroupDataResolver'));
const AsyncAuth = createAsyncComponent(() => import('../../pages/Auth'));
const AsyncRoskildeApp = createAsyncComponent(() => import('../../pages/RoskildeApp'));

const Wrapper = styled.div`
    @font-face {
        font-family: 'Texta';
        font-weight: 300;
        src: url('${getUrlToAsset('/assets/texta-light.woff2')}') format('woff');
    }

    @font-face {
        font-family: 'Texta';
        font-style: normal;
        font-weight: 400;
        src: url('${getUrlToAsset('/assets/texta-regular.woff2')}') format('woff');
    }

    @font-face {
        font-family: 'Texta';
        font-style: normal;
        font-weight: 700;
        src: url('${getUrlToAsset('/assets/texta-bold.woff2')}') format('woff');
    }
`;

const routesShared = [
    {
        path: routes.orderGroup,
        component: AsyncOrderGroup,
    },
    {
        path: BamboraCallbackV2Page.route,
        component: BamboraCallbackV2Page,
    },
    {
        path: TagPage.route,
        component: TagPage,
    },
    {
        path: '/roskilde',
        component: AsyncRoskildeApp,
    },
    {
        path: '/demo',
        component: Demo,
    },
    {
        path: PosPage.route,
        component: PosPage,
    },
    {
        path: V2OrderIdPage.route,
        component: V2OrderIdPage,
    },
    {
        path: OrderIdPage.route,
        component: OrderIdPage,
    },
    {
        path: '/dev',
        component: UiExample,
    },
    {
        path: ZonePage.route,
        component: ZonePage,
    },
    {
        path: ResetDiscountPage.route,
        component: ResetDiscountPage,
    },
    {
        path: '/',
        component: Authenticated,
    },
];

const routesAuthenticated = [...routesShared];

const routesNotAuthenticated = [
    {
        path: routes.auth,
        component: AsyncAuth,
    },
    ...routesShared,
];

export default function App() {
    const { isAuthenticated } = useUser();
    const [token] = useApiJwtToken();

    useTermsAndConditions().useLoadTerms();
    useConnectivity();
    useSubscribeToMediaQueryChanges();

    useEffect(() => {
        refresh();
        bootstrap();
    }, []);

    useEffect(() => {
        Api.setAuthorization(token);
    }, [token]);

    useOnWindowFocus(async () => {
        sync();
        refresh();
    });

    useNfc();

    useDeviceFingerprint().useRegister();
    useOrdersPlaced().useAutoSync();

    async function bootstrap() {
        try {
            await authenticate();
        } catch (error) {
            console.error(error);
        }
    }

    return (
        <MuiPickersUtilsProvider utils={MomentUtils}>
            <MuiThemeProvider theme={materialDesignTheme}>
                <SystemSnackbarProvider>
                    <Wrapper className="App">
                        <System />
                        {/*<OrderActiveToolbar />*/}
                        <OrdersPlaced />
                        <PushNotifications />
                        <NfcScanEffect />
                        <ToastContainer />

                        <SwitchRedirect routes={isAuthenticated ? routesAuthenticated : routesNotAuthenticated} />
                    </Wrapper>
                </SystemSnackbarProvider>
            </MuiThemeProvider>
        </MuiPickersUtilsProvider>
    );
}
