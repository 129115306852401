import React from 'react';
import { AppCenterContainer } from '../../providers/app-center';
import { AudioContainer } from '../../providers/audio';
import { AwsIotContainer } from '../../providers/aws-iot';
import { CodePushContainer } from '../../providers/code-push';
import SystemRouteChange from './SystemRouteChange';
import { SystemBookX } from './SystemBookX';

export function System() {
    return (
        <>
            <CodePushContainer />
            <AppCenterContainer />
            <AwsIotContainer />
            <AudioContainer />
            <SystemRouteChange />
            <SystemBookX />
        </>
    );
}
