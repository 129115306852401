import clsx from 'clsx';
import React, { useEffect, useState, useMemo } from 'react';
import { useDeliveryLocations } from '../../hooks/delivery/locations';
import { UiGrid, UiText, UiButton, UiButtonCheckbox, UiInput, UiLoader, UiToolbar } from '@orderx/ui';
import { useStateStorage } from '../../use/state-storage';
import without from 'lodash/without';
import { CustomerProfilePage } from '../CustomerProfilePage';
import { compareStrings } from '../../util/compare-strings';
import { Switch, Redirect, Route } from 'react-router-dom';
import { DeliveryStationPage } from './station/DeliveryStationPage';
import { useDeliveryView } from '../../hooks/delivery/view';
import { useOrderBubble } from '../../@v2/hooks/order-bubble';
import './DeliveryPage.scss';
import { ToolbarTitle } from '../../@v2/components/toolbar/title/ToolbarTitle';

export function DeliveryPage() {
    useOrderBubble().useTemporaryHide();

    const { load, venues, barsSelected } = useDeliveryLocations();
    const { bars, setBars } = useDeliveryView();

    useEffect(() => {
        setBars(barsSelected);
    }, [barsSelected]);

    useEffect(() => {
        load();
    }, []);

    if (venues.length === 0) {
        return (
            <UiToolbar>
                <ToolbarTitle title="Loading venues..." buttonBackLink={CustomerProfilePage.route} />
            </UiToolbar>
        );
    }

    return (
        <Switch>
            <Route path={DeliveryPage.route} exact>
                <Index />
            </Route>

            <Route path={DeliveryStationPage.route}>{bars.length > 0 ? <DeliveryStationPage /> : <UiLoader />}</Route>

            <Redirect to={DeliveryPage.route} />
        </Switch>
    );
}

DeliveryPage.route = '/delivery';

function Index() {
    const [selectedBarIds, setSelectedBarIds] = useStateStorage('selectedBarIds', []);
    const [keyword, setKeyword] = useState('');
    const { venues, selectBarIds } = useDeliveryLocations();
    const [isSelected, setIsSelected] = useState(false);

    const venuesFiltered = useMemo(() => {
        return venues
            .map((venue) => {
                if (!keyword || compareStrings(venue.name, keyword)) {
                    return venue;
                }

                return {
                    ...venue,
                    bars: venue.bars.filter((bar) => !keyword || compareStrings(bar.name, keyword)),
                };
            })
            .filter((venue) => venue.bars.length > 0);
    }, [venues, keyword]);

    if (isSelected) {
        return <Redirect to={DeliveryStationPage.route} />;
    }

    return (
        <>
            <UiToolbar>
                <ToolbarTitle title="Select bars" buttonBackLink={CustomerProfilePage.route} />
            </UiToolbar>

            <UiGrid outerSpacing={2} className={clsx('DeliveryPage')}>
                <UiInput value={keyword} onChangeValue={setKeyword} size={1} placeholder="Type to filter" />

                {selectedBarIds.length > 0 && (
                    <UiButton onClick={() => setSelectedBarIds([])} variant="filled">
                        Deselect all
                    </UiButton>
                )}

                {venuesFiltered.map((venue) => (
                    <UiGrid key={venue.id}>
                        <UiText transform="uppercase" weight={2}>
                            {venue.name}
                        </UiText>

                        <div className="bars">
                            {venue.bars.map((bar) => (
                                <UiButtonCheckbox
                                    value={selectedBarIds.includes(bar.id)}
                                    onChange={() => {
                                        if (selectedBarIds.includes(bar.id)) {
                                            setSelectedBarIds(without(selectedBarIds, bar.id));
                                        } else {
                                            setSelectedBarIds([...selectedBarIds, bar.id]);
                                        }
                                    }}
                                    color={selectedBarIds.includes(bar.id) ? 'secondary' : 'default'}
                                    variant="filled"
                                    key={bar.id}>
                                    {bar.name}
                                </UiButtonCheckbox>
                            ))}
                        </div>
                    </UiGrid>
                ))}
            </UiGrid>

            {selectedBarIds.length > 0 && (
                <UiToolbar position="bottom">
                    <UiButton
                        size={2}
                        variant="filled"
                        color="primary"
                        fullWidth
                        onClick={() => {
                            selectBarIds(selectedBarIds);
                            setIsSelected(true);
                        }}>
                        Continue (Bars selected: {selectedBarIds.length})
                    </UiButton>
                </UiToolbar>
            )}
        </>
    );
}
