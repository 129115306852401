import { Entity, Column, ManyToOne, PrimaryColumn } from './typeorm';
import { InventoryProduct } from './inventory-product';
import { InventoryMenu } from './inventory-menu';

@Entity()
export class InventoryMenuProduct {
    @ManyToOne(() => InventoryMenu, (model) => model.menuProducts)
    menu: InventoryMenu;
    @PrimaryColumn()
    menuId: number;

    @ManyToOne(() => InventoryProduct, (model) => model.menuProducts)
    product: InventoryProduct;
    @PrimaryColumn()
    productId: number;

    @Column({ nullable: true })
    price: number;

    @Column({ default: 0 })
    position: number;
}
