import clsx from 'clsx';
import React from 'react';
import { useApiQuery } from '@orderx/http';

export function V2BrowsePage() {
    const {} = useApiQuery(async () => {});

    return <div className={clsx('V2BrowsePage')}>V2BrowsePage</div>;
}

V2BrowsePage.route = '/v2/browse';
