import React from 'react';
import styled, { css } from 'styled-components';
import classnames from 'classnames';

const Wrapper = styled.div`
    //background-color: ${props => props.theme.colors.background};
    display: flex;
    flex-flow: ${({ vertical }) => (vertical ? 'column' : 'row')};
    position: ${({ fixed, bottom }) => (fixed || bottom ? 'fixed' : 'relative')};
    bottom: ${({ fixed, bottom }) => (fixed || bottom ? '0px' : 'auto')};
    padding: ${({ fixed, bottom }) => (fixed || bottom ? '16px 16px 16px 16px' : 0)};
    width: ${({ fixed, bottom }) => (fixed || bottom ? '100%' : 'auto')};
    left: 0;

    > *:not(:first-child) {
        margin-top: ${({ vertical }) => (vertical ? 16 : 0)}px;
        margin-left: ${({ vertical }) => (vertical ? 0 : 16)}px;
    }

    ${props =>
        props.fixed &&
        css`
            -webkit-backface-visibility: hidden;
            backface-visibility: hidden;
        `};
`;

export function ButtonGroup({ vertical, fixed, bottom, className, children }) {
    return (
        <Wrapper className={classnames('ButtonGroup', className)} vertical={vertical} fixed={fixed} bottom={bottom}>
            {children}
        </Wrapper>
    );
}
