import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';

export function SwitchRedirect({ routes }) {
    return (
        <Switch>
            {routes.map(route => (
                <Route key={route.path} {...route} />
            ))}

            <Redirect to={routes[0].path} />
        </Switch>
    );
}
