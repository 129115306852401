import React from 'react';
import { UiButton, UiFlex } from '@orderx/ui';
import { RouterSwitch } from '../router/switch/RouterSwitch';
import './NavTab.scss';
import { useRouter } from '../../hooks/router';

interface Props {
    routes?: any;
    redirect?: any;
}

export function NavTab({ routes, redirect }: Props) {
    const { replaceParams } = useRouter();

    return (
        <>
            <UiFlex className="NavTab" overflowAuto>
                {routes
                    .filter((route) => route.label)
                    .map((route) => {
                        const to = replaceParams(route.component?.route || route.to || route.path);

                        return (
                            <UiButton to={to} size={1} key={route.label}>
                                {route.label}
                            </UiButton>
                        );
                    })}
            </UiFlex>

            <RouterSwitch routes={routes} redirect={redirect && replaceParams(redirect?.route || redirect)} />
        </>
    );
}
