import { getApiUrl } from '../config';
import { httpGet, httpPost, httpPut } from '../shared/api';
import { stores } from '../stores';
import { updateLocationProductPopularityByOrderProducts } from '../stores/location-product-popularity';
import { setLocationProducts } from '../stores/location-products';
import { sendOrderToTerminal } from '../stores/location-terminal-orders';
import { setStaffActiveBar } from '../stores/staff-active-bar';
import { setStaffBar } from '../stores/staff-bars';
import { setStaffVenues } from '../stores/staff-venues';
import { formatLegacyAppPostOrderProducts } from '../util/format-legacy-app-post-order-products';
import { formatLegacyAppPostOrderProductsToPay } from '../util/format-legacy-app-post-order-products-to-pay';
import { MicroserviceLocation } from './location';

const getUrl = getApiUrl('legacy-app');

export async function getStaffUserBalances({ userBalanceId, venueId }) {
    return httpGet(getUrl(`staff/venues/${venueId}/user-balances/${userBalanceId}`));
}

export async function resetUserBalance({ userBalanceId, venueId }) {
    return httpPut(getUrl(`staff/venues/${venueId}/user-balances/${userBalanceId}/reset`));
}

export async function loadStaffVenues() {
    const venues = await httpGet(getUrl('staff/venues'));

    stores.staff.set(state => {
        state.venues = venues;
    });

    setStaffVenues(venues);

    return venues;
}

export async function loadVenueById(id) {
    const venue = await httpGet(getUrl(`venues/${id}`));

    stores.venueById.set(state => {
        state[venue.id] = venue;
    });
}

export async function loadStaffBarById(barId) {
    const bar = await MicroserviceLocation.findBarById(barId);

    setLocationProducts(barId, bar.products);
    setStaffBar(barId, bar);
    setStaffActiveBar({
        ...bar,
        venueId: bar.venue.id,
        venueName: bar.venue.name,
    });

    stores.staff.set(state => {
        state.barById[barId] = bar;
        state.barProductsById[barId] = bar.products;
    });
}

export async function getStaffBarProducts(barId) {
    return httpGet(getUrl(`staff/bars/${barId}/products`));
}

export async function getSwishStationById(id) {
    return httpGet(getUrl(`staff/swish/stations/${id}`));
}

const PostOrderResponseType = {
    BalanceOrder: 'BALANCE_ORDER',
    TerminalOrder: 'TERMINAL_ORDER',
    ChangeBar: 'CHANGE_BAR',
};

export const MicroserviceLegacyApp = {
    async postOrder({ barId, tag, products, ...rest }) {
        const { type, value } = tag;
        const isV2 = Boolean(rest.productsToPay);

        if (rest.productsToPay) {
            rest.productsToPay = formatLegacyAppPostOrderProductsToPay(rest.productsToPay);
        }

        const response = await httpPost(getUrl(`staff/bars/${barId}/orders`), {
            ...rest,
            tagScan: { type, value },
            products: formatLegacyAppPostOrderProducts(products),
        });

        const { order } = response;

        if (response.type === PostOrderResponseType.TerminalOrder) {
            const { order, terminal } = response;
            sendOrderToTerminal({ terminalId: terminal.id, order });
        }

        if (
            !isV2 &&
            [PostOrderResponseType.BalanceOrder, PostOrderResponseType.TerminalOrder].includes(response.type)
        ) {
            updateLocationProductPopularityByOrderProducts(order.LocationId, products);
        }

        return {
            order,
            response,
            PostOrderResponseType,
        };
    },

    updateOrderStatuses(orderIds) {
        return httpPost(getUrl(`staff/update-order-statuses`), {
            orderIds,
        });
    },
};

export async function getCustomerTags({ tagValue, tagType }) {
    return httpPut(getUrl(`customer/tags`), {
        tagType,
        tagValue,
    });
}

export async function createTapNGoOrder({ tagValue, tagType, nozzles }) {
    return httpPost(getUrl(`customer/orders/tap-n-go`), {
        tagType,
        tagValue,
        nozzles,
    });
}
