import { useState } from 'react';
import { BehaviorSubject, Subject } from 'rxjs';
import { useSubject } from '../../use/subject';
import times from 'lodash/times';
import without from 'lodash/without';
import sum from 'lodash/sum';

const subjectOrderProducts = new BehaviorSubject<any>([]);
const subjectLastInteractedProductId = new Subject<number>();
const subjectReset = new Subject();

export function usePosOrder() {
    const [, render] = useState(Math.random());

    function calculateTotal() {
        return sum(Object.values(subjectOrderProducts.getValue()).map(({ price }: any) => price));
    }

    function getProductCount(productId) {
        return subjectOrderProducts.getValue().filter((p) => p.productId === productId).length;
    }

    useSubject(subjectReset, () => {
        subjectOrderProducts.next([]);
        subjectLastInteractedProductId.next(0);
        render(Math.random());
    });

    const orderProducts = subjectOrderProducts.getValue();

    return {
        orderProducts,
        reset() {
            subjectReset.next();
        },
        useOrderProducts() {
            const [, render] = useState(0);

            useSubject(subjectOrderProducts, () => render(Math.random()));

            return {
                orderProducts,

                updateOrderProductByKey(key: number, data: any) {
                    subjectOrderProducts.next(
                        subjectOrderProducts
                            .getValue()
                            .map((product) => (product.key === key ? { ...product, ...data } : product)),
                    );
                },
            };
        },
        useSummary() {
            const [total, setTotal] = useState(calculateTotal());

            useSubject(subjectOrderProducts, () => {
                setTotal(calculateTotal());
            });

            return {
                total,
                menuProducts: subjectOrderProducts.getValue(),
                removeProduct(menuProduct) {
                    const products = subjectOrderProducts.getValue().filter((mp) => mp.key !== menuProduct.key);
                    subjectOrderProducts.next(products);
                    subjectLastInteractedProductId.next(menuProduct.productId);
                },
            };
        },
        useMenuProduct(menuProduct) {
            const { productId } = menuProduct;
            const [count, setCount] = useState(getProductCount(productId));

            useSubject(subjectLastInteractedProductId, (id) => {
                if (id === productId) {
                    setCount(getProductCount(productId));
                }
            });

            useSubject(subjectReset, () => {
                setCount(getProductCount(productId));
            });

            return {
                count,
                addCount(countToAdd: number) {
                    let products = subjectOrderProducts.getValue();

                    for (const id of times(Math.abs(countToAdd))) {
                        if (countToAdd < 0) {
                            products = without(
                                products,
                                products.find((p) => p.productId === productId),
                            );
                        } else {
                            products.push({ ...menuProduct, key: Math.random() });
                        }
                    }

                    subjectOrderProducts.next(products);
                    subjectLastInteractedProductId.next(productId);
                },
            };
        },
    };
}
