import { useEffect } from 'react';
import { useUser } from '../stores/user';
import { Log } from '../util/logger';

export function AppCenterContainer() {
    const AppCenter = window.AppCenter;
    const user = useUser();
    const phone = user && user.phone;

    useEffect(
        () => {
            if (AppCenter) {
                if (phone) {
                    Log.dev('AppCenterContainer', 'Set user phone');
                    AppCenter.setUserId(phone);
                } else {
                    Log.dev('AppCenterContainer', 'Clear user phone');
                    AppCenter.setUserId(null);
                }
            }
        },
        [AppCenter, phone],
    );

    return null;
}

export function useAppCenterCrashes() {
    const AppCenter = window.AppCenter;
    const isAvailable = !!(AppCenter && AppCenter.Crashes);

    return {
        isAvailable,
        generateTestCrash: () => AppCenter.Crashes.generateTestCrash(),
    };
}
