import React from 'react';
import { routes, withParams } from '../shared/router';
import { translate } from '../shared/translate';
import { useLocationTerminals } from '../stores/location-terminals';
import { useStaffActiveBar } from '../stores/staff-active-bar';
import { Dev } from './Dev';
import { V2List } from './v2/V2List';
import { V2ListItem } from './v2/V2ListItem';
import { SvgIcons } from './Svg';

export function StaffBarSettingsTerminals() {
    const { id: barId } = useStaffActiveBar();
    const terminals = useLocationTerminals(barId);

    if (terminals.length === 0) {
        return null;
    }

    return (
        <V2List title={translate('Terminals')}>
            <Dev>
                <V2ListItem
                    to={withParams(routes.staffBarSettingsTerminalQueue, { barId })}
                    title={translate('Terminal queue')}
                    subtitle={translate('Clear queue of terminals that are stuck')}
                    iconRight={SvgIcons.ArrowRight}
                />
            </Dev>

            <V2ListItem
                to={withParams(routes.staffBarSettingsTerminalsSlider, { barId })}
                title={translate('Terminal slider')}
                subtitle={translate('Change look of terminals on bottom slider in orders view')}
                iconRight={SvgIcons.ArrowRight}
            />
        </V2List>
    );
}
