import React, { useState } from 'react';
import styled from 'styled-components';
import { createTapNGoOrder } from '../microservices/legacy-app';
import { showError } from '../shared/toasts';
import { Button } from './Button';
import { CssLoader } from './CssLoader';
import { Flex } from './Flex';
import { Svg } from './Svg';

const Wrapper = styled.div`
    display: flex;
    justify-content: space-between;
    flex-flow: column;
    padding: 32px;
    height: 100vh;
    text-align: center;
`;

const ProductWrapper = styled.div`
    display: flex;
    justify-content: center;
    margin-bottom: 32px;
    margin-top: 32px;

    > *:not(:first-child) {
        margin-left: 8px;
    }

    button {
        border-radius: 100%;
        height: 72px;
        width: 72px;
    }
`;

export function CustomerScanOverlayTapNGo({ createdOrder, products = [], tagValue, tagType, actionSheet }) {
    const [isLoading, setIsLoading] = useState(false);
    const [order, setOrder] = useState(createdOrder);

    let content = <div>Nothing interesting found...</div>;

    async function chooseNumberOfProducts(products, number) {
        setIsLoading(true);

        try {
            const order = await createTapNGoOrder({
                tagValue,
                tagType,
                nozzles: products.slice(0, number).map(({ nozzleNumber }) => nozzleNumber),
            });

            setOrder(order);
        } catch (error) {
            showError(error, 'Failed to order');
        }

        setIsLoading(false);
    }

    if (order) {
        content = (
            <>
                <Flex vertical center>
                    <div>You are now being served</div>
                    <h1>
                        {order.Data.Products.map(product => (
                            <div key={product.Name}>
                                {product.Count} x {product.Name}
                            </div>
                        ))}
                    </h1>
                </Flex>

                <ProductWrapper className="animated flipInX">
                    <Svg icon="checkmark" positive size={72} />
                </ProductWrapper>
            </>
        );
    }

    if (!order && products.length) {
        const productName = products[0].product.name;

        content = (
            <>
                <Flex vertical center>
                    <div>You are ordering</div>
                    <h1>{productName}</h1>
                    <div>How many do you want?</div>
                </Flex>

                <ProductWrapper>
                    {isLoading && <CssLoader size={72} />}

                    {!isLoading &&
                        products.map(({ product, nozzleNumber }, i) => (
                            <Button onClick={() => chooseNumberOfProducts(products, i + 1)} key={i} primary outline>
                                {i + 1}
                            </Button>
                        ))}
                </ProductWrapper>
            </>
        );
    }

    return (
        <Wrapper>
            <div />

            <div>{content}</div>

            <Button onClick={actionSheet.close} block>
                Close
            </Button>
        </Wrapper>
    );
}
