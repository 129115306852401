import React from 'react';
import styled from 'styled-components';

const Wrapper = styled.div`
    display: flex;
    align-items: ${props => (props.centerY || props.center ? 'center' : 'initial')};
    justify-content: ${props => (props.centerX || props.center ? 'center' : 'initial')};
    flex-flow: ${props => (props.vertical ? 'column' : 'row')};

    ${props => props.spaceBetween && 'justify-content: space-between'};

    > * {
        flex: ${props => (props.spaceBetween ? 'initial' : 1)};

        :not(:first-child) {
            margin-top: ${({ vertical, gutter }) => (vertical ? gutter : 0)}px;
            margin-left: ${({ vertical, gutter }) => (vertical ? 0 : gutter)}px;
            margin-bottom: 0;
        }
    }
`;

export function Flex({
    vertical = false,
    centerX = false,
    centerY = false,
    center = false,
    spaceBetween = false,
    flexChildren = false,
    className,
    children,
    gutter = 8,
}) {
    return (
        <Wrapper
            vertical={vertical}
            centerX={centerX}
            centerY={centerY}
            center={center}
            gutter={gutter}
            spaceBetween={spaceBetween}
            flexChildren={flexChildren}
            className={className}>
            {children}
        </Wrapper>
    );
}
