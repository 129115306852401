import React, { useEffect, useState, useMemo } from 'react';
import { PosGroupListItem } from '../../../../components/pos/group/list-item/PosGroupListItem';
import { PosPageHeader } from '../../../../components/pos/page/header/PosPageHeader';
import { UiGrid, UiAlert, UiButton, UiIconMaterial } from '@orderx/ui';
import { usePosGroups } from '../../../../hooks/pos/groups';

export function PosGroupIndexPage() {
    const { groups, loadGroups } = usePosGroups();
    const [isMoreVisible, setIsMoreVisible] = useState(false);
    const [showMissingPaymentsOnly, setShowMissingPaymentsOnly] = useState(false);
    const [isEdit, setIsEdit] = useState(false);

    const groupsFiltered = useMemo(() => {
        if (showMissingPaymentsOnly) {
            return groups.filter((group) => !group.isAllProductsPaid && group.hasPendingOrders);
        }

        return groups;
    }, [groups, showMissingPaymentsOnly]);

    useEffect(() => {
        loadGroups();
    }, []);

    return (
        <>
            <PosPageHeader
                contentRight={
                    <>
                        <UiButton
                            onClick={() => setIsEdit(!isEdit)}
                            color={isEdit ? 'secondary' : 'default'}
                            icon={<UiIconMaterial>edit</UiIconMaterial>}
                            size={1}
                        />

                        {groupsFiltered.length > 0 && (
                            <UiButton
                                onClick={() => setIsMoreVisible(!isMoreVisible)}
                                icon={<UiIconMaterial>more_vert</UiIconMaterial>}
                                size={1}
                            />
                        )}
                    </>
                }
            />

            <UiGrid spacing={1} outerSpacing={1}>
                {isMoreVisible && (
                    <UiGrid>
                        <UiButton
                            onClick={() => setShowMissingPaymentsOnly(!showMissingPaymentsOnly)}
                            variant="filled"
                            color={showMissingPaymentsOnly ? 'secondary' : 'default'}>
                            Show tables with missing payments
                        </UiButton>
                    </UiGrid>
                )}

                {showMissingPaymentsOnly && (
                    <UiAlert color="warning">
                        <UiGrid>
                            <span>There are tables that are not being displayed due to filtering.</span>

                            <UiButton onClick={() => setShowMissingPaymentsOnly(false)} variant="filled">
                                Show all tables
                            </UiButton>
                        </UiGrid>
                    </UiAlert>
                )}

                <UiGrid minMax={160} spacing={1}>
                    {groupsFiltered.map((group) => (
                        <PosGroupListItem group={group} isEdit={isEdit} key={group.id} />
                    ))}
                </UiGrid>
            </UiGrid>
        </>
    );
}
