import clsx from 'clsx';
import React from 'react';
import './PosPageLoader.scss';
import { UiLoader } from '../../../ui/UiLoader';

export function PosPageLoader({ message = 'Loading...' }) {
    return (
        <div className={clsx('PosPageLoader')}>
            <div>
                <UiLoader color="secondary" thickness={2} size={64} />
                <h4>{message}</h4>
            </div>
        </div>
    );
}
