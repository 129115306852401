import keyBy from 'lodash/keyBy';
import React from 'react';
import styled from 'styled-components';
import { AwsIotStatus, useAwsIotStatus, useAwsIotSubscription } from '../providers/aws-iot';
import { getOrderStatusColor, ORDER_STATUS } from '../shared/orders';
import { mixins } from '../shared/styles';
import { useTerminalsWithOrderByLocationId } from '../stores/location-terminal-orders';
import { useLocationTerminalSettings } from '../stores/location-terminal-settings';
import { useLocationTerminals } from '../stores/location-terminals';
import { useStaffActiveBar } from '../stores/staff-active-bar';
import { useStaffTerminalSlider } from '../stores/staff-terminal-slider';
import { themeActive } from '../themes/dark';
import orderBy from 'lodash/orderBy';
import { useIotTerminalOrderPlaced } from '../hooks/iot/terminal-order-placed';
import { useStaffTerminalOrders } from '../stores/staff-terminal-orders';

export const Size = {
    Compact: 'compact',
    Expanded: 'expanded',
};

export const styles = {
    [Size.Compact]: {
        placeholderHeight: 50 + themeActive.sizes.base,
        navbar: {
            fontSize: 1,
            padding: 4,
        },
        terminal: {
            paddingHorizontal: 16,
            size: 40,
            marginLeft: 4,
        },
    },
    [Size.Expanded]: {
        placeholderHeight: 80 + themeActive.sizes.base,
        navbar: {
            fontSize: 1.25,
            padding: 16,
        },
        terminal: {
            paddingHorizontal: 16,
            size: 70,
            marginLeft: 16,
        },
    },
};

const Wrapper = styled.div`
    ${mixins.horizontalScroller};
    background-color: ${themeActive.tabs.backgroundColor};
    display: block;
    border-top: 2px solid ${themeActive.tabs.backgroundColor};
    transition: 300ms;
    align-items: center;
    padding: ${props => styles[props.size].navbar.padding}px;

    border-top-color: ${({ mqttStatus }) => {
        switch (mqttStatus) {
            case AwsIotStatus.RECONNECTING:
            case AwsIotStatus.ENDING:
                return themeActive.colors.yellow;
            case AwsIotStatus.CONNECTED:
                return themeActive.colors.green;
            case AwsIotStatus.DISCONNECTED:
            case AwsIotStatus.ERROR:
                return themeActive.colors.red;
            case AwsIotStatus.OFFLINE:
                return 'grey';
            default:
                return themeActive.tabs.backgroundColor;
        }
    }};
`;

const TerminalWrapper = styled.div`
    background-color: ${props => (props.status ? getOrderStatusColor(props.status) : themeActive.body.backgroundColor)};
    box-shadow: 0 0 8px ${props => (props.status ? getOrderStatusColor(props.status) : 'transparent')};
    font-size: ${props => styles[props.size].navbar.fontSize}rem;
    padding: 0 ${props => styles[props.size].terminal.paddingHorizontal}px;
    height: ${props => styles[props.size].terminal.size}px;
    width: ${props => styles[props.size].terminal.size}px;
    border: 1px solid ${props => (props.status ? getOrderStatusColor(props.status) : 'transparent')};
    border-radius: 100%;
    display: inline-flex;
    align-items: center;
    justify-content: center;

    &:not(:first-child) {
        margin-left: ${props => styles[props.size].terminal.marginLeft}px;
    }

    .terminal-name {
        color: ${props => (props.status === ORDER_STATUS.PAYMENT_WAITING ? 'black' : 'white')};
        font-weight: bold;
    }
`;

const Content = styled.a`
    display: flex;
    justify-content: center;
    align-items: center;
    flex-flow: column;
`;

export function StaffTerminalsSlider({ barId, onClickOrder = () => {}, onClickTerminal = () => {}, onUpdate }) {
    const bar = useStaffActiveBar();
    barId = barId || (bar && bar.id);
    onUpdate = onUpdate || (() => {});

    const terminals = useLocationTerminals(barId);
    const { getOrderByTerminalId } = useStaffTerminalSlider();
    const { status } = useAwsIotStatus();
    const terminalsById = keyBy(terminals, terminal => terminal.id);
    const tappedTerminalsWithOrder = useTerminalsWithOrderByLocationId(barId);
    const { isControlledFromSettings, isExpanded, getOrderedTerminals } = useLocationTerminalSettings(barId);
    const size = isExpanded ? Size.Expanded : Size.Compact;

    const sliderTerminals = isControlledFromSettings
        ? getOrderedTerminals(tappedTerminalsWithOrder)
        : orderBy(tappedTerminalsWithOrder, terminal => terminal.name, 'asc')
              .map(terminal => {
                  const match = terminals.find(t => t.id === terminal.id);
                  return match && { ...match, ...terminal };
              })
              .filter(Boolean);

    return (
        <Wrapper mqttStatus={status} className="StaffTerminalsSlider" size={size}>
            {sliderTerminals.map(({ id }) => {
                const order = getOrderByTerminalId(id);
                const terminal = terminalsById[id];
                const onClick = order ? () => onClickOrder({ order }) : () => {};
                const orderStatus = order?.Status;

                return (
                    <TerminalWrapper
                        size={size}
                        key={id}
                        status={orderStatus}
                        onClick={() => {
                            if (terminal) {
                                onClickTerminal(terminal);
                            }

                            onClick();
                        }}>
                        <Content>
                            <div className="terminal-name">{terminal ? terminal.name : id}</div>
                        </Content>

                        <SubscribeToIot terminalId={id} onUpdate={onUpdate} key={id} />
                    </TerminalWrapper>
                );
            })}
        </Wrapper>
    );
}

function SubscribeToIot({ terminalId, onUpdate }) {
    const { putOrder } = useStaffTerminalOrders();
    const { getOrderByTerminalId, setTerminalOrder } = useStaffTerminalSlider();
    const order = getOrderByTerminalId(terminalId);

    function handleOrder(order) {
        order = { ...order, Datetime: order.Datetime || Date.now() };
        setTerminalOrder(terminalId, order);
        putOrder(order);
        onUpdate();
    }

    useIotTerminalOrderPlaced(terminalId, handleOrder);
    useAwsIotSubscription(terminalId && `pos/status/${terminalId}`, handleOrder);
    useAwsIotSubscription(order && `order-update/${order.Id}`, handleOrder);

    return null;
}
