import IconCheck from '@material-ui/icons/CheckCircleRounded';
import React from 'react';
import { usePosGroups } from '../../../../../../hooks/pos/groups';
import { MicroserviceOrders } from '../../../../../../microservices/orders';
import { useSnackbar } from '../../../../../../shared/snackbar';
import { useApiQuery } from '@orderx/http';
import './PosGroupListItemActionArchive.scss';
import { UiButton } from '../../../../../ui/button/UiButton';

export function PosGroupListItemActionArchive({ group }) {
    const { showSuccess } = useSnackbar();
    const { loadGroups } = usePosGroups();

    const { run: archiveGroup, isLoading: isArchiving } = useApiQuery(async (groupId) => {
        await MicroserviceOrders.staffApp({
            action: 'ARCHIVE_GROUP',
            groupId,
        });

        await loadGroups();
        showSuccess('Table has been cleared');
    });

    return (
        <UiButton
            onClick={() => archiveGroup(group.id)}
            isLoading={isArchiving}
            icon={<IconCheck />}
            className="PosGroupListItemActionArchive"
        />
    );
}
