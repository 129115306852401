import keyBy from 'lodash/keyBy';
import sum from 'lodash/sum';
import React, { Fragment } from 'react';
import { getApiUrl } from '../config';
import { httpDelete, httpGet, httpPost, httpPut } from '../shared/api';
import { useAwsIotSubscription } from '../providers/aws-iot';
import { stores } from '../stores';
import { UiAlert } from '@orderx/ui';

const serviceName = 'balance-groups';
const getUrl = getApiUrl(serviceName);

export const PUSH_NOTIFICATION_TYPE = {
    BALANCE_GROUP_UPDATED: 'balance-groups:balance_group_updated',
    BALANCE_GROUP_DELETED: 'balance-groups:balance_group_deleted',
};

export function useMqttVenueBalanceGroups(venueId) {
    return useAwsIotSubscription(`balance-groups:venue_${venueId}_balance_groups`, handleVenueBalanceGroupNotification);
}

export async function loadVenueBalanceGroups(venueId) {
    const balanceGroups = await httpGet(getUrl(`venues/${venueId}/balance-groups`));

    stores.staff.set((state) => {
        state.balanceGroupById = keyBy(balanceGroups, (balanceGroup) => balanceGroup.id);
    });

    return balanceGroups;
}

export function createVenueBalanceGroup(venueId, balanceGroup) {
    return httpPost(getUrl(`venues/${venueId}/balance-groups`), balanceGroup);
}

export function deleteVenueBalanceGroup({ venueId, balanceGroupId }) {
    return httpDelete(getUrl(`venues/${venueId}/balance-groups/${balanceGroupId}`));
}

export function addBalanceToVenueBalanceGroup({ venueId, balanceGroupId, balanceId }) {
    return httpPut(getUrl(`venues/${venueId}/balance-groups/${balanceGroupId}/balances/${balanceId}`));
}

export function resetVenueBalanceGroup({ venueId, balanceGroupId }) {
    return httpPut(getUrl(`venues/${venueId}/balance-groups/${balanceGroupId}/reset`));
}

export function updateBalanceGroup({ venueId, balanceGroupId }, data) {
    return httpPut(getUrl(`venues/${venueId}/balance-groups/${balanceGroupId}`), data);
}

export function getTotalUnitsSpent(balanceGroup) {
    return sum((balanceGroup.balances || []).map((balance) => balance.count)) || 0;
}

export function isLimitExceeded(balanceGroup) {
    const { limit } = balanceGroup;
    return limit > 0 && getTotalUnitsSpent(balanceGroup) >= limit;
}

export function getLimitExceededAlert(balanceGroup) {
    return (
        isLimitExceeded(balanceGroup) && (
            <UiAlert color="error">Limit for this group has been reached. Click to change limit</UiAlert>
        )
    );
}

export function getUnitsSpentLabel(balanceGroup) {
    const hasLimit = balanceGroup.limit > 0;

    return (
        <Fragment>
            {getTotalUnitsSpent(balanceGroup)}
            {hasLimit && <Fragment>/{balanceGroup.limit}</Fragment>}
        </Fragment>
    );
}

function handleVenueBalanceGroupNotification({ type, data }) {
    switch (type) {
        case PUSH_NOTIFICATION_TYPE.BALANCE_GROUP_UPDATED:
            stores.staff.set((state) => {
                state.balanceGroupById[data.id] = data;
            });

            break;
        case PUSH_NOTIFICATION_TYPE.BALANCE_GROUP_DELETED:
            stores.staff.set((state) => {
                delete state.balanceGroupById[data.id];
            });

            break;
        default:
            break;
    }
}
