import React, { useEffect } from 'react';
import { getUrlToAsset } from '../shared/assets';
import { useDevice } from '../shared/device';
import { Log } from '../util/logger';

export const AudioFile = {
    OrderNew: {
        id: 'OrderNew',
        path: getUrlToAsset('/assets/new-order.mp3'),
    },
};

export function AudioContainer() {
    const { isNative } = useDevice();
    const NativeAudio = window.plugins && window.plugins.NativeAudio;
    const canLoad = isNative && Boolean(NativeAudio);

    // useEffect(() => {
    //     if (!isNative) {
    //         for (const key of Object.keys(AudioFile)) {
    //             new Audio(AudioFile[key].path).load();
    //         }
    //     }
    // }, [isNative]);

    useEffect(() => {
        if (canLoad) {
            NativeAudio.preloadSimple(AudioFile.OrderNew.id, AudioFile.OrderNew.path, Log.dev, Log.dev);
        }
    }, [canLoad]);

    return null;
}

export function playSound(audioFile) {
    try {
        const NativeAudio = window.plugins && window.plugins.NativeAudio;

        if (NativeAudio) {
            Log.dev('audio', 'playSound', audioFile.id);
            NativeAudio.play(audioFile.id, 1);
        } else {
            const audio = new Audio(audioFile.path);
            audio.play().catch(console.error);
        }
    } catch (error) {
        console.error(error);
    }
}
