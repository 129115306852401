import List from '@material-ui/core/List';
import ListSubheader from '@material-ui/core/ListSubheader';
import React, { useCallback } from 'react';
import { Route } from 'react-router-dom';
import { UiPageHeader } from '../../components/ui/UiPageHeader';
import { UiSwitch } from '../../components/ui/UiSwitch';
import { V2CodePush } from '../../components/v2/V2CodePush';
import { postAction } from '../../microservices/device-actions';
import { routes } from '../../shared/router';
import { useSnackbar } from '../../shared/snackbar';
import { storageSet } from '../../shared/storage';
import { useDeviceConfig } from '../../stores/device-config';
import { useStaffOrderingBar } from '../../stores/staff-ordering-bar';
import { useUser } from '../../stores/user';
import { StaffOrderConfigOrderHistoryPage } from './StaffOrderConfigOrderHistoryPage';
import { StaffOrderConfigQueuePage } from './StaffOrderConfigQueuePage';
import { StaffOrderConfigStationPage } from './StaffOrderConfigStationPage';
import { StaffOrderConfigTableAvailabilityPage } from './StaffOrderConfigTableAvailabilityPage';
import { StaffOrderConfigTerminalSliderPage } from './StaffOrderConfigTerminalSliderPage';
import { ListItem } from '../../@v2/components/list/item/ListItem';
import { UiGrid } from '@orderx/ui';

export function StaffOrderConfig() {
    const { isBetaTester } = useUser();
    const { barId, barName } = useStaffOrderingBar();
    const { config, updateConfig } = useDeviceConfig();
    const { showSuccess } = useSnackbar();

    const getConfigCheckboxListItem = useCallback(
        (label, property) => (
            <ListItem
                title={label}
                onClick={() => updateConfig({ [property]: !config[property] })}
                iconEnd={
                    <UiSwitch
                        onChange={() => updateConfig({ [property]: !config[property] })}
                        checked={config[property]}
                        noFormGroup
                    />
                }
            />
        ),
        [config],
    );

    const content = (
        <>
            <UiPageHeader>
                <h4>{barName}</h4>
            </UiPageHeader>

            <UiGrid>
                <List>
                    <ListItem
                        title={'Station config'}
                        to={routes.staffOrderBarConfigStation.replace(':barId', barId)}
                    />

                    <ListItem
                        title={'Change table availability'}
                        to={routes.staffOrderBarConfigTableAvailability.replace(':barId', barId)}
                    />

                    <ListItem
                        title="Clear storage and reload app"
                        onClick={() => {
                            localStorage.clear();
                            window.location.reload();
                        }}
                    />

                    <ListItem
                        title="Clear orders"
                        onClick={() => {
                            storageSet('staffOrderHistory', []);
                            showSuccess('Cleared order history');
                            window.location.reload();
                        }}
                    />

                    <ListItem title={'Queue'} to={routes.staffOrderBarConfigQueue.replace(':barId', barId)} />

                    <ListItem
                        title={'Terminal slider'}
                        to={routes.staffOrderBarConfigTerminalSlider.replace(':barId', barId)}
                    />

                    <ListItem
                        title={'Order history'}
                        to={routes.staffOrderBarConfigOrderHistory.replace(':barId', barId)}
                    />
                </List>

                <List subheader={<ListSubheader>Config</ListSubheader>}>
                    {getConfigCheckboxListItem('Lock station statuses', 'isStationStatusesLocked')}
                    {getConfigCheckboxListItem('Product pagination', 'hasPagination')}
                    {getConfigCheckboxListItem('Use new order create view', 'isNewOrderCreateView')}
                    {getConfigCheckboxListItem('Use station view', 'isStationViewVisible')}
                    {getConfigCheckboxListItem('Terminals - Gratitude ON/OFF', 'isGratitudeOn')}
                    {getConfigCheckboxListItem('Terminals - Card manual entry', 'isManualEntryVisible')}
                </List>

                <List subheader={<ListSubheader>Orders</ListSubheader>}>
                    {getConfigCheckboxListItem('Allow comments', 'isOrderCommentsEnabled')}
                    {getConfigCheckboxListItem('Auto print sequence', 'isOrderPrintSequenceEnabled')}
                    {getConfigCheckboxListItem('Poll for orders', 'isPollingForOrders')}
                </List>

                <V2CodePush />

                {isBetaTester && (
                    <List subheader={<ListSubheader>Beta tester</ListSubheader>}>
                        <ListItem
                            title={'Generate test error'}
                            onClick={async () => {
                                try {
                                    throw new Error('TEST ERROR');
                                } catch (error) {
                                    await postAction('react_app_error', { message: String(error) });
                                }
                            }}
                        />
                    </List>
                )}
            </UiGrid>
        </>
    );

    return (
        <>
            <Route path={routes.staffOrderBarConfig} exact render={() => content} />
            <Route path={routes.staffOrderBarConfigStation} component={StaffOrderConfigStationPage} />
            <Route path={routes.staffOrderBarConfigQueue} component={StaffOrderConfigQueuePage} />
            <Route
                path={routes.staffOrderBarConfigTableAvailability}
                component={StaffOrderConfigTableAvailabilityPage}
            />
            <Route path={routes.staffOrderBarConfigTerminalSlider} component={StaffOrderConfigTerminalSliderPage} />
            <Route path={routes.staffOrderBarConfigOrderHistory} component={StaffOrderConfigOrderHistoryPage} />
        </>
    );
}
