import { createStore } from '@orderx/store';
import { storageGet, storageSet } from '../shared/storage';
import { useEffect } from 'react';
import { useApiQuery } from '@orderx/http';
import { MicroserviceOrders } from '../microservices/orders';

const cacheKey = 'discount';

const { useStore } = createStore({ ...storageGet(cacheKey) });

export function useDiscount() {
    const [state, setState] = useStore();

    const { run } = useApiQuery(async ({ venueId, discountId }) => {
        let isValid = false;

        try {
            const response = await MicroserviceOrders.postAppCustomer({
                action: 'handlerAppCustomerValidateDiscount',
                venueId,
                discountId,
            });

            isValid = response.isValid;
        } catch (error) {
            console.error(error);
        }

        if (!isValid) {
            setState((state) => {
                state[venueId] = undefined;
            });
        }
    });

    useEffect(() => {
        storageSet(cacheKey, state);
    }, [state]);

    return {
        reset() {
            setState({});
            storageSet(cacheKey, {});
        },
        validateDiscount(venueId) {
            const discount = state[venueId];

            if (discount) {
                run({ venueId, discountId: discount.id });
            }
        },
        saveVenueAccount({ venueId, account }) {
            setState((state) => {
                state[venueId] = account;
            });
        },
        getDiscountByVenueId(venueId) {
            return state[venueId];
        },
        calculateDiscountByVenueId(amount, venueId) {
            const discount = state[venueId]?.percent || 0;

            if (discount) {
                const discounted = amount - amount * (discount / 100);
                return Math.round(discounted / 100) * 100;
            } else {
                return amount;
            }
        },
    };
}
