import clsx from 'clsx';
import React from 'react';
import './UiTableCellCheckbox.scss';
import { Checkbox } from '@material-ui/core';

export function UiTableCellCheckbox({ checked, onChange }) {
    return (
        <td className={clsx('UiTableCellCheckbox')}>
            <Checkbox checked={checked} onChange={() => onChange(!checked)} />
        </td>
    );
}
