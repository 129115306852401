import { Entity, Column, ManyToOne, PrimaryColumn } from './typeorm';
import { BaseModel } from './base-model';
import { InventoryPurchaseOrder } from './inventory-purchase-order';
import { InventoryProduct } from './inventory-product';

@Entity()
export class InventoryPurchaseOrderProduct extends BaseModel {
    @ManyToOne(() => InventoryPurchaseOrder, (model) => model.products)
    purchaseOrder: InventoryPurchaseOrder;
    @PrimaryColumn()
    purchaseOrderId: number;

    @ManyToOne(() => InventoryProduct, (model) => model.inventoryProducts)
    product: InventoryProduct;
    @PrimaryColumn()
    productId: number;

    @Column()
    unitAmount: number;

    @Column({ nullable: true })
    containerPrice: number;
}
