// NB! DO NOT EDIT THIS FILE! THIS FILE IS AUTOGENERATED AND WILL BE OVERWRITTEN

import { Api } from '@orderx/http';

export function apiOrderOrderIdGet({
    body,
    params,
    query,
}: {
    body?: any;
    params: { orderId: any };
    query?: any;
}): Promise<any> {
    return Api.request('order/:orderId', { method: 'get', body, params, query });
}
