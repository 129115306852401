import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import makeStyles from '@material-ui/core/styles/makeStyles';
import React from 'react';
import { MicroserviceOrders } from '../../../../../../microservices/orders';
import { ProductStatus } from '../../../../../../models/orders/product';
import { useSnackbar } from '../../../../../../shared/snackbar';
import { useDeviceConfig } from '../../../../../../stores/device-config';
import { useApiQuery } from '@orderx/http';
import { UiButton } from '../../../../../../@deprecated/UiButton';
import { UiButtonGroup } from '../../../../../ui/UiButtonGroup';
import { UiFlex, UiGrid } from '@orderx/ui';

const useStyles = makeStyles({
    fullWidth: { width: '100%' },
    comment: { marginTop: 8 },
});

export function StaffOrderStationListItemProductChipDialog({ products, onClose }) {
    const classes = useStyles();
    const { showSuccess } = useSnackbar();
    const { isStationCategoryStatusAllowed } = useDeviceConfig();

    const { run: updateProductsToStatus, isLoading } = useApiQuery(async ({ product, status }) => {
        await MicroserviceOrders.staffApp({
            action: 'UPDATE_PRODUCTS_STATUS',
            orderId: product.orderId,
            productIds: [product.id],
            status,
        });

        showSuccess('Updated');
    });

    function close() {
        onClose(false);
    }

    function getStatusButton(product, label, status) {
        if (!isStationCategoryStatusAllowed(product.category, status)) {
            return null;
        }

        return (
            <UiButton
                variant={'contained'}
                color={product.status === status ? 'primary' : 'default'}
                onClick={() => updateProductsToStatus({ product, status })}
                disabled={product.status === status || isLoading}>
                {label}
            </UiButton>
        );
    }

    return (
        <Dialog open onClose={close}>
            <DialogContent>
                <UiGrid padding={2}>
                    {products.map((product) => (
                        <UiFlex key={product.id} spacing={2} fullWidth>
                            <div className={classes.fullWidth}>
                                <UiButtonGroup label={product.name}>
                                    {getStatusButton(product, 'Not started', ProductStatus.Placed)}
                                    {getStatusButton(product, 'In preparation', ProductStatus.InPreparation)}
                                    {getStatusButton(product, 'Ready', ProductStatus.Ready)}
                                    {getStatusButton(product, 'Handed out', ProductStatus.Delivered)}
                                </UiButtonGroup>

                                {product.comment && (
                                    <div className={classes.comment}>
                                        <b>{'Comment:'}</b> {product.comment}
                                    </div>
                                )}
                            </div>
                        </UiFlex>
                    ))}
                </UiGrid>
            </DialogContent>

            <DialogActions>
                <UiButton fullWidth onClick={close}>
                    Close
                </UiButton>
            </DialogActions>
        </Dialog>
    );
}
