import React, { useEffect } from 'react';
import { StaffBottomNav } from '../../../components/staff-bottom-nav/StaffBottomNav';
import { SwitchRedirect } from '../../../components/SwitchRedirect';
import { PosGroupCreatePage } from './create/PosGroupCreatePage';
import { PosGroupIdPage } from './id/PosGroupIdPage';
import { PosGroupIndexPage } from './index/PosGroupIndexPage';
import { usePosGroups } from '../../../hooks/pos/groups';
import { useOnWindowFocus } from '../../../shared/browser';
import { SplashScreen } from '../../splash-screen/SplashScreen';

export function PosGroupPage() {
    const { loadGroups, groups } = usePosGroups();

    useEffect(() => {
        loadGroups();
    }, []);

    useOnWindowFocus(() => {
        loadGroups();
    }, []);

    return (
        <>
            {groups.length === 0 ? (
                <SplashScreen />
            ) : (
                <SwitchRedirect
                    routes={[
                        {
                            path: PosGroupPage.route,
                            component: PosGroupIndexPage,
                            exact: true,
                        },
                        {
                            path: PosGroupCreatePage.route,
                            component: PosGroupCreatePage,
                        },
                        {
                            path: PosGroupIdPage.route,
                            component: PosGroupIdPage,
                        },
                    ]}
                />
            )}

            <StaffBottomNav />
        </>
    );
}

PosGroupPage.route = '/pos/:barId/group';
