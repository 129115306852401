import React from 'react';
import { UiToolbar, UiGrid, UiButton, UiOverlay } from '@orderx/ui';
import { ToolbarTitle } from '../../../@v2/components/toolbar/title/ToolbarTitle';
import { CustomerBrowseGroupsHashPage } from '../../../pages/CustomerBrowseGroupsHashPage';

interface Props {
    bar: any;

    onClick?(): void;

    onClose(): void;
}

export function CustomerBarGroupsOverlay({ bar, onClick, onClose }: Props) {
    return (
        <UiOverlay>
            <UiToolbar>
                <ToolbarTitle title={bar.name} buttonBackOnClick={onClose} />
            </UiToolbar>

            <UiGrid outerSpacing={2} minMax={80}>
                {bar.groups.map((group) => (
                    <UiButton
                        onClick={onClick}
                        to={CustomerBrowseGroupsHashPage.route}
                        toParams={{ hash: group.hash }}
                        key={group.id}
                        variant="outlined"
                        size={1}>
                        {group.name}
                    </UiButton>
                ))}
            </UiGrid>
        </UiOverlay>
    );
}
