import clsx from 'clsx';
import React, { useEffect } from 'react';
import { UiFlex, UiButton, UiIconMaterial, UiText, UiGrid, UiToolbar, UiOverlay } from '@orderx/ui';
import './DeliveryStationHeaderSettings.scss';
import { useDeliveryView } from '../../../../../hooks/delivery/view';
import { ProductStatus } from '../../../../../models/orders/product';
import { ToolbarTitle } from '../../../../../@v2/components/toolbar/title/ToolbarTitle';
import { useApiQuery } from '@orderx/http';
import { MicroserviceOrders } from '../../../../../microservices/orders';
import { BlockLoader } from '../../../../../@v2/components/block-loader/BlockLoader';

export function DeliveryStationHeaderSettings({ onClose }) {
    const { bars, config, configSetValue, configToggleListValue } = useDeliveryView();
    const barIds = bars.map((bar) => bar.id);
    const hasMultipleBars = barIds.length > 1;

    const { run, response } = useApiQuery(async () =>
        MicroserviceOrders.staffApp({
            action: 'handlerAppStaffDeliveryViewSettings',
            barIds,
        }),
    );

    function closeOverlay() {
        onClose();
    }

    useEffect(() => {
        if (config.groupFilterActive && !hasMultipleBars) {
            run();
        }
    }, [config.groupFilterActive, hasMultipleBars]);

    function getConfigBoolean({ label, icon, description, field }: any) {
        const checked = Boolean(config[field]);

        return (
            <UiFlex
                className="item"
                onClick={() => configSetValue(field, !checked)}
                justifyContent="space-between"
                spacing={2}>
                <UiFlex spacing={1}>
                    <div />

                    {icon && <UiIconMaterial className={clsx(checked && 'filter-active')}>{icon}</UiIconMaterial>}

                    <div />

                    <div>
                        <UiText color={checked ? 'success' : 'default'} weight={2}>
                            {label}
                        </UiText>

                        {description && <UiText color="text-secondary">{description}</UiText>}
                    </div>
                </UiFlex>

                <UiButton
                    color={checked ? 'success' : 'text-secondary'}
                    iconCircle
                    icon={<UiIconMaterial>{checked ? 'check_box' : 'check_box_outline_blank'}</UiIconMaterial>}
                />
            </UiFlex>
        );
    }

    return (
        <UiOverlay className="DeliveryStationHeaderSettings">
            <UiGrid spacing={0}>
                <UiToolbar>
                    <ToolbarTitle title="Settings" />

                    <UiButton
                        onClick={closeOverlay}
                        iconCircle
                        size={1}
                        icon={<UiIconMaterial>close</UiIconMaterial>}
                    />
                </UiToolbar>

                {getConfigBoolean({
                    field: 'compact',
                    icon: 'dashboard',
                    label: <>Compact view</>,
                    description: (
                        <>
                            Each order item data is fit onto one line so in effect, each order takes less space and you
                            see more orders total
                        </>
                    ),
                })}

                {getConfigBoolean({
                    field: 'showTablePlacedOrders',
                    icon: 'filter_list',
                    label: <>Table placed orders only</>,
                    description: <>Only show orders that are placed from table</>,
                })}

                {getConfigBoolean({
                    field: 'orderStatusFilterVisible',
                    icon: 'filter_list',
                    label: <>Order status filter</>,
                    description: <>Allow to filter orders by status</>,
                })}

                {!hasMultipleBars &&
                    getConfigBoolean({
                        field: 'groupFilterActive',
                        icon: 'filter_list',
                        label: <>Tables filter</>,
                        description: <>Choose tables to display orders from</>,
                    })}

                {config.groupFilterActive && !hasMultipleBars && (
                    <>
                        {!response && <BlockLoader />}

                        {response && (
                            <UiGrid className="table-grid" minMax={60} spacing={1} outerSpacing={1}>
                                {response.groups.map((group) => (
                                    <UiButton
                                        onClick={() => configToggleListValue('groupFilterIds', group.id)}
                                        color={config.groupFilterIds.includes(group.id) ? 'secondary' : 'default'}
                                        key={group.id}
                                        variant="filled"
                                        size={-1}>
                                        {group.name}
                                    </UiButton>
                                ))}
                            </UiGrid>
                        )}
                    </>
                )}

                {getConfigBoolean({
                    field: 'categoryFilterVisible',
                    icon: 'filter_list',
                    label: <>Category filter</>,
                    description: <>Allow to filter orders by product category</>,
                })}

                {getConfigBoolean({
                    field: 'commentsVisibleInListView',
                    icon: 'visibility',
                    label: <>Display product comments in list view</>,
                    description: (
                        <>
                            Product comments are visible in list view meaning you do not have to click on separate
                            products to see information
                        </>
                    ),
                })}

                {[
                    { label: 'Allowed food statuses', field: 'allowedFoodStatuses' },
                    { label: 'Allowed drink statuses', field: 'allowedDrinkStatuses' },
                ].map(({ label, field }) => (
                    <UiGrid key={field} outerSpacing={2}>
                        <UiText size={1}>{label}</UiText>

                        <UiGrid minMax={100} spacing={1}>
                            {[
                                ProductStatus.Placed,
                                ProductStatus.InPreparation,
                                ProductStatus.Ready,
                                ProductStatus.InDelivery,
                                ProductStatus.Delivered,
                            ].map((status) => (
                                <UiButton
                                    onClick={() => configToggleListValue(field, status)}
                                    color={config[field].includes(status) ? 'success' : 'default'}
                                    variant="filled"
                                    key={status}>
                                    {status}
                                </UiButton>
                            ))}
                        </UiGrid>
                    </UiGrid>
                ))}
            </UiGrid>
        </UiOverlay>
    );
}
