import React from 'react';
import { usePosGroup } from '../../../../hooks/pos/group';
import { useSnackbar } from '../../../../shared/snackbar';
import { MicroserviceLocation } from '../../../../microservices/location';
import { printPosReceipt } from '../../../../microservices/orderx';
import { useApiQuery } from '@orderx/http';
import IconPrint from '@material-ui/icons/PrintRounded';
import { UiButton } from '../../../ui/button/UiButton';
import { PaymentStatus } from '../../../../models/orders/payment';
import { CurrencyMinor } from '../../../CurrencyMinor';
import { groupProductsByLocationProductId } from '../../../../util/group-products';
import { UiTable } from '../../../ui/table/UiTable';
import { UiText, UiBox, UiAlert, UiFlex } from '@orderx/ui';

export function PosGroupPayments() {
    const { payments, barId } = usePosGroup();
    const { showSuccess } = useSnackbar();

    const { run: printSalesReceipt, isLoading: isPrinting } = useApiQuery(async (payment) => {
        const { venue } = await MicroserviceLocation.findBarById(barId);

        await printPosReceipt({
            orderId: payment.dynamoOrderId,
            venueId: venue.id,
        });

        showSuccess('Printing from terminal');
    });

    function getProductsLabel(payment) {
        return groupProductsByLocationProductId(payment.products)
            .map((product) => `${product.count}x${product.name}`)
            .join(', ');
    }

    const statusColor = {
        [PaymentStatus.Success]: 'success',
        [PaymentStatus.Pending]: 'warning',
        [PaymentStatus.Failed]: 'error',
    };

    if (payments.length === 0) {
        return (
            <UiBox padding={2}>
                <UiAlert>No payments found</UiAlert>
            </UiBox>
        );
    }

    return (
        <>
            <UiTable>
                <thead>
                    <tr>
                        <td />
                    </tr>
                </thead>

                <tbody>
                    {payments.map((payment) => (
                        <tr key={payment.id}>
                            <td>
                                {getProductsLabel(payment)}

                                <UiFlex spacing={1}>
                                    <UiText color={statusColor[payment.status]} weight={2}>
                                        {payment.status} • <CurrencyMinor amount={payment.amount} />
                                    </UiText>
                                </UiFlex>
                            </td>

                            <td align="right">
                                {payment.status === PaymentStatus.Success &&
                                    payment.type === 'BAMBORA_TERMINAL' &&
                                    payment.dynamoOrderId && (
                                        <UiButton
                                            onClick={() => printSalesReceipt(payment)}
                                            disabled={isPrinting}
                                            icon={<IconPrint />}
                                        />
                                    )}
                            </td>
                        </tr>
                    ))}
                </tbody>
            </UiTable>
        </>
    );
}
