import React from 'react';
import styled from 'styled-components';
import { ORDER_STATUS } from '../shared/orders';
import { themeActive } from '../themes/dark';

const Wrapper = styled.div`
    height: 8px;
    width: 8px;
    border-radius: 100%;
`;

export const statusBackgroundColors = {
    [ORDER_STATUS.INITIALIZED]: themeActive.colors.background,
    [ORDER_STATUS.PAYMENT_WAITING]: themeActive.colors.yellow,
    [ORDER_STATUS.PAYMENT_FAILED]: themeActive.colors.red,
    [ORDER_STATUS.PAYMENT_SUCCESS]: themeActive.colors.green,
    [ORDER_STATUS.FINALIZED]: themeActive.colors.green,
    [ORDER_STATUS.SENT_TO_BOTTOMS_UP_UNIT]: themeActive.colors.primary,
};

export function OrderStatusLight({ status }) {
    const color = statusBackgroundColors[status] || themeActive.colors.background;

    const style = {
        backgroundColor: color,
        boxShadow: `0 2px 8px ${color}`,
    };

    return <Wrapper style={style} />;
}
