import React from 'react';
import styled from 'styled-components';
import { theme } from '../themes/dark';
import { CurrencyMinor } from './CurrencyMinor';
import { UiText, UiFlex } from '@orderx/ui';

const PaidProgressWrapper = styled.div`
    height: 8px;
    width: 100%;
    // TODO
    background-color: var(--color-divider);
    border-radius: 8px;
    position: relative;
    margin: 8px 0;
`;

const PaidProgress = styled.div`
    height: 8px;
    border-radius: 8px;
    width: ${(props) => props.progress}%;
    max-width: 100%;
    background-color: ${theme.colors.green};
    transition: width 300ms;
`;

export function OrderPaymentProgressBar({ amountTotal = 0, amountPaid = 0 }) {
    const progress = amountPaid >= amountTotal ? 100 : Math.round((amountPaid * 100) / amountTotal);
    const amountToPay = progress === 100 ? 0 : amountTotal - amountPaid;

    return (
        <div className="animated fadeIn">
            <UiFlex justifyContent="space-between">
                <UiFlex spacing={1}>
                    <UiText color="text-secondary">Paid</UiText>
                    <UiText weight={2}>
                        <CurrencyMinor amount={amountPaid} />
                    </UiText>
                </UiFlex>

                <UiFlex spacing={1}>
                    <UiText color="text-secondary">Total</UiText>
                    <UiText weight={2}>
                        <CurrencyMinor amount={amountTotal} />
                    </UiText>
                </UiFlex>
            </UiFlex>

            <PaidProgressWrapper>
                <PaidProgress progress={progress} />
            </PaidProgressWrapper>

            <UiText color="text-secondary" size={-1} weight={2} align="center">
                Remaining{' '}
                <b>
                    <CurrencyMinor amount={amountToPay} />
                </b>
            </UiText>
        </div>
    );
}
