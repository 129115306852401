import React, { useEffect, useState } from 'react';
import { Route } from 'react-router-dom';
import { V2List } from '../components/v2/V2List';
import { V2ListItem } from '../components/v2/V2ListItem';
import { V2PageHeader } from '../components/v2/V2PageHeader';
import { MicroserviceBookX } from '../microservices/bookx';
import { routes, withParams } from '../shared/router';
import { StaffQueueLocationPage } from './StaffQueueLocationPage';
import { UiGrid } from '@orderx/ui';
import { useApiQuery } from '@orderx/http';

export function StaffQueue() {
    return (
        <>
            <Route path={routes.staffQueue} component={StaffQueueChooseLocation} exact />
            <Route path={StaffQueueLocationPage.route} component={StaffQueueLocationPage} />
        </>
    );
}

function StaffQueueChooseLocation({ history }) {
    const [me, setMe] = useState();

    const { run: loadLocations, isLoading } = useApiQuery(async () => setMe(await MicroserviceBookX.getMe()));

    useEffect(() => {
        loadLocations();
    }, []);

    return (
        <>
            <V2PageHeader title="Choose location" onClickBack={() => history.goBack()} isLoading={isLoading} />

            <UiGrid>
                {me?.companies?.map((company) => (
                    <V2List title={company.name}>
                        {company.locations.map((location) => (
                            <V2ListItem
                                to={withParams(StaffQueueLocationPage.route, { locationId: location.id })}
                                key={location.id}
                                label={location.name}
                                iconRight="arrow-right"
                            />
                        ))}
                    </V2List>
                ))}
            </UiGrid>
        </>
    );
}
