import makeStyles from '@material-ui/styles/makeStyles';
import clsx from 'clsx';
import React from 'react';
import { UiLoader } from '../components/ui/UiLoader';
import { restartApp, useCodePush } from '../shared/code-push';
import { UiAlert, UiFlex } from '@orderx/ui';

const useStyles = makeStyles(() => ({
    root: {
        position: 'fixed',
        top: 0,
        left: 0,
        zIndex: 'var(--z-index-above-toolbar)',
        width: '100%',
        padding: 4,
    },
    alert: {
        padding: 2,
    },
    progress: {
        width: 50,
        textAlign: 'center',
        fontWeight: 700,
    },
}));

export function CodePushContainer() {
    const classes = useStyles();
    const { updateLabel, isPending, isUpdateInstalled, progress } = useCodePush();

    // updateLabel = 'We are updating your application';
    // progress = '100/100%';
    //
    // updateLabel = 'Update installed! Click here to restart or re-open the app';
    // progress = '';
    //
    // isUpdateInstalled = true;

    if (!updateLabel) {
        return null;
    }

    return (
        <div className={clsx(classes.root, 'animated fadeInDown')}>
            <UiAlert
                padding={0}
                onClick={() => {
                    if (isUpdateInstalled) {
                        restartApp();
                    }
                }}
                color={isUpdateInstalled ? 'secondary' : 'default'}>
                <UiFlex spacing={1} className={classes.alert}>
                    {isUpdateInstalled ? <div /> : <UiLoader color={'secondary'} size={16} noPadding />}
                    <small>{updateLabel}</small>
                    {progress && <small className={classes.progress}>{progress}</small>}
                </UiFlex>
            </UiAlert>
        </div>
    );
}
