export enum GroupType {
    FoodTruck = 'FOOD_TRUCK',
    Table = 'TABLE',
}

export enum GroupOrderVisibility {
    Public = 'PUBLIC',
    Private = 'PRIVATE',
}

export enum GroupPaymentMode {
    Upfront = 'UPFRONT',
    After = 'AFTER',
}
