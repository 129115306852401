import React from 'react';
import styled from 'styled-components';
import { themeActive } from '../themes/dark';

const Wrapper = styled.div`
    background-color: ${props => (props.active ? themeActive.colors.green : themeActive.colors.red)};
    height: 6px;
    width: 6px;
    border-radius: 6px;
    box-shadow: 0 0 6px ${props => (props.active ? themeActive.colors.green : themeActive.colors.red)};
`;

export function IndicatorActive({ active }) {
    return <Wrapper active={active} />;
}
