import CircularProgress from '@material-ui/core/CircularProgress';
import makeStyles from '@material-ui/core/styles/makeStyles';
import React from 'react';
import styled from 'styled-components';

const useStyles = makeStyles({
    block: {
        padding: 16,
        display: 'flex',
        justifyContent: 'center',
    },
});

const Cover = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    position: ${props => (props.fixed ? 'fixed' : 'absolute')};
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background-color: ${props => props.coverBackground || 'rgba(0, 0, 0, 0.8)'};
    z-index: 10;
`;

export function V2Loader({
    size = 32,
    thickness = 2,
    cover = false,
    coverBackground,
    fixed = false,
    className,
    block = false,
}) {
    const classes = useStyles();

    const spinner = <CircularProgress size={size} color="inherit" thickness={thickness} />;

    if (block) {
        return <div className={classes.block}>{spinner}</div>;
    }

    if (cover) {
        return (
            <Cover className="animated fadeIn" fixed={fixed} coverBackground={coverBackground}>
                {spinner}
            </Cover>
        );
    }

    return spinner;
}
