import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import { useRouter } from '../../../hooks/router';

interface Props {
    routes: (
        | {
              component: any;
              exact?: boolean;
          }
        | any
    )[];
    redirect?: any;
}

export function RouterSwitch({ routes, redirect }: Props) {
    const { replaceParams } = useRouter();

    return (
        <Switch>
            {routes.map((route) => {
                const component = route.route ? route : route.component;
                const path = route.component?.route || route?.route || route?.path;
                const rest = route.route ? {} : route;

                return <Route {...rest} path={path} component={component} key={path} />;
            })}

            {redirect && <Redirect to={replaceParams(redirect.route || redirect)} />}
        </Switch>
    );
}
