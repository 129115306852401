import { Entity, ManyToOne, PrimaryColumn, Column, Index, OneToMany } from './typeorm';
import { BaseModel } from './base-model';
import { InventoryProduct } from './inventory-product';
import { Order } from './order';
import { OrderProductPayment } from './order-product-payment';

export enum OrderProductStatus {
    PLACED = 'PLACED',
    IN_PREPARATION = 'IN_PREPARATION',
    READY = 'READY',
    IN_DELIVERY = 'IN_DELIVERY',
    DELIVERED = 'DELIVERED',
    REJECTED = 'REJECTED',
}

@Entity()
export class OrderProduct extends BaseModel {
    @Column()
    priceFinal: number;

    @Column({ nullable: true })
    priceInitial: number;

    @Column({ default: OrderProductStatus.PLACED })
    @Index()
    status: OrderProductStatus;

    @Column({ type: 'text', nullable: true })
    comment: string;

    @ManyToOne(() => InventoryProduct, (model) => model.inventoryProducts)
    product: InventoryProduct;
    @Column()
    productId: number;

    @ManyToOne(() => Order, (model) => model.orderProducts)
    order: Order;
    @Column()
    orderId: number;

    @OneToMany(() => OrderProductPayment, (model) => model.orderProduct)
    orderProductPayments: OrderProductPayment[];
}
