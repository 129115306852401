import { useEffect } from 'react';
import { createStore, useStore } from '../create-store';

const store = createStore({});

window.mediaStore = store;

const breakpoints = {
    phoneMax: 767,
    tabletMax: 1200,
    desktopMax: 1440,
};

export const mediaQuery = {
    isPhone: `(max-width: ${breakpoints.phoneMax}px)`,
    isTablet: `(min-width: ${breakpoints.phoneMax + 1}px) and (max-width: ${breakpoints.tabletMax}px)`,
    isLaptop: `(min-width: ${breakpoints.tabletMax + 1}px)and (max-width: ${breakpoints.desktopMax}px)`,
    isDesktop: `(min-width: ${breakpoints.desktopMax + 1}px)`,
};

export function useMedia() {
    const [{ isPhone, isTable, isLaptop, isDesktop }] = useStore(store);
    return { isPhone, isTable, isLaptop, isDesktop };
}

export function useSubscribeToMediaQueryChanges() {
    useEffect(() => {
        store.set(getMedia());

        for (const key of Object.keys(mediaQuery)) {
            window.matchMedia(mediaQuery[key]).addListener(() => {
                store.set(getMedia());
            });
        }
    }, []);
}

function getMedia() {
    return Object.keys(mediaQuery).reduce((media, key) => {
        media[key] = window.matchMedia(mediaQuery[key]).matches;
        return media;
    }, {});
}
