import makeStyles from '@material-ui/core/styles/makeStyles';
import clsx from 'clsx';
import React from 'react';
import { themeActive } from '../../themes/dark';
import { CurrencyMinor } from '../CurrencyMinor';
import { UiFlex } from '@orderx/ui';

const useStyles = makeStyles({
    discountContainer: {
        position: 'relative',
    },
    previous: {
        textDecoration: 'line-through',
        color: themeActive.colors.faded,
    },
    discountLabel: {
        position: 'absolute',
        top: -12,
        whiteSpace: 'nowrap',
        right: 0,
        fontSize: 12,
        color: themeActive.colors.success,
        fontWeight: 700,
    },
    rowDiscounted: {
        marginLeft: 4,
        marginRight: 8,
        color: 'white',
    },
    rowDiscountLabel: {
        whiteSpace: 'nowrap',
        right: 0,
        fontSize: 12,
        backgroundColor: themeActive.colors.success,
        fontWeight: 700,
        padding: 4,
        borderRadius: 4,
    },
});

export function ProductPriceLabel({ product, displayRow }) {
    const classes = useStyles();
    const { priceIncludingVat, priceIncludingVatInitial, discountPercent } = product;

    if (discountPercent) {
        if (displayRow) {
            return (
                <span>
                    <span className={classes.previous}>
                        <CurrencyMinor amount={priceIncludingVatInitial} />
                    </span>

                    <b className={classes.rowDiscounted}>
                        <CurrencyMinor amount={priceIncludingVat} />
                    </b>

                    <span className={classes.rowDiscountLabel}>
                        -{discountPercent}% {product.discount.name || ''}
                    </span>
                </span>
            );
        }

        return (
            <UiFlex justifyContent={'flex-end'} spacing={1}>
                <div className={clsx(product.discount && classes.previous)}>
                    <CurrencyMinor amount={priceIncludingVatInitial} />
                </div>

                <div className={classes.discountContainer}>
                    <div className={classes.discountLabel}>
                        -{discountPercent}% {product.discount.name || ''}
                    </div>

                    <b>
                        <CurrencyMinor amount={priceIncludingVat} />
                    </b>
                </div>
            </UiFlex>
        );
    }

    // TODO: DEPRECATED
    if (product.discount) {
        return (
            <UiFlex justifyContent={'flex-end'} spacing={1}>
                <div className={clsx(product.discount && classes.previous)}>
                    <CurrencyMinor amount={priceIncludingVatInitial} />
                </div>

                <div className={classes.discountContainer}>
                    <div className={classes.discountLabel}>
                        -{product.discount.percent}% {product.discount.name || ''}
                    </div>

                    <b>
                        <CurrencyMinor amount={priceIncludingVat} />
                    </b>
                </div>
            </UiFlex>
        );
    }

    return <CurrencyMinor amount={priceIncludingVat} />;
}
