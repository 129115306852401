import clsx from 'clsx';
import React, { useState } from 'react';
import './ProductCard.scss';
import { UiText, UiFlex, UiBox } from '@orderx/ui';
import { Currency } from '../../../../components/Currency';
import { IncrementDecrement } from '../../../../components/IncrementDecrement';

interface Props {
    image?: any;
    name?: any;
    description?: any;
    price?: any;
    onIncrement?: any;
    onDecrement?: any;
    count?: any;
    countMax?: any;
    disabled?: any;
    currency?: any;
    hasOptions?: any;
    children?: any;
}

export function ProductCard({
    image,
    name,
    description = '',
    price,
    onIncrement,
    onDecrement,
    count,
    countMax = undefined,
    disabled = false,
    currency,
    hasOptions = true,
    children = null,
}: Props) {
    const [isDescriptionVisible, setIsDescriptionVisible] = useState(false);
    const isIconPackCocktail = image?.includes('icon-pack-cocktail');
    const isAsset = isIconPackCocktail || image?.includes('orderx-images');

    //if (name === 'Burger') {
    //    description =
    //        "Thick-sliced turkey and melted provolone cheese stacked on a ciabatta roll, then topped with our signature basil pesto and dry-roasted red peppers. So tasty, you'll want seconds, but so satisfying, you won't need them. Turkeys are raised without the use of antibiotics.";
    //}
    //
    //if (name === 'Salad') {
    //    description =
    //        'Grilled chicken tossed with a medium-spiced chile vinaigrette for a lively but not over-the-top kick and mixed with fresh greens, tomatoes, roasted corn, black beans, jicama, paprika and cotija. Chickens are raised without the use of antibiotics.';
    //}

    if (isAsset) {
        image = image.split('/').slice(-1)[0].replace('.png', '');
    }

    return (
        <div className={clsx('ProductCard', isAsset && `isAsset`, count > 0 && 'hasItems')}>
            <UiFlex className="card-content">
                {isAsset && (
                    <a onClick={() => onIncrement(1)} className="icon-container">
                        <div
                            className={clsx(isAsset && `product-icon ${image}`)}
                            style={isAsset ? {} : { backgroundImage: `url(${image})` }}
                        />
                    </a>
                )}

                <UiFlex direction="column" alignItems="flex-start" justifyContent="space-between" fullWidth>
                    <div>
                        <UiText size={1} weight={2}>
                            {name}
                        </UiText>

                        {description && (
                            <UiBox
                                onClick={() => setIsDescriptionVisible(!isDescriptionVisible)}
                                paddingBottom={1}
                                paddingRight={4}>
                                <UiText
                                    className={clsx('description', isDescriptionVisible && 'visible')}
                                    color="text-secondary">
                                    {description}
                                </UiText>
                            </UiBox>
                        )}

                        {!description && (
                            <UiText className="price" weight={2}>
                                <Currency amount={price} currency={currency} />
                            </UiText>
                        )}
                    </div>

                    {description && (
                        <UiBox>
                            <UiText className="price" weight={2}>
                                <Currency amount={price} currency={currency} />
                            </UiText>
                        </UiBox>
                    )}
                </UiFlex>

                {image && !isAsset && (
                    <a onClick={() => onIncrement(1)} className={clsx('image')}>
                        <div
                            className={clsx(isAsset && `product-icon ${image}`)}
                            style={isAsset ? {} : { backgroundImage: `url(${image})` }}
                        />
                    </a>
                )}

                {hasOptions && (
                    <div className={clsx('options-container')}>
                        <IncrementDecrement
                            count={count}
                            onIncrement={onIncrement}
                            onDecrement={onDecrement}
                            disabled={disabled}
                            countMax={countMax}
                        />
                    </div>
                )}
            </UiFlex>

            {children}
        </div>
    );
}
