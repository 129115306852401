import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { getScrollListener } from '../shared/browser';
import { getUrlOffset, storeOffset } from '../stores/url-offsets';

let debouncedScroller = undefined;

export function ScrollToTop({ children }) {
    const { pathname } = useLocation();

    useEffect(() => {
        updateDebounceScroller();
    }, []);

    useEffect(() => {
        updateDebounceScroller();
        const previousTop = getUrlOffset(pathname);
        window.scrollTo(0, previousTop);
    }, [pathname]);

    function updateDebounceScroller() {
        if (debouncedScroller) {
            window.onscroll = undefined;
            debouncedScroller.cancel();
        }

        debouncedScroller = getScrollListener(top => {
            storeOffset(pathname, top);
        });

        window.onscroll = debouncedScroller;
    }

    return children;
}
