import { Entity, Column, ManyToOne, PrimaryColumn } from './typeorm';
import { InventoryInventory } from './inventory-inventory';
import { InventoryProduct } from './inventory-product';

@Entity()
export class InventoryInventoryProduct {
    @ManyToOne(() => InventoryInventory, (model) => model.inventoryProducts)
    inventory: InventoryInventory;
    @PrimaryColumn()
    inventoryId: number;

    @ManyToOne(() => InventoryProduct, (model) => model.inventoryProducts)
    product: InventoryProduct;
    @PrimaryColumn()
    productId: number;

    @Column({ default: 0 })
    expectedUnitAmount: number;

    @Column({ nullable: true })
    lastContainerPurchasePrice: number;
}
