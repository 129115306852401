import keyBy from 'lodash/keyBy';
import { createStore, useStore } from '../create-store';
import { getStaffBarProducts } from '../microservices/legacy-app';
import { storage, storageRemove } from '../shared/storage';

const StorageKey = 'locationProducts';
storageRemove(StorageKey);
const store = createStore(storage.get(StorageKey) || {});

window.locationProducts = store;

function getInitialState(locationId) {
    return {
        id: locationId,
        products: [],
        productsById: {},
    };
}

export function useLocationProducts(locationId) {
    const [locationProducts] = useStore(store);
    const { products } = locationProducts[locationId] || getInitialState(locationId);
    return products;
}

export async function loadLocationProducts(locationId) {
    try {
        setLocationProducts(locationId, await getStaffBarProducts(locationId));
    } catch (error) {
        console.error(error);
    }
}

export function setLocationProducts(locationId, products) {
    serialize({
        ...store.state,
        [locationId]: toState(locationId, products),
    });
}

function toState(locationId, products) {
    return {
        products,
        productsById: keyBy(products, product => product.id),
    };
}

function serialize(state) {
    // storage.set(StorageKey, state);
    store.set(state);
}
