import { Entity, Column, ManyToOne, PrimaryColumn } from './typeorm';
import { BaseModel } from './base-model';
import { InventoryProduct } from './inventory-product';
import { InventoryCountingReport } from './inventory-counting-report';

@Entity()
export class InventoryCountingReportProduct extends BaseModel {
    @ManyToOne(() => InventoryCountingReport, (model) => model.products)
    countingReport: InventoryCountingReport;
    @PrimaryColumn()
    countingReportId: number;

    @ManyToOne(() => InventoryProduct, (model) => model.countingReportProducts)
    product: InventoryProduct;
    @PrimaryColumn()
    productId: number;

    @Column()
    unitAmountBefore: number;

    @Column()
    unitAmountAfter: number;

    @Column()
    unitAmountCorrection: number;
}
