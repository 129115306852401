import React, { useState } from 'react';
import { UiIconMaterial, UiOverlay, UiButton, UiFlex, UiGrid, UiText } from '@orderx/ui';
import { BottomTab } from '../../../BottomTab';
import { useCovidRegistration } from '../../../../stores/covid-registration';
import styled from 'styled-components';

const WrapperButton = styled(UiButton)`
    flex: 1;
    padding: 0 !important;

    .content {
        display: flex;
        align-items: center;
        flex-direction: column;
        justify-content: center;
    }

    label {
        text-transform: uppercase;
        font-size: 0.5rem;
        letter-spacing: 1px;
        font-weight: bold;
        margin-top: 4px;
    }
`;

const Wrapper = styled(UiFlex)`
    .register-success .UiIconMaterial {
        font-size: 64px;
    }
`;

export function CustomerBottomNavCovid() {
    const { lastRegistrationDate, lastRegistration } = useCovidRegistration();
    const { venueName } = lastRegistration;
    const [isOpen, setIsOpen] = useState(false);

    return (
        <>
            <WrapperButton onClick={() => setIsOpen(true)} color="success">
                <div className="content">
                    <UiIconMaterial>verified_user</UiIconMaterial>
                    <label>COVID</label>
                </div>
            </WrapperButton>

            {isOpen && (
                <UiOverlay>
                    <Wrapper fullWidth fullHeight justifyContent="center" padding={4}>
                        <UiGrid className="animated fadeIn" gap={4}>
                            <UiGrid>
                                <UiText color="success" align="center" className="register-success">
                                    <UiGrid>
                                        <UiIconMaterial>check_circle</UiIconMaterial>

                                        <div>
                                            <UiText size={4}>You are checked into:</UiText>
                                            <UiText size={6} weight={2}>
                                                {venueName}
                                            </UiText>
                                        </div>
                                    </UiGrid>
                                </UiText>

                                <UiText align="center" size={2}>
                                    <div>Last check-in time:</div>
                                    <div>{lastRegistrationDate?.format('DD/MM/YYYY HH:mm') ?? '-'}</div>
                                </UiText>
                            </UiGrid>

                            <UiButton onClick={() => setIsOpen(false)} variant="filled" size={1}>
                                Close
                            </UiButton>
                        </UiGrid>
                    </Wrapper>
                </UiOverlay>
            )}
        </>
    );
}
