export const Entity = patched;
export const Column = patched;
export const AfterInsert = patched;
export const BeforeInsert = patched;
export const Index = patched;

export const OneToOne = patched;
export const OneToMany = patched;
export const ManyToOne = patched;
export const ManyToMany = patched;

export const CreateDateColumn = patched;
export const PrimaryColumn = patched;
export const PrimaryGeneratedColumn = patched;
export const UpdateDateColumn = patched;
export const JoinColumn = patched;
export const JoinTable = patched;
export const TreeChildren = patched;
export const TreeParent = patched;
export const AfterLoad = patched;

//eslint-disable-next-line
function patched(a?: any, b?: any, c?: any): any {
    //eslint-disable-next-line
    return function (target: any): any {};
}
