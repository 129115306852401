import { createStores, useStore, getStoreValue } from './create-store';
import { isNative } from './shared/device';
import { routes } from './shared/router';
import { storageGet } from './shared/storage';

export const initialStateStaff = () => ({
    bar: undefined,
    barId: undefined,
    venue: undefined,
    venueId: undefined,
    location: undefined,
    locationId: undefined,
    balanceGroupById: {},
    barById: {},
    barProductsById: {},
    venues: [],
});

export const stores = createStores({
    isNative: isNative(),
    user: {
        profile: undefined,
        userBalances: undefined,
    },
    locations: [],
    venues: [],
    venueById: {},
    barById: {},
    customer: {
        venue: undefined,
        venueId: undefined,
    },
    staff: initialStateStaff(),
    apiJwtToken: undefined,
    venueIdFixed: storageGet('rdxVenueIdFixed'),
    isDev: !!(storageGet('rdxDev') || storageGet('dev')) || !!process.env.REACT_APP_DEV,
    app: {
        version: '0.0.0',
    },
    customerTabs: {
        browse: routes.customerBrowseVenues,
    },
    nfc: {
        scanOpen: false,
        scanning: false,
    },
    device: {
        id: undefined,
        isIos: false,
        isAndroid: false,
        imei: undefined,
        mac: undefined,
    },
});

window.stores = stores;

export { useStore, getStoreValue };
