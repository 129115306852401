import { createStore, useStore } from '../create-store';
import { storage } from '../shared/storage';

const StorageKey = 'staffVenues';
const store = createStore(storage.get(StorageKey) || []);

export function useStaffVenues() {
    const [venues] = useStore(store);
    return venues || [];
}

export function useStaffVenuesRole() {
    const venues = useStaffVenues();

    return {
        hasRole: (locationId, role) => {
            for (const venue of venues) {
                const hasRoleAtVenue = venue.roles.includes(role);

                if (venue.id === locationId) {
                    return hasRoleAtVenue;
                }

                for (const bar of venue.bars) {
                    if (bar.id === locationId) {
                        return hasRoleAtVenue || bar.roles.includes(role);
                    }
                }
            }

            return false;
        },
    };
}

export function setStaffVenues(venues = []) {
    store.set(venues);
    storage.set(StorageKey, venues);
}
