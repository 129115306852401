import React, { useEffect, useState } from 'react';
import { endOfToday, startOfIsoWeek, startOfMonth, startOfToday } from '../shared/moment';
import { DatepickerFromTo } from './DatepickerFromTo';
import { Tabs } from './Tabs';
import { V2Grid } from './v2/V2Grid';

const timeFilters = [
    {
        label: 'Today',
        from: startOfToday(),
        to: endOfToday(),
    },
    {
        label: 'This week',
        from: startOfIsoWeek(),
        to: endOfToday(),
    },
    {
        label: 'This month',
        from: startOfMonth(),
        to: endOfToday(),
    },
    {
        label: 'Custom',
        from: startOfIsoWeek(),
        to: endOfToday(),
    },
];

export function DatepickerWithFilters({ onChangeFrom, onChangeTo }) {
    const [from, setFrom] = useState(timeFilters[0].from);
    const [to, setTo] = useState(timeFilters[0].to);
    const [timeFilter, setTimeFilter] = useState(timeFilters[0]);

    useEffect(
        () => {
            setFrom(timeFilter.from);
            setTo(timeFilter.to);
        },
        [timeFilter],
    );

    useEffect(
        () => {
            if (from) {
                onChangeFrom(from);
            }
        },
        [from],
    );

    useEffect(
        () => {
            if (to) {
                onChangeTo(to);
            }
        },
        [to],
    );

    return (
        <V2Grid gutter={16}>
            <Tabs
                tabs={timeFilters.map((filter, i) => ({
                    key: i,
                    label: filter.label,
                    value: filter,
                }))}
                value={timeFilter}
                onChange={setTimeFilter}
            />

            {timeFilter.label === 'Custom' && (
                <DatepickerFromTo from={from} to={to} onChangeFrom={setFrom} onChangeTo={setTo} />
            )}
        </V2Grid>
    );
}
