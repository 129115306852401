import { useState } from 'react';
import { storageGet, storageSet } from '../shared/storage';

export function useStateStorage(key, initialValue) {
    const [value, setValue] = useState(storageGet(key) || initialValue);

    return [
        value,
        value => {
            storageSet(key, value);
            setValue(value);
        },
    ];
}
