import IconPrint from '@material-ui/icons/PrintRounded';
import React from 'react';
import { printPosReceipt } from '../../microservices/orderx';
import { ORDER_STATUS } from '../../shared/orders';
import { useSnackbar } from '../../shared/snackbar';
import { useCreateOrderHistory } from '../../stores/create-order-history';
import { useApiQuery } from '@orderx/http';
import { UiButton } from '../../@deprecated/UiButton';

export function StaffCreateHeaderPrint() {
    const { showSuccess } = useSnackbar();
    const { getLatestFinalizedOrder } = useCreateOrderHistory();
    const order = getLatestFinalizedOrder();
    const canPrint = Boolean(order && order.Id && order.VenueId && order.Status === ORDER_STATUS.FINALIZED);

    const { run: print, isLoading: isPrinting } = useApiQuery(async (order) => {
        await printPosReceipt({
            orderId: order.Id,
            venueId: order.VenueId,
        });

        showSuccess('Printing receipt...');
    });

    if (!canPrint) {
        return null;
    }

    return <UiButton onClick={() => print(order)} isLoading={isPrinting} icon={<IconPrint />} />;
}
