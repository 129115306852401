import React, { Fragment } from 'react';
import styled from 'styled-components';

const Wrapper = styled.div`
    position: relative;
    animation-duration: 200ms;
`;

const Placeholder = styled.div`
    height: ${props => props.theme.sizes.headerHeight}px;
`;

const Content = styled.div`
    height: 100%;
`;

export function Page({ header, footer, children }) {
    return (
        <Fragment>
            {header && <Placeholder className="HeaderPlaceholder" />}

            <Wrapper className="Page animated fadeIn">
                <Content className="PageContent">{children}</Content>
            </Wrapper>

            {footer && <Placeholder className="FooterPlaceholder" />}
        </Fragment>
    );
}
