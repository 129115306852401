import React, { useState } from 'react';
import styled from 'styled-components';
import { Button } from './Button';
import { NfcButton } from './NfcButton';
import { QrScanner } from './QrScanner';
import { Svg } from './Svg';
import { V2Loader } from './v2/V2Loader';

const Wrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-flow: column;
    text-align: center;

    height: 100%;
    padding: 6rem 2rem;
    width: 100%;
    position: fixed;
    top: 0;
    left: 0;
`;

const Content = styled.div`
    align-items: center;
    display: flex;
    flex-flow: column;
    justify-content: center;
`;

const Placeholder = styled.div`
    height: ${(props) => props.theme.sizes.buttonHeight}px;
`;

const SvgText = styled.div`
    margin-top: 32px;
    text-transform: uppercase;
    letter-spacing: 1px;

    b {
        color: ${(props) => props.theme.colors.primary};
    }
`;

export const MODE = {
    NFC: 'nfc',
    QR: 'qr',
};

export function ScanPage({ isLoading, defaultMode = MODE.NFC, onTagRead }) {
    const [mode, setMode] = useState(defaultMode);

    const isNfc = mode === MODE.NFC;
    const isQr = mode === MODE.QR;

    const iconSize = 192;
    const icon = isNfc ? 'nfc-scan' : 'qr-scan';

    return (
        <Wrapper className="ScanPage">
            <Placeholder>
                {isNfc && <Button onClick={() => setMode(MODE.QR)}>Switch to QR</Button>}
                {isQr && <Button onClick={() => setMode(MODE.NFC)}>Switch to NFC</Button>}
            </Placeholder>

            <Content>
                {isLoading && <V2Loader size={iconSize} thickness={0.5} />}
                {!isLoading && <Svg icon={icon} size={iconSize} className="animated fadeIn" />}

                {isNfc && (
                    <SvgText>
                        <b>Tap</b> an OrderX <b>NFC tag</b>
                    </SvgText>
                )}

                {isQr && (
                    <SvgText>
                        <b>Scan</b> an OrderX <b>QR code</b>
                    </SvgText>
                )}
            </Content>

            <Placeholder>
                {isQr && <QrScanner onReceivedQr={(value) => onTagRead(value, MODE.QR)} />}
                {isNfc && <NfcButton onTagRead={({ id }) => onTagRead(id, MODE.NFC)} />}
            </Placeholder>
        </Wrapper>
    );
}

export default ScanPage;
