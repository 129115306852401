import { storage } from '../shared/storage';
import { createStore, getStoreValue, useStore } from '../create-store';

const StorageKey = 'apiJwtToken';
const store = createStore(storage.get(StorageKey));

export function getApiJwtToken() {
    return getStoreValue(store);
}

export function setApiJwtToken(token) {
    store.set(token);
    storage.set(StorageKey, token);
}

export function useApiJwtToken() {
    return useStore(store);
}
