import React, { useState } from 'react';
import styled from 'styled-components';
import { Input } from '../components/Input';
import { InputLabel } from '../components/InputLabel';
import { NfcButton } from '../components/NfcButton';
import { Select } from '../components/Select';
import { UiSwitch } from '../components/ui/UiSwitch';
import { V2Grid } from '../components/v2/V2Grid';
import { V2PageHeader } from '../components/v2/V2PageHeader';
import { MicroserviceClients } from '../microservices/clients';
import { showSuccess } from '../shared/toasts';
import { translate } from '../shared/translate';
import { useActiveClient } from '../stores/client';
import { useApiQuery } from '@orderx/http';
import { isNumber } from '../util/is-number';

const Wrapper = styled.div`
    padding: 1rem;
`;

const Info = styled.h3`
    text-align: center;
`;

const SelectWrapper = styled.div`
    margin-bottom: 1rem;
`;

const Action = {
    Update: 'UPDATE',
    AddAmount: 'ADD_AMOUNT',
    SetLimit: 'SET_LIMIT',
    IncreaseLimit: 'INCREASE_LIMIT',
};

export function StaffClientsLinkWristbands() {
    const [{ id: clientId, companyName, locationId }] = useActiveClient();

    const [action, setAction] = useState(Action.Update);
    const [count, setCount] = useState();
    const [spendLimit, setSpendLimit] = useState();
    const [tags, setTags] = useState('');
    const [active, setActive] = useState(true);

    const { isLoading: isUpdating, run: updateWristband } = useApiQuery({
        query: (wristbandId) =>
            MicroserviceClients.updateWristband({
                clientId,
                wristbandId,
                count: isNumber(count) && Number(count),
                spendLimit: isNumber(spendLimit) && Number(spendLimit),
                tags,
                active,
                locationId,
                action,
            }),
        onSuccess: () => showSuccess(translate('Wristband updated')),
    });

    return (
        <>
            <V2PageHeader title={companyName} isLoading={isUpdating} />

            <V2Grid>
                <Wrapper>
                    <SelectWrapper>
                        <InputLabel>{translate('Choose action')}</InputLabel>
                        <Select
                            bottom
                            value={action}
                            onChange={setAction}
                            options={[
                                {
                                    label: translate('Associate wristband'),
                                    value: Action.Update,
                                },
                                {
                                    label: translate('Add amount'),
                                    value: Action.AddAmount,
                                },
                                {
                                    label: translate('Increase limit'),
                                    value: Action.IncreaseLimit,
                                },
                                {
                                    label: translate('Set limit'),
                                    value: Action.SetLimit,
                                },
                            ]}
                        />
                    </SelectWrapper>

                    {[Action.Update, Action.AddAmount].includes(action) && (
                        <div>
                            <InputLabel>{translate('Amount')}</InputLabel>
                            <Input
                                value={count || ''}
                                onChangeValue={setCount}
                                number
                                disabled={isUpdating}
                                placeholder={translate('Amount')}
                            />
                        </div>
                    )}

                    {[Action.Update, Action.SetLimit, Action.IncreaseLimit].includes(action) && (
                        <div>
                            <InputLabel>{translate('Spend limit')}</InputLabel>
                            <Input
                                value={spendLimit || ''}
                                onChangeValue={setSpendLimit}
                                number
                                disabled={isUpdating}
                                placeholder={translate('Limit')}
                            />
                        </div>
                    )}

                    {[Action.Update].includes(action) && (
                        <div>
                            <InputLabel>{translate('Tags')}</InputLabel>
                            <Input
                                value={tags || ''}
                                onChangeValue={setTags}
                                disabled={isUpdating}
                                placeholder={translate('Tags (separate multiple with comma)')}
                            />
                        </div>
                    )}

                    {action === Action.Update && (
                        <div>
                            <UiSwitch
                                label={translate('Active')}
                                checked={active}
                                onChange={setActive}
                                disabled={isUpdating}
                            />
                        </div>
                    )}
                </Wrapper>

                <div>
                    <Info>{translate('Tap wristband to update')}</Info>
                </div>

                <NfcButton onTagRead={({ id }) => updateWristband(id)} />
            </V2Grid>
        </>
    );
}
