import makeStyles from '@material-ui/core/styles/makeStyles';
import clsx from 'clsx';
import React, { useEffect } from 'react';
import { useIotTerminalOrderPlaced } from '../../hooks/iot/terminal-order-placed';
import { useAwsIotSubscription } from '../../providers/aws-iot';
import { ORDER_STATUS } from '../../shared/orders';
import { themeActive } from '../../themes/dark';
import { UiButton } from '../../@deprecated/UiButton';

const useStyles = makeStyles(() => ({
    StaffCreateTerminalSliderButton: ({ size }) => ({
        height: size,
        minHeight: size,
        width: size,
        minWidth: size,
        borderRadius: '100%',
        marginRight: 4,
        overflow: 'hidden',
    }),
    [ORDER_STATUS.PAYMENT_WAITING]: {
        backgroundColor: themeActive.colors.yellow,
        color: themeActive.colors.textReversed,
        '&:hover': {
            backgroundColor: themeActive.colors.yellow,
            color: themeActive.colors.textReversed,
        },
    },
    [ORDER_STATUS.FINALIZED]: {
        backgroundColor: themeActive.colors.success,
        '&:hover': {
            backgroundColor: themeActive.colors.success,
        },
    },
    [ORDER_STATUS.PAYMENT_SUCCESS]: {
        backgroundColor: themeActive.colors.success,
        '&:hover': {
            backgroundColor: themeActive.colors.success,
        },
    },
    [ORDER_STATUS.PAYMENT_CANCELLED]: {
        backgroundColor: themeActive.colors.error,
        '&:hover': {
            backgroundColor: themeActive.colors.error,
        },
    },
    [ORDER_STATUS.PAYMENT_FAILED]: {
        backgroundColor: themeActive.colors.error,
        '&:hover': {
            backgroundColor: themeActive.colors.error,
        },
    },
}));

export function StaffCreateTerminalSliderButton({ compact, terminal, order, onClick, onUpdateOrder, onRemoveOrderId }) {
    const classes = useStyles({ size: (themeActive.tabs.height - 8) * (compact ? 1 : 1.5) });
    const orderId = order && order.Id;
    const orderStatus = order && order.Status;
    const terminalId = terminal.id;
    const finalizedOrderId = Boolean(order && order.Status === ORDER_STATUS.FINALIZED) && orderId;

    function handleOrder(order) {
        order.Datetime = order.Datetime || Date.now();
        onUpdateOrder(order);
    }

    useIotTerminalOrderPlaced(terminalId, handleOrder);
    useAwsIotSubscription(terminalId && `pos/status/${terminalId}`, handleOrder);
    useAwsIotSubscription(orderId && `order-update/${orderId}`, handleOrder);

    useEffect(() => {
        if (finalizedOrderId) {
            const timeout = setTimeout(() => {
                onRemoveOrderId(finalizedOrderId);
            }, 10000);

            return () => {
                clearTimeout(timeout);
                onRemoveOrderId(finalizedOrderId);
            };
        }
    }, [finalizedOrderId]);

    return (
        <>
            <UiButton
                onClick={() => {
                    onClick();
                }}
                className={clsx(classes.StaffCreateTerminalSliderButton, classes[orderStatus])}
                variant={'contained'}>
                {terminal.name || terminal.id}
            </UiButton>
        </>
    );
}
