import React from 'react';
import { UiGrid, UiText, UiBox } from '@orderx/ui';

interface Props {
    title?: any;
    children?: any;
}

export function List({ title, children }: Props) {
    return (
        <UiGrid gap={0}>
            <UiBox padding={1}>
                <UiText color="text-secondary" transform="uppercase" weight={2} size={-1}>
                    {title}
                </UiText>
            </UiBox>

            {children}
        </UiGrid>
    );
}
