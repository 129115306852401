import React from 'react';
import styled from 'styled-components';
import { V2Grid } from './V2Grid';

const Wrapper = styled.div`
    padding: 1rem;
`;

export function V2Page({ header, children }) {
    return (
        <Wrapper>
            {header}
            <V2Grid>{children}</V2Grid>
        </Wrapper>
    );
}
