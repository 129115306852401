import React from 'react';
import { CreateOrder } from '../../modules/create-order';
import { addOrderProducts, removeOrderProducts } from '../../stores/create-order-products';
import { ProductListItem } from '../product/list-item/ProductListItem';

export function StaffCreateProductListItem({ product }) {
    const isTabPending = false;
    const productId = product.id;
    const { productCountById } = CreateOrder.useStateProductId(productId);

    return (
        <ProductListItem
            image={product.icon}
            name={product.name}
            currency={product.currency}
            price={isTabPending ? product.priceIncludingVat / 100 : product.priceIncludingVat}
            count={productCountById[productId]}
            onDecrement={amount => removeOrderProducts(product, amount)}
            onIncrement={amount => addOrderProducts(product, amount)}
        />
    );
}
