import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { MicroserviceOrders } from '../../microservices/orders';
import { useCreateOrderBar } from '../../stores/create-order-bar';
import { updateOrderProducts } from '../../stores/create-order-products';
import { useApiQuery } from '@orderx/http';
import { UiInput } from '../ui/UiInput';
import { UiLoader } from '../ui/UiLoader';
import { UiFlex, UiButton } from '@orderx/ui';

export function StaffCreateActiveOrderDiscount({ products, disabled = false, isLoading = false, onSuccess }) {
    const { barId } = useCreateOrderBar();
    const [discountId, setDiscountId] = useState('');

    const applyDiscount = useCallback(
        (discount) => {
            function resetDiscount(product) {
                const { priceIncludingVatInitial, priceExcludingVatInitial } = product;

                if (priceIncludingVatInitial) {
                    product.priceIncludingVat = priceIncludingVatInitial;
                    product.priceExcludingVat = priceExcludingVatInitial;
                }

                product.priceIncludingVatInitial = undefined;
                product.priceExcludingVatInitial = undefined;

                product.discount = undefined;
                product.discountId = undefined;
                product.discountPercent = undefined;
            }

            for (const product of products) {
                resetDiscount(product);

                if (discount) {
                    const { priceIncludingVat, priceExcludingVat } = product;

                    product.priceIncludingVat = priceIncludingVat - priceIncludingVat * (discount.percent / 100);
                    product.priceIncludingVatInitial = priceIncludingVat;

                    product.priceExcludingVat = priceExcludingVat - priceExcludingVat * (discount.percent / 100);
                    product.priceExcludingVatInitial = priceExcludingVat;

                    product.discount = discount;
                    product.discountId = discount.id;
                    product.discountPercent = discount.percent;
                    product.discountAmount = product.priceIncludingVatInitial - product.priceIncludingVat;
                }
            }

            updateOrderProducts(products);
            onSuccess();
        },
        [products],
    );

    const { isLoading: isLoadingDiscounts, run: loadDiscounts, response } = useApiQuery(async () =>
        MicroserviceOrders.staffApp({
            action: 'FIND_BAR_DISCOUNTS',
            barId,
        }),
    );

    const discounts = useMemo(() => response || [], [response]);

    useEffect(() => {
        loadDiscounts();
    }, []);

    if (!response) {
        return <UiLoader />;
    }

    return (
        <UiFlex fullWidth spacing={1}>
            <UiInput
                label={'Choose discount'}
                value={discountId}
                onChangeValue={setDiscountId}
                options={[
                    {
                        label: 'Remove discount',
                        value: 'remove',
                    },
                    ...discounts.map((discount) => ({
                        label: `-${discount.percent}% ${discount.name}`,
                        value: discount.id,
                    })),
                ]}
            />

            <UiButton
                disabled={disabled || isLoadingDiscounts || !discountId}
                isLoading={isLoading}
                onClick={() => applyDiscount(discounts.find((d) => d.id === discountId))}
                variant="filled"
                color="primary">
                Apply
            </UiButton>
        </UiFlex>
    );
}
