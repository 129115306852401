import React from 'react';
import { UiIconMaterial, UiButton } from '@orderx/ui';
import { useApiQuery } from '@orderx/http';

export function PosCreateHeaderButtonPrintLastOrder() {
    const { run, isLoading } = useApiQuery(async () => {
        await new Promise((r) => setTimeout(r, 1000));
    });

    return (
        <UiButton
            onClick={run}
            isLoading={isLoading}
            icon={<UiIconMaterial>print</UiIconMaterial>}
            iconCircle
            size={1}
        />
    );
}
