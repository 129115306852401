import each from 'lodash/each';

export const routes = {
    roskilde: '/roskilde',

    orderGroup: '/og/:hash',

    menu: '/menu/:barId',
    bar: '/bar/:barId',

    connect: '/connect',

    auth: '/auth',

    customerBrowseVenues: '/customer/browse-venues',
    customerBrowseVenue: `/customer/browse-venues/:venueId`,

    customerScan: '/customer/scan',
    customerProfile: '/customer/profile',
    customerPaymentMethods: '/customer/payment-methods',

    staffCreateOrder: '/staff-create-order/:barId',

    staffBarChoose: '/staff/bar-choose',
    staffBar: '/staff/bar/:barId',

    staffOrderBar: '/staff/order/:barId',
    staffOrderBarOrders: '/staff/order/:barId/orders',
    staffOrderBarConfig: '/staff/order/:barId/config',
    staffOrderBarConfigStation: '/staff/order/:barId/config/station',
    staffOrderBarConfigQueue: '/staff/order/:barId/config/queue',
    staffOrderBarConfigTerminalSlider: '/staff/order/:barId/config/terminal-slider',
    staffOrderBarConfigOrderHistory: '/staff/order/:barId/config/order-history',
    staffOrderBarConfigTableAvailability: '/staff/order/:barId/config/table-availability',
    staffOrderBarCreate: '/staff/order/:barId/create',
    staffOrderBarTables: '/staff/order/:barId/tables',
    staffOrderBarStation: '/staff/order/:barId/station',
    staffOrderBarCreateBeta: '/staff/order/:barId/create-beta',
    // staffOrdering: '/staff/bar-ordering/:barId',

    staffInventory: '/staff/inventory/choose',
    staffInventoryId: '/staff/inventory/:inventoryId/l/:locationId',

    staffBarOrder: '/staff/bar/:barId/orders',
    staffBarOrderCreate: '/staff/bar/:barId/orders-create',
    staffBarOrderRegular: '/staff/bar/:barId/orders/terminal',
    staffBarOrderPre: '/staff/bar/:barId/orders/pre',
    staffBarOrderClosed: '/staff/bar/:barId/orders/closed',

    staffBarScan: '/staff/bar/:barId/scan',

    staffBarSettings: '/staff/bar/:barId/settings',
    staffBarSettingsTerminalsSlider: '/staff/bar/:barId/settings/terminals-slider',
    staffBarSettingsTerminalQueue: '/staff/bar/:barId/settings/terminal-queue',

    staffBarSwish: '/staff/bar/:barId/swish',

    staffBarBalanceGroups: '/staff/bar/:barId/balance-groups',
    staffBarBalanceGroupsList: '/staff/bar/:barId/balance-groups/list',
    staffBarBalanceGroupsDetail: '/staff/bar/:barId/balance-groups/view/:balanceGroupId',

    staffVenueReportingChoose: '/staff/reporting-choose-venue',
    staffVenue: '/staff/venue/:venueId',
    staffVenueReporting: '/staff/venue/:venueId/reporting',
    staffVenueReportingBottomsUp: '/staff/venue/:venueId/reporting/bottoms-up',
    staffVenueReportingStaff: '/staff/venue/:venueId/reporting/staff',
    staffVenueReportingTotalRevenue: '/staff/venue/:venueId/reporting/revenue',

    staffQueue: '/smartq',
    staffQueueLocationQueue: '/smartq/:locationId/queue',
    staffQueueLocationScan: '/smartq/:locationId/scan',
    staffQueueLocationStats: '/smartq/:locationId/stats',

    staffClients: '/staff/clients',
    staffClientsId: '/staff/clients/:clientId',
    staffClientsIdWristbands: '/staff/clients/:clientId/wristbands',
    staffClientsIdLinkWristbands: '/staff/clients/:clientId/link-wristbands',
};

export function withParams(route, params) {
    each(params, (value, key) => (route = route.replace(`:${key}`, value)));
    return route;
}
