import React from 'react';
import { NavLink } from 'react-router-dom';
import styled, { css } from 'styled-components';
import { Svg } from '../Svg';
import { UiText } from '@orderx/ui';

const styles = css`
    background-color: var(--color-background-paper);
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    border: 0;
    text-align: left;

    :focus {
        outline: 0;
    }

    :not(:last-child) {
        border-bottom: 1px solid var(--color-divider);
    }
`;

const Button = styled.a`
    ${styles};
`;

const Link = styled(NavLink)`
    ${styles};
`;

const ContentLeft = styled.div`
    display: flex;
    align-items: center;

    > *:not(:first-child) {
        margin-left: 16px;
    }
`;

const ContentRight = styled.div`
    display: flex;
    margin-left: 16px;
`;

export function V2ListItem({
    icon,
    label,
    title,
    subtitle,
    contentRight,
    to,
    onClick = () => {},
    iconLeft,
    iconRight,
    iconRightFill,
    compact = false,
}) {
    const Component = to ? Link : Button;
    const padding = compact ? '8px 16px' : '16px';

    return (
        <Component to={to} onClick={onClick} style={{ padding }}>
            <ContentLeft>
                {iconLeft}

                {icon && <Svg icon={icon} size={20} />}

                <div>
                    <UiText>{title || label}</UiText>
                    {subtitle && (
                        <UiText color="text-secondary" size={-1} weight={2}>
                            {subtitle}
                        </UiText>
                    )}
                </div>
            </ContentLeft>

            {contentRight && <ContentRight>{contentRight}</ContentRight>}

            {iconRight && (
                <ContentRight>
                    <Svg icon={iconRight} size={20} fill={iconRightFill} />
                </ContentRight>
            )}
        </Component>
    );
}
