import AppBar from '@material-ui/core/AppBar';
import Badge from '@material-ui/core/Badge';
import Tab from '@material-ui/core/Tab';
import MaterialTabs from '@material-ui/core/Tabs';
import React from 'react';

export function Tabs({ tabs = [], value, onChange, ...rest }) {
    const activeIndex = tabs.indexOf(tabs.find(tab => tab.value === value));

    return (
        <AppBar position="static">
            <MaterialTabs
                value={activeIndex}
                variant="scrollable"
                scrollButtons="auto"
                {...rest}
                className={rest.className}>
                {tabs.map((tab, i) => (
                    <Tab
                        key={tab.key || tab.value}
                        disableRipple
                        label={
                            <Badge badgeContent={tab.badge || 0} color="primary">
                                {tab.label}
                            </Badge>
                        }
                        value={i}
                        onClick={() => onChange(tab.value)}
                    />
                ))}
            </MaterialTabs>
        </AppBar>
    );
}
