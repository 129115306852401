import { Entity, Column, ManyToOne, OneToMany } from './typeorm';
import { BaseModel } from './base-model';
import { InventoryAccount } from './inventory-account';
import { InventoryPlugin } from './inventory-plugin';
import { InventoryRecipeProduct } from './inventory-recipe-product';
import { InventoryInventoryProduct } from './inventory-inventory-product';
import { InventoryCountingReportProduct } from './inventory-counting-report-product';
import { InventoryMenuProduct } from './inventory-menu-product';
import { InventoryRecipe } from './inventory-recipe';
import { OrderProduct } from './order-product';

export enum InventoryProductType {
    CONSUMABLE = 'CONSUMABLE',
    SERVICE = 'SERVICE',
}

export enum InventoryProductContainer {
    OTHER = 'OTHER',
    BOTTLE = 'BOTTLE',
    TANK = 'TANK',
    PIECE = 'PIECE',
}

export enum InventoryProductBaseUnit {
    OTHER = 'OTHER',
    OUNCE = 'OUNCE',
    LITRE = 'LITRE',
    CENTILITRE = 'CENTILITRE',
    MILLILITRE = 'MILLILITRE',
}

@Entity()
export class InventoryProduct extends BaseModel {
    @Column()
    name: string;

    @Column({ nullable: true })
    description: string;

    @Column({ nullable: true })
    category: string;

    @Column({ nullable: true })
    subCategory: string;

    @Column({ nullable: true })
    price: number;

    @Column({ nullable: true })
    icon: string;

    @Column({ default: 1 })
    unitAmount: number;

    @Column({ default: InventoryProductType.CONSUMABLE })
    type: InventoryProductType;

    @Column({ nullable: true })
    container: InventoryProductContainer;

    @Column({ nullable: true })
    baseUnit: InventoryProductBaseUnit;

    @ManyToOne(() => InventoryAccount, (model) => model.products)
    account: InventoryAccount;
    @Column({ nullable: true })
    accountId: number;

    @OneToMany(() => OrderProduct, (model) => model.product)
    orderProducts: OrderProduct[];

    @OneToMany(() => InventoryInventoryProduct, (model) => model.product)
    inventoryProducts: InventoryInventoryProduct[];

    @OneToMany(() => InventoryCountingReportProduct, (model) => model.product)
    countingReportProducts: InventoryCountingReportProduct[];

    @OneToMany(() => InventoryRecipeProduct, (model) => model.product)
    recipeProducts: InventoryRecipeProduct[];

    @OneToMany(() => InventoryMenuProduct, (model) => model.product)
    menuProducts: InventoryMenuProduct[];

    @OneToMany(() => InventoryRecipe, (model) => model.product)
    recipes: InventoryRecipe[];

    @ManyToOne(() => InventoryPlugin, (model) => model.products)
    plugin: InventoryPlugin;
    @Column()
    pluginId: number;
}
