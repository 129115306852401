import { useEffect } from 'react';
import { createStore, useStore } from '../create-store';

const storeIsNfcEnabled = createStore(false);
const storeNfcScanValue = createStore();

export function useNfcCallback(callback) {
    const [nfcScanValue] = useStore(storeNfcScanValue);

    useEffect(
        () => {
            if (nfcScanValue) {
                callback(nfcScanValue);
            }
        },
        [nfcScanValue],
    );
}

export function setNfcScanValue(value) {
    storeNfcScanValue.set(value);
}

export function useIsNfcEnabled() {
    const [isNfcEnabled] = useStore(storeIsNfcEnabled);
    return isNfcEnabled;
}

export function initNfc() {
    if (window.nfc) {
        window.nfc.enabled(() => storeIsNfcEnabled.set(true), () => storeIsNfcEnabled.set(false));
    }
}

window.setNfcScanValue = setNfcScanValue;
