import React, { useEffect } from 'react';
import styled from 'styled-components';
import { MicroserviceTags } from '../microservices/tags';
import { GoogleAnalytics } from '../providers/googe-analytics';
import { routes, withParams } from '../shared/router';
import { translate } from '../shared/translate';
import { useApiQuery } from '@orderx/http';
import { SplashScreen } from './splash-screen/SplashScreen';
import { useParams } from 'react-router-dom';
import { UiButton } from '@orderx/ui';
import { TagLocationGroupWithTable } from '../components/tag/location-group-with-table/TagLocationGroupWithTable';
import { CustomerBrowsePage } from './customer/browse/CustomerBrowsePage';
import { CustomerBrowseGroupsHashPage } from './CustomerBrowseGroupsHashPage';
import { TagBarGroup } from '../components/tag/bar-group/TagBarGroup';
import { Discount } from '../components/discount/Discount';
import { TagCovidRegistration } from '../components/tag/covid-registration/TagCovidRegistration';

const WrapperCover = styled.div`
    background-color: var(--color-background-body);
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    display: flex;
    flex-flow: column;
    align-items: center;
    justify-content: space-between;
    padding: 2rem;
    text-align: center;
`;

export function TagPage({ onNotFound }) {
    const { tag } = useParams();

    const { response, run: findTag } = useApiQuery(async (id) => {
        try {
            return await MicroserviceTags.findTag(id);
        } catch (error) {
            return {};
        }
    });

    useEffect(() => {
        if (tag) {
            findTag(tag);
        }

        GoogleAnalytics.event(GoogleAnalytics.Category.Customer, GoogleAnalytics.ActionCustomer.Tag, tag);
    }, [tag]);

    useEffect(() => {
        if (response && !response.type && onNotFound) {
            onNotFound();
        }
    }, [response]);

    function getTargetOrigin() {
        if (window.location.origin.includes('orderx.no')) {
            return `https://appless.no`;
        } else {
            return window.location.origin;
        }
    }

    if (!response) {
        return <SplashScreen />;
    }

    if (response?.type) {
        const { type, value } = response;

        if (type === 'COVID_REGISTRATION') {
            return <TagCovidRegistration tag={response} showAlways />;
        }

        if (type === 'GROUP') {
            const url = withParams(CustomerBrowseGroupsHashPage.route, { hash: value });
            window.location.href = `${getTargetOrigin()}${url}`;
        }

        if (type === 'BOTTOMS_UP') {
            window.location.href = `${getTargetOrigin()}${routes.customerScan}?tag=${value}`;
        }

        if (type === 'LOCATION_GROUP_TO_TABLE') {
            return <TagLocationGroupWithTable tag={response} />;
        }

        if (type === 'BAR_GROUP') {
            return <TagBarGroup tag={response} />;
        }

        if (type === 'APPLY_DISCOUNT' && response.account && response.venue) {
            return <Discount tag={response} />;
        }

        if (type === 'REDIRECT') {
            window.location.href = value;
        }
    }

    return (
        <div>
            <WrapperCover>
                <div />

                <h2>{translate('Nothing interesting found')}</h2>

                <UiButton to={CustomerBrowsePage.route} fullWidth color="primary" variant="filled" size={1}>
                    {translate('Browse venues')}
                </UiButton>
            </WrapperCover>
        </div>
    );
}

TagPage.route = '/tag/:tag';
