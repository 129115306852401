import React, { useEffect } from 'react';
import styled from 'styled-components';
import { BackgroundCoverImage } from '../components/BackgroundCoverImage';
import { Dev } from '../components/Dev';
import { UiIconPosTerminal } from '../components/ui/icon/UiIconPosTerminal';
import { V2CodePush } from '../components/v2/V2CodePush';
import { loadStaffVenues } from '../microservices/legacy-app';
import { loadMe } from '../microservices/me-v1';
import { AppUserRoleRole } from '../models/app-user-role';
import { useAppCenterCrashes } from '../providers/app-center';
import { signOut } from '../shared/aws-amplify';
import { routes, withParams } from '../shared/router';
import { translate } from '../shared/translate';
import { stores, useStore } from '../stores';
import { useStaffVenues } from '../stores/staff-venues';
import { useUser } from '../stores/user';
import { DeliveryPage } from './delivery/DeliveryPage';
import { UiIconMaterial, UiGrid, UiToolbar, UiFlex, UiText } from '@orderx/ui';
import { CustomerBrowsePage } from './customer/browse/CustomerBrowsePage';
import { useDeviceFingerprint } from '../@v2/hooks/device-fingerprint';
import { ToolbarTitle } from '../@v2/components/toolbar/title/ToolbarTitle';
import { ListItem } from '../@v2/components/list/item/ListItem';
import { AdminConfigurationPage } from './admin/configuration/AdminConfigurationPage';
import { useHistory } from 'react-router-dom';
import { DeployedAtLabel } from '../@v2/components/deployed-at-label/DeployedAtLabel';
import { List } from '../@v2/components/list/List';
import { StaffInventoryPage } from '../@v2/pages/staff/inventory/StaffInventoryPage';

const AvatarImage = styled.div`
    background-color: var(--color-background-paper);
    box-shadow: var(--box-shadow);
    height: 120px;
    width: 120px;
    border-radius: 100%;
    position: relative;
    overflow: hidden;

    * {
        font-size: var(--UiText-size-4);
    }
`;

export function CustomerProfilePage() {
    const history = useHistory();
    const { deviceFingerprint } = useDeviceFingerprint();
    const [{ profile }] = useStore(stores.user);
    const { isStaff, isTestUser, hasRole } = useUser();
    const venues = useStaffVenues();
    const { isAvailable, generateTestCrash } = useAppCenterCrashes();
    const { name, phone, image } = profile || {};
    const staffVenues = venues.filter((venue) => venue.roles.includes(AppUserRoleRole.Staff));
    const clientVenues = venues.filter((venue) => venue.roles.includes(AppUserRoleRole.ClientManageWristbands));
    const reportingVenues = venues.filter((venue) => venue.roles.includes(AppUserRoleRole.Reporting));
    const queueStaffVenues = venues.filter((venue) => venue.roles.includes(AppUserRoleRole.QueueStaff));

    useEffect(() => {
        loadStaffVenues();
        loadUser();
    }, []);

    async function loadUser() {
        try {
            await loadMe();
        } catch (error) {
            console.error(error);
        }
    }

    return (
        <>
            <UiToolbar>
                <ToolbarTitle title="Profile" />
            </UiToolbar>

            <UiGrid>
                <div />
                <div />

                <UiGrid>
                    <UiFlex justifyContent="center">
                        <AvatarImage>
                            <UiFlex justifyContent="center" fullHeight>
                                <UiIconMaterial>person</UiIconMaterial>
                                <BackgroundCoverImage image={image} />
                            </UiFlex>
                        </AvatarImage>
                    </UiFlex>

                    <UiText size={4} weight={2} align="center">
                        {name || '-'}
                    </UiText>
                </UiGrid>

                {isStaff && (
                    <List title="Staff">
                        <ListItem
                            iconStart={<UiIconPosTerminal />}
                            title="Point of Sale (POS)"
                            to={withParams(routes.staffOrderBar, { barId: 'choose' })}
                        />

                        <ListItem
                            iconStart={<UiIconMaterial>directions_run</UiIconMaterial>}
                            title="Delivery View"
                            to={DeliveryPage.route}
                        />

                        {hasRole(AppUserRoleRole.Manager) && (
                            <ListItem
                                iconStart={<UiIconMaterial>settings</UiIconMaterial>}
                                title="Configuration"
                                subtitle="Close/Open bars and tables"
                                to={AdminConfigurationPage.route}
                            />
                        )}

                        <ListItem
                            iconStart={<UiIconMaterial>supervised_user_circle</UiIconMaterial>}
                            title={translate('Switch to staff')}
                            to={routes.staffBarChoose}
                        />

                        <ListItem
                            iconStart={<UiIconMaterial>all_inbox</UiIconMaterial>}
                            title="Inventory"
                            subtitle="Add/Show/Hide products"
                            to={StaffInventoryPage.route}
                        />

                        {reportingVenues.length > 0 && (
                            <ListItem
                                iconStart={<UiIconMaterial>bar_chart</UiIconMaterial>}
                                title={translate('Reporting')}
                                to={
                                    reportingVenues.length > 1
                                        ? routes.staffVenueReportingChoose
                                        : withParams(routes.staffVenueReporting, { venueId: reportingVenues[0].id })
                                }
                            />
                        )}

                        {staffVenues.length > 0 && (
                            <ListItem
                                iconStart={<UiIconMaterial>fastfood</UiIconMaterial>}
                                title={translate('Inventory')}
                                to={withParams(routes.staffInventory)}
                            />
                        )}

                        {queueStaffVenues.length > 0 && (
                            <ListItem
                                iconStart={<UiIconMaterial>people</UiIconMaterial>}
                                title={translate('Queue')}
                                to={routes.staffQueue}
                            />
                        )}

                        {clientVenues.length > 0 && (
                            <ListItem
                                iconStart={<UiIconMaterial>people</UiIconMaterial>}
                                title={translate('Clients')}
                                to={withParams(routes.staffClients)}
                            />
                        )}

                        <Dev>
                            <ListItem
                                title={translate('Roskilde')}
                                iconStart={<UiIconMaterial>deck</UiIconMaterial>}
                                onClick={async () => {
                                    await signOut();
                                    history.push(routes.roskilde);
                                }}
                            />

                            {isAvailable && <ListItem title="Generate test crash" onClick={generateTestCrash} />}
                        </Dev>
                    </List>
                )}

                <List title="Account">
                    <ListItem title={translate('Name')} subtitle={name || '-'} />

                    <ListItem title={translate('Phone')} subtitle={phone || '-'} />

                    {/*<ListItem*/}
                    {/*    title={translate('Payment methods')}*/}
                    {/*    iconStart={<UiIconMaterial>credit_card</UiIconMaterial>}*/}
                    {/*    to={routes.customerPaymentMethods}*/}
                    {/*/>*/}

                    {isTestUser && <ListItem title={translate('Fingerprint')} subtitle={deviceFingerprint || '-'} />}

                    <a href="https://www.orderx.no/privacy">
                        <ListItem
                            title={translate('Privacy Policy')}
                            iconStart={<UiIconMaterial>security</UiIconMaterial>}
                        />
                    </a>

                    <ListItem
                        iconStart={<UiIconMaterial>exit_to_app</UiIconMaterial>}
                        title={translate('Logout')}
                        onClick={async () => {
                            await signOut();
                            history.push(CustomerBrowsePage.route);
                        }}
                    />
                </List>

                <DeployedAtLabel />

                <V2CodePush />

                <div />
            </UiGrid>
        </>
    );
}

CustomerProfilePage.route = '/customer/profile';
