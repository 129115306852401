import { storage } from '../shared/storage';
import { createStore, getStoreValue } from '../create-store';

const StorageKey = 'urlOffsets';
const store = createStore(storage.get(StorageKey) || {});

export function getUrlOffset(pathname) {
    return getStoreValue(store)[pathname] || 0;
}

export function storeOffset(pathname, offset) {
    store.set(state => ({
        ...state,
        [pathname]: offset,
    }));
}
