import { useEffect } from 'react';
import { getGroupById } from '../microservices/orders';
import { storage } from '../shared/storage';
import { showError } from '../shared/toasts';
import { createStore, useStore } from '../create-store';
import { useOrdersPlaced } from './order-placed';
import { useDeviceFingerprint } from '../@v2/hooks/device-fingerprint';

const StorageKey = 'orderGroup';
const store = createStore(storage.get(StorageKey) || {});

function getInitialHashState() {
    return {
        isPlacingOrder: false,
        amountTotal: 0,
        amountPaid: 0,
        bar: undefined,
        group: undefined,
        orders: [],
        payments: [],
        products: [],
        productsNotPaid: [],
        productsPaid: [],
        isPaymentProviderEnabledBambora: false,
        isPaymentProviderEnabledVipps: false,
    };
}

export function useOrderGroup(hash) {
    const { deviceFingerprint } = useDeviceFingerprint();
    const { orderIds } = useOrdersPlaced();
    const [orderGroups, setOrderGroups] = useStore(store);
    const state = orderGroups[hash];

    useEffect(() => {
        if (!orderGroups[hash]) {
            setOrderGroups((state) => {
                state[hash] = {
                    isPlacingOrder: false,
                    amountTotal: 0,
                    amountPaid: 0,
                    bar: undefined,
                    group: undefined,
                    orders: [],
                    payments: [],
                    products: [],
                    productsNotPaid: [],
                    productsPaid: [],
                    isPaymentProviderEnabledBambora: false,
                    isPaymentProviderEnabledVipps: false,
                };
            });
        }
    }, [hash]);

    function setState(callback) {
        setOrderGroups((state) => callback(state[hash]));
    }

    async function reload() {
        setState((state) => {
            state.isLoading = true;
        });

        try {
            const response = await getGroupById({
                groupHash: hash,
                orderIds,
                deviceFingerprint,
            });

            setState((state) => {
                Object.assign(state, response);
            });
        } catch (error) {
            showError(error);
        }

        setState((state) => {
            state.isLoading = false;
        });
    }

    return {
        ...state,

        reset() {
            setOrderGroups((state) => {
                state[hash] = getInitialHashState();
            });
        },

        load: reload,

        reload,

        setIsPlacingOrder(isPlacingOrder) {
            setState((state) => {
                state.isPlacingOrder = isPlacingOrder;
            });
        },

        setIsPaying(isPaying) {
            setState((state) => {
                state.isPaying = isPaying;
            });
        },
    };
}
