import once from 'lodash/once';
import React from 'react';
import 'react-app-polyfill/stable';
import 'react-dates/initialize';
import ReactDOM from 'react-dom';
import { BrowserRouter, HashRouter } from 'react-router-dom';
import { ThemeProvider } from 'styled-components';
import { ErrorBoundary } from './components/ErrorBoundry';
import { ScrollToTop } from './components/ScrollToTop';
import App from './components/app/App';
import { GoogleAnalytics } from './providers/googe-analytics';
import * as serviceWorker from './serviceWorker';
import './shared/browser';
import { sync } from './shared/code-push';
import { initDevice, isIos, isNative, loadAppVersion, updateDeviceInfo } from './shared/device';
import { setKeyboardDefaults } from './shared/keyboard';
import { initNfc } from './stores/nfc';
import { themeActive } from './themes/dark';
import { Log } from './util/logger';
import './index.scss';
import { Api } from '@orderx/http';
import { getApiJwtToken } from './stores/api-jwt-token';

const deviceReady = once(() => {
    if ((window as any).isReady) {
        return;
    }

    (window as any).isReady = true;
    Log.dev('index.js', '✨✨✨ Device ready ✨✨✨');

    if (isNative()) {
        if (isIos() && !localStorage.getItem('codePushRestarted')) {
            localStorage.setItem('codePushRestarted', '1');
            (window as any).codePush.restartApplication();
            return;
        }

        localStorage.removeItem('codePushRestarted');

        sync();
        updateDeviceInfo();
        setKeyboardDefaults();
        loadAppVersion();
        initNfc();

        (window as any).StatusBar.styleBlackTranslucent();
        (window as any).StatusBar.backgroundColorByHexString(themeActive.tabs.backgroundColor);
        (navigator as any).splashscreen.hide();
    }

    // Firebase.initialize();
    GoogleAnalytics.initialize();

    initDevice();

    render(App);
});

if ((window as any).cordova) {
    document.addEventListener('deviceready', deviceReady);
} else {
    deviceReady();
}

Api.setAuthorization(getApiJwtToken());

Api.setPrefix(
    process.env.NODE_ENV === 'development'
        ? `${window.location.protocol}//${window.location.hostname}:5001`
        : `https://mm8ts1s5qg.execute-api.eu-west-1.amazonaws.com/prod`,
);

serviceWorker.unregister();

if (module.hot) {
    module.hot.accept('./components/app/App', () => {
        render(require('./components/app/App').default);
    });
}

function render(Component) {
    const Router: any = isNative() ? HashRouter : BrowserRouter;

    ReactDOM.render(
        <ThemeProvider theme={themeActive}>
            <ErrorBoundary>
                <Router>
                    <ScrollToTop>
                        <Component />
                    </ScrollToTop>
                </Router>
            </ErrorBoundary>
        </ThemeProvider>,
        document.getElementById('root'),
    );
}
