import Dialog from '@material-ui/core/Dialog';
import makeStyles from '@material-ui/core/styles/makeStyles';
import React, { useEffect } from 'react';
import { MicroserviceLegacyApp } from '../../microservices/legacy-app';
import { MicroserviceOrders } from '../../microservices/orders';
import { useSnackbar } from '../../shared/snackbar';
import { translate } from '../../shared/translate';
import { useDeviceConfig } from '../../stores/device-config';
import { useStaffOrderingBar } from '../../stores/staff-ordering-bar';
import { useStaffTerminalOrders } from '../../stores/staff-terminal-orders';
import { useStaffTerminalSlider } from '../../stores/staff-terminal-slider';
import { useApiQuery } from '@orderx/http';
import { useStaffOrder } from '../../use/staff-order';
import { UiLoader } from '../ui/UiLoader';

const useStyles = makeStyles({
    paper: {
        backgroundColor: 'transparent',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        textAlign: 'center',
        textTransform: 'uppercase',
        letterSpacing: 1,
    },
    container: {
        backgroundColor: 'rgba(0,0,0,0.35)',
    },
});

export function StaffOrderPlaceQuery({ isManualEntry, onSuccess }) {
    const { showSuccess, showError } = useSnackbar();
    const { config } = useDeviceConfig();
    const { barId } = useStaffOrderingBar();
    const { resetOrder, orderToPlace, setOrderToPlace } = useStaffOrder();
    const classes = useStyles();
    const { putOrder } = useStaffTerminalOrders();
    const { setTerminalOrder } = useStaffTerminalSlider();

    const { run: placeOrder, isLoading: isPlacingOrder } = useApiQuery(async (orderToPlace) => {
        try {
            const isLegacyOrder = Boolean(orderToPlace.tagId);

            if (isLegacyOrder) {
                const { response, PostOrderResponseType } = await MicroserviceLegacyApp.postOrder({
                    barId,
                    tag: {
                        type: orderToPlace.tagType || 'id',
                        value: orderToPlace.tagId,
                    },
                    productsToPay: orderToPlace.products,
                    groupId: orderToPlace.groupId,
                    comment: orderToPlace.comment,
                    manualEntry: isManualEntry,
                    gratitude: config.isGratitudeOn,
                    printSequence: config.isOrderPrintSequenceEnabled,
                });

                if (response.type === PostOrderResponseType.TerminalOrder) {
                    putOrder(response.order);
                    setTerminalOrder(response.terminal.id, response.order);
                }
            } else {
                await MicroserviceOrders.staffApp({
                    action: 'PLACE_ORDER_V2',
                    products: orderToPlace.products,
                    groupId: orderToPlace.groupId,
                    comment: orderToPlace.comment,
                });
            }

            showSuccess(translate('Order has been placed'));
            resetOrder();

            if (onSuccess) {
                onSuccess();
            }
        } catch (error) {
            showError(error);
        }

        setOrderToPlace(undefined);
    });

    useEffect(() => {
        if (orderToPlace) {
            placeOrder(orderToPlace);
        }
    }, [orderToPlace]);

    return (
        <Dialog open={isPlacingOrder} classes={{ paper: classes.paper, container: classes.container }} fullScreen>
            <div>
                <UiLoader color={'secondary'} thickness={2} size={64} />
                <h4>{translate('Placing order...')}</h4>
            </div>
        </Dialog>
    );
}
