import SvgIcon from '@material-ui/core/SvgIcon';
import React from 'react';

export function UiIconPosTerminal(props) {
    return (
        <SvgIcon {...props} height="480pt" viewBox="0 0 480 480" width="480pt">
            <path d="m472 128h-216v-8c-.042969-10.132812-6.445312-19.148438-16-22.527344v-33.472656h16v-24c-.027344-22.082031-17.917969-39.9726562-40-40h-160c-22.082031.0273438-39.972656 17.917969-40 40v57.472656c-9.554688 3.378906-15.9570312 12.394532-16 22.527344v336c0 13.253906 10.746094 24 24 24h208c13.253906 0 24-10.746094 24-24v-120h216c4.417969 0 8-3.582031 8-8v-192c0-4.417969-3.582031-8-8-8zm-8 16v16h-208v-16zm0 48h-208v-16h208zm-224-152v8h-144v-8c-.03125-8.683594-2.914062-17.117188-8.207031-24h128.207031c13.253906 0 24 10.746094 24 24zm-208 0c0-13.253906 10.746094-24 24-24s24 10.746094 24 24v16c0 4.417969 3.582031 8 8 8h136v32h-192zm208 416c0 4.417969-3.582031 8-8 8h-208c-4.417969 0-8-3.582031-8-8v-336c0-4.417969 3.582031-8 8-8h208c4.417969 0 8 3.582031 8 8zm16-136v-112h208v112zm0 0" />
            <path d="m272 272h16v32h-16zm0 0" />
            <path d="m320 272h16v32h-16zm0 0" />
            <path d="m352 272h16v32h-16zm0 0" />
            <path d="m384 272h64v-48h-64zm16-32h32v16h-32zm0 0" />
            <path d="m104 320h48v-48h-48zm16-32h16v16h-16zm0 0" />
            <path d="m40 320h48v-48h-48zm16-32h16v16h-16zm0 0" />
            <path d="m168 320h48v-48h-48zm16-32h16v16h-16zm0 0" />
            <path d="m104 384h48v-48h-48zm16-32h16v16h-16zm0 0" />
            <path d="m40 384h48v-48h-48zm16-32h16v16h-16zm0 0" />
            <path d="m168 384h48v-48h-48zm16-32h16v16h-16zm0 0" />
            <path d="m104 448h48v-48h-48zm16-32h16v16h-16zm0 0" />
            <path d="m40 448h48v-48h-48zm16-32h16v16h-16zm0 0" />
            <path d="m168 448h48v-48h-48zm16-32h16v16h-16zm0 0" />
            <path d="m32 256h192v-128h-192zm16-112h160v96h-160zm0 0" />
            <path d="m64 160h16v16h-16zm0 0" />
            <path d="m96 160h16v16h-16zm0 0" />
            <path d="m122.347656 218.339844 24-24 11.3125 11.3125-24 24.003906zm0 0" />
            <path d="m162.339844 210.34375 24.003906-24 11.3125 11.3125-24 24zm0 0" />
        </SvgIcon>
    );
}
