import React from 'react';
import styled from 'styled-components';

export const SvgIcons = {
    ArrowRight: 'mi-arrow-forward',
    Replay: 'mi-replay',
    Trash: 'mi-delete',
    More: 'mi-more',
    Print: 'print-receipt',
    Receipt: 'mi-receipt',
    List: 'mi-list',
    Close: 'mi-close',
    Checkbox: 'mi-checkbox',
    CheckboxOutline: 'mi-checkbox-outline',
    CheckboxIndeterminate: 'mi-checkbox-indeterminate',
    UnfoldMore: 'mi-unfold-more',
    UnfoldLess: 'mi-unfold-less',
    Order: 'food',
    Exit: 'mi-exit',
    Lock: 'mi-lock',
    LockOpen: 'mi-lock-open',
    Check: 'mi-check',
    Warning: 'mi-warning',
    InDelivery: 'mi-car',
    Link: 'link',
    Qr: 'qr-code',
    Settings: 'mi-settings',
    DeliveryTable: 'delivery-table',
    Add: 'add',
};

const Wrapper = styled.div`
    &,
    svg {
        height: ${({ size = 24 }) => size}px;
        width: ${({ size = 24 }) => size}px;
        min-height: ${({ size = 24 }) => size}px;
        min-width: ${({ size = 24 }) => size}px;
        fill: ${({ positive, fill }) => fill || (positive && 'var(--color-success)') || 'var(--color-text-primary)'};
    }
`;

export function Svg({ icon, fill, size, positive, className, onClick, style }) {
    const svg = require(`!raw-loader!../assets/svgs/${icon}.svg`);

    return (
        <Wrapper
            style={style}
            onClick={onClick}
            icon={icon}
            fill={fill}
            size={size}
            positive={positive}
            className={className}
            dangerouslySetInnerHTML={{ __html: svg }}
        />
    );
}
