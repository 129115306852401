import { Entity, Column, ManyToOne, OneToMany, BeforeInsert } from './typeorm';
import { BaseModel } from './base-model';
import { InventoryInventory } from './inventory-inventory';
import { User } from './user';
import { InventoryPurchaseOrderProduct } from './inventory-purchase-order-product';
import { InventoryPurchaseOrderVendor } from './inventory-purchase-order-vendor';

@Entity()
export class InventoryPurchaseOrder extends BaseModel {
    @Column({ type: 'text', nullable: true })
    description: string;

    @Column()
    date: Date;

    @OneToMany(() => InventoryPurchaseOrderProduct, (model) => model.purchaseOrder)
    products: InventoryPurchaseOrderProduct[];

    @ManyToOne(() => User, (model) => model.inventoryPurchaseOrders)
    user: User;
    @Column()
    userId: number;

    @ManyToOne(() => InventoryInventory, (model) => model.inventoryProducts)
    inventory: InventoryInventory;
    @Column()
    inventoryId: number;

    @ManyToOne(() => InventoryPurchaseOrderVendor, (model) => model.purchaseOrders)
    vendor: InventoryPurchaseOrderVendor;
    @Column({ nullable: true })
    vendorId: number;
}
