import { ListSubheader } from '@material-ui/core';
import List from '@material-ui/core/List';
import IconBack from '@material-ui/icons/ArrowBackRounded';
import React, { useEffect, useState } from 'react';
import { LegacyOrderRow } from '../../components/legacy-order/row/LegacyOrderRow';
import { UiButton } from '../../@deprecated/UiButton';
import { UiFlex, UiAlert } from '@orderx/ui';
import { UiLoader } from '../../components/ui/UiLoader';
import { UiPageHeader } from '../../components/ui/UiPageHeader';
import { UiSelect } from '../../components/ui/UiSelect';
import { V2Grid } from '../../components/v2/V2Grid';
import { MicroserviceLegacy } from '../../microservices/legacy';
import { routes } from '../../shared/router';
import { storageGet, storageSet } from '../../shared/storage';
import { translate } from '../../shared/translate';
import { useLocationTerminals } from '../../stores/location-terminals';
import { useStaffOrderingBar } from '../../stores/staff-ordering-bar';
import { useUser } from '../../stores/user';
import { useApiQuery } from '@orderx/http';
import moment from 'moment';

export function StaffOrderConfigOrderHistoryPage({ history }) {
    const { barId } = useStaffOrderingBar();
    const [activeType, setActiveType] = useState(0);
    const { phone } = useUser();
    const [terminalName, setTerminalName] = useState(storageGet('orderHistoryTerminalName') || '');
    const terminals = useLocationTerminals(barId);

    useEffect(() => {
        storageSet('orderHistoryTerminalName', terminalName);
    }, [terminalName]);

    const { run, response: orders, isLoading, reset } = useApiQuery(async ({ activeType, terminalName }) => {
        const body = {
            from: moment().subtract(1, 'year').unix() * 1000,
            to: moment().add(1, 'hour').unix() * 1000,
            searchParams: {},
            sort: 'Datetime:desc',
        };

        if (activeType === 0) {
            body.searchParams.Username = phone;
        }

        if (activeType === 1) {
            if (!terminalName) {
                return [];
            } else {
                body.searchParams['PosProfile.name'] = terminalName;
            }
        }

        return MicroserviceLegacy.findLocationReceipts(barId, body);
    });

    useEffect(() => {
        reset();
        run({ activeType, terminalName });
    }, [activeType, terminalName]);

    return (
        <>
            <UiPageHeader spacingLeft={0} isLoading={isLoading}>
                <UiFlex spacing={1}>
                    <UiButton
                        onClick={() => history.push(routes.staffOrderBarConfig.replace(':barId', barId))}
                        icon={<IconBack />}
                    />
                    <h4>{translate('Order history')}</h4>
                </UiFlex>
            </UiPageHeader>

            <div>
                <V2Grid container gutter={16}>
                    <UiFlex spacing={1}>
                        <UiButton
                            variant="contained"
                            size="small"
                            color={activeType === 0 ? 'primary' : 'default'}
                            onClick={() => setActiveType(0)}>
                            My orders
                        </UiButton>

                        <UiButton
                            variant="contained"
                            size="small"
                            color={activeType === 1 ? 'primary' : 'default'}
                            onClick={() => setActiveType(1)}>
                            Terminal orders
                        </UiButton>
                    </UiFlex>

                    {activeType === 1 && (
                        <UiSelect
                            placeholder={'Choose terminal'}
                            menuPlacement={'bottom'}
                            options={terminals.map((terminal) => ({
                                label: terminal.name,
                                value: terminal.name,
                            }))}
                            onChange={({ value }) => {
                                setTerminalName(value);
                            }}
                        />
                    )}

                    {orders && orders.length === 50 && <UiAlert>Only last 50 orders are displayed</UiAlert>}
                </V2Grid>

                {isLoading && <UiLoader />}

                {!isLoading && orders && orders.length === 0 && (
                    <V2Grid container>
                        <UiAlert>No orders found</UiAlert>
                    </V2Grid>
                )}

                {orders && orders.length > 0 && (
                    <List subheader={<ListSubheader>Orders</ListSubheader>}>
                        {orders.map((order) => (
                            <LegacyOrderRow key={order.Id} order={order} />
                        ))}
                    </List>
                )}
            </div>
        </>
    );
}
