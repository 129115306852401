import { getApiUrl } from '../config';
import { httpPost } from '../shared/api';

const getUrl = getApiUrl('legacy');

export const MicroserviceLegacy = {
    findLocationReceipts(locationId, searchParams) {
        return httpPost(getUrl(`admin/locations/${locationId}/receipts`), searchParams);
    },
};
