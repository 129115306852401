import React, { useEffect, useState } from 'react';
import { ActionSheet, useActionSheet } from '../components/ActionSheet';
import { BottomTab } from '../components/BottomTab';
import { BottomTabs } from '../components/BottomTabs';
import { Button } from '../components/Button';
import { ButtonPlaceholder } from '../components/ButtonPlaceholder';
import { CssLoader } from '../components/CssLoader';
import { StaffQueueActions } from '../components/StaffQueueActions';
import { SwitchRedirect } from '../components/SwitchRedirect';
import { V2PageHeader } from '../components/v2/V2PageHeader';
import { getLocationById } from '../microservices/bookx';
import { routes, withParams } from '../shared/router';
import { showError } from '../shared/toasts';
import { useBookXLocation } from '../stores/bookx-location';
import { themeActive } from '../themes/dark';
import { useInterval } from '../use/time';
import { StaffQueueLocationQueue } from './StaffQueueLocationQueue';
import { StaffQueueLocationScan } from './StaffQueueLocationScan';
import styled from 'styled-components';
import { StaffQueueLocationStats } from './StaffQueueLocationStats';

const ButtonWrapper = styled.div`
    position: fixed;
    bottom: ${themeActive.sizes.tabsHeight + 16}px;
    width: 100%;
    display: flex;
    justify-content: center;

    button {
        border-radius: 100%;
        height: 64px;
        width: 64px;
    }
`;

export function StaffQueueLocationPage({ match }) {
    const { locationId } = match.params;
    const { location, setLocation } = useBookXLocation();
    const actionSheetActions = useActionSheet();
    const [next, setNext] = useState(0);

    useEffect(() => {
        loadLocation(locationId);
        return () => setLocation(undefined);
    }, [locationId]);

    useEffect(() => {
        if (next && locationId) {
            loadLocation();
        }
    }, [next, locationId]);

    useInterval(5000, () => setNext((next) => next + 1));

    if (!location) {
        return <CssLoader />;
    }

    const tabs = [
        {
            label: 'Queue',
            icon: 'queue',
            path: withParams(routes.staffQueueLocationQueue, { locationId: location.id }),
            to: withParams(routes.staffQueueLocationQueue, { locationId: location.id }),
            component: StaffQueueLocationQueue,
        },
        {
            label: 'Scan',
            icon: 'scan',
            path: withParams(routes.staffQueueLocationScan, { locationId: location.id }),
            to: withParams(routes.staffQueueLocationScan, { locationId: location.id }),
            component: StaffQueueLocationScan,
        },
        {
            label: 'Stats',
            icon: 'chart',
            path: withParams(routes.staffQueueLocationStats, { locationId: location.id }),
            to: withParams(routes.staffQueueLocationStats, { locationId: location.id }),
            component: StaffQueueLocationStats,
        },
        {
            label: 'Exit',
            icon: 'exit',
            path: routes.customerProfile,
            to: routes.customerProfile,
        },
    ];

    async function loadLocation() {
        try {
            setLocation(await getLocationById(locationId));
        } catch (error) {
            showError(error);
        }
    }

    return (
        <>
            <V2PageHeader title={location.name} />

            <SwitchRedirect routes={tabs} />

            <ButtonPlaceholder numberOfButtons={2} />

            <ButtonWrapper>
                <Button onClick={actionSheetActions.open} icon="add" primary />
            </ButtonWrapper>

            {actionSheetActions.isOpen && (
                <ActionSheet {...actionSheetActions}>
                    <StaffQueueActions onLoadQueue={loadLocation} />
                </ActionSheet>
            )}

            <BottomTabs>
                {tabs.map((tab) => (
                    <BottomTab label={tab.label} to={tab.to} icon={tab.icon} key={tab.label} />
                ))}
            </BottomTabs>
        </>
    );
}

StaffQueueLocationPage.route = '/smartq/:locationId';
