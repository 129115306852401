import React from 'react';
import { SmartqChooseLocation } from './choose-location/SmartqChooseLocation';
import { SwitchRedirect } from '../../components/SwitchRedirect';
import { StaffQueueLocationPage } from '../StaffQueueLocationPage';

export function SmartqPage() {
    return (
        <SwitchRedirect
            routes={[
                {
                    path: SmartqChooseLocation.route,
                    component: SmartqChooseLocation,
                },
                {
                    path: StaffQueueLocationPage.route,
                    component: StaffQueueLocationPage,
                },
            ]}
        />
    );
}

SmartqPage.route = '/smartq';
