import { Column, Entity, Index, ManyToOne, BeforeInsert, ManyToMany, JoinTable, OneToMany } from './typeorm';
import { BaseModel } from './base-model';
import { User } from './user';
import { OutletZone } from './outlet-zone';
import { OrderProduct } from './order-product';
import { LocationRole } from './location-role';
import { OrderProductPayment } from './order-product-payment';

export enum PaymentProvider {
    EXTERNAL = 'EXTERNAL',
    BAMBORA_VIPPS = 'BAMBORA_VIPPS',
    BAMBORA_MOBILE_PAY = 'BAMBORA_MOBILE_PAY',
    VIPPS = 'VIPPS',
    STRIPE = 'STRIPE',
}

export enum PaymentStatus {
    PENDING = 'PENDING',
    SUCCESS = 'SUCCESS',
    FAILED = 'FAILED',
}

@Entity()
export class Payment extends BaseModel {
    @Column({ nullable: true })
    @Index()
    hash: string;

    @Column({ nullable: true })
    deviceFingerprint: string;

    @Column()
    provider: PaymentProvider;

    @Column({ nullable: true })
    @Index()
    providerTransactionId: string;

    @Column({ default: PaymentStatus.PENDING })
    @Index()
    status: PaymentStatus;

    @Column()
    currency: string;

    @Column()
    amount: number;

    @Column({ type: 'text', nullable: true })
    comment: string;

    @Column({ type: 'text', nullable: true })
    data: string;

    @ManyToOne(() => OutletZone, (model) => model.payments)
    outletZone: OutletZone;
    @Column({ nullable: true })
    outletZoneId: number;

    @ManyToOne(() => User, (model) => model.payments)
    user: User;
    @Column({ nullable: true })
    userId: number;

    @OneToMany(() => OrderProductPayment, (model) => model.payment)
    orderProductPayments: OrderProductPayment[];
}
