import clsx from 'clsx';
import React, { useMemo } from 'react';
import { useDeliveryView } from '../../../../hooks/delivery/view';
import { UiButton, UiGrid } from '@orderx/ui';
import './DeliveryStationFilter.scss';
import { ProductStatus, getProductStatusLabel } from '../../../../models/orders/product';
import { ButtonGroup } from '../../../../@v2/components/button/group/ButtonGroup';
import uniq from 'lodash/uniq';

export function DeliveryStationFilter() {
    const { config, toggleCategoryActive, configToggleListValue, allowedProductStatuses, orders } = useDeliveryView();
    const { isDynamicCategories } = config;

    const categories = useMemo(() => {
        if (isDynamicCategories) {
            return uniq(
                orders.flatMap((order) => order.products.map((product) => product.category)).filter(Boolean),
            ) as string[];
        } else {
            return ['DRINK', 'FOOD'];
        }
    }, [isDynamicCategories, orders]);

    return (
        <UiGrid spacing={0} className={clsx('DeliveryStationFilter')}>
            {config.categoryFilterVisible && categories.length > 0 && (
                <div className="filter-container">
                    <label>Filter by category</label>

                    <ButtonGroup>
                        {['ALL', ...categories].map((category) => {
                            const value = category === 'ALL' ? '' : category;

                            return (
                                <UiButton
                                    onClick={() => toggleCategoryActive(value)}
                                    color={config.categoryActive === value ? 'secondary' : 'default'}
                                    variant="filled"
                                    key={category}>
                                    {category}
                                </UiButton>
                            );
                        })}
                    </ButtonGroup>
                </div>
            )}

            {config.orderStatusFilterVisible && (
                <div className="filter-container">
                    <label>Filter by status</label>

                    <ButtonGroup>
                        {allowedProductStatuses.map((status) => {
                            const value = status;

                            return (
                                <UiButton
                                    onClick={() => configToggleListValue('filterByStatuses', value)}
                                    color={config.filterByStatuses.includes(value) ? 'secondary' : 'default'}
                                    variant="filled"
                                    key={status}>
                                    {getProductStatusLabel(status)}
                                </UiButton>
                            );
                        })}
                    </ButtonGroup>
                </div>
            )}
        </UiGrid>
    );
}
