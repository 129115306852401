import clsx from 'clsx';
import React, { useEffect, useState } from 'react';
import { CustomerProfilePage } from '../../CustomerProfilePage';
import { useOnResume } from '../../../shared/browser';
import { BlockLoader } from '../../../@v2/components/block-loader/BlockLoader';
import { ToolbarSearch } from '../../../@v2/components/toolbar/search/ToolbarSearch';
import { compareStrings } from '../../../util/compare-strings';
import './AdminConfigurationPage.scss';
import { AdminConfigurationVenue } from '../../../components/admin/configuration/venue/AdminConfigurationVenue';
import { useAdminConfiguration } from '../../../@v2/hooks/admin-configuration';
import { useUser } from '../../../stores/user';
import { AppUserRoleRole } from '../../../models/app-user-role';
import { UiBox } from '@orderx/ui';
import { loadMe } from '../../../microservices/me-v1';

export function AdminConfigurationPage() {
    const { hasLocationRole } = useUser();
    const { load, venues, hasResponse } = useAdminConfiguration();
    const [keyword, setKeyword] = useState('');

    useEffect(() => {
        load();
        loadMe();
    }, []);

    useOnResume(load);

    const venuesFiltered = venues.filter(
        (venue) => hasLocationRole(venue.id, AppUserRoleRole.Manager) && compareStrings(venue.name, keyword),
    );

    return (
        <div className={clsx('AdminConfigurationPage')}>
            <ToolbarSearch
                title="Configuration"
                keyword={keyword}
                setKeyword={setKeyword}
                buttonBackLink={CustomerProfilePage.route}
            />

            {!hasResponse && <BlockLoader />}

            {hasResponse && (
                <>
                    {venuesFiltered.length === 0 && <UiBox padding={2}>No venues found</UiBox>}

                    {venuesFiltered.map((venue) => (
                        <AdminConfigurationVenue venue={venue} key={venue.id} />
                    ))}
                </>
            )}
        </div>
    );
}

AdminConfigurationPage.route = '/admin/configuration';
