import { Column, Entity, BeforeInsert, Index, ManyToOne, OneToMany } from './typeorm';
import { BaseModel } from './base-model';
import { Location } from './location';
import { InventoryMenu } from './inventory-menu';
import { InventoryInventory } from './inventory-inventory';
import { OutletZone } from './outlet-zone';
import { Order } from './order';

@Entity()
export class Outlet extends BaseModel {
    @Column()
    name: string;

    @Column({ type: 'text', nullable: true })
    description: string;

    @Column({ type: 'text', nullable: true })
    image: string;

    @Column({ default: false })
    isClosed: boolean;

    @Column({ default: false })
    isHidden: boolean;

    @Column({ default: 0 })
    position: number;

    @Column({ default: false })
    queueLimiterActive: boolean;

    @Column({ default: 0 })
    queueLimiterActiveLimit: number;

    @Column()
    @Index({ unique: true })
    hash: string;

    @ManyToOne(() => Location, (model) => model.outlets)
    location: Location;
    @Column({ nullable: true })
    locationId: number;

    @ManyToOne(() => InventoryInventory, (model) => model.outlets)
    inventory: InventoryMenu;
    @Column({ nullable: true })
    inventoryId: number;

    @ManyToOne(() => InventoryMenu, (model) => model.outlets)
    inventoryMenu: InventoryMenu;
    @Column({ nullable: true })
    inventoryMenuId: number;

    @OneToMany(() => OutletZone, (model) => model.outlet)
    outletZones: OutletZone[];

    @OneToMany(() => Order, (model) => model.outlet)
    orders: Order[];
}
