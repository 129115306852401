import React from 'react';
import styled from 'styled-components';
import { Button } from '../components/Button';
import { SvgIcons } from '../components/Svg';
import { V2Grid } from '../components/v2/V2Grid';
import { V2List } from '../components/v2/V2List';
import { V2ListItem } from '../components/v2/V2ListItem';
import { V2PageHeader } from '../components/v2/V2PageHeader';
import { routes, withParams } from '../shared/router';
import { clearTerminalQueue } from '../shared/terminals';
import { showSuccess } from '../shared/toasts';
import { translate } from '../shared/translate';
import { useLocationTerminals } from '../stores/location-terminals';
import { useStaffActiveBar } from '../stores/staff-active-bar';

const Content = styled.div`
    padding: 32px 0;
`;

export default function StaffBarSettingsTerminalQueue({ history }) {
    const { id: barId } = useStaffActiveBar();
    const terminals = useLocationTerminals(barId);

    return (
        <div>
            <V2PageHeader
                title={translate('Clear queue')}
                onClickBack={() => history.push(withParams(routes.staffBarSettings, { barId }))}
            />

            <Content>
                <V2Grid>
                    {barId && (
                        <V2List title="Queue">
                            {terminals.map(terminal => (
                                <V2ListItem
                                    key={terminal.id}
                                    label={`Clear terminal queue of ${terminal.name || '???'}`}
                                    contentRight={
                                        <Button
                                            icon={SvgIcons.Replay}
                                            small
                                            negative
                                            onClick={() => {
                                                clearTerminalQueue(terminal);
                                                showSuccess(
                                                    `Event to clear queue of ${terminal.name} has been sent`,
                                                );
                                            }}
                                        />
                                    }
                                />
                            ))}
                        </V2List>
                    )}
                </V2Grid>
            </Content>
        </div>
    );
}
