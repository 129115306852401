import clsx from 'clsx';
import React from 'react';
import { UiFlex, UiInput } from '@orderx/ui';
import countries from './countries.json';
import orderBy from 'lodash/orderBy';
import uniqBy from 'lodash/uniqBy';

const countryOptions = uniqBy(
    [
        {
            name: 'Norway',
            flag: 'https://upload.wikimedia.org/wikipedia/commons/d/d9/Flag_of_Norway.svg',
            number: '+47',
        },
        {
            name: 'Sweden',
            flag: 'https://upload.wikimedia.org/wikipedia/en/4/4c/Flag_of_Sweden.svg',
            number: '+46',
        },
        {
            name: 'Denmark',
            flag: 'https://upload.wikimedia.org/wikipedia/commons/9/9c/Flag_of_Denmark.svg',
            number: '+45',
        },
        {
            name: 'Finland',
            flag: 'https://upload.wikimedia.org/wikipedia/commons/b/bc/Flag_of_Finland.svg',
            number: '+358',
        },
        {
            name: 'United Kingdom',
            flag: 'https://upload.wikimedia.org/wikipedia/en/a/ae/Flag_of_the_United_Kingdom.svg',
            number: '+44',
        },
        {
            name: 'Estonia',
            flag: 'https://upload.wikimedia.org/wikipedia/commons/8/8f/Flag_of_Estonia.svg',
            number: '+372',
        },
        ...orderBy(countries, (item) => item.number),
    ],
    (item) => item.number,
);

type OnChangeArgs = { areaCode: string; phone: string };

interface Props {
    areaCode: any;
    phone: any;

    onChange(args: OnChangeArgs): void;
}

export function XInputPhone({ areaCode, phone, onChange }: Props) {
    return (
        <UiFlex className={clsx('XInputPhone')} gap={1} fullWidth>
            <UiInput
                value={areaCode}
                onChangeValue={(areaCode) => onChange({ areaCode, phone })}
                options={countryOptions.map((country) => ({
                    label: country.number,
                    value: country.number,
                }))}
                name="area_code"
            />

            <UiInput
                value={phone}
                onChangeValue={(phone) => onChange({ areaCode, phone })}
                label="Phone"
                fullWidth
                type="tel"
                name="phone"
            />
        </UiFlex>
    );
}
