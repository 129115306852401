import produce from 'immer';
import { useEffect, useState } from 'react';
import cloneDeep from 'lodash/cloneDeep';

export function useStore(store) {
    const [state, setState] = useState(store.state);

    useEffect(() => {
        store.subscribe(setState);
        return () => store.unsubscribe(setState);
    }, []);

    return [state, store.set];
}

export function getStoreValue(store) {
    return cloneDeep(store.state);
}

export function createGlobalStore(initialState) {
    let subscriptions = [];

    const store = {
        _store: true,
    };

    store.state = initialState;

    store.set = updateState => {
        const newState = updateState instanceof Function ? produce(store.state, updateState) : updateState;
        store.state = newState;
        subscriptions.forEach(setState => setState(newState));
    };

    store.subscribe = setState => subscriptions.push(setState);

    store.unsubscribe = setState => {
        subscriptions = subscriptions.filter($setState => $setState !== setState);
    };

    return store;
}

export function createStores(object) {
    return {
        ...Object.keys(object).reduce((stores, key) => {
            const isStore = object[key] && object[key]._store;
            stores[key] = isStore ? object[key] : createGlobalStore(object[key]);
            return stores;
        }, {}),
        _store: true,
    };
}

export const createStore = createGlobalStore;
