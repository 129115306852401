import clsx from 'clsx';
import React from 'react';
import { UiFlex } from '@orderx/ui';
import './ButtonGroup.scss';

interface Props {
    children: any;
}

export function ButtonGroup({ children }: Props) {
    return (
        <UiFlex className={clsx('ButtonGroup')} flexChildren overflowAuto>
            {children}
        </UiFlex>
    );
}
