import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useApiQuery } from '@orderx/http';
import { MicroserviceLocation } from '../../../microservices/location';
import { UiRouterRedirect, UiToolbar, UiGrid } from '@orderx/ui';
import { CustomerBrowsePage } from '../browse/CustomerBrowsePage';
import { BlockLoader } from '../../../@v2/components/block-loader/BlockLoader';
import { ToolbarTitle } from '../../../@v2/components/toolbar/title/ToolbarTitle';
import { withParams } from '../../../shared/router';
import { LocationBrowseListCard } from '../../../@v2/components/location/browse-list-card/LocationBrowseListCard';
import { CustomerBarGroupsOverlay } from '../../../components/customer/bar-groups-overlay/CustomerBarGroupsOverlay';
import { CustomerBrowseGroupsHashPage } from '../../CustomerBrowseGroupsHashPage';

export function CustomerVenuePage() {
    const { venueId } = useParams();
    const { response, run } = useApiQuery(MicroserviceLocation.findVenues);
    const [bar, setBar] = useState<any>(undefined);

    useEffect(() => {
        run();
    }, []);

    const venue = response?.find((venue) => venue.id === venueId);

    if (!response) {
        return <BlockLoader />;
    }

    if (response && !venue) {
        return <UiRouterRedirect to={CustomerBrowsePage.route} />;
    }

    return (
        <>
            <UiToolbar>
                <ToolbarTitle title={venue.name} buttonBackLink={CustomerBrowsePage.route} />
            </UiToolbar>

            <UiGrid spacing={2} outerSpacing={2} minMax={300}>
                {venue.bars.map((bar) => (
                    <LocationBrowseListCard
                        name={bar.name}
                        description={bar.description}
                        image={bar.image || venue.image}
                        isClosed={bar.inactive}
                        to={
                            bar.groups.length === 1 &&
                            withParams(CustomerBrowseGroupsHashPage.route, { hash: bar.groups[0].hash })
                        }
                        onClick={() => {
                            if (bar.groups.length > 1) {
                                setBar(bar);
                            }
                        }}
                        key={bar.id}
                    />
                ))}
            </UiGrid>

            {bar && <CustomerBarGroupsOverlay bar={bar} onClose={() => setBar(undefined)} />}
        </>
    );
}

CustomerVenuePage.route = '/customer/venue/:venueId';
