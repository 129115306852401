import { getApiUrl } from '../config';
import { httpPost } from '../shared/api';

const getUrl = getApiUrl('inventory');

export const MicroserviceInventory = {
    v2AppInventory(body) {
        return httpPost(getUrl(`v2/app/inventory`), body);
    },
};
