import keyBy from 'lodash/keyBy';
import { useCallback, useMemo } from 'react';

export function useLocationProducts(locationProducts = []) {
    const locationProductsById = useMemo(() => keyBy(locationProducts, product => product.id), [locationProducts]);

    const getInventoryUnitAmount = useCallback(
        productId => {
            const locationProduct = locationProductsById[productId];

            return (
                (locationProduct &&
                    locationProduct.inventoryUnitAmount &&
                    Number(locationProduct.inventoryUnitAmount)) ||
                1
            );
        },
        [locationProductsById],
    );

    return {
        getProductById: id => locationProductsById[id],
        getInventoryUnitAmount,
    };
}
