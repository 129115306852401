import React, { useState } from 'react';
import styled from 'styled-components';
import { publishToAwsIot } from '../providers/aws-iot';
import { showInfo, showSuccess } from '../shared/toasts';
import { wait } from '../shared/util';
import { useStaffActiveBar } from '../stores/staff-active-bar';
import { Button } from './Button';
import { ButtonGroup } from './ButtonGroup';
import { NfcButton } from './NfcButton';
import { StaffTerminalsSlider } from './StaffTerminalsSlider';
import { V2Grid } from './v2/V2Grid';
import { V2Loader } from './v2/V2Loader';

const Wrapper = styled.div`
    text-align: center;
    position: relative;
    padding: 2rem;

    .icon-wrapper {
        align-items: center;
        display: flex;
        justify-content: center;
    }
`;

export function TerminalPrintActionSheet({ actionSheet }) {
    const { bamboraTerminals: terminals } = useStaffActiveBar();
    const [isPrinting, setIsPrinting] = useState(false);

    async function print(nfc) {
        const terminal = terminals.find(terminal => terminal.nfc === nfc);

        if (!terminal) {
            return showInfo('Terminal not found for given tag');
        }

        printByTerminal(terminal);
    }

    async function printByTerminal({ id, name, pos }) {
        setIsPrinting(true);
        publishToAwsIot(`pos/print/${pos}`, { id });
        await wait(300);
        showSuccess(`Printing receipt from terminal ${name}`);
        setIsPrinting(false);
    }

    return (
        <Wrapper>
            {isPrinting && <V2Loader cover size={64} />}

            <V2Grid>
                <h3>Choose terminal to print receipt from</h3>

                <StaffTerminalsSlider onClickTerminal={printByTerminal} />

                <p>NB! You can also tap a terminal to print receipt</p>

                <ButtonGroup vertical>
                    <NfcButton onTagRead={({ id }) => print(id)} />

                    <Button onClick={actionSheet.close} block>
                        Close
                    </Button>
                </ButtonGroup>
            </V2Grid>
        </Wrapper>
    );
}
