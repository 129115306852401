import clsx from 'clsx';
import React from 'react';
import { ProductListItem } from '../../../../../../components/product/list-item/ProductListItem';
import { usePosOrder } from '../../../../../hooks/pos-order';

interface Props {
    menuProduct: any;
}

export function PosCreateMenuProduct({ menuProduct }: Props) {
    const { useMenuProduct } = usePosOrder();
    const { price, product } = menuProduct;
    const { count, addCount } = useMenuProduct(menuProduct);

    return (
        <div className={clsx('PosOrderProduct')}>
            <ProductListItem
                name={product.name}
                image={product.icon}
                price={price / 100}
                count={count}
                disabled={false}
                currency={product.currency}
                onDecrement={(amount) => addCount(-amount)}
                onIncrement={(amount) => addCount(amount)}
                key={product.id}
            />
        </div>
    );
}
