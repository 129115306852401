import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { getSwishStationById } from '../microservices/legacy-app';
import { getSwishQrAsBase64 } from '../microservices/payments';
import { publishToAwsIot, useAwsIotSubscription } from '../providers/aws-iot';
import { showError } from '../shared/toasts';
import { Button } from './Button';
import { CssLoader } from './CssLoader';

const Wrapper = styled.div`
    align-items: center;
    display: flex;
    flex-flow: column;
    justify-content: space-between;
    height: 100%;
    padding: 32px;

    h1 {
        margin: 0;
    }

    img {
        max-height: 400px;
    }
`;

export function SwishPosOverlay({ pos, actionSheet }) {
    const [swishConfig, setSwishConfig] = useState();
    const [base64Image, setBase64Image] = useState();
    const [title, setTitle] = useState();
    const [tokenSwish, setTokenSwish] = useState();
    const [order, setOrder] = useState();

    const { data } = pos;
    const stationId = data[0];
    const swishMqttInTopic = swishConfig && swishConfig.mqtt && swishConfig.mqtt.mqtt_in;

    const topicConfigReceived = `configuration/${pos.id}`;
    const topicConfigRequest = `configuration/pos/${pos.id}`;

    useEffect(() => {
        loadProducts();
    }, []);

    useEffect(() => {
        publishToAwsIot(topicConfigRequest, { name: pos.id });
    }, []);

    useAwsIotSubscription(topicConfigReceived, data => setSwishConfig(data));

    useEffect(
        () => {
            if (swishConfig) {
                requestQr();
            }
        },
        [swishConfig],
    );

    useEffect(
        () => {
            if (tokenSwish) {
                requestBase64Image(tokenSwish);
            }
        },
        [tokenSwish],
    );

    useEffect(
        () => {
            if (order) {
                requestQr();
            }
        },
        [order],
    );

    async function loadProducts() {
        try {
            const { products } = await getSwishStationById(stationId);
            setTitle(products.map(({ count, product }) => `${count} ${product.name}`).join(', '));
        } catch (error) {
            showError(error, 'Failed to load products');
        }
    }

    function requestQr() {
        const { mqtt_out } = swishConfig.mqtt;

        publishToAwsIot(mqtt_out, {
            name: pos.id,
            start: true,
        });
    }

    async function requestBase64Image(token) {
        try {
            const { base64 } = await getSwishQrAsBase64({ token });
            setBase64Image(`data:image/jpg;base64,${base64}`);
        } catch (error) {
            console.error(error);
        }
    }

    return (
        <Wrapper>
            {swishMqttInTopic && <SwishMqttIn topic={swishMqttInTopic} onToken={setTokenSwish} />}
            {swishConfig && pos && pos.id && <SwishPosCallback topic={`swish/callback/${pos.id}`} onOrder={setOrder} />}

            <h1>{title}</h1>

            {base64Image && <img src={base64Image} alt="Swish QR code" />}
            {!base64Image && <CssLoader size={72} />}

            <Button onClick={actionSheet.close}>Close</Button>
        </Wrapper>
    );
}

function SwishMqttIn({ topic, onToken }) {
    useAwsIotSubscription(topic, data => onToken(data && data.token));
    return null;
}

function SwishPosCallback({ topic, onOrder }) {
    useAwsIotSubscription(topic, onOrder);
    return null;
}
