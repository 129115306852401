import React, { useState, useEffect, useMemo } from 'react';
import { useRouter } from '../../../../@v2/hooks/router';
import { useApiQuery } from '@orderx/http';
import { MicroserviceLocation } from '../../../../microservices/location';
import { useOnResume } from '../../../../shared/browser';
import { Redirect } from 'react-router-dom';
import { CustomerBrowseGroupsHashPage } from '../../../CustomerBrowseGroupsHashPage';
import { UiToolbar, UiGrid, UiButton, UiInput } from '@orderx/ui';
import { ToolbarTitle } from '../../../../@v2/components/toolbar/title/ToolbarTitle';
import { LocationBrowseListCard } from '../../../../@v2/components/location/browse-list-card/LocationBrowseListCard';
import { BlockLoader } from '../../../../@v2/components/block-loader/BlockLoader';
import { CustomerBrowsePage } from '../CustomerBrowsePage';

export function CustomerBrowseIdPage() {
    const {
        query: { name, direction, barId, redirect },
        params: { id },
        push,
        replaceParams,
    } = useRouter();

    const [barActive, setBarActive] = useState<any>(undefined);
    const [keyword, setKeyword] = useState('');

    const { response: location, run } = useApiQuery(() =>
        MicroserviceLocation.appCustomerPost({
            action: 'handlerAppCustomerBrowseDetails',
            locationId: id,
        }),
    );

    useEffect(() => {
        run();
    }, [id]);

    useOnResume(run, [id]);

    //useEffect(() => {
    //    if (redirect) {
    //    }
    //}, [redirect]);

    useEffect(() => {
        if (location && direction === 'back' && location.type === 'VENUE' && barId) {
            //setBarActive(location.bars.find((bar) => bar.id === barId));
        }
    }, [location, direction, barId]);

    const bar =
        (location?.type === 'VENUE' && location.bars.length === 1 && location.bars[0]) ||
        barActive ||
        (location?.type === 'FOTOGRAFISKA' && location);

    const groups = useMemo(() => {
        if (location && bar?.groups?.length > 0) {
            return bar.groups.filter((group) => (group.name || '').toLowerCase().includes(keyword.toLowerCase()));
        }

        return [];
    }, [location, bar, keyword]);

    if (bar) {
        const availableGroups = bar.groups.filter(({ isInactive }) => !isInactive);

        if (availableGroups.length === 1) {
            return (
                <Redirect to={replaceParams(CustomerBrowseGroupsHashPage.route, { hash: availableGroups[0].hash })} />
            );
        }
    }

    if (direction === 'back' && location?.type === 'VENUE') {
        if (location.bars.length === 1) {
            const bar = location.bars[0];
            const availableGroups = bar.groups.filter(({ isInactive }) => !isInactive);

            if (availableGroups.length === 1) {
                return <Redirect to={CustomerBrowsePage.route} />;
            }
        }
    }

    const groupLimit = 100;
    const isGroupFilterVisible = bar?.groups?.length > groupLimit;

    return (
        <>
            <UiToolbar>
                <ToolbarTitle
                    title={bar?.name || barActive?.name || name || 'Location'}
                    buttonBackOnClick={() => {
                        if (barActive) {
                            setBarActive(undefined);
                        } else if (redirect) {
                            window.location.href = redirect;
                        } else {
                            push(CustomerBrowsePage);
                        }
                    }}
                />
            </UiToolbar>

            {location ? (
                <>
                    {bar ? (
                        <UiGrid outerSpacing={2}>
                            {isGroupFilterVisible && (
                                <UiInput
                                    value={keyword}
                                    onChangeValue={setKeyword}
                                    label="Enter table number to filter"
                                />
                            )}

                            <UiGrid minMax={80}>
                                {groups.map((group) => (
                                    <UiButton
                                        to={CustomerBrowseGroupsHashPage.route}
                                        toParams={{ hash: group.hash }}
                                        key={group.id}
                                        variant="outlined"
                                        size={1}>
                                        {group.name}
                                    </UiButton>
                                ))}
                            </UiGrid>
                        </UiGrid>
                    ) : (
                        <UiGrid padding={2}>
                            {location.bars.map((bar) => (
                                <LocationBrowseListCard
                                    onClick={() => {
                                        setBarActive(bar);
                                    }}
                                    name={bar.name}
                                    description={bar.description}
                                    image={bar.image}
                                    isClosed={bar.inactive}
                                    key={bar.id}
                                />
                            ))}
                        </UiGrid>
                    )}
                </>
            ) : (
                <BlockLoader />
            )}
        </>
    );
}

CustomerBrowseIdPage.route = '/customer/browse-l/:id';
