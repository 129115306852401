import React, { Fragment, useEffect } from 'react';
import { useUser } from '../../stores/user';
import { useBookXUser } from '../../hooks/bookx/user';

export function SystemBookX() {
    const { isAuthenticated } = useUser();
    const { load, reset } = useBookXUser();

    useEffect(() => {
        if (isAuthenticated) {
            load();
        }

        return () => reset();
    }, [isAuthenticated]);

    return <Fragment />;
}
