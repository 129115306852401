import { event, initialize, pageview, exception } from 'react-ga';
import { isDev } from '../environment';

export const GoogleAnalytics = {
    initialize() {
        initialize('UA-120667220-1');
    },

    pageview(path) {
        pageview(path);
    },

    event(category, action, label, value) {
        event({ category, action, label, value });
    },

    exception(description, fatal = false) {
        if (!isDev) {
            exception({ description, fatal });
        }
    },

    Category: {
        Customer: 'Customer',
        Staff: 'Staff',
    },

    ActionCustomer: {
        PlaceOrderError: 'Place order error',
        PlaceOrder: 'Place order',
        QrScan: 'QR scan',
        NfcScan: 'NFC scan',
        Tag: 'Tag',
    },
};
