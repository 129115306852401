import LinearProgress from '@material-ui/core/LinearProgress';
import makeStyles from '@material-ui/core/styles/makeStyles';
import clsx from 'clsx';
import React from 'react';
import { themeActive } from '../../themes/dark';

const useStyles = makeStyles({
    UiPageHeader: (props) => ({
        backgroundColor: 'var(--color-background-body)',
        position: 'fixed',
        top: 0,
        left: 0,
        width: '100%',
        height: themeActive.tabs.height,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        paddingLeft: 8 * props.spacingLeft,
        paddingRight: 8,
        zIndex: 10,
    }),
    loader: {
        backgroundColor: 'var(--color-background-body)',
        position: 'absolute',
        bottom: 0,
        left: 0,
        width: '100%',
        animationDelay: '1s',
    },
    placeholder: {
        height: themeActive.tabs.height,
    },
});

export function UiPageHeader({ isLoading = false, spacingLeft = 2, children }) {
    const classes = useStyles({ spacingLeft });

    return (
        <>
            <div className={classes.UiPageHeader}>
                {isLoading && <LinearProgress className={clsx(classes.loader, 'animated fadeIn')} />}
                {children}
            </div>
            <div className={classes.placeholder} />
        </>
    );
}
