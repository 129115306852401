import Wallet from '@bambora/wallet';
import { PaymentType } from '../models/orders/payment';
import { Log } from '../util/logger';
import { Bambora } from './bambora';
import { isNative } from './device';
import { showError } from './toasts';
import { translate } from './translate';
import { OrderIdPage } from '../@v2/pages/order/id/OrderIdPage';

export const Payment = {
    handlePaymentSuccess({ provider, ...rest }) {
        switch (provider) {
            case PaymentType.BAMBORA_VIPPS:
                return Payment.handleBamboraVipps(rest);
            case PaymentType.BAMBORA_MOBILE_PAY:
                return Payment.handleBamboraVipps(rest);
            case PaymentType.BAMBORA:
                return Payment.handleBambora(rest);
            case PaymentType.VIPPS:
                return Payment.handleVipps(rest);
            case PaymentType.DEVELOPMENT:
                return;
            default:
                throw new Error(translate('Payment provider not found'));
        }
    },

    async handleVipps({ id, url }) {
        Log.dev('Payment', 'handleVipps', { id, url });

        if (isNative()) {
            window.open(url, '_system');
        } else {
            window.location.href = url;
        }

        setTimeout(() => {
            showError(translate('Vipps app not installed'));
        }, 1000);
    },

    async handleBambora({ id, url, orderId, token }) {
        Log.dev('Payment', 'handleBambora', { id, url, orderId, token });

        try {
            const response = await Bambora.openModalWithToken(token);
            Log.dev('Payment', response);
            window.location.href = `${window.location.origin}${OrderIdPage.route.replace(':orderId', orderId)}`;
        } catch (error) {
            if (error.event !== Bambora.Event.Cancel) {
                throw error;
            }
        }
    },

    async handleBamboraVipps({ id, url }) {
        try {
            Log.dev('Payment', 'handleBamboraVipps', { id, url });

            if (!id) {
                throw new Error('Missing session');
            }

            return new Wallet().open(id, {
                preferredWindowState: 'overlay',
            });
        } catch (error) {
            console.error(error);
        }
    },
};
