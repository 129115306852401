import React from 'react';
import styled from 'styled-components';

const Wrapper = styled.div`
    padding: 0 ${({ padding = 16 }) => padding}px;
    margin-top: ${({ marginTop = 0 }) => marginTop}px;
    margin-bottom: ${({ marginBottom = 16 * 3 }) => marginBottom}px;

    > *:not(:first-child) {
        border-top: ${({ itemBorders }) => (itemBorders ? 1 : 0)}px solid ${props => props.theme.colors.border};
    }
`;

export function List({ padding, marginTop, marginBottom, itemBorders, children }) {
    return (
        <Wrapper padding={padding} marginTop={marginTop} marginBottom={marginBottom} itemBorders={itemBorders}>
            {children}
        </Wrapper>
    );
}
