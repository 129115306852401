import startCase from 'lodash/startCase';
import uniq from 'lodash/uniq';
import React, { useEffect } from 'react';
import { useProductCategoryFilter } from '../../../use/product-category-filter';
import './ProductCategoryTabs.scss';
import { UiFlex, UiButton } from '@orderx/ui';

export function ProductCategoryTabs({
    category,
    onChangeCategory,
    subCategory,
    onChangeSubcategory,
    products = [],
    productsCount,
}) {
    const { Category } = useProductCategoryFilter();

    const subCategoriesByCategory = products.reduce((acc, { category, subCategory }) => {
        if (category) {
            acc[category] = acc[category] || [];
            acc[category].push(subCategory);
            acc[category] = uniq(acc[category]);
        }

        return acc;
    }, {});

    const productMainCategories = uniq(products.map((product) => product.category).filter(Boolean));
    const mainCategories = [...productMainCategories];

    if (productMainCategories.length > 1) {
        mainCategories.unshift(Category.All);
    }

    const subCategories = (subCategoriesByCategory[category] || []).filter(Boolean);
    const isSubCategoryFilterVisible = subCategories.length > 1 && ![Category.All, Category.Pending].includes(category);

    function getCategoryButton({ value, isActive, onClick }) {
        const label = (value === Category.Pending ? 'My Order' : value) || value || 'All';

        return (
            <UiButton
                onClick={onClick}
                color={isActive ? 'secondary' : 'default'}
                className={isActive && 'isActive'}
                key={value || 'all'}>
                {value === Category.Pending && <div className="badge">{productsCount}</div>}
                {startCase((label || '').toLowerCase())}
            </UiButton>
        );
    }

    useEffect(() => {
        if (mainCategories.length === 1) {
            onChangeCategory(mainCategories[0]);
        }
    }, [mainCategories.length]);

    if (productsCount > 0) {
        mainCategories.unshift(Category.Pending);
    }

    return (
        <div className="ProductCategoryTabs">
            <UiFlex overflowAuto>
                {mainCategories.map((value) =>
                    getCategoryButton({
                        value,
                        isActive: category === value,
                        onClick() {
                            if (value === category) {
                                if (subCategory) {
                                    onChangeSubcategory('');
                                } else {
                                    onChangeCategory('');
                                }
                            } else {
                                onChangeSubcategory('');
                                onChangeCategory(value);
                            }
                        },
                    }),
                )}
            </UiFlex>

            {isSubCategoryFilterVisible && (
                <UiFlex overflowAuto>
                    {subCategories.map((value) =>
                        getCategoryButton({
                            value,
                            isActive: subCategory === value,
                            onClick() {
                                onChangeSubcategory(value === subCategory ? '' : value);
                            },
                        }),
                    )}
                </UiFlex>
            )}
        </div>
    );
}
