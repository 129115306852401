import React, { useState, useEffect } from 'react';
import { UiIconMaterial, UiButton, UiInput, UiToolbar } from '@orderx/ui';
import { usePosMenu } from '../../../../../../hooks/pos-menu';

export function PosCreateHeaderButtonSearch() {
    const { keyword, setKeyword } = usePosMenu();
    const [isOpen, setIsOpen] = useState(false);

    function toggleOpen() {
        setIsOpen(!isOpen);
    }

    useEffect(() => {
        if (!isOpen) {
            setKeyword('');
        }
    }, [isOpen]);

    if (isOpen) {
        return (
            <UiToolbar>
                <UiInput
                    value={keyword}
                    onChangeValue={setKeyword}
                    placeholder="Type to filter products"
                    fullWidth
                    noBorder
                    adornmentEnd={
                        <UiButton onClick={toggleOpen} icon={<UiIconMaterial>close</UiIconMaterial>} iconCircle />
                    }
                />
            </UiToolbar>
        );
    }

    return <UiButton onClick={toggleOpen} icon={<UiIconMaterial>search</UiIconMaterial>} iconCircle size={1} />;
}
