export const UserBalanceType = {
    Count: 'count',
    Vouchers: 'items',
    Fiat: 'balance',
};

export function isTypeVouchers(balance) {
    return balance.type === UserBalanceType.Vouchers;
}

export function isTypeFiat(balance) {
    return balance.type === UserBalanceType.Fiat;
}

export function isTypeCount(balance) {
    return balance.type === UserBalanceType.Count;
}
