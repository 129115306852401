import React from 'react';
import { getUrlToAsset } from '../../shared/assets';
import logo from './orderx-logo-aligned.png';
import { UiImage, UiOverlay, UiLoader, UiFlex } from '@orderx/ui';
import './SplashScreen.scss';

export function SplashScreen() {
    return (
        <UiOverlay className="SplashScreen">
            <UiFlex className="image-wrapper" direction="column" justifyContent="center" spacing={4}>
                <UiImage src={getUrlToAsset(logo)} />
                <UiLoader />
            </UiFlex>
        </UiOverlay>
    );
}
