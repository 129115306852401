import { Entity, ManyToOne, PrimaryColumn } from './typeorm';
import { Payment } from './payment';
import { OrderProduct } from './order-product';

@Entity()
export class OrderProductPayment {
    @ManyToOne(() => Payment, (model) => model.orderProductPayments)
    payment: Payment;
    @PrimaryColumn()
    paymentId: number;

    @ManyToOne(() => OrderProduct, (model) => model.orderProductPayments)
    orderProduct: OrderProduct;
    @PrimaryColumn()
    orderProductId: number;
}
