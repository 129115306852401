import { Entity, Column, ManyToOne, OneToMany } from './typeorm';
import { BaseModel } from './base-model';
import { InventoryPlugin } from './inventory-plugin';
import { InventoryInventoryProduct } from './inventory-inventory-product';
import { InventoryPurchaseOrder } from './inventory-purchase-order';
import { InventoryAction } from './inventory-action';
import { InventoryCountingReport } from './inventory-counting-report';
import { InventoryCountingReportSummary } from './inventory-counting-report-summary';
import { Outlet } from './outlet';

@Entity()
export class InventoryInventory extends BaseModel {
    @Column()
    name: string;

    @OneToMany(() => InventoryInventoryProduct, (model) => model.inventory)
    inventoryProducts: InventoryInventoryProduct[];

    @OneToMany(() => InventoryPurchaseOrder, (model) => model.inventory)
    purchaseOrders: InventoryPurchaseOrder[];

    @OneToMany(() => InventoryAction, (model) => model.inventory)
    actions: InventoryAction[];

    @OneToMany(() => InventoryCountingReport, (model) => model.inventory)
    countingReports: InventoryCountingReport[];

    @OneToMany(() => InventoryCountingReportSummary, (model) => model.inventory)
    countingReportSummaries: InventoryCountingReportSummary[];

    @OneToMany(() => Outlet, (model) => model.inventory)
    outlets: Outlet[];

    @ManyToOne(() => InventoryPlugin, (model) => model.inventories)
    plugin: InventoryPlugin;
    @Column()
    pluginId: number;
}
