import React from 'react';
import styled from 'styled-components';
import { postAction } from '../microservices/device-actions';
import { GoogleAnalytics } from '../providers/googe-analytics';
import { Button } from './Button';

const Wrapper = styled.div`
    height: 100%;
    width: 100%;
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-flow: column;
    padding: 64px;
    text-align: center;
`;

export class ErrorBoundary extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = { error: null };
    }

    async componentDidCatch(error, errorInfo) {
        this.setState({ error });

        try {
            GoogleAnalytics.exception(error.message, true);
            await postAction('react_app_error', { error: String(error) });
        } catch (error) {
            console.error(error);
        }
    }

    render() {
        if (this.state.error) {
            const message = this.state.error && this.state.error.toString && this.state.error.toString();

            return (
                <Wrapper>
                    <div />

                    <div>
                        <h2>Oh no...</h2>

                        <p>The app has encountered slight turbulence and thus needs to be restarted</p>

                        {message && <p>{message}</p>}
                    </div>

                    <Button primary block onClick={() => (window.location.href = '/')}>
                        Restart
                    </Button>
                </Wrapper>
            );
        } else {
            return this.props.children;
        }
    }
}
