import IconReload from '@material-ui/icons/RefreshRounded';
import IconSearch from '@material-ui/icons/SearchRounded';
import makeStyles from '@material-ui/styles/makeStyles';
import React, { useEffect, useState } from 'react';
import { PosGroupPage } from '../../pages/pos/group/PosGroupPage';
import { routes, withParams } from '../../shared/router';
import { useCreateOrder } from '../../stores/create-order';
import { useCreateOrderBar } from '../../stores/create-order-bar';
import { useDeviceConfig } from '../../stores/device-config';
import { themeActive } from '../../themes/dark';
import { useToggleIcon } from '../../use/toggle-icon';
import { UiIconManualEntry } from '../ui/icon/UiIconManualEntry';
import { UiIconPosTerminal } from '../ui/icon/UiIconPosTerminal';
import { UiInput } from '../ui/UiInput';
import { StaffCreateHeaderOrderHistory } from './StaffCreateHeaderOrderHistory';
import { StaffCreateHeaderPrint } from './StaffCreateHeaderPrint';
import { UiFlex, UiButton, UiToolbar } from '@orderx/ui';
import { ToolbarTitle } from '../../@v2/components/toolbar/title/ToolbarTitle';

const useStyles = makeStyles({
    searchInputContainer: {
        backgroundColor: themeActive.tabs.backgroundColor,
        paddingRight: 8,
    },
    searchInputIcon: {
        marginLeft: 16,
        marginRight: 8,
    },
});

export function StaffCreateHeader() {
    const classes = useStyles();
    const { barId, searchKeyword, setSearchKeyword } = useCreateOrderBar();
    const { isPosMode, setIsPosMode, isManualEntry, setIsManualEntry, preSelectedGroup } = useCreateOrder();
    const backRoute = preSelectedGroup
        ? withParams(PosGroupPage.route, { barId })
        : withParams(routes.staffOrderBar, { barId });

    const [isSearchOpen, setIsSearchOpen] = useState(false);
    const { isManualEntryVisible } = useDeviceConfig().config;

    const iconSearch = useToggleIcon({
        active: isSearchOpen,
        onChange: setIsSearchOpen,
        icon: IconSearch,
    });

    const iconManualEntry = useToggleIcon({
        active: isManualEntry,
        onChange: setIsManualEntry,
        icon: UiIconManualEntry,
    });

    const iconPosMode = useToggleIcon({
        active: isPosMode,
        onChange: setIsPosMode,
        icon: UiIconPosTerminal,
    });

    useEffect(() => {
        if (!isPosMode) {
            setIsManualEntry(false);
        }
    }, [isPosMode]);

    return (
        <>
            <UiToolbar position="top">
                <ToolbarTitle
                    title={preSelectedGroup ? <>Table:{preSelectedGroup.name}</> : <>Create</>}
                    buttonBackLink={backRoute}
                />

                <UiFlex>
                    <UiButton icon={<IconReload />} onClick={() => window.location.reload()} />

                    {iconSearch}

                    {isPosMode && (
                        <>
                            <StaffCreateHeaderOrderHistory />
                            {isManualEntryVisible && iconManualEntry}
                            <StaffCreateHeaderPrint />
                        </>
                    )}

                    {iconPosMode}
                </UiFlex>
            </UiToolbar>

            {isSearchOpen && (
                <div className={classes.searchInputContainer}>
                    <UiInput
                        value={searchKeyword}
                        onChangeValue={setSearchKeyword}
                        startAdornment={<IconSearch className={classes.searchInputIcon} />}
                        endAdornment={searchKeyword.length > 0 && <IconSearch />}
                        placeholder={'Search products'}
                        noBorder
                        clearable
                    />
                </div>
            )}
        </>
    );
}
