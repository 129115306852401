import React, { useState } from 'react';
import styled from 'styled-components';
import orderxHand from '../assets/orderx-hand.png';
import { resetUserBalance } from '../microservices/legacy-app';
import { MicroserviceWristbands } from '../microservices/wristbands';
import { AppUserRoleRole } from '../models/app-user-role';
import { getUrlToAsset } from '../shared/assets';
import { mixins } from '../shared/styles';
import { showError, showSuccess } from '../shared/toasts';
import { translate } from '../shared/translate';
import { isTypeCount, isTypeFiat, isTypeVouchers } from '../shared/user-balances';
import { useStaffActiveBar } from '../stores/staff-active-bar';
import { useStaffVenuesRole } from '../stores/staff-venues';
import { themeActive } from '../themes/dark';
import { useApiQuery } from '@orderx/http';
import { Button } from './Button';
import { ButtonGroup } from './ButtonGroup';
import { ButtonPlaceholder } from './ButtonPlaceholder';
import { Card } from './Card';
import { Currency } from './Currency';
import Padding from './Padding';
import { Tabs } from './Tabs';
import { V2Button } from './v2/V2Button';
import { V2List } from './v2/V2List';
import { V2ListItem } from './v2/V2ListItem';
import { useUser } from '../stores/user';
import { UiFlex, UiGrid, UiInput } from '@orderx/ui';

const Wrapper = styled.div`
    .balance-card {
        background-image: ${themeActive.gradients.dark};
        box-shadow: var(--box-shadow);
        height: 10rem;
        position: relative;

        h1 {
            font-size: 3rem;
        }

        img {
            position: absolute;
            bottom: 1rem;
            right: 1rem;
            height: 3rem;
        }
    }

    .reset-button {
        margin-top: 16px;
    }
`;

const CardContent = styled.div`
    ${mixins.flexXY};
    height: 100%;
`;

const TabContent = styled.div`
    margin-top: 1rem;
`;

const BalanceTitle = styled.h5`
    text-transform: uppercase;
    letter-spacing: 1px;
    position: absolute;
    top: 2rem;
    width: 100%;
    text-align: center;
    font-weight: normal;
`;

export function BalancesOverlay({ balance: balanceProps, onClose }) {
    const { id: barId } = useStaffActiveBar();
    const { hasRole } = useStaffVenuesRole();

    const { response, run: updateWristband } = useApiQuery(() =>
        MicroserviceWristbands.findWristband({ locationId: barId, wristbandId: balance.id }),
    );

    const tabs = [
        {
            label: translate('Details'),
            value: 0,
        },
        hasRole(barId, AppUserRoleRole.CanChangeBalance) && {
            label: translate('Top-up'),
            value: 1,
        },
    ].filter(Boolean);

    const [tab, setTab] = useState(0);
    const balance = response || balanceProps;

    return (
        <Wrapper>
            <UiGrid padding={2}>
                <Card key={balance.id} className="balance-card" padding={0}>
                    {isTypeFiat(balance) && <BalanceTitle>Amount left</BalanceTitle>}
                    {isTypeVouchers(balance) && <BalanceTitle>Vouchers left</BalanceTitle>}
                    {isTypeCount(balance) && <BalanceTitle>Items left</BalanceTitle>}

                    <CardContent>
                        <h1>{balance.count}</h1>
                    </CardContent>

                    <img src={getUrlToAsset(orderxHand)} alt="orderx" />
                </Card>

                {tabs.length > 1 && <Tabs value={tab} onChange={setTab} tabs={tabs} />}
            </UiGrid>

            <TabContent>
                {tab === 0 && <BalanceDetails balance={balance} updateWristband={updateWristband} />}
                {tab === 1 && <ChangeBalance balance={balance} updateWristband={updateWristband} />}
            </TabContent>

            <ButtonPlaceholder />

            <ButtonGroup fixed bottom>
                <Button block onClick={onClose}>
                    Close
                </Button>
            </ButtonGroup>
        </Wrapper>
    );
}

function BalanceDetails({ balance, updateWristband }) {
    const { id: barId, venueId } = useStaffActiveBar();
    const { isTestUser } = useUser();
    const [isResettingBalance, setIsResettingBalance] = useState(false);
    const { hasRole } = useStaffVenuesRole();

    async function resetBalance(balance) {
        setIsResettingBalance(true);

        try {
            await resetUserBalance({
                venueId,
                userBalanceId: balance.id,
            });
            await updateWristband();

            showSuccess('Amount was reset to 0');
        } catch (error) {
            showError(error, 'Failed to reset');
        }

        setIsResettingBalance(false);
    }

    return (
        <V2List title={translate('Details')}>
            <V2ListItem title={translate('ID')} contentRight={balance.id} />

            {isTestUser && <V2ListItem title={translate('Type')} contentRight={balance.type} />}

            {isTestUser && balance.userId && <V2ListItem title={translate('User')} contentRight={balance.userId} />}

            <V2ListItem
                title={translate('Activated')}
                contentRight={balance.active ? translate('Yes') : translate('No')}
            />

            {hasRole(barId, AppUserRoleRole.CanChangeBalance) && (
                <V2ListItem
                    title={translate('Reset balance')}
                    contentRight={
                        <V2Button isLoading={isResettingBalance} onClick={() => resetBalance(balance)} small>
                            {translate('Reset')}
                        </V2Button>
                    }
                />
            )}
        </V2List>
    );
}

function ChangeBalance({ balance, updateWristband }) {
    const { id: barId, venueConfiguration, currency } = useStaffActiveBar();
    const { wristbandsActivationFeeMajorUnits } = venueConfiguration;
    const { id: wristbandId } = balance;
    const [amount, setAmount] = useState('');
    const canChange = amount > 0;
    const { isLoading, run: addAmount } = useApiQuery(async () => {
        const { count } = await MicroserviceWristbands.addAmount({
            locationId: barId,
            wristbandId,
            amount: amount ? Number(amount) : 0,
        });
        await updateWristband();
        setAmount('');
        showSuccess(`Updated wristband amount to: ${count}`);
    });

    return (
        <Padding horizontal>
            <UiGrid gap={4}>
                <UiFlex flexChildren>
                    {[200, 500, 1000].map((amount) => (
                        <V2Button onClick={() => setAmount(amount)} key={amount} disabled={isLoading}>
                            {amount}
                        </V2Button>
                    ))}
                </UiFlex>

                <div>
                    <UiInput
                        value={amount}
                        onChangeValue={setAmount}
                        label={translate('Amount')}
                        onClick={() => setAmount('')}
                        disabled={isLoading}
                        type="number"
                    />
                </div>

                {!balance.isActivationFeePaid && (
                    <>
                        {wristbandsActivationFeeMajorUnits ? (
                            <h3>
                                {translate('Wristband is not activated. There will be an activation fee subtracted of')}{' '}
                                <Currency amount={wristbandsActivationFeeMajorUnits} currency={currency} />
                            </h3>
                        ) : (
                            <h3>
                                {translate(
                                    'No activation fee is required. Wristband will be activated after adding amount',
                                )}
                            </h3>
                        )}
                    </>
                )}

                <h5>
                    {translate(
                        'NB! Make sure you have received correct amount of cash before tapping wristband! This action cannot be reverted',
                    )}
                </h5>

                <V2Button onClick={addAmount} fullWidth disabled={!canChange} primary isLoading={isLoading}>
                    {translate('Add amount')}
                </V2Button>
            </UiGrid>
        </Padding>
    );
}
