import { createStore, useStore } from '../create-store';
import { ORDER_STATUS } from '../shared/orders';
import { useStaffTerminalOrders } from './staff-terminal-orders';

const store = createStore({});

export function useStaffTerminalSlider() {
    const { getOrderById } = useStaffTerminalOrders();
    const [orderIdByTerminalId, setOrderIdByTerminalId] = useStore(store);

    return {
        hasOrders() {
            return Object.values(orderIdByTerminalId).filter(id => id).length > 0;
        },
        getOrderByTerminalId(terminalId) {
            const orderId = orderIdByTerminalId[terminalId];
            return getOrderById(orderId);
        },
        setTerminalOrder(terminalId, order) {
            const { Id: orderId, Status: status } = order;

            setOrderIdByTerminalId({
                ...orderIdByTerminalId,
                [terminalId]: orderId,
            });

            const shouldClearLight = [
                ORDER_STATUS.FINALIZED,
                ORDER_STATUS.PAYMENT_SUCCESS,
                ORDER_STATUS.PAYMENT_FAILED,
            ].includes(status);

            if (shouldClearLight) {
                setTimeout(() => {
                    setOrderIdByTerminalId(orderIdByTerminalId => {
                        if (orderIdByTerminalId[terminalId] === orderId) {
                            orderIdByTerminalId[terminalId] = undefined;
                        }
                    });
                }, 10000);
            }
        },
        clearTerminalOrders() {
            setOrderIdByTerminalId({});
        },
    };
}
