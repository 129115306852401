import { getApiUrl } from '../config';
import { httpPost } from '../shared/api';

const getUrl = getApiUrl('clients');

const Action = {
    FindClients: 'FIND_CLIENTS',
    FindClientById: 'FIND_CLIENT_BY_ID',
    FindClientOverview: 'FIND_CLIENT_OVERVIEW',
    UpdateWristband: 'UPDATE_WRISTBAND',
};

export const MicroserviceClients = {
    findClients() {
        return httpPost(getUrl('app/index'), {}, { action: Action.FindClients });
    },

    findClientById({ clientId }) {
        return httpPost(getUrl('app/index'), { clientId }, { action: Action.FindClientById });
    },

    findClientOverview({ clientId }) {
        return httpPost(getUrl('app/index'), { clientId }, { action: Action.FindClientOverview });
    },

    updateWristband({ clientId, wristbandId, ...rest }) {
        return httpPost(getUrl('app/index'), { clientId, wristbandId, ...rest }, { action: Action.UpdateWristband });
    },
};
