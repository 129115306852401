import React, { useEffect } from 'react';
import { useDiscount } from '../../../stores/discount';
import { Redirect } from 'react-router-dom';
import { CustomerBrowsePage } from '../../../pages/customer/browse/CustomerBrowsePage';

export function ResetDiscountPage() {
    const { reset } = useDiscount();

    useEffect(() => {
        reset();
    }, []);

    return <Redirect to={CustomerBrowsePage.route} />;
}

ResetDiscountPage.route = '/reset-discount';
