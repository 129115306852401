import { getApiUrl } from '../config';
import { httpGet, httpPut } from '../shared/api';
import { stores } from '../stores';
import { setUser } from '../stores/user';

const getUrl = getApiUrl('me-v1');

export const MicroserviceMeV1 = {
    mePut(attributes) {
        return httpPut(getUrl(`me`), attributes);
    },

    termsAndConditionsGet() {
        return httpGet(getUrl(`terms-and-conditions`));
    },
};

export async function getIotUrl() {
    const url = getUrl(`iot`);
    return (await httpGet(url)).url;
}

export async function registerDevice({ token, platform }) {
    return httpPut(getUrl(`register-device`), { token, platform });
}

export async function loadMe() {
    const { profile, userBalances, attributes, rolesByLocationId, uniqueRoles } = await httpGet(getUrl(`me`));

    stores.user.set((state) => {
        state.profile = profile;
        state.userBalances = userBalances;
        state.rolesByLocationId = rolesByLocationId;
    });

    setUser({ ...attributes, ...profile, rolesByLocationId, uniqueRoles });
}
