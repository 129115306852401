import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import sum from 'lodash/sum';
import React from 'react';
import { themeActive } from '../../themes/dark';
import { CurrencyMinor } from '../CurrencyMinor';
import { ProductPriceLabel } from '../product/ProductPriceLabel';
import { UiTableCellCheckbox } from '../ui/table/cell-checkbox/UiTableCellCheckbox';

export function StaffOrderProductTable({ products, checkboxes }) {
    const { CheckboxToggleAllCell, isChecked, toggleChecked } = checkboxes;
    const total = sum(products.map(product => product.priceIncludingVat));

    return (
        <Table>
            <TableHead>
                <TableRow>
                    {CheckboxToggleAllCell}
                    <TableCell>Name</TableCell>
                    <TableCell align={'right'}>Price</TableCell>
                </TableRow>
            </TableHead>

            <TableBody>
                {products.map(product => (
                    <TableRow key={product.id}>
                        {product.isPaid ? (
                            <TableCell style={{ color: themeActive.colors.success }}>Paid</TableCell>
                        ) : (
                            <UiTableCellCheckbox
                                checked={isChecked(product.id)}
                                onChange={() => toggleChecked(product.id)}
                            />
                        )}

                        <TableCell>{product.name}</TableCell>

                        <TableCell align={'right'}>
                            <ProductPriceLabel product={product} />
                        </TableCell>
                    </TableRow>
                ))}

                <TableRow>
                    <TableCell />
                    <TableCell align={'right'}>
                        <b>Total:</b>
                    </TableCell>
                    <TableCell align={'right'}>
                        <b>
                            <CurrencyMinor amount={total} />
                        </b>
                    </TableCell>
                </TableRow>
            </TableBody>
        </Table>
    );
}
