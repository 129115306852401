import { useEffect } from 'react';
import { stores } from '../stores';
import { useDevice } from '../shared/device';

export function StaffRegisterDevice() {
    const { isIos, isAndroid } = useDevice();

    useEffect(
        () => {
            if (isIos) {
                window.plugins.uniqueDeviceID.get(uuid => {
                    if (uuid) {
                        stores.device.set(state => {
                            state.id = uuid;
                        });
                    }
                });
            }
        },
        [isIos],
    );

    useEffect(
        () => {
            if (isAndroid) {
                window.cordova.plugins.IMEI((error, imei) => {
                    if (imei) {
                        stores.device.set(state => {
                            state.id = imei;
                            state.imei = imei;
                        });
                    }
                });
            }
        },
        [isAndroid],
    );

    return null;
}
