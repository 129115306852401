import React from 'react';
import { PosGroupProducts } from '../products/PosGroupProducts';
import { useApiQuery } from '@orderx/http';
import { MicroserviceOrders } from '../../../../microservices/orders';
import { PosGroupFooter } from '../footer/PosGroupFooter';
import { StaffOrderDiscounts } from '../../../staff-order/StaffOrderDiscounts';
import { usePosGroup } from '../../../../hooks/pos/group';

export function PosGroupDiscount() {
    const { group, barId, loadGroup, productsChecked, resetProductsChecked } = usePosGroup();

    const { isLoading: isApplyingDiscount, run: applyDiscount } = useApiQuery(async (discount) => {
        await MicroserviceOrders.staffApp({
            action: 'APPLY_DISCOUNT',
            barId,
            discountId: discount ? discount.id : 'remove',
            productIds: productsChecked.map((product) => product.id),
        });

        loadGroup();
        resetProductsChecked();
    });

    return (
        <>
            <PosGroupProducts isSelectable />

            <PosGroupFooter>
                <StaffOrderDiscounts
                    barId={group.barId}
                    isLoading={isApplyingDiscount}
                    onApplyDiscount={applyDiscount}
                />
            </PosGroupFooter>
        </>
    );
}
