import React, { useEffect, useCallback } from 'react';
import { useIotBarOrderUpdated } from '../../hooks/iot/bar-order-updated';
import { usePosBar } from '../../hooks/pos/bar';
import { PosGroupPage } from '../../pages/pos/group/PosGroupPage';
import { useOnWindowFocus } from '../../shared/browser';
import { routes, withParams } from '../../shared/router';
import { useCreateOrderBar } from '../../stores/create-order-bar';
import { useDeviceConfig } from '../../stores/device-config';
import { useStaffOrderingBar } from '../../stores/staff-ordering-bar';
import { BottomTab } from '../BottomTab';
import { SvgIcons } from '../Svg';
import './StaffBottomNav.scss';
import { StaffStationPage } from '../../pages/staff-station/StaffStationPage';
import { useDeliveryView } from '../../hooks/delivery/view';
import { useLocation } from 'react-router-dom';
import { useStaffBottomToolbar } from '../../@v2/hooks/staff-bottom-toolbar';
import { useAwsIotSubscription } from '../../providers/aws-iot';
import { UiToolbar } from '@orderx/ui';

export function StaffBottomNav() {
    const { count, loadCount } = useStaffBottomToolbar();
    const { pathname } = useLocation();
    const { barId: barId1 } = useStaffOrderingBar();
    const { barId: barId2 } = useCreateOrderBar();
    const { barId: barId3 } = usePosBar();
    const { bars, config: deliveryViewConfig } = useDeliveryView();
    const barIdDelivery = bars?.length === 1 && bars[0].id;
    const { config } = useDeviceConfig();

    const barId = barId1 || barId2 || barId3 || barIdDelivery;

    // TODO: Find a better way to detect if we are in station view
    const isStationViewActive = pathname.startsWith(StaffStationPage.route.replace(':barId', barId));

    const updateBadge = useCallback(() => {
        loadCount({
            ...deliveryViewConfig,
            barIds: [barId],
        });
    }, [isStationViewActive, deliveryViewConfig]);

    useEffect(() => {
        updateBadge();
    }, [updateBadge]);

    useAwsIotSubscription(`order/staff/bars/${barId}/update`, updateBadge, [updateBadge]);
    useIotBarOrderUpdated(barId, updateBadge, [updateBadge]);
    useOnWindowFocus(updateBadge, [updateBadge]);

    function withBarId(route) {
        return withParams(route, { barId });
    }

    return (
        <UiToolbar position="bottom">
            <BottomTab label="Tables" to={withBarId(PosGroupPage.route)} icon={SvgIcons.List} />

            <BottomTab
                label="Create"
                to={withBarId(config.isNewOrderCreateView ? routes.staffCreateOrder : routes.staffOrderBarCreate)}
                icon={SvgIcons.Add}
            />

            {config.isStationViewVisible && (
                <BottomTab
                    label="Station"
                    to={StaffStationPage.route.replace(':barId', barId)}
                    icon="staff"
                    badge={count}
                />
            )}

            <BottomTab label="Config" to={withBarId(routes.staffOrderBarConfig)} icon={SvgIcons.Settings} />

            <BottomTab label="Exit" to={withBarId(routes.customerProfile)} icon={SvgIcons.Exit} />
        </UiToolbar>
    );
}
