import orderBy from 'lodash/orderBy';
import { useMemo } from 'react';
import { createStore, useStore } from '../create-store';
import { storageGet, storageSet } from '../shared/storage';
import { useCreateOrderBar } from './create-order-bar';
import { useLocationTerminalSettings } from './location-terminal-settings';

const cacheKeyOrderByTerminalId = 'orderByTerminalId';

const getInitialState = () => ({
    orderByTerminalId: storageGet(cacheKeyOrderByTerminalId) || {},
});

const store = createStore(getInitialState());

export function useCreateOrderTerminals() {
    const { barId, bamboraTerminals } = useCreateOrderBar();
    const [value, setValue] = useStore(store);

    const { getOrderedTerminals, isControlledFromSettings, isExpanded, terminalIds } = useLocationTerminalSettings(
        barId,
    );

    const terminals = useMemo(() => {
        if (isControlledFromSettings) {
            return getOrderedTerminals(bamboraTerminals.filter(terminal => terminalIds.includes(terminal.id)));
        } else {
            return orderBy(bamboraTerminals, terminal => terminal.name, 'asc');
        }
    }, [isControlledFromSettings, bamboraTerminals]);

    return {
        getLatestTerminalOrder(terminalId) {
            return value.orderByTerminalId[terminalId];
        },
        compact: !isExpanded,
        terminals,
        setTerminalOrder(terminalId, order) {
            setValue(value => {
                value.orderByTerminalId[terminalId] = order;
                storageSet(cacheKeyOrderByTerminalId, value.orderByTerminalId);
            });
        },
        removeOrder(terminalId, orderId) {
            setValue(value => {
                const activeTerminalOrder = value.orderByTerminalId[terminalId];

                if (activeTerminalOrder && activeTerminalOrder.Id === orderId) {
                    value.orderByTerminalId[terminalId] = undefined;
                    storageSet(cacheKeyOrderByTerminalId, value.orderByTerminalId);
                }
            });
        },
        clearTerminalOrders() {
            setValue(value => {
                for (const { id } of terminals) {
                    value.orderByTerminalId[id] = undefined;
                }

                storageSet(cacheKeyOrderByTerminalId, value.orderByTerminalId);
            });
        },
    };
}
