import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import React, { useEffect, useMemo } from 'react';
import { MicroserviceOrders } from '../../microservices/orders';
import { useSnackbar } from '../../shared/snackbar';
import { useStaffOrderingBar } from '../../stores/staff-ordering-bar';
import { useApiQuery } from '@orderx/http';
import { UiDialogContent } from '../ui/dialog/UiDialogContent';
import { UiDialogTitle } from '../ui/dialog/UiDialogTitle';
import { UiButton } from '../../@deprecated/UiButton';
import { UiGrid } from '@orderx/ui';

export function StaffOrderGroupMoveProductsDialog({ barId, group, open = true, close, onSuccess }) {
    const { showSuccess } = useSnackbar();
    const staffOrderingBarId = useStaffOrderingBar().barId;
    barId = barId || staffOrderingBarId;

    const { run, response } = useApiQuery(() =>
        MicroserviceOrders.staffApp({
            action: 'FIND_GROUPS',
            barId,
        }),
    );

    const { isLoading: isMovingProducts, run: moveProducts } = useApiQuery(async ({ id }) => {
        await MicroserviceOrders.staffApp({
            action: 'MOVE_GROUP_PRODUCTS',
            groupId: group.id,
            targetGroupId: id,
        });

        showSuccess('Products moved');
        close();
        onSuccess();
    });

    const groups = useMemo(() => (response || []).filter(({ id }) => id !== group.id), [response, group]);

    useEffect(() => {
        run();
    }, []);

    return (
        <Dialog open={open} onClose={close}>
            <UiDialogTitle>{'Move all products to another table'}</UiDialogTitle>

            <UiDialogContent>
                <UiGrid minMax={80} outerSpacing={2}>
                    {groups.map((group) => (
                        <UiButton
                            variant={'contained'}
                            onClick={() => moveProducts(group)}
                            disabled={isMovingProducts}
                            key={group.id}>
                            {group.name}
                        </UiButton>
                    ))}
                </UiGrid>
            </UiDialogContent>

            <DialogActions>
                <UiButton onClick={close}>Close</UiButton>
            </DialogActions>
        </Dialog>
    );
}
