import clsx from 'clsx';
import React from 'react';
import { Currency } from '../../Currency';
import { IncrementDecrement } from '../../IncrementDecrement';
import './ProductListItem.scss';
import { UiText, UiPadding, UiFlex } from '@orderx/ui';

export function ProductListItem({
    image,
    name,
    description = '',
    price,
    onIncrement,
    onDecrement,
    count,
    countMax = undefined,
    disabled = false,
    currency,
    hasOptions = true,
    children = null,
}) {
    const imageSrc = image?.split('/').slice(-1)[0];

    return (
        <div className="ProductListItem">
            <UiFlex spacing={2}>
                {imageSrc && <div className={clsx('product-icon', imageSrc.replace('.png', ''))} />}

                <div className="meta">
                    <h4>{name}</h4>

                    {description && (
                        <UiPadding bottom={1}>
                            <UiText size={-1} weight={2} color="text-secondary">
                                {description}
                            </UiText>
                        </UiPadding>
                    )}

                    <div className="price">
                        <Currency amount={price} currency={currency} />
                    </div>
                </div>
            </UiFlex>

            {hasOptions && (
                <div className="options-container">
                    <IncrementDecrement
                        count={count}
                        onIncrement={onIncrement}
                        onDecrement={onDecrement}
                        disabled={disabled}
                        countMax={countMax}
                    />
                </div>
            )}

            {children}
        </div>
    );
}
