import IconAdd from '@material-ui/icons/AddRounded';
import IconClose from '@material-ui/icons/CloseRounded';
import IconRemove from '@material-ui/icons/RemoveRounded';
import clsx from 'clsx';
import React, { useState } from 'react';
import './IncrementDecrement.scss';
import { UiButton } from './ui/button/UiButton';

export function IncrementDecrement({
    multiplier = 1,
    count = 0,
    onIncrement,
    onDecrement,
    disabled,
    countMax,
    showZero = false,
}) {
    const [isExtraButtonsVisible, setIsExtraButtonsVisible] = useState(false);

    const singleUnitAmount = multiplier || 1;
    let label = count / singleUnitAmount;
    label = label % 1 > 0 ? label.toFixed(1) : label;

    const isDecrementDisabled = disabled || count === 0;
    const isIncrementDisabled = disabled || count >= countMax;

    function increment(units = 1) {
        const amountToIncrement = units * singleUnitAmount;
        const resultAmount = count + amountToIncrement;

        if (resultAmount > countMax) {
            onIncrement(countMax - count);
        } else {
            onIncrement(amountToIncrement);
        }
    }

    function decrement(units = 1) {
        const amountToDecrement = units * singleUnitAmount;
        const resultAmount = count - amountToDecrement;

        if (resultAmount < 0) {
            onDecrement(amountToDecrement + resultAmount);
        } else {
            const remainder = count % singleUnitAmount;

            if (remainder > 0) {
                onDecrement(remainder);
            } else {
                onDecrement(amountToDecrement);
            }
        }
    }

    const isExtraContainerVisible = isExtraButtonsVisible || showZero || count > 0;

    return (
        <div className="IncrementDecrement">
            {isExtraButtonsVisible && (
                <div className="upper-row button-group animated fadeInUp">
                    <UiButton
                        className="button"
                        variant="filled"
                        onClick={() => setIsExtraButtonsVisible(false)}
                        icon={<IconClose />}
                    />

                    <UiButton
                        className="button"
                        variant="filled"
                        disabled={isDecrementDisabled}
                        onClick={() => decrement(10)}>
                        -10
                    </UiButton>

                    <UiButton
                        className="button"
                        variant="filled"
                        onClick={() => increment(10)}
                        disabled={isIncrementDisabled}>
                        +10
                    </UiButton>
                </div>
            )}

            <div className={clsx(isExtraContainerVisible && 'button-group')}>
                {isExtraContainerVisible && (
                    <>
                        <UiButton
                            icon={<IconRemove />}
                            variant="filled"
                            className="button button-decrement"
                            disabled={isDecrementDisabled}
                            onClick={() => decrement(1)}
                        />

                        <UiButton
                            className="button button-label"
                            color="primary"
                            variant="filled"
                            onClick={() => setIsExtraButtonsVisible(!isExtraButtonsVisible)}>
                            {label}
                        </UiButton>
                    </>
                )}

                <UiButton
                    icon={<IconAdd />}
                    className="button button-increment"
                    variant="filled"
                    onClick={() => increment(1)}
                    disabled={isIncrementDisabled}
                />
            </div>
        </div>
    );
}
