import { themeActive } from '../themes/dark';

export const ORDER_STATUS = {
    INITIALIZED: 'INITIALIZED',
    SENT_TO_BOTTOMS_UP_UNIT: 'READYFORBOTTOMSUP',
    PAYMENT_WAITING: 'PAYMENTAWAIT',
    PAYMENT_SUCCESS: 'PAYMENTSUCCESS',
    PAYMENT_FAILED: 'PAYMENTFAILED',
    PAYMENT_CANCELLED: 'PAYMENTCANCELED',
    FINALIZED: 'FINALIZED',
};

export function getOrderStatusColor(status) {
    return {
        [ORDER_STATUS.INITIALIZED]: themeActive.colors.background,
        [ORDER_STATUS.PAYMENT_WAITING]: themeActive.colors.yellow,
        [ORDER_STATUS.PAYMENT_FAILED]: themeActive.colors.red,
        [ORDER_STATUS.PAYMENT_CANCELLED]: themeActive.colors.red,
        [ORDER_STATUS.PAYMENT_SUCCESS]: themeActive.colors.green,
        [ORDER_STATUS.FINALIZED]: themeActive.colors.green,
        [ORDER_STATUS.SENT_TO_BOTTOMS_UP_UNIT]: themeActive.colors.primary,
    }[status];
}
