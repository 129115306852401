import clsx from 'clsx';
import React, { useState, useEffect, Fragment } from 'react';
import { useFilterList } from '../../../../hooks/filter-list';
import { useApiQuery } from '@orderx/http';
import { MicroserviceLocation } from '../../../../../microservices/location';
import { UiBox, UiInput, UiTable, UiText, UiButton, UiIconMaterial, UiGrid } from '@orderx/ui';

export function StaffInventoryProductPage({ bar }) {
    const [products, setProducts] = useState<any>([]);
    const { uiInputProps, items: productsFiltered } = useFilterList(products);

    const { run, response } = useApiQuery(() =>
        MicroserviceLocation.appStaffIndexPost({
            action: 'handlerAppStaffInventoryData',
            locationId: bar.id,
        }),
    );

    useEffect(() => {
        run();
    }, []);

    useEffect(() => {
        if (response) {
            setProducts(response.products);
        }
    }, [response]);

    return (
        <div className={clsx('StaffInventoryPage')}>
            <UiBox padding={1}>
                <UiInput {...uiInputProps} />
            </UiBox>

            {products.length > 0 && (
                <UiTable>
                    <thead>
                        <tr>
                            <td>Name</td>
                            <td></td>
                        </tr>
                    </thead>

                    <tbody>
                        {productsFiltered.map((product) => (
                            <ProductRow
                                product={product}
                                onUpdateProduct={(product) =>
                                    setProducts((products) => products.map((p) => (p.id === product.id ? product : p)))
                                }
                                key={product.id}
                            />
                        ))}
                    </tbody>
                </UiTable>
            )}
        </div>
    );
}

function ProductRow({ product, onUpdateProduct }) {
    const [isOpen, setIsOpen] = useState(false);
    const [priceIncludingVatMinorUnits, setPriceIncludingVatMinorUnits] = useState(product.priceIncludingVatMinorUnits);

    const { run, isLoading, response } = useApiQuery((body) =>
        MicroserviceLocation.appStaffIndexPost({
            ...(body || {}),
            action: 'handlerAppStaffInventoryUpdateProduct',
            locationId: product.locationId,
            id: product.id,
        }),
    );

    useEffect(() => {
        if (response) {
            onUpdateProduct(response);
        }
    }, [response]);

    return (
        <Fragment key={product.id}>
            <tr>
                <td onClick={() => setIsOpen(!isOpen)}>
                    <UiText size={1}>{product.name}</UiText>
                </td>
                <td align="right">
                    <UiButton
                        onClick={() => run({ hidden: !product.hidden })}
                        isLoading={isLoading}
                        color={product.hidden ? 'error' : 'default'}
                        icon={<UiIconMaterial>{product.hidden ? 'visibility_off' : 'visibility'}</UiIconMaterial>}
                    />
                </td>
            </tr>

            {isOpen && (
                <tr>
                    <td colSpan={2}>
                        <UiGrid gap={1}>
                            <UiGrid gap={1} minMax={150}>
                                <UiInput
                                    type="currency"
                                    value={priceIncludingVatMinorUnits}
                                    onChangeValue={setPriceIncludingVatMinorUnits}
                                    label="Price"
                                />
                            </UiGrid>

                            <UiButton
                                onClick={() => run({ priceIncludingVatMinorUnits })}
                                isLoading={isLoading}
                                color="primary"
                                variant="filled">
                                Save
                            </UiButton>
                        </UiGrid>
                    </td>
                </tr>
            )}
        </Fragment>
    );
}

StaffInventoryProductPage.route = '/staff-inventory/product';
