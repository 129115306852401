import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import {
    UiText,
    UiGrid,
    UiFlex,
    UiInput,
    UiButton,
    UiInputPhone,
    UiButtonCheckbox,
    UiOverlay,
    UiIconMaterial,
    UiToolbar,
    UiBox,
} from '@orderx/ui';
import { useCovidRegistration } from '../../../stores/covid-registration';
import { useApiQuery } from '@orderx/http';
import { showError } from '../../../shared/toasts';
import { PosPageLoader } from '../../pos/page/loader/PosPageLoader';
import { ToolbarTitle } from '../../../@v2/components/toolbar/title/ToolbarTitle';
import { termsAndConditions } from './terms';
import { XInputPhone } from '../../../@v2/components/x/input-phone/XInputPhone';
import { MicroserviceLocation } from '../../../microservices/location';
import orderBy from 'lodash/orderBy';

const Wrapper = styled(UiGrid)`
    .step {
        background-color: var(--color-success);
        font-weight: bold;
        height: 36px;
        width: 36px;
        min-height: 36px;
        min-width: 36px;
        font-size: 1.25rem;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 100%;
        line-height: 1;
    }

    .underlined {
        text-decoration: underline;
    }

    .button-checkbox .UiButton {
        white-space: initial;
        text-align: left;
        padding-top: 0.5rem;
        padding-bottom: 0.5rem;
        font-weight: 400;

        .UiFlex > *:nth-child(2) {
            padding-left: 0.5rem;
        }
    }

    .register-success .UiIconMaterial {
        font-size: 64px;
    }
`;

interface Props {
    tag: {
        venueId: string;
        venueName: string;
        covidTagTargetBarId?: string;
    };

    showAlways?: boolean;

    onCancel?(): void;

    onSuccess?(): void;
}

export function TagCovidRegistration({ tag, showAlways, onCancel, onSuccess }: Props) {
    const [isTermsOpen, setIsTermsOpen] = useState(false);
    const { venueId, venueName, covidTagTargetBarId } = tag;
    const {
        checkIn,
        form,
        setForm,
        initialAutoRegister,
        isAutoRegister,
        setIsAutoRegister,
        lastRegistrationDate,
    } = useCovidRegistration();
    const { name, contactType, email, areaCode, phone, isConfirmed } = form;

    const isPhone = contactType === 'phone';
    const isValidName = name?.length > 1;
    const isValidEmail = String(email).length > 5 && String(email).includes('@');
    const isValidPhone = String(phone).length > 5;
    const isValidContact = contactType === 'email' ? isValidEmail : isValidPhone;

    const [step, setStep] = useState((isValidContact && 3) || (isValidName && 2) || 1);

    function getUiTextPropsByStep(stepActive) {
        const isActive = stepActive === step;

        return {
            size: 1,
            color: isActive ? 'text-primary' : 'text-secondary',
            weight: isActive ? 2 : (0 as any),
        };
    }

    const { run: register, isLoading: isLoadingRegister, response: isSuccess } = useApiQuery(async () => {
        try {
            await checkIn({ venueId, venueName, groupId: form.groupId });
            onSuccess?.();
            return true;
        } catch (error) {
            console.error(error);
            showError('Failed to register');
        }
    });

    const loadBarById = useApiQuery(() =>
        MicroserviceLocation.appCustomerPost({
            action: 'handlerAppCustomerBrowseDetails',
            locationId: covidTagTargetBarId,
        }),
    );
    const groups: any[] = orderBy(loadBarById.response?.groups || [], (item) => item.sequence);

    useEffect(() => {
        if (initialAutoRegister) {
            register();
        }
    }, [initialAutoRegister]);

    useEffect(() => {
        if (covidTagTargetBarId) {
            loadBarById.run();
        }
    }, [covidTagTargetBarId]);

    if (!showAlways && initialAutoRegister) {
        return null;
    }

    if (isLoadingRegister) {
        return <PosPageLoader message="Please wait..." />;
    }

    const isValidForm = isValidName && isValidContact && isConfirmed && (!covidTagTargetBarId || Boolean(form.groupId));

    if (isSuccess) {
        return (
            <Wrapper>
                <UiOverlay transparent>
                    <UiFlex fullWidth fullHeight justifyContent="center" padding={4}>
                        <UiGrid className="animated fadeIn" gap={4}>
                            <UiGrid>
                                <UiText color="success" align="center" className="register-success">
                                    <UiGrid>
                                        <UiIconMaterial>check_circle</UiIconMaterial>

                                        <div>
                                            <UiText size={4}>You are checked into:</UiText>
                                            <UiText size={6} weight={2}>
                                                {venueName}
                                            </UiText>
                                        </div>
                                    </UiGrid>
                                </UiText>

                                <UiText align="center" size={2}>
                                    <div>Last check-in time:</div>
                                    <div>{lastRegistrationDate?.format('DD/MM/YYYY HH:mm') ?? '-'}</div>
                                </UiText>
                            </UiGrid>

                            <UiButton to="/" variant="filled" size={1}>
                                Continue
                            </UiButton>
                        </UiGrid>
                    </UiFlex>
                </UiOverlay>
            </Wrapper>
        );
    }

    return (
        <Wrapper padding={4}>
            <UiText size={4} align="center">
                {venueName}
            </UiText>

            <UiText color="warning" size={1}>
                Due to COVID-19 related circumstances, you are required to provide valid contact information about
                yourself.
            </UiText>

            {groups.length > 0 && (
                <UiInput
                    value={form.groupId}
                    onChangeValue={(groupId) =>
                        setForm((form) => {
                            form.groupId = groupId;
                        })
                    }
                    options={groups.map((group) => ({
                        label: group.name,
                        value: group.id,
                    }))}
                    label="Choose table"
                    placeholder="Choose table"
                />
            )}

            <UiFlex onClick={() => setStep(1)} gap={1}>
                <label className="step">1</label>

                <UiText {...getUiTextPropsByStep(1)}>{step === 1 ? 'Enter your name' : `Name: ${name || ''}`}</UiText>
            </UiFlex>

            {step === 1 && (
                <UiFlex gap={1}>
                    <UiInput
                        value={name || ''}
                        onChangeValue={(name) =>
                            setForm((form) => {
                                form.name = name;
                            })
                        }
                        label="Enter your name"
                        name="name"
                        fullWidth
                    />
                    <UiButton
                        onClick={() => {
                            setStep(2);
                        }}
                        variant="filled"
                        color="primary">
                        Next
                    </UiButton>
                </UiFlex>
            )}

            {step > 1 && (
                <>
                    <UiFlex onClick={() => setStep(2)} gap={1}>
                        <label className="step">2</label>
                        <UiText {...getUiTextPropsByStep(2)}>
                            {step === 2 ? (
                                <>Enter your contact phone</>
                            ) : (
                                <>
                                    {'Contact: '}
                                    {contactType === 'phone' ? (
                                        <>
                                            {areaCode?.startsWith('+') ? areaCode : `+${areaCode || ''}`}
                                            {phone}
                                        </>
                                    ) : (
                                        <>{email}</>
                                    )}
                                </>
                            )}
                        </UiText>
                    </UiFlex>

                    {step === 2 && (
                        <>
                            {/*<UiFlex gap={1}>*/}
                            {/*    <UiButton*/}
                            {/*        onClick={() =>*/}
                            {/*            setForm((form) => {*/}
                            {/*                form.contactType = 'email';*/}
                            {/*            })*/}
                            {/*        }*/}
                            {/*        variant="filled"*/}
                            {/*        fullWidth*/}
                            {/*        color={isPhone ? 'default' : 'secondary'}>*/}
                            {/*        Email*/}
                            {/*    </UiButton>*/}
                            {/*    <UiText weight={2} size={-1} transform="uppercase" color="text-secondary">*/}
                            {/*        Or*/}
                            {/*    </UiText>*/}
                            {/*    <UiButton*/}
                            {/*        onClick={() =>*/}
                            {/*            setForm((form) => {*/}
                            {/*                form.contactType = 'phone';*/}
                            {/*            })*/}
                            {/*        }*/}
                            {/*        variant="filled"*/}
                            {/*        fullWidth*/}
                            {/*        color={isPhone ? 'secondary' : 'default'}>*/}
                            {/*        Phone*/}
                            {/*    </UiButton>*/}
                            {/*</UiFlex>*/}

                            {isPhone ? (
                                <UiFlex gap={1}>
                                    <XInputPhone
                                        areaCode={areaCode || ''}
                                        phone={phone || ''}
                                        onChange={({ areaCode, phone }) => {
                                            setForm((form) => {
                                                form.areaCode = areaCode;
                                                form.phone = phone;
                                            });
                                        }}
                                    />
                                    <UiButton
                                        onClick={() => {
                                            setStep(3);
                                        }}
                                        variant="filled"
                                        color="primary"
                                        disabled={!isValidPhone}>
                                        Next
                                    </UiButton>
                                </UiFlex>
                            ) : (
                                <UiFlex gap={1}>
                                    <UiInput
                                        value={email || ''}
                                        onChangeValue={(email) => {
                                            setForm((form) => {
                                                form.email = email;
                                            });
                                        }}
                                        label="Enter your email"
                                        type="email"
                                        name="email"
                                        fullWidth
                                    />
                                    <UiButton
                                        onClick={() => {
                                            setStep(3);
                                        }}
                                        variant="filled"
                                        color="primary"
                                        disabled={!isValidEmail}>
                                        Next
                                    </UiButton>
                                </UiFlex>
                            )}
                        </>
                    )}
                </>
            )}

            {step > 2 && (
                <>
                    <UiFlex onClick={() => setStep(3)} gap={1}>
                        <label className="step">3</label>
                        <UiText {...getUiTextPropsByStep(3)}>Confirm</UiText>
                    </UiFlex>

                    <UiGrid gap={0} className="button-checkbox">
                        <UiButtonCheckbox value={isAutoRegister} onChange={setIsAutoRegister}>
                            Auto check-in into other venues
                        </UiButtonCheckbox>

                        <UiButtonCheckbox
                            value={isConfirmed}
                            onChange={(isConfirmed) => {
                                setForm((form) => {
                                    form.isConfirmed = isConfirmed;
                                });
                            }}>
                            I confirm I don't have any symptoms of COVID-19 &{' '}
                            <a
                                className="underlined"
                                onClick={(event) => {
                                    event.stopPropagation();
                                    setIsTermsOpen(true);
                                }}>
                                I agree to terms
                            </a>{' '}
                            for my contact information being stored for the next 10 days.
                        </UiButtonCheckbox>
                    </UiGrid>
                </>
            )}

            <UiFlex gap={1} flexChildren>
                {onCancel && (
                    <UiButton
                        onClick={() => {
                            onCancel();
                        }}
                        variant="filled"
                        size={1}>
                        Cancel
                    </UiButton>
                )}

                {step === 3 && (
                    <UiButton
                        onClick={register}
                        isLoading={isLoadingRegister}
                        disabled={!isValidForm}
                        variant="filled"
                        color="primary"
                        size={1}>
                        I Confirm
                    </UiButton>
                )}
            </UiFlex>

            {isTermsOpen && (
                <UiOverlay position={'top' as any}>
                    <UiToolbar>
                        <ToolbarTitle title="Terms and Conditions" buttonBackOnClick={() => setIsTermsOpen(false)} />
                    </UiToolbar>

                    <UiBox padding={2}>
                        <div dangerouslySetInnerHTML={{ __html: termsAndConditions }} />
                    </UiBox>
                </UiOverlay>
            )}
        </Wrapper>
    );
}
