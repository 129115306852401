import IconPrint from '@material-ui/icons/PrintRounded';
import React, { useState } from 'react';
import { useSnackbar } from '../../../../../../shared/snackbar';
import { useApiQuery } from '@orderx/http';
import { usePrintPreSalesReceipt } from '../../../../../../util/use-print-pre-sales-receipt';
import { StaffOrderTerminalSelection } from '../../../../../staff-order/StaffOrderTerminalSelection';
import { UiButton } from '../../../../../ui/button/UiButton';

export function PosGroupListItemActionPrint({ group }) {
    const [isPrintOpen, setIsPrintOpen] = useState(false);
    const { showSuccess } = useSnackbar();
    const printPreSalesReceipt = usePrintPreSalesReceipt();

    const { isLoading: isPrinting, run: printProducts } = useApiQuery(async (terminal) => {
        await printPreSalesReceipt({
            barId: group.barId,
            groupName: group.name,
            terminal,
            products: group.products,
        });

        showSuccess('Printing');
        setIsPrintOpen(false);
    });

    return (
        <>
            <UiButton
                onClick={() => setIsPrintOpen(true)}
                isLoading={isPrinting}
                icon={<IconPrint />}
                variant="filled"
            />

            {isPrintOpen && (
                <StaffOrderTerminalSelection
                    open
                    onClose={() => setIsPrintOpen(false)}
                    onTerminal={(terminal) => printProducts(terminal)}
                    isLoading={isPrinting}
                />
            )}
        </>
    );
}
