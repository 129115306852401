import React from 'react';
import styled from 'styled-components';
import { themeActive } from '../../themes/dark';
import { ProgressBar } from '../ProgressBar';
import { V2PageHeaderButton } from './V2PageHeaderButton';
import { UiText } from '@orderx/ui';

const Wrapper = styled.div`
    background: ${(props) => props.background || themeActive.tabs.backgroundColor};

    position: fixed;
    z-index: 2;
    left: 0;
    top: 0;
    width: 100%;
    height: 50px;
    padding: 0 16px;

    display: flex;
    align-items: center;
    justify-content: space-between;
`;

const ContentLeft = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: 26px;

    button {
        padding-right: 12px;
    }
`;

const ContentRight = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-end;
    width: 26px;

    button {
        padding-left: 12px;
    }
`;

const Placeholder = styled.div`
    height: ${(props) => props.height}px;
`;

export function V2PageHeader({
    background,
    onClickBack,
    title,
    contentLeft,
    contentRight,
    isLoading = false,
    transparent = false,
    opacity = false,
    children,
    large = false,
}) {
    const content = (children && <div>{children}</div>) ||
        (title && (
            <UiText size={1} weight={2}>
                {title}
            </UiText>
        )) || <div />;
    const height = large ? 100 : 50;
    const backgroundColor = (transparent && 'transparent') || (opacity && 'rgba(0,0,0,0.25)') || background;

    return (
        <>
            <Wrapper background={backgroundColor} height={height}>
                <ContentLeft>
                    {onClickBack && <V2PageHeaderButton onClick={onClickBack} icon="arrow-left" />}
                    {contentLeft}
                </ContentLeft>

                {content}

                <ContentRight>{contentRight}</ContentRight>

                {isLoading && <ProgressBar />}
            </Wrapper>

            <Placeholder height={height} />
        </>
    );
}
