import React, { Fragment, useEffect, useState } from 'react';
import styled from 'styled-components';
import { useDevice } from '../shared/device';
import { storageGet } from '../shared/storage';
import { stores } from '../stores';
import { useNfcCallback } from '../stores/nfc';
import { Button } from './Button';
import { Input } from './Input';
import { SvgIcons } from './Svg';

const InputWrapper = styled.div`
    border-radius: var(--border-radius);
    overflow: hidden;
    padding: 8px;
    background: #000;
    border: 1px solid #111;
    display: flex;
    justify-content: flex-end;
    animation-duration: 300ms;

    input {
        width: 160px;
    }

    > * {
        border-radius: 0;

        :first-child {
            border-top-left-radius: 8px;
            border-bottom-left-radius: 8px;
        }

        :last-child {
            border-top-right-radius: 8px;
            border-bottom-right-radius: 8px;
        }
    }
`;

export function NfcListener({ onTagRead }) {
    const { isIos, isNative } = useDevice();
    const [tag, setTag] = useState(storageGet('rdxNfc') || '');
    const [nfcValue, setNfcValue] = useState();

    useEffect(() => {
        if (nfcValue) {
            onTagRead(nfcValue);
        }
    }, [nfcValue]);

    useNfcCallback((id) => {
        setNfcValue({ id });
    });

    useEffect(() => {
        if (isIos) {
            window.nfc.beginSession();
        }
    }, [isIos]);

    if (isNative) {
        return <Fragment />;
    }

    return (
        <InputWrapper className="animated fadeInRight">
            <Input margin={0} value={tag} onChangeValue={setTag} />

            <Button
                primary
                onClick={() => {
                    setNfcValue({
                        id: tag,
                        payload: null,
                    });

                    stores.nfc.set((state) => {
                        state.scanning = true;
                        // state.scanOpen = false;
                    });

                    setTimeout(() => {
                        stores.nfc.set((state) => {
                            state.scanning = false;
                        });
                    }, 2000);
                }}>
                <span>Emulate</span>
            </Button>

            <Button
                icon={SvgIcons.Close}
                onClick={() => {
                    stores.nfc.set((state) => {
                        state.scanOpen = false;
                    });
                }}
            />
        </InputWrapper>
    );
}
