import React, { useState } from 'react';
import { sendLocationQueueInvites } from '../microservices/bookx';
import { showError, showSuccess } from '../shared/toasts';
import { useBookXLocation } from '../stores/bookx-location';
import { useInput } from '../use/form';
import { Button } from './Button';
import { Input } from './Input';
import { InputLabel } from './InputLabel';

export function StaffQueueActions({ onLoadQueue }) {
    const location = useBookXLocation();
    const inputInvites = useInput('');
    const [isLoading, setIsLoading] = useState(false);

    async function sendInvites() {
        setIsLoading(true);

        try {
            await sendLocationQueueInvites(location.id, Number(inputInvites.value));
            await onLoadQueue();
            inputInvites.reset();
            showSuccess('Invites have been sent');
        } catch (error) {
            showError(error);
        }

        setIsLoading(false);
    }

    return (
        <div>
            <h3>Send invites</h3>

            <InputLabel>Number of people</InputLabel>
            <Input type="tel" {...inputInvites} disabled={isLoading} placeholder="Number of people" />

            <Button onClick={sendInvites} isLoading={isLoading} block>
                Send invites
            </Button>
        </div>
    );
}
