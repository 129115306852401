import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import imagePour from '../assets/pouring.jpg';
import { AnimateCount } from '../components/AnimateCount';
import { BackgroundCover } from '../components/BackgroundCover';
import { Currency } from '../components/Currency';
import { DatepickerWithFilters } from '../components/DatepickerWithFilters';
import { PageSection } from '../components/PageSection';
import { TableTitle } from '../components/TableTitle';
import { Tabs } from '../components/Tabs';
import { V2Page } from '../components/v2/V2Page';
import { V2PageHeader } from '../components/v2/V2PageHeader';
import { V2Table } from '../components/v2/V2Table';
import { getStaffPerformanceReport } from '../microservices/reporting';
import { endOfToday, startOfToday } from '../shared/moment';
import { routes, withParams } from '../shared/router';
import { showError } from '../shared/toasts';
import { translate } from '../shared/translate';
import { stores, useStore } from '../stores';
import { useInterval } from '../use/time';
import { useHistory } from 'react-router-dom';

const Title = styled.div`
    font-size: 3rem;
    font-weight: 300;
`;

const TextCenter = styled.div`
    text-align: center;
`;

const ReportingBackground = styled.div`
    background: #000;
    padding: 32px 0;
    margin: 0 16px 16px 16px;
    border-radius: 12px;
    box-shadow: var(--box-shadow);
    position: relative;
    overflow: hidden;

    .PageSection {
        position: relative;
    }
`;

const PourLabel = styled.div`
    color: #aaa;
    text-transform: uppercase;
    font-size: 0.75rem;
    letter-spacing: 1px;
    margin-bottom: 8px;
`;

const OverviewFilter = {
    Summary: 'SUMMARY',
    PerBar: 'PER_BAR',
};

export function StaffVenueReportingStaff() {
    const history = useHistory();
    const [{ venue }] = useStore(stores.staff);
    const [report, setReport] = useState({
        venue: {
            id: '',
            name: '',
            totals: {
                ordersAmount: 0,
                revenue: 0,
            },
            staff: [],
        },
        bars: [],
    });
    const [isLoading, setIsLoading] = useState(true);
    const [overviewFilter, setOverviewFilter] = useState(OverviewFilter.Summary);
    const [from, setFrom] = useState(startOfToday());
    const [to, setTo] = useState(endOfToday());

    // TODO: Missing from API
    const currency = venue.currency || 'NOK';

    useEffect(() => {
        loadPerformanceReport({ from, to });
    }, [from, to]);

    useInterval(
        5 * 1000,
        () => {
            loadPerformanceReport({ from, to });
        },
        [from, to],
    );

    async function loadPerformanceReport({ from, to }) {
        if (from && to) {
            setIsLoading(true);

            try {
                const report = await getStaffPerformanceReport({
                    venueId: venue.id,
                    fromMilliseconds: from.unix() * 1000,
                    toMilliseconds: to.unix() * 1000,
                });

                setReport(report);
            } catch (error) {
                showError(error);
            }

            setIsLoading(false);
        }
    }

    function getStaffReporting(staff) {
        return (
            <V2Table headers={['Staff', 'Orders', 'Revenue']}>
                {staff.map((staff) => (
                    <TableRow key={staff.id}>
                        <TableCell>{staff.id}</TableCell>
                        <TableCell align="right">{staff.ordersAmount}</TableCell>
                        <TableCell align="right">
                            <Currency amount={staff.revenue} />
                        </TableCell>
                    </TableRow>
                ))}
            </V2Table>
        );
    }

    return (
        <V2Page
            header={
                <V2PageHeader
                    isLoading={isLoading}
                    title={`${venue.name} • Staff`}
                    onClickBack={() => history.push(withParams(routes.staffVenueReporting, { venueId: venue.id }))}
                />
            }>
            <DatepickerWithFilters onChangeFrom={setFrom} onChangeTo={setTo} />

            {report && (
                <>
                    <ReportingBackground>
                        <BackgroundCover image={imagePour} />

                        <PageSection>
                            <TextCenter>
                                <PourLabel>Total revenue</PourLabel>
                                <Title>
                                    <AnimateCount value={report.venue.totals.revenue} currency={currency} />
                                </Title>
                            </TextCenter>
                        </PageSection>
                    </ReportingBackground>

                    {report.venue.totals.revenue > 0 && (
                        <>
                            <Tabs
                                tabs={[
                                    {
                                        label: translate('Summary'),
                                        value: OverviewFilter.Summary,
                                    },
                                    {
                                        label: translate('Per bar'),
                                        value: OverviewFilter.PerBar,
                                    },
                                ]}
                                value={overviewFilter}
                                onChange={setOverviewFilter}
                            />

                            {overviewFilter === OverviewFilter.Summary && getStaffReporting(report.venue.staff)}

                            {overviewFilter === OverviewFilter.PerBar &&
                                report.bars.map((bar) => (
                                    <div key={bar.id}>
                                        <TableTitle>{bar.name}</TableTitle>

                                        {getStaffReporting(bar.staff)}
                                    </div>
                                ))}
                        </>
                    )}
                </>
            )}
        </V2Page>
    );
}
