import classnames from 'classnames';
import React, { useState } from 'react';
import styled from 'styled-components';

const Wrapper = styled.div`
    left: 0;
    top: 0;
    height: ${(props) => (props.height ? `${props.height}px` : '100%')};
    width: 100%;
    position: absolute;
    z-index: ${({ zIndex = 0 }) => zIndex};

    .overlay {
        left: 0;
        top: 0;
        height: ${(props) => (props.height ? `${props.height}px` : '100%')};
        width: 100%;
        position: absolute;
    }

    &.placeholder {
        align-items: center;
        display: flex;
        justify-content: center;

        svg {
            color: #ccc;
            font-size: 2rem;
        }
    }

    .overlay {
        z-index: 1;
    }

    .wrapper {
        height: ${(props) => (props.height ? `${props.height}px` : '100%')};
        width: 100%;
        background-size: cover;
        opacity: 0;
        transition: 300ms opacity, transform 300ms;

        &.loaded {
            opacity: 1;
        }
    }

    img {
        opacity: 0;
        position: absolute;
    }
`;

const isSafari = !!navigator.userAgent.match(/Version\/[\d\.]+.*Safari/);

export function BackgroundCoverImage({ image, zIndex = 0, overlayColor = '', height = 0, darkOverlay = '' }) {
    const [loaded, setLoaded] = useState(false);

    if (!image) {
        return <Wrapper />;
    }

    if (darkOverlay) {
        overlayColor = 'rgba(0, 0, 0, 0.75)';
    }

    return (
        <Wrapper zIndex={zIndex} height={height}>
            {overlayColor && (
                <div
                    className="overlay"
                    style={{
                        background: darkOverlay ? 'rgba(0, 0, 0, 0.75)' : overlayColor,
                    }}
                />
            )}

            {isSafari ? (
                <>
                    {loaded && (
                        <div
                            style={{ backgroundImage: `url(${image})` }}
                            className={classnames('wrapper animated', { loaded })}
                        />
                    )}
                </>
            ) : (
                <div style={{ backgroundImage: `url(${image})` }} className="wrapper animated fadeIn" />
            )}

            <img alt="placeholder" src={image} onLoad={() => setLoaded(true)} />
        </Wrapper>
    );
}
