import throttle from 'lodash/throttle';
import { connect } from 'mqtt';
import { useEffect, useState } from 'react';
import { createStore, useStore } from '../create-store';
import { isDev, IS_DEV } from '../environment';
import { getIotUrl } from '../microservices/me-v1';
import { Log } from '../util/logger';

let id = 0;
const idsPublished = new Set();

export const AwsIotStatus = {
    CONNECTED: 'connected',
    RECONNECTING: 'reconnecting',
    DISCONNECTED: 'disconnected',
    OFFLINE: 'offline',
    ERROR: 'error',
    ENDING: 'ending',
};

const iotStatus = createStore({
    status: '',
    connected: false,
});

const brokerUrlStore = createStore();
const iotClient = createStore();
const publishQueue = createStore([]);

const subscriptions = {};

async function loadBrokerUrl() {
    // Log.dev('aws-iot', 'loadBrokerUrl');

    try {
        brokerUrlStore.set(await getIotUrl());
    } catch (error) {
        console.error(error);
    }
}

const loadBrokerUrlThrottled = throttle(loadBrokerUrl, IS_DEV ? 60000 : 5000);

export function AwsIotContainer() {
    const [events, setEvents] = useStore(publishQueue);
    const [client, setClient] = useStore(iotClient);
    const [brokerUrl] = useStore(brokerUrlStore);
    const [status, setStatus] = useState();

    useEffect(() => {
        loadBrokerUrlThrottled();
    }, []);

    useEffect(() => {
        if (brokerUrl) {
            connectToClient(brokerUrl);
        }
    }, [brokerUrl]);

    useEffect(() => {
        if (status === AwsIotStatus.DISCONNECTED) {
            // Log.dev('aws-iot', 'AwsIotContainer', 'loadBrokerUrlThrottled');
            loadBrokerUrlThrottled();
        }

        if (status === AwsIotStatus.CONNECTED) {
            loadBrokerUrlThrottled.cancel();
        }
    }, [status]);

    useEffect(() => {
        if (client && status) {
            iotStatus.set({
                connected: client.connected,
                status,
            });
        }
    }, [client, status]);

    useEffect(() => {
        if (client && events.length > 0) {
            const idsToRemove = [];

            for (const { id, topic, data } of events) {
                if (idsPublished.has(id)) {
                    return;
                }

                idsToRemove.push(id);
                idsPublished.add(id);

                // Log.dev('aws-iot', 'AwsIotContainer', 'publish', topic, data);
                client.publish(topic, data && JSON.stringify(data, null, 4));
            }

            setEvents((events) => events.filter((event) => !idsToRemove.includes(event.id)));
        }

        if (events.length === 0) {
            idsPublished.clear();
        }
    }, [client, events]);

    useEffect(() => {
        return () => {
            if (client) {
                // Log.dev('aws-iot', 'AwsIotContainer', 'end previous connection');
                client.end(true);
            }
        };
    }, [client]);

    function connectToClient(brokerUrl) {
        if (client && client.connected) {
            console.log('Already connected...');
        }

        // Log.dev('aws-iot', 'AwsIotContainer', 'connectToClient');

        const client = connect(brokerUrl, { reconnectPeriod: 10000 });

        client.on('connect', () => {
            // Log.dev('aws-iot', 'AwsIotContainer', 'connect');
            setStatus(AwsIotStatus.CONNECTED);
        });

        client.on('reconnect', () => {
            setStatus(AwsIotStatus.RECONNECTING);
        });

        client.on('close', () => {
            setStatus(AwsIotStatus.DISCONNECTED);
        });

        client.on('offline', () => {
            // Log.dev('aws-iot', 'AwsIotContainer', 'offline');
            setStatus(AwsIotStatus.OFFLINE);
        });

        client.on('error', (error) => {
            // Log.dev('aws-iot', 'AwsIotContainer', 'error', error);
            setStatus(AwsIotStatus.ERROR);
        });

        client.on('end', () => {
            // Log.dev('aws-iot', 'AwsIotContainer', 'end');
            setStatus(AwsIotStatus.ENDING);
        });

        client.on('message', (topic, message) => {
            const parsedMessage = parseMessage(message);
            Log.dev('aws-iot', 'AwsIotContainer', 'message', topic, parsedMessage);

            for (const callback of subscriptions[topic].values()) {
                callback(parsedMessage);
            }
        });

        setClient(client);
    }

    function parseMessage(message) {
        const messageAsString = message.toString();

        try {
            return JSON.parse(messageAsString);
        } catch (error) {
            return messageAsString;
        }
    }

    return null;
}

export function useAwsIotSubscription(topic, callback, inputs = []) {
    const [client] = useStore(iotClient);

    useEffect(() => {
        if (client && topic) {
            // Log.dev('aws-iot', 'subscribe', topic);
            client.subscribe(topic);

            subscriptions[topic] = subscriptions[topic] || new Set();
            subscriptions[topic].add(callback);

            return () => {
                // Log.dev('aws-iot', 'unsubscribe', topic);
                subscriptions[topic].delete(callback);
            };
        }
    }, [client, topic, ...inputs]);
}

export function useAwsIotStatus() {
    const [{ connected, status }] = useStore(iotStatus);
    return { connected, status };
}

export function publishToAwsIot(topic, data) {
    id += 1;
    publishQueue.set((queue) => [...queue, { id, topic, data }]);
}

if (isDev) {
    window.publishToAwsIot = publishToAwsIot;
}
