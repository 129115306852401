import { CreateOrder } from '../modules/create-order';

export function resetOrderProductState() {
    CreateOrder.reset();
}

export function addOrderProducts(product, count) {
    CreateOrder.addProducts(product, count);
}

export function removeOrderProducts(product, count) {
    CreateOrder.removeProducts(product, count);
}

export function removeOrderProduct(productId) {
    CreateOrder.removeProductById(productId);
}

export function updateOrderProducts(productsToUpdate) {
    CreateOrder.updateOrderProducts(productsToUpdate);
}
