import { createStore, useStore } from '../create-store';
import { MicroserviceBookX } from '../microservices/bookx';

const store = createStore({});

export function useBookXLocation() {
    const [state, setState] = useStore(store);
    const { location } = state;

    function setLocation(location) {
        setState(state => {
            state.location = location;
        });
    }

    return {
        ...state,
        id: location?.id,
        locationId: location?.id,
        location,
        async load(locationId) {
            try {
                setLocation(await MicroserviceBookX.getLocationById(locationId || location?.id));
            } catch (error) {
                console.error(error);
            }
        },

        setLocation,
    };
}
