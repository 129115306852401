import moment from 'moment';
import 'moment/locale/en-gb';

moment.locale('en-gb');

export function toMoment(value = new Date()) {
    return moment(value);
}

export function startOfToday() {
    const value = moment()
        .startOf('day')
        .add(7, 'hour');

    if (moment().format() < value.format()) {
        value.subtract(24, 'hour');
    }

    return value;
}

export function startOfIsoWeek() {
    return moment()
        .startOf('isoWeek')
        .add(7, 'hour');
}

export function startOfMonth() {
    return moment()
        .startOf('month')
        .add(7, 'hour');
}

export function endOfToday() {
    return moment().endOf('day');
}
