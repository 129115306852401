import React from 'react';
import { NavLink } from 'react-router-dom';
import styled from 'styled-components';
import imageBottomsUp from '../assets/bottoms-up.jpg';
import imageStaffPerformance from '../assets/reporting-staff.jpg';
import imageRevenue from '../assets/reporting-revenue.jpg';
import { BackgroundCoverImage } from '../components/BackgroundCoverImage';
import { V2PageHeader } from '../components/v2/V2PageHeader';
import { getUrlToAsset } from '../shared/assets';
import { routes, withParams } from '../shared/router';
import { stores, useStore } from '../stores';
import { theme } from '../themes/dark';
import { useHistory } from 'react-router-dom';

const ReportingItemsWrapper = styled.div`
    display: grid;
    grid-gap: 16px;
    grid-template-columns: 1fr 1fr;
    padding: 16px;
`;

const ReportingItem = styled(NavLink)`
    height: 160px;
    padding: 16px;
    border-radius: ${theme.sizes.borderRadius}px;
    overflow: hidden;
    position: relative;
    box-shadow: var(--box-shadow);
    font-size: 1.25rem;

    display: flex;
    align-items: center;
    justify-content: center;
`;

const Content = styled.div`
    position: relative;
    padding: 16px;
`;

export function StaffVenueReportingDashboard() {
    const history = useHistory();
    const [{ venue }] = useStore(stores.staff);
    const { name } = venue;
    const overlayColor = 'linear-gradient(rgba(0, 0, 0, 0.9), rgba(0, 0, 0, 0.5))';

    function withVenueId(route) {
        return withParams(route, { venueId: venue.id });
    }

    return (
        <div>
            <V2PageHeader title={name} onClickBack={() => history.push(routes.customerProfile)} />

            <ReportingItemsWrapper>
                <ReportingItem to={withVenueId(routes.staffVenueReportingTotalRevenue)}>
                    <BackgroundCoverImage image={getUrlToAsset(imageRevenue)} overlayColor={overlayColor} />
                    <Content>Revenue</Content>
                </ReportingItem>

                <ReportingItem to={withVenueId(routes.staffVenueReportingBottomsUp)}>
                    <BackgroundCoverImage image={getUrlToAsset(imageBottomsUp)} overlayColor={overlayColor} />
                    <Content>Bottoms Up</Content>
                </ReportingItem>

                <ReportingItem to={withVenueId(routes.staffVenueReportingStaff)}>
                    <BackgroundCoverImage image={getUrlToAsset(imageStaffPerformance)} overlayColor={overlayColor} />
                    <Content>Staff</Content>
                </ReportingItem>
            </ReportingItemsWrapper>
        </div>
    );
}
