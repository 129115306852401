import React from 'react';
import { BottomTab } from '../../BottomTab';
import { useCustomerTabs } from '../../../stores/customer-tabs';
import { UiToolbar } from '@orderx/ui';
import { useCovidRegistration } from '../../../stores/covid-registration';
import { CustomerBottomNavCovid } from './covid/CustomerBottomNavCovid';

export function CustomerBottomNav() {
    const { isHidden, tabs } = useCustomerTabs();
    const { lastRegistrationDate } = useCovidRegistration();

    if (isHidden) {
        return null;
    }

    return (
        <UiToolbar position="bottom">
            {lastRegistrationDate && <CustomerBottomNavCovid />}

            {tabs.map((route) => (
                <BottomTab {...route} icon={route.icon} label={route.label} to={route.path} key={route.path} />
            ))}
        </UiToolbar>
    );
}
