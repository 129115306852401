import AddCircleRounded from '@material-ui/icons/AddCircleRounded';
import ReceiptRounded from '@material-ui/icons/ReceiptRounded';
import clsx from 'clsx';
import React, { useState } from 'react';
import { PosGroupCreatePage } from '../../../../pages/pos/group/create/PosGroupCreatePage';
import { PosGroupIdPage } from '../../../../pages/pos/group/id/PosGroupIdPage';
import { withParams } from '../../../../shared/router';
import { PosGroupListItemActionArchive } from './action/archive/PosGroupListItemActionArchive';
import { PosGroupListItemActionPrint } from './action/print/PosGroupListItemActionPrint';
import './PosGroupListItem.scss';
import { UiButton } from '../../../ui/button/UiButton';
import { UiGrid, UiText, UiFlex, UiInput } from '@orderx/ui';
import { useApiQuery } from '@orderx/http';
import { MicroserviceOrders } from '../../../../microservices/orders';
import { ButtonGroup } from '../../../../@v2/components/button/group/ButtonGroup';
import { usePosGroups } from '../../../../hooks/pos/groups';

export function PosGroupListItem({ group, isEdit }) {
    const { loadGroups } = usePosGroups();
    const {
        id: groupId,
        name,
        isAllProductsPaid,
        hasPendingOrders,
        amountPaid,
        amountTotal,
        barId,
        isHidden,
        deliveryTypeDefault,
    } = group;

    const { run: update, isLoading: updateLoading } = useApiQuery(async (form) => {
        await MicroserviceOrders.staffApp({
            action: 'handlerAppStaffConfigurationUpdate',
            method: 'UPDATE_GROUP',
            groupIds: [group.id],
            form,
        });

        await loadGroups();
    });

    return (
        <UiGrid className={clsx('PosGroupListItem')} spacing={1}>
            <div className="name">{name}</div>

            {amountTotal > 0 && (
                <UiFlex
                    className={clsx('payment', isAllProductsPaid && 'all-paid')}
                    justifyContent="center"
                    flexChildren>
                    <UiText>{amountPaid / 100},-</UiText>

                    {!isAllProductsPaid && (
                        <>
                            <span className="divider">/</span>
                            <span>{amountTotal / 100},-</span>
                        </>
                    )}
                </UiFlex>
            )}

            <UiFlex justifyContent="center" spacing={1} flexChildren>
                {isAllProductsPaid && <PosGroupListItemActionArchive group={group} />}
                {hasPendingOrders && <PosGroupListItemActionPrint group={group} />}

                {(hasPendingOrders || isAllProductsPaid) && (
                    <UiButton
                        variant="filled"
                        to={withParams(PosGroupIdPage.route, { barId, groupId })}
                        icon={<ReceiptRounded />}
                    />
                )}

                <UiButton
                    variant="filled"
                    to={withParams(PosGroupCreatePage.route, { barId, groupId })}
                    icon={<AddCircleRounded />}
                />
            </UiFlex>

            {isEdit && (
                <UiGrid className="button-group-container" gap={1}>
                    <ButtonGroup>
                        <UiButton
                            onClick={() => update({ isHidden: false })}
                            disabled={updateLoading}
                            color={isHidden ? 'default' : 'success'}
                            variant="filled"
                            size={2}>
                            Visible
                        </UiButton>

                        <UiButton
                            onClick={() => update({ isHidden: true })}
                            disabled={updateLoading}
                            color={isHidden ? 'error' : 'default'}
                            variant="filled"
                            size={2}>
                            Hidden
                        </UiButton>
                    </ButtonGroup>

                    <UiInput
                        value={deliveryTypeDefault}
                        onChangeValue={(deliveryTypeDefault) => update({ deliveryTypeDefault })}
                        disabled={updateLoading}
                        label="Default delivery type"
                        placeholder="Def. delivery type"
                        options={[
                            {
                                label: 'Pickup',
                                value: 'PICKUP',
                            },
                            {
                                label: 'Table',
                                value: 'TABLE',
                            },
                        ]}
                    />
                </UiGrid>
            )}
        </UiGrid>
    );
}
