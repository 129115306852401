import { createMuiTheme } from '@material-ui/core/styles';
import { themeActive } from './dark';
import 'material-ui-pickers/typings/overrides';

export const materialDesignTheme = createMuiTheme({
    typography: {
        fontFamily: `'Texta', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell',
                'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif`,
        useNextVariants: true,
    },
    palette: {
        type: 'dark',
        primary: {
            light: '#6783ec',
            main: '#4265e8',
            dark: '#2e46a2',
            contrastText: '#fff',
        },
        secondary: {
            light: '#f73378',
            main: '#f50057',
            dark: '#ab003c',
            contrastText: '#000',
        },
    },
    overrides: {
        MuiLinearProgress: {
            indeterminate: {
                height: 2,
                backgroundColor: themeActive.header.loaderBackgroundColor,
            },
            bar1Indeterminate: {
                backgroundColor: themeActive.colors.primary,
            },
            bar2Indeterminate: {
                backgroundColor: themeActive.colors.primary,
            },
        },
        MuiBottomNavigationAction: {
            root: {
                backgroundColor: themeActive.tabs.backgroundColor,
                backgroundImage: themeActive.gradients.dark,
                color: themeActive.colors.text,
                '&$selected': {
                    color: themeActive.colors.primary,
                },
            },
        },
        MuiButton: {
            root: {
                height: 44,
                textTransform: 'initial',
                fontSize: '1rem',
                fontWeight: 700,
                padding: '0 16px',
                whiteSpace: 'nowrap',
                '&$disabled': {
                    color: `${themeActive.colors.text} !important`,
                    opacity: 0.5,
                },
            },
            sizeSmall: {
                height: 32,
            },
            sizeLarge: {
                height: 64,
                fontSize: '1.5rem',
                fontWeight: 400,
                borderRadius: 0,
            },
            label: {
                display: 'flex',
                alignItems: 'center',
            },
            outlined: {
                color: themeActive.colors.text,
                borderColor: 'transparent',
            },
        },
        MuiIconButton: {
            root: {
                padding: 8,
            },
        },
        MuiFormControl: {
            root: {
                width: '100%',
            },
            marginNormal: {
                margin: '0 !important',
            },
        },
        MuiFormLabel: {
            root: {
                fontSize: '0.8rem',
                lineHeight: 0.4,
            },
        },
        MuiInput: {
            root: {
                backgroundColor: 'white',
                color: themeActive.colors.textReversed,
                borderRadius: themeActive.sizes.borderRadius,
                padding: '6px 16px',
                '&$focused': {
                    borderColor: themeActive.colors.text,
                },
            },
        },
        MuiOutlinedInput: {
            input: {
                padding: '12.5px 14px',
            },
        },
        MuiInputLabel: {
            root: {
                color: '#aaa',
                fontWeight: 700,
                textTransform: 'uppercase',
                letterSpacing: 1,
                '&$focused': {
                    color: themeActive.colors.text,
                },
            },
            shrink: {
                transform: 'translate(14px, -1px) scale(0.75) !important',
            },
        },
        MuiAppBar: {
            root: {
                backgroundColor: 'transparent !important',
                boxShadow: '0 2px 0px #222',
            },
        },
        MuiTab: {
            root: {
                color: `${themeActive.colors.text} !important`,
                textTransform: 'initial',
                minWidth: 'initial !important',
                '&$selected': {
                    color: `#ff9876 !important`,
                },
                paddingLeft: 16,
                paddingRight: 16,
            },
        },
        MuiTabs: {
            root: {
                boxShadow: 'none',
            },
            indicator: {
                background: themeActive.gradients.alternative,
            },
        },
        MuiDrawer: {
            paper: {
                backgroundColor: '#1a1a1a',
                margin: 16,
                borderRadius: 8,
            },
        },
        MuiTable: {
            root: {
                overflowX: 'auto',
            },
        },
        MuiTableRow: {
            root: {
                height: 32,
            },
            head: {
                height: 32,
            },
        },
        MuiTableCell: {
            root: {
                padding: 8,
                borderColor: `#222 !important`,
                '&:first-child': {
                    paddingLeft: 16,
                },
                '&:last-child': {
                    paddingRight: 16,
                },
            },
            head: {
                color: themeActive.colors.faded,
                whiteSpace: 'nowrap',
                textTransform: 'uppercase',
                fontWeight: 700,
                fontSize: 12,
                letterSpacing: 1,
                lineHeight: 'initial',
            },
            body: {
                color: themeActive.colors.text,
            },
        },
        MuiDialog: {
            container: {
                backgroundColor: 'rgba(0,0,0,0.75)',
            },
            paper: {
                backgroundColor: '#1a1a1a',
                borderRadius: 12,
                color: themeActive.colors.text,
                margin: 16,
                width: '100%',
            },
        },
        MuiDialogTitle: {
            root: {
                backgroundColor: themeActive.tabs.backgroundColor,
                padding: 16,
            },
        },
        MuiDialogContent: {
            root: {
                padding: 0,
                '&:first-child': {
                    paddingTop: 0,
                },
            },
        },
        MuiDialogActions: {
            root: {
                backgroundColor: themeActive.tabs.backgroundColor,
                margin: 0,
                padding: 16,
            },
        },
        MuiPickersToolbar: {
            toolbar: {
                backgroundColor: '#1a1a1a',
            },
        },
        MuiPickersToolbarButton: {
            toolbarBtn: {
                color: themeActive.colors.text,
            },
        },
        MuiPickersYear: {
            root: {
                color: themeActive.colors.text,
                '&$selected': {
                    color: themeActive.colors.primary,
                },
            },
        },
        MuiPickersCalendarHeader: {
            transitionContainer: {
                color: themeActive.colors.text,
            },
            switchHeader: {
                color: `${themeActive.colors.text} !important`,
            },
            iconButton: {
                color: themeActive.colors.textReversed,
            },
            daysHeader: {
                color: themeActive.colors.text,
            },
            dayLabel: {
                color: '#aaa',
            },
        },
        MuiPickersSlideTransition: {
            transitionContainer: {},
        },
        MuiPickersMonthSelection: {
            container: {},
        },
        MuiPickersDay: {
            day: {
                color: themeActive.colors.text,
            },
            isSelected: {
                backgroundImage: themeActive.gradients.primary,
            },
            current: {
                color: themeActive.colors.text,
            },
        },
        MuiPickersModal: {
            dialogAction: {
                color: themeActive.colors.text,
            },
        },
        MuiPickersClockNumber: {
            clockNumber: {
                color: '#aaa',
                '&$selected': {
                    color: themeActive.colors.text,
                    fontWeight: 700,
                },
            },
        },
        MuiChip: {
            root: {
                height: 24,
            },
            avatar: {
                height: 24,
                // fontSize: 12,
            },
            colorPrimary: {
                backgroundImage: themeActive.gradients.primary,
            },
        },
        MuiListSubheader: {
            root: {
                textTransform: 'uppercase',
                fontWeight: 700,
                letterSpacing: 1,
                fontSize: '0.8rem',
            },
        },
    },
});
