import debounce from 'lodash/debounce';
import { useEffect } from 'react';
import { fromEvent } from 'rxjs';
import { isNative } from './device';

export const onWindowFocus = isNative() ? fromEvent(document, 'resume') : fromEvent(window, 'focus');

export function getScrollTop() {
    const { documentElement } = document;
    return (window.pageYOffset || documentElement.scrollTop) - (documentElement.clientTop || 0);
}

export function getScrollListener(callback) {
    return debounce(() => callback(getScrollTop()), 100);
}

export function useOnResume(callback, inputs = []) {
    useEffect(() => {
        const subscription$ = onWindowFocus.subscribe(callback);
        return () => subscription$.unsubscribe();
    }, inputs);
}

export function useOnWindowFocus(callback, inputs = []) {
    useEffect(() => {
        const subscription$ = onWindowFocus.subscribe(() => {
            setTimeout(callback, 1000);
        });
        return () => subscription$.unsubscribe();
    }, inputs);
}

export function getScreenSize() {
    const width = window.outerHeight ? window.outerWidth : document.body.clientWidth;
    const height = window.outerHeight ? window.outerHeight : document.body.clientHeight;
    return { width, height };
}

export function isTabletUp() {
    return getScreenSize().width >= 768;
}

window.getScrollTop = getScrollTop;
window.getScreenSize = getScreenSize;
