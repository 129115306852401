import clsx from 'clsx';
import React, { useState } from 'react';
import { UiFlex, UiBox, UiText, UiButton, UiIconMaterial, UiTable } from '@orderx/ui';
import { AdminConfigurationVenueBar } from './bar/AdminConfigurationVenueBar';

interface Props {
    venue: any;
}

export function AdminConfigurationVenue({ venue }: Props) {
    const [isOpen, setIsOpen] = useState(false);

    return (
        <div className={clsx('AdminConfigurationVenue')}>
            <div key={venue.id}>
                <div onClick={() => setIsOpen(!isOpen)}>
                    <UiFlex justifyContent="space-between">
                        <UiBox padding={2}>
                            <UiText color={isOpen ? 'secondary' : 'default'} size={1} weight={2}>
                                {venue.name}
                            </UiText>
                        </UiBox>

                        <UiButton
                            icon={<UiIconMaterial>{isOpen ? 'close' : 'chevron_right'}</UiIconMaterial>}
                            size={2}
                        />
                    </UiFlex>
                </div>

                {isOpen && (
                    <UiTable>
                        <thead>
                            <tr>
                                <td>Name</td>
                                <td align="right">Open</td>
                            </tr>
                        </thead>

                        <tbody>
                            {venue.bars.map((bar) => (
                                <AdminConfigurationVenueBar bar={bar} key={bar.id} />
                            ))}
                        </tbody>
                    </UiTable>
                )}
            </div>
        </div>
    );
}
