import cloneDeep from 'lodash/cloneDeep';
import isArray from 'lodash/isArray';
import keyBy from 'lodash/keyBy';

export function replaceItems(sourceArray, targetArrayOrItem, predicate = item => item.id) {
    targetArrayOrItem = isArray(targetArrayOrItem) ? targetArrayOrItem : [targetArrayOrItem];
    targetArrayOrItem = targetArrayOrItem.filter(Boolean);
    const targetItemsByPredicate = keyBy(targetArrayOrItem, predicate);
    return sourceArray.map(item => targetItemsByPredicate[predicate(item)] || cloneDeep(item));
}
