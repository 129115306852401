import React from 'react';
import styled from 'styled-components';
import { isTypeFiat } from '../shared/user-balances';

const Wrapper = styled.div`
    h1 {
        margin: 0;
    }
`;

export default function UserBalanceCountLabel({ count, type }) {
    return (
        <Wrapper>
            <h1>
                {count}
                {isTypeFiat({ type }) && ',-'}
            </h1>
        </Wrapper>
    );
}
