import React, { useState, useEffect, useRef } from 'react';
import { UiToolbar, UiInput, UiButton, UiIconMaterial } from '@orderx/ui';
import { ToolbarTitle, ToolbarTitleProps } from '../title/ToolbarTitle';

interface Props extends ToolbarTitleProps {
    keyword: string;
    setKeyword(keyword: string): void;
}

export function ToolbarSearch({ keyword, setKeyword, ...rest }: Props) {
    const inputRef = useRef<any>(null);
    const [isSearchVisible, setIsSearchVisible] = useState(false);

    useEffect(() => {
        if (isSearchVisible) {
            inputRef?.current?.focus();
        }

        if (!isSearchVisible) {
            setKeyword('');
        }
    }, [isSearchVisible]);

    return (
        <div>
            {isSearchVisible ? (
                <UiToolbar position="top">
                    <UiInput
                        value={keyword}
                        onChangeValue={setKeyword}
                        placeholder="Type to search"
                        fullWidth
                        noBorder
                        size={2}
                        adornmentStart="&nbsp;&nbsp;"
                        inputProps={{
                            ref: inputRef,
                        }}
                    />

                    <UiButton
                        onClick={() => setIsSearchVisible(!isSearchVisible)}
                        icon={<UiIconMaterial>close</UiIconMaterial>}
                        size={2}
                    />
                </UiToolbar>
            ) : (
                <UiToolbar position="top">
                    <ToolbarTitle {...rest} />

                    <UiButton
                        onClick={() => setIsSearchVisible(!isSearchVisible)}
                        icon={<UiIconMaterial>search</UiIconMaterial>}
                        size={2}
                    />
                </UiToolbar>
            )}
        </div>
    );
}
