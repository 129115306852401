import { useEffect } from 'react';
import { createStore, useStore } from '../create-store';

const storeIsOnline = createStore(navigator.onLine);

export function useIsOnline() {
    return useStore(storeIsOnline);
}

function onOnline() {
    storeIsOnline.set(true);
}

function onOffline() {
    storeIsOnline.set(false);
}

export function useConnectivity() {
    useEffect(() => {
        window.addEventListener('online', onOnline);
        window.addEventListener('offline', onOffline);

        return () => {
            window.removeEventListener('online', onOnline);
            window.removeEventListener('offline', onOffline);
        };
    }, []);
}
