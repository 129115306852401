import React, { useEffect, useState } from 'react';
import { ActionSheet, useActionSheet } from '../components/ActionSheet';
import { BalanceGroupList } from '../components/BalanceGroupList';
import { Button } from '../components/Button';
import { ButtonGroup } from '../components/ButtonGroup';
import { CssLoader } from '../components/CssLoader';
import { Flex } from '../components/Flex';
import { Input } from '../components/Input';
import { InputLabel } from '../components/InputLabel';
import Padding from '../components/Padding';
import { Page } from '../components/Page';
import { PageTitle } from '../components/PageTitle';
import { Svg } from '../components/Svg';
import { createVenueBalanceGroup, loadVenueBalanceGroups } from '../microservices/balance-groups';
import { showError, showSuccess } from '../shared/toasts';
import { stores, useStore } from '../stores';
import { useStaffActiveBar } from '../stores/staff-active-bar';
import { useInput } from '../use/form';
import { UiAlert } from '@orderx/ui';

export default function StaffBarBalanceGroupsList() {
    const { venueId } = useStaffActiveBar();
    const [{ balanceGroupById }] = useStore(stores.staff);
    const inputName = useInput('');
    const inputLimit = useInput('');
    const inputLimitReset = useInput('');
    const [isLoading, setIsLoading] = useState(false);
    const [isCreating, setIsCreating] = useState(false);
    const actionSheetCreate = useActionSheet();

    const balanceGroups = Object.values(balanceGroupById);

    useEffect(() => {
        updateBalanceGroups();
    }, []);

    async function updateBalanceGroups() {
        setIsLoading(true);

        try {
            await loadVenueBalanceGroups(venueId);
        } catch (error) {
            showError(error, 'Failed to load balance groups');
        }

        setIsLoading(false);
    }

    async function createBalanceGroup() {
        setIsCreating(true);

        try {
            const [name, limit, limitReset] = [inputName.value, inputLimit.value, inputLimitReset.value];
            await createVenueBalanceGroup(venueId, { name, limit, limitReset });
            showSuccess('Group created');
            updateBalanceGroups();

            inputName.reset();
            inputLimit.reset();
            inputLimitReset.reset();

            actionSheetCreate.close();
        } catch (error) {
            showError(error, 'Failed to create');
        }

        setIsCreating(false);
    }

    const isValidForm = inputName.value.length > 2;

    return (
        <Page footer>
            <PageTitle
                title="Groups"
                contentRight={
                    <ButtonGroup>
                        <Button isLoading={isLoading} clear onClick={updateBalanceGroups}>
                            <Svg icon="sync" />
                        </Button>

                        <Button clear onClick={actionSheetCreate.toggle}>
                            <Svg icon="add" />
                        </Button>
                    </ButtonGroup>
                }
            />

            {balanceGroups.length === 0 && (
                <Padding horizontal>
                    <UiAlert onClick={actionSheetCreate.toggle}>No groups found. Click to add one</UiAlert>
                </Padding>
            )}

            {balanceGroups.length > 0 && <BalanceGroupList balanceGroups={balanceGroups} />}

            {isLoading && <CssLoader />}

            {actionSheetCreate.isOpen && (
                <ActionSheet {...actionSheetCreate}>
                    <form onSubmit={createBalanceGroup}>
                        <Padding bottom>
                            <h3>Create group</h3>
                        </Padding>

                        <div>
                            <InputLabel>Name</InputLabel>
                            <Input {...inputName} placeholder="Name" />
                        </div>

                        <Flex>
                            <div>
                                <InputLabel>Limit</InputLabel>
                                <Input type="tel" {...inputLimit} placeholder="Limit" />
                            </div>

                            <div>
                                <InputLabel>Reset limit</InputLabel>
                                <Input type="tel" {...inputLimitReset} placeholder="Reset limit" />
                            </div>
                        </Flex>

                        <Button
                            onClick={createBalanceGroup}
                            primary
                            block
                            disabled={!isValidForm}
                            isLoading={isCreating}>
                            Create
                        </Button>
                    </form>
                </ActionSheet>
            )}
        </Page>
    );
}
