import clsx from 'clsx';
import React, { useEffect } from 'react';
import styled from 'styled-components';
import { useDiscount } from '../../../../../stores/discount';
import { UiText } from '@orderx/ui';

const Wrapper = styled.div`
    position: fixed;
    top: var(--spacing);
    right: var(--spacing);
    z-index: 1;
    padding: 4px;
    text-align: center;
    background-color: var(--color-success);
    border-radius: 6px;
    font-weight: bold;
`;

interface Props {
    venue: any;
}

export function OrderGroupDiscount({ venue }: Props) {
    const { getDiscountByVenueId, validateDiscount } = useDiscount();
    const venueId = venue?.id;
    const discount = getDiscountByVenueId(venueId);

    useEffect(() => {
        if (venueId) {
            validateDiscount(venueId);
        }
    }, [venueId]);

    if (!discount) {
        return null;
    }

    return (
        <Wrapper className={clsx('OrderGroupDiscount')}>
            <UiText size={-2} transform="uppercase">
                Discount
            </UiText>
            <div>-{discount.percent}%</div>
        </Wrapper>
    );
}
