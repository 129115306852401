import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import React, { useState } from 'react';

export function UiTableDefault({ headers, headerCells, rows, mapRow, renderRow, perPage = 100 }) {
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(perPage);
    const results = rows.length;
    const isPaginationVisible = results > perPage;

    const pagination = isPaginationVisible && (
        <TablePagination
            component="div"
            rowsPerPageOptions={[25, 50, 100, 500, 1000]}
            count={rows.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onChangePage={(event, page) => setPage(page)}
            onChangeRowsPerPage={event => setRowsPerPage(Number(event.target.value))}
        />
    );

    return (
        <>
            {pagination}

            <Table>
                <TableHead>
                    <TableRow>
                        {headerCells || headers.map((header, index) => <TableCell key={index}>{header}</TableCell>)}
                    </TableRow>
                </TableHead>

                <TableBody>
                    {rows
                        .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                        .map((row, index) =>
                            renderRow ? (
                                renderRow(row)
                            ) : (
                                <TableRow key={row.id || row.Id || index}>{mapRow(row)}</TableRow>
                            ),
                        )}
                </TableBody>
            </Table>

            {pagination}
        </>
    );
}

UiTableDefault.Cell = Cell;
UiTableDefault.Row = TableRow;

export function Cell({ right = false, children, ...rest }) {
    return (
        <TableCell align={right ? 'right' : 'left'} {...rest}>
            {children}
        </TableCell>
    );
}
