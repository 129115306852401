import React from 'react';
import { PosCreateHeader } from '../../../../components/pos/create/header/PosCreateHeader';
import { PosCreateMenu } from '../../../../components/pos/create/menu/PosCreateMenu';
import { usePosTerminal } from '../../../../hooks/pos-terminal';
import { PosFooterTerminal } from '../../../../components/pos/footer/terminal/PosFooterTerminal';
import { PosCreateCategory } from '../../../../components/pos/create/menu/category/PosCreateCategory';
import { usePosMenu } from '../../../../hooks/pos-menu';
import { PosCreateActiveOrder } from '../../../../components/pos/create/active-order/PosCreateActiveOrder';

export function PosIdCreatePage() {
    const { isActive } = usePosTerminal();
    const { isActiveOrderOpen } = usePosMenu();

    return (
        <>
            <PosCreateHeader />
            <PosCreateCategory />
            {isActiveOrderOpen ? <PosCreateActiveOrder /> : <PosCreateMenu />}

            {isActive && <PosFooterTerminal />}
        </>
    );
}

PosIdCreatePage.route = '/v2/pos/:locationId/create';
