import React from 'react';
import styled from 'styled-components';
import { themeActive } from '../themes/dark';

const Wrapper = styled.div`
    background-image: ${({ noBackground }) => (noBackground ? 'none' : themeActive.gradients.main)};
    box-shadow: var(--box-shadow);
    border-radius: ${themeActive.sizes.borderRadius}px;
    //overflow: hidden;

    padding: ${({ padding = 16 }) => padding}px;
`;

export function Card({ padding, noBackground, className, children }) {
    return (
        <Wrapper padding={padding} noBackground={noBackground} className={className}>
            {children}
        </Wrapper>
    );
}
