import { getApiUrl } from '../config';
import { httpPost } from '../shared/api';
import { stores, getStoreValue } from '../stores';

const getUrl = getApiUrl('device-actions');

export async function postAction(type, extra = undefined) {
    try {
        const { id, platform, model } = getStoreValue(stores.device);

        const url = getUrl(`action`);

        await httpPost(url, {
            type,
            extra,
            deviceId: id,
            platform,
            model,
        });
    } catch (error) {
        console.error(error);
    }
}

export function postStaffActiveBarAction(type, extra = undefined) {
    const { bar } = getStoreValue(stores.staff);
    return postAction(type, { ...extra, barId: bar.id, barName: bar.name });
}
