import { useEffect, useState } from 'react';
import { registerDevice as meRegisterDevice } from '../microservices/me-v1';
import { useDevice } from '../shared/device';
import { setPushNotification } from '../shared/push-notifications';
import { Log } from '../util/logger';

const PushEvent = {
    Registration: 'registration',
    Notification: 'notification',
    Error: 'error',
};

export function PushNotifications() {
    const [token, setToken] = useState('');
    const { isNative, platform } = useDevice();

    useEffect(
        () => {
            if (isNative) {
                const push = window.PushNotification.init({
                    android: {},
                    browser: {
                        pushServiceURL: 'http://push.api.phonegap.com/v1/push',
                    },
                    ios: {
                        alert: 'true',
                        badge: 'true',
                        sound: 'true',
                    },
                    windows: {},
                });

                push.on(PushEvent.Registration, response => {
                    setToken(response && response.registrationId);
                });

                push.on(PushEvent.Notification, response => {
                    const { title, message: subtitle, additionalData } = response;
                    const { coldstart: isColdStart, foreground: isForeground, payload } = additionalData;

                    const notification = {
                        title,
                        subtitle,
                        isColdStart,
                        isForeground,
                    };

                    if (payload) {
                        const { type, data } = payload;
                        notification.type = type;
                        notification.data = data;
                    }

                    Log.dev('PushNotifications', 'on notification', notification);
                    setPushNotification(notification);
                });

                push.on(PushEvent.Error, error => {
                    console.error(error);
                });
            }
        },
        [isNative],
    );

    useEffect(
        () => {
            if (token && platform) {
                meRegisterDevice({ token, platform });
            }
        },
        [token, platform],
    );

    return null;
}
