import Auth from '@aws-amplify/auth';
import { initialStateStaff, stores } from '../stores';
import { setApiJwtToken } from '../stores/api-jwt-token';
import { storageSet } from './storage';
import { setUser } from '../stores/user';
import { loadMe } from '../microservices/me-v1';

const authConfig = {
    userPoolId: 'eu-west-1_pA2ZUo2j1',
    userPoolWebClientId: '3s9of0as4acls5nl94u3k75uft',
    identityPoolId: 'eu-west-1:673c205e-5598-401a-a368-4f2e55c8a582',
    region: 'eu-west-1',
    mandatorySignIn: false,
};

Auth.configure({
    identityPoolId: authConfig.identityPoolId,
    region: authConfig.region,
    userPoolId: authConfig.userPoolId,
    userPoolWebClientId: authConfig.userPoolWebClientId,
});

export const ErrorCode = {
    UserNotFoundException: 'UserNotFoundException',
    NotAuthorizedException: 'NotAuthorizedException',
    PasswordResetRequiredException: 'PasswordResetRequiredException',
};

function passwordWithSuffix(pin) {
    return `${pin}ordx`;
}

export async function signIn(username, password) {
    try {
        const user = await Auth.signIn(username, passwordWithSuffix(password));
        const { challengeName } = user;

        if (challengeName === 'NEW_PASSWORD_REQUIRED') {
            return {
                user,
                newPasswordRequired: true,
            };
        } else {
            await authenticate();

            return {
                user,
                authenticated: true,
            };
        }
    } catch (error) {
        if (error.code === 'PasswordResetRequiredException') {
            return {
                passwordResetRequired: true,
            };
        } else {
            throw error;
        }
    }
}

export async function signInV2(username, password) {
    const user = await Auth.signIn(username, passwordWithSuffix(password));
    const { challengeName } = user;

    if (challengeName === 'NEW_PASSWORD_REQUIRED') {
        return {
            user,
            newPasswordRequired: true,
            authenticated: false,
        };
    } else {
        await authenticate();

        return {
            user,
            newPasswordRequired: false,
            authenticated: true,
        };
    }
}

export async function signUp({ username, name, password, firstName, lastName }) {
    const params = {
        username,
        password: passwordWithSuffix(password),
        attributes: {
            name,
            given_name: firstName,
            family_name: lastName,
            phone_number: username,
        },
    };

    const { user, userConfirmed, userSub } = await Auth.signUp(params);
    return { user, userConfirmed, userSub };
}

export async function completeNewPassword(user, password) {
    return Auth.completeNewPassword(user, passwordWithSuffix(password));
}

export async function forgotPassword(username) {
    return Auth.forgotPassword(username);
}

export async function resendConfirmationCode(username) {
    return Auth.resendSignUp(username);
}

export async function forgotPasswordSubmit(username, code, newPassword) {
    return Auth.forgotPasswordSubmit(username, code, passwordWithSuffix(newPassword));
}

export async function confirmSignUp(username, code) {
    return Auth.confirmSignUp(username, code);
}

export async function authenticate() {
    // const user = await Auth.currentUserInfo();
    return refresh();
}

export async function signOut() {
    await Auth.signOut();

    setUser(undefined);
    storageSet('isLoginPinVisible', '');
    setApiJwtToken(undefined);
    stores.staff.set(initialStateStaff());
}

export async function refresh() {
    try {
        const session = await Auth.currentSession();
        setApiJwtToken(session?.idToken?.jwtToken);
        await loadMe();
    } catch (error) {
        console.error(error);
    }
}
