import { MicroserviceBambora } from '../microservices/bambora';
import { MicroserviceLocation } from '../microservices/location';
import { toMoment } from '../shared/moment';
import { useStaffActiveBar } from '../stores/staff-active-bar';
import { useUser } from '../stores/user';
import { getOrderPrintData } from './get-order-print-data';

export function usePrintPreSalesReceipt() {
    const user = useUser();
    const userFirstName = (user.name || '').split(' ')[0];

    const {
        legalAddress,
        legalCompanyName,
        legalPostalAddressCity,
        legalPostalAddressCountry,
        legalPostalAddressName,
        legalPostalAddressNumber,
        legalPostalAddressPostalCode,
        legalPostalAddressRegion,
        legalRegistrationNumber,
        legalTaxCode,
        legalTaxRegistrationCountry,
        legalTaxRegistrationCountryAndCode,
    } = useStaffActiveBar().venue;

    return async ({ barId, groupName, terminal, products }) => {
        const { name } = await MicroserviceLocation.findBarById(barId);

        await MicroserviceBambora.printFromTerminal({
            terminalId: terminal.id,
            posFriendlyName: terminal.pos,
            printData: {
                header: [
                    ['NOT A SALES RECEIPT'],
                    [''],
                    [name],
                    [''],
                    [`Table: ${groupName || '-'}`],
                    [`Employee: ${userFirstName}`],
                    [`Date: ${toMoment().format('DD.MM.YYYY/HH:mm')}`],
                    [''],
                ],

                // header: [
                //     ['NOT A SALES RECEIPT'],
                //     [''],
                //     [name],
                //     [''],
                //     ['PB 104 - 3561 HEMSEDAL'],
                //     ['TLF. 32 05 54 10'],
                //     [''],
                //     ['ENTEPRISE NO.: NO 979 760 299'],
                //     [''],
                //     ['HEMSEDAL CAFE'],
                //     [''],
                //     ['Table: 1000'],
                //     ['Employee: 102 STOREBAR CAFE'],
                //     ['Date: 18.11.2019/12:57'],
                //     [''],
                //     [''],
                //     [''],
                // ],

                ...getOrderPrintData(products),

                footer: [[''], [''], [''], [''], ['']],
            },
        });
    };
}
