import React, { useEffect } from 'react';
import { useRouter } from '../../hooks/router';
import { useInterval } from '../../../use/interval';
import { useApiQuery } from '@orderx/http';
import { apiOrderOrderIdGet } from '../../../api/order.{orderId}.[get]';
import { BlockLoader } from '../../components/block-loader/BlockLoader';
import { useOnResume } from '../../../shared/browser';
import { Order } from '../../models/order';
import { useCustomerOrders } from '../../hooks/customer-orders';
import { OrderIdViewCompact } from '../../components/order/id/view/compact/OrderIdViewCompact';
import { useAwsIotSubscription } from '../../../providers/aws-iot';

export function V2OrderIdPage() {
    const {
        params: { orderId },
    } = useRouter();
    const { cacheOrderId } = useCustomerOrders();

    const { run: loadOrder, response: order } = useApiQuery<Order>(async () =>
        apiOrderOrderIdGet({
            params: { orderId },
        }),
    );

    useAwsIotSubscription(`order/${orderId}/update`, loadOrder, [orderId]);

    useOnResume(loadOrder, [orderId]);

    useInterval(loadOrder, 10000, [orderId]);

    useEffect(() => {
        loadOrder();
        cacheOrderId(orderId);
    }, [orderId]);

    if (!order) {
        return <BlockLoader />;
    }

    return <OrderIdViewCompact order={order} />;
}

V2OrderIdPage.route = '/v2/order/:orderId';
