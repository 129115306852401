import { useEffect } from 'react';
import { Subject } from 'rxjs';
import { createStore, useStore } from '../create-store';
import { MicroserviceLegacyApp } from '../microservices/legacy-app';
import { MicroserviceOrders } from '../microservices/orders';
import { CreateOrder } from '../modules/create-order';
import { useSnackbar } from '../shared/snackbar';
import { useApiQuery } from '@orderx/http';
import { useCreateOrder } from './create-order';
import { useCreateOrderBar } from './create-order-bar';
import { useCreateOrderProductStats } from './create-order-product-stats';
import { resetOrderProductState } from './create-order-products';
import { useDeviceConfig } from './device-config';

export const subjectTableOrderPlaced = new Subject();
export const subjectTerminalOrderPlaced = new Subject();
export const subjectBalanceOrderPlaced = new Subject();
export const subjectOrderPlaced = new Subject();

const store = createStore({
    isLoading: false,
});

export function useCreateOrderQuery() {
    const [state, setState] = useStore(store);
    const { barId, setSearchKeyword, setProductsPage } = useCreateOrderBar();
    const { isGratitudeOn, isOrderPrintSequenceEnabled } = useDeviceConfig().config;
    const { showSuccess } = useSnackbar();
    const { orderProducts: products } = CreateOrder.useState();
    const { isManualEntry, preSelectedGroup } = useCreateOrder();
    const { cacheProductsStats } = useCreateOrderProductStats();

    const { isLoading, run, response } = useApiQuery(async ({ groupId, terminal, balance }) => {
        if (preSelectedGroup) {
            groupId = preSelectedGroup.id;
        }

        if (balance) {
            const tagId = balance.id;
            const tagType = 'nfc';

            await MicroserviceLegacyApp.postOrder({
                barId,
                tag: {
                    type: tagType,
                    value: tagId,
                },
                productsToPay: products,
                groupId,
                manualEntry: isManualEntry,
                gratitude: isGratitudeOn,
                printSequence: isOrderPrintSequenceEnabled,
                comment: balance.comment,
            });

            subjectBalanceOrderPlaced.next();
        } else if (terminal) {
            const tagId = terminal.id;
            const tagType = 'id';

            await MicroserviceLegacyApp.postOrder({
                barId,
                tag: {
                    type: tagType,
                    value: tagId,
                },
                productsToPay: products,
                groupId,
                manualEntry: isManualEntry,
                gratitude: isGratitudeOn,
                printSequence: isOrderPrintSequenceEnabled,
            });

            subjectTerminalOrderPlaced.next();
        } else {
            await MicroserviceOrders.staffApp({
                action: 'PLACE_ORDER_V2',
                products,
                groupId,
            });

            subjectTableOrderPlaced.next();
        }

        cacheProductsStats(products);
        showSuccess('Order created');
        resetOrderProductState();
        setSearchKeyword('');
        subjectOrderPlaced.next();
        setProductsPage(0);
    });

    useEffect(() => {
        setState((state) => {
            state.isLoading = isLoading;
        });
    }, [isLoading]);

    return {
        groupId: state.groupId,
        setGroupId: (groupId) =>
            setState((state) => {
                state.groupId = groupId;
            }),
        canPlaceOrder: !isLoading && products.length > 0,
        isLoading: state.isLoading,
        placeOrder: run,
        response,
    };
}
