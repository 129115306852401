import React, { useEffect, useState } from 'react';
import { MicroserviceLegacyApp } from '../../microservices/legacy-app';
import { useCreateOrderHistory } from '../../stores/create-order-history';
import { LegacyOrderRow } from '../legacy-order/row/LegacyOrderRow';
import { UiButton } from '../../@deprecated/UiButton';
import { UiIconMaterial, UiOverlay, UiToolbar } from '@orderx/ui';
import { ToolbarTitle } from '../../@v2/components/toolbar/title/ToolbarTitle';

export function StaffCreateHeaderOrderHistory() {
    const { limit } = useCreateOrderHistory();
    const [isOpen, setIsOpen] = useState(false);

    return (
        <>
            <UiButton onClick={() => setIsOpen(!isOpen)} icon={<UiIconMaterial>list</UiIconMaterial>} />

            {isOpen && (
                <UiOverlay>
                    <UiToolbar>
                        <ToolbarTitle title={`Last ${limit} orders`} buttonBackOnClick={() => setIsOpen(false)} />
                    </UiToolbar>

                    <Dialog />
                </UiOverlay>
            )}
        </>
    );
}

function Dialog() {
    const { orders, putOrder } = useCreateOrderHistory();

    useEffect(() => {
        updateOrderIds();
    }, []);

    async function updateOrderIds() {
        const ids = orders.map((order) => order.Id);

        try {
            const orders = await MicroserviceLegacyApp.updateOrderStatuses(ids);

            for (const order of orders) {
                putOrder(order);
            }
        } catch (error) {
            console.error(error);
        }
    }

    return orders.map((order) => <LegacyOrderRow key={order.Id} order={order} />);
}
