import { createStore } from '@orderx/store';
import { useApiQuery } from '@orderx/http';
import { MicroserviceOrders } from '../../microservices/orders';

const { useStore } = createStore<any>({
    isLoading: false,
    configuration: undefined,
    hasResponse: false,
});

export function useAdminConfiguration() {
    const [state, setState] = useStore();

    const { run: load } = useApiQuery(async () => {
        setState((state) => {
            state.isLoading = true;
        });

        try {
            const response = await MicroserviceOrders.staffApp({
                action: 'handlerAppStaffConfigurationData',
            });

            setState((state) => {
                state.configuration = response;
            });
        } catch (error) {}

        setState((state) => {
            state.isLoading = false;
            state.hasResponse = true;
        });
    });

    return {
        isLoading: state.isLoading,
        hasResponse: state.hasResponse,
        venues: state.configuration?.venues || [],
        load,
    };
}
