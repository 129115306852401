import React from 'react';
import { usePosBar } from '../../../../hooks/pos/bar';
import { UiToolbar, UiFlex } from '@orderx/ui';
import { ToolbarTitle } from '../../../../@v2/components/toolbar/title/ToolbarTitle';

export function PosPageHeader({ title, contentLeft, contentRight, children, isLoading }) {
    const { bar } = usePosBar();

    return (
        <UiToolbar position="top">
            {children || (
                <>
                    {contentLeft || <ToolbarTitle title={title || bar.name} />}
                    <UiFlex>{contentRight}</UiFlex>
                </>
            )}
        </UiToolbar>
    );
}
