import React from 'react';
import styled from 'styled-components';
import { themeActive } from '../themes/dark';
import { Svg, SvgIcons } from './Svg';

const Wrapper = styled.label`
    display: inline-block;

    input {
        display: none;
    }
`;

export function Checkbox({ isChecked, onChange }) {
    const icon = isChecked ? SvgIcons.Checkbox : SvgIcons.CheckboxOutline;

    return (
        <Wrapper>
            <Svg size={24} icon={icon} fill={isChecked ? themeActive.colors.green : themeActive.colors.faded} />
            <input type="checkbox" checked={isChecked} onChange={event => onChange(event.target.checked)} />
        </Wrapper>
    );
}
