import classNames from 'classnames';
import React, { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import styled from 'styled-components';

const rootElement = document.getElementById('bottom-portal');

const Wrapper = styled.div`
    position: fixed;
    left: 0;
    bottom: ${(props) => props.bottom}px;
    z-index: 10;
    width: 100%;
`;

export function PortalBottom({ children, animated = true, bottom = 0 }) {
    const [element, setElement] = useState();

    useEffect(() => {
        const element = document.createElement('div');
        setElement(element);
        rootElement.appendChild(element);
        return () => rootElement.removeChild(element);
    }, []);

    if (!element) {
        return null;
    }

    return ReactDOM.createPortal(
        <Wrapper
            bottom={bottom}
            className={classNames('PortalBottom', {
                'animated fadeInUp': animated,
            })}>
            {children}
        </Wrapper>,
        element,
    );
}
