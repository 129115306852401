import React, { useMemo } from 'react';
import { useCreateOrderBar } from '../../stores/create-order-bar';
import { useDeviceConfig } from '../../stores/device-config';
import { compareStrings } from '../../util/compare-strings';
import { StaffCreateProductListItem } from './StaffCreateProductListItem';
import { UiGrid } from '@orderx/ui';

export function StaffCreateProductList() {
    const { hasPagination } = useDeviceConfig().config;
    const { category, subCategory, products, searchKeyword, productsPage, productsPerPage } = useCreateOrderBar();

    const productsFiltered = useMemo(() => {
        let productsFiltered = [...products];

        if (searchKeyword) {
            productsFiltered = productsFiltered.filter((product) => compareStrings(product.name, searchKeyword));
        }

        if (subCategory) {
            return productsFiltered.filter(
                (product) => product.category === category && product.subCategory === subCategory,
            );
        }

        if (category === '_ACTIVE') {
            return [];
        }

        if (category) {
            return productsFiltered.filter((product) => product.category === category);
        }

        return productsFiltered;
    }, [products, category, subCategory, searchKeyword]);

    const productsPaginated = useMemo(
        () =>
            hasPagination
                ? productsFiltered.slice(
                      productsPage * productsPerPage,
                      productsPage * productsPerPage + productsPerPage,
                  )
                : productsFiltered,
        [hasPagination, productsFiltered, productsPage, productsPerPage],
    );

    return (
        <UiGrid minMax={200} outerSpacing={2} spacing={1}>
            {productsPaginated.map((product) => (
                <StaffCreateProductListItem product={product} key={product.id} />
            ))}
        </UiGrid>
    );
}
