import React, { useState } from 'react';
import styled from 'styled-components';
import { ActionSheet, useActionSheet } from '../components/ActionSheet';
import { Button } from '../components/Button';
import Padding from '../components/Padding';
import { Page } from '../components/Page';
import { PageTitle } from '../components/PageTitle';
import { Svg } from '../components/Svg';
import { SwishPosOverlay } from '../components/SwishPosOverlay';
import { Table } from '../components/Table';
import { useStaffActiveBar } from '../stores/staff-active-bar';

const Wrapper = styled.div`
    td:last-child {
        display: flex;
        justify-content: flex-end;
    }
`;

export default function StaffBarSwish() {
    const bar = useStaffActiveBar();
    const [pos, setPos] = useState();
    const actionSheet = useActionSheet();

    return (
        <Wrapper>
            <Page footer>
                <PageTitle title="Swish" />

                <Padding horizontal>
                    <Table>
                        <thead>
                            <tr>
                                <td>Name</td>
                                <td />
                            </tr>
                        </thead>

                        <tbody>
                            {bar.swishPos.map(pos => (
                                <tr key={pos.id}>
                                    <td>{pos.name || pos.id}</td>
                                    <td>
                                        <Button
                                            onClick={() => {
                                                setPos(pos);
                                                actionSheet.open();
                                            }}>
                                            <Svg icon="arrow-right" />
                                        </Button>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </Table>
                </Padding>
            </Page>

            {pos &&
                actionSheet.isOpen && (
                    <ActionSheet {...actionSheet} onClosed={() => setPos(undefined)} cover>
                        <SwishPosOverlay actionSheet={actionSheet} pos={pos} />
                    </ActionSheet>
                )}
        </Wrapper>
    );
}
