import clsx from 'clsx';
import React, { useEffect, useState } from 'react';
import { useApiQuery } from '@orderx/http';
import { useRouter } from '../../hooks/router';
import { apiPublicZoneZoneIdGet } from '../../../api/public.zone.{zoneId}.[get]';
import { useOnResume } from '../../../shared/browser';
import { BlockLoader } from '../../components/block-loader/BlockLoader';
import './ZonePage.scss';
import { UiText, UiFlex, UiImage, UiGrid, UiAlert, useUiToggle } from '@orderx/ui';
import { OrderPaymentProgressBar } from '../../../components/OrderPaymentProgressBar';
import { useCurrency } from '../../hooks/currency';
import { ProductCard } from '../../components/product/card/ProductCard';
import times from 'lodash/times';
import sum from 'lodash/sum';
import { ButtonBottom } from '../../components/button/bottom/ButtonBottom';
import { apiPublicOrderPost } from '../../../api/public.order.[post]';
import { useDeviceFingerprint } from '../../hooks/device-fingerprint';
import { useAwsIotSubscription } from '../../../providers/aws-iot';
import { V2OrderIdPage } from '../v2-order-id/V2OrderIdPage';
import { OrderSuccessOverlay } from '../../components/order/success-overlay/OrderSuccessOverlay';
import { useInterval } from '../../../use/interval';
import { V2OrderPlaced } from '../../components/v2/order-placed/V2OrderPlaced';

export function ZonePage() {
    const {
        params: { hash },
        replaceParams,
    } = useRouter();
    const { formatMinor, setCurrency } = useCurrency();
    const [productCountById, setProductCountById] = useState({});
    const { deviceFingerprint } = useDeviceFingerprint();

    const { component: componentToggle, value: toggleValue } = useUiToggle([
        {
            label: 'Order',
            value: 'ORDER',
        },
        {
            label: 'Pay',
            value: 'PAY',
        },
    ]);

    const { run: loadZone, response } = useApiQuery(() =>
        apiPublicZoneZoneIdGet({
            params: { zoneId: hash },
        }),
    );

    const productsWithCounts = Object.keys(productCountById)
        .map((productId) =>
            times(productCountById[productId]).map(() =>
                response?.menu.find((product) => product.id === Number(productId)),
            ),
        )
        .flat();

    const orderTotalCost = sum(productsWithCounts.map(({ price }) => price));

    const { run: placeOrder, isLoading: isPlacingOrder, response: placedOrder } = useApiQuery(async () => {
        const order = await apiPublicOrderPost({
            body: {
                outletZoneId: response.id,
                orderProducts: productsWithCounts.map((p) => ({ productId: p.id, ...p })),
                deviceFingerprint,
            },
        });

        reset();

        return order;
    });

    const iotTopicUpdate = response?.id && `zone/${response.id}/update`;

    useAwsIotSubscription(iotTopicUpdate, loadZone, []);

    useEffect(() => {
        loadZone();
    }, []);

    useInterval(
        () => {
            loadZone();
        },
        30000,
        [],
    );

    useOnResume(() => {
        loadZone();
    });

    useEffect(() => {
        if (response) {
            setCurrency(response.currency);
        }
    }, [response]);

    function reset() {
        setProductCountById({});
    }

    if (!response) {
        return <BlockLoader />;
    }

    const {
        name,
        image,
        outlet,
        payments: { total, paid },
        menu,
        isQueueLimitReached,
    } = response;

    return (
        <>
            <V2OrderPlaced />

            <UiGrid className={clsx('ZonePage')} padding={2} gap={8}>
                {placedOrder && (
                    <OrderSuccessOverlay redirectTo={replaceParams(V2OrderIdPage.route, { orderId: placedOrder.id })} />
                )}

                {image && (
                    <div className="image">
                        <UiImage src={image} type="cover" />
                        <div className="image-overlay" />
                    </div>
                )}

                <UiFlex flexChildren>
                    <div />

                    <UiFlex direction="column" justifyContent="center">
                        <UiText color="text-secondary" size={2} weight={2}>
                            {outlet.name}
                        </UiText>

                        <UiText size={4} weight={2}>
                            {name}
                        </UiText>
                    </UiFlex>

                    <div />
                </UiFlex>

                {total > 0 && <OrderPaymentProgressBar amountTotal={total} amountPaid={paid} />}

                {isQueueLimitReached && (
                    <UiAlert color="warning">
                        {outlet.name} is currently not accepting any orders! Please try again later!
                    </UiAlert>
                )}

                {toggleValue === 'ORDER' && (
                    <>
                        {!isQueueLimitReached && (
                            <UiGrid gap={1}>
                                {menu.map((product) => (
                                    <ProductCard
                                        image={product.icon}
                                        name={product.name}
                                        description={product.description}
                                        price={product.price / 100}
                                        count={productCountById[product.id]}
                                        onDecrement={(amount) =>
                                            setProductCountById({
                                                ...productCountById,
                                                [product.id]: (productCountById[product.id] || 0) - amount,
                                            })
                                        }
                                        onIncrement={(amount) =>
                                            setProductCountById({
                                                ...productCountById,
                                                [product.id]: (productCountById[product.id] || 0) + amount,
                                            })
                                        }
                                        key={product.id}
                                    />
                                ))}
                            </UiGrid>
                        )}

                        {productsWithCounts.length > 0 && (
                            <ButtonBottom onClick={placeOrder} isLoading={isPlacingOrder} color="primary">
                                Place order {formatMinor(orderTotalCost)}
                            </ButtonBottom>
                        )}
                    </>
                )}
            </UiGrid>
        </>
    );
}

ZonePage.route = '/z/:hash';
