import orderBy from 'lodash/orderBy';
import React, { useState } from 'react';
import { getTotalUnitsSpent, isLimitExceeded } from '../microservices/balance-groups';
import BalanceGroupListRow from './BalanceGroupListRow';
import { InputLabel } from './InputLabel';
import Padding from './Padding';
import Pill from './Pill';
import Pills from './Pills';
import { Table } from './Table';
import { UiAlert } from '@orderx/ui';

const FILTER = {
    ALL: 'All',
    LIMIT_REACHED: 'Limit reached',
    USAGE: 'Usage',
};

export function BalanceGroupList({ balanceGroups = [] }) {
    const [filter, setFilter] = useState(FILTER.ALL);

    if (filter === FILTER.LIMIT_REACHED) {
        balanceGroups = balanceGroups.filter(isLimitExceeded);
    }

    if (filter === FILTER.USAGE) {
        balanceGroups = orderBy(balanceGroups, getTotalUnitsSpent, 'desc');
    }

    return (
        <div>
            <Padding horizontal>
                <InputLabel>Filter groups by</InputLabel>
            </Padding>

            <Pills>
                {Object.values(FILTER).map((filterLabel) => (
                    <Pill key={filterLabel} onClick={() => setFilter(filterLabel)} active={filter === filterLabel}>
                        {filterLabel}
                    </Pill>
                ))}
            </Pills>

            <Padding horizontal bottom>
                <Table>
                    <thead>
                        <tr>
                            <td>Name</td>
                            <td>Used/Limit</td>
                        </tr>
                    </thead>

                    <tbody>
                        {balanceGroups.map((balanceGroup) => (
                            <BalanceGroupListRow key={balanceGroup.id} balanceGroup={balanceGroup} />
                        ))}
                    </tbody>
                </Table>

                {balanceGroups.length === 0 && <UiAlert>No groups found for current filter</UiAlert>}
            </Padding>
        </div>
    );
}
