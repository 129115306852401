import { Column, Entity, BeforeInsert, Index, ManyToOne, OneToMany } from './typeorm';
import { BaseModel } from './base-model';
import { Outlet } from './outlet';
import { Order } from './order';
import { Payment } from './payment';

export enum OutletZoneOrderVisibilityType {
    PUBLIC = 'PUBLIC',
    USER_ORDERS_ONLY = 'USER_ORDERS_ONLY',
}

export enum OutletZonePaymentType {
    UPFRONT = 'UPFRONT',
    AFTER = 'AFTER',
}

export enum OutletZoneLayoutType {
    DEFAULT = 'DEFAULT',
    COMPACT = 'COMPACT',
}

@Entity()
export class OutletZone extends BaseModel {
    @Column()
    @Index({ unique: true })
    hash: string;

    @Column()
    name: string;

    @Column({ default: false })
    isClosed: boolean;

    @Column({ default: false })
    isHidden: boolean;

    @Column({ default: 0 })
    @Index()
    position: number;

    @Column({ default: OutletZoneOrderVisibilityType.USER_ORDERS_ONLY })
    orderVisibilityType: OutletZoneOrderVisibilityType;

    @Column({ default: OutletZonePaymentType.UPFRONT })
    paymentType: OutletZonePaymentType;

    @Column({ default: OutletZoneLayoutType.DEFAULT })
    layoutType: OutletZoneLayoutType;

    @ManyToOne(() => Outlet, (model) => model.outletZones)
    outlet: Outlet;
    @Column({ nullable: true })
    outletId: number;

    @OneToMany(() => Order, (model) => model.outletZone)
    orders: Order[];

    @OneToMany(() => Payment, (model) => model.outletZone)
    payments: Payment[];
}
