import React, { useCallback, useEffect, useState } from 'react';
import { MicroserviceOrders } from '../../microservices/orders';
import { useStaffOrderingBar } from '../../stores/staff-ordering-bar';
import { useApiQuery } from '@orderx/http';
import { UiInput } from '../ui/UiInput';
import './StaffOrderDiscounts.scss';
import { UiFlex, UiButton } from '@orderx/ui';

export function StaffOrderDiscounts({ barId, onApplyDiscount, disabled = false, isLoading = false }) {
    const staffOrderingBarId = useStaffOrderingBar().barId;
    barId = barId || staffOrderingBarId;

    const [discountId, setDiscountId] = useState('');

    const { isLoading: isLoadingDiscounts, run: loadDiscounts, response: discounts = [] } = useApiQuery(async () =>
        MicroserviceOrders.staffApp({
            action: 'FIND_BAR_DISCOUNTS',
            barId,
        }),
    );

    useEffect(() => {
        loadDiscounts();
    }, []);

    const applyDiscount = useCallback(async () => {
        try {
            await onApplyDiscount(discounts.find((discount) => discount.id === discountId));
            setDiscountId('');
        } catch (error) {}
    }, [discountId]);

    return (
        <UiFlex fullWidth spacing={1} className="StaffOrderDiscounts">
            <UiInput
                label={'Choose discount'}
                value={discountId}
                onChangeValue={setDiscountId}
                options={[
                    {
                        label: 'Remove discount',
                        value: 'remove',
                    },
                    ...discounts.map((discount) => ({
                        label: `-${discount.percent}% ${discount.name}`,
                        value: discount.id,
                    })),
                ]}
            />

            <UiButton
                disabled={disabled || isLoadingDiscounts || !discountId}
                isLoading={isLoading}
                onClick={applyDiscount}
                variant="filled"
                color="primary">
                Apply
            </UiButton>
        </UiFlex>
    );
}
