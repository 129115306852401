import React from 'react';
import { UiFlex, UiButton, UiIconMaterial, UiToolbar } from '@orderx/ui';
import { PosCreateHeaderSummary } from './summary/PosCreateHeaderSummary';
import { PosIdOrderHistoryPage } from '../../../../pages/pos/id/order-history/PosIdOrderHistoryPage';
import { usePos } from '../../../../hooks/pos';
import { PosCreateHeaderButtonPrintLastOrder } from './button/print-last-order/PosCreateHeaderButtonPrintLastOrder';
import { PosCreateHeaderButtonSearch } from './button/search/PosCreateHeaderButtonSearch';
import { UiIconPosTerminal } from '../../../../../components/ui/icon/UiIconPosTerminal';
import { usePosTerminal } from '../../../../hooks/pos-terminal';
import { useHistory } from 'react-router-dom';
import { ToolbarTitle } from '../../../toolbar/title/ToolbarTitle';

export function PosCreateHeader() {
    const { locationId } = usePos();
    const { isActive, setIsActive } = usePosTerminal();
    const { goBack } = useHistory();

    return (
        <>
            <UiToolbar>
                <ToolbarTitle title="Create" buttonBackOnClick={goBack} />

                <UiFlex>
                    <PosCreateHeaderButtonSearch />

                    <UiButton
                        to={PosIdOrderHistoryPage.route}
                        toParams={{ locationId }}
                        icon={<UiIconMaterial>list</UiIconMaterial>}
                        iconCircle
                        size={1}
                    />

                    <PosCreateHeaderButtonPrintLastOrder />

                    <UiButton
                        color={isActive ? 'primary' : 'default'}
                        onClick={() => setIsActive(!isActive)}
                        icon={<UiIconPosTerminal />}
                        iconCircle
                        size={1}
                    />
                </UiFlex>
            </UiToolbar>

            <PosCreateHeaderSummary />
        </>
    );
}
