import React, { useEffect, useState, Fragment } from 'react';
import styled from 'styled-components';
import imageBambora from '../../assets/bambora-logo.png';
import imageVipps from '../../assets/vipps-logo.png';
import { MicroserviceOrders } from '../../microservices/orders';
import { OrderDeliveryType } from '../../models/orders/order';
import { PaymentType, PaymentStatus } from '../../models/orders/payment';
import { GoogleAnalytics } from '../../providers/googe-analytics';
import { translate } from '../../shared/translate';
import { useOrderGroup } from '../../stores/order-group';
import { useOrdersPlaced } from '../../stores/order-placed';
import { useApiQuery } from '@orderx/http';
import { V2Loader } from './V2Loader';
import { UiToolbar, UiGrid, UiIconMaterial, UiButton, UiText, UiFlex, UiInput, UiOverlay } from '@orderx/ui';
import { useDeviceFingerprint } from '../../@v2/hooks/device-fingerprint';
import { ButtonBottom } from '../../@v2/components/button/bottom/ButtonBottom';
import { useTermsAndConditions } from '../../@v2/hooks/terms-and-conditions';
import { ToolbarTitle } from '../../@v2/components/toolbar/title/ToolbarTitle';
import { IS_DEV } from '../../environment';
import { GroupPaymentMode } from '../../models/orders/group';
import { OrderIdPage } from '../../@v2/pages/order/id/OrderIdPage';
import { useCurrency } from '../../@v2/hooks/currency';
import { useDiscount } from '../../stores/discount';
import { ProfileDataForm } from '../../@v2/components/profile-data-form/ProfileDataForm';

const PaymentProviderButton = styled.a`
    display: flex;
    align-items: center;
    justify-content: center;
    height: 56px;
    font-weight: bold;
    // TODO: hardcoded font-size
    font-size: 18px;

    .vipps {
        height: 16px;
    }

    .mobile-pay,
    .bambora {
        height: 40px;
    }

    b {
        margin-left: 4px;
    }

    &:not(:first-child) {
        border-top: 1px solid var(--color-divider);
    }

    > *:not(:first-child) {
        margin-left: 16px;
    }
`;

const LogoWrapper = styled.div`
    display: flex;
    align-items: center;
    width: 80px;
`;

const PaymentOptions = styled.div`
    background-color: var(--color-background-body);
    position: absolute;
    bottom: 0;
    display: grid;
    width: 100%;
    border-radius: 16px;
`;

export function V2PaymentButton({
    group,
    locationId,
    amount,
    productIds,
    productsWithCounts,
    onSuccess,
    onReset,
    config = {},
    isPay,
}) {
    const {
        isTermsAccepted,
        acceptTermsAndConditions,
        termsAndConditions,
        urlTermsAndConditions,
    } = useTermsAndConditions();

    const { venue, isProfileDataRequired } = useOrderGroup(locationId);
    const venueId = venue?.id;
    const venueName = venue?.name;
    const { getDiscountByVenueId, calculateDiscountByVenueId } = useDiscount();

    const { deviceFingerprint } = useDeviceFingerprint();
    const { cacheOrderId, refresh } = useOrdersPlaced();
    const [placeOrderResponse, setPlaceOrderResponse] = useState({});
    const [isTermsVisible, setIsTermsVisible] = useState(false);
    const [isTipOverlayVisible, setIsTipOverlayVisible] = useState(false);
    const [isDeliveryMethodsVisible, setIsDeliveryMethodsVisible] = useState(false);
    const [isPaymentOptionsVisible, setIsPaymentOptionsVisible] = useState(false);
    const [isCheckedIn, setIsCheckedIn] = useState(false);
    const [tipAmount, setTipAmount] = useState('');
    const { formatMinor, symbol, setCurrency } = useCurrency();

    useEffect(() => {
        if (isTermsAccepted) {
            setIsTermsVisible(false);
        }
    }, [isTermsAccepted]);

    const {
        deliveryMethodDefault,
        deliveryPickupEnabled,
        deliveryTableEnabled,
        deliveryTableProduct,
        tipEnabled,
        tipPercentPresets,
        paymentMode,
        covidTagTargetBarId,
    } = config;

    const {
        bar,
        isPaymentProviderBamboraEnabled,
        isPaymentProviderVippsEnabled,
        isPaymentProviderBamboraVippsEnabled,
        isPaymentProviderBamboraMobilePayEnabled,
    } = useOrderGroup(locationId);

    useEffect(() => {
        if (bar?.currency) {
            setCurrency(bar.currency);
        }
    }, [bar]);

    const currencyLabel = symbol;
    const discount = isPay ? undefined : getDiscountByVenueId(venueId);
    const amountTotalExclTip = isPay ? amount : calculateDiscountByVenueId(amount, venueId);
    const amountTotal = amountTotalExclTip + (tipAmount || 0);
    const isPayOrder = productIds?.length > 0;
    const buttonLabel = isPayOrder ? 'Pay' : 'Place order';

    const { isLoading: isPlacingOrder, run: placeOrder } = useApiQuery({
        query: async (params) => {
            for (const product of productsWithCounts) {
                if (product.modifierGroupValues) {
                    product.modifierValues = Object.keys(product.modifierGroupValues)
                        .map((name) => `${name}:${product.modifierGroupValues[name]}`)
                        .join(' • ');
                }
            }

            const { orderId, isPaymentRequired, paymentParams } = await MicroserviceOrders.placeOrder({
                deliveryType: group.deliveryTypeDefault || deliveryMethodDefault,
                ...params,
                locationId,
                productsWithCounts,
                deviceFingerprint,
                origin: window.location.origin,
                tipAmount,
                discountId: discount?.id,
            });

            GoogleAnalytics.event(
                GoogleAnalytics.Category.Customer,
                GoogleAnalytics.ActionCustomer.PlaceOrder,
                'Order group',
                amountTotal / 100,
            );

            cacheOrderId(orderId);

            // TODO: Remove timeout and cache order ID correctly
            await new Promise((r) => setTimeout(r, 500));

            await refresh();

            setPlaceOrderResponse({ orderId, isPaymentRequired, paymentParams, productIds });

            if (!isPaymentRequired) {
                handleOrderSuccess(orderId);
            }

            setIsDeliveryMethodsVisible(false);
        },
        onError(error) {
            GoogleAnalytics.event(
                GoogleAnalytics.Category.Customer,
                GoogleAnalytics.ActionCustomer.PlaceOrderError,
                error.message,
            );

            GoogleAnalytics.exception(error.message);
        },
    });

    const { isLoading: isPaying, run: payWithProvider } = useApiQuery(async ({ provider, paymentStatus }) => {
        const productIdsToPay =
            productIds || (placeOrderResponse.paymentParams && placeOrderResponse.paymentParams.productIds) || [];

        const orderId = placeOrderResponse?.orderId;

        const body = {
            productIds: productIdsToPay,
            provider,
            hash: locationId,
            paymentStatus,
            deviceFingerprint,
            origin: window.location.origin,
            orderId,
            redirectTo: orderId && `${window.location.origin}/order/${orderId}`,
            tipAmount: undefined,
        };

        if (isPayOrder && tipAmount) {
            body.tipAmount = tipAmount;
        }

        await MicroserviceOrders.payWithProvider(body);

        if (provider === PaymentType.DEVELOPMENT) {
            if (orderId) {
                handleOrderSuccess(orderId);
                window.location.href = OrderIdPage.route.replace(':orderId', orderId);
            }
        }
    });

    useEffect(() => {
        if (placeOrderResponse?.isPaymentRequired) {
            tryPay();
        }
    }, [placeOrderResponse]);

    function handleOrderSuccess(orderId) {
        cacheOrderId(orderId);
        close();
        onSuccess(orderId);
    }

    function close() {
        setIsDeliveryMethodsVisible(false);
        setIsPaymentOptionsVisible(false);
    }

    function clickPlaceOrder() {
        const requireDeliveryMethod = deliveryPickupEnabled || deliveryTableEnabled;

        if (requireDeliveryMethod) {
            setIsDeliveryMethodsVisible(true);
        } else {
            placeOrder();
        }
    }

    function chooseDeliveryType(deliveryType, deliveryTypeCost) {
        placeOrder({ deliveryType, deliveryTypeCost });
    }

    // if (placeOrderResponse?.orderId && !placeOrderResponse.isPaymentRequired) {
    //     push(OrderIdPage.route.replace(':orderId', placeOrderResponse.orderId));
    // }

    function tryPlaceOrder({ isTermsAccepted }) {
        if (!isTermsAccepted) {
            return setIsTermsVisible(true);
        }

        const showTipOverlay = paymentMode === GroupPaymentMode.Upfront && tipEnabled && !isTipOverlayVisible;

        if (showTipOverlay) {
            return setIsTipOverlayVisible(true);
        }

        clickPlaceOrder();
    }

    function getPercentValue(percent) {
        return Math.round((amount * (percent / 100)) / 100) * 100;
    }

    function tryPay(props) {
        const tipStepComplete = Boolean(props?.tipStepComplete);

        if (isPayOrder && paymentMode === GroupPaymentMode.After && tipEnabled && !tipStepComplete) {
            return setIsTipOverlayVisible(true);
        }

        setIsPaymentOptionsVisible(true);
    }

    return (
        <>
            {!isCheckedIn && isProfileDataRequired && (
                <ProfileDataForm
                    venueId={venueId}
                    venueName={venueName}
                    covidTagTargetBarId={covidTagTargetBarId}
                    onSuccess={() => setIsCheckedIn(true)}
                    onCancel={onReset}
                />
            )}

            {(productsWithCounts?.length > 0 && (
                <>
                    <ButtonBottom
                        onClick={() => tryPlaceOrder({ isTermsAccepted })}
                        isLoading={isPlacingOrder}
                        color="primary">
                        {buttonLabel} {formatMinor(amountTotal)}
                    </ButtonBottom>
                </>
            )) ||
                (isPayOrder && (
                    <ButtonBottom onClick={tryPay} isLoading={isPaying} color="primary">
                        {buttonLabel} {formatMinor(amountTotal)}
                    </ButtonBottom>
                ))}

            {isDeliveryMethodsVisible && (
                <UiOverlay position="bottom" transparent>
                    {isPlacingOrder && <V2Loader cover />}

                    <PaymentOptions>
                        {deliveryPickupEnabled && (
                            <PaymentProviderButton onClick={() => chooseDeliveryType(OrderDeliveryType.Pickup)}>
                                <label>{translate('Pickup')}</label>
                            </PaymentProviderButton>
                        )}

                        {deliveryTableEnabled && (
                            <PaymentProviderButton
                                onClick={() =>
                                    chooseDeliveryType(
                                        OrderDeliveryType.Table,
                                        deliveryTableProduct && deliveryTableProduct.priceIncludingVatMinorUnits,
                                    )
                                }>
                                <label>
                                    {translate('Table service')}
                                    {deliveryTableProduct && (
                                        <>
                                            &nbsp;+&nbsp;{formatMinor(deliveryTableProduct.priceIncludingVatMinorUnits)}
                                        </>
                                    )}
                                </label>
                            </PaymentProviderButton>
                        )}

                        <PaymentProviderButton onClick={close}>{translate('Cancel')}</PaymentProviderButton>
                    </PaymentOptions>
                </UiOverlay>
            )}

            {isPaymentOptionsVisible && (
                <UiOverlay position="bottom" transparent className="animated fadeInUp">
                    {isPaying && <V2Loader cover />}

                    <PaymentOptions>
                        {/*<UiBox padding={2}>*/}
                        {/*    <UiAlert color="warning">*/}
                        {/*        There are problems with the online card payment infrastructure in Norway and this*/}
                        {/*        affects all payments in Vipps.*/}
                        {/*        <br />*/}
                        {/*        <br />*/}
                        {/*        Try pressing the Giraffe in next step. Some cards with Visa/Mastercard will go through,*/}
                        {/*        some wont...*/}
                        {/*    </UiAlert>*/}
                        {/*</UiBox>*/}

                        {isPaymentProviderVippsEnabled && (
                            <PaymentProviderButton
                                onClick={() =>
                                    payWithProvider({
                                        provider: PaymentType.VIPPS,
                                    })
                                }>
                                <label>{translate('Pay with')}</label>
                                <LogoWrapper>
                                    <img src={imageVipps} alt="Vipps" className="vipps" />
                                </LogoWrapper>
                            </PaymentProviderButton>
                        )}

                        {isPaymentProviderBamboraVippsEnabled && (
                            <PaymentProviderButton
                                onClick={() =>
                                    payWithProvider({
                                        provider: PaymentType.BAMBORA_VIPPS,
                                    })
                                }>
                                <label>{translate('Pay with')}</label>
                                <LogoWrapper>
                                    <img src={imageVipps} alt="Vipps" className="vipps" />
                                </LogoWrapper>
                            </PaymentProviderButton>
                        )}

                        {isPaymentProviderBamboraMobilePayEnabled && (
                            <PaymentProviderButton
                                onClick={() =>
                                    payWithProvider({
                                        provider: PaymentType.BAMBORA_MOBILE_PAY,
                                    })
                                }>
                                <label>{translate('Pay with')}</label>
                                <LogoWrapper>
                                    <img
                                        src="https://orderx-content.s3-eu-west-1.amazonaws.com/images/mobile-pay.png"
                                        alt="MobilePay"
                                        className="mobile-pay"
                                    />
                                </LogoWrapper>
                            </PaymentProviderButton>
                        )}

                        {isPaymentProviderBamboraEnabled && (
                            <PaymentProviderButton
                                onClick={() =>
                                    payWithProvider({
                                        provider: PaymentType.BAMBORA,
                                    })
                                }>
                                <label>{translate('Pay with')}</label>
                                <LogoWrapper>
                                    <img src={imageBambora} alt="Bambora" className="bambora" />
                                </LogoWrapper>
                            </PaymentProviderButton>
                        )}

                        {IS_DEV && (
                            <>
                                <PaymentProviderButton
                                    onClick={() =>
                                        payWithProvider({
                                            provider: PaymentType.DEVELOPMENT,
                                            paymentStatus: PaymentStatus.Failed,
                                        })
                                    }>
                                    <label>{translate('[DEV] Failed payment')}</label>
                                </PaymentProviderButton>

                                <PaymentProviderButton
                                    onClick={() =>
                                        payWithProvider({
                                            provider: PaymentType.DEVELOPMENT,
                                            paymentStatus: PaymentStatus.Pending,
                                        })
                                    }>
                                    <label>{translate('[DEV] Pending payment')}</label>
                                </PaymentProviderButton>

                                <PaymentProviderButton
                                    onClick={() =>
                                        payWithProvider({
                                            provider: PaymentType.DEVELOPMENT,
                                            paymentStatus: PaymentStatus.Success,
                                        })
                                    }>
                                    <label>{translate('[DEV] Successful payment')}</label>
                                </PaymentProviderButton>
                            </>
                        )}

                        <PaymentProviderButton onClick={close} fontWeight={400}>
                            {translate('Cancel')}
                        </PaymentProviderButton>
                    </PaymentOptions>
                </UiOverlay>
            )}

            {isTermsVisible && (
                <UiOverlay position="bottom" height="50%" onClose={() => setIsTermsVisible(false)}>
                    <UiToolbar>
                        <ToolbarTitle title="Terms and conditions" />

                        <UiButton
                            onClick={() => setIsTermsVisible(false)}
                            size={2}
                            icon={<UiIconMaterial>close</UiIconMaterial>}
                        />
                    </UiToolbar>

                    <UiGrid outerSpacing={2}>
                        {termsAndConditions ? (
                            <div dangerouslySetInnerHTML={{ __html: termsAndConditions }} />
                        ) : (
                            <a href={urlTermsAndConditions}>You can read terms and conditions by clicking here</a>
                        )}
                    </UiGrid>

                    <ButtonBottom
                        onClick={() => {
                            acceptTermsAndConditions();
                            tryPlaceOrder({ isTermsAccepted: true });
                        }}
                        color="primary">
                        I accept
                    </ButtonBottom>
                </UiOverlay>
            )}

            {isTipOverlayVisible && (
                <UiOverlay position="bottom" height={176 + 48 + 16} onClose={() => setIsTermsVisible(false)}>
                    <UiToolbar>
                        <ToolbarTitle title="Do you wish to leave tip?" />

                        <UiButton
                            onClick={() => setIsTipOverlayVisible(false)}
                            size={2}
                            icon={<UiIconMaterial>close</UiIconMaterial>}
                        />
                    </UiToolbar>

                    <UiGrid outerSpacing={2}>
                        <UiGrid minMax={70} spacing={1}>
                            {tipPercentPresets.map((percent) => {
                                const amount = getPercentValue(percent);

                                if (amount === 0) {
                                    return <Fragment key={percent} />;
                                }

                                return (
                                    <UiButton
                                        onClick={() => setTipAmount(tipAmount === amount ? '' : amount)}
                                        color={amount === tipAmount ? 'secondary' : 'default'}
                                        variant="filled"
                                        size={1}
                                        key={percent}>
                                        {percent}%
                                    </UiButton>
                                );
                            })}

                            <UiInput
                                value={tipAmount ? Math.round(tipAmount / 100) : ''}
                                onChangeValue={(value) => {
                                    setTipAmount(value ? Math.round(Number(value)) * 100 : '');
                                }}
                                size={1}
                                label={currencyLabel}
                                placeholder={currencyLabel}
                            />
                        </UiGrid>

                        {tipAmount > 0 && (
                            <UiFlex justifyContent="space-between">
                                <UiText size={1} color="text-secondary">
                                    {formatMinor(amount)}
                                </UiText>

                                <UiIconMaterial>arrow_right_alt</UiIconMaterial>

                                <UiText size={1} color="success" weight={2}>
                                    New total: {formatMinor(amountTotal)}
                                </UiText>
                            </UiFlex>
                        )}

                        <ButtonBottom
                            onClick={() => {
                                if (isPayOrder) {
                                    tryPay({ tipStepComplete: true });
                                } else {
                                    tryPlaceOrder({ isTermsAccepted });
                                }

                                setIsTipOverlayVisible(false);
                            }}
                            variant="filled"
                            color="primary">
                            {tipAmount > 0 ? (
                                <>
                                    {buttonLabel} {formatMinor(amountTotal)}
                                </>
                            ) : (
                                <>No tip</>
                            )}
                        </ButtonBottom>
                    </UiGrid>
                </UiOverlay>
            )}
        </>
    );
}
