import MenuItem from '@material-ui/core/MenuItem';
import React from 'react';
import Select from 'react-select';
import { themeActive } from '../../themes/dark';

const styles = {
    control: (provided) => ({
        ...provided,
        background: 'transparent',
        borderColor: themeActive.colors.border,
        minWidth: 160,
        height: themeActive.sizes.inputHeight,
        overflow: 'hidden',
    }),
    menu: (provided) => ({
        ...provided,
        zIndex: 1000,
        background: themeActive.colors.paper,
    }),
    option: (provided, { isSelected }) => ({
        ...provided,
        color: themeActive.colors.text,
        background: isSelected ? themeActive.colors.primary : 'transparent',
    }),
    indicatorSeparator: (provided) => ({
        ...provided,
        background: themeActive.colors.paper,
    }),
    dropdownIndicator: (provided) => ({
        ...provided,
        color: themeActive.colors.paper,
    }),
    clearIndicator: (provided) => ({
        ...provided,
        color: themeActive.colors.paper,
    }),
    multiValue: (provided) => ({
        ...provided,
        background: themeActive.colors.paper,
        color: themeActive.colors.text,
    }),
    multiValueLabel: (provided) => ({
        ...provided,
        color: themeActive.colors.text,
    }),
    // multiValueRemove: provided => ({
    //     ...provided,
    //     background: themeActive.colors.background,
    // }),
};

const Option = ({ children, innerRef, innerProps }) => (
    <MenuItem ref={innerRef} className={'needsclick'} {...innerProps}>
        {children}
    </MenuItem>
);

export function UiSelect({ ...rest }) {
    return (
        <Select
            styles={styles}
            {...rest}
            components={{ Option }}
            onChange={(newValue, { action, option, removedValue }) => {
                if (rest.onChange) {
                    rest.onChange(newValue);
                }
            }}
        />
    );
}
