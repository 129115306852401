import React from 'react';
import { routes, withParams } from '../../shared/router';
import { useSnackbar } from '../../shared/snackbar';
import { useCreateOrderTerminals } from '../../stores/create-order-terminals';
import { useStaffActiveBar } from '../../stores/staff-active-bar';
import StaffBarSettingsTerminalSlider from '../StaffBarSettingsTerminalSlider';
import { ListItem } from '../../@v2/components/list/item/ListItem';

export function StaffOrderConfigTerminalSliderPage({ history }) {
    const { id: barId } = useStaffActiveBar();
    const { clearTerminalOrders } = useCreateOrderTerminals();
    const { showSuccess } = useSnackbar();

    return (
        <>
            <StaffBarSettingsTerminalSlider
                history={history}
                onClickBack={() => history.push(withParams(routes.staffOrderBarConfig, { barId }))}
            />

            <ListItem
                title="Clear terminal orders"
                onClick={() => {
                    clearTerminalOrders();
                    showSuccess('Cleared terminal orders');
                }}
            />
        </>
    );
}
