import React from 'react';
import styled, { css } from 'styled-components';
import { mixins } from '../shared/styles';
import { themeActive } from '../themes/dark';
import { CssLoader } from './CssLoader';
import { Svg } from './Svg';

const styles = {
    primary: css`
        background-image: ${props => (props.outline ? 'none' : themeActive.gradients.primary)};
        border: 1px solid ${props => (props.outline ? themeActive.colors.primary : 'transparent')};
        color: ${themeActive.colors.text};
    `,
    secondary: css`
        background: ${props => (props.outline ? 'transparent' : themeActive.colors.secondary)};
        border-color: ${themeActive.colors.secondary};
        color: ${props => (props.outline ? themeActive.colors.text : themeActive.colors.text)};
    `,
    negative: css`
        background: ${props => (props.outline ? 'transparent' : themeActive.colors.red)};
        border-color: ${themeActive.colors.red};
        color: ${props => (props.outline ? themeActive.colors.red : themeActive.colors.text)};
    `,
    positive: css`
        background: ${props => (props.outline ? 'transparent' : themeActive.colors.green)};
        border-color: ${themeActive.colors.green};
        color: ${props => (props.outline ? themeActive.colors.green : themeActive.colors.text)};
    `,
};

const block = ({ block }) =>
    block &&
    css`
        display: block;
        width: 100%;
    `;

const link = ({ link }) =>
    link &&
    css`
        background-color: transparent;
        outline: none;
        border: none;
        text-transform: initial;
        font-weight: 400;
        padding: 8px;
    `;

const Wrapper = styled.button`
    ${mixins.flexXY};
    border-radius: ${themeActive.sizes.borderRadius}px;
    height: ${themeActive.sizes.buttonHeight}px;
    background: ${props => (props.outline ? 'transparent' : themeActive.body.backgroundColor)};
    color: ${props => (props.reversedColor ? themeActive.colors.textReversed : themeActive.colors.text)};
    border: 1px solid ${themeActive.body.backgroundColor};
    padding: 0 16px;
    transition: 300ms;
    font-weight: bold;
    font-size: 1rem;
    position: relative;
    user-select: none;
    
    ${props =>
        props.disabled &&
        css`
            opacity: 0.5;

            .content {
                opacity: 0.5;
            }
        `}
    

    :focus,
    :hover {
        cursor: pointer;
        outline: none;
        text-decoration: none;
    }
    
    .content {
        ${mixins.flexXY};
        
        > *:not(:first-child) {
            margin-left: 8px;
        }
    }
    
    ${props => props.primary && styles.primary};
    ${props => props.secondary && styles.secondary};
    ${props => props.negative && styles.negative};
    ${props => props.positive && styles.positive};
    
    ${block};
    ${link};
    
    border-width: ${props => (props.noBorder ? 0 : 1)}px;
    
    ${props =>
        props.vertical &&
        css`
            .content {
                flex-flow: column;
            }
        `}
    
    ${props =>
        props.clear &&
        css`
            background-color: transparent;
            border-color: transparent;
            border-radius: 0;
            padding: 0;
            height: auto;
        `}
    
    ${props =>
        props.isLoading &&
        css`
            opacity: 0.25;

            .content {
                opacity: 0;
            }

            .css-loader {
                position: absolute;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
            }
        `}
    
    ${props =>
        (props.small || props.link) &&
        css`
            font-size: 0.75rem;
            height: 32px;
        `}
    
    ${props =>
        props.transparent &&
        css`
            background-color: transparent;
            border-color: transparent;
        `}
`;

export function Button({
    primary,
    secondary,
    negative,
    positive,
    outline,
    block,
    link,
    small,
    vertical,
    clear,
    reversedColor,
    noBorder,
    isLoading,
    active,
    disabled,
    children,
    onClick,
    className,
    type = 'button',
    icon,
    iconFill,
    iconSize = 24,
    onTouchStart,
    onTouchEnd,
    transparent = false,
}) {
    className = [className || '', active ? 'active' : ''].join(' ');

    return (
        <Wrapper
            {...{
                disabled,
                primary,
                secondary,
                negative,
                positive,
                outline,
                block,
                link,
                small,
                vertical,
                clear,
                reversedColor,
                noBorder,
                isLoading,
                active,
                onClick,
                type,
                onTouchStart,
                onTouchEnd,
                transparent,
            }}
            disabled={disabled}
            className={className}>
            {isLoading && <CssLoader margin={0} className="css-loader" />}

            <span className="content">
                {icon && <Svg icon={icon} size={iconSize} fill={iconFill} />}
                {children && <span>{children}</span>}
            </span>
        </Wrapper>
    );
}
