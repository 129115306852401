import makeStyles from '@material-ui/core/styles/makeStyles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import IconArrowDown from '@material-ui/icons/KeyboardArrowDownRounded';
import clsx from 'clsx';
import sum from 'lodash/sum';
import React, { Fragment, useCallback, useEffect, useMemo, useState } from 'react';
import { MicroserviceOrders } from '../../microservices/orders';
import { useStaffOrderingBar } from '../../stores/staff-ordering-bar';
import { useApiQuery } from '@orderx/http';
import { Currency } from '../Currency';
import { UiLoader } from '../ui/UiLoader';
import { UiFlex, UiButton } from '@orderx/ui';

const useStyles = makeStyles({
    clientHeaderRow: {
        textTransform: 'uppercase',
        letterSpacing: 1,
        backgroundColor: '#333',
        position: 'relative',
        '& td': {
            fontWeight: 700,
            paddingTop: 0,
            paddingBottom: 0,
        },
    },
    balanceTitle: {
        padding: 16,
    },
    toggleArrow: {
        transition: 'opacity 200ms, transform 200ms',
        opacity: 0.25,
    },
    toggleArrowExpanded: {
        transform: 'rotate(180deg)',
        opacity: 1,
    },
    tableCellPay: {
        width: 64,
    },
});

export function StaffOrderViewBalancePay({ barId, products, onPayWithBalance, disabled = false }) {
    const staffOrderingBarBarId = useStaffOrderingBar().barId;
    barId = barId || staffOrderingBarBarId;
    const classes = useStyles();

    const { run: loadClients, response } = useApiQuery(async () =>
        MicroserviceOrders.staffApp({
            action: 'FIND_CLIENTS',
            barId,
        }),
    );

    const clients = (response && response.clients) || [];

    const totalAmountNotPaid = sum(products.map((product) => product.priceIncludingVat));
    const [activeClientId, setActiveClientId] = useState('');

    const getClientBalances = useCallback(
        (clientId) => {
            if (!response) {
                return [];
            }

            return response.balancesByClientId[clientId] || [];
        },
        [response],
    );

    const canPayWithBalance = useCallback(
        (balance) => totalAmountNotPaid > 0 && balance.count * 100 >= totalAmountNotPaid,
        [totalAmountNotPaid],
    );

    useEffect(() => {
        loadClients();
    }, []);

    const clientsFiltered = useMemo(
        () => (clients.length > 0 && clients.filter((client) => getClientBalances(client.id).length > 0)) || [],
        [clients],
    );

    if (totalAmountNotPaid === 0) {
        return <h4 className={classes.balanceTitle}>All products are paid for!</h4>;
    }

    return (
        <>
            {!response && <UiLoader />}

            <Table>
                {clientsFiltered.length > 0 && (
                    <TableHead>
                        <TableRow>
                            <TableCell>Name</TableCell>
                            <TableCell colSpan={2} align={'right'}>
                                Amount remaining
                            </TableCell>
                        </TableRow>
                    </TableHead>
                )}

                <TableBody>
                    {clientsFiltered.map((client) => (
                        <Fragment key={client.id}>
                            <TableRow className={classes.clientHeaderRow}>
                                <TableCell
                                    onClick={() => setActiveClientId(activeClientId === client.id ? '' : client.id)}
                                    colSpan={3}>
                                    <UiFlex justifyContent={'space-between'}>
                                        <span>{client.companyName}</span>
                                        <UiButton
                                            icon={
                                                <IconArrowDown
                                                    className={clsx(
                                                        classes.toggleArrow,
                                                        activeClientId === client.id && classes.toggleArrowExpanded,
                                                    )}
                                                />
                                            }
                                        />
                                    </UiFlex>
                                </TableCell>
                            </TableRow>

                            {activeClientId === client.id &&
                                getClientBalances(client.id).map((balance) => {
                                    const canPay = canPayWithBalance(balance);

                                    return (
                                        <TableRow key={balance.id} className={'animated fadeIn'}>
                                            <TableCell>{balance.userFirstName || balance.id}</TableCell>
                                            <TableCell align={'right'}>
                                                <Currency amount={balance.count} />
                                            </TableCell>
                                            <TableCell align={'right'} className={classes.tableCellPay}>
                                                <UiButton
                                                    onClick={() => onPayWithBalance(balance.id)}
                                                    disabled={disabled || !canPay}
                                                    color={canPay ? 'primary' : 'default'}
                                                    variant={canPay ? 'filled' : 'text'}
                                                    size={-1}>
                                                    Pay
                                                </UiButton>
                                            </TableCell>
                                        </TableRow>
                                    );
                                })}
                        </Fragment>
                    ))}
                </TableBody>
            </Table>
        </>
    );
}
