import { createStore } from '@orderx/store';
import { apiMeGet } from '../../api/me.[get]';
import { storageGet, storageSet } from '../../shared/storage';

const { useStore, reset } = createStore<any>(undefined);

export function useMe() {
    const [state, setState] = useStore();

    return {
        userId: state?.id,
        user: state,
        isAuthenticated: Boolean(state),
        async loadUser() {
            const user = await apiMeGet({});
            setState(user);
            return user;
        },
        setToken(token) {
            storageSet('token', token);
        },
        signOut() {
            reset();
            storageGet('token');
        },
    };
}
