import React, { useState } from 'react';
import { UiInput } from '../../../ui/UiInput';
import { StaffOrderViewBalancePay } from '../../../staff-order/StaffOrderViewBalancePay';
import { PosGroupProducts } from '../products/PosGroupProducts';
import { useApiQuery } from '@orderx/http';
import { MicroserviceLegacyApp } from '../../../../microservices/legacy-app';
import { ORDER_STATUS } from '../../../../shared/orders';
import { useSnackbar } from '../../../../shared/snackbar';
import { useDeviceConfig } from '../../../../stores/device-config';
import { UiBox, UiToolbar } from '@orderx/ui';
import { PosGroupFooter } from '../footer/PosGroupFooter';
import { usePosGroup } from '../../../../hooks/pos/group';
import { PosGroupFooterButtonProducts } from '../footer/button-products/PosGroupFooterButtonProducts';
import { PosPageLoader } from '../../page/loader/PosPageLoader';

export function PosGroupPaymentBalance() {
    const { groupId, barId, loadGroup, productsChecked, resetProductsChecked } = usePosGroup();
    const [comment, setComment] = useState('');
    const { showSuccess, showError } = useSnackbar();
    const { config } = useDeviceConfig();

    const { run: payWithBalance, isLoading: isCreatingOrder } = useApiQuery(
        async ({ value, productsToPay, comment }) => {
            const { response } = await MicroserviceLegacyApp.postOrder({
                barId,
                tag: {
                    type: 'nfc',
                    value,
                },
                groupId,
                payForExistingProducts: true,
                productsToPay,
                gratitude: config.isGratitudeOn,
                comment,
            });

            if (response.order.Status === ORDER_STATUS.FINALIZED) {
                await loadGroup();
                resetProductsChecked();
                showSuccess('Payment successful');
            } else {
                showError('Something went wrong...');
            }
        },
    );

    return (
        <>
            <PosGroupProducts isSelectable />

            {isCreatingOrder && <PosPageLoader message="Paying..." />}

            {productsChecked.length > 0 && (
                <>
                    <UiBox padding={2}>
                        <UiInput
                            placeholder={'Why is the order paid by balance?'}
                            value={comment}
                            onChangeValue={setComment}
                            multiline
                            rows={3}
                        />
                    </UiBox>

                    {comment && (
                        <StaffOrderViewBalancePay
                            barId={barId}
                            products={productsChecked}
                            onPayWithBalance={(id) => {
                                payWithBalance({
                                    value: id,
                                    productsToPay: productsChecked,
                                    comment,
                                });
                            }}
                        />
                    )}
                </>
            )}

            <UiToolbar position="bottom">
                <div />
                <PosGroupFooterButtonProducts />
                <div />
            </UiToolbar>
        </>
    );
}
