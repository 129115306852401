import React, { useEffect } from 'react';
import { useCountUp } from 'react-countup';
import { Currency } from './Currency';

export function AnimateCount({ value, currency, formattingFunction }) {
    const { countUp, update } = useCountUp({
        start: value,
        end: value,
        duration: 0.5,
        formattingFn: formattingFunction || (value => value),
    });

    useEffect(() => update(value), [value]);

    return currency ? <Currency amount={Number(countUp)} currency={currency} /> : countUp;
}
