import clsx from 'clsx';
import React, { useEffect, useState } from 'react';
import uniq from 'lodash/uniq';
import { Order } from '../../../models/order';
import { OrderProductStatus } from '../../../models/order-product';
import { useCustomerOrders } from '../../../hooks/customer-orders';
import { useApiQuery } from '@orderx/http';
import { apiMeOrderPost } from '../../../../api/me.order.[post]';
import './V2OrderPlaced.scss';
import { useInterval } from '../../../../use/interval';
import { useOnResume } from '../../../../shared/browser';
import { UiImage, UiButton, UiIconMaterial, UiFlex, UiText } from '@orderx/ui';
import { NavLink } from 'react-router-dom';
import { useRouter } from '../../../hooks/router';
import { V2OrderIdPage } from '../../../pages/v2-order-id/V2OrderIdPage';

export function V2OrderPlaced() {
    const { orderIdsCached } = useCustomerOrders();
    const { replaceParams } = useRouter();
    const [isCompact, setIsCompact] = useState(false);

    const { run: loadOrders, response: orders } = useApiQuery<Order[]>(() =>
        apiMeOrderPost({
            body: { orderIds: orderIdsCached },
        }),
    );

    useEffect(() => {
        loadOrders();
    }, []);

    useInterval(() => {
        loadOrders();
    }, 30000);

    useOnResume(loadOrders);

    function getOrderProductStatuses(order: Order): OrderProductStatus[] {
        return uniq(order.orderProducts.map(({ status }) => status));
    }

    if (!orders?.length) {
        return null;
    }

    return (
        <div className={clsx('V2OrderPlaced')}>
            {orders.length > 1 && (
                <div className="toggle-compact" onClick={() => setIsCompact(!isCompact)}>
                    <UiIconMaterial>{isCompact ? 'unfold_more' : 'unfold_less'}</UiIconMaterial>
                </div>
            )}

            {orders.map((order) => (
                <NavLink
                    to={replaceParams(V2OrderIdPage.route, { orderId: order.id })}
                    key={order.id}
                    className={clsx(
                        'item',
                        isCompact && orders.length > 0 && 'compact',
                        ...getOrderProductStatuses(order),
                    )}>
                    <div className="indicator" />

                    <div className="content">
                        <UiImage src={order.outlet.location.imageLogo} type="cover" />

                        <div className="overlay">
                            <UiText weight={2} size={5}>
                                {order.sequence}
                            </UiText>
                        </div>
                    </div>
                </NavLink>
            ))}
        </div>
    );
}
