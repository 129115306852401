import { storage } from '../shared/storage';
import { createStore, useStore } from '../create-store';

const StorageKey = 'locationTerminals';
const store = createStore(storage.get(StorageKey) || {});

export function useLocationTerminals(locationId) {
    const [locationTerminals] = useStore(store);
    return locationTerminals[locationId] || [];
}

export function setLocationTerminals(locationId, terminals = []) {
    const nextState = {
        ...store.state,
        [locationId]: terminals || [],
    };

    store.set(nextState);
    storage.set(StorageKey, nextState);
}
