import React from 'react';
import Slider from 'react-slick/lib';
import styled from 'styled-components';
import orderxHand from '../assets/orderx-hand.png';
import { getUrlToAsset } from '../shared/assets';
import { mixins } from '../shared/styles';
import { isTypeCount, isTypeFiat, isTypeVouchers } from '../shared/user-balances';
import { Card } from './Card';

const Wrapper = styled.div`
    height: 100%;

    .slick-slider,
    .slick-list,
    .slick-track,
    .slick-track > *,
    .slick-track > * > *,
    .balance-card {
        height: 100%;
    }

    .slick-track > *:not(:first-child) .balance-card {
        margin-left: 16px;
    }

    .balance-card {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        padding: 32px;
        text-align: center;

        > * {
            flex: 1;
        }

        img {
            display: inline-block;
            max-height: 160px;
        }
    }

    .slick-track {
        background-color: transparent;
    }
`;

const CardContent = styled.div`
    ${mixins.flexXY};
    height: 100%;
`;

export default function UserBalancesSlider({ userBalances = [], onChangeUserBalance = () => {} }) {
    const settings = {
        arrows: false,
        className: 'center',
        centerMode: true,
        focusOnSelect: true,
        swipeToSlide: true,
        infinite: false,
        centerPadding: '40px',
        slidesToShow: 1,
        speed: 300,
    };

    return (
        <Wrapper>
            <Slider
                {...settings}
                beforeChange={(previousIndex, nextIndex) => onChangeUserBalance(userBalances[nextIndex])}>
                {userBalances.map(userBalance => (
                    <Card key={userBalance.id} className="balance-card" padding={0}>
                        {isTypeFiat(userBalance) && <h4>Amount left:</h4>}
                        {isTypeVouchers(userBalance) && <h4>Vouchers left:</h4>}
                        {isTypeCount(userBalance) && <h4>Items left:</h4>}

                        <CardContent>
                            <h1>{userBalance.count}</h1>
                        </CardContent>

                        <div>
                            <img src={getUrlToAsset(orderxHand)} alt="orderx" />
                        </div>
                    </Card>
                ))}
            </Slider>
        </Wrapper>
    );
}
