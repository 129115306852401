import { createStore } from '@orderx/store';
import { MicroserviceOrders } from '../../microservices/orders';

const { useStore } = createStore(0);

export function useStaffBottomToolbar() {
    const [count, setCount] = useStore();

    async function loadCount(config) {
        try {
            const { groupFilterActive, groupFilterIds, barIds } = config;

            const { count } = await MicroserviceOrders.staffApp({
                action: 'handlerAppStaffDeliveryViewOrderCount',
                barIds,
                groupFilterIds:
                    groupFilterActive && barIds.length === 1 && groupFilterIds?.length > 0 ? groupFilterIds : [],
            });

            setCount(count);
        } catch (error) {}
    }

    return {
        count,
        setCount,
        loadCount,
    };
}
