import { Entity, Column, OneToMany, ManyToOne } from './typeorm';
import { BaseModel } from './base-model';
import { InventoryPlugin } from './inventory-plugin';
import { InventoryMenuProduct } from './inventory-menu-product';
import { Outlet } from './outlet';

@Entity()
export class InventoryMenu extends BaseModel {
    @Column()
    name: string;

    @OneToMany(() => InventoryMenuProduct, (model) => model.menu)
    menuProducts: InventoryMenuProduct[];

    @OneToMany(() => Outlet, (model) => model.inventoryMenu)
    outlets: Outlet[];

    @ManyToOne(() => InventoryPlugin, (model) => model.menus)
    plugin: InventoryPlugin;
    @Column()
    pluginId: number;
}
