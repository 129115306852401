import { createStore } from '@orderx/store';
import { storageGet, storageSet } from '../../shared/storage';
import { useEffect } from 'react';

const storageKey = 'usePosTerminal.isActive';
const { useStore } = createStore(storageGet(storageKey));

export function usePosTerminal() {
    const [isActive, setIsActive] = useStore();

    useEffect(() => {
        storageSet(storageKey, isActive);
    }, [isActive]);

    return {
        isActive,
        setIsActive,
    };
}
