import { createStore, useStore } from '../../create-store';

const store = createStore(getInitialState());

export function usePosBar() {
    const [state, setState] = useStore(store);

    return {
        barId: state.bar?.id,
        bar: state.bar,
        products: state.products || [],

        setBar(bar) {
            setState(state => {
                state.bar = bar;
            });
        },

        setProducts(products) {
            setState(state => {
                state.products = products;
            });
        },

        reset() {
            setState(getInitialState());
        },

        state,
    };
}

function getInitialState() {
    return {
        barId: undefined,
        bar: undefined,
        products: [],
    };
}
