import makeStyles from '@material-ui/core/styles/makeStyles';
import React from 'react';
import { useAwsIotStatus } from '../../providers/aws-iot';
import { useCreateOrder } from '../../stores/create-order';
import { useCreateOrderHistory } from '../../stores/create-order-history';
import { useCreateOrderQuery } from '../../stores/create-order-query';
import { useCreateOrderTerminals } from '../../stores/create-order-terminals';
import { themeActive } from '../../themes/dark';
import { StaffCreateTerminalSliderButton } from './StaffCreateTerminalSliderButton';

const useStyles = makeStyles({
    StaffCreateTerminalSlider: ({ sizeMultiplier }) => ({
        position: 'fixed',
        bottom: themeActive.tabs.height,
        height: themeActive.tabs.height * sizeMultiplier,
        width: '100%',
        backgroundColor: themeActive.tabs.backgroundColor,
        padding: 4,
        display: 'inline-flex',
        alignItems: 'center',
        whiteSpace: 'nowrap',
        overflow: 'auto',
    }),
    mqttIndicator: ({ connected, sizeMultiplier }) => ({
        bottom: themeActive.tabs.height + themeActive.tabs.height * sizeMultiplier,
        backgroundColor: connected ? themeActive.colors.success : themeActive.colors.warning,
        position: 'fixed',
        left: 0,
        height: 2,
        width: '100%',
    }),
    placeholder: ({ sizeMultiplier }) => ({
        height: themeActive.tabs.height * sizeMultiplier,
    }),
});

export function StaffCreateTerminalSlider() {
    const { canPlaceOrder, placeOrder } = useCreateOrderQuery();
    const { terminals, compact, getLatestTerminalOrder, setTerminalOrder, removeOrder } = useCreateOrderTerminals();
    const { connected } = useAwsIotStatus();
    const classes = useStyles({ connected, sizeMultiplier: compact ? 1 : 1.5 });
    const { isPosMode } = useCreateOrder();
    const { putOrder: putOrderToHistory } = useCreateOrderHistory();

    if (!isPosMode) {
        return null;
    }

    function handleTerminal(terminal) {
        if (canPlaceOrder) {
            placeOrder({ terminal });
        }
    }

    return (
        <>
            <div className={classes.placeholder} />

            <div className={classes.mqttIndicator} />

            <div className={classes.StaffCreateTerminalSlider}>
                {terminals.map(terminal => (
                    <StaffCreateTerminalSliderButton
                        compact={compact}
                        terminal={terminal}
                        order={getLatestTerminalOrder(terminal.id)}
                        onUpdateOrder={order => {
                            setTerminalOrder(terminal.id, order);
                            putOrderToHistory(order);
                        }}
                        onRemoveOrderId={orderId => removeOrder(terminal.id, orderId)}
                        onClick={() => handleTerminal(terminal)}
                        key={terminal.id}
                    />
                ))}
            </div>
        </>
    );
}
