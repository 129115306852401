import React from 'react';
import styled from 'styled-components';
import { useLocationTerminalSettings } from '../stores/location-terminal-settings';
import { useStaffActiveBar } from '../stores/staff-active-bar';
import { themeActive } from '../themes/dark';
import { Size, StaffTerminalsSlider, styles } from './StaffTerminalsSlider';

const Wrapper = styled.div`
    display: block;
    position: fixed;
    bottom: ${themeActive.sizes.tabsHeight}px;
    width: 100%;
    left: 0;
`;

export function StaffTerminalsSliderBottom({ barId, onClickOrder = () => {}, onClickTerminal = () => {} }) {
    const bar = useStaffActiveBar();
    barId = barId || (bar && bar.id);
    const { isExpanded } = useLocationTerminalSettings(barId);
    const size = isExpanded ? Size.Expanded : Size.Compact;

    return (
        <>
            <div style={{ height: styles[size].placeholderHeight }} />

            <Wrapper size={size} className="StaffTerminalsSliderBottom">
                <StaffTerminalsSlider barId={barId} onClickOrder={onClickOrder} onClickTerminal={onClickTerminal} />
            </Wrapper>
        </>
    );
}
