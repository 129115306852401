import clsx from 'clsx';
import React, { useEffect } from 'react';
import { UiOverlay, UiFlex, UiText, UiGrid, UiIconMaterial } from '@orderx/ui';
import './OrderSuccessOverlay.scss';
import { useRouter } from '../../../hooks/router';

interface Props {
    redirectTo: any;
}

export function OrderSuccessOverlay({ redirectTo }: Props) {
    const { push } = useRouter();

    useEffect(() => {
        setTimeout(() => {
            push(redirectTo);
        }, 2000);

        return () => clearTimeout();
    }, [redirectTo]);

    return (
        <UiOverlay className={clsx('OrderSuccessOverlay')} transparent>
            <UiFlex fullWidth fullHeight justifyContent="center" padding={8}>
                <UiGrid className="animated flipInX">
                    <UiText size={6} weight={2} color="success" align="center">
                        <UiIconMaterial>check_circle</UiIconMaterial>
                        <br />
                        Order placed!
                    </UiText>
                </UiGrid>
            </UiFlex>
        </UiOverlay>
    );
}
