import { createStore, useStore } from '../create-store';
import { ProductStatus } from '../models/orders/product';
import { storageGet, storageSet } from '../shared/storage';

const store = createStore(
    Object.assign(
        {
            isGratitudeOn: false,
            isStationViewVisible: true,
            isOrderCommentsEnabled: true,
            isOrderPrintSequenceEnabled: false,
            isNewOrderCreateView: true,
            hasPagination: false,
            isStationStatusesLocked: false,
            isManualEntryVisible: false,
            allowedStationFoodStatuses: Object.values(ProductStatus),
            allowedStationDrinkStatuses: Object.values(ProductStatus),
            isCommentsVisibleInListView: false,
        },
        storageGet('deviceConfig'),
    ),
);

export function useDeviceConfig() {
    const [state, setState] = useStore(store);

    return {
        config: state,
        updateConfig: object => {
            setState(state => {
                const nextState = { ...state, ...object };
                storageSet('deviceConfig', nextState);
                return nextState;
            });
        },
        isStationCategoryStatusAllowed(category, status) {
            if (category === 'FOOD') {
                return state.allowedStationFoodStatuses.includes(status);
            } else if (category === 'DRINK') {
                return state.allowedStationDrinkStatuses.includes(status);
            } else {
                return true;
            }
        }
    };
}
