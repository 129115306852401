import React from 'react';
import styled from 'styled-components';
import { useIsOnline } from '../stores/connectivity';
import { themeActive } from '../themes/dark';

const Wrapper = styled.div`
    position: fixed;
    top: ${props => (props.isOnline ? 4 : 0)}px;
    right: ${props => (props.isOnline ? 4 : 0)}px;
    height: ${props => (props.isOnline ? 4 : 2)}px;
    width: ${props => (props.isOnline ? '4px' : '100%')};
    border-radius: ${props => (props.isOnline ? 100 : 0)}%;
    background-color: ${props => (props.isOnline ? themeActive.colors.green : themeActive.colors.red)};
    box-shadow: 0 0 4px ${props => (props.isOnline ? themeActive.colors.green : themeActive.colors.red)};
    z-index: 100;
    transition: right 300ms, top 300ms, width 300ms, height 300ms, background-color 300ms;
`;

export function Connectivity() {
    const [isOnline] = useIsOnline();
    return <Wrapper isOnline={isOnline} />;
}
