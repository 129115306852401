import clsx from 'clsx';
import React from 'react';
import { UiGrid, UiText, UiBox } from '@orderx/ui';
import { LocationBrowseListCard } from '../../../@v2/components/location/browse-list-card/LocationBrowseListCard';
import { storageSet } from '../../../shared/storage';
import { useLocation } from 'react-router-dom';
import { CustomerBrowseGroupsHashPage } from '../../../pages/CustomerBrowseGroupsHashPage';

interface Props {
    tag?: any;
}

export function TagLocationGroupWithTable({ tag }: Props) {
    const { bars } = tag;
    const { pathname } = useLocation();

    return (
        <UiGrid outerSpacing={2} className={clsx('TagLocationGroupWithTable')}>
            <UiBox padding={2}>
                <UiText size={1} align="center">
                    Choose outlet to start ordering
                </UiText>
            </UiBox>

            {bars.map((bar) => (
                <LocationBrowseListCard
                    name={bar.name}
                    description={bar.description}
                    image={bar.image}
                    isClosed={bar.inactive}
                    onClick={() => {
                        storageSet('OrderGroup.backButtonLink', pathname);
                    }}
                    to={CustomerBrowseGroupsHashPage.route.replace(':hash', bar.group.hash)}
                    key={bar.id}
                />
            ))}
        </UiGrid>
    );
}
