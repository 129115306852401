import React from 'react';
import styled from 'styled-components';

const Wrapper = styled.div`
    align-items: center;
    display: flex;
    justify-content: space-between;

    > * {
        padding: ${props => props.padding}px;
    }
`;

export function ListItem({ padding = 16, children }) {
    return <Wrapper padding={padding}>{children}</Wrapper>;
}
