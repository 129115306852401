import React from 'react';
import { Route } from 'react-router-dom';
import styled from 'styled-components';
import { Checkbox } from '../components/Checkbox';
import { StaffBarSettingsTerminals } from '../components/StaffBarSettingsTerminals';
import { V2Grid } from '../components/v2/V2Grid';
import { V2List } from '../components/v2/V2List';
import { V2ListItem } from '../components/v2/V2ListItem';
import { V2PageHeader } from '../components/v2/V2PageHeader';
import { postStaffActiveBarAction } from '../microservices/device-actions';
import { useDevice } from '../shared/device';
import { routes } from '../shared/router';
import { translate } from '../shared/translate';
import { stores, useStore } from '../stores';
import { useLocationTerminals } from '../stores/location-terminals';
import { useStaffActiveBar } from '../stores/staff-active-bar';
import { useStaffBarNewOrderIndicator } from '../stores/staff-bar-new-order-indicator';
import StaffBarSettingsTerminalQueue from './StaffBarSettingsTerminalQueue';
import StaffBarSettingsTerminalSlider from './StaffBarSettingsTerminalSlider';

const Content = styled.div`
    padding: 32px 0;
`;

export default function StaffBarSettings() {
    const { isNative } = useDevice();
    const bar = useStaffActiveBar();
    const terminals = useLocationTerminals(bar.id);
    const [{ id: deviceId, imei, model }] = useStore(stores.device);
    const { isVisible, setIsVisible } = useStaffBarNewOrderIndicator();

    return (
        <div>
            <Route
                path={routes.staffBarSettings}
                render={() => (
                    <>
                        <V2PageHeader title={translate('Settings')} />

                        <Content>
                            <V2Grid>
                                {terminals.length > 0 && <StaffBarSettingsTerminals />}

                                {isNative && (
                                    <V2List title={translate('Device')}>
                                        <V2ListItem label="ID" subtitle={deviceId || '-'} />
                                        <V2ListItem label="IMEI" subtitle={imei || '-'} />
                                        <V2ListItem label="Model" subtitle={model || '-'} />
                                    </V2List>
                                )}

                                <V2List title={translate('Settings')}>
                                    <V2ListItem
                                        label={translate('New orders indicator')}
                                        contentRight={<Checkbox isChecked={isVisible} onChange={setIsVisible} />}
                                    />
                                </V2List>

                                <V2List title={translate('Account')}>
                                    <V2ListItem
                                        label={translate('Exit staff')}
                                        iconRight="exit"
                                        to={routes.customerProfile}
                                        onClick={() => {
                                            postStaffActiveBarAction('staff_exit');
                                        }}
                                    />
                                </V2List>
                            </V2Grid>
                        </Content>
                    </>
                )}
                exact
            />

            <Route path={routes.staffBarSettingsTerminalQueue} component={StaffBarSettingsTerminalQueue} />
            <Route path={routes.staffBarSettingsTerminalsSlider} component={StaffBarSettingsTerminalSlider} />
        </div>
    );
}
