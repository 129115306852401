import clsx from 'clsx';
import React from 'react';
import './PosCreateHeaderSummary.scss';
import { UiFlex, UiText, UiIconMaterial, UiButton, UiToolbar } from '@orderx/ui';
import { usePosOrder } from '../../../../../hooks/pos-order';
import { CurrencyMinor } from '../../../../../../components/CurrencyMinor';

export function PosCreateHeaderSummary() {
    const { useSummary, reset } = usePosOrder();
    const { total } = useSummary();

    return (
        <UiToolbar>
            <UiFlex className={clsx('PosCreateHeaderSummary', total > 0 && 'primary')}>
                <UiButton onClick={reset} icon={<UiIconMaterial>delete_forever</UiIconMaterial>} />

                <UiText weight={2}>
                    Total order cost: <CurrencyMinor amount={total} />
                </UiText>
            </UiFlex>
        </UiToolbar>
    );
}
