import { useSnackbar as useSnackbarNotistack } from 'notistack';

export function useSnackbar() {
    const { enqueueSnackbar, closeSnackbar } = useSnackbarNotistack();

    function showSnack(message, variant, props = {}) {
        const { preventOnClickClose, ...rest } = props;

        let id;

        const snackbarOptions = {
            variant,
            ...rest,
        };

        if (!preventOnClickClose && !rest.onClick) {
            snackbarOptions.onClick = () => closeSnackbar(id);
        }

        id = enqueueSnackbar(message, snackbarOptions);
    }

    return {
        showSnack,
        showDefault(message, props = {}) {
            showSnack(message, 'default', props);
        },
        showSuccess(message, props = {}) {
            showSnack(message, 'success', props);
        },
        showError(message, props = {}) {
            message = message.message || message;

            if (!message.includes('avbrutt') && !message.includes('cancelled')) {
                showSnack(message, 'error', props);
            }
        },
        showInfo(message, props = {}) {
            showSnack(message, 'info', props);
        },
        showWarning(message, props = {}) {
            showSnack(message, 'warning', props);
        },
    };
}
