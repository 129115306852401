import React from 'react';
import styled from 'styled-components';
import { removeButtonStyles } from '../../themes/remove-button-styles';
import { Svg } from '../Svg';
import { V2Loader } from './V2Loader';

const Wrapper = styled.button`
    ${removeButtonStyles};

    display: flex;
    align-items: center;
    justify-content: center;
`;

export function V2PageHeaderButton({ onClick, icon, isLoading }) {
    return (
        <Wrapper onClick={onClick} className="V2PageHeaderButton">
            {isLoading ? <V2Loader size={24} /> : <Svg icon={icon} />}
        </Wrapper>
    );
}
