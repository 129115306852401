import React from 'react';
import { SwitchRedirect } from '../components/SwitchRedirect';
import { routes } from '../shared/router';
import { StaffVenueReportingBottomsUp } from './StaffVenueReportingBottomsUp';
import { StaffVenueReportingDashboard } from './StaffVenueReportingDashboard';
import { StaffVenueReportingStaff } from './StaffVenueReportingStaff';
import { StaffVenueReportingTotalRevenue } from './StaffVenueReportingTotalRevenue';

export function StaffVenueReporting() {
    return (
        <SwitchRedirect
            routes={[
                {
                    path: routes.staffVenueReporting,
                    component: StaffVenueReportingDashboard,
                    exact: true,
                },
                {
                    path: routes.staffVenueReportingTotalRevenue,
                    component: StaffVenueReportingTotalRevenue,
                },
                {
                    path: routes.staffVenueReportingBottomsUp,
                    component: StaffVenueReportingBottomsUp,
                },
                {
                    path: routes.staffVenueReportingStaff,
                    component: StaffVenueReportingStaff,
                },
            ]}
        />
    );
}
