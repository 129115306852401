import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import makeStyles from '@material-ui/styles/makeStyles';
import React from 'react';
import { useStaffActiveBar } from '../../stores/staff-active-bar';
import { NfcButton } from '../NfcButton';
import { UiDialogTitle } from '../ui/dialog/UiDialogTitle';
import { UiButton } from '../../@deprecated/UiButton';
import { UiLoader } from '../ui/UiLoader';

const useStyles = makeStyles({
    container: {
        alignItems: 'flex-end',
    },
    dialogContent: {
        display: 'grid',
        gridGap: 16,
        gridTemplateColumns: 'repeat(auto-fill, minmax(60px, 1fr))',
        padding: 16,
    },
});

export function StaffOrderTerminalSelection({ open, onClose, onTerminal, isLoading = false }) {
    const classes = useStyles();
    const { bamboraTerminals: terminals } = useStaffActiveBar();

    function close() {
        onClose(false);
    }

    return (
        <>
            <Dialog open={open} onClose={close} classes={{ container: classes.container }}>
                {isLoading && <UiLoader cover />}

                <UiDialogTitle>Choose or tap terminal to print</UiDialogTitle>

                <DialogContent className={classes.dialogContent}>
                    {terminals.map(terminal => (
                        <UiButton onClick={() => onTerminal(terminal)} key={terminal.id}>
                            {terminal.name}
                        </UiButton>
                    ))}

                    {open && (
                        <NfcButton
                            onTagRead={({ id }) => {
                                const terminal = terminals.find(({ nfc }) => nfc === id);

                                if (terminal) {
                                    onTerminal(terminal);
                                }
                            }}
                        />
                    )}
                </DialogContent>

                <DialogActions>
                    <UiButton onClick={close} fullWidth>
                        Close
                    </UiButton>
                </DialogActions>
            </Dialog>
        </>
    );
}
