import { createStore } from '@orderx/store';
import { useApiQuery } from '@orderx/http';
import { MicroserviceOrders } from '../../microservices/orders';
import keyBy from 'lodash/keyBy';
import merge from 'lodash/merge';
import without from 'lodash/without';
import { storageGet, storageSet } from '../../shared/storage';
import { ProductStatus, getProductStatusLabel } from '../../models/orders/product';
import uniq from 'lodash/uniq';
import { OrderStatus } from '../../models/orders/order';

const cacheKey = {
    config: 'delivery.config',
};

const { useStore } = createStore<any>({
    bars: [],
    orders: [],
    categories: [],
    barById: {},
    isLoadingData: false,
    config: Object.assign(
        {
            orderLimit: 100,
            compact: window?.innerWidth > 700,
            categoryActive: '',
            isFiltersVisible: true,
            isCancelOrderMode: false,
            filterByStatuses: [ProductStatus.Placed, ProductStatus.InPreparation, ProductStatus.Ready],
            showTablePlacedOrders: false,
            categoryFilterVisible: true,
            orderStatusFilterVisible: true,
            commentsVisibleInListView: false,
            allowedFoodStatuses: [
                ProductStatus.Placed,
                ProductStatus.InPreparation,
                ProductStatus.Ready,
                ProductStatus.Delivered,
            ],
            allowedDrinkStatuses: [
                ProductStatus.Placed,
                ProductStatus.InPreparation,
                ProductStatus.Ready,
                ProductStatus.Delivered,
            ],
            groupFilterActive: false,
            groupFilterIds: [],
            isDynamicCategories: false,
        },
        storageGet(cacheKey.config),
    ),
});

export function useDeliveryView() {
    const [state, setState] = useStore();

    const { run } = useApiQuery(async () => {
        setState((state) => {
            state.isLoadingData = true;
        });

        try {
            const { bars, config } = state;

            const {
                categoryActive: category,
                showTablePlacedOrders: isPlacedFromTable,
                filterByStatuses: productStatuses,
                orderLimit: take,
                groupFilterActive,
                groupFilterIds,
            } = config;

            const response = await MicroserviceOrders.staffApp({
                action: 'handlerAppStaffDeliveryView',
                barIds: bars.map((bar) => bar.id),
                category,
                isPlacedFromTable,
                productStatuses,
                groupFilterIds:
                    groupFilterActive && bars.length === 1 && groupFilterIds?.length > 0 ? groupFilterIds : [],
                take,
            });

            setState((state) => ({ ...state, ...response }));
        } catch (error) {
            console.error(error);
        }

        setState((state) => {
            state.isLoadingData = false;
        });
    });

    function isStatusChangeAllowedForStatus(order, targetStatus, product?: any) {
        if (!allowedProductStatuses.includes(targetStatus)) {
            return false;
        }

        const isProductAllowed = (product) => {
            if (product.category === 'FOOD') {
                return state.config.allowedFoodStatuses.includes(targetStatus);
            }

            if (product.category === 'DRINK') {
                return state.config.allowedDrinkStatuses.includes(targetStatus);
            }

            return true;
        };

        if (product) {
            return isProductAllowed(product);
        } else {
            return order.products.filter(isProductAllowed).length === order.products.length;
        }
    }

    const allowedProductStatuses = state.allowedProductStatuses || [];

    return {
        bars: state.bars,
        orders: state.orders,
        categories: state.categories,
        barById: state.barById,
        config: state.config,
        isAnyFilterAvailable: state.config.orderStatusFilterVisible || state.config.categoryFilterVisible,
        allowedProductStatuses,
        configSetValue(field, value) {
            setState((state) => {
                state.config[field] = value;
            });
        },
        configToggleListValue(field, value) {
            setState((state) => {
                state.config[field] = state.config[field]?.includes(value)
                    ? without(state.config[field], value)
                    : [...state.config[field], value];
            });
        },
        configCache() {
            storageSet(cacheKey.config, state.config);
        },
        toggleCategoryActive(categoryActive) {
            const category = state.config.categoryActive === categoryActive ? '' : categoryActive;

            setState((state) => {
                state.config.categoryActive = category;
            });
        },
        setBars(bars) {
            setState((state) => {
                state.bars = bars;
                state.barById = keyBy(bars, (bar) => bar.id);
            });
        },
        loadData: run,
        isLoadingData: state.isLoadingData,
        clearOrders() {
            setState((state) => {
                state.orders = [];
            });
        },
        reset() {
            setState((state) => {
                state.orders = [];
                state.categories = [];
            });
        },
        getBarById(barId) {
            return state.barById[barId];
        },
        isStatusChangeAllowedForStatus,
        getNextOrderStatus(order): { label: string; value: any } | undefined {
            const { products } = order;
            const uniqProductStatuses = uniq(products.map((product) => product.status));
            const currentStatus: any = uniqProductStatuses.length === 1 && uniqProductStatuses[0];

            if (currentStatus) {
                for (const statusTarget of allowedProductStatuses.slice(
                    allowedProductStatuses.indexOf(currentStatus) + 1,
                )) {
                    if (isStatusChangeAllowedForStatus(order, statusTarget)) {
                        return {
                            label: getProductStatusLabel(statusTarget),
                            value: statusTarget,
                        };
                    }
                }
            }
        },
    };
}
