import { Column, Entity, OneToMany, BeforeInsert, Index, OneToOne, ManyToOne } from './typeorm';
import { LocationRole } from './location-role';
import { BaseModel } from './base-model';
import { InventoryPlugin } from './inventory-plugin';
import { Company } from './company';
import { Outlet } from './outlet';
import { OutletPlugin } from './outlet-plugin';

@Entity()
export class Location extends BaseModel {
    @Column()
    name: string;

    @Column({ type: 'text', nullable: true })
    description: string;

    @Column({ nullable: true })
    currency: string;

    @Column({ type: 'text', nullable: true })
    imageLogo: string;

    @Column({ type: 'text', nullable: true })
    imageBackground: string;

    @Column({ default: false })
    isClosed: boolean;

    @Column({ default: false })
    isHidden: boolean;

    @Column({ default: 0 })
    position: number;

    @Column()
    @Index({ unique: true })
    hash: string;

    @OneToMany(() => LocationRole, (model) => model.location)
    userRoles: LocationRole[];

    @OneToMany(() => Outlet, (model) => model.location)
    outlets: Outlet[];

    @ManyToOne(() => Company, (model) => model.locations)
    company: Company;
    @Column({ nullable: true })
    companyId: number;

    @OneToOne(() => InventoryPlugin, (model) => model.location)
    inventoryPlugin: InventoryPlugin;

    @OneToOne(() => OutletPlugin, (model) => model.location)
    outletPlugin: OutletPlugin;
}
