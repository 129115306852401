import moment from 'moment';
import { isDev } from '../environment';

export const Log = (function() {
    const timestamp = function() {};

    timestamp.toString = function() {
        return `${moment().format('DD/MM HH:mm:ss')} •`;
    };

    return {
        dev: isDev ? console.log.bind(console, '%s', timestamp) : () => {},
    };
})();
