import makeStyles from '@material-ui/core/styles/makeStyles';
import { SnackbarProvider } from 'notistack';
import React from 'react';
import { themeActive } from '../../themes/dark';

const useStyles = makeStyles({
    success: {
        backgroundColor: themeActive.colors.success,
        color: themeActive.colors.text,
    },
    error: {
        backgroundColor: themeActive.colors.error,
        color: themeActive.colors.text,
    },
    info: {
        backgroundColor: themeActive.colors.primary,
        color: themeActive.colors.text,
    },
    warning: {
        backgroundColor: themeActive.colors.warning,
        color: themeActive.colors.text,
    },
});

export function SystemSnackbarProvider({ children }) {
    const classes = useStyles();

    return (
        <SnackbarProvider
            maxSnack={1}
            anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
            autoHideDuration={2500}
            classes={{
                variantSuccess: classes.success,
                variantError: classes.error,
                variantInfo: classes.info,
                variantWarning: classes.warning,
            }}>
            {children}
        </SnackbarProvider>
    );
}
