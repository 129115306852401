import React from 'react';
import { PosGroupProducts } from '../products/PosGroupProducts';
import { PosGroupFooter } from '../footer/PosGroupFooter';
import { StaffTerminalsSlider } from '../../../StaffTerminalsSlider';
import { NfcButton } from '../../../NfcButton';
import { useApiQuery } from '@orderx/http';
import { MicroserviceLegacyApp } from '../../../../microservices/legacy-app';
import { useSnackbar } from '../../../../shared/snackbar';
import { useDeviceConfig } from '../../../../stores/device-config';
import { useStaffTerminalOrders } from '../../../../stores/staff-terminal-orders';
import { useStaffTerminalSlider } from '../../../../stores/staff-terminal-slider';
import { PosPageLoader } from '../../page/loader/PosPageLoader';
import { usePosGroup } from '../../../../hooks/pos/group';
import { PosGroupFooterButtonProducts } from '../footer/button-products/PosGroupFooterButtonProducts';
import { UiToolbar } from '@orderx/ui';

export function PosGroupPaymentTerminal() {
    const { groupId, barId, loadGroup, productsChecked, resetProductsChecked } = usePosGroup();
    const { showSuccess } = useSnackbar();
    const { config } = useDeviceConfig();
    const { putOrder } = useStaffTerminalOrders();
    const { setTerminalOrder } = useStaffTerminalSlider();

    const { run: createTerminalPaymentOrder, isLoading: isCreatingOrder } = useApiQuery(
        async ({ value, type = 'id', productsToPay, payForExistingProducts = true, comment }) => {
            const { response } = await MicroserviceLegacyApp.postOrder({
                barId,
                tag: {
                    type,
                    value,
                },
                groupId,
                payForExistingProducts,
                productsToPay,
                gratitude: config.isGratitudeOn,
                comment,
            });

            showSuccess('Order sent to terminal');
            putOrder(response.order);
            setTerminalOrder(response.terminal.id, response.order);
            await loadGroup();
            resetProductsChecked();
        },
    );

    return (
        <>
            {isCreatingOrder && <PosPageLoader message={'Placing order'} />}

            <PosGroupProducts isSelectable />

            <PosGroupFooter style={{ bottom: 50, padding: 0 }}>
                <StaffTerminalsSlider
                    barId={barId}
                    onClickOrder={() => {}}
                    onClickTerminal={(terminal) => {
                        if (productsChecked.length > 0) {
                            createTerminalPaymentOrder({ value: terminal.id, productsToPay: productsChecked });
                        }
                    }}
                    onUpdate={loadGroup}
                />
            </PosGroupFooter>

            {productsChecked.length > 0 && (
                <>
                    <NfcButton
                        onTagRead={({ id }) =>
                            createTerminalPaymentOrder({ value: id, productsToPay: productsChecked })
                        }
                    />
                </>
            )}

            <UiToolbar position="bottom">
                <div />
                <PosGroupFooterButtonProducts />
                <div />
            </UiToolbar>
        </>
    );
}
