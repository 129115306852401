import { useState } from 'react';

export function useProductCategoryFilter() {
    const [category, setCategory] = useState('');
    const [subCategory, setSubCategory] = useState('');

    return {
        Category: {
            All: '',
            Pending: 'pending',
        },
        category,
        setCategory(category) {
            setCategory(category);
        },
        subCategory,
        setSubCategory,
        resetCategories() {
            setCategory('');
            setSubCategory('');
        },
    };
}
