import { getApiUrl } from '../config';
import { httpPost, httpPut } from '../shared/api';
import { Payment } from '../shared/payment';

const serviceName = 'orders';
const getUrl = getApiUrl(serviceName);

export const MicroserviceOrders = {
    async placeOrder(event) {
        return httpPost(getUrl('app/order'), event);
    },

    async payWithProvider({ provider, productIds, hash, orderId, ...rest }) {
        const response = await httpPost(getUrl('app/pay'), { provider, hash, productIds, ...rest });
        await Payment.handlePaymentSuccess({ provider, orderId, ...response });
    },

    async staffFindPlacedOrders({ barId }) {
        return httpPost(getUrl('app/staff'), { barId }, { action: 'FIND_PLACED_ORDERS' });
    },

    async staffFindCreateViewData({ barId }) {
        return httpPost(getUrl('app/staff'), { barId }, { action: 'CREATE_VIEW_DATA' });
    },

    /* @DEPRECATED */
    async staffPlaceOrder(order) {
        return httpPost(getUrl('app/staff'), order, { action: 'PLACE_ORDER' });
    },

    postAppCustomer(body) {
        return httpPost(getUrl('app/customer'), body);
    },

    async findReceipt({ paymentId }) {
        return httpPost(getUrl('app/customer'), { paymentId }, { action: 'FIND_RECEIPT' });
    },

    staffApp(data) {
        return httpPost(getUrl('app/staff'), data);
    },
};

export async function getGroupById({ groupHash, orderIds, deviceFingerprint }) {
    return httpPost(getUrl(`v2/groups/${groupHash}`), { orderIds: orderIds ?? [], deviceFingerprint });
}

export async function getOrders(body) {
    return httpPost(getUrl(`v2/orders`), body);
}

export async function getStaffBar(barId) {
    return MicroserviceOrders.staffApp({
        action: 'FIND_BAR',
        barId,
    });
}

async function postStaffBarOrderAction(barId, orderId, action, options = {}) {
    return httpPut(getUrl(`v2/staff/bars/${barId}/orders/${orderId}`), { action, ...options });
}

export async function claimOrder(barId, orderId) {
    return postStaffBarOrderAction(barId, orderId, 'CLAIM');
}

export async function disclaimOrder(barId, orderId) {
    return postStaffBarOrderAction(barId, orderId, 'DISCLAIM');
}

export async function putOrderProductsAction(barId, orderId, options) {
    return httpPut(getUrl(`v2/staff/bars/${barId}/orders/${orderId}`), options);
}
