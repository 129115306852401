import CircularProgress from '@material-ui/core/CircularProgress';
import LinearProgress from '@material-ui/core/LinearProgress';
import React from 'react';
import './UiLoader.scss';
import { UiFlex } from '@orderx/ui';

export function UiLoader({ indeterminate = false, fixedTop = false, fixedBottom = false, fullWidth }) {
    if (indeterminate) {
        const style = {};

        if (fixedTop || fixedBottom) {
            style.position = 'absolute';
            style.left = 0;
            style.width = '100%';
            style.zIndex = 1;
            style.height = 2;
        }

        if (fixedTop) {
            style.top = 0;
        }

        if (fixedBottom) {
            style.bottom = 0;
        }

        return <LinearProgress variant="indeterminate" style={style} />;
    }

    if (fullWidth) {
        return (
            <UiFlex fullWidth justifyContent="center">
                <CircularProgress className="UiLoader" />
            </UiFlex>
        );
    }

    return <CircularProgress className="UiLoader" />;
}
