import React, { useEffect } from 'react';
import { loadStaffBarById, loadStaffVenues } from '../../microservices/legacy-app';
import { stores } from '../../stores';
import { setLocationTerminals } from '../../stores/location-terminals';
import { useStaffActiveBar } from '../../stores/staff-active-bar';
import { UiLoader } from '../ui/UiLoader';

export function DataResolverStaffBar({ children, barId }) {
    const bar = useStaffActiveBar();

    useEffect(() => {
        loadStaffVenues();
    }, []);

    useEffect(() => {
        if (barId) {
            loadStaffBarById(barId);
        }
    }, [barId]);

    useEffect(() => {
        if (!bar) {
            return;
        }

        stores.staff.set(state => {
            state.bar = bar;
            state.barId = barId;

            state.venue = bar.venue;
            state.venueId = bar.venue.id;

            state.location = bar.venue.location;
            state.locationId = bar.venue.location.id;
        });

        setLocationTerminals(barId, bar.bamboraTerminals);
    }, [bar]);

    return bar && bar.id === barId ? children : <UiLoader />;
}
