import React from 'react';
import { useIotTerminalOrderPlaced } from '../hooks/iot/terminal-order-placed';
import { useAwsIotSubscription } from '../providers/aws-iot';
import { toMoment } from '../shared/moment';
import { useTerminalsWithOrderByLocationId } from '../stores/location-terminal-orders';
import { useStaffActiveBar } from '../stores/staff-active-bar';
import { useStaffTerminalOrders } from '../stores/staff-terminal-orders';
import { useStaffTerminalSlider } from '../stores/staff-terminal-slider';

export function StaffBarTerminalSubscriptions() {
    const { id: barId } = useStaffActiveBar();
    const terminalsWithOrder = useTerminalsWithOrderByLocationId(barId);

    return terminalsWithOrder.map((terminal) => (
        <TerminalSubscription key={terminal.id} barId={barId} terminalId={terminal.id} />
    ));
}

function TerminalSubscription({ barId, terminalId }) {
    const { putOrder } = useStaffTerminalOrders();
    const { setTerminalOrder } = useStaffTerminalSlider();

    useIotTerminalOrderPlaced(terminalId, (order) => {
        putOrder(order);
        setTerminalOrder(terminalId, order);
    });

    useAwsIotSubscription(`pos/status/${terminalId}`, (order) => {
        // const isFailedOrder = [ORDER_STATUS.PAYMENT_CANCELLED].includes(order.Status);

        // NB! Do not proxy failed order events.
        //
        // This is due to autopay - placing new order cancels the current order but this means that new order
        // is created before cancelling the existing one.
        //
        // This will result in receiving "failed" event right after "create" event and the
        // light in terminal slider will always be red.

        order.Datetime = order.Datetime || Date.now();
        order.DatetimeStr = order.DatetimeStr || toMoment().format();
        order.LocationId = order.LocationId || barId;
        putOrder(order);
        setTerminalOrder(terminalId, order);
    });

    return null;
}
