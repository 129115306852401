import orderBy from 'lodash/orderBy';
import uniq from 'lodash/uniq';
import React, { useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';
import { Button } from '../components/Button';
import { CssLoader } from '../components/CssLoader';
import { Currency } from '../components/Currency';
import { NfcButton } from '../components/NfcButton';
import Pill from '../components/Pill';
import Pills from '../components/Pills';
import PrintReceiptButton from '../components/PrintReceiptButton';
import { ProductListItem } from '../components/product/list-item/ProductListItem';
import { StaffProductGrid } from '../components/StaffProductGrid';
import { StaffTerminalsSliderBottom } from '../components/StaffTerminalsSliderBottom';
import { Svg } from '../components/Svg';
import { V2PageHeader } from '../components/v2/V2PageHeader';
import { postStaffActiveBarAction } from '../microservices/device-actions';
import { MicroserviceLegacyApp } from '../microservices/legacy-app';
import { routes, withParams } from '../shared/router';
import { showError, showInfo, showSuccess } from '../shared/toasts';
import { translate } from '../shared/translate';
import { useLocationProductPopularityByProductId } from '../stores/location-product-popularity';
import { loadLocationProducts, useLocationProducts } from '../stores/location-products';
import { useStaffActiveBar } from '../stores/staff-active-bar';
import { useStaffBarCreateOrder } from '../stores/staff-bar-create-order';
import { useStaffTerminalOrders } from '../stores/staff-terminal-orders';
import { useStaffTerminalSlider } from '../stores/staff-terminal-slider';
import { theme } from '../themes/dark';

const ProductsWrapper = styled.div`
    padding: 0 16px;
`;

const LoaderOverlay = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
    background-color: rgba(0, 0, 0, 0.75);
    display: flex;
    align-items: center;
    justify-content: center;
`;

const Category = {
    All: 'all',
    Popular: 'popular',
};

export function StaffBarOrdersCreate({ history, onClickBack }) {
    const bar = useStaffActiveBar();
    const barId = bar.id;

    const {
        counts,
        increment,
        decrement,
        reset,
        getProductsWithCounts,
        getTotalPaymentAmount,
    } = useStaffBarCreateOrder(barId);

    const products = useLocationProducts(barId);
    const [isActiveOrderVisible, setIsActiveOrderVisible] = useState(false);
    const [categorySelected, setCategorySelected] = useState(Category.All);
    const [isPlacingOrder, setIsPlacingOrder] = useState(false);
    const productPopularityByProductId = useLocationProductPopularityByProductId(barId);
    const { setTerminalOrder } = useStaffTerminalSlider();
    const { putOrder } = useStaffTerminalOrders();

    const { currency } = bar;
    const categories = useMemo(() => getProductsCategories(products), [products]);

    const selectedProductsWithCounts = getProductsWithCounts(products);
    const totalPaymentAmount = getTotalPaymentAmount(selectedProductsWithCounts);
    const canPlaceOrder = selectedProductsWithCounts.filter((product) => product.count > 0).length > 0;

    const productsVisible = useMemo(() => {
        if (isActiveOrderVisible) {
            return products.filter((product) => counts[product.id] > 0);
        } else if (categorySelected === Category.All) {
            return products;
        } else if (categorySelected === Category.Popular) {
            return getProductsOrderedByPopularity(products, productPopularityByProductId);
        } else {
            return products.filter((product) => product.subCategory === categorySelected);
        }
    }, [isActiveOrderVisible, products, categorySelected]);

    useEffect(() => {
        if (barId) {
            loadLocationProducts(barId);
        }
    }, [barId]);

    function resetOrder() {
        setIsActiveOrderVisible(false);
        reset();
    }

    function changeCategory(category) {
        if (categorySelected === category) {
            setCategorySelected(Category.All);
        } else {
            setCategorySelected(category);
            postStaffActiveBarAction('staff_create_order_change_category', { category });
        }
    }

    async function placeOrder(tag, type) {
        setIsPlacingOrder(true);

        try {
            const postOrderParams = {
                barId,
                tag: {
                    type: type,
                    value: tag,
                },
                products: selectedProductsWithCounts,
            };

            postStaffActiveBarAction('staff_create_order_before', postOrderParams);
            const { response, PostOrderResponseType } = await MicroserviceLegacyApp.postOrder(postOrderParams);

            if (response.type === PostOrderResponseType.BalanceOrder) {
                const { userBalance } = response;

                showSuccess(`Paid with balance. Amount left: ${userBalance.count}`);
            } else if (response.type === PostOrderResponseType.TerminalOrder) {
                const { terminal } = response;

                showSuccess(`Order sent to terminal ${terminal.name}`);

                putOrder(response.order);
                setTerminalOrder(response.terminal.id, response.order);

                // sendOrderToTerminal({ barId, order, terminal });
            } else if (response.type === PostOrderResponseType.ChangeBar) {
                const correctBarRoute = withParams(routes.staffBarOrder, { barId: response.bar.id });

                history.push(correctBarRoute);

                return showInfo(
                    `Tag you scanned was found in another bar; You have been switched to ${response.bar.name}. Please retry the order`,
                );
            } else {
                showError('We have encountered an unknown action');
            }

            resetOrder();
        } catch (error) {
            showError(error, 'Failed to place order');
            postStaffActiveBarAction('staff_create_order_error', error);
        }

        setIsPlacingOrder(false);
    }

    return (
        <>
            {isPlacingOrder && (
                <LoaderOverlay className="animated fadeIn">
                    <div>
                        <CssLoader />
                        {translate('Placing order... Please wait')}
                    </div>
                </LoaderOverlay>
            )}

            <V2PageHeader
                background={canPlaceOrder && theme.gradients.primary}
                title={
                    <span
                        onClick={() => setIsActiveOrderVisible(!isActiveOrderVisible)}
                        style={{ color: isActiveOrderVisible ? theme.colors.green : theme.colors.text }}>
                        Total cost:{' '}
                        <b>
                            <Currency amount={totalPaymentAmount} currency={currency} />
                        </b>
                    </span>
                }
                contentRight={
                    <>
                        {canPlaceOrder && (
                            <Button onClick={resetOrder} clear>
                                <Svg icon="trash" size={16} />
                            </Button>
                        )}

                        <PrintReceiptButton />
                    </>
                }
                onClickBack={() =>
                    onClickBack ? onClickBack() : history.push(withParams(routes.staffBarOrder, { barId }))
                }
            />

            <Pills>
                <Pill onClick={() => changeCategory(Category.All)} active={categorySelected === Category.All}>
                    {translate('All')}
                </Pill>

                <Pill onClick={() => changeCategory(Category.Popular)} active={categorySelected === Category.Popular}>
                    {translate('Popular')}
                </Pill>

                {categories.map((category) => (
                    <Pill
                        key={category}
                        onClick={() => changeCategory(category)}
                        active={categorySelected === category}>
                        {category}
                    </Pill>
                ))}
            </Pills>

            <ProductsWrapper>
                <StaffProductGrid isSingleRow={isActiveOrderVisible}>
                    {productsVisible.map((product) => (
                        <ProductListItem
                            key={product.id}
                            name={product.name}
                            image={product.icon}
                            price={product.priceIncludingVat}
                            count={counts[product.id] || 0}
                            disabled={isPlacingOrder}
                            currency={product.currency}
                            onDecrement={(amount) => decrement(product.id, amount)}
                            onIncrement={(amount) => increment(product.id, amount)}
                        />
                    ))}
                </StaffProductGrid>
            </ProductsWrapper>

            {canPlaceOrder && <NfcButton onTagRead={({ id }) => placeOrder(id, 'nfc')} />}

            <StaffTerminalsSliderBottom
                onClickTerminal={(terminal) => {
                    if (canPlaceOrder) {
                        placeOrder(terminal.id, 'id');
                    }
                }}
            />
        </>
    );
}

function getProductsOrderedByPopularity(products, productPopularityByProductId) {
    return orderBy(products, (product) => productPopularityByProductId[product.id] || 0, 'desc');
}

function getProductsCategories(products) {
    return uniq(products.map(({ subCategory }) => subCategory).filter((value) => value));
}
