import React, { useState } from 'react';
import styled from 'styled-components';
import { ActionSheet, useActionSheet } from '../components/ActionSheet';
import { BalanceGroupBalances } from '../components/BalanceGroupBalances';
import { BalanceGroupDetailOverlay } from '../components/BalanceGroupDetailOverlay';
import { Button } from '../components/Button';
import { ButtonGroup } from '../components/ButtonGroup';
import { CssLoader } from '../components/CssLoader';
import { Flex } from '../components/Flex';
import { Input } from '../components/Input';
import { ListItem } from '../components/ListItem';
import { NfcButton } from '../components/NfcButton';
import { Page } from '../components/Page';
import { PageTitle } from '../components/PageTitle';
import { Svg } from '../components/Svg';
import { stores, useStore } from '../stores';
import {
    addBalanceToVenueBalanceGroup,
    getLimitExceededAlert,
    getUnitsSpentLabel,
    loadVenueBalanceGroups,
    resetVenueBalanceGroup,
    updateBalanceGroup,
} from '../microservices/balance-groups';
import { mixins } from '../shared/styles';
import { showError, showSuccess } from '../shared/toasts';
import { useStaffActiveBar } from '../stores/staff-active-bar';

const Wrapper = styled.div`
    .title {
        text-align: center;
        margin-bottom: 32px;
    }
`;

const OverlayContent = styled.div`
    .overlay-title {
        padding: 16px;
        text-align: center;
        margin-bottom: 16px;
    }

    .new-limit {
        background: transparent;
        margin: 0;

        input {
            border: 0;
            text-align: center;
            color: ${props => props.theme.colors.text};
            font-size: 2rem;
        }
    }

    .add-units-wrapper {
        margin-bottom: 32px;

        > * {
            margin-bottom: 0;
        }
    }
`;

const IconWrapper = styled.div`
    ${mixins.flexXY};
    padding: 32px;
`;

const Content = styled.div`
    padding: 0 16px;

    > * {
        margin-bottom: 32px;
    }
`;

export default function StaffBarBalanceGroupsDetail({ match, history }) {
    const [isLoading, setIsLoading] = useState(false);
    const [newLimit, setNewLimit] = useState(1);
    const actionSheetAddUnits = useActionSheet();
    const actionSheetReset = useActionSheet();
    const actionSheetDetails = useActionSheet();

    const { venueId } = useStaffActiveBar();
    const [{ balanceGroupById }] = useStore(stores.staff);

    const { balanceGroupId } = match.params;
    const balanceGroup = balanceGroupById[balanceGroupId];

    if (!balanceGroup) {
        return <CssLoader />;
    }

    const { balances = [] } = balanceGroup;

    function goBack() {
        history.goBack();
    }

    async function resetBalanceGroup() {
        setIsLoading(true);

        try {
            await resetVenueBalanceGroup({ venueId, balanceGroupId });
            loadVenueBalanceGroups(venueId);

            showSuccess('Reset successful');
            actionSheetReset.close();
        } catch (error) {
            showError(error, 'Failed to reset');
        }

        setIsLoading(false);
    }

    async function onTagRead(id) {
        setIsLoading(true);

        try {
            await addBalanceToVenueBalanceGroup({ venueId, balanceGroupId, balanceId: id });
            loadVenueBalanceGroups(venueId);
            showSuccess('Added to group');
        } catch (error) {
            showError(error, 'Failed to add to group');
        }

        setIsLoading(false);
    }

    async function addUnits() {
        setIsLoading(true);

        try {
            const limit = balanceGroup.limit + newLimit;

            await updateBalanceGroup({ venueId, balanceGroupId }, { ...balanceGroup, limit });
            loadVenueBalanceGroups(venueId);

            showSuccess(`Units increased by ${newLimit} to total of ${limit}`);

            actionSheetAddUnits.close();
            setNewLimit(1);
        } catch (error) {
            showError(error, 'Failed to add units');
        }

        setIsLoading(false);
    }

    return (
        <Page footer>
            <Wrapper>
                <PageTitle
                    onClickBack={goBack}
                    title={balanceGroup && balanceGroup.name}
                    contentRight={
                        <Button clear onClick={() => actionSheetDetails.toggle()}>
                            <Svg icon="more" />
                        </Button>
                    }
                />

                <IconWrapper>{isLoading ? <CssLoader size={160} /> : <Svg icon="nfc-scan" size={160} />}</IconWrapper>

                <Content>
                    {getLimitExceededAlert(balanceGroup)}

                    <h3 className="title">Tap NFC wristband or card to add</h3>

                    <ListItem>
                        <span>Total units spent</span>
                        <span>{getUnitsSpentLabel(balanceGroup)}</span>
                    </ListItem>

                    <ButtonGroup vertical>
                        {!isLoading && <NfcButton onTagRead={({ id }) => onTagRead(id)} />}

                        <ButtonGroup>
                            <Button onClick={actionSheetAddUnits.open} block>
                                Add units
                            </Button>

                            <Button onClick={actionSheetReset.open} block negative>
                                Reset
                            </Button>
                        </ButtonGroup>
                    </ButtonGroup>

                    {balances.length > 0 && <BalanceGroupBalances balances={balances} />}
                </Content>

                {actionSheetAddUnits.isOpen && (
                    <ActionSheet {...actionSheetAddUnits}>
                        <OverlayContent>
                            <h4 className="overlay-title">
                                Increase units from {balanceGroup.limit} to {balanceGroup.limit + newLimit}
                            </h4>

                            <Flex center className="add-units-wrapper">
                                <Button disabled={newLimit <= 1} onClick={() => setNewLimit(newLimit - 1)}>
                                    <Svg icon="minus" />
                                </Button>

                                <Input
                                    className="new-limit"
                                    value={newLimit}
                                    onChangeValue={value => setNewLimit(Number(value))}
                                />

                                <Button onClick={() => setNewLimit(newLimit + 1)}>
                                    <Svg icon="add" />
                                </Button>
                            </Flex>

                            <Flex>
                                <Button onClick={addUnits} disabled={newLimit < 1} positive isLoading={isLoading}>
                                    Add units
                                </Button>
                            </Flex>
                        </OverlayContent>
                    </ActionSheet>
                )}

                {actionSheetReset.isOpen && (
                    <ActionSheet {...actionSheetReset}>
                        <OverlayContent>
                            <h4 className="overlay-title">Are you sure you want to reset this group?</h4>

                            <Flex>
                                <Button onClick={resetBalanceGroup} negative isLoading={isLoading}>
                                    Reset
                                </Button>
                            </Flex>
                        </OverlayContent>
                    </ActionSheet>
                )}

                {actionSheetDetails.isOpen && (
                    <BalanceGroupDetailOverlay
                        balanceGroup={balanceGroup}
                        onClosed={({ deleted }) => {
                            actionSheetDetails.setClosed();

                            if (deleted) {
                                goBack();
                                loadVenueBalanceGroups(venueId);
                            }
                        }}
                    />
                )}
            </Wrapper>
        </Page>
    );
}
