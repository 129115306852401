import React, { useEffect, useState } from 'react';
import { ProductCategoryTabs } from '../../components/product/category-tabs/ProductCategoryTabs';
import { V2PaymentButton } from '../../components/v2/V2PaymentButton';
import { useCustomerActiveOrder } from '../../use/customer-active-order';
import { useProductCategoryFilter } from '../../use/product-category-filter';
import { getProductsByCategory } from '../../util/get-products-by-category';
import { UiGrid, UiBox, UiInput, UiText } from '@orderx/ui';
import { ProductCard } from '../../@v2/components/product/card/ProductCard';
import { OrderSuccessOverlay } from '../../@v2/components/order/success-overlay/OrderSuccessOverlay';
import { OrderIdPage } from '../../@v2/pages/order/id/OrderIdPage';
import { useRouter } from '../../@v2/hooks/router';

export function OrderGroupMenu({ group, hash, products, isAcceptingOrders, config, disabled }) {
    const { replaceParams } = useRouter();
    const [orderIdSuccess, setOrderIdSuccess] = useState('');

    const {
        totalProductsAdded,
        getTotalAmount,
        productsWithCounts,
        getProductCountById,
        incrementProductCount,
        decrementProductCount,
        resetProductCount,
        productDataById,
        updateProductById,
    } = useCustomerActiveOrder({ hash });

    const {
        category,
        setCategory,
        subCategory,
        setSubCategory,
        resetCategories,
        Category,
    } = useProductCategoryFilter();

    const productsByActiveCategory =
        category === Category.Pending ? productsWithCounts : getProductsByCategory({ products, category, subCategory });

    useEffect(() => {
        if (totalProductsAdded === 0 && category === Category.Pending) {
            resetCategories();
        }
    }, [totalProductsAdded]);

    function resetOrder() {
        resetProductCount();
        resetCategories();
    }

    function handleOrderSuccess(orderId) {
        resetOrder();
        setOrderIdSuccess(orderId);
    }

    if (orderIdSuccess) {
        return <OrderSuccessOverlay redirectTo={replaceParams(OrderIdPage.route, { orderId: orderIdSuccess })} />;
    }

    return (
        <>
            <UiGrid>
                <ProductCategoryTabs
                    category={category}
                    onChangeCategory={setCategory}
                    subCategory={subCategory}
                    onChangeSubcategory={setSubCategory}
                    products={products}
                    productsCount={totalProductsAdded}
                />

                <UiGrid minMax={240} spacing={1}>
                    {productsByActiveCategory.map((product) => (
                        <ProductCard
                            disabled={disabled}
                            key={product.id}
                            name={product.name}
                            description={product.description}
                            image={product.icon}
                            price={product.priceIncludingVat}
                            count={getProductCountById(product.id)}
                            currency={product.currency}
                            hasOptions={isAcceptingOrders}
                            onDecrement={(amount) => decrementProductCount(product.id, amount)}
                            onIncrement={(amount) => incrementProductCount(product.id, amount)}>
                            {product.modifierGroups?.length > 0 && getProductCountById(product.id) > 0 && (
                                <UiBox>
                                    <UiGrid outerSpacing={1} spacing={1}>
                                        <UiText color="text-secondary" weight={2}>
                                            Choose extras
                                        </UiText>

                                        {product.modifierGroups.map((modifierGroup) => (
                                            <UiInput
                                                placeholder={modifierGroup.name}
                                                value={
                                                    productDataById[product.id]?.modifierGroupValues?.[
                                                        modifierGroup.name
                                                    ] || ''
                                                }
                                                options={modifierGroup.modifiers.map((mod) => ({
                                                    label: mod.name,
                                                    value: mod.name,
                                                }))}
                                                onChangeValue={(value) => {
                                                    updateProductById(product.id, {
                                                        modifierGroupValues: {
                                                            ...productDataById[product.id]?.modifierGroupValues,
                                                            [modifierGroup.name]: value,
                                                        },
                                                    });
                                                }}
                                                key={modifierGroup.id}
                                            />
                                        ))}
                                    </UiGrid>
                                </UiBox>
                            )}
                        </ProductCard>
                    ))}
                </UiGrid>
            </UiGrid>

            {productsWithCounts.length > 0 && !disabled && (
                <V2PaymentButton
                    group={group}
                    config={config}
                    locationId={hash}
                    amount={getTotalAmount()}
                    productsWithCounts={productsWithCounts}
                    onSuccess={handleOrderSuccess}
                    onReset={resetOrder}
                />
            )}
        </>
    );
}
