import DialogActions from '@material-ui/core/DialogActions';
import Dialog from '@material-ui/core/Dialog';
import LinearProgress from '@material-ui/core/LinearProgress';
import makeStyles from '@material-ui/core/styles/makeStyles';
import IconComment from '@material-ui/icons/CommentRounded';
import clsx from 'clsx';
import uniq from 'lodash/uniq';
import React, { useMemo, useState } from 'react';
import { ProductStatus } from '../../../../../models/orders/product';
import { themeActive } from '../../../../../themes/dark';
import { UiDialogContent } from '../../../../ui/dialog/UiDialogContent';
import { UiDialogTitle } from '../../../../ui/dialog/UiDialogTitle';
import { UiButton } from '../../../../../@deprecated/UiButton';
import { UiButtonGroup } from '../../../../ui/UiButtonGroup';
import { UiFlex } from '@orderx/ui';
import { V2Grid } from '../../../../v2/V2Grid';
import { StaffOrderStationListItemProductChipDialog } from './dialog/StaffOrderStationListItemProductChipDialog';

const useStyles = makeStyles({
    StaffOrderStationListItemProductChip: {
        position: 'relative',
    },
    buttonGroup: {
        marginRight: 4,
    },
    chipProduct: {
        backgroundColor: themeActive.colors.paper,
        border: 0,
        borderRadius: 4,
        display: 'inline-flex',
        alignItems: 'center',
        fontSize: 12,
        position: 'relative',
        overflow: 'hidden',
        height: 'initial',
        minWidth: 'initial',
        padding: 4,
    },
    buttonComments: {
        backgroundColor: themeActive.colors.warning,
    },
    chipFontSize: {
        fontSize: 12,
    },
    chipStatus: {
        position: 'absolute',
        bottom: 0,
        left: 0,
        height: 4,
        width: '100%',
        '& > *': {
            height: '100%',
        },
    },
    chipLabel: {
        position: 'relative',
        lineHeight: '12px',
    },
    [`CHIP_STATUS_${ProductStatus.Placed}`]: {
        backgroundColor: themeActive.colors.yellow,
    },
    [`CHIP_STATUS_${ProductStatus.Ready}`]: {
        backgroundColor: themeActive.colors.success,
    },
    [`CHIP_STATUS_${ProductStatus.Delivered}`]: {
        backgroundColor: themeActive.colors.success,
    },
    inPreparation: {
        backgroundColor: themeActive.colors.paper,
    },
    inPreparationBar: {
        backgroundColor: '#444',
    },
});

export function StaffOrderStationListItemProductChip({ groupedProduct }) {
    const { name, count, products } = groupedProduct;
    const classes = useStyles();
    const [isDetailsVisible, setIsDetailsVisible] = useState(false);
    const [isCommentsVisible, setIsCommentsVisible] = useState(false);

    const comments = useMemo(() => products.filter((product) => product.comment), [products]);
    const uniqStatuses = useMemo(() => uniq(products.map((product) => product.status)), [products]);
    const isAllProductsDelivered = uniqStatuses.length === 1 && uniqStatuses[0] === ProductStatus.Delivered;

    function showDetails() {
        setIsDetailsVisible(true);
    }

    function closeDetails() {
        setIsDetailsVisible(false);
    }

    function showComments() {
        if (comments.length > 0) {
            setIsCommentsVisible(true);
        }
    }

    function closeComments() {
        setIsCommentsVisible(false);
    }

    return (
        <span className={classes.StaffOrderStationListItemProductChip}>
            <UiButtonGroup className={classes.buttonGroup} fullWidth={false}>
                <UiButton
                    onClick={showDetails}
                    className={clsx(
                        classes.chipProduct,
                        classes.chipFontSize,
                        isAllProductsDelivered && classes.CHIP_STATUS_DELIVERED,
                    )}>
                    <UiFlex flexChildren className={classes.chipStatus}>
                        {uniqStatuses.map((status) =>
                            status === ProductStatus.InPreparation ? (
                                <LinearProgress
                                    key={status}
                                    className={classes.inPreparation}
                                    classes={{
                                        bar1Indeterminate: classes.inPreparationBar,
                                        bar2Indeterminate: classes.inPreparationBar,
                                    }}
                                />
                            ) : (
                                <div key={status} className={classes[`CHIP_STATUS_${status}`]} />
                            ),
                        )}
                    </UiFlex>

                    <div className={classes.chipLabel}>
                        {count}x{name}
                    </div>
                </UiButton>

                {comments.length > 0 && (
                    <UiButton
                        onClick={showComments}
                        className={clsx(classes.chipProduct, classes.buttonComments)}
                        variant={'contained'}
                        size={'small'}>
                        <IconComment fontSize={'small'} />
                    </UiButton>
                )}
            </UiButtonGroup>

            {isDetailsVisible && (
                <StaffOrderStationListItemProductChipDialog products={products} onClose={closeDetails} />
            )}

            {isCommentsVisible > 0 && (
                <Dialog open onClose={closeComments}>
                    <UiDialogTitle>Comments</UiDialogTitle>

                    <UiDialogContent>
                        <V2Grid gutter={16} container>
                            {comments.map((product) => (
                                <div key={product.id}>
                                    <h4>{product.name}</h4>
                                    <div>{product.comment}</div>
                                </div>
                            ))}
                        </V2Grid>
                    </UiDialogContent>

                    <DialogActions>
                        <UiButton onClick={closeComments}>Close</UiButton>
                    </DialogActions>
                </Dialog>
            )}
        </span>
    );
}
