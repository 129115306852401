import React from 'react';
import orderBy from 'lodash/orderBy';
import { UiTable } from '../../../ui/table/UiTable';
import { CurrencyMinor } from '../../../CurrencyMinor';
import { ProductPriceLabel } from '../../../product/ProductPriceLabel';
import { capitalCase } from '../../../../util/capital-case';
import { usePosGroup } from '../../../../hooks/pos/group';
import groupBy from 'lodash/groupBy';
import { UiTableCellCheckbox } from '../../../ui/table/cell-checkbox/UiTableCellCheckbox';
import { UiText } from '@orderx/ui';

export function PosGroupProducts({ isSelectable, isGrouped }) {
    const {
        products,
        isAllProductsChecked,
        isProductChecked,
        toggleProductChecked,
        toggleAllProductsChecked,
        totalAmount,
    } = usePosGroup();

    function getProducts() {
        const productsOrdered = orderBy(products, (product) => product.name);

        if (isGrouped) {
            const productsGrouped = groupBy(productsOrdered, (product) => product.locationProductId);

            return Object.keys(productsGrouped).map((id) => ({
                ...productsGrouped[id][0],
                name: `${productsGrouped[id].length} x ${productsGrouped[id][0].name}`,
            }));
        } else {
            return productsOrdered;
        }
    }

    return (
        <UiTable>
            <thead>
                <tr>
                    {isSelectable ? (
                        <UiTableCellCheckbox checked={isAllProductsChecked} onChange={toggleAllProductsChecked} />
                    ) : (
                        <td />
                    )}

                    <td>Name</td>
                    {isSelectable && <td>Status</td>}
                    <td align={'right'}>Price</td>
                </tr>
            </thead>

            <tbody>
                {getProducts().map((product) => (
                    <tr key={product.id}>
                        {product.isPaid && (
                            <td className="checkbox">
                                {product.isPaid && (
                                    <UiText color="success" weight={2}>
                                        Paid
                                    </UiText>
                                )}
                            </td>
                        )}

                        {!product.isPaid && (
                            <>
                                {isSelectable ? (
                                    <UiTableCellCheckbox
                                        checked={isProductChecked(product.id)}
                                        onChange={() => toggleProductChecked(product.id)}
                                    />
                                ) : (
                                    <td className="checkbox" />
                                )}
                            </>
                        )}

                        <td>{product.name}</td>

                        {isSelectable && <td>{capitalCase(product.status)}</td>}

                        <td align={'right'}>
                            <ProductPriceLabel product={product} />
                        </td>
                    </tr>
                ))}

                <tr>
                    {isSelectable && <td />}
                    <td />
                    {isSelectable && <td />}

                    <td align={'right'}>
                        <b>
                            {'Total:'} <CurrencyMinor amount={totalAmount} />
                        </b>
                    </td>
                </tr>
            </tbody>
        </UiTable>
    );
}
