import { storage, storageRemove } from '../shared/storage';
import { createStore } from '../create-store';

const StorageKey = 'staffBars';
storageRemove(StorageKey);

const store = createStore(storage.get(StorageKey) || {});

export function setStaffBar(barId, bar) {
    const nextState = {
        ...store.state,
        [barId]: bar,
    };

    store.set(nextState);
    // storage.set(StorageKey, nextState);
}
