import React from 'react';
import { NavLink } from 'react-router-dom';
import styled from 'styled-components';
import { themeActive } from '../themes/dark';

const Wrapper = styled.button`
    background-color: transparent;
    border: 0;
    display: block;
    padding: 8px 16px;
    border-radius: 40px;
    margin-right: 8px;
    text-transform: uppercase;
    font-size: 0.75rem;
    font-weight: bold;
    position: relative;
    transition: 300ms;

    background-image: ${(props) => (props.active ? themeActive.gradients.alternative : 'none')};
    box-shadow: ${(props) => (props.active ? 'none' : 'var(--box-shadow)')};

    &.active {
        background-image: ${themeActive.gradients.alternative};
        box-shadow: none;
    }

    &:focus {
        transform: scale(1.1);
        outline: 0;
    }
`;

const StyledNavLink = styled(NavLink)`
    background-color: transparent;
    border: 0;
    display: block;
    padding: 8px 16px;
    border-radius: 40px;
    margin-right: 8px;
    text-transform: uppercase;
    font-size: 0.75rem;
    font-weight: bold;
    position: relative;
    transition: 300ms;

    background-image: ${(props) => (props.active ? themeActive.gradients.alternative : 'none')};
    box-shadow: ${(props) => (props.active ? 'none' : 'var(--box-shadow)')};

    &.active {
        background-image: ${themeActive.gradients.alternative};
        box-shadow: none;
    }

    &:focus {
        transform: scale(1.1);
        outline: 0;
    }
`;

const Badge = styled.div`
    position: absolute;
    top: -8px;
    right: -8px;
    background-color: ${themeActive.colors.text};
    color: ${themeActive.colors.textReversed};
    padding: 2px 8px;
    border-radius: 40px;
    font-size: 0.75rem;
`;

export default function Pill({ children, active, onClick, to, badge }) {
    const badgeDiv = badge > 0 && <Badge>{badge}</Badge>;

    if (to) {
        return (
            <StyledNavLink to={to} onClick={onClick} badge={badge}>
                {badgeDiv}
                {children}
            </StyledNavLink>
        );
    }

    return (
        <Wrapper active={active} onClick={onClick} badge={badge}>
            {badgeDiv}
            {children}
        </Wrapper>
    );
}
