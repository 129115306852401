import { createStore, useStore } from '../../create-store';
import { MicroserviceOrders } from '../../microservices/orders';
import { usePosBar } from './bar';

const store = createStore(getInitialState());

export function usePosGroups() {
    const { barId } = usePosBar();
    const [state, setState] = useStore(store);

    function setGroups(groups) {
        setState(state => {
            state.groups = groups;
        });
    }

    return {
        groups: state.groups || [],

        async loadGroups() {
            try {
                setGroups(
                    await MicroserviceOrders.staffApp({
                        action: 'handlerAppStaffPosGroupsGet',
                        barId,
                    }),
                );
            } catch (error) {
                console.error(error);
            }
        },

        setGroups,

        reset() {
            setState(getInitialState());
        },

        state,
    };
}

function getInitialState() {
    return {
        groups: [],
    };
}
