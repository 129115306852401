import React from 'react';
import { BackgroundCoverImage } from '../../../../components/BackgroundCoverImage';
import { UiButton, UiIconMaterial, UiText } from '@orderx/ui';
import { NavLink } from 'react-router-dom';
import './LocationBrowseListCard.scss';

interface Props {
    name: string;
    description?: string;
    image?: string;
    to?: any;
    onClick?(): any;
    isClosed?: boolean;
}

export function LocationBrowseListCard({ name, description, image, to, onClick, isClosed }: Props) {
    const propsContainer = {
        className: 'LocationBrowseListCard',
        onClick,
    };

    const content = (
        <>
            {isClosed && <div className="closed-overlay">{name} is currently closed and not accepting any orders</div>}

            <BackgroundCoverImage image={image} />

            <div className="content">
                <div>
                    <UiText weight={2} size={1}>
                        {name}
                    </UiText>

                    {description && <UiText className="description">{description}</UiText>}
                </div>
            </div>

            <div className="button-container">
                <UiButton iconCircle size={1}>
                    <UiIconMaterial>keyboard_backspace</UiIconMaterial>
                </UiButton>
            </div>
        </>
    );

    if (isClosed) {
        return <div className="LocationBrowseListCard">{content}</div>;
    }

    if (to) {
        return (
            <NavLink {...propsContainer} to={to}>
                {content}
            </NavLink>
        );
    } else {
        return <div {...propsContainer}>{content}</div>;
    }
}
