import React from 'react';
import styled from 'styled-components';
import { themeActive } from '../themes/dark';

const Wrapper = styled.label`
    color: ${themeActive.colors.faded};
    text-transform: uppercase;
    font-size: 0.75rem;
    letter-spacing: 1px;
    margin-bottom: ${({ margin }) => margin}px;
    display: block;
`;

export function InputLabel({ margin = 8, children }) {
    return <Wrapper margin={margin}>{children}</Wrapper>;
}
