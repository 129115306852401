import keyBy from 'lodash/keyBy';

export const currencies = [
    {
        label: 'Euro',
        code: 'EUR',
        locale: 'de-de',
    },
    {
        label: 'Swedish Krona',
        code: 'SEK',
        locale: 'sv-se',
    },
    {
        label: 'Norwegian Krone',
        code: 'NOK',
        locale: 'nb',
    },
    {
        label: 'Pound Sterling',
        code: 'GBP',
        locale: 'en-gb',
    },
    {
        label: 'US Dollar',
        code: 'USD',
        locale: 'en-us',
    },
];

const currencyByCode = keyBy(currencies, currency => currency.code);

function getSymbol(code) {
    const { locale } = currencyByCode[code];

    return Number(0)
        .toLocaleString(locale, {
            style: 'currency',
            currency: code,
        })
        .replace('0', '')
        .trim();
}

export function formatAmount(amount, code = 'NOK') {
    const compact = true;

    const { locale } = currencyByCode[code];

    const toLocaleStringOptions = {
        style: 'currency',
        currency: code,
    };

    if (compact) {
        toLocaleStringOptions.useGrouping = false;
    }

    const value = Number(amount).toLocaleString(locale, toLocaleStringOptions);

    if (compact) {
        if (String(amount).length >= 7) {
            return `${Number((Number(amount) / 1000000).toFixed(1))} Mill.${getSymbol(code)}`;
        }

        // if (String(amount).length >= 4) {
        //     return `${Number(Math.round(Number(amount) / 1000))}K${getSymbol(code)}`;
        // }
    }

    return value;
}
