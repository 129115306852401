import { Entity, Column, ManyToOne, OneToMany } from './typeorm';
import { BaseModel } from './base-model';
import { InventoryProduct } from './inventory-product';
import { InventoryPlugin } from './inventory-plugin';

export enum InventoryAccountType {
    SALES = 'SALES',
    PURCHASE = 'PURCHASE',
    DISCOUNT = 'DISCOUNT',
}

@Entity()
export class InventoryAccount extends BaseModel {
    @Column()
    name: string;

    @Column()
    code: string;

    @Column()
    percent: number;

    @Column()
    type: InventoryAccountType;

    @OneToMany(() => InventoryProduct, (model) => model.account)
    products: InventoryProduct[];

    @ManyToOne(() => InventoryPlugin, (model) => model.accounts)
    plugin: InventoryPlugin;
    @Column()
    pluginId: number;
}
