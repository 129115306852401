import React, { useState, useEffect } from 'react';
import { useRouter } from '../../../@v2/hooks/router';
import { useApiQuery } from '@orderx/http';
import { MicroserviceLocation } from '../../../microservices/location';
import { storageRemove } from '../../../shared/storage';
import { useOnResume } from '../../../shared/browser';
import { compareStrings } from '../../../util/compare-strings';
import { ToolbarSearch } from '../../../@v2/components/toolbar/search/ToolbarSearch';
import { UiGrid, UiButton } from '@orderx/ui';
import { LocationBrowseListCard } from '../../../@v2/components/location/browse-list-card/LocationBrowseListCard';
import { TagPage } from '../../TagPage';
import { CustomerBrowseIdPage } from './id/CustomerBrowseIdPage';
import last from 'lodash/last';

export function CustomerBrowsePage() {
    const { history, replaceParams } = useRouter();
    const [keyword, setKeyword] = useState('');
    const [findAll, setFindAll] = useState(false);

    const { response, run } = useApiQuery(() =>
        MicroserviceLocation.appCustomerPost({
            action: 'handlerAppCustomerBrowse',
            findAll,
            tld: last(window.location.hostname.split('.')),
        }),
    );

    const locations = response || [];

    useEffect(() => {
        storageRemove('OrderGroup.backButtonLink');
        run();
    }, []);

    useEffect(() => {
        run();
    }, [findAll]);

    useOnResume(run, [findAll]);

    const locationsFiltered = keyword
        ? locations.filter((location: any) => compareStrings(location.name, keyword))
        : locations;

    return (
        <>
            <ToolbarSearch title="Browse" keyword={keyword} setKeyword={setKeyword} />

            <UiGrid outerSpacing={2}>
                {locationsFiltered.map((location: any) => {
                    const { id, name, description, image, inactive, browseScreenNavigateToTag } = location;

                    return (
                        <LocationBrowseListCard
                            onClick={() => {
                                if (location.type === 'V2') {
                                    window.location.href = `https://orderx.eu/${id}`;
                                } else if (browseScreenNavigateToTag) {
                                    history.push(replaceParams(TagPage.route, { tag: browseScreenNavigateToTag }));
                                } else {
                                    history.push(`${replaceParams(CustomerBrowseIdPage.route, { id })}?name=${name}`);
                                }
                            }}
                            name={name}
                            description={description}
                            image={image}
                            isClosed={inactive}
                            key={id}
                        />
                    );
                })}

                {response && !findAll && (
                    <UiButton
                        onClick={() => {
                            setFindAll(true);
                        }}>
                        Browse all venues
                    </UiButton>
                )}
            </UiGrid>
        </>
    );
}

CustomerBrowsePage.route = '/customer/browse';
