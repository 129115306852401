export function getProductsByCategory({ products, category, subCategory }) {
    if (subCategory) {
        return products.filter(product => product.subCategory === subCategory);
    }

    if (category) {
        return products.filter(product => product.category === category);
    }

    return products || [];
}
