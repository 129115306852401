import React, { useEffect, useState } from 'react';
import { UiToolbar, UiGrid, UiText, UiFlex, UiLoader, UiIconMaterial, UiButton, UiAlert } from '@orderx/ui';
import { ToolbarTitle } from '../../components/toolbar/title/ToolbarTitle';
import { useApiQuery } from '@orderx/http';
import { MicroserviceOrders } from '../../../microservices/orders';
import { useDeviceFingerprint } from '../../hooks/device-fingerprint';
import { toMoment } from '../../../shared/moment';
import './OrderHistoryPage.scss';
import { Image } from '../../../components/Image';
import sum from 'lodash/sum';
import { CurrencyMinor } from '../../../components/CurrencyMinor';
import { useUiToggle } from '../../components/ui-toggle/UiToggle';
import { useOrdersPlaced } from '../../../stores/order-placed';
import { OrderGroupPaymentReceiptDialog } from '../../../components/OrderGroupPaymentReceiptDialog';
import { PaymentStatus } from '../../../models/orders/payment';
import { useRouter } from '../../hooks/router';
import { OrderIdPage } from '../order/id/OrderIdPage';

export function OrderHistoryPage() {
    const {
        push,
        location: { pathname },
    } = useRouter();
    const { orderIds } = useOrdersPlaced();
    const { deviceFingerprint } = useDeviceFingerprint();
    const [paymentOpen, setPaymentOpen] = useState(undefined);

    const { component, value } = useUiToggle([
        {
            label: 'Payments',
            value: 'PAYMENTS',
        },
        {
            label: 'Orders',
            value: 'ORDERS',
        },
    ]);

    const { run, response } = useApiQuery(() =>
        MicroserviceOrders.postAppCustomer({
            action: 'handlerAppCustomerOrderHistory',
            deviceFingerprint,
            orderIds,
        }),
    );

    useEffect(() => {
        run();
    }, [deviceFingerprint]);

    const orders = response?.orders || [];
    const payments = response?.payments || [];

    return (
        <div className="OrderHistoryPage">
            <UiToolbar>
                <ToolbarTitle title="Order history" />
            </UiToolbar>

            <UiGrid outerSpacing={2}>
                {component}

                {!response && (
                    <UiFlex justifyContent="center">
                        <UiLoader />
                    </UiFlex>
                )}

                {value === 'ORDERS' && orders.length === 0 && response && <UiAlert>No orders found</UiAlert>}
                {value === 'PAYMENTS' && payments.length === 0 && response && <UiAlert>No payments found</UiAlert>}
            </UiGrid>

            {value === 'ORDERS' &&
                orders.map((order) => (
                    <UiGrid key={order.id} className="item-row">
                        <UiFlex
                            onClick={() => {
                                push(`${OrderIdPage.route}?back=${pathname}`, { orderId: order.id });
                            }}
                            justifyContent="space-between">
                            <UiFlex>
                                {order.locationImage && (
                                    <div className="image-container">
                                        <Image src={order.locationImage} alt={order.locationName} />
                                    </div>
                                )}

                                <UiGrid spacing={0}>
                                    <UiText size={-1} weight={2} color="text-secondary">
                                        {toMoment(order.createdAt).format('MMM D, YYYY, HH:mm')}
                                    </UiText>

                                    <UiText>{order.barName}</UiText>

                                    <UiText size={-1} weight={2} color="text-secondary">
                                        {order.group?.name} • Nr:{order.sequence}
                                    </UiText>
                                </UiGrid>
                            </UiFlex>
                        </UiFlex>
                    </UiGrid>
                ))}

            {value === 'PAYMENTS' &&
                payments.map((payment) => {
                    const isSuccess = payment.status === PaymentStatus.Success;
                    const color = isSuccess ? 'success' : 'error';

                    function handleClick() {
                        if (isSuccess) {
                            setPaymentOpen(payment);
                        }
                    }

                    return (
                        <UiGrid key={payment.id} className="item-row">
                            <UiFlex onClick={handleClick} justifyContent="space-between" spacing={2}>
                                <UiFlex>
                                    <UiGrid spacing={0}>
                                        <UiText size={-1} weight={2} color="text-secondary">
                                            {toMoment(payment.createdAt).format('MMM D, YYYY, HH:mm')}
                                        </UiText>

                                        <UiText>{payment.barName}</UiText>

                                        <UiText size={-1} weight={2} color="text-secondary">
                                            {payment.group?.name}
                                        </UiText>
                                    </UiGrid>
                                </UiFlex>

                                <UiFlex spacing={1}>
                                    <UiText weight={2} color={color}>
                                        <CurrencyMinor
                                            amount={sum(payment.products.map((product) => product.priceIncludingVat))}
                                        />
                                    </UiText>

                                    <UiButton
                                        color={color}
                                        icon={<UiIconMaterial>{isSuccess ? 'receipt' : 'warning'}</UiIconMaterial>}
                                    />
                                </UiFlex>
                            </UiFlex>
                        </UiGrid>
                    );
                })}

            {paymentOpen && (
                <OrderGroupPaymentReceiptDialog
                    payment={paymentOpen}
                    isOpen
                    onClose={() => setPaymentOpen(undefined)}
                />
            )}
        </div>
    );
}

OrderHistoryPage.route = '/customer/order-history';
