import React, { useEffect } from 'react';
import { StaffBottomNav } from '../../components/staff-bottom-nav/StaffBottomNav';
import { StaffCreateActiveOrder } from '../../components/staff-create/StaffCreateActiveOrder';
import { StaffCreateBottomButton } from '../../components/staff-create/StaffCreateBottomButton';
import { StaffCreateHeader } from '../../components/staff-create/StaffCreateHeader';
import { StaffCreateOrderQuery } from '../../components/staff-create/StaffCreateOrderQuery';
import { StaffCreateProductCategoryFilter } from '../../components/staff-create/StaffCreateProductCategoryFilter';
import { StaffCreateProductList } from '../../components/staff-create/StaffCreateProductList';
import { StaffCreateProductPagination } from '../../components/staff-create/StaffCreateProductPagination';
import { StaffCreateProductSummary } from '../../components/staff-create/StaffCreateProductSummary';
import { StaffCreateTerminalSlider } from '../../components/staff-create/StaffCreateTerminalSlider';
import { resetOrderProductState } from '../../stores/create-order-products';

export function StaffCreatePage() {
    useEffect(() => {
        return () => resetOrderProductState();
    }, []);

    return (
        <>
            <StaffCreateHeader />
            <StaffCreateProductSummary />
            <StaffCreateProductCategoryFilter />
            <StaffCreateActiveOrder />
            <StaffCreateProductList />
            <StaffCreateOrderQuery />
            <StaffCreateProductPagination />
            <StaffCreateBottomButton />
            <StaffCreateTerminalSlider />
            <StaffBottomNav />
        </>
    );
}
