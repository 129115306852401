import ArrowForwardRounded from '@material-ui/icons/ArrowForwardRounded';
import React from 'react';
import { toMoment } from '../../shared/moment';
import { UiFlex } from '@orderx/ui';

export function InventoryStockHoldingReportDateLabel({ report }) {
    const dateFormat = 'Do MMMM, HH:mm';

    return (
        <UiFlex justifyContent={'center'} spacing={1}>
            <span>{(report.dateFrom && toMoment(report.dateFrom).format(dateFormat)) || '-'}</span>
            <ArrowForwardRounded fontSize={'small'} />
            <span>{(report.dateTo && toMoment(report.dateTo).format(dateFormat)) || 'Current time'}</span>
        </UiFlex>
    );
}
