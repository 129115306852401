import React, { useEffect, useState } from 'react';
import { V2Button } from '../../components/v2/V2Button';
import { V2Page } from '../../components/v2/V2Page';
import { V2PageHeader } from '../../components/v2/V2PageHeader';
import { MicroserviceLocation } from '../../microservices/location';
import { routes } from '../../shared/router';
import { showSuccess } from '../../shared/toasts';
import { translate } from '../../shared/translate';
import { useStaffOrderingBar } from '../../stores/staff-ordering-bar';
import { useApiQuery } from '@orderx/http';
import { UiInput, UiText } from '@orderx/ui';

export function StaffOrderConfigQueuePage({ history }) {
    const { barId } = useStaffOrderingBar();
    const { response: config, isLoading, run: loadConfig } = useApiQuery(() =>
        MicroserviceLocation.findBarConfig(barId),
    );

    const [barOrderQueueLimit, setBarOrderQueueLimit] = useState(0);
    const [barOrderQueueLimitReachedText, setBarOrderQueueLimitReachedText] = useState('');

    const { isLoading: isUpdating, run: updateConfig } = useApiQuery(async () => {
        await MicroserviceLocation.updateBarConfig(barId, {
            barOrderQueueLimit: barOrderQueueLimit ? Number(barOrderQueueLimit) : 0,
            barOrderQueueLimitReachedText,
        });

        showSuccess(translate('Config updated'));
    });

    useEffect(() => {
        loadConfig();
    }, []);

    useEffect(() => {
        if (config) {
            const { barOrderQueueLimit, barOrderQueueLimitReachedText } = config;

            setBarOrderQueueLimit(barOrderQueueLimit || 0);
            setBarOrderQueueLimitReachedText(barOrderQueueLimitReachedText || '');
        }
    }, [config]);

    return (
        <>
            <V2Page
                header={
                    <V2PageHeader
                        onClickBack={() => history.push(routes.staffOrderBarConfig.replace(':barId', barId))}
                        title={'Queue'}
                        isLoading={isLoading}
                    />
                }>
                {config && (
                    <>
                        <div>
                            <UiInput
                                label={translate('Queue limit')}
                                value={barOrderQueueLimit}
                                onChangeValue={setBarOrderQueueLimit}
                                onClick={() => setBarOrderQueueLimit('')}
                                type="number"
                            />

                            <UiText size={-1}>
                                Use 0 for no limit. If limit is reached, no more orders are accepted from customers
                            </UiText>
                        </div>

                        <div>
                            <UiInput
                                label={translate('Queue limit reached text')}
                                value={barOrderQueueLimitReachedText}
                                onChangeValue={setBarOrderQueueLimitReachedText}
                            />

                            <UiText size={-1}>Will be displayed to customers if queue limit is reached</UiText>
                        </div>

                        <V2Button onClick={updateConfig} isLoading={isUpdating} fullWidth primary>
                            {translate('Save')}
                        </V2Button>
                    </>
                )}
            </V2Page>
        </>
    );
}
