import React from 'react';
import videoOrderInProcess from '../../../../assets/order-in-process.mp4';
import videoOrderReady from '../../../../assets/order-ready.mp4';
import videoOrderReceived from '../../../../assets/order-received.mp4';
import { ProductStatus } from '../../../../models/orders/product';
import { VideoPlayer } from '../../../../components/VideoPlayer';
import { getUrlToAsset } from '../../../../shared/assets';

interface Props {
    order: any;
    height?: number;
}

export function OrderVideo({ order, height }: Props) {
    const { products = [] } = order;

    const isReady = products.find((product) => product.status === ProductStatus.Ready);
    const isDelivered =
        !isReady && products.filter((product) => product.status === ProductStatus.Delivered).length === products.length;
    const inPreparation = !isDelivered && products.find((product) => product.status === ProductStatus.InPreparation);

    const video =
        ((isReady || isDelivered) && videoOrderReady) || (inPreparation && videoOrderInProcess) || videoOrderReceived;

    return <VideoPlayer src={getUrlToAsset(video)} height={height} timeout={500} />;
}
