import { getApiUrl } from '../config';
import { httpPost } from '../shared/api';

const getUrl = getApiUrl('wristbands');

const Action = {
    FindWristband: 'FIND_WRISTBAND',
    AddBalance: 'ADD_BALANCE',
};

export const MicroserviceWristbands = {
    findWristband({ locationId, wristbandId }) {
        return httpPost(getUrl('app/index'), { locationId, wristbandId }, { action: Action.FindWristband });
    },

    addAmount({ locationId, wristbandId, amount }) {
        return httpPost(getUrl('app/index'), { locationId, wristbandId, amount }, { action: Action.AddBalance });
    },
};
