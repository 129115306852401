import { stringify } from 'query-string';
import { getApiJwtToken } from '../stores/api-jwt-token';
import { getDeviceId } from './device';
import isEmpty from 'lodash/isEmpty';

export async function httpGet(url, params = {}) {
    params = getExtendedParams(params);
    url = isEmpty(params) ? url : `${url}?${stringify(params)}`;

    return parseResponse(
        await fetch(url, {
            method: 'GET',
            headers: getHeaders(),
            mode: 'cors',
        }),
    );
}

export async function httpPost(url, data = {}, params = {}) {
    params = getExtendedParams(params);
    url = isEmpty(params) ? url : `${url}?${stringify(params)}`;

    return parseResponse(
        await fetch(url, {
            method: 'POST',
            body: JSON.stringify(data),
            headers: getHeaders(),
            mode: 'cors',
        }),
    );
}

export async function httpPut(url, data, params) {
    params = getExtendedParams(params);
    url = isEmpty(params) ? url : `${url}?${stringify(params)}`;

    return parseResponse(
        await fetch(url, {
            method: 'PUT',
            body: JSON.stringify(data),
            headers: getHeaders(),
            mode: 'cors',
        }),
    );
}

export async function httpDelete(url) {
    return parseResponse(
        await fetch(url, {
            method: 'DELETE',
            headers: getHeaders(),
            mode: 'cors',
        }),
    );
}

async function parseResponse(response) {
    if (response.status < 200 || response.status >= 300) {
        throw await parseBodyFromResponse(response);
    }

    return parseBodyFromResponse(response);
}

async function parseBodyFromResponse(response) {
    try {
        return await response.clone().json();
    } catch (error) {
        return response.text();
    }
}

function getExtendedParams(params = {}) {
    const deviceId = getDeviceId();

    if (deviceId) {
        params.deviceId = deviceId;
    }

    return params;
}

function getHeaders() {
    const token = getApiJwtToken();

    const headers = {
        'Content-Type': 'application/json',
    };

    if (token) {
        headers.Authorization = `Bearer ${token}`;
    }

    return headers;
}
