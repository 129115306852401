import { createStore, useStore } from '../../create-store';
import { MicroserviceOrders } from '../../microservices/orders';
import sum from 'lodash/sum';
import without from 'lodash/without';
import keyBy from 'lodash/keyBy';
import { useMemo } from 'react';

const store = createStore(getInitialState());

export function usePosGroup() {
    const [state, setState] = useStore(store);
    const { group, productIdsChecked, products } = state;
    const groupIdActive = group?.id;
    const productById = useMemo(() => keyBy(products, product => product.id), [products]);
    const isProductChecked = id => productIdsChecked.includes(id);
    const isAllProductsChecked =
        products.length > 0 && products.filter(canCheckProduct).length === productIdsChecked.length;
    const productsChecked = productIdsChecked.map(id => productById[id]).filter(Boolean);

    function canCheckProduct(product) {
        return !product.isPaid;
    }

    return {
        groupId: groupIdActive,
        barId: group?.barId,
        group,
        products,
        payments: state.payments,
        payment: state.payment,
        productsChecked,

        async loadGroup(groupId) {
            try {
                const { group, products, payments, payment } = await MicroserviceOrders.staffApp({
                    action: 'handlerAppStaffFindGroup',
                    groupId: groupId || groupIdActive,
                });

                setState(state => {
                    state.group = group;
                    state.products = products;
                    state.payments = payments;
                    state.payment = payment;
                });
            } catch (error) {
                console.error(error);
            }
        },

        reset() {
            setState(getInitialState());
        },

        isProductChecked,

        toggleProductChecked(id) {
            setState(state => {
                state.productIdsChecked = isProductChecked(id)
                    ? without(state.productIdsChecked, id)
                    : [...state.productIdsChecked, id];
            });
        },

        checkAllProducts() {
            setState(state => {
                state.productIdsChecked = products.filter(canCheckProduct).map(product => product.id);
            });
        },

        toggleAllProductsChecked() {
            setState(state => {
                state.productIdsChecked = isAllProductsChecked
                    ? []
                    : products.filter(canCheckProduct).map(product => product.id);
            });
        },

        resetProductsChecked() {
            setState(state => {
                state.productIdsChecked = [];
            });
        },

        isAllProductsChecked,

        totalAmountChecked: sum(productsChecked.map(product => product.priceIncludingVat)),
        totalAmount: sum(products.map(product => product.priceIncludingVat)),
    };
}

function getInitialState() {
    return {
        group: undefined,
        products: [],
        payments: [],
        payment: undefined,
        productIdsChecked: [],
    };
}
