export const OrderStatus = {
    PaymentRequired: 'PAYMENT_REQUIRED',
    Placed: 'PLACED',
    InPreparation: 'IN_PREPARATION',
    Ready: 'READY',
    InDelivery: 'IN_DELIVERY',
    Delivered: 'DELIVERED',
    Rejected: 'REJECTED',

    Open: 'OPEN',
    Closed: 'CLOSED',
    Archived: 'ARCHIVED',
};

export const OrderDeliveryType = {
    Pickup: 'PICKUP',
    Address: 'ADDRESS',
    Table: 'TABLE',
};

export const OrderRelation = {
    Products: 'products',
    ProductsPayments: 'products.payments',
};
