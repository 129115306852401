import React from 'react';
import { UiText } from '@orderx/ui';

export function DeployedAtLabel() {
    return (
        <UiText size={-2} weight={2} color="text-secondary" align="center">
            {process.env.REACT_APP_DEPLOYED_AT}
        </UiText>
    );
}
