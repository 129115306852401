import { useState } from 'react';

export function useCountById() {
    const [counts, setCounts] = useState({});

    return {
        counts,
        increment: (id, count) => setCounts(counts => ({ ...counts, [id]: (counts[id] || 0) + count })),
        decrement: (id, count) => setCounts(counts => ({ ...counts, [id]: (counts[id] || 0) - count })),
        setCount: (id, count) => setCounts(counts => ({ ...counts, [id]: count })),
        reset: () => setCounts({}),
    };
}
