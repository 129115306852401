import { Entity, OneToMany, OneToOne, JoinColumn, Column } from './typeorm';
import { Location } from './location';
import { InventoryAccount } from './inventory-account';
import { InventoryProduct } from './inventory-product';
import { InventoryInventory } from './inventory-inventory';
import { InventoryRecipe } from './inventory-recipe';
import { BaseModel } from './base-model';
import { InventoryMenu } from './inventory-menu';

@Entity()
export class InventoryPlugin extends BaseModel {
    @OneToOne(() => Location, (model) => model.inventoryPlugin)
    @JoinColumn()
    location: Location;
    @Column()
    locationId: number;

    @OneToMany(() => InventoryAccount, (model) => model.plugin)
    accounts: InventoryAccount[];

    @OneToMany(() => InventoryProduct, (model) => model.plugin)
    products: InventoryProduct[];

    @OneToMany(() => InventoryInventory, (model) => model.plugin)
    inventories: InventoryInventory[];

    @OneToMany(() => InventoryRecipe, (model) => model.plugin)
    recipes: InventoryRecipe[];

    @OneToMany(() => InventoryMenu, (model) => model.plugin)
    menus: InventoryMenu[];
}
