import clsx from 'clsx';
import React, { useState } from 'react';
import { UiButton, UiFlex } from '@orderx/ui';
import './PosFooterTerminal.scss';

export function PosFooterTerminal() {
    const [isConnected] = useState(true);

    return (
        <div className="PosFooterTerminal">
            <div className="placeholder" />

            <UiFlex className={clsx('container', isConnected && 'connected')} fullWidth fullHeight>
                <UiButton variant="filled">T1</UiButton>
                <UiButton variant="filled">T2</UiButton>
                <UiButton variant="filled">T3</UiButton>
                <UiButton variant="filled">T4</UiButton>
                <UiButton variant="filled">T5</UiButton>
                <UiButton variant="filled">T6</UiButton>
                <UiButton variant="filled">T7</UiButton>
                <UiButton variant="filled">T8</UiButton>
                <UiButton variant="filled">T9</UiButton>
                <UiButton variant="filled">T10</UiButton>
                <UiButton variant="filled">T11</UiButton>
                <UiButton variant="filled">T12</UiButton>
                <UiButton variant="filled">T13</UiButton>
                <UiButton variant="filled">T14</UiButton>
            </UiFlex>
        </div>
    );
}
