import clsx from 'clsx';
import React, { Fragment, useState } from 'react';
import { useOrderBubble } from '../../../../../hooks/order-bubble';
import { UiGrid, UiText, UiImage, UiFlex, UiTable, UiButton, UiIconMaterial, UiBox } from '@orderx/ui';
import './OrderIdViewCompact.scss';
import { useCurrency } from '../../../../../hooks/currency';
import sum from 'lodash/sum';
import times from 'lodash/times';
import groupBy from 'lodash/groupBy';
import { useRouter } from '../../../../../hooks/router';
import { Order } from '../../../../../models/order';
import { OrderProductStatus, OrderProduct } from '../../../../../models/order-product';
import { ZonePage } from '../../../../../pages/zone/ZonePage';

interface Props {
    order: Order;
}

export function OrderIdViewCompact({ order }: Props) {
    useOrderBubble().useTemporaryHide();
    const { formatMinor } = useCurrency();
    const { replaceParams } = useRouter();
    const [activeLanguage, setActiveLanguage] = useState('ET');

    const { sequence, outlet, orderProducts } = order;

    const numTotalProducts = orderProducts.length;

    const statusOfAllProducts =
        numTotalProducts > 0 &&
        orderProducts.filter((product) => product.status === orderProducts[0].status).length === numTotalProducts &&
        orderProducts[0].status;

    const isDelivered = statusOfAllProducts === OrderProductStatus.DELIVERED;
    const isReady = orderProducts.find((product) => product.status === OrderProductStatus.READY);
    const inPreparation =
        !isReady && orderProducts.find((product) => product.status === OrderProductStatus.IN_PREPARATION);

    const amountTotal = sum(orderProducts.map((orderProduct) => orderProduct.priceFinal));

    const translation = {
        ET: {
            sequencePrefix: 'Nr:',
            orderStatusPlaced: `Palun ole leti läheduses!<br />Tellimus väljastatakse sinu numbri alusel`,
            orderStatusInPreparation: `Palun ole leti läheduses!<br />Tellimust valmistatakse`,
            orderStatusReady: `Tellimus valmis!<br />Näita seda numbrit letis`,
            orderStatusDelivered: `Tellimus antud üle!<br />Ilusat õhtu jätku!`,
            total: 'Kokku:',
        },
        EN: {
            sequencePrefix: 'Nr:',
            orderStatusPlaced: `Please stay near the counter!<br/> We will hand out the order based on your number`,
            orderStatusInPreparation: `Please stay near the counter!<br/>Order is being prepared.`,
            orderStatusReady: `Your order is ready!<br/>Show this number at the counter`,
            orderStatusDelivered: `Order delivered!<br/>Have a nice evening!`,
            total: 'Total:',
        },
    }[activeLanguage];

    const orderProductsByProductId = groupBy(orderProducts, (orderProduct) => orderProduct.productId);

    const orderProductsGrouped: (OrderProduct & { count: number; total: number })[] = Object.keys(
        orderProductsByProductId,
    ).map((id) => ({
        ...orderProductsByProductId[id][0],
        count: orderProductsByProductId[id].length,
        total: sum(orderProductsByProductId[id].map(({ priceFinal }) => priceFinal)),
    }));

    const elementProducts = (
        <UiTable>
            <tbody>
                {times(1).map((i) => (
                    <Fragment key={i}>
                        {orderProductsGrouped.map(({ product, count, total }) => (
                            <tr key={product.id}>
                                <td>
                                    {count} x {product.name}
                                </td>
                                <td align="right">{formatMinor(total)}</td>
                            </tr>
                        ))}
                    </Fragment>
                ))}

                <tr>
                    <td align="right" colSpan={2}>
                        <b>
                            {translation.total} {formatMinor(amountTotal)}
                        </b>
                    </td>
                </tr>
            </tbody>
        </UiTable>
    );

    const elementClose = (
        <>
            <div className="close-button-container">
                <UiButton
                    to={replaceParams(ZonePage.route, { hash: order.outletZone.hash })}
                    size={2}
                    fullWidth
                    variant="filled"
                    icon={<UiIconMaterial>close</UiIconMaterial>}
                />
            </div>

            <div className="background" />
        </>
    );

    if (isReady) {
        return (
            <UiGrid gap={4} padding={2} className={clsx('OrderIdViewFotografiska')}>
                <UiBox padding={2} className="ready-container">
                    <UiGrid className="ready" gap={4} padding={2} paddingTop={4}>
                        <UiText className="sequence" align="center" weight={2}>
                            <UiFlex justifyContent="center">
                                <div className="image-container">
                                    <UiImage src={outlet.location.imageLogo} type="cover" />
                                </div>
                            </UiFlex>

                            <UiText className="number">{sequence}</UiText>

                            <UiText size={4} weight={2}>
                                <div dangerouslySetInnerHTML={{ __html: translation.orderStatusReady }} />
                            </UiText>
                        </UiText>

                        {elementProducts}
                    </UiGrid>
                </UiBox>

                {elementClose}
            </UiGrid>
        );
    }

    return (
        <UiGrid gap={4} padding={2} paddingTop={4} className={clsx('OrderIdViewCompact', isDelivered && 'isDelivered')}>
            <UiFlex justifyContent="center">
                <div className="image-container">
                    <UiImage src={outlet.location.imageLogo} type="cover" />
                </div>
            </UiFlex>

            <div>
                <UiText size={2} align="center">
                    {outlet.location.name} • {outlet.name}
                </UiText>

                <UiText size={6} weight={2} align="center">
                    {translation.sequencePrefix}
                    {sequence}
                </UiText>
            </div>

            <UiBox className="info" padding={2}>
                <UiText size={3} weight={2} align="center">
                    <UiIconMaterial>{isDelivered ? 'check_circle' : 'new_releases'}</UiIconMaterial>

                    <div
                        dangerouslySetInnerHTML={{
                            __html:
                                (isDelivered && translation.orderStatusDelivered) ||
                                (isReady && translation.orderStatusReady) ||
                                (inPreparation && translation.orderStatusInPreparation) ||
                                translation.orderStatusPlaced,
                        }}
                    />
                </UiText>
            </UiBox>

            {elementProducts}

            {elementClose}
        </UiGrid>
    );
}
