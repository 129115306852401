import React from 'react';
import Loadable from 'react-loadable';
import { SplashScreen } from '../pages/splash-screen/SplashScreen';

export function createAsyncComponent(loader) {
    return Loadable({
        async loader() {
            const module = await loader();
            const defaultExport = module.default;
            const namedExport = module[Object.keys(module)[0]];
            return defaultExport || namedExport;
        },
        loading: () => <SplashScreen />,
    });
}
