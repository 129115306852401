import React, { useEffect } from 'react';
import { NavLink } from 'react-router-dom';
import styled from 'styled-components';
import { MicroserviceOrders } from '../microservices/orders';
import { useOnWindowFocus } from '../shared/browser';
import { routes, withParams } from '../shared/router';
import { useStaffActiveBar } from '../stores/staff-active-bar';
import { themeActive } from '../themes/dark';
import { useApiQuery } from '@orderx/http';
import { useAudio } from '../@v2/hooks/audio';

const Wrapper = styled.h2`
    background-image: ${themeActive.gradients.green};
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    top: 3rem;
    left: 0.5rem;
    height: 3rem;
    width: 3rem;
    border-radius: 3rem;
    z-index: 100;
    animation-duration: 2s !important;
`;

export function StaffBarNewOrders() {
    const { playNewOrder } = useAudio();
    const { id: barId } = useStaffActiveBar();

    const { response: orders = [], run: loadOrders } = useApiQuery(() =>
        MicroserviceOrders.staffFindPlacedOrders({ barId }),
    );
    const shouldPlay = orders.length > 0;

    useEffect(() => {
        loadOrders();
    }, []);

    useOnWindowFocus(loadOrders);

    useEffect(() => {
        if (shouldPlay) {
            const interval = setInterval(() => {
                playNewOrder();
            }, 5000);

            return () => clearInterval(interval);
        }
    }, [shouldPlay]);

    if (orders.length > 0) {
        return (
            <NavLink to={withParams(routes.staffOrderBarOrders, { barId })}>
                <Wrapper className="animated infinite flash">{orders.length}</Wrapper>
            </NavLink>
        );
    }

    return null;
}
