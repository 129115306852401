import clone from 'lodash/clone';
import isBoolean from 'lodash/isBoolean';
import orderBy from 'lodash/orderBy';
import uniq from 'lodash/uniq';
import without from 'lodash/without';
import { isTabletUp } from '../shared/browser';
import { storage } from '../shared/storage';
import { createStore, useStore } from '../create-store';

const StorageKey = 'locationTerminalSettings';
const store = createStore(storage.get(StorageKey) || {});

window.locationTerminalSettings = store;

export function useLocationTerminalSettings(locationId) {
    const [locationTerminalSettings] = useStore(store);
    const settings = locationTerminalSettings[locationId] || {};

    settings.id = settings.id || locationId;
    settings.terminalIds = uniq(settings.terminalIds) || [];
    settings.terminalOrderById = settings.terminalOrderById || {};
    settings.isControlledFromSettings = settings.isControlledFromSettings || false;
    settings.isExpanded = isBoolean(settings.isExpanded) ? settings.isExpanded : isTabletUp();

    const { terminalIds, terminalOrderById } = settings;

    function serialize(state) {
        const nextState = {
            [locationId]: {
                ...store.state[locationId],
                ...state,
            },
        };

        storage.set(StorageKey, nextState);
        store.set(nextState);
    }

    return {
        isControlledFromSettings: settings.isControlledFromSettings,
        isExpanded: settings.isExpanded,
        terminalIds,
        terminalOrderById: settings.terminalOrderById,
        settings,

        getOrderedTerminals(terminals) {
            return orderBy(terminals, terminal => terminalOrderById[terminal.id], 'asc');
        },

        setIsExpanded(isExpanded) {
            serialize({ isExpanded });
        },

        setControlFromSettings(isControlledFromSettings) {
            serialize({ isControlledFromSettings });
        },

        setTerminalOrderById(terminalOrderById) {
            serialize({ terminalOrderById });
        },

        toggleTerminal(id) {
            let ids = clone(terminalIds);

            if (ids.includes(id)) {
                ids = without(ids, id);
            } else {
                ids.push(id);
            }

            serialize({ terminalIds: ids });
        },

        reset() {
            serialize({
                id: locationId,
                terminalIds: [],
                terminalOrderById: {},
                isControlledFromSettings: false,
                isExpanded: isTabletUp(),
            });
        },
    };
}
