import makeStyles from '@material-ui/core/styles/makeStyles';
import React from 'react';
import { themeActive } from '../themes/dark';
import { UiFlex } from '@orderx/ui';

const useStyles = makeStyles({
    Demo: {
        backgroundColor: '#333',
        height: '100%',
        width: '100%',
        position: 'fixed',
        top: 0,
        left: 0,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    frame: {
        position: 'relative',
        width: 360,
        height: 780,
        backgroundColor: themeActive.tabs.backgroundColor,
        borderRadius: 40,
        boxShadow: '0px 0px 0px 11px #1f1f1f, 0px 0px 0px 13px #191919, 0px 0px 0px 20px #111',
        paddingTop: 30,
        paddingBottom: 20,
        overflow: 'hidden',
    },
    frameTop: {
        position: 'absolute',
        left: '50%',
        transform: 'translateX(-50%)',
        top: 0,
        width: '56%',
        height: 30,
        backgroundColor: '#1f1f1f',
        borderRadius: '0px 0px 40px 40px',
    },
    frameBottom: {
        position: 'absolute',
        left: '50%',
        transform: 'translateX(-50%)',
        bottom: 7,
        width: 140,
        height: 4,
        backgroundColor: '#f2f2f2',
        borderRadius: 10,
    },
    iframe: {
        border: 0,
        height: '100%',
        width: '100%',
    },
});

export function Demo({ frames }) {
    const classes = useStyles();

    frames = [
        `${window.location.origin}/customer/browse/rbt-1`,
        `${window.location.origin}/staff/order/B1_USAdEf/tables`,
    ];

    return (
        <div className={classes.Demo}>
            <UiFlex spacing={8}>
                {frames.map((frame) => (
                    <div key={frame} className={classes.frame}>
                        <div className={classes.frameTop} />
                        <iframe className={classes.iframe} src={frame} />
                        <div className={classes.frameBottom} />
                    </div>
                ))}
            </UiFlex>
        </div>
    );
}
