import SvgIcon from '@material-ui/core/SvgIcon';
import React from 'react';

export function UiIconManualEntry(props) {
    return (
        <SvgIcon {...props} width="941.5px" height="941.5px" viewBox="0 0 941.5 941.5">
            <g>
                <path d="M181.8,20c0-11-9-20-20-20H92C81,0,72,9,72,20v69.8c0,11,9,20,20,20h69.8c11,0,20-9,20-20V20z" />
                <path d="M266.1,109.7h69.8c11,0,20-9,20-20V20c0-11-9-20-20-20h-69.8c-11,0-20,9-20,20v69.8C246.1,100.8,255.1,109.7,266.1,109.7z" />
                <path d="M440.2,109.7H510c11,0,20-9,20-20V20c0-11-9-20-20-20h-69.8c-11,0-20,9-20,20v69.8C420.2,100.8,429.2,109.7,440.2,109.7z" />
                <path d="M181.8,189c0-11-9-20-20-20H92c-11,0-20,9-20,20v69.8c0,11,9,20,20,20h69.8c11,0,20-9,20-20V189z" />
                <path d="M246.1,258.8c0,11,9,20,20,20h69.8c11,0,20-9,20-20V189c0-11-9-20-20-20h-69.8c-11,0-20,9-20,20V258.8L246.1,258.8z" />
                <path d="M420.2,258.8c0,11,9,20,20,20h24.6c0-13,0-26,0-38.9c0-24.3,4.101-53.2,22-70.9h-46.6c-11,0-20,9-20,20V258.8z" />
                <path d="M92,447.9h69.8c11,0,20-9,20-20v-69.8c0-11-9-20-20-20H92c-11,0-20,9-20,20v69.8C72,438.9,81,447.9,92,447.9z" />
                <path d="M246.1,427.9c0,11,9,20,20,20h69.8c11,0,20-9,20-20v-69.8c0-11-9-20-20-20h-69.8c-11,0-20,9-20,20V427.9L246.1,427.9z" />
                <path d="M420.2,427.9c0,11,9,20,20,20h24.5V338.1h-24.5c-11,0-20,9-20,20V427.9z" />
                <path
                    d="M851,786.4c12.1-20.301,18.5-43.5,18.5-67.201V559.9c0-19.5-12.5-36.801-31.1-42.801C782.9,499,670.9,462.9,653.8,459.5
		c-5.3-1-10.5-1.5-15.5-1.5c-13.5,0-25.3,3.699-32.399,10H605.8c0,0,0,0,0-0.101c-0.5-4.5-25.899-250.3-25.899-250.7
		c-4.7-43.4-75.2-38.1-75.101,5.4l-0.2,404.3c0,4.799-3.5,8.799-8.3,9.5c-0.1,0-0.1,0-0.2,0c-0.5,0.1-1,0.1-1.5,0.1
		c-13.6-0.4-44.3-0.801-74.199-44.1c-4-5.701-7.601-10.9-10.9-15.701c-10.4-14.898-24.3-21.299-37.4-21.299
		c-23,0-43.699,19.9-38.699,49.6c7.199,43.301,54.6,144.199,148,238.801H817L851,786.4z"
                />
                <path d="M481.5,921.5c0,11,9,20,20,20h295.6c11,0,20-9,20-20v-45.801H481.5V921.5z" />
            </g>
        </SvgIcon>
    );
}
