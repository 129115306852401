import Checkbox from '@material-ui/core/Checkbox';
import without from 'lodash/without';
import React, { useEffect, useState, useMemo } from 'react';
import { UiTableCellCheckbox } from '../components/ui/table/cell-checkbox/UiTableCellCheckbox';

export function useCheckboxes({ models = [], idMapper = (model) => model.id, enabledModelsFilter = false }) {
    const modelsLength = models.length;

    if (enabledModelsFilter) {
        models = models.filter(enabledModelsFilter);
    }

    const [checkedIds, setCheckedIds] = useState([]);
    const isAllChecked = checkedIds.length === models.length;
    const isIndeterminate = checkedIds.length > 0 && !isAllChecked;

    const CheckboxToggleAll =
        models.length > 0 ? (
            <Checkbox onChange={toggleCheckedAll} indeterminate={isIndeterminate} checked={isAllChecked} />
        ) : null;

    const CheckboxToggleAllCell =
        models.length > 0 ? (
            <UiTableCellCheckbox onChange={toggleCheckedAll} indeterminate={isIndeterminate} checked={isAllChecked} />
        ) : null;

    const checkedModels = useMemo(() => models.filter((model) => checkedIds.includes(idMapper(model))), [
        models.length,
        checkedIds,
    ]);

    useEffect(() => {
        setCheckedIds([]);
    }, [modelsLength]);

    function toggleChecked(id) {
        if (checkedIds.includes(id)) {
            setCheckedIds(without(checkedIds, id));
        } else {
            setCheckedIds([...checkedIds, id]);
        }
    }

    function toggleCheckedAll() {
        if (isAllChecked) {
            return setCheckedIds([]);
        }

        return setCheckedIds(models.map(idMapper));
    }

    function isChecked(id) {
        return checkedIds.includes(id);
    }

    function reset() {
        return setCheckedIds([]);
    }

    return {
        toggleCheckedAll,
        checkedIds,
        CheckboxToggleAll,
        CheckboxToggleAllCell,
        toggleChecked,
        isChecked,
        isAllChecked,
        setChecked(model) {
            const id = idMapper(model);

            if (!checkedIds.includes(id)) {
                setCheckedIds([...checkedIds, id]);
            }
        },
        setUnchecked(model) {
            setCheckedIds(without(checkedIds, idMapper(model)));
        },
        reset,
        checkedModels,
        getCheckbox(model, checkboxArgs) {
            return (
                <Checkbox
                    checked={isChecked(idMapper(model))}
                    onChange={() => toggleChecked(idMapper(model))}
                    {...checkboxArgs}
                />
            );
        },
    };
}
