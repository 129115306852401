import { getUrlToOrderX } from '../config';
import { httpGet } from '../shared/api';
import { publishToAwsIot } from '../providers/aws-iot';

export async function printPosReceipt({ orderId, venueId }) {
    await httpGet(getUrlToOrderX('employee/pos-receipts'), { orderId, venueId });
}

export function printPosTerminalOrderReceipt({ posFriendlyName, terminalId, orderId }) {
    return publishToAwsIot(`pos/print/${posFriendlyName}`, { id: terminalId, orderId });
}

export function scan({ code, codeType, orderId, nozzles }) {
    return httpGet(getUrlToOrderX('scan'), { code, codeType, orderId, nozzles });
}
