import { Entity, OneToOne, JoinColumn, Column } from './typeorm';
import { Location } from './location';
import { BaseModel } from './base-model';

@Entity()
export class OutletPlugin extends BaseModel {
    @OneToOne(() => Location, (model) => model.outletPlugin)
    @JoinColumn()
    location: Location;
    @Column()
    locationId: number;
}
