import React, { useEffect, useCallback, useState } from 'react';
import { UiBox, UiGrid, UiAlert } from '@orderx/ui';
import { useDeliveryView } from '../../../hooks/delivery/view';
import { DeliveryStationOrderItem } from '../../../components/delivery/station/order/item/DeliveryStationOrderItem';
import { useIotBarOrderUpdated } from '../../../hooks/iot/bar-order-updated';
import { DeliveryStationHeader } from '../../../components/delivery/station/header/DeliveryStationHeader';
import { useAwsIotSubscription } from '../../../providers/aws-iot';
import { useOnResume } from '../../../shared/browser';
import { useAudio } from '../../../@v2/hooks/audio';
import { DeliveryStationHistory } from '../../../components/delivery/station/history/DeliveryStationHistory';

export function DeliveryStationPage() {
    const { loadData, bars, orders, config, configCache, reset } = useDeliveryView();

    const reloadData = useCallback(() => {
        loadData();
    }, [config]);

    useEffect(() => {
        configCache();
        reloadData();
    }, [config, reloadData]);

    useEffect(() => reset, []);

    useOnResume(reloadData, [reloadData]);

    const [view, setView] = useState('ORDERS');

    return (
        <>
            <DeliveryStationHeader
                view={view}
                onChangeView={(viewTarget) => {
                    setView(viewTarget === view ? 'ORDERS' : viewTarget);
                }}
            />

            {view === 'ORDERS' && (
                <UiGrid>
                    {orders.length >= config.orderLimit && (
                        <UiBox padding={1}>
                            <UiAlert color="warning">Only displaying first {config.orderLimit} orders</UiAlert>
                        </UiBox>
                    )}

                    {orders.length === 0 && (
                        <UiBox padding={1}>
                            <UiAlert>No orders found</UiAlert>
                        </UiBox>
                    )}

                    <div>
                        {orders.map((order) => (
                            <DeliveryStationOrderItem order={order} key={order.id} />
                        ))}
                    </div>
                </UiGrid>
            )}

            {view === 'HISTORY' && <DeliveryStationHistory />}

            {bars.map((bar) => (
                <BarSubscription barId={bar.id} callback={reloadData} key={bar.id} />
            ))}
        </>
    );
}

DeliveryStationPage.route = '/delivery/station';

function BarSubscription({ barId, callback }) {
    const { playNewOrder } = useAudio();

    useAwsIotSubscription(`bars/${barId}/order-placed`, playNewOrder, []);
    useAwsIotSubscription(`order/staff/bars/${barId}/update`, callback, [callback]);
    useIotBarOrderUpdated(barId, callback, [callback]);
    return null;
}
