import React, { useState } from 'react';
import { printPosReceipt } from '../microservices/orderx';
import { showError, showSuccess } from '../shared/toasts';
import { useStaffActiveBar } from '../stores/staff-active-bar';
import { useStaffTerminalOrders } from '../stores/staff-terminal-orders';
import { UiButton } from '../@deprecated/UiButton';
import IconPrint from '@material-ui/icons/PrintRounded';

export default function PrintReceiptButton() {
    const { id: barId } = useStaffActiveBar();
    const [isPrinting, setIsPrinting] = useState(false);
    const { getOrdersByBarId } = useStaffTerminalOrders();
    const orders = getOrdersByBarId(barId);
    const order = orders[0];
    const canPrint = order && order.Id && order.Venue && order.Venue.id;

    if (!canPrint) {
        return null;
    }

    async function printReceipt(order) {
        setIsPrinting(true);

        try {
            await printPosReceipt({
                orderId: order.Id,
                venueId: order.Venue.id,
            });

            showSuccess('Printing receipt...');
        } catch (error) {
            showError(error, 'Failed to print receipt');
        }

        setIsPrinting(false);
    }

    return <UiButton onClick={() => printReceipt(orders[0])} isLoading={isPrinting} icon={<IconPrint />} />;
}
