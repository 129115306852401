import { Entity, ManyToOne, PrimaryColumn } from './typeorm';
import { User } from './user';
import { Location } from './location';

export enum LocationRoleValue {
    ADMIN = 'ADMIN',
    STAFF = 'STAFF',
}

@Entity()
export class LocationRole {
    @ManyToOne(() => User, (model) => model.locationRoles)
    user: User;
    @PrimaryColumn()
    userId: number;

    @ManyToOne(() => Location, (model) => model.userRoles)
    location: Location;
    @PrimaryColumn()
    locationId: number;

    @PrimaryColumn()
    value: LocationRoleValue;
}
