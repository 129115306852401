import React, { Fragment, useEffect } from 'react';
import styled, { css } from 'styled-components';
import { OrderStatus } from '../models/orders/order';
import { ProductStatus } from '../models/orders/product';
import { useOnWindowFocus } from '../shared/browser';
import { useNotification } from '../shared/push-notifications';
import { useStorage } from '../shared/storage';
import { translate } from '../shared/translate';
import { useOrdersPlaced } from '../stores/order-placed';
import { BackgroundCoverImage } from './BackgroundCoverImage';
import { Svg, SvgIcons } from './Svg';
import times from 'lodash/times';
import { useHistory } from 'react-router-dom';
import { useOrderBubble } from '../@v2/hooks/order-bubble';
import { useIotOrderUpdated } from '../hooks/iot/order-updated';
import { OrderIdPage } from '../@v2/pages/order/id/OrderIdPage';

const Wrapper = styled.div`
    position: fixed;
    top: 0.5rem;
    right: 0.5rem;
    z-index: var(--z-index-OrdersPlaced);

    ${times(10).map(
        (i) => css`
            a:nth-child(${i + 1}) {
                transform: translateY(${(props) => i * (props.isCompactMode ? 4 : 80)}px);
                z-index: ${10 - i};
            }
        `,
    )};
`;

const OrderItem = styled.a`
    background-color: var(--color-background-paper);
    box-shadow: var(--box-shadow);
    height: 80px;
    width: 80px;
    border-radius: 100%;
    overflow: hidden;
    transition: transform 300ms;

    position: absolute;
    top: 0;
    right: 0;

    display: flex;
    align-items: center;
    justify-content: center;

    .wrapper {
        background-position: center;
    }
`;

const Line = styled.div`
    height: 80px;
    width: 80px;
    border-radius: 100%;
    position: absolute;
    top: 0;
    left: 0;
    border: 2px solid ${(props) => (props.isDelivered && 'var(--color-success)') || 'transparent'};
    border-left-color: ${(props) =>
        (props.isDelivered && 'var(--color-success)') ||
        (props.isReady && 'var(--color-success)') ||
        (props.isPending && 'var(--color-yellow)') ||
        'transparent'};

    animation: spin 5s infinite;
    animation-timing-function: linear;

    .pulse {
        animation-duration: 3s;
    }
`;

const Text = styled.div`
    color: white;
    position: relative;
    text-transform: uppercase;
    text-align: center;
    font-size: 0.75rem;
    font-weight: bold;
    letter-spacing: 0.5px;
`;

export function OrdersPlaced() {
    const { isVisible } = useOrderBubble();
    const { push } = useHistory();
    const { orders, refresh } = useOrdersPlaced();
    const [isCompactMode, setIsCompactMode] = useStorage('orderCompactMode');
    const ordersFiltered = orders.filter((order) => order.status !== OrderStatus.PaymentRequired);

    useOnWindowFocus(() => {
        refresh();
    }, [refresh]);

    useEffect(() => {
        refresh();
    }, [refresh]);

    return (
        <>
            <Wrapper isCompactMode={isCompactMode}>
                {ordersFiltered.length > 1 && isVisible && (
                    <OrderItem onClick={() => setIsCompactMode(!isCompactMode)}>
                        <Svg icon={isCompactMode ? SvgIcons.UnfoldMore : SvgIcons.UnfoldLess} size={32} />
                    </OrderItem>
                )}

                {ordersFiltered.map((order, index) => (
                    <PlacedOrderCircle
                        key={order.id}
                        index={index}
                        order={order}
                        onSetOrder={() => {
                            push(OrderIdPage.route.replace(':orderId', order.id));
                        }}
                    />
                ))}
            </Wrapper>
        </>
    );
}

function PlacedOrderCircle({ order, onSetOrder }) {
    const { isVisible } = useOrderBubble();
    const { refresh } = useOrdersPlaced();
    const { id, locationImage: image } = order;

    const products = order.products || [];

    const isReady = products.find((product) => product.status === ProductStatus.Ready);
    const isDelivered =
        products.filter((product) => product.status === ProductStatus.Delivered).length === products.length;
    const isPending = !isReady && !isDelivered;

    useNotification('orders/order-updated', ({ data }) => {
        if (data && data.id === order.id && data.status === OrderStatus.Ready) {
            onSetOrder(order);
        }
    });

    useIotOrderUpdated(id, refresh);

    return (
        <Fragment>
            {isVisible && (
                <OrderItem onClick={() => onSetOrder(order)} className="OrderItem">
                    <BackgroundCoverImage image={image} overlayColor="rgba(0,0,0,0.5)" />
                    <Line isPending={isPending} isReady={isReady} isDelivered={isDelivered} />
                    <Text>
                        {translate('Tap to')}
                        <br />
                        {translate('open')}
                    </Text>
                </OrderItem>
            )}
        </Fragment>
    );
}
