import React, { Fragment } from 'react';
import times from 'lodash/times';
import { PosCreateMenuProduct } from './product/PosCreateMenuProduct';
import { UiGrid } from '@orderx/ui';
import { usePosMenu } from '../../../../hooks/pos-menu';

export function PosCreateMenu() {
    const { menuProductsFiltered } = usePosMenu();

    return (
        <UiGrid minMax={160} outerSpacing={1} spacing={1}>
            {times(1).map((i) => (
                <Fragment key={i}>
                    {menuProductsFiltered.map((menuProduct) => (
                        <PosCreateMenuProduct menuProduct={menuProduct} key={menuProduct.productId} />
                    ))}
                </Fragment>
            ))}
        </UiGrid>
    );
}
