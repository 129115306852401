import { createStore } from '@orderx/store';
import { useEffect } from 'react';

const { useStore } = createStore(true);

export function useOrderBubble() {
    const [isVisible, setIsVisible] = useStore();

    return {
        isVisible,
        setIsVisible,
        useTemporaryHide() {
            useEffect(() => {
                setIsVisible(false);
                return () => setIsVisible(true);
            }, []);
        },
    };
}
