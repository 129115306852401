import { Column, Entity, Index, OneToMany } from './typeorm';
import { LocationRole } from './location-role';
import { BaseModel } from './base-model';
import { InventoryPurchaseOrder } from './inventory-purchase-order';
import { InventoryAction } from './inventory-action';
import { InventoryCountingReport } from './inventory-counting-report';
import { InventoryCountingReportSummary } from './inventory-counting-report-summary';
import { CompanyRole } from './company-role';
import { Order } from './order';
import { Payment } from './payment';

@Entity()
export class User extends BaseModel {
    @Column({ nullable: true })
    @Index({ unique: true })
    username: string;

    @Column({ nullable: true, select: false })
    password: string;

    @Column({ nullable: true })
    @Index({ unique: true })
    awsCognitoUsernameApp: string;

    @Column({ nullable: true })
    @Index({ unique: true })
    awsCognitoUsernameAdmin: string;

    @Column({ nullable: true })
    firstName: string;

    @Column({ nullable: true })
    lastName: string;

    @Column({ nullable: true })
    @Index({ unique: true })
    email: string;

    @Column({ nullable: true })
    areaCode: string;

    @Column({ nullable: true })
    @Index({ unique: true })
    phone: string;

    @OneToMany(() => Payment, (model) => model.user)
    payments: Payment[];

    @OneToMany(() => CompanyRole, (model) => model.company)
    companyRoles: CompanyRole[];

    @OneToMany(() => LocationRole, (model) => model.user)
    locationRoles: LocationRole[];

    @OneToMany(() => Order, (model) => model.user)
    orders: Order[];

    @OneToMany(() => InventoryAction, (model) => model.user)
    inventoryActions: InventoryAction[];

    @OneToMany(() => InventoryPurchaseOrder, (model) => model.user)
    inventoryPurchaseOrders: InventoryPurchaseOrder[];

    @OneToMany(() => InventoryCountingReport, (model) => model.user)
    inventoryCountingReports: InventoryCountingReport[];

    @OneToMany(() => InventoryCountingReportSummary, (model) => model.user)
    inventoryCountingReportSummaries: InventoryCountingReportSummary[];
}
