import { Entity, Column, ManyToOne, OneToMany, BeforeInsert } from './typeorm';
import { BaseModel } from './base-model';
import { InventoryInventory } from './inventory-inventory';
import { User } from './user';
import { InventoryCountingReportProduct } from './inventory-counting-report-product';
import { InventoryCountingReportSummary } from './inventory-counting-report-summary';

@Entity()
export class InventoryCountingReport extends BaseModel {
    @Column()
    date: Date;

    @Column({ type: 'text', nullable: true })
    description: string;

    @OneToMany(() => InventoryCountingReportProduct, (model) => model.countingReport)
    products: InventoryCountingReportProduct[];

    @ManyToOne(() => User, (model) => model.inventoryCountingReports)
    user: User;
    @Column()
    userId: number;

    @ManyToOne(() => InventoryCountingReportSummary, (model) => model.countingReports)
    countingReportSummary: InventoryCountingReportSummary;
    @Column({ nullable: true })
    countingReportSummaryId: number;

    @ManyToOne(() => InventoryInventory, (model) => model.countingReports)
    inventory: InventoryInventory;
    @Column()
    inventoryId: number;
}
