import { createStore, useStore, getStoreValue } from '../create-store';
import { Log } from '../util/logger';
import { storageGet } from './storage';

const store = createStore({
    isNative: false,
    isPwa: false,
    isAndroid: false,
    isIos: false,
    platform: '',
    model: '',
    appVersion: undefined,
    id: storageGet('deviceId') || '',
    needsExternalKeyboard: false,
});

export function initDevice() {
    store.set({
        isNative: isNative(),
        isPwa: !isNative(),
        isAndroid: isAndroid(),
        isIos: isIos(),
        platform: isNative() && window.device.platform.toLowerCase(),
        model: isNative() && window.device.model,
        appVersion: undefined,
        id: storageGet('deviceId') || '',
        needsExternalKeyboard: Boolean(storageGet('needsExternalKeyboard')) || false,
    });
}

export function getDeviceId() {
    return getStoreValue(store).id;
}

export function useDevice() {
    const [{ isNative, isPwa, isAndroid, isIos, platform, model, appVersion, needsExternalKeyboard }] = useStore(store);
    return { isNative, isPwa, isAndroid, isIos, platform, model, appVersion, needsExternalKeyboard };
}

export function setAppVersion(version) {
    store.set(state => {
        state.appVersion = version;
    });
}

export function isNative() {
    return !!window.cordova;
}

export function isIos() {
    return isNative() && window.device.platform.toLowerCase() === 'ios';
}

export function isAndroid() {
    return isNative() && window.device.platform.toLowerCase() === 'android';
}

export function updateDeviceInfo() {
    if (!isNative()) {
        return Log.dev('device', 'Cannot get device info on a non-native device');
    }

    const { model, platform } = window.device;

    const devicePlatform = platform.toLowerCase();

    document.querySelector('html').classList.add(devicePlatform);

    store.set(state => {
        state.model = model;
        state.platform = devicePlatform;
    });
}

export async function loadAppVersion() {
    if (!isNative()) {
        return;
    }

    const version = await window.cordova.getAppVersion.getVersionNumber();

    setAppVersion(version);
}
