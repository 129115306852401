import keyBy from 'lodash/keyBy';
import times from 'lodash/times';
import without from 'lodash/without';
import sum from 'lodash/sum';
import { useEffect, useState } from 'react';
import { BehaviorSubject } from 'rxjs';
import { ProductConverter } from '../util/product-converter';

function getInitialState() {
    return {
        lastProductIdEdited: '',
        productCountById: {},
        orderProducts: [],
        totalPriceIncludingVat: 0,
        hasProducts: false,
        reset: true,
    };
}

const subject = (window.createOrderState = new BehaviorSubject(getInitialState()));

export const CreateOrder = {
    addProducts(product, count) {
        let { orderProducts, productCountById } = subject.value;

        const products = times(count).map(() => ({ ...product, key: Math.random() }));
        productCountById[product.id] = (productCountById[product.id] || 0) + count;
        orderProducts.push(...ProductConverter.convertLocationProductsToOrderProducts(products));

        subject.next({
            lastProductIdEdited: product.id,
            orderProducts,
            productCountById,
            reset: false,
            ...CreateOrder.calculateTotalPriceIncludingVat(orderProducts),
        });
    },

    removeProducts(product, count) {
        let { orderProducts, productCountById } = subject.value;

        times(count).forEach(() => {
            orderProducts = without(
                orderProducts,
                orderProducts.find(({ locationProductId }) => locationProductId === product.id),
            );
        });

        productCountById[product.id] = (productCountById[product.id] || 0) - count;

        subject.next({
            lastProductIdEdited: product.id,
            orderProducts,
            productCountById,
            reset: false,
            ...CreateOrder.calculateTotalPriceIncludingVat(orderProducts),
        });
    },

    removeProductById(productId) {
        let { orderProducts, productCountById } = subject.value;

        const product = orderProducts.find(({ id }) => id === productId);
        const { locationProductId } = product;
        orderProducts = without(orderProducts, product);
        productCountById[locationProductId] = (productCountById[locationProductId] || 0) - 1;

        subject.next({
            lastProductIdEdited: product.locationProductId,
            orderProducts,
            productCountById,
            ...CreateOrder.calculateTotalPriceIncludingVat(orderProducts),
        });
    },

    updateOrderProducts(productsToUpdate) {
        let { orderProducts } = subject.value;

        const productById = keyBy(productsToUpdate, product => product.id);

        subject.next({
            ...subject.value,
            orderProducts: orderProducts.map(product => productById[product.id] || product),
        });
    },

    calculateTotalPriceIncludingVat(products) {
        return {
            hasProducts: products.length > 0,
            totalPriceIncludingVat: sum(products.map(product => product.priceIncludingVat)),
        };
    },

    reset() {
        subject.next(getInitialState());
    },

    useState() {
        const [state, setState] = useState(subject.value);

        useEffect(() => {
            const subscription = subject.subscribe(value => {
                setState(value);
            });

            return () => subscription.unsubscribe();
        }, []);

        return state;
    },

    useStateProductId(locationProductId) {
        const [state, setState] = useState(subject.value);

        useEffect(() => {
            const subscription = subject.subscribe(value => {
                if (value.lastProductIdEdited === locationProductId || value.reset) {
                    setState(value);
                }
            });

            return () => subscription.unsubscribe();
        }, []);

        return state;
    },
};
