import React from 'react';
import styled from 'styled-components';
import { themeActive } from '../themes/dark';

const Wrapper = styled.span`
    display: ${props => (props.block ? 'block' : 'inline-block')};
    color: ${props =>
        (props.faded && themeActive.colors.faded) ||
        (props.warning && themeActive.colors.yellow) ||
        (props.color === 'success' && themeActive.colors.success) ||
        (props.color === 'warning' && themeActive.colors.yellow) ||
        'inherit'};
    font-size: ${props => (props.small ? 0.75 : 1)}rem;
    font-weight: ${props => (props.bold ? 700 : 400)};
    letter-spacing: 0.25px;
    text-align: ${props => (props.center ? 'center' : 'left')};
    text-transform: ${props => (props.uppercase ? 'uppercase' : 'initial')};
`;

export function Text({
    children,
    faded = false,
    small = false,
    block = false,
    center = false,
    uppercase = false,
    bold = false,
    warning = false,
    color,
}) {
    return (
        <Wrapper
            faded={faded}
            small={small}
            block={block}
            center={center}
            uppercase={uppercase}
            bold={bold}
            color={color}
            warning={warning}>
            {children}
        </Wrapper>
    );
}
