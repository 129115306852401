import { format } from 'date-fns';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { ActionSheet, useActionSheet } from '../components/ActionSheet';
import { Button } from '../components/Button';
import { ButtonGroup } from '../components/ButtonGroup';
import { Flex } from '../components/Flex';
import Pill from '../components/Pill';
import Pills from '../components/Pills';
import { Svg } from '../components/Svg';
import { Text } from '../components/Text';
import { TimeLeft } from '../components/TimeLeft';
import { V2Grid } from '../components/v2/V2Grid';
import { V2List } from '../components/v2/V2List';
import { V2ListItem } from '../components/v2/V2ListItem';
import {
    cancelLocationQueueItem,
    getLocationQueue,
    sendLocationQueueInvite,
    setUserAsNext,
} from '../microservices/bookx';
import { QueueStatus, QueueStatusLabel } from '../models/queue';
import { showError, showSuccess } from '../shared/toasts';
import { useBookXLocation } from '../stores/bookx-location';
import { themeActive } from '../themes/dark';
import { useInterval } from '../use/interval';

const QueueItemTitle = styled.h3`
    text-align: center;
`;

const FlexGrid = styled.div`
    > * {
        padding: 0.5rem;
    }
    > *:not(:first-child) {
        border-top: 1px solid ${themeActive.colors.border};
    }
`;

export function StaffQueueLocationQueue() {
    const location = useBookXLocation();
    const locationId = location.id;
    const actionSheetQueue = useActionSheet();
    const [status, setStatus] = useState(QueueStatus.NotSent);
    const [isLoadingById, setIsLoadingById] = useState({});
    const [queueByStatus, setQueueByStatus] = useState({});
    const [queueOpen, setQueueOpen] = useState();

    useEffect(() => {
        if (queueOpen) {
            actionSheetQueue.open();
        } else {
            actionSheetQueue.close();
        }
    }, [queueOpen]);

    useInterval(() => {
        loadQueue();
    }, 5000);

    useEffect(() => {
        loadQueue();
    }, [status]);

    const queue = queueByStatus[status] || [];

    const statusTabs = Object.values(QueueStatus).map(status => ({
        label: QueueStatusLabel[status],
        value: status,
    }));

    async function cancel(queueId) {
        setIsLoadingById(isLoadingById => ({ ...isLoadingById, [locationId]: true }));

        try {
            await cancelLocationQueueItem(locationId, queueId);
            await loadQueue();
            showSuccess('Cancelled user');
        } catch (error) {
            showError(error, 'Failed');
        }

        setIsLoadingById(isLoadingById => ({ ...isLoadingById, [locationId]: false }));
    }

    async function sendInvite(queueId) {
        setIsLoadingById(isLoadingById => ({ ...isLoadingById, [locationId]: true }));

        try {
            await sendLocationQueueInvite(locationId, queueId);
            await loadQueue();
            showSuccess('Invite sent');
        } catch (error) {
            showError(error);
        }

        setIsLoadingById(isLoadingById => ({ ...isLoadingById, [locationId]: false }));
    }

    async function setAsNextInLine(queueId) {
        setIsLoadingById(isLoadingById => ({ ...isLoadingById, [locationId]: true }));

        try {
            await setUserAsNext(locationId, queueId);
            await loadQueue();
            showSuccess('Moved to first in line');
        } catch (error) {
            showError(error);
        }

        setIsLoadingById(isLoadingById => ({ ...isLoadingById, [locationId]: false }));
    }

    async function loadQueue() {
        try {
            const queue = await getLocationQueue(locationId, {
                status,
                orderBy: 'sequence',
                orderByDirection: [QueueStatus.Expired, QueueStatus.CancelledByStaff].includes(status) ? 'DESC' : 'ASC',
            });

            setQueueByStatus(queueByStatus => ({
                ...queueByStatus,
                [status]: queue,
            }));
        } catch (error) {
            showError(error);
        }
    }

    return (
        <div>
            <Pills>
                {statusTabs.map(tab => (
                    <Pill onClick={() => setStatus(tab.value)} key={tab.value} active={tab.value === status}>
                        {tab.label}
                    </Pill>
                ))}
            </Pills>

            <V2List title="Queue">
                {queue.length === 0 && <V2ListItem label="No results found" />}

                {queue.map(({ id, name, status, numberOfPeople, areaCode, phone, expiresAt }, index) => (
                    <V2ListItem
                        label={name || '-'}
                        subtitle={
                            <>
                                <div>
                                    People: {numberOfPeople}
                                    {status === QueueStatus.Sent && (
                                        <>
                                            , Expires: <TimeLeft expiresAt={expiresAt} />
                                        </>
                                    )}
                                </div>
                            </>
                        }
                        contentRight={
                            <Flex centerY>
                                {status === QueueStatus.NotSent && (
                                    <Button onClick={() => sendInvite(id)} isLoading={isLoadingById[locationId]}>
                                        Invite
                                    </Button>
                                )}

                                <Button
                                    onClick={() => setQueueOpen(queue[index])}
                                    isLoading={isLoadingById[locationId]}>
                                    <Svg icon="more" size={20} />
                                </Button>
                            </Flex>
                        }
                        compact
                        key={id}
                    />
                ))}
            </V2List>

            {actionSheetQueue.isOpen && queueOpen && (
                <ActionSheet {...actionSheetQueue}>
                    <V2Grid>
                        <QueueItemTitle>{queueOpen.name}</QueueItemTitle>

                        <FlexGrid>
                            <Flex spaceBetween>
                                <Text block center>
                                    People
                                </Text>

                                <Text block center>
                                    {queueOpen.numberOfPeople}
                                </Text>
                            </Flex>

                            <Flex spaceBetween>
                                <Text block center>
                                    Registered at
                                </Text>

                                <Text block center>
                                    {format(queueOpen.sequence, 'HH:mm')}
                                </Text>
                            </Flex>

                            {status === QueueStatus.Sent && queueOpen.expiresAt && (
                                <>
                                    <Flex spaceBetween>
                                        <Text block center>
                                            Invite sent at
                                        </Text>

                                        <Text block center>
                                            {format(queueOpen.inviteSentAt, 'HH:mm')}
                                        </Text>
                                    </Flex>

                                    <Flex spaceBetween>
                                        <Text block center>
                                            Expires at
                                        </Text>

                                        <Text block center>
                                            {format(queueOpen.expiresAt, 'HH:mm')}
                                        </Text>
                                    </Flex>

                                    <Flex spaceBetween>
                                        <Text block center>
                                            Expires in
                                        </Text>

                                        <Text block center>
                                            <TimeLeft expiresAt={queueOpen.expiresAt} />
                                        </Text>
                                    </Flex>
                                </>
                            )}
                        </FlexGrid>

                        <ButtonGroup vertical>
                            {[QueueStatus.NotSent, QueueStatus.Expired, QueueStatus.CancelledByStaff].includes(
                                status,
                            ) && (
                                <Button
                                    onClick={() => setAsNextInLine(queueOpen.id)}
                                    isLoading={isLoadingById[queueOpen.id]}
                                    block>
                                    Move to first in queue
                                </Button>
                            )}

                            {[QueueStatus.Sent, QueueStatus.NotSent].includes(status) && (
                                <Button
                                    onClick={() => cancel(queueOpen.id)}
                                    isLoading={isLoadingById[queueOpen.id]}
                                    block
                                    negative>
                                    Remove user from queue
                                </Button>
                            )}

                            <Button onClick={actionSheetQueue.close} block>
                                Close
                            </Button>
                        </ButtonGroup>
                    </V2Grid>
                </ActionSheet>
            )}
        </div>
    );
}
