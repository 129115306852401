import times from 'lodash/times';
import React, { useEffect, useState } from 'react';
import { Route, withRouter } from 'react-router-dom';
import { NavLink } from 'react-router-dom';
import styled, { css } from 'styled-components';
import { BottomOverlay } from '../components/BottomOverlay';
import { BottomTab } from '../components/BottomTab';
import { BottomTabs } from '../components/BottomTabs';
import { Dev } from '../components/Dev';
import { Flex } from '../components/Flex';
import { StaffBarNewOrders } from '../components/StaffBarNewOrders';
import { StaffBarTerminalSubscriptions } from '../components/StaffBarTerminalSubscriptions';
import { Svg } from '../components/Svg';
import { postStaffActiveBarAction } from '../microservices/device-actions';
import { loadStaffBarById, loadVenueById } from '../microservices/legacy-app';
import { registerToBarTerminal, registerToVenueBar, unregisterFromVenueBar } from '../microservices/staff';
import { useAwsIotSubscription } from '../providers/aws-iot';
import { useOnWindowFocus } from '../shared/browser';
import { routes, withParams } from '../shared/router';
import { storageRemove, storageSet } from '../shared/storage';
import { mixins } from '../shared/styles';
import { translate } from '../shared/translate';
import { useLocationTerminalData } from '../stores/location-terminal-orders';
import { useLocationTerminals } from '../stores/location-terminals';
import { useStaffActiveBar } from '../stores/staff-active-bar';
import { useStaffBarNewOrderIndicator } from '../stores/staff-bar-new-order-indicator';
import StaffBarBalanceGroups from './StaffBarBalanceGroups';
import { StaffBarOrders } from './StaffBarOrders';
import { StaffBarOrdersCreate } from './StaffBarOrdersCreate';
import StaffBarScan from './StaffBarScan';
import StaffBarSettings from './StaffBarSettings';
import StaffBarSwish from './StaffBarSwish';

const MenuWrapper = styled.div`
    ${mixins.flexXY};
    align-items: flex-end;
    height: 100vh;
    padding-bottom: 48px;

    a {
        color: ${props => props.theme.colors.text};
        text-decoration: none;
    }
`;

const MenuContent = styled.div`
    .link {
        padding: 16px 48px;

        ${times(5).map(
            i => css`
                :nth-child(${i + 1}) {
                    animation-delay: ${i * 50}ms;
                }
            `,
        )};

        &.active {
            background-image: ${props => props.theme.gradients.primary};
            border-radius: ${props => props.theme.sizes.borderRadius}px;
        }
    }

    .icon-wrapper {
        max-width: 24px;
    }
`;

function StaffBar() {
    const [menuOpen, setMenuOpen] = useState(false);
    const [menuVisible, setMenuVisible] = useState(false);

    const bar = useStaffActiveBar();
    const barId = bar.id;
    const venueId = bar.venueId;

    const terminals = useLocationTerminals(barId);
    const { terminalTappedCountById } = useLocationTerminalData(barId);
    const tappedTerminals = terminals.filter(terminal => terminalTappedCountById[terminal.id]);
    const { isVisible } = useStaffBarNewOrderIndicator();

    useEffect(() => {
        storageSet('staffLastBarId', barId);
        return () => storageRemove('staffLastBarId');
    }, []);

    useAwsIotSubscription(`location/${barId}/publish`, () => {
        reloadBarData();
    });

    useAwsIotSubscription(`location/${barId}/products-updated`, () => {
        loadStaffBarById(barId);
    });

    useEffect(() => {
        registerToBarTerminal(barId, tappedTerminals.map(terminal => terminal.id));
        return () => registerToBarTerminal(barId, []);
    }, tappedTerminals);

    useEffect(() => {
        registerToVenueBar(venueId, barId);
        return () => unregisterFromVenueBar(venueId, barId);
    }, [barId]);

    useOnWindowFocus(() => {
        reloadBarData();
    });

    const withBarId = route => withParams(route, { barId });

    function reloadBarData() {
        loadVenueById(venueId);
        loadStaffBarById(barId);
    }

    function closeMenu() {
        setMenuOpen(false);
        setMenuVisible(false);
    }

    function hideMenu() {
        setMenuVisible(false);
    }

    const linkClass = 'link animated fadeInUp';

    const tabRoutes = [
        {
            label: translate('Orders'),
            path: routes.staffBarOrder,
            component: StaffBarOrders,
            icon: 'receipt',
            isTab: true,
        },
        {
            label: translate('Scan'),
            path: routes.staffBarScan,
            component: StaffBarScan,
            icon: 'scan',
            isTab: true,
        },
        {
            path: routes.staffBarSettings,
            component: StaffBarSettings,
            isTab: false,
        },
        {
            path: routes.staffBarSwish,
            component: StaffBarSwish,
            isTab: false,
        },
        {
            path: routes.staffBarBalanceGroups,
            component: StaffBarBalanceGroups,
            isTab: false,
        },
        {
            label: translate('Menu'),
            onClick: () => {
                setMenuOpen(true);
                setMenuVisible(true);
            },
            icon: 'blocks',
            isTab: true,
        },
    ].filter(Boolean);

    return (
        <>
            {isVisible && <StaffBarNewOrders />}
            <StaffBarTerminalSubscriptions />

            <Route path={routes.staffBarOrder} component={StaffBarOrders} />
            <Route path={routes.staffBarOrderCreate} component={StaffBarOrdersCreate} />
            <Route path={routes.staffBarScan} component={StaffBarScan} />
            <Route path={routes.staffBarSettings} component={StaffBarSettings} />
            <Route path={routes.staffBarBalanceGroups} component={StaffBarBalanceGroups} />
            <Route path={routes.staffBarSwish} component={StaffBarSwish} />

            <BottomTabs>
                {tabRoutes
                    .filter(route => route.isTab)
                    .map(route => (
                        <BottomTab
                            key={route.label}
                            icon={route.icon}
                            to={route.path && withBarId(route.path)}
                            label={route.label}
                            onClick={route.onClick}
                        />
                    ))}
            </BottomTabs>

            {menuOpen && (
                <BottomOverlay hasOverlay onClose={closeMenu} visible={menuVisible} overlayOpacity={0.8}>
                    <MenuWrapper onClick={hideMenu}>
                        <MenuContent>
                            <Flex vertical>
                                <Dev>
                                    {bar.swishPos.length > 0 && (
                                        <NavLink
                                            to={withBarId(routes.staffBarSwish)}
                                            onClick={hideMenu}
                                            className={linkClass}>
                                            <Flex centerY>
                                                <Svg icon="qr" size={24} className="icon-wrapper" />
                                                <span>Swish</span>
                                            </Flex>
                                        </NavLink>
                                    )}
                                </Dev>

                                <NavLink
                                    to={withBarId(routes.staffBarBalanceGroups)}
                                    onClick={hideMenu}
                                    className={linkClass}>
                                    <Flex centerY>
                                        <Svg icon="people" size={24} className="icon-wrapper" />
                                        <span>Balance groups</span>
                                    </Flex>
                                </NavLink>

                                <NavLink to={withBarId(routes.staffBarOrder)} onClick={hideMenu} className={linkClass}>
                                    <Flex centerY>
                                        <Svg icon="receipt" className="icon-wrapper" />
                                        <span>{translate('Terminal orders')}</span>
                                    </Flex>
                                </NavLink>

                                <NavLink to={withBarId(routes.staffBarScan)} onClick={hideMenu} className={linkClass}>
                                    <Flex centerY>
                                        <Svg icon="scan" className="icon-wrapper" />
                                        <span>Scan</span>
                                    </Flex>
                                </NavLink>

                                <NavLink
                                    to={withBarId(routes.staffBarSettings)}
                                    onClick={hideMenu}
                                    className={linkClass}>
                                    <Flex centerY>
                                        <Svg icon="settings" className="icon-wrapper" />
                                        <span>Settings</span>
                                    </Flex>
                                </NavLink>

                                <NavLink
                                    to={routes.customerProfile}
                                    onClick={() => {
                                        hideMenu();
                                        postStaffActiveBarAction('staff_exit');
                                    }}
                                    className={linkClass}>
                                    <Flex centerY>
                                        <Svg icon="exit" className="icon-wrapper" />
                                        <span>Exit staff</span>
                                    </Flex>
                                </NavLink>
                            </Flex>
                        </MenuContent>
                    </MenuWrapper>
                </BottomOverlay>
            )}
        </>
    );
}

export default withRouter(StaffBar);
