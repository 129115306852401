import { Event, ModalCheckout } from '@bambora/checkout-sdk-web';

export const Bambora = {
    Event,
    openModalWithToken(token) {
        return new Promise((resolve, reject) => {
            const options = {};

            const checkout = new ModalCheckout(token, options);

            checkout.initialize(token);

            let authorizeData;
            let isAuthorized = false;
            let isResolved = false;

            checkout.on(Event.Authorize, (payload) => {
                const { txnid, orderid, reference } = payload.data;

                authorizeData = {
                    transactionId: txnid,
                    orderId: orderid,
                    reference,
                };

                resolve(authorizeData);
                isAuthorized = true;
                isResolved = true;
            });

            checkout.on(Event.Close, () => {
                if (isResolved) {
                    return;
                }

                if (isAuthorized) {
                    resolve(authorizeData);
                } else {
                    reject({ event: Event.Cancel });
                }
            });

            checkout.on(Event.Cancel, () => {
                if (!isResolved) {
                    reject({ event: Event.Cancel });
                }
            });

            checkout.show();
        });
    },
};
