import makeStyles from '@material-ui/core/styles/makeStyles';
import React from 'react';

const useStyles = makeStyles({
    InventoryUnitLabel: {},
});

export function InventoryUnitLabel({ amount, inventoryProduct, packagingUnit, unit, unitAmount }) {
    const classes = useStyles();
    unitAmount = (unitAmount && Number(unitAmount)) || 1;

    if (inventoryProduct) {
        packagingUnit = inventoryProduct.inventoryPackagingUnit;
        unit = inventoryProduct.inventoryUnit;
        unitAmount = inventoryProduct.inventoryUnitAmount || 1;
    }

    const majorUnits = Math.floor(amount / unitAmount);
    const subUnits = unitAmount ? amount % unitAmount : 0;

    if (amount < 0) {
        return (
            <>
                {amount}
                {unit || ''}
            </>
        );
    }

    return (
        <span className={classes.InventoryUnitLabel}>
            {majorUnits > 0 && (
                <>
                    {majorUnits}&nbsp;{packagingUnit || ''}
                </>
            )}

            {subUnits > 0 && (
                <>
                    {majorUnits > 0 && <>{' +'}</>}
                    {subUnits}&nbsp;{unit || ''}
                </>
            )}

            {majorUnits === 0 && subUnits === 0 ? 0 : ''}
        </span>
    );
}
