import React from 'react';
import styled from 'styled-components';
import { OrderStatus } from '../models/orders/order';
import { ORDER_STATUS } from '../shared/orders';
import { translate } from '../shared/translate';

const Label = styled.label`
    color: ${({ status, withoutColor, theme }) => {
        if (withoutColor) {
            return 'inherit';
        }

        switch (status) {
            default:
            case ORDER_STATUS.INITIALIZED:
            case ORDER_STATUS.SENT_TO_BOTTOMS_UP_UNIT:
                return theme.colors.text;
            case ORDER_STATUS.PAYMENT_WAITING:
                return theme.colors.yellow;
            case ORDER_STATUS.PAYMENT_FAILED:
                return theme.colors.red;
            case ORDER_STATUS.PAYMENT_SUCCESS:
            case ORDER_STATUS.FINALIZED:
                return theme.colors.green;
        }
    }};
`;

export function OrderStatusLabel({ status, withoutColor = false }) {
    const labels = {
        [ORDER_STATUS.INITIALIZED]: 'Initialized',
        [ORDER_STATUS.PAYMENT_WAITING]: 'Waiting payment',
        [ORDER_STATUS.PAYMENT_FAILED]: 'Payment failed',
        [ORDER_STATUS.PAYMENT_SUCCESS]: 'Paid',
        [ORDER_STATUS.FINALIZED]: 'Done',
        [ORDER_STATUS.SENT_TO_BOTTOMS_UP_UNIT]: 'Sent to Bottoms-up',

        [OrderStatus.PaymentRequired]: translate('Payment required'),
        [OrderStatus.Placed]: translate('Needs action'),
        [OrderStatus.InPreparation]: translate('In preparation'),
        [OrderStatus.InDelivery]: translate('In delivery'),
        [OrderStatus.Delivered]: translate('Delivered'),
        [OrderStatus.Ready]: translate('Ready'),
        [OrderStatus.Rejected]: translate('Rejected'),

        [OrderStatus.Open]: translate('Open'),
        [OrderStatus.Closed]: translate('Closed'),
        [OrderStatus.Archived]: translate('Archived'),
    };

    return (
        <Label withoutColor={withoutColor} status={status}>
            {labels[status] || '?'}
        </Label>
    );
}
