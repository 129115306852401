import IconResetOrder from '@material-ui/icons/DeleteForeverRounded';
import React from 'react';
import { CreateOrder } from '../../modules/create-order';
import { useCreateOrderBar } from '../../stores/create-order-bar';
import { resetOrderProductState } from '../../stores/create-order-products';
import { CurrencyMinor } from '../CurrencyMinor';
import { UiButton } from '../../@deprecated/UiButton';
import { UiCard, UiFlex } from '@orderx/ui';
import './StaffCreateProductSummary.scss';
import clsx from 'clsx';

export function StaffCreateProductSummary() {
    const { currency, setCategory } = useCreateOrderBar();
    const { totalPriceIncludingVat, hasProducts } = CreateOrder.useState();

    return (
        <UiCard
            className={clsx('StaffCreateProductSummary', hasProducts && 'active')}
            paddingLeft={1}
            onClick={() => {
                if (hasProducts) {
                    setCategory('_ACTIVE');
                }
            }}>
            <UiFlex spacing={1}>
                <UiButton
                    onClick={resetOrderProductState}
                    icon={<IconResetOrder />}
                    disabled={totalPriceIncludingVat === 0}
                />

                <b>
                    {'Total order cost: '}
                    <span>
                        <CurrencyMinor amount={totalPriceIncludingVat} currency={currency} />
                    </span>
                </b>
            </UiFlex>
        </UiCard>
    );
}
