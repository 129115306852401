import { useLocation, useParams, useHistory } from 'react-router-dom';
import { parse } from 'query-string';

export function useRouter() {
    const location = useLocation();
    const query = parse(location.search);
    const params = useParams<any>();
    const history = useHistory();

    function replaceParams(path, object = {}) {
        const $params = { ...params, ...object };

        return Object.keys($params || {}).reduce(
            (path = '', key) => path.replace(`:${key}`, $params?.[key] || key),
            path,
        );
    }

    return {
        location,
        query,
        params,
        history,
        push(routeOrPage, params?) {
            history.push(replaceParams(routeOrPage.route || routeOrPage, params));
        },
        isPathActive(path: string) {
            return location.pathname.startsWith(path);
        },
        replaceParams,
    };
}
