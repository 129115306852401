import { Column, Entity, OneToMany } from './typeorm';
import { BaseModel } from './base-model';
import { Location } from './location';
import { CompanyRole } from './company-role';

@Entity()
export class Company extends BaseModel {
    @Column()
    name: string;

    @OneToMany(() => Location, (model) => model.company)
    locations: Location[];

    @OneToMany(() => CompanyRole, (model) => model.company)
    userRoles: CompanyRole[];
}
