import React, { useEffect } from 'react';
import { MicroserviceOrders } from '../microservices/orders';
import { toMoment } from '../shared/moment';
import { translate } from '../shared/translate';
import { useApiQuery } from '@orderx/http';
import { CurrencyMinor } from './CurrencyMinor';
import { UiLoader } from './ui/UiLoader';
import { UiToolbar, UiIconMaterial, UiGrid, UiTable, UiText, UiButton, UiBox, UiOverlay } from '@orderx/ui';
import { ToolbarTitle } from '../@v2/components/toolbar/title/ToolbarTitle';

export function OrderGroupPaymentReceiptDialog({ payment, isOpen, onClose }) {
    const { response, run } = useApiQuery(() => MicroserviceOrders.findReceipt({ paymentId: payment.id }));

    useEffect(() => {
        if (isOpen) {
            run();
        }
    }, [isOpen]);

    if (isOpen) {
        return (
            <UiOverlay>
                <UiToolbar>
                    <ToolbarTitle title="Receipt" />

                    <UiButton onClick={() => onClose(false)} icon={<UiIconMaterial>close</UiIconMaterial>} size={2} />
                </UiToolbar>

                {response ? (
                    <div>
                        <UiBox padding={2}>
                            <UiText>
                                <h3>{response.barName}</h3>
                                <UiText color="text-secondary">
                                    {toMoment(response.date).format('DD/MM/YYYY HH:mm')}
                                </UiText>
                            </UiText>
                        </UiBox>

                        <UiGrid>
                            <UiTable>
                                <thead>
                                    <tr>
                                        <td>{translate('Items')}</td>

                                        <td />
                                    </tr>
                                </thead>

                                <tbody>
                                    {response.products.map((product) => (
                                        <tr key={product.id}>
                                            <td>
                                                {product.name}{' '}
                                                {product.count > 1 && (
                                                    <>
                                                        ({product.count} x{' '}
                                                        <CurrencyMinor
                                                            amount={product.priceIncludingVat}
                                                            currency={product.currency}
                                                        />
                                                        )
                                                    </>
                                                )}
                                            </td>

                                            <td align="right">
                                                <CurrencyMinor
                                                    amount={product.priceTotal}
                                                    currency={product.currency}
                                                />
                                            </td>
                                        </tr>
                                    ))}

                                    <tr>
                                        <td>
                                            <b>{translate('Total:')}</b>
                                        </td>

                                        <td align="right">
                                            <b>
                                                <CurrencyMinor
                                                    amount={response.amountTotal}
                                                    currency={response.currency}
                                                />
                                            </b>
                                        </td>
                                    </tr>

                                    {response.vats.map(({ vat, amount }) => (
                                        <tr key={vat}>
                                            <td>
                                                <b>
                                                    {translate('VAT')} {vat}%:
                                                </b>
                                            </td>

                                            <td align="right">
                                                <b>
                                                    <CurrencyMinor amount={amount} currency={response.currency} />
                                                </b>
                                            </td>
                                        </tr>
                                    ))}

                                    <tr>
                                        <td>
                                            <b>{translate('Total VAT')}:</b>
                                        </td>

                                        <td align="right">
                                            <b>
                                                <CurrencyMinor
                                                    amount={response.vatTotal}
                                                    currency={response.currency}
                                                />
                                            </b>
                                        </td>
                                    </tr>
                                </tbody>
                            </UiTable>

                            <UiTable>
                                <tbody>
                                    <tr>
                                        <td>
                                            <UiText weight={2} color="text-secondary">
                                                {translate('Sold by')}
                                            </UiText>
                                        </td>
                                    </tr>

                                    <tr>
                                        <td>
                                            <div>
                                                {response.legalCompanyName} ({response.legalRegistrationNumber})
                                            </div>

                                            <div>{response.legalLocation.join(', ')}</div>
                                        </td>
                                    </tr>
                                </tbody>
                            </UiTable>
                        </UiGrid>
                    </div>
                ) : (
                    <UiLoader />
                )}
            </UiOverlay>
        );
    }

    return null;
}
