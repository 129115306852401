import { Entity, Column, ManyToOne, PrimaryColumn } from './typeorm';
import { InventoryRecipe } from './inventory-recipe';
import { InventoryProduct } from './inventory-product';

@Entity()
export class InventoryRecipeProduct {
    @ManyToOne(() => InventoryRecipe, (model) => model.recipeProducts)
    recipe: InventoryRecipe;
    @PrimaryColumn()
    recipeId: number;

    @ManyToOne(() => InventoryProduct, (model) => model.recipeProducts)
    product: InventoryProduct;
    @PrimaryColumn()
    productId: number;

    @Column()
    unitAmount: number;
}
