import LinearProgress from '@material-ui/core/LinearProgress';
import React from 'react';
import styled from 'styled-components';

const Wrapper = styled.div`
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
`;

export function ProgressBar() {
    return (
        <Wrapper className="animated fadeIn">
            <LinearProgress />
        </Wrapper>
    );
}
