import { getApiUrl } from '../config';
import { httpPost } from '../shared/api';

const getUrl = getApiUrl('payments');

export async function getSwishQrAsBase64({ token }) {
    return httpPost(getUrl('swish/qr'), { token });
}

// TODO: @DEPRECATED
export async function topup({ balanceId, venueId }) {
    return httpPost(getUrl('bambora/topup'), {
        balanceId,
        venueId,
        urlAccept: window.location.href,
        urlCancel: window.location.href,
    });
}
