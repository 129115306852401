import makeStyles from '@material-ui/core/styles/makeStyles';
import React from 'react';
import { useStaffActiveBar } from '../../stores/staff-active-bar';
import { useStaffTerminalOrders } from '../../stores/staff-terminal-orders';
import OrderListItem from '../OrderListItem';
import { Table } from '../Table';

const useStyles = makeStyles({
    StaffOrderViewPreviousOrders: {
        paddingLeft: 16,
        paddingRight: 16,
    },
});

export function StaffOrderViewPreviousOrders() {
    const classes = useStyles();
    const { id: barId } = useStaffActiveBar();
    const { getOrdersByBarId } = useStaffTerminalOrders();
    const orders = getOrdersByBarId(barId);

    return (
        <div className={classes.StaffOrderViewPreviousOrders}>
            <Table>
                <thead>
                    <tr>
                        <td />
                        <td />
                        <td>Price</td>
                        <td>Products</td>
                        <td />
                    </tr>
                </thead>

                <tbody>
                    {orders.map(order => (
                        <OrderListItem key={order.Id} orderId={order.Id} />
                    ))}
                </tbody>
            </Table>
        </div>
    );
}
