import clsx from 'clsx';
import React, { useState } from 'react';
import { ProductPriceLabel } from '../product/ProductPriceLabel';
import { UiTableCellCheckbox } from '../ui/table/cell-checkbox/UiTableCellCheckbox';
import IconComment from '@material-ui/icons/CommentRounded';
import { themeActive } from '../../themes/dark';
import { UiGrid, UiText, UiButton, UiFlex } from '@orderx/ui';
import { UiInput } from '../ui/UiInput';
import { updateOrderProducts, removeOrderProduct } from '../../stores/create-order-products';
import { useSnackbar } from '../../shared/snackbar';
import IconDelete from '@material-ui/icons/DeleteForeverRounded';

export function StaffCreateActiveOrderProductRow({ product, checked, setChecked }) {
    const [isCommentVisible, setIsCommentVisible] = useState();
    const [comment, setComment] = useState(product.comment || '');
    const { showSuccess } = useSnackbar();

    function updateProduct(product, message) {
        updateOrderProducts([product]);
        showSuccess(message);
        setIsCommentVisible(false);
    }

    function addComment() {
        product.comment = comment;
        updateProduct(product, 'Comment added');
    }

    function removeComment() {
        delete product.comment;
        updateProduct(product, 'Comment removed');
    }

    return (
        <>
            <tr className={clsx('StaffCreateActiveOrderProductRow')}>
                <UiTableCellCheckbox checked={checked} onChange={setChecked} />

                <td>
                    <div>{product.name}</div>
                    <UiText color="textSecondary">
                        <ProductPriceLabel product={product} displayRow />
                    </UiText>
                </td>

                <td align="right">
                    <UiButton
                        onClick={() => setIsCommentVisible(!isCommentVisible)}
                        icon={<IconComment htmlColor={product.comment && themeActive.colors.green} />}
                    />

                    <UiButton onClick={() => removeOrderProduct(product.id)} icon={<IconDelete />} />
                </td>
            </tr>

            {isCommentVisible && (
                <tr>
                    <td colSpan={3}>
                        <UiGrid gap={1}>
                            <UiInput
                                label={'Comment'}
                                placeholder={'Add comment to order'}
                                value={comment}
                                onChangeValue={setComment}
                                multiline
                                rows={2}
                            />

                            <UiFlex spacing={1} flexChildren>
                                <UiButton onClick={() => setIsCommentVisible(false)} variant="outlined">
                                    Close
                                </UiButton>

                                {product.comment && (
                                    <UiButton onClick={removeComment} color="error" variant="outlined">
                                        Remove
                                    </UiButton>
                                )}

                                <UiButton onClick={addComment} color="primary" variant="outlined">
                                    Save
                                </UiButton>
                            </UiFlex>
                        </UiGrid>
                    </td>
                </tr>
            )}
        </>
    );
}
