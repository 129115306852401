import React, { useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import { GoogleAnalytics } from '../../providers/googe-analytics';

function SystemRouteChange({ location }) {
    const { pathname } = location;

    useEffect(() => GoogleAnalytics.pageview(pathname), [pathname]);

    return null;
}

export default withRouter(SystemRouteChange);
