import { getApiUrl } from '../config';
import { httpGet } from '../shared/api';

const getUrl = getApiUrl('reporting');

export async function getVenueTotalRevenueReport({ venueId, fromMilliseconds, toMilliseconds }) {
    return httpGet(getUrl(`staff/venues/${venueId}/total-revenue`), {
        fromMilliseconds,
        toMilliseconds,
    });
}

export async function getVenueProductsReport({ venueId, fromMilliseconds, toMilliseconds }) {
    return httpGet(getUrl(`staff/venues/${venueId}`), {
        fromMilliseconds,
        toMilliseconds,
    });
}

export async function getStaffPerformanceReport({ venueId, fromMilliseconds, toMilliseconds }) {
    return httpGet(getUrl(`staff/venues/${venueId}/performance`), {
        fromMilliseconds,
        toMilliseconds,
    });
}
