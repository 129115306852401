import clsx from 'clsx';
import React from 'react';
import { UiToolbar } from '@orderx/ui';
import { usePos } from '../../../../hooks/pos';
import { PosIdCreatePage } from '../create/PosIdCreatePage';
import { ToolbarTitle } from '../../../../components/toolbar/title/ToolbarTitle';

export function PosIdOrderHistoryPage() {
    const { locationId } = usePos();

    return (
        <div className={clsx('PosIdOrderHistoryPage')}>
            <UiToolbar>
                <ToolbarTitle buttonBackLink={PosIdCreatePage.route} buttonBackLinkParams={{ locationId }} />
            </UiToolbar>
        </div>
    );
}

PosIdOrderHistoryPage.route = '/v2/pos/:locationId/order-history';
