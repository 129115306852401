export function formatLegacyAppPostOrderProductsToPay(products) {
    return (products || []).map(product => ({
        id: product.id,
        name: product.name,
        comment: product.comment,
        priceIncludingVat: product.priceIncludingVat,
        priceExcludingVat: product.priceExcludingVat,
        locationProductId: product.locationProductId,
    }));
}
