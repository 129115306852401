export const termsAndConditions = `
<h2>
    Vilkår for bruk av Corona besøksregistrering i OrderX / Appless
</h2>

<p>
    Alle brukersteder som benytter OrderX / appless kan velge å skru på funksjon forbesøksregistering, i forbindelse
    med Corona-smittesporing. Formålet med tjenesten er å sikre en effektiv håndtering av registrering av
    tilstedeværelse, slik at brukersteder enkelt kan følge myndighetenes sterke oppfordring om å foreta
    besøksregistrering, for å forenkle smittesporingsarbeidet. Tjenesten kan ikke benyttes i strid med formålet med
    tjenesten.
</p>

<h3>Behandlingsgrunnlag</h3>

<p>
    Det er foretatt en interesseavveiing og brukerstedet har funnet at innsamling av nevnte personopplysninger er
    nødvendige for å ivareta legitime interesser. Nærmere bestemt for å kunne følge helsemyndighetenes oppfordring om å
    kunne bidra med gjestelister i forbindelse med covid-19 smittesporingsarbeid. Innsamlingen av data er således funnet
    å ha en større samfunnsmessig verdi, enn den ulempen en gjest påføres ved å måtte gi fra seg personopplysninger. Man
    har fulgt et minimeringsprinsipp, og forsikret seg om at man ikke samler innflere opplysninger, enn det som er
    nødvendig for å oppnå formålet.
</p>

<h3>Behandling av personopplysninger:</h3>

<p>
    Behandlingsansvarlig for persondata som samles inn
    til smittesporingsformål, vil være det enkelte brukersted som benytter tjenesten fra OrderX / Appless
    (Leverandøren). Se kvitteringen din på appless.no. OrderX AS er ansvarlig for leveranse og drift av tjensten og er
    databehandler for utsalgsstedet. OrderX har således inngått en databehandleravtale med utsalgsstedet. Tjenesten er
    etablertved og lagres på et datasenter innenfor EU. Personopplysningene vil kun bli lagret og brukt for å oppfylle
    helsemyndighetenes krav i forbindelse med smittesporing. Tjenesten stiller krav om opplysninger om fullt navn og
    kontaktopplysninger slik som e-post eller mobilnummer. Opplysningene om deg vil bli lagret knyttet til det enkelte
    brukersted du har vært på, og vil inneholde dato og klokkeslett. Dersom du krysser av for at du ønsker at vi skal
    huske deg, for automatisk å kunne sjekke inn på andre steder som benytter OrderX/appless, vil vi lagre
    kontaktopplysningene dine lokalt i nettleseren din. Personopplysningene vil kun være tilgjengelig for autorisert
    personell hos OrderX. Disse vil kun benyttes i smittesporingsøyemed. Dersom det oppstår mistanke om eller bekreftet
    smitte hos en eller flere personer som har vært til stede på samme utsalgssted som deg, vil OrderX på vegne av
    utsalgsstedet utlevere navn, kontaktopplysninger og opplysninger om dato og tidspunkt for tilstedeværelse til
    helsemyndighetene.
</p>

<h3>Statistikk</h3>

<p>
    Leverandøren kan bruke opplysningene til å generere anonymiserte eller aggregerte data
    over tilstedeværelse og kobling mot eventuell mistanke om ellerbekreftet smitte blant personer som har vært på et
    OrderX/ appless.no utsalgssted. For disse formålene vil vi fjerne navn og kontaktopplysninger.
</p>

<h3>Lagring av data<h3>

<p>Vi vil oppbevare opplysninger om deg og tilstedeværelse så lenge dette er nødvendig av hensyn til eventuelt behov for
smittesporing. Dine registrerte data vil bli slettet innen 10 dager etter at du besøkte utsalgsstedet. For mer
detaljer om vår generelle personvernhåndtering, se https://www.orderx.no/privacy</p>

<h3>Endring av vilkårene</h3>

<p>OrderX kan endre disse vilkårene. Ved vesentlige endringer I vilkårene vil vi sørge for at dette blir varslet ved neste innsjekk. Vilkårene anses akseptert ved gjennomført besøksregistrering</p>

<h3>Kontaktopplysninger</h3>

<p>Eier av løsningen er OrderX AS, org. Nr. 916 690 479</p>

<p>Slottsbrugata 26b, 3912 Porsgrunn</p>
<p>hello@orderx.no</p>
`;
