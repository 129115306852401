import { DatePicker, TimePicker } from 'material-ui-pickers';
import React from 'react';
import styled from 'styled-components';

const Wrapper = styled.div`
    display: flex;

    > div:first-child {
        flex: 1;

        > div {
            border-top-right-radius: 0;
            border-bottom-right-radius: 0;
        }
    }

    > div:last-child {
        width: 80px;

        > div {
            border-top-left-radius: 0;
            border-bottom-left-radius: 0;
        }

        input {
            text-align: center;
        }
    }
`;

export function Datepicker({ label, format = 'DD/MM', value, onChange, minDate, maxDate }) {
    return (
        <Wrapper>
            <DatePicker
                label={label || ' '}
                value={value}
                onChange={onChange}
                minDate={minDate}
                maxDate={maxDate}
                format={format}
            />

            <TimePicker
                label={' '}
                ampm={false}
                value={value}
                onChange={onChange}
                onClear={() => {
                    onChange(
                        value
                            .clone()
                            .startOf('day')
                            .add(7, 'hour'),
                    );
                }}
                minutesStep={5}
            />
        </Wrapper>
    );
}
