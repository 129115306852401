import { Entity, Column, ManyToOne, OneToMany } from './typeorm';
import { BaseModel } from './base-model';
import { InventoryPlugin } from './inventory-plugin';
import { InventoryPurchaseOrder } from './inventory-purchase-order';

@Entity()
export class InventoryPurchaseOrderVendor extends BaseModel {
    @Column()
    name: string;

    @OneToMany(() => InventoryPurchaseOrder, (model) => model.vendor)
    purchaseOrders: InventoryPurchaseOrder[];

    @ManyToOne(() => InventoryPlugin, (model) => model.recipes)
    plugin: InventoryPlugin;
    @Column()
    pluginId: number;
}
