import { createStore } from '@orderx/store';
import { storageGet, storageSet } from '../../shared/storage';
import { useEffect } from 'react';
import { useUser } from '../../stores/user';
import { MicroserviceMeV1 } from '../../microservices/me-v1';

interface Store {
    isTermsAccepted: boolean;
    termsAndConditions: any;
    privacyPolicy: any;
}

const storageKey = 'useTermsAndConditions';

const { useStore } = createStore<Store>(
    Object.assign(
        {
            isTermsAccepted: false,
            termsAndConditions: '',
            privacyPolicy: '',
        },
        storageGet(storageKey),
    ),
);

export function useTermsAndConditions(): Store & {
    acceptTermsAndConditions(): void;
    useLoadTerms(): void;
    urlTermsAndConditions: string;
    urlPrivacyPolicy: string;
} {
    const { isTermsAccepted, isAuthenticated } = useUser();

    const [state, setState] = useStore();

    useEffect(() => {
        storageSet(storageKey, state);
    }, [state]);

    const urlToBucket = (url) => `https://s3-eu-west-1.amazonaws.com/orderx-app/terms-and-conditions/${url}`;
    const urlTermsAndConditions = urlToBucket(`27.04.2018-terms-and-conditions.html`);
    const urlPrivacyPolicy = urlToBucket(`27.04.2018-privacy-policy.html`);

    return {
        ...state,
        urlTermsAndConditions,
        urlPrivacyPolicy,
        acceptTermsAndConditions() {
            setState((state) => {
                state.isTermsAccepted = true;
            });

            if (isAuthenticated && !isTermsAccepted) {
                MicroserviceMeV1.mePut({
                    isTermsAccepted: true,
                });
            }
        },
        useLoadTerms() {
            async function load() {
                try {
                    const { privacyPolicy, termsAndConditions } = await MicroserviceMeV1.termsAndConditionsGet();

                    setState((state) => {
                        state.termsAndConditions = termsAndConditions;
                        state.privacyPolicy = privacyPolicy;
                    });
                } catch (error) {
                    console.error(error);
                }
            }

            useEffect(() => {
                load();
            }, []);
        },
    };
}
