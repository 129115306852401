import CircularProgress from '@material-ui/core/CircularProgress';
import makeStyles from '@material-ui/core/styles/makeStyles';
import clsx from 'clsx';
import React from 'react';

const useStyles = makeStyles(({ spacing }) => ({
    UiLoader: ({ noPadding }) => ({
        padding: noPadding ? 0 : spacing(2),
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    }),
    cover: {
        position: 'absolute',
        height: '100%',
        width: '100%',
        zIndex: 1,
        backgroundColor: 'rgba(0,0,0,0.75)',
    },
}));

export function UiLoader({ cover, noPadding, ...props }) {
    const classes = useStyles({ noPadding });

    return (
        <div className={clsx(classes.UiLoader, cover && classes.cover)}>
            <CircularProgress {...props} />
        </div>
    );
}
