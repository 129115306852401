import { Entity, Column, ManyToOne, Index, AfterLoad } from './typeorm';
import { BaseModel } from './base-model';
import { InventoryInventory } from './inventory-inventory';
import { User } from './user';
import { InventoryProduct } from './inventory-product';

export type InventoryActionDataProcessPurchaseOrderProduct = {
    unitAmount: number;
    containerPrice: number;
    purchaseOrderId: number;
    productId: number;
    inventoryId: number;
    meta?: any;
};

export type InventoryActionDataProcessCountingReportProduct = {
    unitAmountCorrection: number;
    unitAmountBefore: number;
    unitAmountAfter: number;
    productId: number;
    inventoryId: number;
    meta?: any;
    countingReportId: number;
};

export type InventoryActionDataInventoryMoveProduct = {
    productId: number;
    unitAmount: number;
    sourceInventoryId: number;
    sourceInventory: InventoryInventory;
    targetInventoryId: number;
    targetInventory: InventoryInventory;
    meta?: any;
};

export type InventoryActionDataProcessInventoryMoveProduct = {
    unitAmountCorrection: number;
    unitAmountBefore: number;
    unitAmountAfter: number;
    productId: number;
    product: InventoryProduct;
    sourceInventory: InventoryInventory;
    targetInventory: InventoryInventory;
};

export enum InventoryActionType {
    INVENTORY_MOVE_CREATE = 'INVENTORY_MOVE_CREATE',
    INVENTORY_MOVE_PRODUCT_PROCESS = 'INVENTORY_MOVE_PRODUCT_PROCESS',
    PURCHASE_ORDER_PRODUCT_PROCESS = 'PURCHASE_ORDER_PRODUCT_PROCESS',
    COUNTING_REPORT_PRODUCT_PROCESS = 'COUNTING_REPORT_PRODUCT_PROCESS',
}

@Entity()
export class InventoryAction extends BaseModel {
    @Column()
    type: InventoryActionType;

    @Column({ type: 'text', nullable: true })
    description: string;

    @Column({ default: false })
    @Index()
    isProcessed: boolean;

    @Column({ nullable: true })
    processedAt: Date;

    @Column({ type: 'text', nullable: true })
    data:
        | InventoryActionDataProcessCountingReportProduct
        | InventoryActionDataProcessPurchaseOrderProduct
        | InventoryActionDataInventoryMoveProduct
        | InventoryActionDataProcessInventoryMoveProduct;

    @ManyToOne(() => User, (model) => model.inventoryActions)
    user: User;
    @Column({ nullable: true })
    userId: number;

    @ManyToOne(() => InventoryInventory, (model) => model.actions)
    inventory: InventoryInventory;
    @Column({ nullable: true })
    inventoryId: number;

    parseJson() {
        try {
            this.data = JSON.parse(this.data as any);
        } catch (error) {}
    }
}
