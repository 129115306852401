import React, { useState } from 'react';
import { ChooseBar } from '../../../components/choose-bar/ChooseBar';
import { StaffInventoryProductPage } from './product/StaffInventoryProductPage';
import { NavTab } from '../../../components/nav-tab/NavTab';
import { UiToolbar } from '@orderx/ui';
import { ToolbarTitle } from '../../../components/toolbar/title/ToolbarTitle';
import { CustomerProfilePage } from '../../../../pages/CustomerProfilePage';

export function StaffInventoryPage() {
    const [bar, setBar] = useState<any>(undefined);

    if (!bar) {
        return <ChooseBar barIdCacheKey="StaffInventoryPageBarId" onChooseBar={setBar} />;
    }

    return (
        <>
            <UiToolbar>
                <ToolbarTitle title={bar.name} buttonBackLink={CustomerProfilePage.route} />
            </UiToolbar>

            <NavTab
                routes={[
                    {
                        path: StaffInventoryProductPage.route,
                        children: <StaffInventoryProductPage bar={bar} />,
                    },
                ]}
                redirect={StaffInventoryProductPage.route}
            />
        </>
    );
}

StaffInventoryPage.route = '/staff-inventory';
