import groupBy from 'lodash/groupBy';
import sum from 'lodash/sum';

export function groupProductsByLocationProductId(products) {
    const productsGroupedByLocationProduct = groupBy(products, product => product.locationProductId);

    return Object.keys(productsGroupedByLocationProduct).map(locationProductId => {
        const locationProducts = productsGroupedByLocationProduct[locationProductId];

        return {
            ...locationProducts[0],
            count: locationProducts.length,
            total: sum(locationProducts.map(product => product.priceIncludingVat)),
        };
    });
}
