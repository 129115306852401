import orderBy from 'lodash/orderBy';

export const ProductConverter = {
    convertLocationProductsToOrderProducts(products = []) {
        const productsConverted = products.map(product => ({
            id: product.key,
            locationProductId: product.id,
            icon: product.icon,
            name: product.name,
            priceIncludingVat: product.priceIncludingVatMinorUnits,
            priceExcludingVat: product.priceExcludingVatMinorUnits,
            key: product.key,
            category: product.category,
            subCategory: product.subCategory,
        }));

        return orderBy(productsConverted, product => product.name);
    },
};
