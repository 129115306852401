import clsx from 'clsx';
import React, { useState } from 'react';
import styled from 'styled-components';

const Wrapper = styled.div`
    img {
        opacity: 0;
        max-width: 100%;
        transition: 300ms;

        &.loaded {
            opacity: 1;
        }
    }
`;

export function Image({ src, alt }) {
    const [loaded, setLoaded] = useState(false);

    if (!src) {
        return <Wrapper />;
    }

    return (
        <Wrapper>
            <img className={clsx({ loaded })} alt={alt || ''} src={src} onLoad={() => setLoaded(true)} />
        </Wrapper>
    );
}
