import { createStore, useStore } from '../create-store';

export const OrderByType = {
    Default: 'DEFAULT',
    ProductPriceDescending: 'PRODUCT_PRICE_DESCENDING',
};

const store = createStore({
    orderByType: OrderByType.Default,
});

export function useStaffStationConfiguration() {
    const [{ orderByType }, setState] = useStore(store);

    function mergeState(next) {
        setState(state => ({ ...state, ...next }));
    }

    return {
        orderByType,
        OrderByType,
        setOrderByType(orderByType) {
            mergeState({ orderByType });
        },
    };
}
