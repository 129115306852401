import { useState } from 'react';
import store from 'store';

export function storageGet(key) {
    return store.get(key);
}

export function storageSet(key, value) {
    store.set(key, value);
}

export function storageRemove(key) {
    store.remove(key);
}

export const storage = {
    get: storageGet,
    set: storageSet,
    remove: storageRemove,
};

export function useStorage(key) {
    const [state, setState] = useState(storageGet(key));

    return [
        state,
        (value) => {
            storageSet(key, value);
            setState(value);
        },
    ];
}
