import React, { useEffect } from 'react';
import { usePosOrder } from '../../../../hooks/pos-order';
import { UiGrid, UiTable, UiTableCellCheckbox, UiCard, UiButton } from '@orderx/ui';
import { PosCreateActiveOrderProductRow } from './product-row/PosCreateActiveOrderProductRow';
import { useCheckboxes } from '../../../../../use/checkbox';
import { useApiQuery } from '@orderx/http';
import { usePos } from '../../../../hooks/pos';
import { apiEntityLocationInventoryPost } from '../../../../../api/entity-location-inventory.[post]';

export function PosCreateActiveOrder() {
    const { venueId } = usePos();
    const { useSummary, useOrderProducts } = usePosOrder();
    const { updateOrderProductByKey } = useOrderProducts();
    const { menuProducts } = useSummary();

    const {
        isAllChecked,
        toggleCheckedAll,
        isChecked,
        toggleChecked,
        checkedModels,
        reset: resetCheckedModels,
    } = useCheckboxes({
        models: menuProducts,
        idMapper: (model) => model.key,
    });

    const hasCheckedModels = checkedModels.length > 0;

    const { run: loadAccounts, response } = useApiQuery(() =>
        apiEntityLocationInventoryPost({
            body: {
                crud: 'READ',
                entity: 'InventoryAccount',
                entityOptions: {
                    where: {
                        locationId: venueId,
                        type: 'DISCOUNT',
                    },
                },
            },
        }),
    );

    const discountAccounts = response?.[0] || [];

    useEffect(() => {
        if (hasCheckedModels) {
            loadAccounts();
        }
    }, [hasCheckedModels]);

    function applyDiscount(account) {
        for (const product of checkedModels) {
            updateOrderProductByKey(product.key, {
                discountAccountId: account.id,
                discountAccount: account,
            });
        }

        resetCheckedModels();
    }

    if (menuProducts.length === 0) {
        return null;
    }

    return (
        <UiGrid outerSpacing={1} spacing={1}>
            <UiCard>
                <UiTable>
                    <thead>
                        <tr>
                            <UiTableCellCheckbox checked={isAllChecked} onChange={toggleCheckedAll} />
                            <td>Name</td>
                            <td align="right">Price</td>
                            <td />
                        </tr>
                    </thead>

                    <tbody>
                        {menuProducts.map((menuProduct) => (
                            <PosCreateActiveOrderProductRow
                                menuProduct={menuProduct}
                                isChecked={isChecked(menuProduct.key)}
                                setIsChecked={() => toggleChecked(menuProduct.key)}
                                key={menuProduct.key}
                            />
                        ))}
                    </tbody>
                </UiTable>
            </UiCard>

            {hasCheckedModels && (
                <UiGrid spacing={1}>
                    <UiCard>
                        <UiTable>
                            <thead>
                                <tr>
                                    <td>Apply discount</td>
                                    <td>Amount</td>
                                    <td />
                                </tr>
                            </thead>

                            <tbody>
                                {discountAccounts.map((account) => (
                                    <tr key={account.id}>
                                        <td>{account.name}</td>
                                        <td>-{account.percent}%</td>
                                        <td align="right">
                                            <UiButton onClick={() => applyDiscount(account)} variant="filled">
                                                Apply
                                            </UiButton>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </UiTable>
                    </UiCard>
                </UiGrid>
            )}
        </UiGrid>
    );
}
