import React, { useEffect, useState } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { authenticate } from '../shared/aws-amplify';
import { routes, withParams } from '../shared/router';
import { storageGet } from '../shared/storage';
import { useUser } from '../stores/user';
import { useInterval } from '../use/time';
import { CustomerPage } from './CustomerPage';
import { PosPage } from './pos/PosPage';
import Staff from './Staff';
import { StaffCreatePageDataResolver } from './staff-create/StaffCreatePageDataResolver';
import { SmartqPage } from './smartq/SmartqPage';
import { DeliveryPage } from './delivery/DeliveryPage';
import { AdminPage } from './admin/AdminPage';
import { StaffStationPage } from './staff-station/StaffStationPage';
import { StaffInventoryPage } from '../@v2/pages/staff/inventory/StaffInventoryPage';
import { V2BrowsePage } from '../@v2/pages/browse/V2BrowsePage';
import { TagPage } from './TagPage';

export function Authenticated({ history }) {
    const { isStaff, isTestUser } = useUser();
    const [tagNotFound, setTagNotFound] = useState(false);

    useInterval(5 * 60 * 1000, authenticate);

    useEffect(() => {
        if (isTestUser) {
            return;
        }

        const lastBarId = storageGet('staffLastBarId');

        if (!storageGet('dev') && isStaff) {
            if (lastBarId) {
                history.push(withParams(routes.staffOrderBar, { barId: lastBarId }));
            } else {
                history.push(withParams(routes.staffOrderBar, { barId: 'choose' }));
            }
        }
    }, [isTestUser, isStaff]);

    return (
        <Switch>
            <Route path={V2BrowsePage.route} component={V2BrowsePage} />
            <Route path={AdminPage.route} component={AdminPage} />
            <Route path={DeliveryPage.route} component={DeliveryPage} />
            <Route path={StaffStationPage.route} component={StaffStationPage} />
            <Route path={PosPage.route} component={PosPage} />
            <Route path={routes.staffCreateOrder} component={StaffCreatePageDataResolver} />
            <Route path="/staff" component={Staff} />
            <Route path={CustomerPage.route} component={CustomerPage} />
            <Route path={SmartqPage.route} component={SmartqPage} />
            <Route path={StaffInventoryPage.route} component={StaffInventoryPage} />

            {!tagNotFound && (
                <Route path="/:tag">
                    <TagPage onNotFound={() => setTagNotFound(true)} />
                </Route>
            )}

            <Redirect to={CustomerPage.route} />
        </Switch>
    );
}
