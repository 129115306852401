import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';
import styled from 'styled-components';
import { ActionSheet, useActionSheet } from '../components/ActionSheet';
import { Button } from '../components/Button';
import OrderDetailsOverlay from '../components/OrderDetailsOverlay';
import OrderListItem from '../components/OrderListItem';
import { Page } from '../components/Page';
import { PageSection } from '../components/PageSection';
import StaffBarPageHeader from '../components/StaffBarPageHeader';
import { StaffTerminalsSliderBottom } from '../components/StaffTerminalsSliderBottom';
import { Svg } from '../components/Svg';
import { Table } from '../components/Table';
import { TableTitle } from '../components/TableTitle';
import { TerminalPrintActionSheet } from '../components/TerminalPrintActionSheet';
import { UiButton } from '../@deprecated/UiButton';
import { routes, withParams } from '../shared/router';
import { useLocationTerminalSettings } from '../stores/location-terminal-settings';
import { useStaffActiveBar } from '../stores/staff-active-bar';
import { BAR_ORDER_LIMIT, useStaffTerminalOrders } from '../stores/staff-terminal-orders';
import { themeActive } from '../themes/dark';
import IconPrint from '@material-ui/icons/PrintRounded';
import { UiAlert } from '@orderx/ui';

const BottomButton = styled.div`
    height: 64px;

    button {
        background-image: ${(props) => props.theme.gradients.primary};
        position: fixed;
        bottom: ${(props) =>
            props.isExpanded ? themeActive.sizes.tabsHeight * 2 + 40 - 16 : themeActive.sizes.tabsHeight * 2 - 16}px;
        border-radius: 100%;
        height: 64px;
        width: 64px;
        box-shadow: var(--box-shadow);
        left: 50%;
        transform: translateX(-50%);
    }
`;

export default function StaffBarOrdersTerminal() {
    const { id: barId } = useStaffActiveBar();
    const [openOrder, setOpenOrder] = useState();
    const { isExpanded } = useLocationTerminalSettings(barId);
    const { getOrdersByBarId } = useStaffTerminalOrders();

    const actionSheetOrder = useActionSheet();
    const actionSheetPrint = useActionSheet();

    const orders = getOrdersByBarId(barId);

    return (
        <>
            <StaffBarPageHeader right={<UiButton onClick={actionSheetPrint.open} icon={<IconPrint />} />} />

            <Page>
                {orders.length === 0 && (
                    <PageSection>
                        <UiAlert>
                            There are no orders. To create a new order, click on button below marked with +
                        </UiAlert>
                    </PageSection>
                )}

                {orders.length > 0 && (
                    <PageSection>
                        <TableTitle>Last {BAR_ORDER_LIMIT} orders</TableTitle>

                        <Table>
                            <thead>
                                <tr>
                                    <td />
                                    <td />
                                    <td>Price</td>
                                    <td>Products</td>
                                    <td />
                                </tr>
                            </thead>

                            <tbody>
                                {orders.map((order) => (
                                    <OrderListItem
                                        key={order.Id}
                                        orderId={order.Id}
                                        onShowDetails={() => {
                                            setOpenOrder(order);
                                            actionSheetOrder.open();
                                        }}
                                    />
                                ))}
                            </tbody>
                        </Table>
                    </PageSection>
                )}
            </Page>

            <StaffTerminalsSliderBottom />

            <BottomButton isExpanded={isExpanded}>
                <NavLink to={withParams(routes.staffBarOrderCreate, { barId })}>
                    <Button clear>
                        <Svg icon="add" />
                    </Button>
                </NavLink>
            </BottomButton>

            {actionSheetOrder.isOpen && (
                <ActionSheet {...actionSheetOrder}>
                    <OrderDetailsOverlay actionSheet={actionSheetOrder} orderId={openOrder.Id} />
                </ActionSheet>
            )}

            {actionSheetPrint.isOpen && (
                <ActionSheet {...actionSheetPrint} padding={0}>
                    <TerminalPrintActionSheet actionSheet={actionSheetPrint} />
                </ActionSheet>
            )}
        </>
    );
}
