import { createStore } from '@orderx/store';
import { storageGet, storageSet } from '../../shared/storage';
import { useEffect } from 'react';
import { Api } from '@orderx/http';

const storageKey = 'useDeviceFingerprint';
const { useStore } = createStore(storageGet(storageKey));

export function useDeviceFingerprint() {
    const [deviceFingerprint, setDeviceFingerprint] = useStore();

    useEffect(() => {
        storageSet(storageKey, deviceFingerprint);
    }, [deviceFingerprint]);

    return {
        useRegister() {
            useEffect(() => {
                if (deviceFingerprint?.startsWith('0.')) {
                    const deviceFingerprint = createFingerprint();
                    setDeviceFingerprint(deviceFingerprint);
                    storageSet(storageKey, deviceFingerprint);
                } else if (deviceFingerprint) {
                    Api.setHeaders({ 'device-fingerprint': deviceFingerprint });
                } else {
                    const deviceFingerprint = createFingerprint();
                    setDeviceFingerprint(deviceFingerprint);
                    storageSet(storageKey, deviceFingerprint);
                }
            }, [deviceFingerprint]);
        },

        deviceFingerprint,
    };
}

function createFingerprint() {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
        var r = (Math.random() * 16) | 0,
            v = c === 'x' ? r : (r & 0x3) | 0x8;

        return v.toString(16);
    });
}
