import orderBy from 'lodash/orderBy';
import { storage } from '../shared/storage';
import { createStore, useStore, getStoreValue } from '../create-store';
import { useLocationTerminalSettings } from './location-terminal-settings';

const StorageKey = 'terminalOrdersByLocation';
const terminalOrdersByLocation = createStore(storage.get(StorageKey) || {});

export function useTerminalsWithOrderByLocationId(locationId) {
    const { terminalIds, isControlledFromSettings } = useLocationTerminalSettings(locationId);
    const [state] = useStore(terminalOrdersByLocation);
    const { terminalTappedCountById, orderIdByTerminalId } = state[locationId] || getLocationState(locationId);

    const mapTerminalIdToTerminal = terminalId => ({
        id: terminalId,
        count: terminalTappedCountById[terminalId],
        orderId: orderIdByTerminalId[terminalId],
    });

    if (isControlledFromSettings) {
        return terminalIds.map(mapTerminalIdToTerminal);
    }

    return orderBy(Object.keys(terminalTappedCountById).map(mapTerminalIdToTerminal), ({ count }) => count, 'desc');
}

export function sendOrderToTerminal({ terminalId, order }) {
    const barId = order.LocationId;
    const location = getLocationState(barId);

    location.terminalTappedCountById[terminalId] = (location.terminalTappedCountById[terminalId] || 0) + 1;

    serializeLocation(location);
}

export function useLocationTerminalData(locationId) {
    const [state] = useStore(terminalOrdersByLocation);
    return state[locationId] || getLocationState(locationId);
}

function serializeLocation(location) {
    const state = getStoreValue(terminalOrdersByLocation);

    const stateNew = {
        ...state,
        [location.id]: location,
    };

    terminalOrdersByLocation.set(stateNew);
    storage.set(StorageKey, stateNew);
}

function getLocationState(locationId) {
    const state = getStoreValue(terminalOrdersByLocation);

    return Object.assign(
        {
            id: locationId,
            terminalTappedCountById: {},
            orderIdByTerminalId: {},
            ordersById: [],
        },
        state[locationId],
    );
}

export function resetLocationState(locationId) {
    const state = getStoreValue(terminalOrdersByLocation);

    const stateNew = {
        ...state,
        [locationId]: {
            id: locationId,
            terminalTappedCountById: {},
            orderIdByTerminalId: {},
            ordersById: [],
        },
    };

    terminalOrdersByLocation.set(stateNew);
    storage.set(StorageKey, stateNew);
}
