import React from 'react';
import { SwitchRedirect } from '../components/SwitchRedirect';
import { routes } from '../shared/router';
import { CustomerBrowsePage } from './customer/browse/CustomerBrowsePage';
import { CustomerBrowseGroupsHashPage } from './CustomerBrowseGroupsHashPage';
import { CustomerPaymentMethods } from './CustomerPaymentMethods';
import { CustomerProfilePage } from './CustomerProfilePage';
import { CustomerScan } from './CustomerScan';
import { CustomerBottomNav } from '../components/customer/bottom-nav/CustomerBottomNav';
import { OrderHistoryPage } from '../@v2/pages/order-history/OrderHistoryPage';
import { CustomerVenuePage } from './customer/venue/CustomerVenuePage';
import { CustomerBrowseIdPage } from './customer/browse/id/CustomerBrowseIdPage';

export function CustomerPage() {
    const moduleRoutes = [
        {
            path: CustomerBrowsePage.route,
            component: CustomerBrowsePage,
            exact: true,
        },
        {
            path: CustomerBrowseIdPage.route,
            component: CustomerBrowseIdPage,
        },
        {
            path: CustomerVenuePage.route,
            component: CustomerVenuePage,
        },
        {
            path: CustomerBrowseGroupsHashPage.route,
            component: CustomerBrowseGroupsHashPage,
        },
        {
            path: routes.customerScan,
            component: CustomerScan,
        },
        {
            path: routes.customerProfile,
            component: CustomerProfilePage,
        },
        {
            path: routes.customerPaymentMethods,
            component: CustomerPaymentMethods,
        },
        {
            path: OrderHistoryPage.route,
            component: OrderHistoryPage,
        },
    ];

    return (
        <>
            <SwitchRedirect routes={moduleRoutes} />
            <CustomerBottomNav />
        </>
    );
}

CustomerPage.route = '/customer';
