import InputAdornment from '@material-ui/core/InputAdornment';
import IconClose from '@material-ui/icons/CloseRounded';
import MenuItem from '@material-ui/core/MenuItem';
import TextField from '@material-ui/core/TextField';
import makeStyles from '@material-ui/styles/makeStyles';
import React from 'react';
import { UiButton } from '../../@deprecated/UiButton';

const useStyles = makeStyles({
    noBorder: {
        border: 0,
    },
    adornedStart: {
        paddingLeft: 0,
    },
    adornedEnd: {
        paddingRight: 0,
    },
});

export function UiInput({ onChange, onChangeValue, startAdornment, endAdornment, noBorder, clearable, ...rest }) {
    const classes = useStyles();

    if (rest.options) {
        rest.select = true;

        rest.children = rest.options.map(({ label, value }) => (
            <MenuItem key={value} value={value}>
                {label}
            </MenuItem>
        ));
    }

    if (clearable && rest.value) {
        endAdornment = <UiButton onClick={() => changeValue('')} icon={<IconClose />} />;
    }

    const InputProps = {
        startAdornment: startAdornment && <InputAdornment position="start">{startAdornment}</InputAdornment>,
        endAdornment: endAdornment && <InputAdornment position="end">{endAdornment}</InputAdornment>,
        ...rest.InputProps,
    };

    if (noBorder) {
        InputProps.classes = InputProps.classes || {};
        InputProps.classes.notchedOutline = classes.noBorder;
        InputProps.classes.adornedStart = classes.adornedStart;
        InputProps.classes.adornedEnd = classes.adornedEnd;
    }

    function changeValue(value, event) {
        if (onChange && event) {
            onChange(event);
        }

        if (onChangeValue) {
            if (rest.type === 'number') {
                onChangeValue(value ? Number(value) : '');
            } else {
                onChangeValue(value);
            }
        }
    }

    return (
        <TextField
            {...rest}
            onChange={event => changeValue(event.target.value, event)}
            margin="normal"
            variant="outlined"
            InputProps={InputProps || rest.InputProps}
        />
    );
}
