import makeStyles from '@material-ui/styles/makeStyles';
import React, { useEffect } from 'react';
import { Currency } from '../components/Currency';
import { IndicatorActive } from '../components/IndicatorActive';
import { V2Button } from '../components/v2/V2Button';
import { V2List } from '../components/v2/V2List';
import { V2ListItem } from '../components/v2/V2ListItem';
import { V2PageHeader } from '../components/v2/V2PageHeader';
import { V2Table, V2TableCell, V2TableRow } from '../components/v2/V2Table';
import { MicroserviceClients } from '../microservices/clients';
import { useOnWindowFocus } from '../shared/browser';
import { translate } from '../shared/translate';
import { useActiveClient } from '../stores/client';
import { useApiQuery } from '@orderx/http';
import { UiFlex } from '@orderx/ui';

const useStyles = makeStyles({
    container: {
        padding: 16,
    },
});

export function StaffClientsWristbands() {
    const classes = useStyles();

    const [{ id: clientId, totalSpendLimit, companyName }] = useActiveClient();

    const { response: overview, isLoading, run: loadOverview } = useApiQuery({
        query: () => MicroserviceClients.findClientOverview({ clientId }),
    });

    const { isLoading: isUpdating, run: updateWristbandActive } = useApiQuery({
        query: async (params) => {
            await MicroserviceClients.updateWristband({ clientId, ...params });
            await loadOverview();
        },
    });

    useEffect(() => {
        loadOverview();
    }, []);

    useOnWindowFocus(() => {
        loadOverview();
    });

    return (
        <>
            <V2PageHeader title={companyName} isLoading={isLoading} />

            {overview && (
                <>
                    <V2List>
                        <V2ListItem
                            title={translate('Total spend limit')}
                            contentRight={<Currency amount={totalSpendLimit || 0} />}
                        />
                        <V2ListItem
                            title={translate('Amount spent')}
                            contentRight={<Currency amount={overview.totalSpent || 0} />}
                        />
                    </V2List>

                    <div className={classes.container}>
                        <V2Table headers={['ID', 'Spent/Limit', '']}>
                            {overview.wristbands.map((wristband) => (
                                <V2TableRow key={wristband.id}>
                                    <V2TableCell>
                                        <UiFlex spacing={1}>
                                            <div>
                                                <IndicatorActive active={wristband.active} />
                                            </div>

                                            <span>
                                                {wristband.registrationCode}/{wristband.securityCode}
                                            </span>
                                        </UiFlex>
                                    </V2TableCell>

                                    <V2TableCell right>
                                        {wristband.amountSpent}/{wristband.spendLimit || 0}
                                    </V2TableCell>

                                    <V2TableCell right>
                                        <V2Button
                                            onClick={() =>
                                                updateWristbandActive({
                                                    wristbandId: wristband.id,
                                                    active: !wristband.active,
                                                })
                                            }
                                            isLoading={isUpdating}
                                            small>
                                            {wristband.active ? translate('Deactivate') : translate('Activate')}
                                        </V2Button>
                                    </V2TableCell>
                                </V2TableRow>
                            ))}
                        </V2Table>
                    </div>
                </>
            )}
        </>
    );
}
