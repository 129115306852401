import React from 'react';
import styled, { css } from 'styled-components';

const Wrapper = styled.div`
    ${(props) =>
        props.cardList &&
        css`
            padding: 0 ${(props) => (props.paddingHorizontal ? 16 : 0)}px;

            .list-items-wrapper {
                background-color: var(--color-background-paper);
                box-shadow: var(--box-shadow);
                border-radius: var(--border-radius);

                > * {
                    &:first-child {
                        border-top-left-radius: 12px;
                        border-top-right-radius: 12px;
                    }

                    &:last-child {
                        border-bottom-left-radius: 12px;
                        border-bottom-right-radius: 12px;
                    }
                }
            }
        `};
`;

const Title = styled.h3`
    margin: 0 0 8px 16px;
`;

export function V2List({ title, children, cardList, paddingHorizontal = true }) {
    return (
        <Wrapper cardList={cardList} paddingHorizontal={paddingHorizontal}>
            {title && <Title>{title}</Title>}
            <div className="list-items-wrapper">{children}</div>
        </Wrapper>
    );
}
