import keyBy from 'lodash/keyBy';
import sum from 'lodash/sum';
import { createStore, useStore } from '../create-store';

const initialState = () => ({
    counts: {},
});

const store = createStore({});

export function useStaffBarCreateOrder(barId) {
    const [state, setState] = useStore(store);
    const barState = state[barId] || initialState();
    const { counts } = barState;

    function ensureState(state) {
        state[barId] = state[barId] || initialState();
    }

    function getProductsWithCounts(products) {
        const productById = keyBy(products, product => product.id);

        return Object.keys(counts)
            .filter(productId => counts[productId] > 0)
            .map(productId => ({
                ...productById[productId],
                count: counts[productId],
            }));
    }

    return {
        counts,
        reset() {
            setState(state => {
                state[barId] = initialState();
            });
        },
        increment(id, count) {
            setState(state => {
                ensureState(state);
                state[barId].counts[id] = (state[barId].counts[id] || 0) + count;
            });
        },
        decrement(id, count) {
            setState(state => {
                ensureState(state);
                state[barId].counts[id] = (state[barId].counts[id] || 0) - count;

                if (state[barId].counts[id] < 0) {
                    state[barId].counts[id] = 0;
                }
            });
        },
        getProductsWithCounts,
        getTotalPaymentAmount(products) {
            const productsWithCounts = getProductsWithCounts(products);
            return sum(productsWithCounts.map(({ priceIncludingVat, count }) => priceIncludingVat * count));
        },
    };
}
