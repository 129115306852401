import React, { useEffect, useState } from 'react';
import { CreateOrder } from '../../modules/create-order';
import { useCreateOrder } from '../../stores/create-order';
import { useCreateOrderBar } from '../../stores/create-order-bar';
import { useCreateOrderQuery } from '../../stores/create-order-query';
import { CurrencyMinor } from '../CurrencyMinor';
import { UiButtonBottom } from '../ui/UiButtonBottom';
import { StaffCreateBottomButtonGroups } from './StaffCreateBottomButtonGroups';

export function StaffCreateBottomButton() {
    const { groups } = useCreateOrderBar();
    const { totalPriceIncludingVat } = CreateOrder.useState();
    const { isPosMode, preSelectedGroup } = useCreateOrder();
    const { placeOrder, isLoading: isPlacingOrder } = useCreateOrderQuery();
    const [isGroupsOpen, setIsGroupsOpen] = useState(false);

    useEffect(() => {
        if (isPlacingOrder) {
            setIsGroupsOpen(false);
        }
    }, [isPlacingOrder]);

    if (isPosMode || totalPriceIncludingVat === 0) {
        return null;
    }

    const handleClick = () => {
        if (preSelectedGroup) {
            return placeOrder({ groupId: preSelectedGroup.id });
        } else if (groups.length === 1) {
            return placeOrder({ groupId: groups[0].id });
        } else if (groups.length > 1) {
            return setIsGroupsOpen(true);
        }
    };

    const chooseGroup = (group) => {
        placeOrder({ groupId: group.id });
    };

    return (
        <>
            <UiButtonBottom onClick={handleClick} position={'aboveTabs'} isLoading={isPlacingOrder}>
                Place order of <CurrencyMinor amount={totalPriceIncludingVat} />
            </UiButtonBottom>

            <StaffCreateBottomButtonGroups
                isOpen={isGroupsOpen}
                close={() => setIsGroupsOpen(false)}
                onChooseGroup={chooseGroup}
            />
        </>
    );
}
