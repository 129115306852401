import { createStore, useStore } from '../create-store';
import { getStaffBar } from '../microservices/orders';

const store = createStore({});

export function useStaffOrderingBar() {
    const [state] = useStore(store);

    const { barId, isLoadingOrders = false, isLoadingBar = false, orders = [] } = state;

    return {
        isLoadingBar,
        barId,
        barName: state.barName,
        barImage: state.barImage,
        barHash: state.barHash,
        groups: state.groups || [],
        pickupSlots: state.pickupSlots || [],
        isLoadingOrders,
        orders,
        products: state.products || [],
        ordersCustomerPlacedCount: state.ordersCustomerPlacedCount,
        loadBarById: loadBar,
        loadBar: () => loadBar(barId),
        reset: () => store.set({}),
    };
}

async function loadBar(barId) {
    store.set(state => {
        state.isLoadingBar = true;
    });

    let response;

    try {
        response = await getStaffBar(barId);
    } catch (error) {
        console.error(error);
    }

    store.set(state => {
        Object.assign(state, response);
        state.isLoadingBar = false;
    });
}
