import groupBy from 'lodash/groupBy';
import orderBy from 'lodash/orderBy';
import startCase from 'lodash/startCase';
import uniq from 'lodash/uniq';
import React, { useEffect, useMemo } from 'react';
import { CreateOrder } from '../../modules/create-order';
import { useCreateOrderBar } from '../../stores/create-order-bar';
import { useCreateOrderProductStats } from '../../stores/create-order-product-stats';
import { UiGrid, UiFlex, UiButton } from '@orderx/ui';
import './StaffCreateProductCategoryFilter.scss';

export function StaffCreateProductCategoryFilter() {
    const { categoryStats } = useCreateOrderProductStats();
    const { orderProducts } = CreateOrder.useState();
    const { category, setCategory, subCategory, setSubCategory, products, setProductsPage } = useCreateOrderBar();
    const orderProductsCount = orderProducts.length;
    const categoryAll = '';

    const filterUnique = (categories) =>
        orderBy(uniq(categories).filter(Boolean), (category) => categoryStats[category], 'desc');

    const mainCategories = useMemo(() => filterUnique(products.map((product) => product.category)), [products]);

    const productsByCategoryId = useMemo(() => groupBy(products, (product) => product.category), [products]);

    const subCategories = useMemo(
        () => filterUnique((productsByCategoryId[category] || []).map((product) => product.subCategory)),
        [productsByCategoryId, category],
    );

    useEffect(() => {
        if (orderProductsCount === 0 && category === '_ACTIVE') {
            setCategory(categoryAll);
            setSubCategory('');
        }
    }, [category, orderProductsCount]);

    useEffect(() => setProductsPage(0), [category, subCategory]);

    const getButton = ({ label, active, target, onSet, onClick, key }) => (
        <UiButton
            size={-1}
            onClick={() => {
                if (onSet) {
                    onSet(target === active ? '' : target);
                }

                if (onClick) {
                    onClick();
                }
            }}
            color={target === active ? 'primary' : 'default'}
            variant="filled"
            key={key}>
            {startCase((label || 'Other').toLowerCase().replace(/ /g, ' '))}
        </UiButton>
    );

    return (
        <div className="StaffCreateProductCategoryFilter">
            <UiGrid spacing={1}>
                <UiFlex spacing={1} overflowAuto>
                    {orderProducts.length > 0 && (
                        <UiButton
                            onClick={() => {
                                setCategory('_ACTIVE');
                                setSubCategory('');
                            }}
                            size={-1}
                            variant="filled"
                            color="secondary">
                            Active
                        </UiButton>
                    )}

                    {getButton({
                        label: 'All',
                        active: category,
                        target: '',
                        onClick: () => {
                            setCategory(categoryAll);
                            setSubCategory('');
                        },
                    })}

                    {mainCategories.map(($category) =>
                        getButton({
                            label: $category,
                            active: category,
                            target: $category,
                            onSet: setCategory,
                            onClick: () => setSubCategory(''),
                            key: $category || '_other',
                        }),
                    )}
                </UiFlex>

                {subCategories.length > 0 && (
                    <UiFlex spacing={1} overflowAuto>
                        {subCategories.map(($subCategory) =>
                            getButton({
                                label: $subCategory,
                                active: subCategory,
                                target: $subCategory,
                                onSet: setSubCategory,
                                key: $subCategory || '_other',
                            }),
                        )}
                    </UiFlex>
                )}
            </UiGrid>
        </div>
    );
}
