import makeStyles from '@material-ui/styles/makeStyles';
import clsx from 'clsx';
import React, { useEffect } from 'react';
import { useProductCategoryFilter } from '../../use/product-category-filter';
import { useStaffOrder } from '../../use/staff-order';
import { getProductsByCategory } from '../../util/get-products-by-category';
import { ProductCategoryTabs } from '../product/category-tabs/ProductCategoryTabs';
import { ProductListItem } from '../product/list-item/ProductListItem';

const useStyles = makeStyles({
    container: {
        paddingLeft: 16,
        paddingRight: 16,
        marginBottom: 16,
    },
    gridProducts: {
        display: 'grid',
        gridGap: 8,
        gridTemplateColumns: 'repeat(auto-fill, minmax(200px, 1fr))',
        '& .card': {
            marginBottom: '0 !important',
        },
    },
});

export function StaffOrderViewProducts({ locationProducts }) {
    const classes = useStyles();

    const {
        productsGrouped,
        totalProductsAdded,
        getProductCountByLocationProductId,
        addProduct,
        removeProduct,
    } = useStaffOrder();

    const {
        category,
        setCategory,
        subCategory,
        setSubCategory,
        Category,
        resetCategories,
    } = useProductCategoryFilter();

    const isTabPending = category === Category.Pending;

    const productsByCategory = isTabPending
        ? productsGrouped
        : getProductsByCategory({ products: locationProducts, category, subCategory });

    const productsByTab = isTabPending ? productsGrouped : productsByCategory;

    useEffect(() => {
        if (isTabPending && totalProductsAdded === 0) {
            resetCategories();
        }
    }, [isTabPending, totalProductsAdded]);

    return (
        <>
            <div className={classes.container}>
                <ProductCategoryTabs
                    category={category}
                    onChangeCategory={setCategory}
                    subCategory={subCategory}
                    onChangeSubcategory={setSubCategory}
                    products={locationProducts}
                    productsCount={totalProductsAdded}
                />
            </div>

            <div className={clsx(classes.container, classes.gridProducts)}>
                {productsByTab.map((product) => (
                    <ProductListItem
                        key={product.id}
                        image={product.icon}
                        name={product.name}
                        price={isTabPending ? product.priceIncludingVat / 100 : product.priceIncludingVat}
                        count={getProductCountByLocationProductId(product.id)}
                        currency={product.currency}
                        onDecrement={(amount) => {
                            removeProduct(product, amount);
                        }}
                        onIncrement={(amount) => {
                            addProduct(product, amount);
                        }}
                    />
                ))}
            </div>
        </>
    );
}
