import React from 'react';
import { withRouter } from 'react-router-dom';
import styled from 'styled-components';
import { getUnitsSpentLabel, isLimitExceeded } from '../microservices/balance-groups';
import { routes, withParams } from '../shared/router';
import { useStaffActiveBar } from '../stores/staff-active-bar';

const Row = styled.tr`
    background-color: ${props => props.limitReached && props.theme.colors.red};
`;

function BalanceGroupListRow({ balanceGroup, history }) {
    const { id: barId } = useStaffActiveBar();
    const to = withParams(routes.staffBarBalanceGroupsDetail, { barId, balanceGroupId: balanceGroup.id });

    return (
        <Row onClick={() => history.push(to)} limitReached={isLimitExceeded(balanceGroup)}>
            <td>
                <b>{balanceGroup.name}</b>
            </td>

            <td>
                <b>{getUnitsSpentLabel(balanceGroup)}</b>
            </td>
        </Row>
    );
}

export default withRouter(BalanceGroupListRow);
