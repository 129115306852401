import { useEffect } from 'react';
import { postAction } from '../microservices/device-actions';
import { stores } from '../stores';
import { useDevice } from './device';
import { setNfcScanValue } from '../stores/nfc';
import { Subject } from 'rxjs';

const onNfcScan = new Subject();

const minTimeBetweenScansInMilliseconds = 2000;

let isScanning = false;

export function useNfc() {
    const { isAndroid, isIos } = useDevice();

    useEffect(
        () => {
            if (isAndroid) {
                window.nfc.addNdefListener(onTag);
                window.nfc.addTagDiscoveredListener(onTag);
            }
        },
        [isAndroid],
    );

    useEffect(
        () => {
            if (isIos) {
                window.nfc.addNdefListener(onTag);
            }
        },
        [isIos],
    );

    function onTag(nfcResponse) {
        if (isScanning) {
            return;
        }

        isScanning = true;

        const id = isAndroid
            ? window.nfc.bytesToHexString(nfcResponse.tag.id)
            : window.nfc.bytesToHexString(nfcResponse.tag.ndefMessage[0].payload);

        const nfcEmitData = {
            id: id && id.toUpperCase(),
            payload: null,
        };

        onNfcScan.next(nfcEmitData.id);
        postAction('nfc_tag_read', nfcEmitData);
        setNfcScanValue(nfcEmitData.id);

        stores.nfc.set(state => {
            state.scanning = true;
            state.scanOpen = false;
        });

        setTimeout(() => {
            isScanning = false;

            setNfcScanValue(undefined);

            stores.nfc.set(state => {
                state.scanning = false;
            });
        }, minTimeBetweenScansInMilliseconds);
    }
}
