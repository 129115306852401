import ButtonGroup from '@material-ui/core/ButtonGroup';
import makeStyles from '@material-ui/core/styles/makeStyles';
import React from 'react';
import { themeActive } from '../../themes/dark';

const useStyles = makeStyles({
    UiButtonGroup: {
        position: 'relative',
    },
    label: {
        backgroundColor: themeActive.card.backgroundColor,
        position: 'absolute',
        top: 0,
        left: '50%',
        transform: 'translateX(-50%)',
        zIndex: 1,
        borderBottomLeftRadius: 4,
        borderBottomRightRadius: 4,
        paddingLeft: 16,
        paddingRight: 16,
        textTransform: 'uppercase',
        fontWeight: 700,
        letterSpacing: 1,
        fontSize: '0.65rem',
        textAlign: 'center',
        whiteSpace: 'nowrap',
    },
});

export function UiButtonGroup({ label = '', ...rest }) {
    const classes = useStyles();

    const buttonGroup = <ButtonGroup fullWidth {...rest} />;

    if (label) {
        return (
            <div className={classes.UiButtonGroup}>
                {label && <div className={classes.label}>{label}</div>}
                {buttonGroup}
            </div>
        );
    }

    return buttonGroup;
}
