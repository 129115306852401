import React, { useEffect, useMemo, useState } from 'react';
import { withRouter } from 'react-router-dom';
import styled from 'styled-components';
import { CssLoader } from '../components/CssLoader';
import { Input } from '../components/Input';
import { InputLabel } from '../components/InputLabel';
import { V2Grid } from '../components/v2/V2Grid';
import { V2List } from '../components/v2/V2List';
import { V2ListItem } from '../components/v2/V2ListItem';
import { V2PageHeader } from '../components/v2/V2PageHeader';
import { postAction } from '../microservices/device-actions';
import { loadStaffVenues } from '../microservices/legacy-app';
import { routes, withParams } from '../shared/router';
import { useStaffVenues } from '../stores/staff-venues';

const FilterWrapper = styled.div`
    padding: 16px 16px 0 16px;
`;

function StaffChooseBar({ history }) {
    const venues = useStaffVenues();
    const [filter, setFilter] = useState('');

    useEffect(() => {
        loadStaffVenues();
    }, []);

    const filteredVenues = useMemo(
        () =>
            venues
                .map(venue => {
                    const includesVenue = venue.name.toLowerCase().includes(filter.toLowerCase());

                    if (!filter || includesVenue) {
                        return venue;
                    }

                    return {
                        ...venue,
                        bars: venue.bars.filter(bar => {
                            return !filter || bar.name.toLowerCase().includes(filter.toLowerCase());
                        }),
                    };
                })
                .filter(venue => venue.bars.length > 0),
        [venues, filter],
    );

    function chooseBar(bar) {
        postAction('staff_choose_bar', { barId: bar.id, name: bar.name });
    }

    return (
        <>
            <V2PageHeader title="Choose bar" onClickBack={() => history.goBack()} />

            <V2Grid>
                <FilterWrapper>
                    <InputLabel>Filter bars</InputLabel>
                    <Input value={filter} placeholder="Type to filter bars" onChangeValue={setFilter} margin={0} />
                </FilterWrapper>

                {filteredVenues.length === 0 && venues.length === 0 && <CssLoader margin={0} />}

                {filteredVenues.map(venue => (
                    <V2List key={venue.id} title={venue.name}>
                        {(venue.bars || []).map(bar => (
                            <V2ListItem
                                onClick={() => chooseBar(bar)}
                                to={withParams(routes.staffBarOrder, { barId: bar.id })}
                                key={bar.id}
                                label={bar.name}
                                iconRight="arrow-right"
                            />
                        ))}
                    </V2List>
                ))}
            </V2Grid>
        </>
    );
}

export default withRouter(StaffChooseBar);
