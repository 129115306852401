import React from 'react';
import styled from 'styled-components';

const Wrapper = styled.div`
    height: ${({ theme, numberOfButtons }) => numberOfButtons * theme.sizes.buttonHeight + (numberOfButtons - 1) * 8}px;
`;

export function ButtonPlaceholder({ numberOfButtons = 1, children }) {
    return <Wrapper numberOfButtons={numberOfButtons}>{children}</Wrapper>;
}
