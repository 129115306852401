import last from 'lodash/last';

export function utilGetDefaultAreaCode() {
    const topLevelDomain = last(window.location.hostname.split('.'));

    if (topLevelDomain === 'dk') {
        return '45';
    }

    if (topLevelDomain === 'se') {
        return '46';
    }

    return '47';
}
