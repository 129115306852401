import React, { useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';
import { CssLoader } from '../components/CssLoader';
import { Input } from '../components/Input';
import { InputLabel } from '../components/InputLabel';
import { V2Grid } from '../components/v2/V2Grid';
import { V2List } from '../components/v2/V2List';
import { V2ListItem } from '../components/v2/V2ListItem';
import { V2PageHeader } from '../components/v2/V2PageHeader';
import { loadStaffVenues } from '../microservices/legacy-app';
import { AppUserRoleRole } from '../models/app-user-role';
import { routes, withParams } from '../shared/router';
import { useStaffVenues } from '../stores/staff-venues';

const FilterWrapper = styled.div`
    padding: 16px 16px 0 16px;
`;

export default function StaffChooseReportingVenue({ history }) {
    const venues = useStaffVenues();
    const [filter, setFilter] = useState('');

    useEffect(() => {
        loadStaffVenues();
    }, []);

    const filteredVenues = useMemo(
        () =>
            venues.filter(
                ({ name, roles }) =>
                    name.toLowerCase().includes(filter.toLowerCase()) && roles.includes(AppUserRoleRole.Reporting),
            ),
        [venues, filter],
    );

    return (
        <>
            <V2PageHeader title="Choose venue" onClickBack={() => history.goBack()} />

            <V2Grid>
                <FilterWrapper>
                    <InputLabel>Filter venues</InputLabel>
                    <Input value={filter} placeholder="Type to filter venues" onChangeValue={setFilter} margin={0} />
                </FilterWrapper>

                {filteredVenues.length === 0 && venues.length === 0 && <CssLoader margin={0} />}

                {filteredVenues.length > 0 && (
                    <V2List>
                        {filteredVenues.map(venue => (
                            <V2ListItem
                                to={withParams(routes.staffVenueReporting, { venueId: venue.id })}
                                key={venue.id}
                                label={venue.name}
                                iconRight="arrow-right"
                            />
                        ))}
                    </V2List>
                )}
            </V2Grid>
        </>
    );
}
