import React from 'react';
import { UiButton } from '../@deprecated/UiButton';

export function useToggleIcon({ icon: Icon, iconActive: IconActive, onChange, active }) {
    IconActive = IconActive || Icon;

    const iconProps = {
        color: active ? 'primary' : 'inherit',
    };

    return (
        <UiButton
            onClick={() => onChange(!active)}
            icon={active ? <IconActive {...iconProps} /> : <Icon {...iconProps} />}
        />
    );
}
