import { createStore } from '@orderx/store';
import { apiPosLocationIdGet } from '../../api/pos.{locationId}.[get]';

const { useStore } = createStore<any>();

export function usePos() {
    const [state, setState] = useStore();

    return {
        locationId: state?.locationId,
        venueId: state?.venueId,
        menu: state?.menu,
        menuProducts: state?.menu?.menuProducts || [],
        async loadLocation(locationId: number) {
            try {
                setState(
                    await apiPosLocationIdGet({
                        params: { locationId },
                    }),
                );
            } catch (error) {
                console.error(error);
                setState(undefined);
            }
        },
    };
}
