import clsx from 'clsx';
import React, { Fragment, useState } from 'react';
import { useOrderBubble } from '../../../../../hooks/order-bubble';
import { UiGrid, UiText, UiImage, UiFlex, UiTable, UiButton, UiIconMaterial, UiBox } from '@orderx/ui';
import './OrderIdViewFotografiska.scss';
import { groupProductsByLocationProductId } from '../../../../../../util/group-products';
import { useCurrency } from '../../../../../hooks/currency';
import sum from 'lodash/sum';
import times from 'lodash/times';
import { CustomerBrowseGroupsHashPage } from '../../../../../../pages/CustomerBrowseGroupsHashPage';
import { CustomerBrowsePage } from '../../../../../../pages/customer/browse/CustomerBrowsePage';
import { ProductStatus } from '../../../../../../models/orders/product';
import { useRouter } from '../../../../../hooks/router';

interface Props {
    order: any;
}

export function OrderIdViewFotografiska({ order }: Props) {
    useOrderBubble().useTemporaryHide();
    const { formatMinor } = useCurrency();
    const { query } = useRouter();
    const [activeLanguage, setActiveLanguage] = useState('ET');

    const { sequence, products, group, locationImage, bar } = order;

    const numTotalProducts = products.length;

    const statusOfAllProducts =
        numTotalProducts > 0 &&
        products.filter((product) => product.status === products[0].status).length === numTotalProducts &&
        products[0].status;

    const isDelivered = statusOfAllProducts === ProductStatus.Delivered;
    const isReady = products.find((product) => product.status === ProductStatus.Ready);
    const inPreparation = !isReady && products.find((product) => product.status === ProductStatus.InPreparation);

    const groupHash = group.hash;
    const amountTotal = sum(products.map((product) => product.priceIncludingVat));

    const translation = {
        ET: {
            sequencePrefix: 'Nr:',
            orderStatusPlaced: `Palun ole leti läheduses!<br />Tellimus väljastatakse sinu numbri alusel`,
            orderStatusInPreparation: `Palun ole leti läheduses!<br />Tellimust valmistatakse`,
            orderStatusReady: `Tellimus valmis!<br />Näita seda numbrit letis`,
            orderStatusDelivered: `Tellimus antud üle!<br />Ilusat õhtu jätku!`,
            total: 'Kokku:',
        },
        EN: {
            sequencePrefix: 'Nr:',
            orderStatusPlaced: `Please stay near the counter!<br/> We will hand out the order based on your number`,
            orderStatusInPreparation: `Please stay near the counter!<br/>Order is being prepared.`,
            orderStatusReady: `Your order is ready!<br/>Show this number at the counter`,
            orderStatusDelivered: `Order delivered!<br/>Have a nice evening!`,
            total: 'Total:',
        },
    }[activeLanguage];

    const elementClose = (
        <>
            <div className="close-button-container">
                <UiButton
                    to={query.back || (groupHash ? CustomerBrowseGroupsHashPage.route : CustomerBrowsePage.route)}
                    toParams={{ hash: groupHash }}
                    size={2}
                    fullWidth
                    variant="filled"
                    icon={<UiIconMaterial>close</UiIconMaterial>}
                />
            </div>

            <div className="background" />
        </>
    );

    const elementProducts = (
        <UiTable>
            <tbody>
                {times(1).map((i) => (
                    <Fragment key={i}>
                        {groupProductsByLocationProductId(products).map((product: any) => (
                            <tr key={product.locationProductId}>
                                <td>
                                    {product.count} x {product.name}
                                </td>
                                <td align="right">{formatMinor(product.total)}</td>
                            </tr>
                        ))}
                    </Fragment>
                ))}

                <tr>
                    <td align="right" colSpan={2}>
                        <b>
                            {translation.total} {formatMinor(amountTotal)}
                        </b>
                    </td>
                </tr>
            </tbody>
        </UiTable>
    );

    if (isReady) {
        return (
            <UiGrid gap={4} padding={2} className={clsx('OrderIdViewFotografiska')}>
                <UiBox padding={2} className="ready-container">
                    <UiGrid className="ready" gap={4} padding={2} paddingTop={4}>
                        <UiText className="sequence" align="center" weight={2}>
                            <UiFlex justifyContent="center">
                                <div className="image-container">
                                    <UiImage src={bar.image} type="cover" />
                                </div>
                            </UiFlex>

                            <UiText className="number">{sequence}</UiText>

                            <UiText size={4} weight={2}>
                                <div dangerouslySetInnerHTML={{ __html: translation.orderStatusReady }} />
                            </UiText>
                        </UiText>

                        {elementProducts}
                    </UiGrid>
                </UiBox>

                {elementClose}
            </UiGrid>
        );
    }

    return (
        <UiGrid
            gap={4}
            padding={2}
            paddingTop={4}
            className={clsx('OrderIdViewFotografiska', isDelivered && 'isDelivered')}>
            <UiFlex justifyContent="center">
                <div className="image-container">
                    <UiImage src={bar.image} type="cover" />
                </div>
            </UiFlex>

            <div>
                <UiText size={2} align="center">
                    {bar.name} • {group.name}
                </UiText>

                <UiText size={6} weight={2} align="center">
                    {translation.sequencePrefix}
                    {sequence}
                </UiText>
            </div>

            <UiBox className="info" padding={2}>
                <UiText size={3} weight={2} align="center">
                    <UiIconMaterial>{isDelivered ? 'check_circle' : 'new_releases'}</UiIconMaterial>

                    <div
                        dangerouslySetInnerHTML={{
                            __html:
                                (isDelivered && translation.orderStatusDelivered) ||
                                (isReady && translation.orderStatusReady) ||
                                (inPreparation && translation.orderStatusInPreparation) ||
                                translation.orderStatusPlaced,
                        }}
                    />
                </UiText>
            </UiBox>

            {elementProducts}

            {elementClose}
        </UiGrid>
    );
}
