import clsx from 'clsx';
import React, { useEffect, useState } from 'react';
import { useDeliveryView } from '../../../../hooks/delivery/view';
import { useApiQuery } from '@orderx/http';
import { MicroserviceOrders } from '../../../../microservices/orders';
import { UiButton, UiBox, UiIconMaterial, UiGrid } from '@orderx/ui';
import { DeliveryStationOrderItem } from '../order/item/DeliveryStationOrderItem';
import { UiAlert } from '@orderx/ui';

const pageSize = 100;

export function DeliveryStationHistory() {
    const { bars, config } = useDeliveryView();
    const [take, setTake] = useState(pageSize);

    const { run, isLoading, response } = useApiQuery(() =>
        MicroserviceOrders.staffApp({
            action: 'handlerAppStaffDeliveryViewHistory',
            barIds: bars.map((bar) => bar.id),
            groupFilterIds: config.groupFilterIds,
            isPlacedFromTable: config.showTablePlacedOrders,
            take,
        }),
    );

    useEffect(() => {
        run();
    }, [take, config]);

    const orders = response?.orders || [];

    return (
        <div className={clsx('DeliveryStationHistory')}>
            <UiGrid outerSpacing={2}>
                <UiButton
                    onClick={run}
                    isLoading={isLoading}
                    icon={<UiIconMaterial>refresh</UiIconMaterial>}
                    variant="filled"
                    fullWidth>
                    <span>Reload</span>
                </UiButton>

                {response && orders.length === 0 && <UiAlert>No orders found</UiAlert>}
            </UiGrid>

            {orders.map((order) => (
                <DeliveryStationOrderItem order={order} key={order.id} />
            ))}

            <UiGrid outerSpacing={2}>
                {orders.length > 0 && orders.length === take ? (
                    <UiButton isLoading={isLoading} onClick={() => setTake(take + pageSize)} variant="filled">
                        Load more
                    </UiButton>
                ) : (
                    <UiBox padding={2}>&nbsp;</UiBox>
                )}
            </UiGrid>
        </div>
    );
}
