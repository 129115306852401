import { getBookXApiUrl } from '../config';
import { httpGet, httpPut } from '../shared/api';

const getUrl = getBookXApiUrl;

export const MicroserviceBookX = {
    getMe: () => httpGet(getUrl('me')),
    getLocationById: locationId => httpGet(getUrl(`locations/${locationId}`)),
};

export async function getLocationById(locationId) {
    return httpGet(getUrl(`locations/${locationId}`));
}

export async function getLocationQueue(locationId, { status, orderBy, orderByDirection } = {}) {
    return httpGet(getUrl(`queue/locations/${locationId}/queue`), { status, orderBy, orderByDirection });
}

export async function setUserAsNext(locationId, queueId) {
    await httpGet(getUrl(`queue/locations/${locationId}/queue/${queueId}/set-as-next`));
}

export async function cancelLocationQueueItem(locationId, queueId) {
    return httpGet(getUrl(`queue/locations/${locationId}/queue/${queueId}/cancel`));
}

export async function sendLocationQueueInvite(locationId, queueId) {
    return httpGet(getUrl(`queue/locations/${locationId}/queue/${queueId}/send`));
}

export async function getLocationQueueStats(locationId) {
    return httpGet(getUrl(`queue/locations/${locationId}/queue-stats`));
}

export async function sendLocationQueueInvites(locationId, numberOfInvites) {
    return httpPut(getUrl(`queue/locations/${locationId}/queue-send-invites`), { numberOfInvites });
}

export async function resetQueueCount(locationId) {
    return httpPut(getUrl(`queue/locations/${locationId}/queue-reset`));
}

export async function registerLocationQueueHash(hash) {
    return httpGet(getUrl(`queue/invites/${hash}/register`));
}
