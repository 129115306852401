import React from 'react';
import { restartApp, sync, useCodePush } from '../../shared/code-push';
import { isNative, useDevice } from '../../shared/device';
import { useUser } from '../../stores/user';
import { useStateStorage } from '../../use/state-storage';
import { Dev } from '../Dev';
import { UiSwitch } from '../ui/UiSwitch';
import { List } from '../../@v2/components/list/List';
import { ListItem } from '../../@v2/components/list/item/ListItem';
import { UiIconMaterial } from '@orderx/ui';

export function V2CodePush() {
    const { isTestUser } = useUser();
    const { statusLabel, version, isCheckingForUpdates } = useCodePush();
    const { appVersion } = useDevice();
    const [isDev, setIsDev] = useStateStorage('codePushDev', false);

    if (!isNative()) {
        return null;
    }

    const toggleDev = (isDev) => {
        setIsDev(isDev);
        sync();
    };

    return (
        <Dev>
            <List title="CodePush">
                <ListItem title={'Status'} subtitle={statusLabel || '-'} />

                {isTestUser && (
                    <ListItem
                        title={'Development'}
                        iconEnd={<UiSwitch checked={isDev} onChange={() => toggleDev(!isDev)} />}
                    />
                )}

                <ListItem
                    title="Restart app"
                    iconStart={<UiIconMaterial>power_settings_new</UiIconMaterial>}
                    onClick={restartApp}
                />

                {isCheckingForUpdates ? (
                    <ListItem title="Checking for updates..." iconStart={<UiIconMaterial>sync</UiIconMaterial>} />
                ) : (
                    <ListItem
                        title="Check for updates"
                        iconStart={<UiIconMaterial>sync</UiIconMaterial>}
                        onClick={sync}
                    />
                )}

                <ListItem title="Current version" subtitle={`${appVersion || ''} ${version || ''}`} />
            </List>
        </Dev>
    );
}
