import { isNative } from './device';
import { toPromise } from './promise';

export async function scan() {
    if (!isNative()) {
        return;
    }

    document.querySelector('html').classList.add('transparent');
    window.QRScanner.show();
    return toPromise(window.QRScanner.scan);
}

export function stopScan() {
    if (!isNative()) {
        return;
    }

    document.querySelector('html').classList.remove('transparent');
    document.querySelector('html').style = '';
    document.querySelector('body').style = '';

    return new Promise(resolve => {
        window.QRScanner.destroy(() => {
            document.querySelector('html').classList.remove('transparent');
            document.querySelector('html').style = '';
            document.querySelector('body').style = '';
            resolve();
        });
    });
}
