import clsx from 'clsx';
import React from 'react';
import './PosGroupFooter.scss';

export function PosGroupFooter({ children, style }) {
    return (
        <div className={clsx('PosGroupFooter')} style={style}>
            {children}
        </div>
    );
}
