import IconClose from '@material-ui/icons/CloseRounded';
import IconMore from '@material-ui/icons/MoreVertRounded';
import makeStyles from '@material-ui/styles/makeStyles';
import clsx from 'clsx';
import groupBy from 'lodash/groupBy';
import truncate from 'lodash/truncate';
import uniq from 'lodash/uniq';
import React, { useMemo, useState } from 'react';
import { MicroserviceOrders, putOrderProductsAction } from '../../../../microservices/orders';
import { ProductStatus } from '../../../../models/orders/product';
import { toMoment } from '../../../../shared/moment';
import { useSnackbar } from '../../../../shared/snackbar';
import { translate } from '../../../../shared/translate';
import { useDeviceConfig } from '../../../../stores/device-config';
import { useMedia } from '../../../../stores/media';
import { useStaffOrderingBar } from '../../../../stores/staff-ordering-bar';
import { useStaffStationConfiguration } from '../../../../stores/staff-station-configuration';
import { useUser } from '../../../../stores/user';
import { themeActive } from '../../../../themes/dark';
import { useApiQuery } from '@orderx/http';
import { formatStaffName } from '../../../../util/format-staff-name';
import { OrderUpdateSubscription } from '../../../OrderUpdateSubscription';
import { StaffOrderStationListItemComment } from './comment/StaffOrderStationListItemComment';
import { StaffOrderStationListItemProductChip } from './product-chip/StaffOrderStationListItemProductChip';
import './StaffOrderStationListItem.scss';
import orderBy from 'lodash/orderBy';
import { UiButton, UiFlex } from '@orderx/ui';

const useStyles = makeStyles({
    listItemDelivered: {
        background: themeActive.colors.success,
    },
    ready: {
        background: themeActive.colors.success,
    },
    sequence: {
        width: 32,
        fontWeight: 700,
        height: '100%',
        position: 'relative',
    },
    textSmall: {
        fontSize: 8,
    },
    sequenceLoader: {
        position: 'absolute',
        top: 'calc(50% - 12px)',
        left: 'calc(50% - 12px)',
        color: themeActive.colors.faded,
    },
    groupName: {
        width: 64,
        height: '100%',
    },
    chipContainer: {
        paddingTop: 4,
        paddingBottom: 4,
    },
    statusesContainer: {
        background: '#222',
        position: 'absolute',
        top: 0,
        left: 0,
        height: '100%',
        width: '100%',
        zIndex: 1,
        animationDuration: '200ms !important',
        borderRadius: 4,
        paddingLeft: 8,
        paddingRight: 8,
    },
    closeButton: { maxWidth: 40 },
    buttonStatus: {
        backgroundColor: themeActive.colors.background,
    },
    buttonNextStatus: {
        background: themeActive.gradients.primary,
    },
    phoneLimitWidth: {
        maxWidth: 64,
    },
    buttonComment: {
        position: 'absolute',
    },
    containerExtendedView: {
        fontSize: 12,
        fontWeight: 700,
        width: 48,
    },
});

export function StaffOrderStationListItem({ order, group, onUpdate, compact, onArchived }) {
    const { orderByType, OrderByType } = useStaffStationConfiguration();
    const { isStationCategoryStatusAllowed, config } = useDeviceConfig();
    const { barId } = useStaffOrderingBar();
    const { isTestUser } = useUser();
    const classes = useStyles();
    const { isPhone } = useMedia();
    const { showSuccess } = useSnackbar();
    const [isStatusesVisible, setIsStatusesVisible] = useState(false);
    const groupName = group ? group.name : '';
    const products = order.products;

    const uniqProductStatuses = useMemo(() => uniq(products.map((product) => product.status)), [products]);
    const productsSingleStatus = uniqProductStatuses.length === 1 && uniqProductStatuses[0];

    const nextStatus = productsSingleStatus && getNextStatus(productsSingleStatus);

    const productsGroupedByLocationProductId = useMemo(() => {
        const groupedProducts = groupBy(products, (product) => product.locationProductId);

        let productsGrouped = Object.keys(groupedProducts).map((locationProductId) => ({
            id: locationProductId,
            name: groupedProducts[locationProductId][0].name,
            count: groupedProducts[locationProductId].length,
            products: groupedProducts[locationProductId],
            price: groupedProducts[locationProductId][0].priceIncludingVat,
        }));

        if (orderByType === OrderByType.ProductPriceDescending) {
            productsGrouped = orderBy(productsGrouped, (product) => product.price, 'desc');
        }

        return productsGrouped;
    }, [products, orderByType]);

    const { run: updateProductsToStatus, isLoading: isLoadingStatusUpdate } = useApiQuery(async (status) => {
        const response = await MicroserviceOrders.staffApp({
            action: 'UPDATE_PRODUCTS_STATUS',
            orderId: order.id,
            productIds: products.map((product) => product.id),
            status,
        });

        await onUpdate(response);
        setIsStatusesVisible(false);
        showSuccess('Order updated');
    });

    const { run: loadOrder } = useApiQuery(async () => {
        const response = await MicroserviceOrders.staffApp({
            action: 'FIND_ORDER',
            orderId: order.id,
        });

        onUpdate({ orders: [response] });
    });

    function isAllProductsChangeAllowed(status) {
        const filteredProducts = order.products.filter((product) =>
            isStationCategoryStatusAllowed(product.category, status),
        );

        return filteredProducts.length === order.products.length;
    }

    function getStatusButton(label, status) {
        if (productsSingleStatus === status || !isAllProductsChangeAllowed(status)) {
            return null;
        }

        return (
            <UiButton
                size={-1}
                onClick={() => updateProductsToStatus(status)}
                className={classes.buttonStatus}
                disabled={isLoadingStatusUpdate}>
                {label}
            </UiButton>
        );
    }

    const { run: archiveOrder, isLoading: isArchiving } = useApiQuery(async () => {
        await putOrderProductsAction(barId, order.id, { action: 'ARCHIVE_ORDER' });
        showSuccess('Order archived');
        onArchived();
    });

    const isAllProductsDelivered = productsSingleStatus === ProductStatus.Delivered;

    return (
        <>
            <OrderUpdateSubscription orderId={order.id} callback={loadOrder} />

            <div className={clsx('StaffOrderStationListItem', isAllProductsDelivered && classes.listItemDelivered)}>
                {order.isCustomerPlaced && <div className="customer-placed">Placed by customer</div>}

                <UiFlex spacing={1}>
                    <UiFlex justifyContent={'center'} className={clsx('sequence', productsSingleStatus)}>
                        {order.sequence}
                    </UiFlex>

                    {!compact && (
                        <UiFlex
                            alignItems={'flex-start'}
                            justifyContent={'center'}
                            direction={'column'}
                            className={classes.containerExtendedView}>
                            <div>{toMoment(order.createdAt).format('HH:mm')}</div>
                            <div>
                                {truncate(formatStaffName(order.placedByName) || '', {
                                    length: 10,
                                    omission: '..',
                                })}
                            </div>
                        </UiFlex>
                    )}

                    <UiFlex className={clsx(classes.groupName, isPhone && classes.phoneLimitWidth)}>{groupName}</UiFlex>

                    <div className={clsx(classes.chipContainer, isPhone && classes.phoneLimitWidth)}>
                        {productsGroupedByLocationProductId.map((product) => (
                            <StaffOrderStationListItemProductChip key={product.id} groupedProduct={product} />
                        ))}
                    </div>
                </UiFlex>

                <UiFlex>
                    {nextStatus && isAllProductsChangeAllowed(nextStatus?.value) && (
                        <UiButton
                            size={'small'}
                            variant={'contained'}
                            isLoading={isLoadingStatusUpdate}
                            className={classes.buttonNextStatus}
                            onClick={() => updateProductsToStatus(nextStatus.value)}>
                            {nextStatus.label}
                        </UiButton>
                    )}

                    <UiButton onClick={() => setIsStatusesVisible(!isStatusesVisible)} icon={<IconMore />} />
                </UiFlex>

                {isStatusesVisible && (
                    <UiFlex className={clsx(classes.statusesContainer, 'animated fadeIn')} flexChildren spacing={1}>
                        {getStatusButton('Not started', ProductStatus.Placed)}
                        {getStatusButton('In preparation', ProductStatus.InPreparation)}
                        {getStatusButton('Ready', ProductStatus.Ready)}
                        {getStatusButton('Handed out', ProductStatus.Delivered)}

                        {isTestUser && (
                            <UiButton
                                onClick={archiveOrder}
                                isLoading={isArchiving}
                                color={'primary'}
                                variant={'contained'}
                                size={-1}>
                                {translate('Archive')}
                            </UiButton>
                        )}

                        <UiButton
                            onClick={() => setIsStatusesVisible(false)}
                            className={classes.closeButton}
                            icon={<IconClose />}
                        />
                    </UiFlex>
                )}
            </div>

            {config.isCommentsVisibleInListView && order.products.filter((product) => product.comment).length > 0 && (
                <StaffOrderStationListItemComment order={order} />
            )}
        </>
    );
}

function getNextStatus(currentStatus) {
    if (currentStatus === ProductStatus.Placed) {
        return {
            label: 'Start',
            value: ProductStatus.InPreparation,
        };
    }

    if (currentStatus === ProductStatus.InPreparation) {
        return {
            label: 'Ready',
            value: ProductStatus.Ready,
        };
    }

    if (currentStatus === ProductStatus.Ready) {
        return {
            label: 'Handed out',
            value: ProductStatus.Delivered,
        };
    }
}
