import IconBack from '@material-ui/icons/ArrowBackRounded';
import IconComment from '@material-ui/icons/CommentRounded';
import IconResetOrder from '@material-ui/icons/DeleteForeverRounded';
import IconProducts from '@material-ui/icons/FastfoodRounded';
import IconMore from '@material-ui/icons/MoreVertRounded';
import IconRedeem from '@material-ui/icons/RedeemRounded';
import IconPreviousOrders from '@material-ui/icons/ListRounded';
import makeStyles from '@material-ui/styles/makeStyles';
import React, { useCallback, useEffect, useState } from 'react';
import { NfcButton } from '../../components/NfcButton';
import PrintReceiptButton from '../../components/PrintReceiptButton';
import { StaffOrderDiscounts } from '../../components/staff-order/StaffOrderDiscounts';
import { StaffOrderPlaceDialog } from '../../components/staff-order/StaffOrderPlaceDialog';
import { StaffOrderPlaceQuery } from '../../components/staff-order/StaffOrderPlaceQuery';
import { StaffOrderProductTable } from '../../components/staff-order/StaffOrderProductTable';
import { StaffOrderViewBalancePay } from '../../components/staff-order/StaffOrderViewBalancePay';
import { StaffOrderViewComment } from '../../components/staff-order/StaffOrderViewComment';
import { StaffOrderViewPreviousOrders } from '../../components/staff-order/StaffOrderViewPreviousOrders';
import { StaffOrderViewProducts } from '../../components/staff-order/StaffOrderViewProducts';
import { StaffTerminalsSliderBottom } from '../../components/StaffTerminalsSliderBottom';
import { UiIconDiscount } from '../../components/ui/icon/UiIconDiscount';
import { UiIconManualEntry } from '../../components/ui/icon/UiIconManualEntry';
import { UiIconPosTerminal } from '../../components/ui/icon/UiIconPosTerminal';
import { UiButton } from '../../@deprecated/UiButton';
import { UiFlex } from '@orderx/ui';
import { UiPageHeader } from '../../components/ui/UiPageHeader';
import { MicroserviceOrders } from '../../microservices/orders';
import { useOnWindowFocus } from '../../shared/browser';
import { useDeviceConfig } from '../../stores/device-config';
import { useStaffOrderingBar } from '../../stores/staff-ordering-bar';
import { themeActive } from '../../themes/dark';
import { useCheckboxes } from '../../use/checkbox';
import { useApiQuery } from '@orderx/http';
import { useStaffOrder } from '../../use/staff-order';

const useStyles = makeStyles({
    page: {
        paddingLeft: 16,
        paddingRight: 16,
    },
    container: {
        padding: 16,
    },
    buttonsContainer: {
        whiteSpace: 'nowrap',
    },
    extraActionsContainer: {
        position: 'sticky',
        zIndex: 1,
        top: themeActive.header.height,
        backgroundColor: themeActive.header.backgroundColor,
        height: themeActive.header.height,
        paddingLeft: 8,
        paddingRight: 8,
    },
    content: {
        flex: 1,
    },
});

const VIEW = {
    DEFAULT: 'DEFAULT',
    DISCOUNT: 'DISCOUNT',
    TERMINAL: 'TERMINAL',
    PAY_WITH_BALANCE: 'PAY_WITH_BALANCE',
    COMMENT: 'COMMENT',
    PREVIOUS_ORDERS: 'PREVIOUS_ORDERS',
};

export function StaffOrderCreatePage({ preSelectedGroup, onSuccess, onBack }) {
    const classes = useStyles();
    const [view, setView] = useState(VIEW.DEFAULT);
    const { config } = useDeviceConfig();
    const { barIdStaffOrdering, barName, products } = useStaffOrderingBar();
    const barId = preSelectedGroup?.barId || barIdStaffOrdering;
    const { run: loadViewData, isLoading, response } = useApiQuery(() =>
        MicroserviceOrders.staffFindCreateViewData({ barId }),
    );
    const groups = (preSelectedGroup && [preSelectedGroup]) || (response && response.groups) || [];
    const [isManualEntry, setIsManualEntry] = useState(false);
    const [isModeTerminals, setIsModeTerminals] = useState(false);
    const [isExtraActionsVisible, setIsExtraActionsVisible] = useState(false);
    const [isGroupsDialogOpen, setIsGroupsDialogOpen] = useState(false);
    const groupId = preSelectedGroup ? preSelectedGroup.id : undefined;

    const {
        products: orderProducts,
        totalProductsAdded,
        setTagId,
        resetTagId,
        setOrderToPlace,
        resetOrder,
        applyDiscount,
        order,
    } = useStaffOrder();

    const hasProducts = totalProductsAdded > 0;

    useEffect(() => {
        loadViewData();
    }, []);

    useEffect(() => {
        if (!isGroupsDialogOpen) {
            resetTagId();
        }
    }, [isGroupsDialogOpen]);

    useEffect(() => {
        return () => resetOrder();
    }, []);

    useEffect(() => {
        if (!isExtraActionsVisible) {
            setIsManualEntry(false);
        }
    }, [isExtraActionsVisible]);

    useOnWindowFocus(loadViewData);

    const handleBalanceId = useCallback(
        (id) => {
            if (hasProducts) {
                setTagId(id);

                setOrderToPlace({
                    ...order,
                    tagId: id,
                    groupId,
                });
            }
        },
        [hasProducts, order],
    );

    const handleTag = useCallback(
        (id, type) => {
            if (hasProducts) {
                setOrderToPlace({
                    ...order,
                    tagId: id,
                    tagType: type,
                    groupId,
                });
            }
        },
        [hasProducts, order],
    );

    function toggleView(targetView) {
        setView(targetView === view ? VIEW.DEFAULT : targetView);
    }

    function resetViewState() {
        setView(VIEW.DEFAULT);
        resetOrder();
    }

    const getIconButton = useCallback(
        ({ targetView, onClick, Icon, active = false }) => (
            <UiButton
                onClick={() => {
                    if (targetView) {
                        toggleView(targetView);
                    }

                    if (onClick) {
                        onClick();
                    }
                }}
                icon={<Icon color={view === targetView || active ? 'primary' : 'inherit'} />}
            />
        ),
        [view],
    );

    const checkboxes = useCheckboxes({
        models: orderProducts,
    });

    const isPlaceOrderHidden = [VIEW.PAY_WITH_BALANCE, VIEW.COMMENT].includes(view);

    return (
        <>
            <UiPageHeader isLoading={isLoading} spacingLeft={preSelectedGroup ? 1 : 2}>
                {preSelectedGroup ? (
                    <UiFlex spacing={1}>
                        <UiButton onClick={() => onBack()} icon={<IconBack />} />
                        <h4>{preSelectedGroup.name}</h4>
                    </UiFlex>
                ) : (
                    <h4>{barName}</h4>
                )}

                <div className={classes.buttonsContainer}>
                    {hasProducts && (
                        <>
                            {getIconButton({
                                onClick: resetViewState,
                                Icon: IconResetOrder,
                            })}
                        </>
                    )}

                    {!isExtraActionsVisible &&
                        getIconButton({
                            targetView: VIEW.DEFAULT,
                            Icon: IconProducts,
                        })}

                    {isModeTerminals && <PrintReceiptButton />}

                    {getIconButton({
                        onClick: () => setIsModeTerminals(!isModeTerminals),
                        Icon: UiIconPosTerminal,
                        active: isModeTerminals,
                    })}

                    {getIconButton({
                        onClick: () => setIsExtraActionsVisible(!isExtraActionsVisible),
                        Icon: IconMore,
                        active: isExtraActionsVisible,
                    })}
                </div>
            </UiPageHeader>

            {isExtraActionsVisible && (
                <UiFlex className={classes.extraActionsContainer} justifyContent={'space-between'}>
                    <div>
                        {getIconButton({
                            targetView: VIEW.DEFAULT,
                            Icon: IconProducts,
                        })}

                        {config.isOrderCommentsEnabled &&
                            getIconButton({
                                targetView: VIEW.COMMENT,
                                Icon: IconComment,
                            })}

                        {getIconButton({
                            targetView: VIEW.DISCOUNT,
                            Icon: UiIconDiscount,
                        })}

                        {getIconButton({
                            targetView: VIEW.PAY_WITH_BALANCE,
                            Icon: IconRedeem,
                        })}

                        {getIconButton({
                            targetView: VIEW.PREVIOUS_ORDERS,
                            Icon: IconPreviousOrders,
                        })}
                    </div>

                    <div>
                        {getIconButton({
                            onClick: () => setIsManualEntry(!isManualEntry),
                            Icon: UiIconManualEntry,
                            active: isManualEntry,
                        })}
                    </div>
                </UiFlex>
            )}

            {view === VIEW.DISCOUNT && (
                <div className={classes.container}>
                    <StaffOrderDiscounts
                        onApplyDiscount={(discount) => {
                            applyDiscount(checkboxes.checkedModels, discount);
                            checkboxes.reset();
                        }}
                        disabled={checkboxes.checkedModels.length === 0}
                    />
                </div>
            )}

            {response && (
                <UiFlex spacing={1} alignItems={'flex-start'}>
                    <div className={classes.content}>
                        {view === VIEW.DISCOUNT && (
                            <StaffOrderProductTable products={orderProducts} checkboxes={checkboxes} />
                        )}

                        {view === VIEW.PAY_WITH_BALANCE && (
                            <StaffOrderViewBalancePay
                                products={orderProducts}
                                onPayWithBalance={(id) => handleBalanceId(id)}
                            />
                        )}

                        {view === VIEW.DEFAULT && <StaffOrderViewProducts locationProducts={products} />}

                        {view === VIEW.COMMENT && <StaffOrderViewComment />}

                        {view === VIEW.PREVIOUS_ORDERS && <StaffOrderViewPreviousOrders />}
                    </div>
                </UiFlex>
            )}

            <StaffOrderPlaceQuery
                onSuccess={() => {
                    resetViewState();
                    setIsGroupsDialogOpen(false);

                    if (onSuccess) {
                        onSuccess();
                    }
                }}
            />

            {!isPlaceOrderHidden && (
                <>
                    <StaffOrderPlaceDialog
                        groups={groups}
                        hidePlaceOrderButton={isModeTerminals}
                        isGroupsDialogOpen={isGroupsDialogOpen}
                        setIsGroupsDialogOpen={setIsGroupsDialogOpen}
                    />

                    {isModeTerminals && (
                        <>
                            {hasProducts && <NfcButton onTagRead={({ id }) => handleTag(id, 'nfc')} />}

                            <StaffTerminalsSliderBottom
                                barId={barId}
                                onClickOrder={() => {}}
                                onClickTerminal={(terminal) => handleTag(terminal.id)}
                            />
                        </>
                    )}
                </>
            )}
        </>
    );
}
