import React, { useEffect } from 'react';
import { MicroserviceLocation } from '../../microservices/location';
import { MicroserviceOrders } from '../../microservices/orders';
import { useAwsIotSubscription } from '../../providers/aws-iot';
import { useOnWindowFocus } from '../../shared/browser';
import { useCreateOrderBar } from '../../stores/create-order-bar';
import { useApiQuery } from '@orderx/http';
import { SplashScreen } from '../splash-screen/SplashScreen';
import { StaffCreatePage } from './StaffCreatePage';

export function StaffCreatePageDataResolver({ match }) {
    const { barId } = match.params;
    const { run, response } = useApiQuery((barId) => MicroserviceLocation.findBarById(barId));
    const { id, setActiveBar, retrieveFromCache } = useCreateOrderBar();

    useEffect(() => {
        if (barId) {
            run(barId);
            retrieveFromCache(barId);
        }
    }, [barId]);

    useEffect(() => {
        if (response) {
            setActiveBar(response);
        }
    }, [response]);

    return id === barId ? (
        <>
            <Data barId={barId} />
            <StaffCreatePage />
        </>
    ) : (
        <SplashScreen />
    );
}

function Data({ barId }) {
    const { setGroupsByBarId, setActiveBar } = useCreateOrderBar();
    const { run, response } = useApiQuery(() => MicroserviceLocation.findBarById(barId));

    const { run: loadGroups, response: groups } = useApiQuery(() =>
        MicroserviceOrders.staffApp({
            action: 'FIND_GROUPS',
            barId,
        }),
    );

    const update = () => {
        loadGroups();
        run();
    };

    useEffect(() => {
        setGroupsByBarId(barId, groups || []);
    }, [groups]);

    useEffect(() => {
        if (response) {
            setActiveBar(response);
        }
    }, [response]);

    useEffect(update, []);
    useOnWindowFocus(update);
    useAwsIotSubscription(barId && `location/${barId}/publish`, update);
    useAwsIotSubscription(barId && `location/${barId}/products-updated`, update);

    return null;
}
