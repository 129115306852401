import { storage } from '../shared/storage';
import { createStore, useStore } from '../create-store';

const StorageKey = 'staffActiveBar';
const store = createStore(storage.get(StorageKey));

export function useStaffActiveBar() {
    const [bar] = useStore(store);
    return bar;
}

export function setStaffActiveBar(bar) {
    store.set(bar);
    storage.set(StorageKey, bar);
}
