// NB! DO NOT EDIT THIS FILE! THIS FILE IS AUTOGENERATED AND WILL BE OVERWRITTEN

import { Api } from '@orderx/http';

export function apiPublicZoneZoneIdGet({
    body,
    params,
    query,
}: {
    body?: any;
    params: { zoneId: any };
    query?: any;
}): Promise<any> {
    return Api.request('public/zone/:zoneId', { method: 'get', body, params, query });
}
