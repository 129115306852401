import React from 'react';
import { PosGroupProducts } from '../products/PosGroupProducts';
import { StaffOrderGroupDialogSpeedDial } from '../../../staff-order/StaffOrderGroupDialogSpeedDial';
import { PosGroupFooter } from '../footer/PosGroupFooter';
import { usePosGroup } from '../../../../hooks/pos/group';

export function PosGroupProductActions() {
    const { group, loadGroup, productsChecked } = usePosGroup();

    return (
        <>
            <PosGroupProducts isSelectable />

            {productsChecked.length > 0 && (
                <PosGroupFooter>
                    <StaffOrderGroupDialogSpeedDial group={group} products={productsChecked} onUpdate={loadGroup} />
                </PosGroupFooter>
            )}
        </>
    );
}
