import React from 'react';
import { NavLink } from 'react-router-dom';
import styled from 'styled-components';
import orderxHand from '../assets/orderx-hand.png';
import { Button } from '../components/Button';
import { Flex } from '../components/Flex';
import { Image } from '../components/Image';
import Padding from '../components/Padding';
import UserBalanceCountLabel from '../components/UserBalanceCountLabel';
import { V2Grid } from '../components/v2/V2Grid';
import { V2PageHeader } from '../components/v2/V2PageHeader';
import { routes, withParams } from '../shared/router';
import { UserBalanceType } from '../shared/user-balances';
import { stores, useStore } from '../stores';
import { UiAlert } from '@orderx/ui';

const UserBalanceCard = styled.div`
    background-image: ${(props) => props.theme.gradients.primary};
    border-radius: 8px;
    overflow: hidden;
    position: relative;
    display: flex;
    justify-content: space-between;
    flex-flow: column;

    &:not(:first-child) {
        margin-top: 16px;
    }
`;

const BottomLogoWrapper = styled.div`
    height: 32px;
    width: 32px;
    position: absolute;
    bottom: 16px;
    right: 16px;
    opacity: 0.25;
`;

const UserBalanceCountWrapper = styled.div`
    padding: 16px 16px 48px;
`;

const UserBalanceTypeLabel = styled.div`
    text-transform: uppercase;
    letter-spacing: 1px;
    font-size: 0.75rem;
    padding: 4px 16px;
    background: rgba(0, 0, 0, 0.5);
    border-radius: 40px;
    position: absolute;
    bottom: 8px;
    left: 8px;
`;

const Wrapper = styled.div`
    padding: 16px;
`;

export function CustomerPaymentMethods({ history }) {
    const [{ userBalances = [] }] = useStore(stores.user);

    const typeLabel = {
        [UserBalanceType.Fiat]: 'Credit',
        [UserBalanceType.Count]: 'Vouchers',
        [UserBalanceType.Vouchers]: 'Vouchers',
    };

    return (
        <>
            <V2PageHeader title="Payment methods" onClickBack={() => history.push(routes.customerProfile)} />

            <Wrapper>
                <V2Grid>
                    {userBalances.length === 0 && (
                        <div>
                            <UiAlert>You don't have any payment methods</UiAlert>
                            <Button block>Add credit card</Button>
                        </div>
                    )}

                    {userBalances.length > 0 && (
                        <div>
                            {userBalances.map((userBalance) => (
                                <UserBalanceCard key={userBalance.id + userBalance.venueId}>
                                    <Padding horizontal top>
                                        <Flex spaceBetween>
                                            <NavLink
                                                to={withParams(routes.customerBrowseVenue, {
                                                    venueId: userBalance.venueId,
                                                })}>
                                                {userBalance.venueName || '-'}
                                            </NavLink>

                                            <span>{userBalance.id}</span>
                                        </Flex>
                                    </Padding>

                                    <UserBalanceCountWrapper>
                                        <UserBalanceCountLabel count={userBalance.count} type={userBalance.type} />
                                    </UserBalanceCountWrapper>

                                    <UserBalanceTypeLabel>
                                        {typeLabel[userBalance.type] || 'Unknown type'}
                                    </UserBalanceTypeLabel>

                                    <BottomLogoWrapper>
                                        <Image src={orderxHand} />
                                    </BottomLogoWrapper>
                                </UserBalanceCard>
                            ))}
                        </div>
                    )}
                </V2Grid>
            </Wrapper>
        </>
    );
}
