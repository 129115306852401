import React from 'react';
import { NavLink } from 'react-router-dom';
import styled from 'styled-components';
import { routes } from '../shared/router';
import { mixins } from '../shared/styles';
import { useStaffActiveBar } from '../stores/staff-active-bar';
import { useUser } from '../stores/user';
import { Svg } from './Svg';
import { Text } from './Text';
import { V2PageHeader } from './v2/V2PageHeader';

const Wrapper = styled.div`
    .header-title {
        display: flex;
        flex-flow: column;
        justify-content: center;
        align-items: center;

        h4 {
            ${mixins.flexXY};
            color: ${props => props.theme.colors.primary};
            font-weight: bold;
            white-space: nowrap;
        }
    }
`;

export default function StaffBarPageHeader({ right }) {
    const bar = useStaffActiveBar();
    const { name } = useUser();

    return (
        <V2PageHeader contentRight={right}>
            <Wrapper>
                <NavLink to={routes.staffBarChoose} className="header-title">
                    <Text small faded>
                        {bar.venueName}
                    </Text>

                    <h4>
                        <Svg icon="arrow-down" size={12} />
                        &nbsp;
                        {bar.name}
                        &nbsp;
                        <Svg icon="arrow-down" size={12} />
                    </h4>

                    <Text small>{name}</Text>
                </NavLink>
            </Wrapper>
        </V2PageHeader>
    );
}
