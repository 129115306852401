import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useDeliveryView } from '../../hooks/delivery/view';
import { useDeliveryLocations } from '../../hooks/delivery/locations';
import { BlockLoader } from '../../@v2/components/block-loader/BlockLoader';
import { DeliveryStationPage } from '../delivery/station/DeliveryStationPage';
import { StaffBottomNav } from '../../components/staff-bottom-nav/StaffBottomNav';
import { useOrderBubble } from '../../@v2/hooks/order-bubble';

export function StaffStationPage() {
    useOrderBubble().useTemporaryHide();

    const { barId } = useParams();

    const [barNotFound, setBarNotFound] = useState(false);
    const { load } = useDeliveryLocations();
    const { bars, setBars } = useDeliveryView();

    useEffect(() => {
        findBar(barId);
    }, [barId]);

    async function findBar(barId) {
        try {
            const { venues } = await load();

            const bar = venues.flatMap((venue) => venue.bars.find((bar) => bar.id === barId)).filter(Boolean)[0];

            if (bar) {
                setBars([bar]);
            } else {
                setBarNotFound(true);
            }
        } catch (error) {
            console.error(error);
        }
    }

    return (
        <>
            {bars.length === 0 ? <BlockLoader /> : <DeliveryStationPage />}
            <StaffBottomNav />
        </>
    );
}

StaffStationPage.route = '/staff-station/:barId';
