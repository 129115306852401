import React, { Fragment, useState } from 'react';
import { UiText, UiButton, UiIconMaterial, UiInput, UiFlex, UiTableCellCheckbox } from '@orderx/ui';
import { CurrencyMinor } from '../../../../../../components/CurrencyMinor';
import { usePosOrder } from '../../../../../hooks/pos-order';
import { PosCreateProductPriceLabel } from '../../product/price-label/PosCreateProductPriceLabel';

interface Props {
    menuProduct: any;
    isChecked: boolean;
    setIsChecked: any;
}

export function PosCreateActiveOrderProductRow({ menuProduct, isChecked, setIsChecked }: Props) {
    const { useSummary, useOrderProducts } = usePosOrder();
    const { updateOrderProductByKey } = useOrderProducts();
    const { removeProduct } = useSummary();
    const [isCommentVisible, setIsCommentVisible] = useState(false);
    const [comment, setComment] = useState(menuProduct.comment || '');

    function toggleCommentVisible() {
        setIsCommentVisible(!isCommentVisible);
    }

    return (
        <Fragment>
            <tr>
                <UiTableCellCheckbox checked={isChecked} onChange={setIsChecked} />
                <td>
                    <UiText>{menuProduct.product.name}</UiText>
                </td>
                <td align="right">
                    <PosCreateProductPriceLabel product={menuProduct} />
                </td>
                <td align="right">
                    <UiButton
                        onClick={toggleCommentVisible}
                        color={menuProduct.comment ? 'success' : 'default'}
                        icon={<UiIconMaterial>comment</UiIconMaterial>}
                        iconCircle
                    />

                    <UiButton
                        onClick={() => removeProduct(menuProduct)}
                        icon={<UiIconMaterial>close</UiIconMaterial>}
                        iconCircle
                    />
                </td>
            </tr>

            {isCommentVisible && (
                <tr>
                    <td colSpan={4}>
                        <UiFlex spacing={1}>
                            <UiInput
                                value={comment}
                                onChangeValue={setComment}
                                placeholder="Add comment"
                                fullWidth
                                adornmentEnd={
                                    comment.length > 0 && (
                                        <UiButton
                                            onClick={() => setComment('')}
                                            icon={<UiIconMaterial>close</UiIconMaterial>}
                                        />
                                    )
                                }
                            />

                            <UiButton
                                onClick={() => {
                                    updateOrderProductByKey(menuProduct.key, { comment });
                                    toggleCommentVisible();
                                }}
                                variant="filled">
                                Save
                            </UiButton>
                        </UiFlex>
                    </td>
                </tr>
            )}
        </Fragment>
    );
}
