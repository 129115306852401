import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import imagePour from '../assets/pouring.jpg';
import { AnimateCount } from '../components/AnimateCount';
import { BackgroundCover } from '../components/BackgroundCover';
import { Card } from '../components/Card';
import { Currency, numberWithSpaces } from '../components/Currency';
import { DatepickerWithFilters } from '../components/DatepickerWithFilters';
import { Flex } from '../components/Flex';
import Padding from '../components/Padding';
import { TableTitle } from '../components/TableTitle';
import { Tabs } from '../components/Tabs';
import { Text } from '../components/Text';
import { V2Grid } from '../components/v2/V2Grid';
import { V2Page } from '../components/v2/V2Page';
import { V2PageHeader } from '../components/v2/V2PageHeader';
import { V2Table } from '../components/v2/V2Table';
import { getVenueTotalRevenueReport } from '../microservices/reporting';
import { routes, withParams } from '../shared/router';
import { showError } from '../shared/toasts';
import { translate } from '../shared/translate';
import { stores, useStore } from '../stores';
import { useInterval } from '../use/time';

const OverviewFilter = {
    Summary: 'SUMMARY',
    PerBar: 'PER_BAR',
};

const Title = styled.div`
    font-size: 3rem;
    font-weight: 300;
`;

const SubTitle = styled.div`
    font-size: 2rem;
    font-weight: 300;
`;

const ReportingBackground = styled.div`
    background: #000;
    padding: 32px 0;
    margin: 0 16px 16px 16px;
    border-radius: 12px;
    box-shadow: var(--box-shadow);
    position: relative;
    overflow: hidden;

    .PageSection {
        position: relative;
    }
`;

const PourLabel = styled.div`
    color: #aaa;
    text-transform: uppercase;
    font-size: 0.75rem;
    letter-spacing: 1px;
    margin-bottom: 8px;
`;

export function StaffVenueReportingTotalRevenue({ history }) {
    const [{ venue }] = useStore(stores.staff);
    const [report, setReport] = useState({
        venue: {
            id: '',
            name: '',
            total: 0,
            transactions: 0,
        },
        bars: [],
    });
    const [isLoading, setIsLoading] = useState(true);
    const [overviewFilter, setOverviewFilter] = useState(OverviewFilter.Summary);
    const [from, setFrom] = useState();
    const [to, setTo] = useState();

    const topProductsCount = 5;

    // TODO: Missing from API
    const currency = venue.currency || 'NOK';

    useEffect(() => {
        loadVenueTotalRevenueReport({ from, to });
    }, [from, to]);

    useInterval(
        5 * 1000,
        () => {
            loadVenueTotalRevenueReport({ from, to });
        },
        [from, to],
    );

    async function loadVenueTotalRevenueReport({ from, to }) {
        if (from && to) {
            setIsLoading(true);

            try {
                const report = await getVenueTotalRevenueReport({
                    venueId: venue.id,
                    fromMilliseconds: from.unix() * 1000,
                    toMilliseconds: to.unix() * 1000,
                });

                setReport(report);
            } catch (error) {
                showError(error);
            }

            setIsLoading(false);
        }
    }

    return (
        <V2Page
            header={
                <V2PageHeader
                    isLoading={isLoading}
                    title={`${venue.name} • Revenue`}
                    onClickBack={() => history.push(withParams(routes.staffVenueReporting, { venueId: venue.id }))}
                />
            }>
            <DatepickerWithFilters onChangeFrom={setFrom} onChangeTo={setTo} />

            {report && (
                <>
                    <ReportingBackground>
                        <BackgroundCover image={imagePour} />

                        <div>
                            <Text block center>
                                <V2Grid>
                                    <div>
                                        <PourLabel>Total revenue</PourLabel>

                                        <Title>
                                            <AnimateCount value={report.venue.total} currency={currency} />
                                        </Title>
                                    </div>

                                    <div>
                                        <PourLabel>Transactions</PourLabel>

                                        <SubTitle>
                                            <AnimateCount
                                                value={report.venue.transactions}
                                                formattingFunction={(amount) => `${numberWithSpaces(amount)}`}
                                            />
                                        </SubTitle>
                                    </div>
                                </V2Grid>
                            </Text>
                        </div>
                    </ReportingBackground>

                    {report.venue.total > 0 && (
                        <>
                            <Tabs
                                tabs={[
                                    {
                                        label: translate('Summary'),
                                        value: OverviewFilter.Summary,
                                    },
                                    {
                                        label: translate('Per bar'),
                                        value: OverviewFilter.PerBar,
                                    },
                                ]}
                                value={overviewFilter}
                                onChange={setOverviewFilter}
                            />

                            {overviewFilter === OverviewFilter.Summary && (
                                <div>
                                    <TableTitle>Summary</TableTitle>

                                    <V2Table headers={['Name', 'Total']}>
                                        {report.bars.map((bar) => (
                                            <TableRow key={bar.id}>
                                                <TableCell>{bar.name}</TableCell>

                                                <TableCell align="right">
                                                    <Currency amount={bar.total} />
                                                </TableCell>
                                            </TableRow>
                                        ))}
                                    </V2Table>
                                </div>
                            )}

                            {overviewFilter === OverviewFilter.PerBar &&
                                report.bars.map((bar) => (
                                    <div key={bar.id}>
                                        <TableTitle>{bar.name}</TableTitle>

                                        <Padding top bottom>
                                            <Flex>
                                                <Card>
                                                    <PourLabel>Total revenue</PourLabel>
                                                    <Text>
                                                        <Currency amount={bar.total} />
                                                    </Text>
                                                </Card>
                                                <Card>
                                                    <PourLabel>Transactions</PourLabel>
                                                    <Text>#{numberWithSpaces(bar.transactions)}</Text>
                                                </Card>
                                            </Flex>
                                        </Padding>

                                        <V2Table headers={['Name', 'Count', 'Total']}>
                                            {bar.products.slice(0, topProductsCount).map((product) => (
                                                <TableRow key={product.id}>
                                                    <TableCell>{product.name}</TableCell>
                                                    <TableCell align="right">
                                                        {numberWithSpaces(product.count)}
                                                    </TableCell>

                                                    <TableCell align="right">
                                                        <Currency amount={product.total} />
                                                    </TableCell>
                                                </TableRow>
                                            ))}
                                        </V2Table>
                                    </div>
                                ))}
                        </>
                    )}
                </>
            )}
        </V2Page>
    );
}
