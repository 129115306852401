import React from 'react';
import styled, { css } from 'styled-components';
import { themeActive } from '../themes/dark';

const padding = 12;

const Wrapper = styled.table`
    table-layout: auto;
    //border-collapse: collapse;
    width: 100%;
    border-spacing: 0 ${padding}px;
    border-collapse: separate;

    thead td {
        padding: 0 ${padding}px;
    }

    thead td:last-child {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        text-align: right;
    }

    thead {
        text-transform: uppercase;
        font-size: 0.75rem;
        letter-spacing: 1px;
        color: ${themeActive.colors.faded};
    }

    tbody tr {
        background-image: ${({ noBackground, theme }) => (noBackground ? 'none' : theme.gradients.main)};
        box-shadow: var(--box-shadow);
        border-radius: ${(props) => props.theme.sizes.borderRadius}px;
        overflow: hidden;

        td {
            padding: ${padding}px;
        }

        td:first-child {
            border-top-left-radius: ${(props) => props.theme.sizes.borderRadius}px;
            border-bottom-left-radius: ${(props) => props.theme.sizes.borderRadius}px;
        }

        td:last-child {
            border-top-right-radius: ${(props) => props.theme.sizes.borderRadius}px;
            border-bottom-right-radius: ${(props) => props.theme.sizes.borderRadius}px;
            display: flex;
            justify-content: flex-end;
            align-items: center;
        }
    }

    ${(props) =>
        !props.noRowBorders &&
        css`
            tbody tr:not(:first-child) {
                border-top: 1px solid #333;
            }
        `};
`;

export function Table({ children, noRowBorders }) {
    return <Wrapper noRowBorders={noRowBorders}>{children}</Wrapper>;
}
