import { isNative } from './device';

export function setKeyboardDefaults() {
    if (!isNative()) {
        return;
    }

    window.Keyboard.shrinkView(true);
    window.Keyboard.disableScrollingInShrinkView(true);
}
