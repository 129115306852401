import clsx from 'clsx';
import React, { useState } from 'react';
import { UiButton, UiIconMaterial } from '@orderx/ui';
import { OrderGroupPaymentReceiptDialog } from '../../../../components/OrderGroupPaymentReceiptDialog';

interface Props {
    payment: any;
    size?: any;
}

export function PaymentReceiptButton({ payment, size }: Props) {
    const [isReceiptOpen, setIsReceiptOpen] = useState(false);

    return (
        <div className={clsx('PaymentReceiptButton')}>
            <UiButton
                onClick={() => setIsReceiptOpen(true)}
                size={size}
                icon={<UiIconMaterial>receipt</UiIconMaterial>}
            />

            {isReceiptOpen && <OrderGroupPaymentReceiptDialog payment={payment} isOpen onClose={setIsReceiptOpen} />}
        </div>
    );
}
