import SvgIcon from '@material-ui/core/SvgIcon';
import React from 'react';

export function UiIconDiscount(props) {
    return (
        <SvgIcon {...props} viewBox={'-16 0 384 384.064'} width="384pt">
            <path d="m264.03125 0h-176c-17.648438 0-32 14.351562-32 32v160h-23.96875c-13.328125 0-25.039062 8.03125-29.847656 20.464844-4.808594 12.429687-1.535156 26.246094 8.457031 35.335937l143.832031 127.863281c6.160156 5.609376 13.871094 8.398438 21.574219 8.398438 7.664063 0 15.296875-2.757812 21.34375-8.261719l144.097656-128.128906c9.855469-8.960937 13.128907-22.785156 8.328125-35.207031-4.800781-12.425782-16.519531-20.464844-29.847656-20.464844h-23.96875v-160c0-17.648438-14.351562-32-32-32zm56.097656 223.871094-144.226562 128-143.839844-127.871094h23.96875c17.648438 0 32-14.351562 32-32v-160h176v160c0 17.648438 14.351562 32 32 32zm0 0" />
            <path d="m144.03125 128c8.832031 0 16-7.167969 16-16s-7.167969-16-16-16h-8c-8.832031 0-16 7.167969-16 16s7.167969 16 16 16zm0 0" />
            <path d="m216.03125 192h-8c-8.832031 0-16 7.167969-16 16s7.167969 16 16 16h8c8.832031 0 16-7.167969 16-16s-7.167969-16-16-16zm0 0" />
            <path d="m219.34375 116.6875c-6.242188-6.246094-16.386719-6.246094-22.625 0l-64 64c-6.246094 6.25-6.246094 16.375 0 22.625 3.121094 3.128906 7.214844 4.6875 11.3125 4.6875s8.191406-1.558594 11.3125-4.6875l64-64c6.246094-6.25 6.246094-16.375 0-22.625zm0 0" />
        </SvgIcon>
    );
}
