import { createStore } from '@orderx/store';

export enum Currency {
    NOK = 'NOK',
    DKK = 'DKK',
    EUR = 'EUR',
}

const currencyLocale = {
    [Currency.NOK]: 'no',
    [Currency.DKK]: 'dk',
    [Currency.EUR]: 'et',
};

const { useStore } = createStore(Currency.NOK);

export function useCurrency() {
    const [currency, setCurrency] = useStore();
    const locale = currencyLocale[currency];

    const { format } = new Intl.NumberFormat(locale, {
        style: 'currency',
        currency: currency,
        minimumFractionDigits: 2,
    });

    const symbol =
        {
            [Currency.EUR]: '€',
            [Currency.NOK]: 'Kr',
            [Currency.DKK]: 'kr.',
        }[currency] || currency;

    function formatMajor(value) {
        const formatted = format(value);

        const result = formatted.includes(currency)
            ? `${format(value).replace(currency, '')} ${symbol}`.trim()
            : formatted;

        if (currency === Currency.NOK) {
            return result.replace('.00', '');
        }

        return result;
    }

    return {
        symbol,
        currency,
        setCurrency,
        formatMinor: (value: number) => formatMajor(value / 100),
        formatMajor,
    };
}
