import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Button } from '../components/Button';
import { CssLoader } from '../components/CssLoader';
import { V2Grid } from '../components/v2/V2Grid';
import { V2List } from '../components/v2/V2List';
import { V2ListItem } from '../components/v2/V2ListItem';
import { getLocationQueueStats, resetQueueCount } from '../microservices/bookx';
import { showError, showSuccess } from '../shared/toasts';
import { useBookXLocation } from '../stores/bookx-location';
import { useInterval } from '../use/time';

const Wrapper = styled.div`
    padding: 1rem 0;
`;

const ButtonWrapper = styled.div`
    padding: 0 1rem;
`;

export function StaffQueueLocationStats() {
    const location = useBookXLocation();
    const [isLoading, setIsLoading] = useState(false);
    const [stats, setStats] = useState();

    useEffect(() => {
        loadStats();
    }, []);

    useInterval(5000, loadStats);

    async function loadStats() {
        try {
            setStats(await getLocationQueueStats(location.id));
        } catch (error) {
            console.error(error);
        }
    }

    async function resetQueue() {
        setIsLoading(true);

        try {
            await resetQueueCount(location.id);
            await loadStats();
            showSuccess('Queue has been reset');
        } catch (error) {
            showError(error);
        }

        setIsLoading(false);
    }

    if (!stats) {
        return <CssLoader />;
    }

    const { counts } = stats;

    return (
        <Wrapper>
            <V2Grid>
                <V2List title="Statistics">
                    {counts.map(({ label, count }) => (
                        <V2ListItem label={label} key={label} contentRight={count} />
                    ))}
                </V2List>

                <ButtonWrapper>
                    <Button onClick={resetQueue} isLoading={isLoading} negative block>
                        Reset queue count
                    </Button>
                </ButtonWrapper>
            </V2Grid>
        </Wrapper>
    );
}
