import clsx from 'clsx';
import React, { useEffect, useState } from 'react';
import { useOrderBubble } from '../../../hooks/order-bubble';
import {
    UiGrid,
    UiFlex,
    UiText,
    UiButton,
    UiIconMaterial,
    useUiInputPhone,
    UiAlert,
    UiTable,
    UiOverlay,
    UiToolbar,
} from '@orderx/ui';
import './OrderIdPage.scss';
import { OrderVideo } from '../../../components/order/video/OrderVideo';
import { useOrdersPlaced } from '../../../../stores/order-placed';
import { useParams } from 'react-router-dom';
import { BlockLoader } from '../../../components/block-loader/BlockLoader';
import { OrderDeliveryType, OrderStatus } from '../../../../models/orders/order';
import sum from 'lodash/sum';
import { ProductStatus } from '../../../../models/orders/product';
import { useApiQuery } from '@orderx/http';
import { MicroserviceOrders } from '../../../../microservices/orders';
import { ToolbarTitle } from '../../../components/toolbar/title/ToolbarTitle';
import { OrderTooltipOrderBubble } from '../../../components/order/tooltip/OrderTooltipOrderBubble';
import { CustomerBrowseGroupsHashPage } from '../../../../pages/CustomerBrowseGroupsHashPage';
import { CustomerBrowsePage } from '../../../../pages/customer/browse/CustomerBrowsePage';
import { useCurrency } from '../../../hooks/currency';
import { OrderIdViewFotografiska } from '../../../components/order/id/view/fotografiska/OrderIdViewFotografiska';
import { groupProductsByLocationProductId } from '../../../../util/group-products';
import { useOnResume } from '../../../../shared/browser';
import { useIotOrderUpdated } from '../../../../hooks/iot/order-updated';
import { utilGetDefaultAreaCode } from '../../../../util/get-default-area-code';
import { useInterval } from '../../../../use/interval';

export function OrderIdPage() {
    const { refresh, cacheOrderId } = useOrdersPlaced();
    const { orderId } = useParams();
    const { setCurrency } = useCurrency();

    useEffect(() => {
        refresh();
    }, [refresh]);

    const { run, response: order } = useApiQuery(() =>
        MicroserviceOrders.postAppCustomer({
            action: 'handlerAppCustomerOrderById',
            orderId,
        }),
    );

    const currency = order?.currency;

    useEffect(() => {
        if (orderId) {
            run(orderId);
        }
    }, [orderId]);

    useEffect(() => {
        if (currency) {
            setCurrency(currency);
        }
    }, [currency]);

    useOnResume(() => {
        run();
    }, [orderId]);

    useInterval(
        () => {
            run();
        },
        30 * 1000,
        [orderId],
    );

    useEffect(() => {
        if (order && order?.status !== OrderStatus.Archived) {
            cacheOrderId(orderId);
        }
    }, [order]);

    useIotOrderUpdated(
        orderId,
        () => {
            run(orderId);
        },
        [orderId],
    );

    if (!order || !orderId) {
        return (
            <div className="OrderIdPage">
                <BlockLoader />

                <UiFlex className="buttons" justifyContent="space-between">
                    <div />

                    <UiButton
                        to={CustomerBrowsePage.route}
                        variant="filled"
                        size={2}
                        icon={<UiIconMaterial>close</UiIconMaterial>}
                    />
                </UiFlex>
            </div>
        );
    }

    if (order.group.viewType === 'FOTOGRAFISKA') {
        return <OrderIdViewFotografiska order={order} />;
    }

    return <Index order={order} onUpdate={() => run(orderId)} />;
}

function Index({ order, onUpdate }) {
    const { load: loadOrders } = useOrdersPlaced();
    const { pickupSlotName, barName, products, customerPhoneAreaCode, customerPhoneNumber, group } = order;

    const groupName = group?.name || order.groupName;
    const { element, areaCode, phone, setAreaCode, setPhone, isValid } = useUiInputPhone();
    const [isDetailsVisible, setIsDetailsVisible] = useState(false);
    const [isEditPhone, setIsEditPhone] = useState(!customerPhoneNumber);
    const { setIsVisible } = useOrderBubble();
    const { formatMinor } = useCurrency();

    const isDeliveryPickup = order.deliveryType === OrderDeliveryType.Pickup;
    const isDeliveryTable = order.deliveryType === OrderDeliveryType.Table;

    const amountTotal = sum(products.map((product) => product.priceIncludingVat));

    const productsReady = products.filter((product) => product.status === ProductStatus.Ready);

    const numTotalProducts = products.length;

    const statusOfAllProducts =
        numTotalProducts > 0 &&
        products.filter((product) => product.status === products[0].status).length === numTotalProducts &&
        products[0].status;

    const isAllProductsDelivered = statusOfAllProducts === ProductStatus.Delivered;
    const groupHash = group?.hash;

    const isCancelled = order.status?.toUpperCase() === 'CANCELLED';
    const isDelivered =
        products.filter((product) => product.status === ProductStatus.Delivered).length === numTotalProducts;
    const isInDelivery = products.find((product) => product.status === ProductStatus.InDelivery);
    const isReady = products.find((product) => product.status === ProductStatus.Ready);
    const inPreparation = !isDelivered && products.find((product) => product.status === ProductStatus.InPreparation);

    const orderStatus = (isDelivered && { label: 'Delivered', stepActive: 4 }) ||
        (isInDelivery && { label: 'In Delivery', stepActive: 3 }) ||
        (isReady && { label: 'Ready', stepActive: 2 }) ||
        (inPreparation && { label: 'In Preparation', stepActive: 1 }) || { label: 'Order Placed', stepActive: 0 };

    const { run: updatePhone, isLoading: isUpdatingPhone } = useApiQuery(async () => {
        await MicroserviceOrders.postAppCustomer({
            action: 'handlerAppCustomerUpdateOrder',
            orderId: order.id,
            customerPhoneAreaCode: isValid() ? areaCode : '',
            customerPhoneNumber: isValid() ? phone : '',
        });

        await loadOrders();
        await onUpdate();

        setPhone('');
        setIsEditPhone(false);
    });

    useEffect(() => {
        return () => setIsVisible(true);
    }, []);

    useEffect(() => {
        if (customerPhoneAreaCode && customerPhoneNumber) {
            setAreaCode(customerPhoneAreaCode);
            setPhone(customerPhoneNumber);
        } else {
            setAreaCode(utilGetDefaultAreaCode());
        }
    }, [customerPhoneAreaCode, customerPhoneNumber]);

    return (
        <div className={clsx('OrderIdPage')}>
            <OrderTooltipOrderBubble />

            <div className="video-container">
                <div className="video">
                    <OrderVideo order={order} height={160} />
                </div>
            </div>

            <UiGrid gap={6} padding={4}>
                <UiGrid gap={2}>
                    <div>
                        <UiText className="sequence" weight={2}>
                            Order Nr: {order.sequence}
                        </UiText>
                    </div>

                    <UiGrid gap={1}>
                        <UiText size={5} weight={2} color={(productsReady.length > 0 && 'success') || 'default'}>
                            {orderStatus.label}
                        </UiText>

                        <UiText size={1} weight={2} color="text-secondary">
                            {barName} • {groupName}
                        </UiText>
                    </UiGrid>
                </UiGrid>

                {isCancelled ? (
                    <UiAlert color="error">
                        Your order has been cancelled
                        <br />
                        <br />
                        If you have made a payment, it will be returned to you.
                    </UiAlert>
                ) : (
                    <>
                        <UiFlex spacing={1}>
                            {[0, 1, 2, 3].map((i) => (
                                <div
                                    className={clsx(
                                        'segment',
                                        orderStatus.stepActive > i && 'done',
                                        orderStatus.stepActive === i && 'pending',
                                    )}>
                                    <div />
                                </div>
                            ))}
                        </UiFlex>

                        {isAllProductsDelivered ? (
                            <UiText size={3} weight={2} color="success" align="center">
                                All products have been delivered
                                <br />
                                <br />
                                Bon Appétit!
                            </UiText>
                        ) : (
                            <>
                                {productsReady.length > 0 && (
                                    <UiAlert color="success">
                                        <UiGrid gap={1}>
                                            <UiText size={1}>
                                                {isDeliveryPickup
                                                    ? 'Come and pick up the following products'
                                                    : 'Products ready:'}
                                            </UiText>

                                            {pickupSlotName && <UiText size={1}>Pickup at: {pickupSlotName}</UiText>}

                                            {productsReady.map((product) => (
                                                <UiText key={product.id} size={1}>
                                                    <UiFlex spacing={1}>
                                                        <UiIconMaterial>check</UiIconMaterial>
                                                        <span>{product.name}</span>
                                                    </UiFlex>
                                                </UiText>
                                            ))}
                                        </UiGrid>
                                    </UiAlert>
                                )}

                                {isDeliveryPickup && productsReady.length === 0 && (
                                    <UiGrid>
                                        {customerPhoneNumber && !isEditPhone ? (
                                            <>
                                                <UiText size={2}>
                                                    We will send an SMS to{' '}
                                                    <b onClick={() => setIsEditPhone(true)}>
                                                        +{customerPhoneAreaCode}
                                                        {customerPhoneNumber}
                                                    </b>{' '}
                                                    when you can come pick up products!
                                                </UiText>

                                                <UiText size={2}>NB! You can also order from other places...</UiText>
                                            </>
                                        ) : (
                                            <>
                                                <UiText size={2} weight={2}>
                                                    Please stay where you are! We will let you know when you can come
                                                    pick up your order!
                                                </UiText>

                                                <UiGrid className="alert">
                                                    <UiText size={2} align="center">
                                                        <UiFlex spacing={2}>
                                                            <UiIconMaterial>sms_failed</UiIconMaterial>
                                                            <span>How to contact you?</span>
                                                        </UiFlex>
                                                    </UiText>

                                                    <UiText size={1}>
                                                        Please enter your phone number so we can let you know when your
                                                        order is ready
                                                    </UiText>

                                                    <UiText>
                                                        <UiFlex spacing={1}>
                                                            {element}

                                                            <UiButton
                                                                size={1}
                                                                onClick={updatePhone}
                                                                isLoading={isUpdatingPhone}
                                                                variant={isValid() ? 'filled' : 'text'}
                                                                color="primary">
                                                                {isValid() ? 'Save' : 'Clear'}
                                                            </UiButton>
                                                        </UiFlex>
                                                    </UiText>

                                                    <UiText size={1} weight={2}>
                                                        NB! If you decide not to enter you phone,{' '}
                                                        <span className="line-through">
                                                            please check this screen from time to time
                                                        </span>{' '}
                                                        to get updates about your order
                                                    </UiText>
                                                </UiGrid>
                                            </>
                                        )}
                                    </UiGrid>
                                )}

                                {isDeliveryTable && (
                                    <>
                                        <UiText size={2}>
                                            We will bring the order to your table when it is ready. You can sit back and
                                            relax...
                                        </UiText>
                                    </>
                                )}
                            </>
                        )}
                    </>
                )}
            </UiGrid>

            <UiFlex className="buttons" justifyContent="space-between">
                <UiButton onClick={() => setIsDetailsVisible(!isDetailsVisible)} variant="filled" size={1}>
                    <UiFlex spacing={2}>
                        <UiIconMaterial>receipt</UiIconMaterial>
                        <span>Show details</span>
                    </UiFlex>
                </UiButton>

                <UiButton
                    to={groupHash ? CustomerBrowseGroupsHashPage.route : CustomerBrowsePage.route}
                    toParams={{ hash: groupHash }}
                    variant="filled"
                    size={2}
                    icon={<UiIconMaterial>close</UiIconMaterial>}
                />
            </UiFlex>

            {isDetailsVisible && (
                <UiOverlay className="animated fadeInUp" position="bottom" height="50vh">
                    <UiToolbar onClick={() => setIsDetailsVisible(false)}>
                        <ToolbarTitle title="Details" buttonBackOnClick={() => setIsDetailsVisible(false)} />
                    </UiToolbar>

                    <UiTable>
                        <thead>
                            <tr>
                                <td>Name</td>
                                <td />
                            </tr>
                        </thead>

                        <tbody>
                            {groupProductsByLocationProductId(products).map((product: any) => (
                                <tr key={product.locationProductId}>
                                    <td>
                                        {product.count} x {product.name}
                                    </td>
                                    <td align="right">{formatMinor(product.total)}</td>
                                </tr>
                            ))}

                            <tr>
                                <td align="right" colSpan={2}>
                                    <b>Total: {formatMinor(amountTotal)}</b>
                                </td>
                            </tr>
                        </tbody>
                    </UiTable>
                </UiOverlay>
            )}
        </div>
    );
}

OrderIdPage.route = '/order/:orderId';
