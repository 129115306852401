import React from 'react';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { useLocationTerminalSettings } from '../stores/location-terminal-settings';
import { useLocationTerminals } from '../stores/location-terminals';
import { useStaffActiveBar } from '../stores/staff-active-bar';
import { Checkbox } from './Checkbox';
import { V2List } from './v2/V2List';
import { V2ListItem } from './v2/V2ListItem';

const getItemStyle = (isDragging, draggableStyle) => ({
    userSelect: 'none',
    background: isDragging ? 'red' : 'grey',
    ...draggableStyle,
});

export function TerminalOrdering() {
    const { id: barId } = useStaffActiveBar();

    const { terminalIds, toggleTerminal, setTerminalOrderById, getOrderedTerminals } = useLocationTerminalSettings(
        barId,
    );

    const terminals = useLocationTerminals(barId);
    const terminalsOrdered = getOrderedTerminals(terminals);

    function reorder(startIndex, endIndex) {
        const terminals = Array.from(terminalsOrdered);
        const [removed] = terminals.splice(startIndex, 1);
        terminals.splice(endIndex, 0, removed);
        return terminals;
    }

    function onDragEnd(result) {
        const isDroppedOutsideList = !result.destination;

        if (isDroppedOutsideList) {
            return;
        }

        const { source, destination } = result;
        const sourceIndex = source.index;
        const targetIndex = destination.index;

        if (sourceIndex === targetIndex) {
            return;
        }

        const reordered = reorder(sourceIndex, targetIndex);

        setTerminalOrderById(
            reordered.reduce((terminalOrderById, terminal, i) => {
                terminalOrderById[terminal.id] = i;
                return terminalOrderById;
            }, {}),
        );
    }

    return (
        <V2List>
            <DragDropContext onDragEnd={onDragEnd}>
                <Droppable droppableId="droppable">
                    {provided => (
                        <div {...provided.droppableProps} ref={provided.innerRef}>
                            {terminalsOrdered.map((terminal, index) => (
                                <Draggable key={terminal.id} draggableId={terminal.id} index={index}>
                                    {(provided, snapshot) => (
                                        <div
                                            ref={provided.innerRef}
                                            {...provided.draggableProps}
                                            {...provided.dragHandleProps}
                                            style={getItemStyle(snapshot.isDragging, provided.draggableProps.style)}>
                                            <V2ListItem
                                                title={terminal.name}
                                                contentRight={
                                                    <Checkbox
                                                        isChecked={terminalIds.includes(terminal.id)}
                                                        onChange={() => toggleTerminal(terminal.id)}
                                                    />
                                                }
                                            />
                                        </div>
                                    )}
                                </Draggable>
                            ))}
                            {provided.placeholder}
                        </div>
                    )}
                </Droppable>
            </DragDropContext>
        </V2List>
    );
}
