import { useState } from 'react';
import times from 'lodash/times';
import { storageGet } from '../shared/storage';
import { utilGetDefaultAreaCode } from '../util/get-default-area-code';

export function usePhone(
    initialAreaCode = storageGet('rdxAreaCode') || storageGet('areaCode') || `+${utilGetDefaultAreaCode()}`,
    initialPhone = storageGet('rdxPhone') || storageGet('phone') || '',
) {
    const [areaCode, setAreaCode] = useState(initialAreaCode);
    const [phone, setPhone] = useState(initialPhone);

    return {
        areaCode,
        phone,
        getValue: () => `${areaCode}${phone}`,
        onChangeValue: ({ areaCode, phone }) => {
            setAreaCode(areaCode);
            setPhone(phone);
        },
    };
}

export function useInput(initialValue = '') {
    const [value, setValue] = useState(initialValue);

    return {
        value,
        setValue,
        reset: () => setValue(initialValue),
        onChange: (event) => setValue(event.target.value),
    };
}

export function usePin(numberOfInputs = 4, initialValue) {
    const [value, setValue] = useState(initialValue || times(numberOfInputs, () => ''));

    return {
        value,
        numberOfInputs,
        pin: value,
        getValue: () => value.filter((value) => value !== undefined && value !== null).join(''),
        reset: () => setValue(initialValue || times(numberOfInputs, () => '')),
        onChangeValue: (pin) => setValue(pin),
        setValue,
    };
}
