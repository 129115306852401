import Backdrop from '@material-ui/core/Backdrop';
import makeStyles from '@material-ui/core/styles/makeStyles';
import IconClose from '@material-ui/icons/CloseRounded';
import IconRemove from '@material-ui/icons/DeleteForeverRounded';
import IconMore from '@material-ui/icons/MoreVertRounded';
import IconPrint from '@material-ui/icons/PrintRounded';
import SpeedDial from '@material-ui/lab/SpeedDial';
import SpeedDialAction from '@material-ui/lab/SpeedDialAction';
import SpeedDialIcon from '@material-ui/lab/SpeedDialIcon';
import React, { useState } from 'react';
import { MicroserviceOrders } from '../../microservices/orders';
import { useSnackbar } from '../../shared/snackbar';
import { useStaffActiveBar } from '../../stores/staff-active-bar';
import { themeActive } from '../../themes/dark';
import { useApiQuery } from '@orderx/http';
import { usePrintPreSalesReceipt } from '../../util/use-print-pre-sales-receipt';
import { StaffOrderTerminalSelection } from './StaffOrderTerminalSelection';

const useStyles = makeStyles({
    backdrop: {
        zIndex: 1,
    },
    speedDial: {
        position: 'absolute',
        bottom: 16,
        left: 8,
    },
    speedDialButton: {
        height: 44,
        width: 44,
    },
    staticTooltipLabel: {
        whiteSpace: 'nowrap',
        background: '#222',
        color: themeActive.colors.text,
        textTransform: 'uppercase',
        letterSpacing: 1,
        fontWeight: 700,
        fontSize: '0.8rem',
    },
    fabSecondary: {
        background: themeActive.colors.error,
        color: themeActive.colors.text,
    },
});

export function StaffOrderGroupDialogSpeedDial({ group, products, onUpdate }) {
    const { barId } = group;
    const { bamboraTerminals: terminals } = useStaffActiveBar();
    const { showSuccess } = useSnackbar();
    const classes = useStyles();
    const [open, setOpen] = useState(false);
    const [isTerminalSelectionOpen, setIsTerminalSelectionOpen] = useState(false);

    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    const printPreSalesReceipt = usePrintPreSalesReceipt();

    const { isLoading: isRemoving, run: removeProducts } = useApiQuery(async () => {
        await MicroserviceOrders.staffApp({
            action: 'REMOVE_PRODUCTS',
            productIds: products.map((product) => product.id),
        });

        showSuccess('Products removed');
        handleClose();
        onUpdate();
    });

    const { isLoading: isPrinting, run: printProducts } = useApiQuery(async (terminal) => {
        setIsTerminalSelectionOpen(false);

        await printPreSalesReceipt({
            barId,
            groupName: group.name,
            terminal,
            products,
        });

        showSuccess('Printing');
        handleClose();
        onUpdate();
    });

    const actions = [
        {
            tooltipTitle: 'Remove',
            icon: <IconRemove />,
            className: classes.fabSecondary,
            onClick: () => {
                if (!isRemoving) {
                    removeProducts();
                }
            },
            disabled: isRemoving,
        },
        terminals.length > 0 && {
            tooltipTitle: 'Print',
            icon: <IconPrint />,
            onClick: () => setIsTerminalSelectionOpen(true),
            disabled: isPrinting,
        },
    ].filter(Boolean);

    const disabled = isRemoving || isPrinting;

    return (
        <>
            <Backdrop open={open} className={classes.backdrop} />

            <SpeedDial
                className={classes.speedDial}
                classes={{ fab: classes.speedDialButton }}
                direction={'up'}
                FabProps={{ disabled }}
                icon={<SpeedDialIcon icon={<IconMore />} openIcon={<IconClose />} />}
                onClose={handleClose}
                onOpen={handleOpen}
                open={open}
                ariaLabel={'order-options'}>
                {actions.map((action) => (
                    <SpeedDialAction
                        FabProps={{ disabled }}
                        key={action.tooltipTitle}
                        icon={action.icon}
                        tooltipOpen
                        tooltipPlacement={'right'}
                        classes={{ staticTooltipLabel: classes.staticTooltipLabel }}
                        {...action}
                    />
                ))}
            </SpeedDial>

            <StaffOrderTerminalSelection
                open={isTerminalSelectionOpen}
                onClose={setIsTerminalSelectionOpen}
                onTerminal={printProducts}
                isLoading={isPrinting}
            />
        </>
    );
}
