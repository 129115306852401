import clsx from 'clsx';
import React from 'react';
import { UiButtonProps, UiButton } from '@orderx/ui';
import './ButtonBottom.scss';
import { UiToolbar } from '@orderx/ui';

interface Props extends UiButtonProps {}

export function ButtonBottom({ className, ...rest }: Props) {
    return (
        <UiToolbar position="bottom" className="ButtonBottom">
            <UiButton fullWidth size={2} variant="filled" {...rest} className={clsx('ButtonBottom', className)} />
        </UiToolbar>
    );
}
