import React, { useEffect } from 'react';
import { SwitchRedirect } from '../components/SwitchRedirect';
import { loadStaffVenues } from '../microservices/legacy-app';
import { routes } from '../shared/router';
import { showError } from '../shared/toasts';
import { stores, useStore } from '../stores';
import { SplashScreen } from './splash-screen/SplashScreen';
import { StaffVenueReporting } from './StaffVenueReporting';

export function StaffVenue({ match, history }) {
    const { venueId } = match.params;
    const [{ venue }, setStaff] = useStore(stores.staff);

    useEffect(() => {
        loadVenues();
    }, []);

    async function loadVenues() {
        try {
            const venues = await loadStaffVenues();
            const venue = venues.find(({ id }) => id === venueId);

            setStaff((state) => {
                state.venue = venue;
                state.venueId = venue.id;
            });
        } catch (error) {
            showError(error, 'Failed to load venue');
            history.push(routes.customerProfile);
        }
    }

    if (!venue) {
        return <SplashScreen />;
    }

    return (
        <SwitchRedirect
            routes={[
                {
                    path: routes.staffVenueReporting,
                    component: StaffVenueReporting,
                },
            ]}
        />
    );
}
