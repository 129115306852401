import React from 'react';
import { Table } from './Table';
import { TableTitle } from './TableTitle';

export function BalanceGroupBalances({ balances = [] }) {
    return (
        <div>
            <TableTitle>Balances</TableTitle>

            <Table>
                <thead>
                    <tr>
                        <td>ID</td>
                        <td>Usage</td>
                    </tr>
                </thead>

                <tbody>
                    {balances.map(balance => (
                        <tr key={balance.id}>
                            <td>{balance.id}</td>
                            <td>
                                {balance.count}
                                {!!balance.limit && <>/{balance.limit}</>}
                            </td>
                        </tr>
                    ))}
                </tbody>
            </Table>
        </div>
    );
}
