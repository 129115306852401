import React, { useState } from 'react';
import { UiFlex, UiButton, UiIconMaterial, UiToolbar } from '@orderx/ui';
import { useDeliveryView } from '../../../../hooks/delivery/view';
import './DeliveryStationHeader.scss';
import { DeliveryStationFilter } from '../filter/DeliveryStationFilter';
import { useStorage } from '../../../../shared/storage';
import { DeliveryStationHeaderSettings } from './settings/DeliveryStationHeaderSettings';
import { ToolbarTitle } from '../../../../@v2/components/toolbar/title/ToolbarTitle';
import { useHistory } from 'react-router-dom';

export function DeliveryStationHeader({ view, onChangeView }) {
    const { goBack } = useHistory();
    const [isSettingsVisible, setIsSettingsVisible] = useState(false);
    const [isHeaderHidden, setIsHeaderHidden] = useStorage('isHeaderHidden');
    const { loadData, isLoadingData, isAnyFilterAvailable, config, configSetValue, bars } = useDeliveryView();
    const { isFiltersVisible, isCancelOrderMode } = config;

    function toggleSettingsVisible() {
        setIsSettingsVisible(!isSettingsVisible);
    }

    function toggleFiltersVisible() {
        configSetValue('isFiltersVisible', !isFiltersVisible);
    }

    function toggleHeaderVisible() {
        setIsHeaderHidden(!isHeaderHidden);
    }

    const barName = bars.length === 1 && bars[0].name;

    return (
        <div className="DeliveryStationHeader">
            {isHeaderHidden && (
                <div className="header-visibility-button">
                    <UiButton
                        variant="filled"
                        onClick={toggleHeaderVisible}
                        icon={<UiIconMaterial>visibility</UiIconMaterial>}
                        size={1}
                    />
                </div>
            )}

            {!isHeaderHidden && (
                <UiToolbar>
                    <ToolbarTitle buttonBackOnClick={goBack} title={barName} />

                    <UiFlex>
                        <UiButton
                            variant={view === 'HISTORY' ? 'filled' : 'default'}
                            color={view === 'HISTORY' ? 'secondary' : 'default'}
                            onClick={() => onChangeView('HISTORY')}
                            icon={<UiIconMaterial>history</UiIconMaterial>}
                            iconCircle
                            size={1}
                        />

                        <UiButton
                            onClick={() => {
                                configSetValue('isCancelOrderMode', !isCancelOrderMode);
                            }}
                            variant={isCancelOrderMode ? 'filled' : 'default'}
                            color={isCancelOrderMode ? 'secondary' : 'default'}
                            icon={<UiIconMaterial>delete_forever</UiIconMaterial>}
                            iconCircle
                            size={1}
                        />

                        <UiButton
                            onClick={toggleHeaderVisible}
                            icon={<UiIconMaterial>visibility_off</UiIconMaterial>}
                            iconCircle
                            size={1}
                        />

                        <UiButton
                            onClick={loadData}
                            isLoading={isLoadingData}
                            icon={<UiIconMaterial>refresh</UiIconMaterial>}
                            iconCircle
                            size={1}
                        />

                        {isAnyFilterAvailable && (
                            <UiButton
                                onClick={toggleFiltersVisible}
                                color={isFiltersVisible ? 'primary' : 'default'}
                                icon={<UiIconMaterial>filter_list</UiIconMaterial>}
                                iconCircle
                                size={1}
                            />
                        )}

                        <UiButton
                            onClick={toggleSettingsVisible}
                            icon={<UiIconMaterial>settings</UiIconMaterial>}
                            iconCircle
                            size={1}
                        />
                    </UiFlex>
                </UiToolbar>
            )}

            {isFiltersVisible && isAnyFilterAvailable && view !== 'HISTORY' && <DeliveryStationFilter />}

            {isSettingsVisible && <DeliveryStationHeaderSettings onClose={toggleSettingsVisible} />}
        </div>
    );
}
