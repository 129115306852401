import clsx from 'clsx';
import React from 'react';
import { CurrencyMinor } from '../../../../../../components/CurrencyMinor';
import './PosCreateProductPriceLabel.scss';
import { UiButton, UiIconMaterial } from '@orderx/ui';
import { usePosOrder } from '../../../../../hooks/pos-order';

interface Props {
    product: any;
}

export function PosCreateProductPriceLabel({ product }: Props) {
    const { updateOrderProductByKey } = usePosOrder().useOrderProducts();
    const hasDiscount = Boolean(product.discountAccount);

    function getActualPrice() {
        return product.discountAccount ? product.price * (product.discountAccount.percent / 100) : product.price;
    }

    function removeDiscount() {
        updateOrderProductByKey(product.key, {
            discountAccount: undefined,
            discountAccountId: undefined,
        });
    }

    return (
        <label className={clsx('PosCreateProductPriceLabel')}>
            {hasDiscount && (
                <>
                    <span className="percent">-{product.discountAccount.percent}%</span>

                    <span className="old-price">
                        <CurrencyMinor amount={product.price} />
                    </span>
                </>
            )}

            <span className={clsx(hasDiscount && 'new-price')}>
                <CurrencyMinor amount={getActualPrice()} />
            </span>

            {hasDiscount && (
                <UiButton onClick={removeDiscount} iconCircle size={-1}>
                    <UiIconMaterial>close</UiIconMaterial>
                </UiButton>
            )}
        </label>
    );
}
