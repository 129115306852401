import React, { useEffect } from 'react';
import { Connectivity } from '../../components/Connectivity';
import { StaffRegisterDevice } from '../../components/StaffRegisterDevice';
import { SwitchRedirect } from '../../components/SwitchRedirect';
import { usePosBar } from '../../hooks/pos/bar';
import { MicroserviceOrders } from '../../microservices/orders';
import { useApiQuery } from '@orderx/http';
import { SplashScreen } from '../splash-screen/SplashScreen';
import './PosPage.scss';
import { PosGroupPage } from './group/PosGroupPage';
import { useOrderBubble } from '../../@v2/hooks/order-bubble';

export function PosPage({ match }) {
    useOrderBubble().useTemporaryHide();

    const { barId: barIdQuery } = match.params;
    const { barId, setBar, setProducts } = usePosBar();
    const isCorrectBar = barIdQuery !== barId;

    const { run: loadBar } = useApiQuery(async (barId) => {
        const { barName, products } = await MicroserviceOrders.staffApp({
            action: 'FIND_BAR',
            barId,
        });

        setBar({
            id: barId,
            name: barName,
        });

        setProducts(products);
    });

    useEffect(() => {
        if (barIdQuery) {
            loadBar(barIdQuery);
        }
    }, [barIdQuery]);

    if (isCorrectBar) {
        return <SplashScreen />;
    }

    return (
        <>
            <Connectivity />
            <StaffRegisterDevice />

            <SwitchRedirect
                routes={[
                    {
                        path: PosGroupPage.route,
                        component: PosGroupPage,
                    },
                ]}
            />
        </>
    );
}

PosPage.route = '/pos/:barId';
