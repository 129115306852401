import React from 'react';
import styled from 'styled-components';
import { Button } from './Button';
import UserBalancesSlider from './UserBalancesSlider';

const Wrapper = styled.div`
    height: 100vh;
    display: flex;
    align-items: center;
    flex-flow: column;

    > :first-child {
        flex: 1;
        width: 100%;
        padding: 32px 0 16px 0;
    }

    > :last-child {
        width: 100%;
        padding: 16px 32px 32px 32px;
    }
`;

export function CustomerScanOverlayUserBalances({ userBalances, actionSheet }) {
    return (
        <Wrapper>
            <div>
                <UserBalancesSlider userBalances={userBalances} />
            </div>

            <div>
                <Button onClick={actionSheet.close} block>
                    Close
                </Button>
            </div>
        </Wrapper>
    );
}
