import keyBy from 'lodash/keyBy';
import orderBy from 'lodash/orderBy';
import { createStore, useStore } from '../create-store';
import { storage, storageSet } from '../shared/storage';

const StorageKey = 'staffTerminalOrders';
const store = createStore(storage.get(StorageKey) || {});

export const BAR_ORDER_LIMIT = 5;

export function useStaffTerminalOrders() {
    const [orderById, setOrderById] = useStore(store);

    function limitBarOrders(state, order) {
        const barId = order.LocationId;
        const barOrders = Object.values(state).filter(order => order.LocationId === barId);

        const ordersWithDates = barOrders.filter(order => order.Datetime);
        const lastOrders = orderBy(ordersWithDates, order => order.Datetime, 'desc').slice(0, BAR_ORDER_LIMIT);
        const lastOrdersIds = keyBy(lastOrders, order => order.Id);

        for (const order of barOrders) {
            if (!lastOrdersIds[order.Id]) {
                delete state[order.Id];
            }
        }
    }

    return {
        getOrderById(orderId) {
            return orderById[orderId];
        },
        getOrdersByBarId(barId) {
            const orders = Object.values(orderById).filter(order => order.LocationId === barId);
            return orderBy(orders, order => order.Datetime, 'desc');
        },
        putOrder(order) {
            setOrderById(state => {
                const orderExtended = Object.assign({}, state[order.Id], order);
                state[order.Id] = orderExtended;

                limitBarOrders(state, orderExtended);
                storageSet(StorageKey, state);
            });
        },
    };
}
