import React from 'react';
import styled from 'styled-components';
import { mixins } from '../shared/styles';

const Wrapper = styled.div`
    ${mixins.inputWrapper};
    align-items: center;
    display: flex;
    margin-bottom: ${({ margin = 16 }) => margin}px;

    input {
        padding: 0 16px;
        width: 100%;
    }
`;

export function Input({ name, type, number, value, margin, clear, onChange, onChangeValue, placeholder, disabled }) {
    return (
        <Wrapper margin={margin}>
            <input
                value={value}
                name={name}
                type={number ? 'number' : type}
                disabled={disabled}
                placeholder={placeholder}
                onChange={event => {
                    const { value } = event.target;

                    if (onChangeValue) {
                        onChangeValue(value);
                    }

                    if (onChange) {
                        onChange(event);
                    }
                }}
            />
        </Wrapper>
    );
}
