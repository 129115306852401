import React from 'react';
import { Currency } from './Currency';

export function CurrencyMinor({ currency = undefined, amount }) {
    amount = amount / 100;

    if (currency === 'NOK') {
        amount = Math.round(amount);
    }

    return <Currency currency={currency} amount={amount} />;
}
