import { Entity, Column, ManyToOne, OneToMany, AfterLoad } from './typeorm';
import { BaseModel } from './base-model';
import { InventoryInventory } from './inventory-inventory';
import { User } from './user';
import { InventoryCountingReport } from './inventory-counting-report';

@Entity()
export class InventoryCountingReportSummary extends BaseModel {
    @Column({ type: 'text', nullable: true })
    data: any;

    @OneToMany(() => InventoryCountingReport, (model) => model.countingReportSummary)
    countingReports: InventoryCountingReport[];

    @ManyToOne(() => User, (model) => model.inventoryCountingReportSummaries)
    user: User;
    @Column()
    userId: number;

    @ManyToOne(() => InventoryInventory, (model) => model.countingReportSummaries)
    inventory: InventoryInventory;
    @Column()
    inventoryId: number;

    parseJson() {
        try {
            this.data = JSON.parse(this.data as any);
        } catch (error) {}
    }
}
