import { getApiUrl } from '../config';
import { httpPost } from '../shared/api';

const getUrl = getApiUrl('bambora');

export const MicroserviceBambora = {
    async printFromTerminal(event) {
        return httpPost(getUrl('print-from-terminal'), event);
    },
};
