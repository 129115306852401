import { storage } from '../shared/storage';
import { createStore, useStore, getStoreValue } from '../create-store';

const StorageKey = 'productPopularity';
const store = createStore(storage.get(StorageKey) || {});

export function useLocationProductPopularityByProductId(locationId) {
    const [productPopularity] = useStore(store);
    return productPopularity[locationId] || {};
}

export function updateLocationProductPopularityByOrderProducts(locationId, products) {
    const productPopularity = getStoreValue(store);

    productPopularity[locationId] = productPopularity[locationId] || {};

    for (const product of products) {
        productPopularity[locationId][product.id] = productPopularity[locationId][product.id] || 0;
        productPopularity[locationId][product.id] += product.count || 0;
    }

    serialize(productPopularity);
}

function serialize(state) {
    storage.set(StorageKey, state);
    store.set(state);
}
