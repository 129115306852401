import { createStore, useStore } from '../create-store';

const getInitialState = () => ({
    isManualEntry: false,
    isPosMode: true,
    preSelectedGroup: undefined,
});

const store = createStore(getInitialState());

export function useCreateOrder() {
    const [value, setValue] = useStore(store);

    function merge(object) {
        setValue(value => ({ ...value, ...object }));
    }

    return {
        isPosMode: value.isPosMode,
        setIsPosMode: isPosMode => merge({ isPosMode }),

        isManualEntry: value.isManualEntry,
        setIsManualEntry: isManualEntry => merge({ isManualEntry }),

        preSelectedGroup: value.preSelectedGroup,
        setPreSelectedGroup: preSelectedGroup => merge({ preSelectedGroup }),

        reset: () => setValue(getInitialState()),

        merge,
    };
}
