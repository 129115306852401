import React, { useEffect } from 'react';
import { UiGrid, UiFlex, UiText, UiButton, UiOverlay } from '@orderx/ui';
import './OrderTooltipOrderBubble.scss';
import { useStorage } from '../../../../shared/storage';
import { useOrderBubble } from '../../../hooks/order-bubble';

export function OrderTooltipOrderBubble() {
    const { setIsVisible } = useOrderBubble();
    const [isInfoHidden, setIsInfoHidden] = useStorage('isInfoHidden');

    function confirm() {
        setIsInfoHidden(true);
    }

    useEffect(() => {
        setIsVisible(!isInfoHidden);
    }, [isInfoHidden]);

    useEffect(() => {
        return () => confirm();
    }, []);

    if (isInfoHidden) {
        return null;
    }

    return (
        <div className="OrderTooltipOrderBubble" onClick={confirm}>
            <UiOverlay transparent onClose={confirm}>
                <div className="content">
                    <div className="bubble" />

                    <UiGrid>
                        <UiFlex spacing={2}>
                            <UiGrid>
                                <div>
                                    <UiText size={1}>By tapping this bubble</UiText>
                                    <UiText size={1}>you can open your</UiText>
                                    <UiText size={1}>pending order</UiText>
                                </div>

                                <div>
                                    <UiButton onClick={confirm} variant="filled">
                                        OK!
                                    </UiButton>
                                </div>
                            </UiGrid>
                        </UiFlex>
                    </UiGrid>
                </div>
            </UiOverlay>
        </div>
    );
}
