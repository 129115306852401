import clsx from 'clsx';
import React from 'react';
import { UiFlex, UiButton, UiIconMaterial, UiText } from '@orderx/ui';
import './ToolbarTitle.scss';

export interface ToolbarTitleProps {
    buttonBackOnClick?(): any;
    buttonBackLink?: any;
    buttonBackLinkParams?: any;
    title?: any;
}

export function ToolbarTitle({ buttonBackLink, buttonBackLinkParams, buttonBackOnClick, title }: ToolbarTitleProps) {
    const hasBackButton = buttonBackLink || buttonBackOnClick;

    return (
        <UiFlex className={clsx('ToolbarTitle')}>
            {!hasBackButton && <div className="placeholder" />}

            {hasBackButton && (
                <UiButton
                    to={buttonBackLink}
                    toParams={buttonBackLinkParams}
                    onClick={buttonBackOnClick}
                    icon={<UiIconMaterial>arrow_back</UiIconMaterial>}
                    size={2}
                />
            )}

            {title && (
                <UiText size={1} weight={2}>
                    {title}
                </UiText>
            )}
        </UiFlex>
    );
}
