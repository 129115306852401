import { getApiUrl } from '../config';
import { httpPost } from '../shared/api';

const serviceName = 'tags';
const getUrl = getApiUrl(serviceName);

export const MicroserviceTags = {
    async findTag(id) {
        return httpPost(getUrl('app'), { id }, { action: 'FIND_TAG' });
    },
};
