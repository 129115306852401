import { createStore, useStore } from '../create-store';
import { storageGet, storageRemove } from '../shared/storage';

const cacheKey = 'barsById';
storageRemove(cacheKey);

const productsPaginationInitial = {
    productsPage: 0,
    productsPerPage: getProductPaginationOptions()[0],
};

const store = createStore({
    activeBar: undefined,
    barsById: storageGet(cacheKey) || {},
    category: '',
    subCategory: '',
    searchKeyword: '',
    groupsByBarId: {},
    ...productsPaginationInitial,
});

export function useCreateOrderBar() {
    const [state, setState] = useStore(store);
    const activeBarId = state.activeBar && state.activeBar.id;

    return {
        ...state.activeBar,

        retrieveFromCache(id) {
            const bar = state.barsById[id];

            if (bar) {
                setState(value => {
                    value.activeBar = bar;
                });
            }
        },

        setActiveBar(bar) {
            setState(value => {
                value.activeBar = bar;
                value.barsById[bar.id] = bar;
            });
        },

        barId: state.activeBar && state.activeBar.id,

        category: state.category,
        setCategory(category) {
            setState(value => {
                if (value.category === category) {
                    value.category = '';
                } else {
                    value.category = category;
                }
            });
        },

        subCategory: state.subCategory,
        setSubCategory(category) {
            setState(value => {
                if (value.subCategory === category) {
                    value.subCategory = '';
                } else {
                    value.subCategory = category;
                }
            });
        },

        groups: state.groupsByBarId[activeBarId] || [],
        setGroupsByBarId(barId, groups) {
            setState(value => {
                value.groupsByBarId[barId] = value.groupsByBarId[barId] || [];
                value.groupsByBarId[barId] = groups;
            });
        },

        searchKeyword: state.searchKeyword,
        setSearchKeyword(searchKeyword) {
            setState(value => {
                value.searchKeyword = searchKeyword;
            });
        },

        productsPage: state.productsPage,
        setProductsPage(productsPage) {
            setState(value => {
                value.productsPage = productsPage;
            });
        },

        productsPerPage: state.productsPerPage,
        setProductsPerPage(productsPerPage) {
            setState(value => {
                value.productsPerPage = productsPerPage;
            });
        },

        resetPagination() {
            setState(value => {
                Object.assign(value, {
                    ...productsPaginationInitial,
                });
            });
        },

        reset() {
            setState(value => {
                Object.assign(value, {
                    category: '',
                    subCategory: '',
                    searchKeyword: '',
                    ...productsPaginationInitial,
                });
            });
        },
    };
}

export function getProductPaginationOptions() {
    try {
        const { width } = document.body.getBoundingClientRect();

        if (width < 400) {

        }

        const isIpadVertical =
            document.body.getBoundingClientRect().height === 1024 &&
            document.body.getBoundingClientRect().width === 768;

        const isIpadHorizontal =
            document.body.getBoundingClientRect().height === 768 &&
            document.body.getBoundingClientRect().width === 1024;

        if (isIpadVertical) {
            return [33, 50, 100];
        }

        if (isIpadHorizontal) {
            return [28, 50, 100];
        }
    } catch (error) {}

    return [30, 60, 90, 120];
}
