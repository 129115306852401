import classNames from 'classnames';
import addSeconds from 'date-fns/add_seconds';
import differenceInSeconds from 'date-fns/difference_in_seconds';
import format from 'date-fns/format';
import startOfDay from 'date-fns/start_of_day';
import React, { useState } from 'react';
import styled from 'styled-components';
import { themeActive } from '../themes/dark';
import { useInterval } from '../use/time';

const zoneRedSeconds = 60;
const zoneYellowSeconds = 180;

const Wrapper = styled.span`
    animation-duration: 2s !important;
    color: ${props =>
        (props.timeLeft < zoneRedSeconds && themeActive.colors.red) ||
        (props.timeLeft < zoneYellowSeconds && themeActive.colors.yellow) ||
        themeActive.colors.faded};
`;

export function TimeLeft({ expiresAt }) {
    const timeLeft = differenceInSeconds(expiresAt, new Date());
    const timeLeftActual = timeLeft > 0 ? timeLeft : 0;

    const [render, setRender] = useState(0);
    useInterval(500, () => setRender(r => r + render + 1));

    return (
        <Wrapper
            timeLeft={timeLeftActual}
            className={classNames('animated infinite', {
                flash: timeLeftActual < zoneRedSeconds,
            })}>
            {format(addSeconds(startOfDay(new Date()), timeLeftActual), 'HH:mm:ss')}
        </Wrapper>
    );
}
