import MaterialButton from '@material-ui/core/Button';
import React from 'react';
import { NavLink } from 'react-router-dom';
import { Svg } from '../Svg';
import { V2Loader } from './V2Loader';

export function V2Button({
    primary,
    secondary,
    fullWidth = false,
    isLoading = false,
    outlined = false,
    icon,
    children,
    disabled = false,
    large = false,
    small = false,
    text = false,
    to,
    ...rest
}) {
    const color = (primary && 'primary') || (secondary && 'secondary') || 'default';
    const variant = (outlined && 'outlined') || (text && 'text') || 'contained';
    const size = (large && 'large') || (small && 'small') || 'medium';

    const button = (
        <MaterialButton
            {...rest}
            variant={variant}
            color={color}
            fullWidth={fullWidth}
            disabled={isLoading || disabled}
            size={size}>
            {isLoading && <V2Loader size={24} cover coverBackground="rgba(0, 0, 0, 0.5)" />}

            {icon && <Svg icon={icon} size={20} style={children && { marginRight: 8 }} />}

            {children}
        </MaterialButton>
    );

    if (to) {
        const navLinkStyle = {};

        if (fullWidth) {
            navLinkStyle.width = '100%';
        }

        return (
            <NavLink style={navLinkStyle} to={to}>
                {button}
            </NavLink>
        );
    }

    return button;
}
