import clsx from 'clsx';
import React from 'react';
import { usePosMenu } from '../../../../../hooks/pos-menu';
import uniq from 'lodash/uniq';
import { UiFlex, UiButton, UiGrid, UiBox } from '@orderx/ui';
import './PosCreateCategory.scss';
import { usePosOrder } from '../../../../../hooks/pos-order';

export function PosCreateCategory() {
    const {
        menuProducts,
        activeCategory,
        setActiveCategory,
        activeSubCategory,
        setActiveSubCategory,
        isActiveOrderOpen,
        setIsActiveOrderOpen,
    } = usePosMenu();
    const { total } = usePosOrder().useSummary();

    const categories = uniq<any>(menuProducts.map(({ product }) => product.category));
    const subCategories = activeCategory
        ? uniq<any>(
              menuProducts
                  .filter(({ product }) => product.category === activeCategory)
                  .map(({ product }) => product.subCategory),
          )
        : [];

    function getCategoryButton({
        label,
        isActive,
        onClick,
        key,
    }: {
        label: any;
        isActive: any;
        onClick: any;
        key?: any;
    }) {
        return (
            <UiButton onClick={onClick} color={isActive ? 'primary' : 'default'} variant="filled" key={key}>
                {label}
            </UiButton>
        );
    }

    return (
        <UiBox paddingHorizontal={1} paddingTop={1}>
            <UiGrid spacing={1} className={clsx('PosCreateCategory')}>
                <UiFlex spacing={1}>
                    {total > 0 && (
                        <UiButton
                            onClick={() => setIsActiveOrderOpen(!isActiveOrderOpen)}
                            variant={isActiveOrderOpen ? 'filled' : 'outlined'}
                            color="secondary">
                            Active order
                        </UiButton>
                    )}

                    {getCategoryButton({
                        label: 'All',
                        isActive: activeCategory === '' && !isActiveOrderOpen,
                        onClick() {
                            setActiveCategory('');
                            setActiveSubCategory('');
                            setIsActiveOrderOpen(false);
                        },
                    })}

                    {categories.map((category) =>
                        getCategoryButton({
                            label: category,
                            isActive: activeCategory === category && !isActiveOrderOpen,
                            onClick() {
                                setActiveCategory(activeCategory === category ? '' : category);
                                setActiveSubCategory('');
                                setIsActiveOrderOpen(false);
                            },
                            key: category,
                        }),
                    )}
                </UiFlex>

                {activeCategory && !isActiveOrderOpen && (
                    <UiFlex spacing={1}>
                        {subCategories.map((category) =>
                            getCategoryButton({
                                label: category,
                                isActive: activeSubCategory === category && !isActiveOrderOpen,
                                onClick() {
                                    setActiveSubCategory(activeSubCategory === category ? '' : category);
                                    setIsActiveOrderOpen(false);
                                },
                                key: category,
                            }),
                        )}
                    </UiFlex>
                )}
            </UiGrid>
        </UiBox>
    );
}
