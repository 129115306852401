import React from 'react';
import styled from 'styled-components';
import { theme as themeActive } from '../themes/dark';

const Wrapper = styled.div`
    background: ${themeActive.colors.background};
    display: inline-block;
    padding: 0 16px;
    text-transform: uppercase;
    letter-spacing: 1px;
    color: ${themeActive.colors.faded};
    margin-bottom: 16px;
    font-weight: bold;

    &::before {
        content: '';
        left: 0;
        height: 2px;
        background-image: linear-gradient(to right, #333, ${themeActive.colors.background});
        position: absolute;
        width: 100%;
        z-index: -1;
        transform: translateY(8px);
    }
`;

export function TableTitle({ children }) {
    return <Wrapper>{children}</Wrapper>;
}

