import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import IconButton from '@material-ui/core/IconButton';
import makeStyles from '@material-ui/core/styles/makeStyles';
import merge from 'lodash/merge';
import React from 'react';
import { Link } from 'react-router-dom';
import { themeActive } from '../themes/dark';

const useStyles = makeStyles(({ palette }) => ({
    loaderContainer: {
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    transparent: {
        opacity: 0,
    },
    contained: {
        // TODO: Hardcoded color
        backgroundColor: 'var(--color-background-paper)',
        color: themeActive.colors.text,
        '&:hover': {
            backgroundColor: '#444',
            color: themeActive.colors.text,
        },
        '&.Mui-disabled': {
            backgroundColor: '#333',
        },
    },
    containedPrimary: {
        backgroundColor: palette.primary.main,
        color: themeActive.colors.text,
        '&:hover': {
            backgroundColor: palette.primary.light,
        },
        '&.Mui-disabled': {
            backgroundColor: palette.primary.main,
        },
    },
    containedSecondary: {
        backgroundColor: palette.secondary.main,
        color: themeActive.colors.text,
        '&:hover': {
            backgroundColor: palette.secondary.light,
        },
        '&.Mui-disabled': {
            backgroundColor: palette.secondary.main,
        },
    },
}));

export function UiButton({ icon, to, children, isLoading, ...rest }) {
    const classes = useStyles();

    const buttonProps = {
        ...rest,
    };

    if (!icon) {
        buttonProps.classes = merge(
            {
                contained: classes.contained,
                containedPrimary: classes.containedPrimary,
                containedSecondary: classes.containedSecondary,
            },
            buttonProps.classes,
        );
    }

    if (to) {
        buttonProps.component = Link;
        buttonProps.to = to;
    }

    const Component = icon ? IconButton : Button;

    if (icon) {
        children = icon;
    }

    if (isLoading) {
        return (
            <Component {...buttonProps} disabled>
                <div className={classes.loaderContainer}>
                    <CircularProgress size={24} />
                </div>

                <span className={classes.transparent}>{children}</span>
            </Component>
        );
    }

    return <Component {...buttonProps}>{children}</Component>;
}
