import React from 'react';
import ReactDOM from 'react-dom';
import styled, { css } from 'styled-components';

const modalRoot = document.getElementById('modal-root');

const position = css`
    ${(props) =>
        props.top &&
        css`
            top: 0;
        `};

    ${(props) =>
        !props.top &&
        css`
            bottom: 0;
        `};
`;

const Wrapper = styled.div`
    background-color: rgba(0, 0, 0, ${({ hasOverlay, overlayOpacity = 0.4 }) => (hasOverlay ? overlayOpacity : 0)});
    position: fixed;
    height: 100%;
    width: 100%;
    z-index: 11;
    display: flex;
    flex-direction: column;
    left: 0;
    transform: translateZ(0);

    ${(props) =>
        !props.top &&
        css`
            bottom: -100vh;
            justify-content: flex-end;
        `};

    ${(props) =>
        props.top &&
        css`
            top: -100vh;
            justify-content: flex-start;
        `};

    ${position};

    .Content {
        overflow: auto;
    }
`;

const contentBackground = (props) => {
    if (props.lightContent) {
        return props.theme.colors.bottomOverlayReversed;
    } else {
        return props.theme.colors.bottomOverlay;
    }
};

const DefaultWrapper = styled.div`
    background-color: ${contentBackground};
    color: ${(props) => (props.lightContent ? props.theme.colors.background : props.theme.colors.backgroundReversed)};
    border-top-left-radius: ${({ theme, cover }) => (cover ? 0 : theme.sizes.borderRadius)}px;
    border-top-right-radius: ${({ theme, cover }) => (cover ? 0 : theme.sizes.borderRadius)}px;
    //margin: ${({ cover }) => (cover ? 0 : 8)}px;
    height: ${({ cover }) => (cover ? '100vh' : 'auto')};
    padding: ${({ cover }) => (cover ? 0 : 32)}px;
    position: relative;
    overflow: auto;
`;

export class BottomOverlay extends React.Component {
    timeoutClose;

    state = {
        isClosing: false,
    };

    constructor(props) {
        super(props);
        this.el = document.createElement('div');
    }

    componentDidMount() {
        modalRoot.appendChild(this.el);
    }

    componentWillUnmount() {
        modalRoot.removeChild(this.el);
        clearTimeout(this.timeoutClose);
    }

    prevent = (event) => {
        event.preventDefault();
        event.stopPropagation();
    };

    clickOverlay = (event) => {
        this.prevent(event);

        if (this.props.canClose === false) {
            return;
        }

        this.componentDidUpdate();

        this.setState({ isClosing: true }, () => {
            this.timeoutClose = setTimeout(this.props.onClose, 400);
        });
    };

    componentDidUpdate(prevProps = {}, prevState, snapshot) {
        if (this.props.visible === false && prevProps.visible !== this.props.visible) {
            this.setState({ isClosing: true }, () => {
                this.timeoutClose = setTimeout(this.props.onClose, 400);
            });
        }
    }

    render() {
        const isClosing = this.state.isClosing;
        const closingClassOverlay = isClosing && 'fadeOut';
        const closingClassContent = isClosing && 'fadeOutDown';
        const fadeInClass = this.props.top ? 'fadeInDown' : 'fadeInUp';
        const className = `Content animated ${fadeInClass} ${closingClassContent}`;

        const contentWrapper =
            this.props.lightContent || this.props.darkContent ? (
                <DefaultWrapper {...this.props}>{this.props.children}</DefaultWrapper>
            ) : (
                this.props.children
            );

        const content = (
            <div className={className} onClick={this.prevent}>
                {contentWrapper}
            </div>
        );

        const modal = (
            <Wrapper
                {...this.props}
                className={`BottomOverlay animated fadeIn ${closingClassOverlay}`}
                onClick={this.clickOverlay}>
                {content}
            </Wrapper>
        );

        return ReactDOM.createPortal(modal, this.el);
    }
}
