import { getApiUrl } from '../config';
import { httpDelete, httpPut } from '../shared/api';

const getUrl = getApiUrl('staff');

export async function registerToBarTerminal(barId, terminalIds = []) {
    const url = getUrl(`register-bar-terminal`);
    await httpPut(url, { barId, terminalIds });
}

export async function registerToVenueBar(venueId, barId) {
    const url = getUrl(`register/venue/${venueId}/bar/${barId}`);
    await httpPut(url);
}

export async function unregisterFromVenueBar(venueId, barId) {
    const url = getUrl(`register/venue/${venueId}/bar/${barId}`);
    await httpDelete(url);
}
