import makeStyles from '@material-ui/core/styles/makeStyles';
import IconFilter from '@material-ui/icons/FilterListRounded';
import IconReload from '@material-ui/icons/RefreshRounded';
import clsx from 'clsx';
import flatten from 'lodash/flatten';
import keyBy from 'lodash/keyBy';
import uniqBy from 'lodash/uniqBy';
import React, { useEffect, useMemo, useState } from 'react';
import { MqttOrderGroupUpdate } from '../../../components/mqtt/MqttOrderGroupUpdate';
import { StaffOrderStationSortOrder } from '../../../components/staff-order/station/sort-order/StaffOrderStationSortOrder';
import { StaffOrderStationListItem } from '../../../components/staff-order/station/list-item/StaffOrderStationListItem';
import { UiGrid, UiText, UiAlert, UiFlex } from '@orderx/ui';
import { UiButton } from '../../../@deprecated/UiButton';
import { UiButtonGroup } from '../../../components/ui/UiButtonGroup';
import { UiPageHeader } from '../../../components/ui/UiPageHeader';
import { UiSelect } from '../../../components/ui/UiSelect';
import { UiSwitch } from '../../../components/ui/UiSwitch';
import { MicroserviceOrders } from '../../../microservices/orders';
import { ProductStatus } from '../../../models/orders/product';
import { AudioFile, playSound } from '../../../providers/audio';
import { useAwsIotSubscription } from '../../../providers/aws-iot';
import { useOnWindowFocus } from '../../../shared/browser';
import { storageGet, storageSet } from '../../../shared/storage';
import { useDeviceConfig } from '../../../stores/device-config';
import { useStaffOrderingBar } from '../../../stores/staff-ordering-bar';
import { themeActive } from '../../../themes/dark';
import { useApiQuery } from '@orderx/http';
import { useStateStorage } from '../../../use/state-storage';
import { capitalCase } from '../../../util/capital-case';
import { replaceItems } from '../../../util/replace-items';
import './StaffOrderStationPage.scss';
import { useOrderBubble } from '../../../@v2/hooks/order-bubble';

const useStyles = makeStyles({
    container: {
        padding: 16,
    },
    filterGrid: {
        display: 'grid',
        gridGap: 16,
        gridTemplateColumns: 'repeat(auto-fill, minmax(240px, 1fr))',
    },
    filterContainer: {
        backgroundColor: themeActive.header.backgroundColor,
        padding: 16,
    },
    buttonExtendedView: {
        fontSize: 12,
        lineHeight: 1,
    },
    categoryProductFilter: {
        backgroundColor: themeActive.colors.background,
        position: 'absolute !important',
        left: 0,
        top: 0,
        width: `calc(100% - ${80}px)`,
        zIndex: 1,
    },
});

export function StaffOrderStationPage() {
    useOrderBubble().useTemporaryHide();

    const classes = useStyles();
    const { barId, barName } = useStaffOrderingBar();
    const { isStationStatusesLocked, isPollingForOrders } = useDeviceConfig().config;

    const [isFiltersVisible, setIsFiltersVisible] = useState(false);
    const [isSortVisible, setIsSortVisible] = useState(false);
    const [isExtendedView, setIsExtendedView] = useStateStorage('StaffOrderStationPage.isExtendedView', false);

    const [orders, setOrders] = useState([]);
    const [groups, setGroups] = useState([]);

    const [productOptions, setProductOptions] = useState([]);
    const [productOptionsSelected, setProductOptionsSelected] = useState([]);

    const [groupOptions, setGroupOptions] = useState([]);
    const [groupOptionsSelected, setGroupOptionsSelected] = useState([]);

    const [categorySelected, setCategorySelected] = useState('');
    const [filterPlacedFromTable, setFilterPlacedFromTable] = useState(Boolean(storageGet('filterPlacedFromTable')));

    const [visibleOrders, setVisibleOrders] = useStateStorage('visibleOrders', 100);

    const groupsIdsFiltered = useMemo(() => {
        const ids = groupOptionsSelected.map((option) => option.value);
        return groups.filter((group) => ids.includes(group.id)).map((group) => group.id);
    }, [groups, groupOptionsSelected]);

    const categories = useMemo(
        () =>
            uniqBy([
                'FOOD',
                'DRINK',
                ...flatten(orders.map((order) => order.products.map((product) => product.category))).filter(Boolean),
            ]),
        [orders],
    );

    const groupById = useMemo(() => keyBy(groups, (group) => group.id), [groups]);

    const { run, isLoading, response } = useApiQuery((params) =>
        MicroserviceOrders.staffApp({
            action: 'GET_STATION_VIEW_DATA',
            barId,
            productStatuses: (params && params.productStatuses) || [],
            isPlacedFromTable: filterPlacedFromTable,
        }),
    );

    useEffect(() => {
        if (response) {
            setGroups(response.groups);
            setOrders(response.orders);
        }
    }, [response]);

    useEffect(() => {
        if (isPollingForOrders) {
            const interval = setInterval(() => run(), 10000);
            return () => clearInterval(interval);
        }
    }, [isPollingForOrders]);

    useEffect(() => {
        run();
    }, [filterPlacedFromTable]);

    useOnWindowFocus(() => {
        run();
    });

    useAwsIotSubscription(`bars/${barId}/order-placed`, () => {
        playSound(AudioFile.OrderNew);
        run();
    });

    useAwsIotSubscription(`bars/${barId}/update`, () => {
        run();
    });

    useEffect(() => {
        setGroupOptions(
            groups.map((group) => ({
                label: group.name,
                value: group.id,
            })),
        );
    }, [groups]);

    useEffect(() => {
        const products = uniqBy(flatten(orders.map((order) => order.products)), (product) => product.locationProductId);

        setProductOptions(
            products.map((product) => ({
                label: product.name,
                value: product.locationProductId,
            })),
        );
    }, [orders]);

    const [productStatusOptions] = useState([
        {
            label: 'New orders',
            value: ProductStatus.Placed,
        },
        {
            label: 'Being prepared',
            value: ProductStatus.InPreparation,
        },
        {
            label: 'Ready',
            value: ProductStatus.Ready,
        },
        {
            label: 'Handed out',
            value: ProductStatus.Delivered,
        },
    ]);

    const cachedFilterOptions = storageGet('staffOrderFilterStatuses');

    const [statuses, setStatuses] = useState(
        cachedFilterOptions
            ? productStatusOptions.filter((filter) => cachedFilterOptions.includes(filter.value))
            : productStatusOptions,
    );

    function updateStatuses(statuses) {
        const productStatuses = statuses.map((status) => status.value);
        storageSet('staffOrderFilterStatuses', productStatuses);
        setStatuses(statuses);
        run({ productStatuses });
    }

    const ordersMemo = useMemo(() => {
        let ordersFiltered = [...orders];

        const filters = [];

        if (categorySelected) {
            ordersFiltered = ordersFiltered.map((order) => ({
                ...order,
                products: order.products.filter((product) => product.category === categorySelected),
            }));
        }

        if (productOptionsSelected.length > 0) {
            const filteredProductIds = productOptionsSelected.map(({ value }) => value);

            ordersFiltered = ordersFiltered.map((order) => ({
                ...order,
                products: order.products.filter((product) => filteredProductIds.includes(product.locationProductId)),
            }));
        }

        if (groupsIdsFiltered.length > 0) {
            filters.push((order) => groupsIdsFiltered.includes(order.groupId));
        }

        const selectedStatuses = statuses.map((status) => status.value);

        if (selectedStatuses.length > 0) {
            filters.push((order) => order.products.find((product) => selectedStatuses.includes(product.status)));
        }

        filters.push((order) => order.products.length > 0);

        if (filters.length === 0) {
            return ordersFiltered;
        }

        return ordersFiltered.filter((order) => {
            for (const filter of filters) {
                if (!filter(order)) {
                    return false;
                }
            }

            return true;
        });
    }, [orders, groupsIdsFiltered, statuses, categorySelected, productOptionsSelected]);

    const totalOrders = ordersMemo.length;
    const ordersFiltered = visibleOrders ? ordersMemo.slice(0, visibleOrders) : ordersMemo;

    if (!response) {
        return (
            <UiPageHeader isLoading>
                <h4>{barName}</h4>
            </UiPageHeader>
        );
    }

    function handleCategoryChange(category) {
        setCategorySelected(category);
        setProductOptionsSelected([]);
    }

    function getVisibleOrderButton(amount) {
        return (
            <UiButton
                fullWidth
                onClick={() => setVisibleOrders(amount)}
                variant={'contained'}
                color={amount === visibleOrders ? 'primary' : 'default'}>
                {amount || 'All'}
            </UiButton>
        );
    }

    return (
        <>
            {groups.map((group) => (
                <MqttOrderGroupUpdate key={group.id} hash={group.hash} callback={run} />
            ))}

            <UiPageHeader isLoading={isLoading}>
                <UiText weight={2}>{barName}</UiText>

                <div>
                    <UiButton icon={<IconReload />} onClick={() => window.location.reload()} />

                    <UiButton
                        onClick={() => setIsSortVisible(!isSortVisible)}
                        size={'small'}
                        className={classes.buttonExtendedView}>
                        <div>
                            <div>{'Sort'}</div>
                            <div>{'orders'}</div>
                        </div>
                    </UiButton>

                    <UiButton
                        onClick={() => setIsExtendedView(!isExtendedView)}
                        size={'small'}
                        className={classes.buttonExtendedView}>
                        <div>
                            <div>{'Switch to'}</div>
                            <div>{isExtendedView ? 'compact' : 'detailed'}</div>
                        </div>
                    </UiButton>

                    <UiButton
                        onClick={() => setIsFiltersVisible(!isFiltersVisible)}
                        icon={<IconFilter />}
                        color={isFiltersVisible ? 'primary' : 'default'}
                    />
                </div>
            </UiPageHeader>

            {isFiltersVisible && (
                <div className={clsx(classes.filterContainer, classes.filterGrid)}>
                    <UiSwitch
                        label={'Only show orders placed from table'}
                        checked={filterPlacedFromTable}
                        onChange={(checked) => {
                            setFilterPlacedFromTable(checked);
                            storageSet('filterPlacedFromTable', checked);
                        }}
                    />

                    <UiSelect
                        isSearchable={false}
                        placeholder={'Filter tables'}
                        menuPlacement={'bottom'}
                        isMulti
                        options={groupOptions}
                        defaultValue={groupOptionsSelected}
                        onChange={setGroupOptionsSelected}
                    />

                    <UiButtonGroup label={'Limit orders'}>
                        {getVisibleOrderButton(0)}
                        {getVisibleOrderButton(5)}
                        {getVisibleOrderButton(10)}
                        {getVisibleOrderButton(100)}
                    </UiButtonGroup>
                </div>
            )}

            <UiGrid outerSpacing={2}>
                {isSortVisible && <StaffOrderStationSortOrder />}

                <UiGrid minMax={360 - 32}>
                    <UiButtonGroup label={'Filter orders by category'}>
                        <UiButton
                            variant={'contained'}
                            color={categorySelected === '' ? 'primary' : 'default'}
                            onClick={() => handleCategoryChange('')}>
                            All
                        </UiButton>

                        {categories.map((category) => (
                            <UiButton
                                variant={'contained'}
                                color={categorySelected === category ? 'primary' : 'default'}
                                onClick={() => handleCategoryChange(categorySelected === category ? '' : category)}
                                key={category}>
                                {capitalCase(category)}
                            </UiButton>
                        ))}

                        {categorySelected === false && (
                            <UiSelect
                                className={classes.categoryProductFilter}
                                isSearchable={false}
                                placeholder={'Filter by products'}
                                menuPlacement={'bottom'}
                                isMulti
                                options={productOptions}
                                defaultValue={productOptionsSelected}
                                onChange={setProductOptionsSelected}
                            />
                        )}

                        <UiButton
                            variant={'contained'}
                            color={categorySelected === false ? 'primary' : 'default'}
                            onClick={() => handleCategoryChange(categorySelected === false ? '' : false)}>
                            Products
                        </UiButton>
                    </UiButtonGroup>

                    {!isStationStatusesLocked && (
                        <UiSelect
                            isSearchable={false}
                            placeholder={'Filter by status'}
                            menuPlacement={'bottom'}
                            isMulti
                            options={productStatusOptions}
                            defaultValue={statuses}
                            onChange={updateStatuses}
                        />
                    )}
                </UiGrid>

                <UiGrid spacing={1}>
                    {ordersFiltered.length === 0 && <UiAlert>No orders with selected filters</UiAlert>}

                    {totalOrders > ordersFiltered.length && (
                        <UiAlert color={'info'}>
                            <UiFlex spacing={1} justifyContent={'space-between'}>
                                <span>
                                    There are <b>{totalOrders - ordersFiltered.length}</b> orders that are not being
                                    displayed due to <b>LIMIT ORDER</b> filter.
                                </span>

                                <UiButton onClick={() => setVisibleOrders(0)} size={'small'} variant={'contained'}>
                                    Show all orders
                                </UiButton>
                            </UiFlex>
                        </UiAlert>
                    )}

                    {ordersFiltered.map((order) => (
                        <StaffOrderStationListItem
                            key={order.id}
                            order={order}
                            group={groupById[order.groupId]}
                            onUpdate={({ orders: ordersNew }) => setOrders((orders) => replaceItems(orders, ordersNew))}
                            compact={!isExtendedView}
                            onArchived={run}
                        />
                    ))}
                </UiGrid>
            </UiGrid>
        </>
    );
}
