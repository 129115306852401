import React, { useState } from 'react';
import { deleteVenueBalanceGroup, loadVenueBalanceGroups, updateBalanceGroup } from '../microservices/balance-groups';
import { showError, showSuccess } from '../shared/toasts';
import { useStaffActiveBar } from '../stores/staff-active-bar';
import { BottomOverlay } from './BottomOverlay';
import { Button } from './Button';
import { ButtonGroup } from './ButtonGroup';
import { Flex } from './Flex';
import { Input } from './Input';
import { InputLabel } from './InputLabel';
import { List } from './List';
import Padding from './Padding';

export function BalanceGroupDetailOverlay({ balanceGroup, onClosed }) {
    const { venueId } = useStaffActiveBar();

    const [visible, setVisible] = useState(true);
    const [isLoading, setIsLoading] = useState(false);
    const [deleted, setDeleted] = useState(false);
    const [form, setForm] = useState({
        name: balanceGroup.name,
        limit: balanceGroup.limit,
        limitReset: balanceGroup.limitReset,
    });
    const balanceGroupId = balanceGroup.id;

    function hideOverlay() {
        setVisible(false);
    }

    async function resetAndDelete() {
        setIsLoading(true);

        try {
            await deleteVenueBalanceGroup({ venueId, balanceGroupId });
            showSuccess('Groups was reset and deleted');
            setDeleted(true);
            hideOverlay();
        } catch (error) {
            showError(error, 'Failed to reset and delete group');
        }

        setIsLoading(false);
    }

    const update = async () => {
        setIsLoading(true);

        try {
            await updateBalanceGroup({ venueId, balanceGroupId }, { ...balanceGroup, ...form });
            showSuccess('Group updated');
            loadVenueBalanceGroups(venueId);
            hideOverlay();
        } catch (error) {
            showError(error, 'Failed to update');
        }

        setIsLoading(false);
    };

    const closed = () => onClosed({ deleted });

    return (
        <BottomOverlay hasOverlay darkContent onClose={closed} visible={visible}>
            <List marginBottom={0} padding={0}>
                <InputLabel>Reset</InputLabel>

                <Padding bottom>
                    <Button isLoading={isLoading} negative block onClick={resetAndDelete}>
                        Reset and delete
                    </Button>
                </Padding>

                <div>
                    <InputLabel>Name</InputLabel>

                    <Input value={form.name} onChangeValue={name => setForm({ ...form, name })} />
                </div>

                <Flex>
                    <div>
                        <InputLabel>Current limit</InputLabel>

                        <Input type="tel" value={form.limit} onChangeValue={limit => setForm({ ...form, limit })} />
                    </div>

                    <div>
                        <InputLabel>Reset limit</InputLabel>

                        <Input
                            type="tel"
                            value={form.limitReset}
                            onChangeValue={limitReset => setForm({ ...form, limitReset })}
                        />
                    </div>
                </Flex>

                <ButtonGroup vertical>
                    <Button isLoading={isLoading} primary block onClick={update}>
                        Update
                    </Button>
                </ButtonGroup>
            </List>
        </BottomOverlay>
    );
}
