import React from 'react';
import { CurrencyMinor } from '../../../../CurrencyMinor';
import { usePosGroup } from '../../../../../hooks/pos/group';
import { UiButton } from '../../../../ui/button/UiButton';

export function PosGroupFooterButtonProducts() {
    const { totalAmountChecked, checkAllProducts } = usePosGroup();

    return (
        <UiButton
            onClick={checkAllProducts}
            color="secondary"
            variant={totalAmountChecked > 0 ? 'text' : 'outlined'}
            fullWidth>
            {totalAmountChecked > 0 ? (
                <>
                    Payment of <CurrencyMinor amount={totalAmountChecked} />
                </>
            ) : (
                <>Select all products</>
            )}
        </UiButton>
    );
}
