import React from 'react';
import styled from 'styled-components';
import { Button } from './Button';
import { Svg } from './Svg';

const Wrapper = styled.div`
    display: flex;
    justify-content: space-between;

    .content-left-wrapper {
        align-items: center;
        display: flex;
        padding: 24px 16px;

        .icon-wrapper {
            margin-right: 8px;
        }
    }

    .content-right-wrapper {
        align-items: center;
        display: flex;
        padding: 24px 16px;
    }

    h2 {
        font-size: 1.25rem;
    }
`;

export function PageTitle({ icon, title, onClickBack, contentRight }) {
    return (
        <Wrapper>
            <div className="content-left-wrapper">
                {onClickBack && (
                    <Button clear onClick={onClickBack}>
                        <Svg icon="arrow-left" size={24} className="icon-wrapper" />
                    </Button>
                )}

                <h2>{title}</h2>
            </div>

            {contentRight && <div className="content-right-wrapper">{contentRight}</div>}
        </Wrapper>
    );
}
