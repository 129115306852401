import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import imagePour from '../assets/pouring.jpg';
import { AnimateCount } from '../components/AnimateCount';
import { BackgroundCover } from '../components/BackgroundCover';
import { DatepickerWithFilters } from '../components/DatepickerWithFilters';
import { Flex } from '../components/Flex';
import { TableTitle } from '../components/TableTitle';
import { Tabs } from '../components/Tabs';
import { V2Grid } from '../components/v2/V2Grid';
import { V2Page } from '../components/v2/V2Page';
import { V2PageHeader } from '../components/v2/V2PageHeader';
import { V2Table } from '../components/v2/V2Table';
import { getVenueProductsReport } from '../microservices/reporting';
import { endOfToday, startOfToday } from '../shared/moment';
import { routes, withParams } from '../shared/router';
import { showError } from '../shared/toasts';
import { translate } from '../shared/translate';
import { stores, useStore } from '../stores';
import { themeActive } from '../themes/dark';
import { useInterval } from '../use/time';
import { useHistory } from 'react-router-dom';

const TableCellFaded = styled(TableCell)`
    color: ${themeActive.colors.faded} !important;
`;

const Title = styled.div`
    font-size: 3rem;
    font-weight: 300;
`;

const SubTitle = styled.div`
    font-size: 1.5rem;
`;

const TextCenter = styled.div`
    text-align: center;
`;

const ReportingBackground = styled.div`
    background: #000;
    padding: 32px 0;
    margin: 0 16px 16px 16px;
    border-radius: 12px;
    box-shadow: var(--box-shadow);
    position: relative;
    overflow: hidden;

    .PageSection {
        position: relative;
    }
`;

const PourLabel = styled.div`
    color: #aaa;
    text-transform: uppercase;
    font-size: 0.75rem;
    letter-spacing: 1px;
    margin-bottom: 8px;
`;

const ContentWrapper = styled.div`
    position: relative;
`;

const OverviewFilter = {
    Summary: 'SUMMARY',
    PerBar: 'PER_BAR',
};

export function StaffVenueReportingBottomsUp() {
    const history = useHistory();
    const [{ venue }] = useStore(stores.staff);
    const [report, setReport] = useState({
        uniqueProductsPoured: 0,
        pours: {
            total: 0,
            small: 0,
            medium: 0,
            large: 0,
        },
        venue: {
            id: '',
            products: [],
        },
        bars: [],
    });
    const [isLoading, setIsLoading] = useState(true);
    const [overviewFilter, setOverviewFilter] = useState(OverviewFilter.Summary);
    const [from, setFrom] = useState(startOfToday());
    const [to, setTo] = useState(endOfToday());

    // TODO: Missing from API
    const currency = venue.currency || 'NOK';

    useEffect(() => {
        loadProductsReport({ from, to });
    }, [from, to]);

    useInterval(
        5 * 1000,
        () => {
            loadProductsReport({ from, to });
        },
        [from, to],
    );

    async function loadProductsReport({ from, to }) {
        if (from && to) {
            setIsLoading(true);

            try {
                const report = await getVenueProductsReport({
                    venueId: venue.id,
                    fromMilliseconds: from.unix() * 1000,
                    toMilliseconds: to.unix() * 1000,
                });

                setReport(report);
            } catch (error) {
                showError(error);
            }

            setIsLoading(false);
        }
    }

    function getProductsTable(products) {
        return (
            <V2Table headers={['Product', 'Total', 'S', 'M', 'L']}>
                {products.map((product) => (
                    <TableRow key={product.id}>
                        <TableCell>{product.name}</TableCell>
                        <TableCell align="right">{product.pours.total}</TableCell>
                        <TableCellFaded align="right">{product.pours.small}</TableCellFaded>
                        <TableCellFaded align="right">{product.pours.medium}</TableCellFaded>
                        <TableCellFaded align="right">{product.pours.large}</TableCellFaded>
                    </TableRow>
                ))}
            </V2Table>
        );
    }

    return (
        <V2Page
            header={
                <V2PageHeader
                    isLoading={isLoading}
                    title={`${venue.name} • Bottoms Up`}
                    onClickBack={() => history.push(withParams(routes.staffVenueReporting, { venueId: venue.id }))}
                />
            }>
            <DatepickerWithFilters onChangeFrom={setFrom} onChangeTo={setTo} />

            {report && (
                <>
                    <ReportingBackground>
                        <BackgroundCover image={imagePour} />

                        <V2Grid>
                            <ContentWrapper>
                                <TextCenter>
                                    <PourLabel>Total pours</PourLabel>

                                    <Title>
                                        <AnimateCount value={report.pours.total} currency={currency} />
                                    </Title>
                                </TextCenter>
                            </ContentWrapper>

                            <ContentWrapper>
                                <Flex>
                                    <TextCenter>
                                        <PourLabel>Small</PourLabel>
                                        <SubTitle>{report.pours.small}</SubTitle>
                                    </TextCenter>
                                    <TextCenter>
                                        <PourLabel>Medium</PourLabel>
                                        <SubTitle>{report.pours.medium}</SubTitle>
                                    </TextCenter>
                                    <TextCenter>
                                        <PourLabel>Large</PourLabel>
                                        <SubTitle>{report.pours.large}</SubTitle>
                                    </TextCenter>
                                </Flex>
                            </ContentWrapper>
                        </V2Grid>
                    </ReportingBackground>

                    {report.uniqueProductsPoured > 0 && (
                        <>
                            <Tabs
                                tabs={[
                                    {
                                        label: translate('Summary'),
                                        value: OverviewFilter.Summary,
                                    },
                                    {
                                        label: translate('Per bar'),
                                        value: OverviewFilter.PerBar,
                                    },
                                ]}
                                value={overviewFilter}
                                onChange={setOverviewFilter}
                            />

                            {overviewFilter === OverviewFilter.Summary && getProductsTable(report.venue.products)}

                            {overviewFilter === OverviewFilter.PerBar &&
                                report.bars.map((bar) => (
                                    <div key={bar.id}>
                                        <TableTitle>{bar.name}</TableTitle>
                                        {getProductsTable(bar.products)}
                                    </div>
                                ))}
                        </>
                    )}
                </>
            )}
        </V2Page>
    );
}
