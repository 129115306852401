import React, { useEffect, useMemo, useState } from 'react';
import { DataResolverStaffBar } from '../../components/data-resolver/DataResolverStaffBar';
import { StaffBottomNav } from '../../components/staff-bottom-nav/StaffBottomNav';
import { StaffBarTerminalSubscriptions } from '../../components/StaffBarTerminalSubscriptions';
import { SwitchRedirect } from '../../components/SwitchRedirect';
import { useIotBarOrderPlaced } from '../../hooks/iot/bar-order-placed';
import { loadStaffVenues } from '../../microservices/legacy-app';
import { AppUserRoleRole } from '../../models/app-user-role';
import { AudioFile, playSound } from '../../providers/audio';
import { useAwsIotSubscription } from '../../providers/aws-iot';
import { useOnWindowFocus } from '../../shared/browser';
import { routes, withParams } from '../../shared/router';
import { translate } from '../../shared/translate';
import { useDeviceConfig } from '../../stores/device-config';
import { useStaffOrderingBar } from '../../stores/staff-ordering-bar';
import { useStaffVenues } from '../../stores/staff-venues';
import { SplashScreen } from '../splash-screen/SplashScreen';
import { StaffCreatePageDataResolver } from '../staff-create/StaffCreatePageDataResolver';
import { StaffOrderConfig } from './StaffOrderConfig';
import { StaffOrderCreatePage } from './StaffOrderCreatePage';
import { StaffOrderStationPage } from './station/StaffOrderStationPage';
import { UiToolbar, UiGrid, UiBox, UiInput } from '@orderx/ui';
import { ToolbarTitle } from '../../@v2/components/toolbar/title/ToolbarTitle';
import { useParams, useHistory } from 'react-router-dom';
import { List } from '../../@v2/components/list/List';
import { ListItem } from '../../@v2/components/list/item/ListItem';

export function StaffOrderPage() {
    const history = useHistory();
    const { barId: matchBarId } = useParams();
    const { config } = useDeviceConfig();
    const isChooseBar = matchBarId === 'choose';
    const venues = useStaffVenues().filter(filterByCorrectRole);
    const { barId, loadBarById, loadBar, reset } = useStaffOrderingBar();
    const incorrectActiveBarId = barId !== matchBarId;
    const [filter, setFilter] = useState('');

    const filteredVenues = useMemo(
        () =>
            venues
                .map((venue) => {
                    const includesVenue = venue.name.toLowerCase().includes(filter.toLowerCase());

                    if (!filter || includesVenue) {
                        return venue;
                    }

                    return {
                        ...venue,
                        bars: venue.bars.filter((bar) => {
                            return !filter || bar.name.toLowerCase().includes(filter.toLowerCase());
                        }),
                    };
                })
                .filter((venue) => venue.bars.length > 0),
        [venues, filter],
    );

    useAwsIotSubscription(barId && `order/staff/bars/${barId}/update`, () => {
        loadBar();
    });

    useIotBarOrderPlaced(barId, () => {
        playSound(AudioFile.OrderNew);
    });

    useAwsIotSubscription(barId && `location/${barId}/publish`, () => {
        loadBar();
    });

    useAwsIotSubscription(barId && `location/${barId}/products-updated`, () => {
        loadBar();
    });

    useEffect(() => {
        loadStaffVenues();
    }, []);

    useEffect(() => {
        if (matchBarId && !isChooseBar) {
            loadBarById(matchBarId);
        } else {
            reset();
        }
    }, [matchBarId]);

    useOnWindowFocus(() => {
        if (barId && matchBarId === barId) {
            loadBar();
        }
    }, [barId, matchBarId]);

    function filterByCorrectRole(venue) {
        return Boolean(
            venue.roles.includes(AppUserRoleRole.Staff) ||
                venue.bars.find((bar) => bar.roles.includes(AppUserRoleRole.Staff)),
        );
    }

    if (isChooseBar) {
        return (
            <>
                <UiToolbar>
                    <ToolbarTitle title="Choose bar" buttonBackOnClick={() => history.push(routes.customerProfile)} />
                </UiToolbar>

                <UiGrid>
                    <UiBox padding={2}>
                        <UiInput
                            label={'Filter bars'}
                            value={filter}
                            placeholder={translate('Type to filter bars')}
                            onChangeValue={setFilter}
                            size={1}
                        />
                    </UiBox>

                    {filteredVenues.map((venue) => (
                        <List title={venue.name} key={venue.id}>
                            {venue.bars.map((bar) => (
                                <ListItem
                                    key={bar.id}
                                    title={bar.name}
                                    to={withParams(routes.staffOrderBar, { barId: bar.id })}
                                />
                            ))}
                        </List>
                    ))}
                </UiGrid>
            </>
        );
    }

    if (!barId || incorrectActiveBarId) {
        return <SplashScreen />;
    }

    const tabRoutes = [
        config.isNewOrderCreateView
            ? {
                  path: routes.staffCreateOrder,
                  component: StaffCreatePageDataResolver,
              }
            : {
                  path: routes.staffOrderBarCreate,
                  component: StaffOrderCreatePage,
              },
        config.isStationViewVisible && {
            path: routes.staffOrderBarStation,
            component: StaffOrderStationPage,
        },
        {
            path: routes.staffOrderBarConfig,
            component: StaffOrderConfig,
        },
    ].filter(Boolean);

    return (
        <DataResolverStaffBar barId={barId}>
            <StaffBarTerminalSubscriptions />
            <SwitchRedirect routes={tabRoutes} />
            <StaffBottomNav />
        </DataResolverStaffBar>
    );
}

StaffOrderPage.route = '/staff/order/:barId';
