import { Entity, Column, ManyToOne, OneToMany } from './typeorm';
import { BaseModel } from './base-model';
import { InventoryPlugin } from './inventory-plugin';
import { InventoryRecipeProduct } from './inventory-recipe-product';
import { InventoryProduct } from './inventory-product';

@Entity()
export class InventoryRecipe extends BaseModel {
    @ManyToOne(() => InventoryProduct, (model) => model.recipes)
    product: InventoryProduct;
    @Column()
    productId: number;

    @OneToMany(() => InventoryRecipeProduct, (model) => model.recipe)
    recipeProducts: InventoryRecipeProduct[];

    @ManyToOne(() => InventoryPlugin, (model) => model.recipes)
    plugin: InventoryPlugin;
    @Column()
    pluginId: number;
}
