import clsx from 'clsx';
import React from 'react';
import './UiButton.scss';
import CircularProgress from '@material-ui/core/CircularProgress';
import { NavLink } from 'react-router-dom';
import { UiFlex } from '@orderx/ui';

export function UiButton({
    className,
    size,
    color,
    variant,
    fullWidth,
    isLoading,
    icon,
    noWrap,
    children,
    to,
    onClick,
    disabled,
    direction = 'row',
}) {
    variant = variant || 'text';
    color = color || 'default';
    size = size || 'default';

    if (variant === 'contained') {
        variant = 'filled';
    }

    const props = {
        className: clsx(
            'UiButtonX',
            className,
            size && `size-${size}`,
            color && `color-${color}`,
            variant && `variant-${variant}`,
            direction && `direction-${direction}`,
            fullWidth && 'fullWidth',
            isLoading && 'loading',
            icon && !children && 'icon',
            noWrap && 'noWrap',
        ),
        disabled,
        onClick,
    };

    const content = (
        <>
            {isLoading && (
                <UiFlex justifyContent="center" fullWidth className="loader">
                    <CircularProgress color="inherit" size={24} />
                </UiFlex>
            )}

            <UiFlex spacing={1} direction={direction}>
                {icon}
                {children && <div>{children}</div>}
            </UiFlex>
        </>
    );

    return to ? (
        <NavLink {...props} to={to}>
            {content}
        </NavLink>
    ) : (
        <button {...props}>{content}</button>
    );
}
