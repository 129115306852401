import { createStore, useStore } from '../create-store';
import { storageGet } from '../shared/storage';

const store = createStore();

export function useUser() {
    const [user] = useStore(store);
    const phone = user?.phone;

    const isTestUser =
        storageGet('isTestUser') ||
        (['+4791919191', '+37253305793', '+4797532550', '+4792687977'].includes(phone || 'invalid') &&
            !localStorage.getItem('staff'));

    const isBetaTester = Boolean(phone === '+37253305793');
    const isTermsAccepted = Boolean(user?.['custom:termsAcceptedDate']);

    function hasRole(role) {
        return (user?.uniqueRoles || []).includes(role);
    }

    function hasLocationRole(locationId, role) {
        return user?.rolesByLocationId?.[locationId]?.includes(role);
    }

    return user
        ? {
              ...user,
              isTermsAccepted,
              isBetaTester,
              isTestUser,
              isAuthenticated: true,
              isNorwegian: phone?.startsWith('+47'),
              hasRole,
              hasLocationRole,
          }
        : {
              isTermsAccepted,
              isBetaTester,
              isTestUser,
              isStaff: false,
              isAuthenticated: false,
              isNorwegian: false,
              hasRole,
              hasLocationRole,
          };
}

export function setUser(user) {
    store.set(user);
}
