import React from 'react';
import ReactSelect from 'react-select';
import { themeActive } from '../themes/dark';

const customStyles = {
    option: (provided, state) => ({
        ...provided,
        color: state.isSelected ? themeActive.colors.text : themeActive.colors.textReversed,
        background: state.isSelected ? themeActive.colors.primary : 'white',
    }),
};

export function Select({
    options,
    value,
    onChange,
    onChangeValue,
    bottom,
    menuPlacement,
    isSearchable = false,
    ...rest
}) {
    return (
        <ReactSelect
            {...rest}
            styles={customStyles}
            isSearchable={isSearchable}
            menuPlacement={menuPlacement || (bottom && 'bottom') || 'top'}
            value={options.find(option => option.value === value)}
            onChange={({ value }) => {
                if (onChangeValue) {
                    onChangeValue(value);
                }

                if (onChange) {
                    onChange(value);
                }
            }}
            options={options}
        />
    );
}
