import { createStore, useStore } from '../../create-store';
import { MicroserviceBookX } from '../../microservices/bookx';
import { useApiQuery } from '@orderx/http';

const store = createStore(undefined);

export function useBookXUser() {
    const [state, setState] = useStore(store);

    const { run: load } = useApiQuery(async () => {
        try {
            setState(await MicroserviceBookX.getMe());
        } catch (error) {
            console.error(error);
        }
    });

    return {
        user: state,
        setUser(user) {
            setState(user);
        },
        reset() {
            setState(undefined);
        },
        load,
        hasCompanies: state?.companies?.length > 0,
    };
}
