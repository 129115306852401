import { useDeviceFingerprint } from '../@v2/hooks/device-fingerprint';
import { MicroserviceOrders } from '../microservices/orders';
import { createStore } from '@orderx/store';
import { storageGet, storageSet } from '../shared/storage';
import moment from 'moment';
import { useEffect, useState } from 'react';

const STORAGE_KEY_FORM = 'profileData';
const STORAGE_KEY_AUTO_REGISTER = 'isAutomaticCheckIn';
const STORAGE_KEY_LAST_REGISTRATION = 'covidRegLastRegistration';

interface Form {
    groupId?: string;
    name: string;
    contactType: 'phone' | 'email';
    email: string;
    areaCode: string;
    phone: string;
    isConfirmed: boolean;
}

const { useStore } = createStore<Form>({
    groupId: '',
    name: '',
    email: '',
    contactType: 'phone',
    areaCode: '+47',
    phone: '',
    ...storageGet(STORAGE_KEY_FORM),
    isConfirmed: false,
});

const { useStore: useStoreIsAutoRegister } = createStore(storageGet(STORAGE_KEY_AUTO_REGISTER) ?? true);

interface LastRegistration {
    venueId: string;
    venueName: string;
    date: string;
}

const { useStore: useStoreLastRegistration } = createStore<LastRegistration>({
    venueId: '',
    venueName: '',
    date: '',
    ...storageGet(STORAGE_KEY_LAST_REGISTRATION),
});

export function useCovidRegistration() {
    const [form, setForm] = useStore();
    const [initialAutoRegister, setInitialAutoRegister] = useState(storageGet(STORAGE_KEY_AUTO_REGISTER));
    const [isAutoRegister, setIsAutoRegister] = useStoreIsAutoRegister();
    const [lastRegistration, setLastRegistration] = useStoreLastRegistration();
    const { deviceFingerprint } = useDeviceFingerprint();
    const lastRegistrationDate = lastRegistration?.date;

    useEffect(() => {
        if (lastRegistrationDate) {
            const isPast = moment().subtract(10, 'days').isAfter(moment(lastRegistrationDate));

            if (isPast) {
                const form = {
                    venueId: '',
                    venueName: '',
                    date: '',
                };

                setLastRegistration(form);
                storageSet(STORAGE_KEY_LAST_REGISTRATION, form);
            }
        }
    }, [lastRegistrationDate]);

    return {
        form,
        setForm,
        initialAutoRegister,
        isAutoRegister,
        setIsAutoRegister(value: boolean) {
            setIsAutoRegister(value);
        },
        lastRegistration,
        lastRegistrationDate: lastRegistration.date ? moment(lastRegistration.date) : undefined,
        setLastRegistration,
        async checkIn({ venueId, venueName, groupId }: { venueId: any; venueName: any; groupId?: any }) {
            await MicroserviceOrders.postAppCustomer({
                action: 'handlerAppCustomerProfileData',
                venueId,
                deviceFingerprint,
                name: form.name,
                areaCode: form.areaCode,
                phone: form.phone,
                email: form.email,
                contactType: form.contactType,
                groupId,
            });

            storageSet(STORAGE_KEY_AUTO_REGISTER, Boolean(isAutoRegister));
            setInitialAutoRegister(Boolean(isAutoRegister));
            storageSet(STORAGE_KEY_FORM, form);

            const lastRegistration: LastRegistration = {
                venueId,
                venueName,
                date: moment().format(),
            };

            setLastRegistration(lastRegistration);
            storageSet(STORAGE_KEY_LAST_REGISTRATION, lastRegistration);
        },
    };
}
