import clsx from 'clsx';
import React, { useState } from 'react';
import './UiToggle.scss';
import findIndex from 'lodash/findIndex';
import { UiButton, UiFlex } from '@orderx/ui';

interface Item {
    label: any;
    value: any;
}

export function useUiToggle(items: Item[]) {
    const [value, setValue] = useState<any>(items[0]?.value);

    return {
        value,
        setValue,
        component: <UiToggle value={value} onChangeValue={setValue} items={items} />,
    };
}

interface Props {
    value: any;
    onChangeValue: (value: any) => void;
    items: Item[];
}

export function UiToggle({ items, value, onChangeValue }: Props) {
    const activeIndex = findIndex(items, { value });

    return (
        <div className={clsx('UiToggle')}>
            <UiFlex className="content" flexChildren>
                <UiButton className={clsx('indicator', `buttons-${items.length}`, `active-${activeIndex}`)} />

                {items.map(({ label, value: buttonValue }) => (
                    <UiButton
                        onClick={() => onChangeValue(buttonValue)}
                        color={buttonValue === value ? 'default' : 'default'}
                        key={buttonValue}>
                        {label}
                    </UiButton>
                ))}
            </UiFlex>
        </div>
    );
}
