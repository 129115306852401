import sum from 'lodash/sum';
import { formatAmount } from '../shared/currency';
import { groupProductsByLocationProductId } from './group-products';

export function getOrderPrintData(products) {
    const productsGrouped = groupProductsByLocationProductId(products);
    const productsTotal = sum(productsGrouped.map(product => product.total)) / 100;

    const padAmount =
        (productsGrouped.find(product => product.count >= 100) && 3) ||
        (productsGrouped.find(product => product.count >= 10) && 2) ||
        1;

    return {
        tableHeader: ['Product', 'Price'],
        tableContent: productsGrouped.map(product => [
            `${String(product.count).padEnd(padAmount, ' ')} x ${product.name}`,
            formatAmount(product.total / 100),
        ]),
        tableFooter: ['Total', formatAmount(productsTotal)],
    };
}
