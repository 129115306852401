import React from 'react';
import styled from 'styled-components';
import { mixins } from '../shared/styles';
import { stores, useStore } from '../stores';
import { themeActive } from '../themes/dark';
import { Svg } from './Svg';

const Wrapper = styled.div`
    .nfc-scan-effect-wrapper {
        ${mixins.flexXY};
        position: fixed;
        top: -200px;
        left: 0;
        width: 100%;
        z-index: 30;
        transform: rotate(180deg);
        animation-name: fadeOut;
        animation-duration: 1s;
        animation-delay: 500ms;
        animation-fill-mode: forwards;
    }

    .nfc-scan-wrapper {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        z-index: 31;
    }
`;

export function NfcScanEffect() {
    const [{ scanning }] = useStore(stores.nfc);

    return (
        <Wrapper>
            {scanning && (
                <div className="nfc-scan-effect-wrapper">
                    <Svg
                        icon="nfc-scan-effect"
                        size={400}
                        fill={themeActive.colors.green}
                        className="animated bounceIn"
                    />
                </div>
            )}
        </Wrapper>
    );
}
