import React, { useEffect } from 'react';
import { UiSwitch } from '../../components/ui/UiSwitch';
import { V2Page } from '../../components/v2/V2Page';
import { V2PageHeader } from '../../components/v2/V2PageHeader';
import { MicroserviceOrders } from '../../microservices/orders';
import { routes } from '../../shared/router';
import { useSnackbar } from '../../shared/snackbar';
import { useStaffOrderingBar } from '../../stores/staff-ordering-bar';
import { useApiQuery } from '@orderx/http';
import { List } from '../../@v2/components/list/List';
import { ListItem } from '../../@v2/components/list/item/ListItem';

export function StaffOrderConfigTableAvailabilityPage({ history }) {
    const { barId } = useStaffOrderingBar();
    const { showSuccess } = useSnackbar();

    const { run: loadGroups, isLoading: isLoadingGroups, response: groups = [] } = useApiQuery(() =>
        MicroserviceOrders.staffApp({
            action: 'GROUPS_GET',
            barId,
        }),
    );

    const { run, isLoading } = useApiQuery(async ({ groupId, isInactive }) => {
        await MicroserviceOrders.staffApp({
            action: 'GROUPS_UPDATE',
            groupId,
            isInactive,
        });

        await loadGroups();
        showSuccess('Table updated');
    });

    useEffect(() => {
        loadGroups();
    }, []);

    return (
        <V2Page
            isLoading={isLoading || isLoadingGroups}
            header={
                <V2PageHeader
                    onClickBack={() => history.push(routes.staffOrderBarConfig.replace(':barId', barId))}
                    title={'Tables'}
                />
            }>
            <List>
                {groups.map((group) => (
                    <ListItem
                        key={group.id}
                        title={group.name}
                        iconEnd={
                            <UiSwitch
                                onChange={(value) => run({ groupId: group.id, isInactive: !value })}
                                checked={!group.isInactive}
                                noFormGroup
                            />
                        }
                    />
                ))}
            </List>
        </V2Page>
    );
}
