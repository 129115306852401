import { getApiUrl } from '../config';
import { httpPost } from '../shared/api';

const getUrl = getApiUrl('location');

export const MicroserviceLocation = {
    appCustomerPost(body) {
        return httpPost(getUrl('app/customer'), body);
    },

    appStaffIndexPost(body) {
        return httpPost(getUrl('app/staff/index'), body);
    },

    findDeliveryData() {
        return httpPost(getUrl('app/staff/index'), {}, { action: 'handlerFindDeliveryData' });
    },

    findDeliveryBarsData(body) {
        return httpPost(getUrl('app/staff/index'), body, { action: 'handlerFindDeliveryBarsData' });
    },

    findBarById(barId) {
        return httpPost(getUrl('app/staff/index'), { barId }, { action: 'FIND_BAR' });
    },

    updateBarConfig(barId, config) {
        return httpPost(getUrl('app/staff/index'), { barId, config }, { action: 'UPDATE_BAR_CONFIG' });
    },

    findBarConfig(barId) {
        return httpPost(getUrl('app/staff/index'), { barId }, { action: 'FIND_BAR_CONFIG' });
    },

    findVenues() {
        return httpPost(getUrl('app/customer'), {}, { action: 'FIND_VENUES' });
    },
};
