import DialogContent from '@material-ui/core/DialogContent';
import makeStyles from '@material-ui/core/styles/makeStyles';
import clsx from 'clsx';
import React from 'react';
import { themeActive } from '../../../themes/dark';

const useStyles = makeStyles({
    UiDialogContent: {
        backgroundColor: themeActive.dialog.content.backgroundColor,
        borderTop: themeActive.dialog.border,
        borderBottom: themeActive.dialog.border,
    },
});

export function UiDialogContent(props) {
    const classes = useStyles();
    return <DialogContent {...props} className={clsx(classes.UiDialogContent, props.className)} />;
}
