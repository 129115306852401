import { createStore, useStore } from '../create-store';
import { routes } from '../shared/router';
import { translate } from '../shared/translate';
import { useUser } from './user';
import { useBookXUser } from '../hooks/bookx/user';
import { SmartqPage } from '../pages/smartq/SmartqPage';
import { OrderHistoryPage } from '../@v2/pages/order-history/OrderHistoryPage';
import { UiIconMaterial } from '@orderx/ui';
import React from 'react';
import { useDevice } from '../shared/device';
import { useLocation } from 'react-router-dom';
import { CustomerBrowsePage } from '../pages/customer/browse/CustomerBrowsePage';

const store = createStore({
    browseButtonLink: '',
    isHidden: false,
});

export function useCustomerTabs() {
    const { pathname } = useLocation();
    const { isNative } = useDevice();
    const [{ browseButtonLink, isHidden }, setState] = useStore(store);
    const { isAuthenticated, isNorwegian } = useUser();
    const { hasCompanies } = useBookXUser();

    const tabs = [];

    tabs.push({
        label: translate('Browse'),
        path: pathname === browseButtonLink ? CustomerBrowsePage.route : browseButtonLink || CustomerBrowsePage.route,
        icon: <UiIconMaterial size={20}>search</UiIconMaterial>,
        onClick: resetBrowseButtonLink,
    });

    tabs.push({
        label: translate('Orders'),
        path: OrderHistoryPage.route,
        icon: <UiIconMaterial size={20}>receipt</UiIconMaterial>,
    });

    if (isNative) {
        tabs.push({
            label: translate('Scan'),
            path: routes.customerScan,
            icon: <UiIconMaterial size={20}>control_camera</UiIconMaterial>,
        });
    }

    if (hasCompanies) {
        tabs.push({
            label: translate(isNorwegian ? 'Smartkø' : 'SmartQueue'),
            path: SmartqPage.route,
            icon: <UiIconMaterial size={20}>group</UiIconMaterial>,
        });
    }

    tabs.push(
        isAuthenticated
            ? {
                  label: translate('Profile'),
                  path: routes.customerProfile,
                  icon: <UiIconMaterial size={20}>person</UiIconMaterial>,
              }
            : {
                  label: translate('Login'),
                  path: routes.auth,
                  icon: <UiIconMaterial size={20}>lock</UiIconMaterial>,
              },
    );

    function resetBrowseButtonLink() {
        setState((state) => {
            state.browseButtonLink = undefined;
        });
    }

    return {
        tabs,

        isHidden,
        setIsHidden(isHidden) {
            setState((state) => {
                state.isHidden = isHidden;
            });
        },

        setBrowseButtonLink(browseButtonLink) {
            setState((state) => {
                state.browseButtonLink = browseButtonLink;
            });
        },

        resetBrowseButtonLink,
    };
}
