import React from 'react';
import { translate } from '../shared/translate';
import { Datepicker } from './Datepicker';
import { Flex } from './Flex';

export function DatepickerFromTo({ from, to, onChangeFrom, onChangeTo }) {
    return (
        <Flex>
            <Datepicker label={translate('From')} value={from} onChange={onChangeFrom} maxDate={to} />
            <Datepicker label={translate('To')} value={to} onChange={onChangeTo} minDate={from} />
        </Flex>
    );
}
