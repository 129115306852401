export const theme = {
    header: {
        backgroundColor: 'var(--color-background-body)',
        height: 50,
        zIndex: 10,
        loaderBackgroundColor: '#333',
    },
    body: {
        backgroundColor: 'var(--color-background-body)',
    },
    tabs: {
        backgroundColor: 'var(--color-background-body)',
        height: 50,
    },
    dialog: {
        border: '1px solid #222',
        content: {
            backgroundColor: 'var(--color-background-body)',
        },
    },
    card: {
        backgroundColor: 'var(--color-background-paper)',
        backgroundImage: 'linear-gradient(to bottom,#2a2a2a,#191919)',
        boxShadow: '0 2px 8px #111',
        borderRadius: 8,
    },
    colors: {
        paper: 'var(--color-background-paper)',

        background: 'var(--color-background-body)',
        backgroundReversed: '#e6e6e6',

        faded: '#666',

        bottomOverlay: 'var(--color-background-body)',
        bottomOverlayReversed: '#ffffff',

        text: 'var(--color-text-primary)',
        textReversed: 'var(--color-text-primary-inverse)',

        primary: '#4265e8',
        secondary: '#f50057',
        green: '#32db64',
        yellow: '#ffeb3b',
        red: '#ff1133',

        success: '#32db64',
        error: '#ff1133',
        warning: '#ff9800',
        border: '#282828',

        inputBackgroundColor: 'white',
        inputBorderColor: '#ccc',
        inputLabelColor: '#aaa',
        inputColor: '#222',

        videoBackground: '#161516',
    },
    gradients: {
        main: `linear-gradient(to bottom, #2a2a2a, #191919)`,
        primary: `linear-gradient(to right, #4265e8 0%, #6a11cb 100%)`,
        red: `linear-gradient(to right, #ff1133 0%, #ff1133 100%)`,
        accent: 'linear-gradient(to right, #6a11cb 0%, #2575fc 100%)',
        dark: `linear-gradient(to bottom, #2a2a2a, #191919)`,
        alternative: `linear-gradient(to bottom, #ff768b, #ff9876)`,
        green: `linear-gradient(to bottom, #32db64, #32db64)`,
    },
    sizes: {
        base: 16,
        borderRadius: 6,
        buttonHeight: 44,
        inputHeight: 44,
        headerHeight: 50,
        tabsHeight: 50,
    },
};

export const themeActive = theme;
