import React, { useEffect, useState } from 'react';
import { Route } from 'react-router-dom';
import { BottomTab } from '../components/BottomTab';
import { BottomTabs } from '../components/BottomTabs';
import { CssLoader } from '../components/CssLoader';
import { SwitchRedirect } from '../components/SwitchRedirect';
import { V2Grid } from '../components/v2/V2Grid';
import { V2List } from '../components/v2/V2List';
import { V2ListItem } from '../components/v2/V2ListItem';
import { V2PageHeader } from '../components/v2/V2PageHeader';
import { MicroserviceClients } from '../microservices/clients';
import { routes, withParams } from '../shared/router';
import { showError } from '../shared/toasts';
import { translate } from '../shared/translate';
import { useActiveClient } from '../stores/client';
import { StaffClientsLinkWristbands } from './StaffClientsLinkWristbands';
import { StaffClientsWristbands } from './StaffClientsWristbands';

export function StaffClients() {
    return (
        <div>
            <Route path={routes.staffClients} component={ChooseLocation} exact />
            <Route path={routes.staffClientsId} component={Layout} />
        </div>
    );
}

function Layout({ match, history }) {
    const { clientId } = match.params;
    const [isLoading, setIsLoading] = useState(false);
    const [client, setClient] = useActiveClient();

    const isValidClient = client && client.id === clientId;

    useEffect(() => {
        load(clientId);
        return () => setClient();
    }, [clientId]);

    async function load(clientId) {
        setIsLoading(true);

        try {
            const client = await MicroserviceClients.findClientById({ clientId });
            setClient(client);
        } catch (error) {
            showError(error);
        }

        setIsLoading(false);
    }

    if (!isValidClient) {
        return (
            <V2PageHeader title={translate('Loading...')} onClickBack={() => history.goBack()} isLoading={isLoading} />
        );
    }

    const tabs = [
        {
            label: translate('Wristbands'),
            icon: 'wristband',
            path: withParams(routes.staffClientsIdWristbands, { clientId }),
            to: withParams(routes.staffClientsIdWristbands, { clientId }),
            component: StaffClientsWristbands,
        },
        {
            label: translate('Link wristbands'),
            icon: 'link',
            path: withParams(routes.staffClientsIdLinkWristbands, { clientId }),
            to: withParams(routes.staffClientsIdLinkWristbands, { clientId }),
            component: StaffClientsLinkWristbands,
        },
        {
            label: 'Exit',
            icon: 'exit',
            path: routes.customerProfile,
            to: routes.customerProfile,
        },
    ];

    return (
        <>
            <SwitchRedirect routes={tabs} />

            <BottomTabs>
                {tabs.map(tab => (
                    <BottomTab label={tab.label} to={tab.to} icon={tab.icon} key={tab.label} />
                ))}
            </BottomTabs>
        </>
    );
}

function ChooseLocation({ history }) {
    const [isLoading, setIsLoading] = useState(true);
    const [clients, setClients] = useState([]);

    useEffect(() => {
        load();
    }, []);

    async function load() {
        setIsLoading(true);

        try {
            setClients(await MicroserviceClients.findClients());
        } catch (error) {
            showError(error);
        }

        setIsLoading(false);
    }

    return (
        <>
            <V2PageHeader
                title={translate('Choose client')}
                onClickBack={() => history.goBack()}
                isLoading={isLoading}
            />

            <V2Grid>
                <V2List>
                    {clients.map(client => (
                        <V2ListItem
                            to={withParams(routes.staffClientsIdWristbands, { clientId: client.id })}
                            key={client.id}
                            label={client.companyName}
                            iconRight="arrow-right"
                        />
                    ))}
                </V2List>

                {isLoading && <CssLoader />}
            </V2Grid>
        </>
    );
}
