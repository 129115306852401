import makeStyles from '@material-ui/core/styles/makeStyles';
import clsx from 'clsx';
import React from 'react';
import { themeActive } from '../../themes/dark';
import { UiButton } from '../../@deprecated/UiButton';

const useStyles = makeStyles({
    placeholder: {
        height: 64,
    },
    button: {
        border: 0,
        borderRadius: 0,
        position: 'fixed',
        bottom: 0,
        left: 0,
        width: '100%',
        zIndex: 1,
    },
    aboveTabs: {
        bottom: themeActive.tabs.height,
    },
});

export function UiButtonBottom({ position = '', ...buttonProps }) {
    const classes = useStyles();

    return (
        <>
            <div className={classes.placeholder} />

            <UiButton
                {...buttonProps}
                fullWidth
                color={'primary'}
                size={'large'}
                variant={'contained'}
                className={clsx(classes.button, buttonProps.className, position === 'aboveTabs' && classes.aboveTabs)}
            />
        </>
    );
}
