import { translate } from '../../shared/translate';

export const ProductStatus = {
    PaymentRequired: 'PAYMENT_REQUIRED',
    Placed: 'PLACED',
    InPreparation: 'IN_PREPARATION',
    Ready: 'READY',
    InDelivery: 'IN_DELIVERY',
    Delivered: 'DELIVERED',
    Rejected: 'REJECTED',
};

export function getProductStatusLabel(status) {
    return {
        [ProductStatus.PaymentRequired]: translate('Payment required'),
        [ProductStatus.Placed]: translate('Placed'),
        [ProductStatus.InPreparation]: translate('In preparation'),
        [ProductStatus.Ready]: translate('Ready'),
        [ProductStatus.InDelivery]: translate('In delivery'),
        [ProductStatus.Delivered]: translate('Handed out'),
        [ProductStatus.Rejected]: translate('Rejected'),
    }[status];
}
