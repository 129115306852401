import React from 'react';
import { UiGrid, UiOverlay } from '@orderx/ui';
import styled from 'styled-components';
import { useCovidRegistration } from '../../../stores/covid-registration';
import { TagCovidRegistration } from '../../../components/tag/covid-registration/TagCovidRegistration';

const Wrapper = styled(UiGrid)`
    .UiOverlay {
        z-index: 100000;
    }

    .step {
        background-color: var(--color-success);
        font-weight: bold;
        height: 36px;
        width: 36px;
        min-height: 36px;
        min-width: 36px;
        font-size: 1.25rem;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 100%;
        line-height: 1;
    }

    .underlined {
        text-decoration: underline;
    }

    .button-checkbox .UiButton {
        white-space: initial;
        text-align: left;
    }
`;

interface Props {
    venueId: string;
    venueName: string;
    covidTagTargetBarId?: string;

    onCancel(): void;

    onSuccess(): void;
}

export function ProfileDataForm({ venueId, venueName, covidTagTargetBarId, onCancel, onSuccess }: Props) {
    const { initialAutoRegister } = useCovidRegistration();

    const registrationElement = (
        <TagCovidRegistration
            tag={{ venueId, venueName, covidTagTargetBarId }}
            onSuccess={onSuccess}
            onCancel={onCancel}
        />
    );

    if (initialAutoRegister) {
        return registrationElement;
    }

    return (
        <Wrapper>
            <UiOverlay transparent>{registrationElement}</UiOverlay>
        </Wrapper>
    );
}
