import React, { Fragment, useState } from 'react';
import { UiSwitch } from '../../../../ui/UiSwitch';
import { UiFlex, UiButton, UiGrid, UiIconMaterial } from '@orderx/ui';
import { useApiQuery } from '@orderx/http';
import { MicroserviceOrders } from '../../../../../microservices/orders';
import { useAdminConfiguration } from '../../../../../@v2/hooks/admin-configuration';
import { useSnackbar } from '../../../../../shared/snackbar';

interface Props {
    bar: any;
}

export function AdminConfigurationVenueBar({ bar }: Props) {
    const { id: barId, inactive } = bar;

    const { showSuccess } = useSnackbar();
    const { load } = useAdminConfiguration();
    const [isMoreVisible, setIsMoreVisible] = useState(false);
    const [allGroupsVisible, setAllGroupsVisible] = useState(false);

    const { run: toggleInactive, isLoading: isUpdating } = useApiQuery(async () => {
        await MicroserviceOrders.staffApp({
            action: 'handlerAppStaffConfigurationUpdate',
            method: 'TOGGLE_INACTIVE',
            barId,
            inactive: !inactive,
        });

        await new Promise((r) => setTimeout(r, 2000));
        await load();
        showSuccess('Bar updated');
    });

    const { run: setGroupInactive, isLoading: isUpdatingTables } = useApiQuery(async ({ groupIds, isInactive }) => {
        await MicroserviceOrders.staffApp({
            action: 'handlerAppStaffConfigurationUpdate',
            method: 'SET_GROUP_TABLES_INACTIVE',
            groupIds,
            isInactive,
        });

        await load();
        showSuccess('Bar updated');
    });

    const groupIds = bar.groups.map((group) => group.id);
    return (
        <Fragment key={bar.id}>
            <tr>
                <td>{bar.name}</td>
                <td align="right" className="switch">
                    <UiFlex justifyContent="flex-end">
                        <UiSwitch disabled={isUpdating} checked={!inactive} onChange={() => toggleInactive()} />
                        <UiButton
                            onClick={() => setIsMoreVisible(!isMoreVisible)}
                            color={isMoreVisible ? 'secondary' : 'default'}
                            icon={<UiIconMaterial>more_vert</UiIconMaterial>}
                            size={1}
                        />
                    </UiFlex>
                </td>
            </tr>

            {isMoreVisible && (
                <>
                    <tr>
                        <td colSpan={2}>
                            <UiFlex flexChildren spacing={1}>
                                <UiButton
                                    onClick={() => setGroupInactive({ groupIds, isInactive: true })}
                                    isLoading={isUpdatingTables}
                                    size={-1}
                                    variant="filled">
                                    Close all tables
                                </UiButton>

                                <UiButton
                                    onClick={() => setGroupInactive({ groupIds, isInactive: false })}
                                    isLoading={isUpdatingTables}
                                    size={-1}
                                    variant="filled">
                                    Open all tables
                                </UiButton>

                                <UiButton
                                    onClick={() => setAllGroupsVisible(!allGroupsVisible)}
                                    size={-1}
                                    color={allGroupsVisible ? 'secondary' : 'default'}
                                    variant="filled">
                                    Show all tables
                                </UiButton>
                            </UiFlex>
                        </td>
                    </tr>

                    {allGroupsVisible && (
                        <tr>
                            <td colSpan={2}>
                                <UiGrid minMax={60} spacing={1}>
                                    {bar.groups.map((group) => (
                                        <UiButton
                                            onClick={() =>
                                                setGroupInactive({
                                                    groupIds: [group.id],
                                                    isInactive: !group.isInactive,
                                                })
                                            }
                                            isLoading={isUpdatingTables}
                                            variant="filled"
                                            color={group.isInactive ? 'default' : 'success'}
                                            size={-1}
                                            key={group.id}>
                                            {group.name}
                                        </UiButton>
                                    ))}
                                </UiGrid>
                            </td>
                        </tr>
                    )}
                </>
            )}
        </Fragment>
    );
}
