import { isNative } from './device';

export function getUrlToAsset(path) {
    if (!isNative()) {
        return path;
    }

    if (path.startsWith('/')) {
        return path.slice(1, path.length);
    } else {
        return path;
    }
}
