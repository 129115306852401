import React from 'react';
import styled from 'styled-components';

const Wrapper = styled.div`
    display: grid;
    grid-gap: 8px;
    grid-template-columns: ${(props) =>
        props.isSingleRow ? 'grid-template-columns: repeat(1fr)' : 'repeat(auto-fill, minmax(200px, 1fr))'};

    .card {
        margin-bottom: 0 !important;
    }
`;

export function StaffProductGrid({ children, isSingleRow = false }) {
    return <Wrapper isSingleRow={isSingleRow}>{children}</Wrapper>;
}
