export const QueueStatus = {
    NotSent: 'NOT_SENT',
    Expired: 'EXPIRED',
    Sent: 'SENT',
    CancelledByStaff: 'CANCELLED_BY_STAFF',
    Registered: 'REGISTERED',
    Finished: 'FINISHED',
    Billed: 'BILLED',
};

export const QueueStatusLabel = {
    [QueueStatus.NotSent]: 'Not sent',
    [QueueStatus.CancelledByStaff]: 'Cancelled',
    [QueueStatus.Expired]: 'Expired',
    [QueueStatus.Sent]: 'Sent',
    [QueueStatus.Registered]: 'Registered',
    [QueueStatus.Finished]: 'Finished',
    [QueueStatus.Billed]: 'Billed',
};
