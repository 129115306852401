import { createStore, useStore } from '../create-store';
import { getOrders } from '../microservices/orders';
import { storage, storageSet } from '../shared/storage';
import { useDeviceFingerprint } from '../@v2/hooks/device-fingerprint';
import { useEffect, useCallback } from 'react';
import uniq from 'lodash/uniq';

const initialState = () => ({
    orders: [],
    orderIds: [],
});

const StorageKey = 'orderPlaced';
const store = createStore(storage.get(StorageKey) || initialState());

export function useOrdersPlaced() {
    const { deviceFingerprint } = useDeviceFingerprint();
    const [state, setState] = useStore(store);
    const { orders, orderIds } = state;

    const refresh = useCallback(async () => {
        try {
            const orders = await getOrders({
                orderIds,
                deviceFingerprint,
            });

            setState((state) => {
                state.orders = orders;
            });
        } catch (error) {
            console.error(error);
        }
    }, [orderIds, deviceFingerprint]);

    return {
        orders,
        orderIds,
        refresh,
        load: refresh,
        useAutoSync() {
            useEffect(() => {
                storageSet(StorageKey, state);
            }, [state]);
        },
        cacheOrderId(orderId) {
            setState((state) => {
                state.orderIds = uniq([...state.orderIds, orderId]).filter(Boolean);
            });
        },
    };
}
