import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import makeStyles from '@material-ui/core/styles/makeStyles';
import TableCell from '@material-ui/core/TableCell';
import SearchRounded from '@material-ui/icons/SearchRounded';
import isNumber from 'lodash/isNumber';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { MicroserviceInventory } from '../../microservices/inventory';
import { showSuccess } from '../../shared/toasts';
import { useCountById } from '../../use/count-by-id';
import { useLocationProducts } from '../../use/location-products';
import { useApiQuery } from '@orderx/http';
import { IncrementDecrement } from '../IncrementDecrement';
import { UiButtonConfirmDialog } from '../ui/UiButtonConfirmDialog';
import { UiInput } from '../ui/UiInput';
import { UiLoader } from '../ui/UiLoader';
import { UiTableDefault } from '../ui/UiTableDefault';
import { InventoryStockHoldingReportDateLabel } from './InventoryStockHoldingReportDateLabel';
import { InventoryUnitLabel } from './InventoryUnitLabel';
import { UiFlex } from '@orderx/ui';

const useStyles = makeStyles({
    InventoryStockHoldingReportTab: {
        padding: 16,
    },
});

export function InventoryStockHoldingReportTab({ inventoryId, locationId, onConfirmed }) {
    const classes = useStyles();
    const [report, setReport] = useState();
    const [comment, setComment] = useState('');
    const [search, setSearch] = useState('');
    const [products, setProducts] = useState([]);
    const reportId = report && report.id;
    const { counts, increment, decrement, setCount } = useCountById();

    const { isLoading, run: loadForm, response } = useApiQuery(() =>
        MicroserviceInventory.v2AppInventory({
            action: 'FIND_STOCK_HOLDING_REPORT',
            locationId,
            inventoryId,
        }),
    );

    const saveReportCallback = useCallback(
        () =>
            MicroserviceInventory.v2AppInventory({
                locationId,
                action: 'SAVE_STOCK_HOLDING_REPORT',
                comment,
                reportId,
                products: products.map((product) => ({
                    productId: product.productId,
                    actualCount: counts[product.productId],
                })),
            }),
        [locationId, comment, reportId, products, counts],
    );

    const { run: confirmReport, isLoading: isConfirming } = useApiQuery(async () => {
        await saveReportCallback();

        await MicroserviceInventory.v2AppInventory({
            locationId,
            action: 'CONFIRM_STOCK_HOLDING_REPORT',
            reportId,
        });

        showSuccess('Inventory has been updated and report has been created');
        onConfirmed();
    });

    const { run: saveReport, isLoading: isSaving } = useApiQuery(async () => {
        await saveReportCallback();
        showSuccess('Report has been updated');
    });

    useEffect(() => {
        loadForm();
    }, []);

    useEffect(() => {
        if (!response) {
            return;
        }

        if (response.inventory) {
            const { report, products } = response.inventory;

            setReport(report);
            setProducts(products);
            setComment(report.comment || '');

            for (const { productId, actualUnitAmount, expectedUnitAmount } of products) {
                setCount(productId, 0);

                if (isNumber(expectedUnitAmount) && expectedUnitAmount > 0) {
                    setCount(productId, expectedUnitAmount);
                }

                if (isNumber(actualUnitAmount)) {
                    setCount(productId, actualUnitAmount);
                }
            }
        }
    }, [response]);

    const locationProducts = response && response.inventory && response.inventory.products;
    const { getInventoryUnitAmount } = useLocationProducts(locationProducts);

    const productsFiltered = useMemo(() => {
        if (!search) {
            return products;
        }

        return products.filter((product) => (product.productName || '').toLowerCase().includes(search.toLowerCase()));
    }, [search, products]);

    const isDisabled = isSaving || isConfirming;
    const stockHoldingReport = response && response.inventory && response.inventory.report;

    if (!response) {
        return <UiLoader />;
    }

    return (
        <div className={classes.InventoryStockHoldingReportTab}>
            {!isLoading && stockHoldingReport && (
                <Grid container spacing={4}>
                    <Grid item xs={12} className={classes.dateFromTo}>
                        <InventoryStockHoldingReportDateLabel report={stockHoldingReport} />
                    </Grid>

                    <Grid item xs={12}>
                        <UiInput
                            placeholder={'Type to filter products'}
                            value={search}
                            onChangeValue={setSearch}
                            startAdornment={<SearchRounded />}
                        />
                    </Grid>

                    <UiTableDefault
                        headers={['Name', 'Expected', 'Actual']}
                        rows={productsFiltered}
                        mapRow={({
                            productId,
                            productName,
                            expectedUnitAmount,
                            inventoryPackagingUnit,
                            inventoryUnit,
                            inventoryUnitAmount,
                        }) => (
                            <>
                                <TableCell>{productName || '?'}</TableCell>

                                <TableCell>
                                    <InventoryUnitLabel
                                        amount={expectedUnitAmount}
                                        packagingUnit={inventoryPackagingUnit}
                                        unit={inventoryUnit}
                                        unitAmount={inventoryUnitAmount}
                                    />
                                </TableCell>

                                <TableCell>
                                    <UiFlex justifyContent={'flex-end'}>
                                        <IncrementDecrement
                                            showZero
                                            multiplier={getInventoryUnitAmount(productId)}
                                            disabled={isLoading}
                                            count={counts[productId]}
                                            onIncrement={(amount) => increment(productId, amount)}
                                            onDecrement={(amount) => decrement(productId, amount)}
                                        />
                                    </UiFlex>
                                </TableCell>
                            </>
                        )}
                    />

                    <Grid item xs={12}>
                        <UiInput label={'Comment'} value={comment} onChangeValue={setComment} multiline rows={2} />
                    </Grid>

                    <Grid item xs={12} className={classes.footer}>
                        <UiFlex justifyContent={'flex-end'} spacing={1}>
                            <UiButtonConfirmDialog
                                buttonText={'Confirm report'}
                                buttonProps={{ isLoading: isConfirming, color: 'default' }}
                                onConfirm={confirmReport}
                                dialogContent={
                                    <div>
                                        <div className={classes.InventoryStockHoldingReportTab}>
                                            By confirming report, changes will be made to the active inventory. This
                                            process <b>cannot</b> be reverted!
                                        </div>

                                        <Grid className={classes.InventoryStockHoldingReportTab}>
                                            Confirmed reports can be viewed under
                                            <br />
                                            <b>Report history</b>
                                        </Grid>
                                    </div>
                                }
                            />

                            <Button color={'primary'} isLoading={isSaving} disabled={isDisabled} onClick={saveReport}>
                                Save report
                            </Button>
                        </UiFlex>
                    </Grid>
                </Grid>
            )}
        </div>
    );
}
