import clsx from 'clsx';
import React, { useState, useEffect, useMemo } from 'react';
import { UiToolbar, UiGrid, UiInput, UiButton, UiText, UiButtonCheckbox } from '@orderx/ui';
import { ToolbarTitle } from '../toolbar/title/ToolbarTitle';
import { CustomerProfilePage } from '../../../pages/CustomerProfilePage';
import { useApiQuery } from '@orderx/http';
import { loadStaffVenues } from '../../../microservices/legacy-app';
import { compareStrings } from '../../../util/compare-strings';
import { useStateStorage } from '../../../use/state-storage';

interface Props {
    barIdCacheKey: any;
    onChooseBar(bar: any): void;
}

export function ChooseBar({ barIdCacheKey, onChooseBar }: Props) {
    const [barId, setBarId] = useStateStorage(barIdCacheKey, '');
    const [barIdSelected, setBarIdSelected] = useState(barId || '');
    const [keyword, setKeyword] = useState('');

    const { run, response: venues = [] } = useApiQuery<any>(loadStaffVenues);

    const venuesFiltered = useMemo(() => {
        return venues
            .map((venue) => {
                if (!keyword || compareStrings(venue.name, keyword)) {
                    return venue;
                }

                return {
                    ...venue,
                    bars: venue.bars.filter((bar) => !keyword || compareStrings(bar.name, keyword)),
                };
            })
            .filter((venue) => venue.bars.length > 0);
    }, [venues, keyword]);

    const bars = venues.map((venue) => venue.bars).flat();
    const barSelected = bars.find((bar) => bar.id === barIdSelected);

    useEffect(() => {
        run();
    }, []);

    return (
        <div className={clsx('ChooseBar')}>
            <UiToolbar>
                <ToolbarTitle title="Choose bar" buttonBackLink={CustomerProfilePage.route} />
            </UiToolbar>

            <UiGrid padding={2}>
                <UiInput value={keyword} onChangeValue={setKeyword} size={1} placeholder="Type to filter" />

                {venuesFiltered.map((venue) => (
                    <UiGrid key={venue.id}>
                        <UiText transform="uppercase" weight={2}>
                            {venue.name}
                        </UiText>

                        <UiGrid minMax={160} gap={1} className="bars">
                            {venue.bars.map((bar) => (
                                <UiButton
                                    onClick={() => setBarIdSelected(bar.id)}
                                    color={bar.id === barIdSelected ? 'secondary' : 'default'}
                                    variant="filled"
                                    key={bar.id}>
                                    {bar.name}
                                </UiButton>
                            ))}
                        </UiGrid>
                    </UiGrid>
                ))}
            </UiGrid>

            {barSelected && (
                <UiToolbar position="bottom">
                    <UiButton
                        size={2}
                        variant="filled"
                        color="primary"
                        fullWidth
                        onClick={() => {
                            setBarId(barSelected.id);
                            onChooseBar(barSelected);
                        }}>
                        Continue to {barSelected.name}
                    </UiButton>
                </UiToolbar>
            )}
        </div>
    );
}
