import React, { useEffect } from 'react';
import { useParams, Switch, Route } from 'react-router-dom';
import { usePos } from '../../../hooks/pos';
import { UiRouterRedirect } from '@orderx/ui';
import { PosIdCreatePage } from './create/PosIdCreatePage';
import { PosFooter } from '../../../components/pos/footer/PosFooter';
import { PosIdOrderHistoryPage } from './order-history/PosIdOrderHistoryPage';

export function PosIdPage() {
    const { locationId } = useParams();
    const { loadLocation } = usePos();

    useEffect(() => {
        loadLocation(locationId);
    }, [locationId]);

    return (
        <>
            <Switch>
                <Route path={PosIdCreatePage.route}>
                    <PosIdCreatePage />
                </Route>

                <Route path={PosIdOrderHistoryPage.route}>
                    <PosIdOrderHistoryPage />
                </Route>

                <UiRouterRedirect to={PosIdCreatePage.route} params={{ locationId }} />
            </Switch>

            <PosFooter />
        </>
    );
}

PosIdPage.route = '/v2/pos/:locationId';
