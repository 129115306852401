import keyBy from 'lodash/keyBy';
import sum from 'lodash/sum';
import { useMemo } from 'react';
import { createStore, useStore } from '../create-store';
import { useOrderGroup } from '../stores/order-group';

const store = createStore(getInitialState());

function getInitialState() {
    return {
        productCountById: {},
        groupId: '',
        productDataById: {},
    };
}

export function useCustomerActiveOrder({ hash }) {
    const { products } = useOrderGroup(hash);
    const productById = keyBy(products, (product) => product.id);
    const [order, setOrder] = useStore(store);
    const { groupId, productCountById, productDataById } = order;

    const productsWithCounts = useMemo(
        () =>
            Object.keys(productCountById)
                .map((id) => ({
                    ...productById[id],
                    ...productDataById[id],
                    count: productCountById[id],
                }))
                .filter((product) => product.count > 0),
        [productCountById, productById],
    );

    const totalProductsAdded = sum(productsWithCounts.map((product) => product.count));

    // TODO: DO NOT DEFAULT TO NOK
    const currency = (productsWithCounts.length > 0 && productsWithCounts[0].currency) || 'NOK';

    return {
        hasActiveOrder: productsWithCounts.length > 0,
        order,
        totalProductsAdded,
        productsWithCounts,
        currency,
        getTotalAmount: () => {
            return sum(productsWithCounts.map((product) => product.priceIncludingVatMinorUnits * product.count));
        },
        getProductCountById: (id) => productCountById[id] || 0,
        incrementProductCount: (id, count) => {
            setOrder((state) => {
                state.productCountById[id] = state.productCountById[id] || 0;
                state.productCountById[id] += count;
            });
        },
        decrementProductCount: (id, count) => {
            setOrder((state) => {
                state.productCountById[id] = state.productCountById[id] || 0;
                state.productCountById[id] -= count;
            });
        },
        resetProductCount() {
            setOrder((state) => {
                state.productCountById = {};
            });
        },
        groupId,
        setGroupId(groupId) {
            setOrder((state) => {
                state.groupId = groupId;
            });
        },
        resetOrder() {
            setOrder(getInitialState());
        },
        getOrder() {
            return {
                groupId,
                productsWithCounts: productsWithCounts,
            };
        },
        products,
        productById,
        productDataById,
        updateProductById(locationProductId, data) {
            setOrder((state) => {
                state.productDataById[locationProductId] = { ...state.productDataById[locationProductId], ...data };
            });
        },
    };
}
