import makeStyles from '@material-ui/core/styles/makeStyles';
import TableCell from '@material-ui/core/TableCell';
import keyBy from 'lodash/keyBy';
import React, { useEffect, useMemo, useState } from 'react';
import { MicroserviceInventory } from '../../microservices/inventory';
import { showSuccess } from '../../shared/toasts';
import { useCountById } from '../../use/count-by-id';
import { useLocationProducts } from '../../use/location-products';
import { useApiQuery } from '@orderx/http';
import { IncrementDecrement } from '../IncrementDecrement';
import { Select } from '../Select';
import { UiButtonBottom } from '../ui/UiButtonBottom';
import { UiLoader } from '../ui/UiLoader';
import { UiTableDefault } from '../ui/UiTableDefault';
import { InventoryUnitLabel } from './InventoryUnitLabel';

const useStyles = makeStyles({
    InventoryMoveTab: {
        padding: 16,
    },
    selectInventoryContainer: {
        padding: 16,
    },
    cellLast: {
        display: 'flex',
    },
});

export function InventoryMoveTab({ inventoryId, locationId }) {
    const classes = useStyles();

    const { isLoading, run, response } = useApiQuery(() =>
        MicroserviceInventory.v2AppInventory({
            action: 'FIND_INVENTORY',
            locationId,
            inventoryId,
        }),
    );

    const [targetInventoryId, setTargetInventoryId] = useState('');
    const inventory = response && response.inventory;
    const targetInventories = (response && response.targetInventories) || [];
    const inventoryProducts = (inventory && inventory.inventoryProducts) || [];
    const inventoryProductsById = useMemo(() => keyBy(inventoryProducts, (product) => product.id), [inventoryProducts]);
    const { getInventoryUnitAmount, getProductById } = useLocationProducts(response && response.locationProducts);

    useEffect(() => {
        run();
    }, []);

    const { counts, increment, decrement, reset } = useCountById();

    const productsWithUnitAmounts = useMemo(
        () =>
            Object.keys(counts)
                .map((id) => inventoryProductsById[id])
                .filter(Boolean)
                .map((inventoryProduct) => ({
                    id: inventoryProduct.id,
                    unitAmount: counts[inventoryProduct.id] || 0,
                }))
                .filter((product) => product.unitAmount > 0),
        [counts, inventoryProductsById],
    );

    const { run: moveInventory, isLoading: isMoving } = useApiQuery(async () => {
        await MicroserviceInventory.v2AppInventory({
            action: 'MOVE_INVENTORY',
            sourceInventoryId: inventoryId,
            targetInventoryId,
            products: productsWithUnitAmounts,
        });

        run();
        reset();
        setTargetInventoryId('');
        showSuccess('Inventory moved');
    });

    if (!response) {
        return <UiLoader />;
    }

    return (
        <>
            <div className={classes.selectInventoryContainer}>
                <Select
                    disabled={isLoading}
                    placeholder={'Target inventory'}
                    bottom
                    value={targetInventoryId}
                    onChangeValue={setTargetInventoryId}
                    options={targetInventories.map((inventory) => ({
                        label: inventory.name,
                        value: inventory.id,
                    }))}
                />
            </div>

            <UiTableDefault
                headers={['Name', 'Expected', 'Actual']}
                rows={inventoryProducts}
                mapRow={(inventoryProduct) => (
                    <>
                        <TableCell>{inventoryProduct.name}</TableCell>
                        <TableCell>
                            <InventoryUnitLabel
                                amount={inventoryProduct.expectedUnitAmount}
                                inventoryProduct={getProductById(inventoryProduct.productId)}
                            />
                        </TableCell>
                        <TableCell align={'right'} className={classes.cellLast}>
                            <IncrementDecrement
                                multiplier={getInventoryUnitAmount(inventoryProduct.productId)}
                                disabled={isLoading}
                                count={counts[inventoryProduct.id]}
                                countMax={inventoryProduct.expectedUnitAmount || 0}
                                onIncrement={(amount) => increment(inventoryProduct.id, amount)}
                                onDecrement={(amount) => decrement(inventoryProduct.id, amount)}
                            />
                        </TableCell>
                    </>
                )}
            />

            {productsWithUnitAmounts.length > 0 && targetInventoryId && (
                <UiButtonBottom disabled={isMoving} onClick={moveInventory}>
                    Move inventory
                </UiButtonBottom>
            )}
        </>
    );
}
