import React from 'react';
import { NavLink } from 'react-router-dom';
import styled, { css } from 'styled-components';
import { Svg } from './Svg';
import isString from 'lodash/isString';

const styles = css`
    background-color: transparent;
    align-items: center;
    display: flex;
    flex: 1;
    justify-content: center;
    flex-flow: column;
    transition: color 300ms;
    text-align: center;
    text-transform: uppercase;
    font-size: 0.5rem;
    letter-spacing: 1px;
    font-weight: bold;
    position: relative;

    &.active {
        color: var(--color-primary);

        svg {
            fill: var(--color-primary);
        }
    }

    &:not(:first-child) {
        margin-left: 16px;
    }

    > *:not(:first-child) {
        margin-top: 4px;
    }

    .badge {
        background-color: var(--color-secondary);
        position: absolute;
        top: 0;
        right: 0;
        padding: 4px;
        font-size: 12px;
        border-radius: var(--border-radius);
        color: white;
        animation-iteration-count: infinite;
        margin-top: 0;
    }
`;

const Button = styled.button`
    ${styles};
    border: 0;
    padding: 0;

    &:focus {
        outline: 0;
    }
`;

const Link = styled(NavLink)`
    ${styles};
`;

export function BottomTab({ icon, label, to, onClick = () => {}, badge }) {
    const Component = to ? Link : Button;

    return (
        <Component to={to} onClick={onClick}>
            {icon && (isString(icon) ? <Svg icon={icon} /> : icon)}
            <span>{label}</span>
            {badge > 0 && <div className="badge animated pulse">{badge}</div>}
        </Component>
    );
}
