import Dialog from '@material-ui/core/Dialog';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { BalancesOverlay } from '../components/BalancesOverlay';
import ScanPage from '../components/ScanPage';
import StaffBarPageHeader from '../components/StaffBarPageHeader';
import { MicroserviceWristbands } from '../microservices/wristbands';
import { showInfo } from '../shared/toasts';
import { useStaffActiveBar } from '../stores/staff-active-bar';
import { useApiQuery } from '@orderx/http';

const Wrapper = styled.div`
    position: absolute;
    padding-top: ${(props) => props.theme.sizes.headerHeight}px;
    padding-bottom: ${(props) => props.theme.sizes.tabsHeight}px;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
`;

export default function StaffBarScan() {
    const { id: locationId } = useStaffActiveBar();
    const [balance, setBalance] = useState();
    const [isOpen, setIsOpen] = useState(false);

    const { run: onTagRead, isLoading, response: wristband } = useApiQuery(async (id) => {
        const response = await MicroserviceWristbands.findWristband({ locationId, wristbandId: id });
        return response || showInfo('Nothing found related to scanned tag');
    });

    useEffect(() => {
        if (wristband) {
            setBalance(wristband);
            setIsOpen(true);
        }
    }, [wristband]);

    return (
        <>
            <StaffBarPageHeader />

            <Wrapper>
                <ScanPage isLoading={isLoading} onTagRead={onTagRead} />

                <Dialog open={isOpen} onClose={() => setIsOpen(false)} fullScreen>
                    <BalancesOverlay balance={balance} onClose={() => setIsOpen(false)} />
                </Dialog>
            </Wrapper>
        </>
    );
}
