import React, { useState } from 'react';
import { StaffOrderGroupMoveProductsDialog } from '../../../staff-order/StaffOrderGroupMoveProductsDialog';
import { usePosGroup } from '../../../../hooks/pos/group';
import { UiButton, UiIconMaterial } from '@orderx/ui';

export function PosGroupMoveProducts({ onSuccess }) {
    const { group } = usePosGroup();
    const [isMoveTablesOpen, setIsMoveTablesOpen] = useState(false);

    return (
        <>
            <UiButton
                onClick={() => setIsMoveTablesOpen(true)}
                icon={<UiIconMaterial>arrow_right_alt</UiIconMaterial>}
                variant="filled"
                size={1}>
                Move products
            </UiButton>

            {isMoveTablesOpen && (
                <StaffOrderGroupMoveProductsDialog
                    group={group}
                    barId={group.barId}
                    close={() => setIsMoveTablesOpen(false)}
                    onSuccess={onSuccess}
                />
            )}
        </>
    );
}
