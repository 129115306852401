import React from 'react';
import { useCreateOrderBar } from '../../stores/create-order-bar';
import { UiButton, UiGrid, UiOverlay, UiToolbar } from '@orderx/ui';
import { ToolbarTitle } from '../../@v2/components/toolbar/title/ToolbarTitle';

export function StaffCreateBottomButtonGroups({ isOpen, close, onChooseGroup }) {
    const { groups } = useCreateOrderBar();

    return isOpen ? (
        <UiOverlay height="50vh" position="bottom">
            <UiToolbar>
                <ToolbarTitle title="Choose group" buttonBackOnClick={close} />
            </UiToolbar>

            <UiGrid padding={2} minMax={60}>
                {groups.map((group) => (
                    <UiButton onClick={() => onChooseGroup(group)} variant="outlined" key={group.id}>
                        {group.name}
                    </UiButton>
                ))}
            </UiGrid>
        </UiOverlay>
    ) : null;
}
