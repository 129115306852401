import React from 'react';
import styled from 'styled-components';

const BackgroundImage = styled.div`
    background-image: ${(props) => (props.image ? `url("${props.image}")` : 'none')};
    background-size: cover;
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 0;
`;

const BackgroundOverlayColor = styled.div`
    background-image: linear-gradient(rgba(17, 17, 17, 0.75), rgba(17, 17, 17, 0.95));
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 0;
`;

const Content = styled.div`
    position: relative;
`;

export function BackgroundCover({ image = '', children }) {
    return (
        <>
            {image && <BackgroundImage image={image} />}
            <BackgroundOverlayColor />
            <Content>{children}</Content>
        </>
    );
}
