import React, { useEffect, useState } from 'react';
import ReactPlayer from 'react-player';

export function VideoPlayer({ src, timeout = 0, height = 360 }) {
    const [shouldPlay, setShouldPlay] = useState(false);

    useEffect(() => {
        const timeoutRef = setTimeout(() => setShouldPlay(true), timeout);
        return () => clearTimeout(timeoutRef);
    });

    return (
        <ReactPlayer
            url={src}
            playing={shouldPlay}
            loop
            height={height}
            muted
            width="100%"
            playsinline
            className="animated fadeIn"
        />
    );
}
