import groupBy from 'lodash/groupBy';
import orderBy from 'lodash/orderBy';
import sum from 'lodash/sum';
import times from 'lodash/times';
import without from 'lodash/without';
import { useMemo } from 'react';
import { createStore, useStore } from '../create-store';
import { ProductConverter } from '../util/product-converter';

const store = createStore(getInitialState());

function getInitialState() {
    return {
        products: [],
        tagId: '',
        orderToPlace: undefined,
        comment: '',
    };
}

export function useStaffOrder() {
    const [order, setOrder] = useStore(store);
    const { products, tagId, orderToPlace, comment } = order;

    const productsGroupedById = useMemo(() => groupBy(products, product => product.locationProductId), [products]);

    const productsGrouped = useMemo(
        () =>
            orderBy(
                Object.keys(productsGroupedById).map(id => {
                    const products = productsGroupedById[id];
                    const product = products[0];

                    return {
                        id: id,
                        icon: product.icon,
                        name: product.name,
                        count: products.length,
                        currency: product.currency,
                        priceIncludingVat: product.priceIncludingVat,
                        priceTotal: sum(products.map(product => product.priceIncludingVat)),
                    };
                }),
                product => product.name,
            ),
        [productsGroupedById],
    );

    return {
        order: {
            products,
            tagId,
            comment,
        },
        products,
        productsGrouped,
        totalProductsAdded: products.length,
        getProductCountByLocationProductId: id => (productsGroupedById[id] ? productsGroupedById[id].length : 0),
        currency: products.length > 0 ? products[0].currency : 'NOK',
        orderToPlace,
        setOrderToPlace(order) {
            setOrder(state => {
                state.orderToPlace = order;
            });
        },
        tagId,
        setTagId(tagId) {
            setOrder(state => {
                state.tagId = tagId;
            });
        },
        resetTagId() {
            setOrder(state => {
                state.tagId = '';
            });
        },
        setComment(comment) {
            setOrder(state => {
                state.comment = comment;
            });
        },
        addProduct(product, count = 1) {
            const products = times(count).map(() => ({ ...product, key: Math.random() }));
            const orderProducts = ProductConverter.convertLocationProductsToOrderProducts(products);

            setOrder(order => {
                order.products = [...order.products, ...orderProducts];
            });
        },
        removeProduct(product, count = 1) {
            setOrder(state => {
                for (const i of times(count)) {
                    state.products = without(
                        state.products,
                        state.products.find(p => p.locationProductId === product.id),
                    );
                }
            });
        },
        resetOrder() {
            setOrder(getInitialState());
        },
        applyDiscount(productsDiscounted, discount) {
            function resetDiscount(product) {
                const { priceIncludingVatInitial, priceExcludingVatInitial } = product;

                if (priceIncludingVatInitial) {
                    product.priceIncludingVat = priceIncludingVatInitial;
                    product.priceExcludingVat = priceExcludingVatInitial;
                }

                product.priceIncludingVatInitial = undefined;
                product.priceExcludingVatInitial = undefined;

                product.discount = undefined;
                product.discountId = undefined;
                product.discountPercent = undefined;
            }

            setOrder(state => {
                for (const { id } of productsDiscounted) {
                    const product = state.products.find(p => p.id === id);
                    resetDiscount(product);

                    if (discount) {
                        const { priceIncludingVat, priceExcludingVat } = product;

                        product.priceIncludingVat = priceIncludingVat - priceIncludingVat * (discount.percent / 100);
                        product.priceIncludingVatInitial = priceIncludingVat;

                        product.priceExcludingVat = priceExcludingVat - priceExcludingVat * (discount.percent / 100);
                        product.priceExcludingVatInitial = priceExcludingVat;

                        product.discount = discount;
                        product.discountId = discount.id;
                        product.discountPercent = discount.percent;
                        product.discountAmount = product.priceIncludingVatInitial - product.priceIncludingVat;
                    }
                }
            });
        },
        getTotalAmount() {
            return sum(productsGrouped.map(product => product.priceTotal));
        },
    };
}
