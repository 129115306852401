import React, { useState } from 'react';
import styled from 'styled-components';
import ScanPage, { MODE } from '../components/ScanPage';
import { registerLocationQueueHash } from '../microservices/bookx';
import { showError, showSuccess } from '../shared/toasts';

const Wrapper = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    padding: 64px 0;
`;

export function StaffQueueLocationScan() {
    const [isLoading, setIsLoading] = useState(false);

    async function onTagRead(id) {
        setIsLoading(true);

        try {
            await registerLocationQueueHash(id);
            showSuccess('User registered');
        } catch (error) {
            showError(error);
        }
        setIsLoading(false);
    }

    return (
        <Wrapper>
            <ScanPage defaultMode={MODE.QR} isLoading={isLoading} onTagRead={onTagRead} />
        </Wrapper>
    );
}
