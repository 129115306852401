import { useEffect, useState } from 'react';

export function useInterval(callback, milliseconds, inputs = []) {
    const [timesExecuted, setTimesExecuted] = useState(0);

    useEffect(() => {
        const timeout = setTimeout(() => {
            callback();
            setTimesExecuted((timesExecuted) => timesExecuted + 1);
        }, milliseconds);

        return () => clearTimeout(timeout);
    }, [timesExecuted, ...inputs]);
}
