import { useApiQuery } from '@orderx/http';
import { MicroserviceLocation } from '../../microservices/location';
import { createStore } from '@orderx/store';
import { storageGet, storageSet } from '../../shared/storage';

const { useStore } = createStore({
    venues: [],
    barsSelected: [],
    barIdsCached: storageGet('barIdsCached') || [],
});

export function useDeliveryLocations(): {
    load: any;
    selectBarIds: any;
    venues: any[];
    barsSelected: any[];
} {
    const [state, setState] = useStore();

    const { run } = useApiQuery(async () => {
        const response = await MicroserviceLocation.findDeliveryData();
        const { barIdsCached } = state;

        setState((state) => ({
            ...state,
            ...response,
            barsSelected: barIdsToBars(response.venues, barIdsCached),
        }));

        return response;
    });

    function barIdsToBars(venues, barIds) {
        return barIds
            .map((id) =>
                venues
                    .map((venue) => venue.bars)
                    .flat()
                    .find((bar) => bar.id === id),
            )
            .filter(Boolean);
    }

    return {
        load: run,
        venues: state.venues,
        barsSelected: state.barsSelected,
        selectBarIds(barIds) {
            const bars = barIdsToBars(state.venues, barIds);

            setState((state) => {
                state.barsSelected = bars;
                state.barIdsCached = barIds;
                storageSet('barIdsCached', barIds);
            });
        },
    };
}
