import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { UiToolbar, UiGrid } from '@orderx/ui';
import { CustomerBrowsePage } from '../../../pages/customer/browse/CustomerBrowsePage';
import { ToolbarTitle } from '../../../@v2/components/toolbar/title/ToolbarTitle';
import { LocationBrowseListCard } from '../../../@v2/components/location/browse-list-card/LocationBrowseListCard';
import { withParams } from '../../../shared/router';
import { CustomerBrowseGroupsHashPage } from '../../../pages/CustomerBrowseGroupsHashPage';
import { CustomerBarGroupsOverlay } from '../../customer/bar-groups-overlay/CustomerBarGroupsOverlay';
import { storageSet } from '../../../shared/storage';

interface Props {
    tag: any;
}

export function TagBarGroup({ tag }: Props) {
    const { venue, bars } = tag;
    const [bar, setBar] = useState<any>(undefined);
    const { pathname } = useLocation();

    useEffect(() => {
        storageSet('OrderGroup.backButtonLink', pathname);
    }, []);

    return (
        <>
            <UiToolbar>
                <ToolbarTitle title={venue.name} buttonBackLink={CustomerBrowsePage.route} />
            </UiToolbar>

            <UiGrid spacing={2} outerSpacing={2} minMax={300}>
                {bars.map((bar) => (
                    <LocationBrowseListCard
                        name={bar.name}
                        description={bar.description}
                        image={bar.image || bar.venueImage}
                        isClosed={bar.inactive}
                        to={
                            bar.groups.length === 1 &&
                            withParams(CustomerBrowseGroupsHashPage.route, { hash: bar.groups[0].hash })
                        }
                        onClick={() => {
                            if (bar.groups.length > 1) {
                                setBar(bar);
                            }
                        }}
                        key={bar.id}
                    />
                ))}
            </UiGrid>

            {bar && <CustomerBarGroupsOverlay bar={bar} onClose={() => setBar(undefined)} />}
        </>
    );
}
