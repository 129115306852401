import clsx from 'clsx';
import React, { useState } from 'react';
import { usePosGroup } from '../../../../hooks/pos/group';
import { UiGrid, UiText, UiAlert, UiFlex } from '@orderx/ui';
import { UiButton } from '../../../ui/button/UiButton';
import { CurrencyMinor } from '../../../CurrencyMinor';
import { UiTable } from '../../../ui/table/UiTable';
import { UiInput } from '../../../ui/UiInput';
import './PosGroupSplitBill.scss';
import { UiIconPosTerminal } from '../../../ui/icon/UiIconPosTerminal';
import { UiIconVoucher } from '../../../ui/icon/UiIconVoucher';
import { UiIconBalance } from '../../../ui/icon/UiIconBalance';

export function PosGroupSplitBill() {
    const { payment } = usePosGroup();
    const [amount, setAmount] = useState('');
    const amountTotal = payment.amountTotal;
    const amountNotPaid = payment.amountNotPaid;
    const amountPaid = payment.amountPaid;
    const amountMinor = amount * 100;

    function setPaymentAmount(amount) {
        setAmount(Math.round(amount / 100));
    }

    const isExcessiveAmount = amountMinor > amountNotPaid;
    const amountConfirmed = isExcessiveAmount ? amountNotPaid : amountMinor;

    return (
        <UiGrid className={clsx('PosGroupSplitBill')} outerSpacing={2}>
            <UiTable>
                <tbody>
                    <tr>
                        <td>Amount paid:</td>
                        <td align="right">
                            <UiText color="success">
                                <CurrencyMinor amount={amountPaid} />
                            </UiText>
                        </td>
                    </tr>

                    <tr>
                        <td>Amount remaining:</td>
                        <td align="right">
                            <UiText color="warning">
                                <CurrencyMinor amount={amountNotPaid} />
                            </UiText>
                        </td>
                    </tr>
                </tbody>

                <tfoot>
                    <tr>
                        <td>Total:</td>
                        <td align="right">
                            <CurrencyMinor amount={amountTotal} />
                        </td>
                    </tr>
                </tfoot>
            </UiTable>

            <UiFlex spacing={1} flexChildren>
                <UiButton onClick={() => setPaymentAmount(amountTotal / 2)} variant="filled">
                    1/2
                </UiButton>

                <UiButton onClick={() => setPaymentAmount(amountTotal / 4)} variant="filled">
                    1/4
                </UiButton>

                <UiButton onClick={() => setPaymentAmount(amountNotPaid)} variant="filled">
                    Remaining
                </UiButton>
            </UiFlex>

            <UiInput label="Payment of" value={amount} onChangeValue={setAmount} type="number" endAdornment=",-" />

            {isExcessiveAmount && (
                <UiAlert color="warning">The amount you have entered is higher than the amount left to pay.</UiAlert>
            )}

            {amountConfirmed > 0 && (
                <>
                    <span className="divider">
                        Place payment of{' '}
                        <UiText weight={2} color="secondary">
                            <CurrencyMinor amount={amountConfirmed} />
                        </UiText>
                    </span>

                    <UiFlex flexChildren spacing={1}>
                        <UiButton direction="column" icon={<UiIconPosTerminal />} variant="filled">
                            Terminal
                        </UiButton>

                        <UiButton direction="column" icon={<UiIconBalance />} variant="filled">
                            Balance
                        </UiButton>

                        <UiButton direction="column" icon={<UiIconVoucher />} variant="filled">
                            Voucher
                        </UiButton>
                    </UiFlex>
                </>
            )}
        </UiGrid>
    );
}
