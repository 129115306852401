import { createStore, useStore } from '../create-store';
import { storageGet, storageSet } from '../shared/storage';
import { useCreateOrderBar } from './create-order-bar';

const cacheKey = 'createOrderProductStats';
const store = createStore(storageGet(cacheKey) || {});

export function useCreateOrderProductStats() {
    const [state, setState] = useStore(store);
    const { barId } = useCreateOrderBar();
    const stats = state[barId] || {};

    return {
        productStats: stats.productCount || {},
        categoryStats: stats.categoryCount || {},
        cacheProductsStats(products) {
            setState(state => {
                state[barId] = state[barId] || {
                    categoryCount: {},
                    productCount: {},
                };

                const stats = state[barId];

                for (const product of products) {
                    const { locationProductId, category, subCategory } = product;

                    if (locationProductId) {
                        stats.productCount[locationProductId] = (stats.productCount[locationProductId] || 0) + 1;
                    }

                    if (category) {
                        stats.categoryCount[category] = (stats.categoryCount[category] || 0) + 1;
                    }

                    if (subCategory) {
                        stats.categoryCount[subCategory] = (stats.categoryCount[subCategory] || 0) + 1;
                    }
                }

                storageSet(cacheKey, state);
            });
        },
    };
}
