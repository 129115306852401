import React, { useEffect } from 'react';
import { useActionSheet } from '../../components/ActionSheet';
import { BackgroundCoverImage } from '../../components/BackgroundCoverImage';
import { OrderPaymentProgressBar } from '../../components/OrderPaymentProgressBar';
import { translate } from '../../shared/translate';
import { useCustomerTabs } from '../../stores/customer-tabs';
import { useOrderGroup } from '../../stores/order-group';
import { OrderGroupMenu } from './OrderGroupMenu';
import { OrderGroupPay } from './OrderGroupPay';
import { UiGrid, UiText, UiButton, UiIconMaterial, UiFlex, UiAlert } from '@orderx/ui';
import { useCustomerActiveOrder } from '../../use/customer-active-order';
import { CustomerBrowsePage } from '../customer/browse/CustomerBrowsePage';
import { storageGet, storageRemove } from '../../shared/storage';
import { useUiToggle } from '../../@v2/components/ui-toggle/UiToggle';
import { CustomerBrowseIdPage } from '../customer/browse/id/CustomerBrowseIdPage';
import { useRouter } from '../../@v2/hooks/router';
import { useCurrency } from '../../@v2/hooks/currency';
import { OrderGroupDiscount } from '../../@v2/components/order/group/discount/OrderGroupDiscount';

export function OrderGroup({ hash }) {
    const {
        replaceParams,
        location: { pathname },
    } = useRouter();

    let {
        group,
        bar,
        orders,
        amountTotal,
        amountPaid,
        reload,
        products,
        isAcceptingOrders,
        hasReachedLimit,
        active,
        config,
        venue,
        reset: resetOrderPage,
    } = useOrderGroup(hash);

    const { setCurrency } = useCurrency();
    const { setIsHidden: setIsTabsHidden } = useCustomerTabs();

    // isAcceptingOrders = false;
    // hasReachedLimit = true;

    const { resetOrder } = useCustomerActiveOrder({ hash });

    const { setBrowseButtonLink, resetBrowseButtonLink } = useCustomerTabs();

    const { component: componentToggle, value, setValue } = useUiToggle([
        {
            label: 'Order',
            value: 'ORDER',
        },
        {
            label: 'Pay',
            value: 'PAY',
        },
    ]);

    const { name, isInactive } = group;
    const { name: barName, image } = bar;
    const isViewTypeBar = group?.viewType === 'FOTOGRAFISKA';
    const allowedToPlaceOrders = isAcceptingOrders && !isInactive && active;
    const actionSheet = useActionSheet();
    const overlayColor = `linear-gradient(rgba(25, 25, 25, 0.75), var(--color-background-body))`;
    const hasTabs = orders.length > 0 && !isViewTypeBar;
    const backButtonLink = storageGet('OrderGroup.backButtonLink');
    const backButtonLinkDefault = venue?.id
        ? replaceParams(`${CustomerBrowseIdPage.route}?direction=back&barId=${bar?.id}`, { id: venue.id })
        : CustomerBrowsePage.route;

    useEffect(() => {
        actionSheet.open();

        if (!hasTabs) {
            setValue('ORDER');
        }
    }, [hasTabs]);

    useEffect(() => {
        setBrowseButtonLink(pathname);

        return () => {
            resetOrderPage();
            resetOrder();
        };
    }, []);

    useEffect(() => {
        if (isViewTypeBar) {
            setIsTabsHidden(true);
            return () => setIsTabsHidden(false);
        }
    }, [isViewTypeBar]);

    useEffect(() => {
        if (bar?.currency) {
            setCurrency(bar.currency);
        }
    }, [bar]);

    return (
        <>
            <OrderGroupDiscount venue={venue} />

            {image && <BackgroundCoverImage image={image} height={120} overlayColor={overlayColor} zIndex={-1} />}

            <UiGrid outerSpacing={2} spacing={8}>
                <UiFlex flexChildren>
                    <div>
                        {!isViewTypeBar && (
                            <UiButton
                                to={backButtonLink || backButtonLinkDefault}
                                onClick={() => {
                                    storageRemove('OrderGroup.backButtonLink');
                                    resetBrowseButtonLink();
                                }}
                                icon={<UiIconMaterial>arrow_back</UiIconMaterial>}
                            />
                        )}
                    </div>

                    <UiFlex direction="column">
                        <UiText color="text-secondary" weight={2} noWrap align="center">
                            {barName}
                        </UiText>

                        <UiText size={2} weight={2} noWrap align="center">
                            {name}
                        </UiText>
                    </UiFlex>

                    <div />
                </UiFlex>

                {!allowedToPlaceOrders && (
                    <UiAlert color="warning">{translate('This venue is currently not accepting any orders')}</UiAlert>
                )}

                <UiGrid>
                    {amountTotal > 0 && <OrderPaymentProgressBar amountTotal={amountTotal} amountPaid={amountPaid} />}

                    {hasTabs && componentToggle}

                    {hasReachedLimit && (
                        <UiAlert color="error">
                            {config.barOrderQueueLimitReachedText ? (
                                config.barOrderQueueLimitReachedText
                            ) : (
                                <span>
                                    {name} {translate('is currently not accepting any orders! Please try again later')}
                                </span>
                            )}
                        </UiAlert>
                    )}

                    {value === 'ORDER' && allowedToPlaceOrders && (
                        <OrderGroupMenu
                            group={group}
                            bar={bar}
                            hash={hash}
                            products={products}
                            isAcceptingOrders={allowedToPlaceOrders}
                            config={config}
                            onUpdate={reload}
                            disabled={hasReachedLimit}
                        />
                    )}

                    {value === 'PAY' && (
                        <OrderGroupPay
                            group={group}
                            hash={hash}
                            products={products}
                            config={config}
                            onUpdate={reload}
                        />
                    )}
                </UiGrid>
            </UiGrid>
        </>
    );
}
