import React from 'react';
import { SwitchRedirect } from '../components/SwitchRedirect';
import { routes } from '../shared/router';
import StaffBarOrdersList from './StaffBarOrdersTerminal';

export function StaffBarOrders() {
    return (
        <SwitchRedirect
            routes={[
                {
                    component: StaffBarOrdersList,
                    path: routes.staffBarOrderRegular,
                },
            ]}
        />
    );
}
