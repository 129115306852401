import React from 'react';
import { useStaffStationConfiguration, OrderByType } from '../../../../stores/staff-station-configuration';
import { UiToggle } from '../../../../@v2/components/ui-toggle/UiToggle';

export function StaffOrderStationSortOrder() {
    const { orderByType, setOrderByType } = useStaffStationConfiguration();

    return (
        <UiToggle
            value={orderByType}
            onChangeValue={setOrderByType}
            items={[
                {
                    label: 'Default order',
                    value: OrderByType.Default,
                },
                {
                    label: 'Price, descending',
                    value: OrderByType.ProductPriceDescending,
                },
            ]}
        />
    );
}
