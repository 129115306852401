import orderBy from 'lodash/orderBy';
import { createStore, useStore } from '../create-store';
import { ORDER_STATUS } from '../shared/orders';
import { storageGet, storageSet } from '../shared/storage';

const cacheKey = 'staffOrderHistory';

const store = createStore({
    orders: storageGet(cacheKey) || [],
    limit: 25,
});

export function useCreateOrderHistory() {
    const [{ orders, limit = 25 }, setState] = useStore(store);

    return {
        limit,
        getLatestFinalizedOrder: () => orders.filter(order => order.Status === ORDER_STATUS.FINALIZED)[0],
        orders,
        putOrder(order) {
            setState(state => {
                const exists = state.orders.find(({ Id }) => Id === order.Id);

                if (exists) {
                    Object.assign(exists, order);
                } else {
                    state.orders.push(order);
                }

                state.orders = orderBy(state.orders, order => order.Datetime, 'desc').slice(0, limit);

                storageSet(cacheKey, state.orders);
            });
        },
    };
}
