import { format } from 'date-fns';
import truncate from 'lodash/truncate';
import React, { Fragment } from 'react';
import styled from 'styled-components';
import { useStaffActiveBar } from '../stores/staff-active-bar';
import { useStaffTerminalOrders } from '../stores/staff-terminal-orders';
import { themeActive } from '../themes/dark';
import { Currency } from './Currency';
import { OrderListItemMoreButton } from './OrderListItemMoreButton';
import { OrderStatusLight } from './OrderStatusLight';

const Wrapper = styled.tr`
    .time,
    .status {
        width: 24px;
    }

    .time {
        color: ${themeActive.colors.faded};
    }

    .price {
        width: 40px;
    }

    .products {
        font-size: 0.75rem;
        color: #aaa;
    }

    .claimed-by {
        color: ${themeActive.colors.text};
        font-weight: bold;
        text-decoration: underline;
    }
`;

export default function OrderListItem({ orderId, onShowDetails }) {
    const bar = useStaffActiveBar();

    const { getOrderById } = useStaffTerminalOrders();
    const order = getOrderById(orderId);

    if (!order) {
        return <Fragment />;
    }

    const claimedBy = order && order.ClaimedBy;
    const orderProducts = (order.Data && order.Data.Products) || [];
    const products = truncate(orderProducts.map((product) => `${product.Count} x ${product.Name}`).join(', '), {
        length: 60,
        separator: ' ',
        omission: '...',
    });
    const terminalName = order.PosProfile && order.PosProfile.name;

    return (
        <Wrapper>
            <td className="status">
                <OrderStatusLight status={order.Status} />
            </td>

            <td className="time">
                <small>
                    {order.DatetimeStr && <div>{format(order.DatetimeStr, 'HH:mm')}</div>}
                    <div>{terminalName}</div>
                </small>
            </td>

            <td className="price">
                {order.PaymentAmount ? <Currency amount={order.PaymentAmount} currency={bar.currency} /> : '-'}
            </td>

            <td className="products">
                {claimedBy && <div className="claimed-by">Claimed by {claimedBy.Name}</div>}
                {products ? products : '-'}
            </td>

            {/*<td>{(claimedBy && <Svg icon="lock" />) || (isFullOrder && <Svg icon="arrow-right" />) || ''}</td>*/}
            <td>
                <OrderListItemMoreButton order={order} onClickDetails={onShowDetails} />
            </td>
        </Wrapper>
    );
}
