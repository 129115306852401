import { distanceInWordsToNow, format } from 'date-fns';
import isArray from 'lodash/isArray';
import React, { useState } from 'react';
import { postStaffActiveBarAction } from '../microservices/device-actions';
import { scan } from '../microservices/orderx';
import { ORDER_STATUS } from '../shared/orders';
import { showError, showSuccess } from '../shared/toasts';
import { useStaffActiveBar } from '../stores/staff-active-bar';
import { useStaffTerminalOrders } from '../stores/staff-terminal-orders';
import { Button } from './Button';
import { ButtonGroup } from './ButtonGroup';
import { Currency } from './Currency';
import { List } from './List';
import { ListItem } from './ListItem';
import { NfcButton } from './NfcButton';
import { OrderStatusLabel } from './OrderStatusLabel';

export default function OrderDetailsOverlay({ orderId, onClose, actionSheet }) {
    const bar = useStaffActiveBar();

    const { getOrderById } = useStaffTerminalOrders();
    const [isRetrying, setIsRetrying] = useState(false);

    const order = getOrderById(orderId);
    const terminalName = order.PosProfile && order.PosProfile.name;
    const canRetry =
        (order.Status === ORDER_STATUS.PAYMENT_FAILED || order.Status === ORDER_STATUS.PAYMENT_WAITING) && !isRetrying;

    async function retryOrder(tag) {
        setIsRetrying(true);

        postStaffActiveBarAction('staff_retry_order', { tag, order });

        try {
            const response = await scan({ code: tag, codeType: 'nfc', orderId: order.Id });
            postStaffActiveBarAction('staff_retry_order_success', { tag, order, response });

            if (isArray(response) && response[0] && response[0].type === 'balance') {
                const amount = response[0].count;
                showSuccess(`Payment successful. Remaining amount: ${amount}`);

                actionSheet.close();
            } else {
                showSuccess('Order sent to terminal');
            }
        } catch (error) {
            postStaffActiveBarAction('staff_retry_order_error', error);
            showError(error, 'Failed to send order to terminal');
        }

        setIsRetrying(false);
    }

    return (
        <div>
            <List padding={0}>
                <ListItem padding={8}>
                    <b>Products</b>
                </ListItem>

                {order.Data.Products.map((product) => (
                    <ListItem key={product.Id} padding={8}>
                        <span>{product.Name}</span>
                        <span>
                            {product.Count} x {product.Price} ={' '}
                            <Currency amount={product.Count * product.Price} currency={product.currency} />
                        </span>
                    </ListItem>
                ))}

                <ListItem padding={8}>
                    <span>Total</span>
                    <b>
                        <Currency amount={order.PaymentAmount} currency={bar.currency} />
                    </b>
                </ListItem>

                <ListItem padding={8}>
                    <b>Details</b>
                </ListItem>

                <ListItem padding={8}>
                    <span>Created</span>
                    <span>
                        {distanceInWordsToNow(order.DatetimeStr)}; {format(order.DatetimeStr, 'D.M HH:mm')}
                    </span>
                </ListItem>

                {terminalName && (
                    <ListItem padding={8}>
                        <span>Terminal</span>
                        <span>{terminalName}</span>
                    </ListItem>
                )}

                <ListItem padding={8}>
                    <span>Status</span>
                    <OrderStatusLabel status={order.Status} />
                </ListItem>
            </List>

            <ButtonGroup vertical>
                {canRetry && <NfcButton onTagRead={({ id }) => retryOrder(id)}>Retry</NfcButton>}

                <Button isLoading={isRetrying} onClick={actionSheet.close} block>
                    Close
                </Button>
            </ButtonGroup>
        </div>
    );
}
