import { storage } from '../shared/storage';
import { createStore, useStore } from '../create-store';

const StorageKey = 'staffBarNewOrderIndicator';
const store = createStore(storage.get(StorageKey) === undefined ? true : storage.get(StorageKey));

export function useStaffBarNewOrderIndicator() {
    const [isVisible, setIsVisible] = useStore(store);

    return {
        isVisible: Boolean(isVisible),
        setIsVisible(isVisible) {
            setIsVisible(isVisible);
            storage.set(StorageKey, isVisible);
        },
    };
}
