import classNames from 'classnames';
import React, { Fragment, useEffect, useRef, useState } from 'react';
import ReactDOM from 'react-dom';
import styled, { css } from 'styled-components';
import { themeActive } from '../themes/dark';
import { Svg } from './Svg';

const transitionSpeed = 300;

const Wrapper = styled.div`
    height: 100%;
    width: 100%;
    position: fixed;
    top: 0;
    left: 0;
    z-index: var(--z-index-Actionsheet);

    .overlay-background {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.65);
        animation: animateBackgroundIn ${transitionSpeed}ms;

        &.closing {
            animation: animateBackgroundOut ${transitionSpeed}ms forwards;
        }
    }

    .overlay-content-wrapper,
    .overlay-content {
        height: ${(props) => (props.cover ? '100%' : 'auto')};
    }

    .overlay-content-wrapper {
        bottom: 0;
        width: 100%;
        padding: ${(props) => {
            if (props.noMargin) {
                return 0;
            } else if (props.margin) {
                return props.margin;
            } else {
                return 8;
            }
        }}px;
        position: fixed;
        margin: 0 auto;
        left: 0;
        right: 0;
    }

    .overlay-content {
        background-color: ${({ reversedColors, theme }) =>
            reversedColors ? theme.colors.bottomOverlayReversed : theme.colors.bottomOverlay};
        color: ${({ reversedColors, theme }) => (reversedColors ? theme.colors.textReversed : theme.colors.text)};
        box-shadow: var(--box-shadow);
        border-radius: ${(props) => (props.cover ? 0 : 4)}px;
        animation: animateBottomIn ${transitionSpeed}ms;
        overflow: auto;
        padding: ${(props) => props.padding}px;
        position: relative;

        &.closing {
            animation: animateBottomOut ${transitionSpeed}ms forwards;
        }
    }

    ${(props) => props.cover && css``};

    @keyframes animateBackgroundIn {
        from {
            opacity: 0;
        }
        to {
            opacity: 1;
        }
    }

    @keyframes animateBackgroundOut {
        from {
            opacity: 1;
        }
        to {
            opacity: 0;
        }
    }

    @keyframes animateBottomIn {
        from {
            transform: translateY(100%);
        }
        to {
            transform: translateY(0);
        }
    }

    @keyframes animateBottomOut {
        from {
            transform: translateY(0);
            opacity: 1;
        }
        to {
            transform: translateY(100%);
            opacity: 0;
        }
    }
`;

const CloseButtonWrapper = styled.div`
    position: absolute;
    //top: -18px;
    //right: 0;
    bottom: 16px;
    right: 16px;
    background: ${themeActive.body.backgroundColor};
    border-radius: 100%;
    z-index: 10;
    padding: 8px;
`;

export function useModal() {
    const [element, setElement] = useState();

    useEffect(() => {
        const modalRoot = document.getElementById('modal-root');
        const el = document.createElement('div');
        setElement(el);
        modalRoot.appendChild(el);
        return () => modalRoot.removeChild(el);
    }, []);

    return {
        render: (children) => (element ? ReactDOM.createPortal(children, element) : <Fragment />),
    };
}

export function useActionSheet() {
    const [isOpen, setIsOpen] = useState(false);
    const [isClosing, setIsClosing] = useState(false);

    return {
        isOpen,
        isClosing,
        setIsClosing,
        setClosed: () => {
            setIsClosing(false);
            setIsOpen(false);
        },
        close: () => setIsClosing(true),
        open: () => {
            setIsClosing(false);
            setIsOpen(true);
        },
        toggle: () => {
            if (!isOpen) {
                setIsClosing(false);
            }

            setIsOpen(!isOpen);
        },
    };
}

export function ActionSheet({
    isOpen = false,
    isClosing = false,
    hasCloseButton = false,
    setIsClosing,
    setClosed,
    toggle,
    close,
    children,
    margin = 0,
    padding = 32,
    cover = false,
    noMargin = false,
    reversedColors = false,
    onClosed = () => {},
}) {
    const overlayBackground = useRef(null);

    const { render } = useModal();

    useEffect(() => {
        if (isClosing) {
            close();
            setTimeout(onAnimationEnd, transitionSpeed);
        }
    }, [isClosing]);

    if (!isOpen) {
        return <Fragment />;
    }

    const classNameBackground = classNames('overlay-background', { closing: isClosing });
    const classNameContent = classNames('overlay-content', { closing: isClosing });
    const classNameCloseButton = classNames('animated fadeInRight', { fadeOutRight: isClosing });

    function onAnimationEnd() {
        setClosed();
        onClosed();
    }

    function preventClick(event) {
        event.stopPropagation();
        event.preventDefault();
    }

    return render(
        <Wrapper
            onClick={() => setIsClosing(true)}
            padding={cover ? 0 : padding}
            margin={margin}
            cover={cover}
            noMargin={cover || noMargin}
            reversedColors={reversedColors}>
            <div className={classNameBackground} ref={overlayBackground} />

            <div className="overlay-content-wrapper">
                {hasCloseButton && (
                    <CloseButtonWrapper className={classNameCloseButton}>
                        <Svg icon="close" size={36} />
                    </CloseButtonWrapper>
                )}

                <div className={classNameContent} onClick={preventClick}>
                    {children}
                </div>
            </div>
        </Wrapper>,
    );
}
