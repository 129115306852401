import React from 'react';
import styled from 'styled-components';
import { Button } from '../components/Button';
import { Checkbox } from '../components/Checkbox';
import { SvgIcons } from '../components/Svg';
import { TerminalOrdering } from '../components/TerminalOrdering';
import { V2Grid } from '../components/v2/V2Grid';
import { V2List } from '../components/v2/V2List';
import { V2ListItem } from '../components/v2/V2ListItem';
import { V2PageHeader } from '../components/v2/V2PageHeader';
import { routes, withParams } from '../shared/router';
import { showSuccess } from '../shared/toasts';
import { translate } from '../shared/translate';
import { resetLocationState } from '../stores/location-terminal-orders';
import { useLocationTerminalSettings } from '../stores/location-terminal-settings';
import { useStaffActiveBar } from '../stores/staff-active-bar';
import { useStaffTerminalSlider } from '../stores/staff-terminal-slider';
import { useHistory } from 'react-router-dom';

const Content = styled.div`
    padding: 32px 0;
`;

export default function StaffBarSettingsTerminalSlider({ onClickBack }) {
    const history = useHistory();
    const { id: barId } = useStaffActiveBar();

    const {
        isControlledFromSettings,
        isExpanded,
        setControlFromSettings,
        setIsExpanded,
        reset,
    } = useLocationTerminalSettings(barId);

    const { hasOrders, clearTerminalOrders } = useStaffTerminalSlider();

    return (
        <div>
            <V2PageHeader
                title="Terminal slider"
                onClickBack={() =>
                    onClickBack ? onClickBack() : history.push(withParams(routes.staffBarSettings, { barId }))
                }
            />

            <Content>
                <V2Grid>
                    <V2List title={translate('Terminals')}>
                        <V2ListItem
                            title={translate('Expanded view')}
                            subtitle={translate('Increase size of terminal slider')}
                            contentRight={<Checkbox isChecked={isExpanded} onChange={setIsExpanded} />}
                        />

                        {hasOrders() && (
                            <V2ListItem
                                title={translate('Clear orders')}
                                subtitle={translate('Remove any pending orders from slider')}
                                contentRight={
                                    <Button
                                        icon={SvgIcons.Trash}
                                        small
                                        negative
                                        onClick={() => {
                                            clearTerminalOrders();
                                            showSuccess(translate('Terminal orders have been cleared'));
                                        }}
                                    />
                                }
                            />
                        )}

                        <V2ListItem
                            onClick={() => {
                                reset();
                                resetLocationState(barId);
                            }}
                            title={translate('Reset to default')}
                        />

                        <V2ListItem
                            title={translate('Use checked terminals')}
                            subtitle={translate('Only checked terminals will be displayed on terminal slider')}
                            contentRight={
                                <Checkbox isChecked={isControlledFromSettings} onChange={setControlFromSettings} />
                            }
                        />

                        {isControlledFromSettings && <TerminalOrdering />}
                    </V2List>
                </V2Grid>
            </Content>
        </div>
    );
}
