import makeStyles from '@material-ui/core/styles/makeStyles';
import flatten from 'lodash/flatten';
import React, { useEffect, useState } from 'react';
import { InventoryMoveTab } from '../../components/inventory/InventoryMoveTab';
import { InventoryStockHoldingReportTab } from '../../components/inventory/InventoryStockHoldingReportTab';
import { Tabs } from '../../components/Tabs';
import { V2Grid } from '../../components/v2/V2Grid';
import { V2List } from '../../components/v2/V2List';
import { V2ListItem } from '../../components/v2/V2ListItem';
import { V2Loader } from '../../components/v2/V2Loader';
import { V2PageHeader } from '../../components/v2/V2PageHeader';
import { MicroserviceInventory } from '../../microservices/inventory';
import { AppUserRoleRole } from '../../models/app-user-role';
import { routes } from '../../shared/router';
import { useStaffVenues } from '../../stores/staff-venues';
import { themeActive } from '../../themes/dark';
import { useApiQuery } from '@orderx/http';
import { useHistory } from 'react-router-dom';

const useStyles = makeStyles({
    InventoryPage: {
        paddingTop: 16,
    },
    header: {
        padding: 16,
    },
    button: {
        borderRadius: 0,
    },
    tabs: {
        backgroundColor: themeActive.tabs.backgroundColor,
    },
});

export function InventoryPage({ match }) {
    const history = useHistory();
    const { inventoryId, locationId } = match.params;
    const classes = useStyles();
    const [tabValue, setTabValue] = useState(0);
    const [reloadingReport, setReloadingReport] = useState(false);

    const locationIds = flatten(
        useStaffVenues()
            .filter((venue) => venue.roles.includes(AppUserRoleRole.Staff))
            .map((venue) => [venue.id, ...venue.bars.map((bar) => bar.id)]),
    );

    const { run: loadInventories, response: locations } = useApiQuery(() =>
        MicroserviceInventory.v2AppInventory({
            action: 'FIND_INVENTORIES',
            locationIds,
        }),
    );

    const location = inventoryId && (locations || []).find((location) => location.id === locationId);
    const inventory = ((location && location.inventories) || []).find((inventory) => inventory.id === inventoryId);

    useEffect(() => {
        if (tabValue === 1 && reloadingReport) {
            const timeout = setTimeout(() => setReloadingReport(false), 100);
            return () => clearTimeout(timeout);
        }
    }, [tabValue, reloadingReport]);

    useEffect(() => {
        loadInventories();
    }, []);

    if (!locations) {
        return <V2Loader block />;
    }

    if (inventory) {
        return (
            <>
                <V2PageHeader onClickBack={() => history.push(routes.staffInventory)} title={inventory.name} />

                <Tabs
                    variant={'fullWidth'}
                    value={tabValue}
                    onChange={setTabValue}
                    tabs={[
                        { label: 'Move inventory', value: 0 },
                        { label: 'Stock holding report', value: 1 },
                    ]}
                    className={classes.tabs}
                />

                {tabValue === 0 && <InventoryMoveTab inventoryId={inventory.id} locationId={locationId} />}

                {tabValue === 1 && !reloadingReport && (
                    <InventoryStockHoldingReportTab
                        inventoryId={inventory.id}
                        locationId={locationId}
                        onConfirmed={() => setReloadingReport(true)}
                    />
                )}
            </>
        );
    }

    return (
        <>
            <V2PageHeader onClickBack={() => history.push(routes.customerProfile)} title={'Choose inventory'} />

            <div className={classes.InventoryPage}>
                <V2Grid>
                    {locations.map((location) => (
                        <V2List key={location.id} title={location.name}>
                            {location.inventories.map((inventory) => (
                                <V2ListItem
                                    to={routes.staffInventoryId
                                        .replace(':inventoryId', inventory.id)
                                        .replace(':locationId', location.id)}
                                    key={inventory.id}
                                    title={inventory.name}
                                    compact
                                    iconRight={'arrow-right'}
                                />
                            ))}
                        </V2List>
                    ))}
                </V2Grid>
            </div>
        </>
    );
}
