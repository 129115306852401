import { Entity, ManyToOne, PrimaryColumn } from './typeorm';
import { Company } from './company';
import { User } from './user';

@Entity()
export class CompanyRole {
    @ManyToOne(() => Company, (model) => model.userRoles)
    company: Company;
    @PrimaryColumn()
    companyId: number;

    @ManyToOne(() => User, (model) => model.companyRoles)
    user: User;
    @PrimaryColumn()
    userId: number;
}
