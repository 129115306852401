import { storageGet } from './shared/storage';
import { IS_DEV } from './environment';

export const ALWAYS_USE_PROD_API = !!storageGet('useProd');

const host = window.location.host.split(':')[0];

export function getUrlToOrderX(url) {
    return `https://ue6nt4fr5b.execute-api.eu-west-1.amazonaws.com/prod/orderx/${url}`;
}

export function getApiUrl(serviceName, portDev = window.location.port) {
    const isLocalhost = IS_DEV && !ALWAYS_USE_PROD_API;

    const prefix = isLocalhost
        ? `http://${window.location.hostname}:${portDev}/${serviceName}/`
        : `https://api.orderx.no/${serviceName}/`;

    return (url) => `${prefix}${url}`;
}

export function getBookXApiUrl(url) {
    const isLocalhost = IS_DEV && !ALWAYS_USE_PROD_API;

    const prefix = isLocalhost ? `http://${host}:4444/api/` : `https://bookx2.orderx.no/api/`;

    return `${prefix}${url}`;
}
