import { createStore } from '@orderx/store';
import { usePos } from './pos';
import { useMemo } from 'react';
import { compareStrings } from '../../util/compare-strings';

const { useStore } = createStore({
    keyword: '',
    activeCategory: '',
    activeSubCategory: '',
    isActiveOrderOpen: false,
});

export function usePosMenu() {
    const [{ keyword, activeCategory, activeSubCategory, isActiveOrderOpen }, setState] = useStore();
    const { menuProducts } = usePos();

    const productsFiltered = useMemo(() => {
        let products = menuProducts;

        if (keyword) {
            products = products.filter(({ product }) => compareStrings(product.name, keyword));
        }

        if (activeCategory) {
            products = products.filter(({ product }) => product.category === activeCategory);
        }

        if (activeSubCategory) {
            products = products.filter(({ product }) => product.subCategory === activeSubCategory);
        }

        return products;
    }, [menuProducts, keyword, activeCategory, activeSubCategory]);

    function getSetter(field) {
        return (value) => {
            setState((state) => {
                state[field] = value;
            });
        };
    }

    return {
        isActiveOrderOpen,
        setIsActiveOrderOpen: getSetter('isActiveOrderOpen'),
        activeCategory,
        setActiveCategory: getSetter('activeCategory'),
        activeSubCategory,
        setActiveSubCategory: getSetter('activeSubCategory'),
        keyword,
        setKeyword: getSetter('keyword'),
        menuProducts,
        menuProductsFiltered: productsFiltered,
    };
}
