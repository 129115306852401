import clsx from 'clsx';
import React from 'react';
import './ListItem.scss';
import { UiFlex, UiText } from '@orderx/ui';
import { NavLink } from 'react-router-dom';

interface Props {
    iconStart?: any;
    title?: any;
    subtitle?: any;
    iconEnd?: any;
    to?: string;
    onClick?(): void;
}

export function ListItem({ iconStart, title, subtitle, iconEnd, to, onClick }: Props) {
    const content = (
        <UiFlex
            className={clsx('ListItem')}
            onClick={onClick}
            justifyContent="space-between"
            spacing={2}
            paddingLeft={2}
            paddingVertical={1}>
            <UiFlex spacing={2}>
                {iconStart}

                <div>
                    <UiText>{title}</UiText>

                    {subtitle && (
                        <UiText color="text-secondary" size={-1} weight={2}>
                            {subtitle}
                        </UiText>
                    )}
                </div>
            </UiFlex>

            {iconEnd}
        </UiFlex>
    );

    if (to) {
        return <NavLink to={to}>{content}</NavLink>;
    }

    return content;
}
