import { format } from 'date-fns';
import orderBy from 'lodash/orderBy';
import sum from 'lodash/sum';
import React, { useEffect, useMemo } from 'react';
import { CurrencyMinor } from '../../components/CurrencyMinor';
import { ProductListItem } from '../../components/product/list-item/ProductListItem';
import { V2PaymentButton } from '../../components/v2/V2PaymentButton';
import { PaymentStatus, toPaymentStatusLabel } from '../../models/orders/payment';
import { translate } from '../../shared/translate';
import { useOrderGroup } from '../../stores/order-group';
import { useCountById } from '../../use/count-by-id';
import { PaymentReceiptButton } from '../../@v2/components/payment/receipt-button/PaymentReceiptButton';
import { ListItem } from '../../@v2/components/list/item/ListItem';
import { UiIconMaterial, UiText, UiBox, UiGrid, UiButton } from '@orderx/ui';

export function OrderGroupPay({ hash, config }) {
    const group = useOrderGroup(hash);
    const { bar, productsNotPaid, payments } = group;
    const { counts, increment, decrement, reset, setCount } = useCountById();
    const { currency } = bar;

    useEffect(() => {
        const productCount = productsNotPaid.reduce((countsById, product) => {
            countsById[product.locationProductId] = (countsById[product.locationProductId] || 0) + 1;
            return countsById;
        }, {});

        let resetCount = false;

        for (const locationProductId of Object.keys(counts)) {
            if (!productCount[locationProductId]) {
                resetCount = true;
                break;
            }

            if (counts[locationProductId] > productCount[locationProductId]) {
                setCount(productCount[locationProductId] || 0);
            }
        }

        if (resetCount) {
            reset();
        }
    }, [productsNotPaid, counts]);

    const productsNotPaidWithCounts = useMemo(() => groupOrderProducts(productsNotPaid), [productsNotPaid]);

    const amountPay = sum(
        Object.keys(counts).map((id) => {
            const product = productsNotPaid.find((product) => product.locationProductId === id);
            return (product ? product.priceIncludingVat : 0) * counts[id];
        }),
    );

    const { productIds } = productsNotPaid.reduce(
        (data, { id, locationProductId }) => {
            if (data.counts[locationProductId] > 0) {
                data.productIds.push(id);
                data.counts[locationProductId] -= 1;
            }

            return data;
        },
        {
            productIds: [],
            counts: { ...counts },
        },
    );

    function selectAllProducts() {
        reset();

        for (const product of productsNotPaidWithCounts) {
            increment(product.locationProductId, product.count);
        }
    }

    return (
        <UiBox paddingTop={2}>
            <UiGrid spacing={4}>
                {productsNotPaid.length > 0 && (
                    <UiGrid>
                        <UiButton onClick={selectAllProducts} variant="filled" size={1} color="success">
                            Select all products
                        </UiButton>

                        <UiText weight={2}>{translate('Choose products to pay for')}</UiText>

                        <UiGrid spacing={1}>
                            {productsNotPaidWithCounts.map((product) => (
                                <ProductListItem
                                    key={product.id}
                                    noMarginBottom
                                    name={
                                        <UiText weight={2}>
                                            {product.count}
                                            {' x '}
                                            {product.name}
                                        </UiText>
                                    }
                                    image={product.icon}
                                    price={product.priceIncludingVat ? product.priceIncludingVat / 100 : 0}
                                    currency={product.currency}
                                    hasOptions={true}
                                    count={counts[product.locationProductId]}
                                    onDecrement={(amount) => decrement(product.locationProductId, amount)}
                                    onIncrement={(amount) => increment(product.locationProductId, amount)}
                                    countMax={product.count}
                                />
                            ))}
                        </UiGrid>
                    </UiGrid>
                )}

                {payments.length > 0 && (
                    <UiGrid>
                        <UiText weight={2}>{translate('Payment history')}</UiText>

                        {payments.map((payment) => (
                            <ListItem
                                iconStart={
                                    <UiText color={payment.status === PaymentStatus.Success ? 'success' : 'error'}>
                                        <UiIconMaterial>
                                            {payment.status === PaymentStatus.Success ? 'check' : 'warning'}
                                        </UiIconMaterial>
                                    </UiText>
                                }
                                title={groupOrderProducts(payment.products)
                                    .map((product) => `${product.count} x ${product.name}`)
                                    .join(', ')}
                                subtitle={
                                    <>
                                        {format(payment.createdAt, 'DD/MM HH:mm')}
                                        {' • '}
                                        {toPaymentStatusLabel(payment.status)}
                                        {' • '}
                                        <CurrencyMinor currency={currency} amount={payment.amount} />
                                    </>
                                }
                                iconEnd={
                                    payment.status === PaymentStatus.Success && (
                                        <UiBox paddingRight={1}>
                                            <PaymentReceiptButton payment={payment} />
                                        </UiBox>
                                    )
                                }
                                key={payment.id}
                            />
                        ))}
                    </UiGrid>
                )}
            </UiGrid>

            {productIds.length > 0 && (
                <V2PaymentButton
                    locationId={hash}
                    productIds={productIds}
                    amount={amountPay}
                    config={config}
                    onSuccess={reset}
                    onReset={reset}
                    isPay
                />
            )}
        </UiBox>
    );
}

function groupOrderProducts(products) {
    const productsWithCounts = Object.values(
        products.reduce((products, product) => {
            products[product.locationProductId] = products[product.locationProductId] || {
                ...product,
                count: 0,
            };
            products[product.locationProductId].count += 1;
            return products;
        }, {}),
    );

    return orderBy(productsWithCounts, (product) => product.createdAt, 'asc');
}
