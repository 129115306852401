// NB! DO NOT EDIT THIS FILE! THIS FILE IS AUTOGENERATED AND WILL BE OVERWRITTEN

import { Api } from '@orderx/http';

export function apiEntityLocationInventoryPost({
    body,
    params,
    query,
}: {
    body?: any;
    params?: any;
    query?: any;
}): Promise<any> {
    return Api.request('entity-location-inventory', { method: 'post', body, params, query });
}
