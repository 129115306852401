import React, { useEffect } from 'react';
import { useAwsIotSubscription } from '../../providers/aws-iot';
import { useOnWindowFocus } from '../../shared/browser';
import { useOrderGroup } from '../../stores/order-group';
import { SplashScreen } from '../splash-screen/SplashScreen';
import { OrderGroup } from './OrderGroup';
import { useParams } from 'react-router-dom';

export function OrderGroupDataResolver() {
    const { hash } = useParams();
    const { group, reset, load } = useOrderGroup(hash);

    useEffect(() => {
        reset();
        load();
    }, [hash]);

    useOnWindowFocus(load, [hash]);
    useAwsIotSubscription(`order/groups/${hash}/update`, load, [hash]);

    return group ? <OrderGroup hash={hash} /> : <SplashScreen />;
}
