import React from 'react';
import styled from 'styled-components';

const Wrapper = styled.div`
    position: relative;
    padding: ${(props) => (props.container ? 1 : 0)}rem;

    > *:not(:first-child) {
        margin-top: ${(props) => props.gutter}px;
    }
`;

export function V2Grid({ children, gutter = 48, container = false, ...rest }) {
    return (
        <Wrapper {...rest} gutter={gutter} container={container}>
            {children}
        </Wrapper>
    );
}
