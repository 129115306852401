import { translate } from '../../shared/translate';

export const PaymentStatus = {
    Pending: 'PENDING',
    Success: 'SUCCESS',
    Failed: 'FAILED',
};

export const PaymentType = {
    BALANCE: 'BALANCE',
    BAMBORA: 'BAMBORA',
    BAMBORA_VIPPS: 'BAMBORA_VIPPS',
    BAMBORA_MOBILE_PAY: 'BAMBORA_MOBILE_PAY',
    VIPPS: 'VIPPS',
    INVOICE: 'INVOICE',
    DEVELOPMENT: 'DEVELOPMENT',
};

export function toPaymentStatusLabel(status) {
    const labels = {
        [PaymentStatus.Pending]: translate('Pending'),
        [PaymentStatus.Success]: translate('Success'),
        [PaymentStatus.Failed]: translate('Failed'),
    };

    return labels[status] || status;
}
