import React from 'react';
import { UiFlex, UiLoader, UiBox } from '@orderx/ui';

export function BlockLoader() {
    return (
        <UiBox padding={2}>
            <UiFlex justifyContent="center">
                <UiLoader />
            </UiFlex>
        </UiBox>
    );
}
