import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormGroup from '@material-ui/core/FormGroup';
import Switch from '@material-ui/core/Switch';
import makeStyles from '@material-ui/styles/makeStyles';
import React from 'react';

const useStyles = makeStyles({
    label: {
        lineHeight: 1.25,
    },
});

export function UiSwitch({ label = '', checked = false, onChange, disabled = false, noFormGroup = false }) {
    const classes = useStyles();
    const control = <Switch checked={Boolean(checked)} onChange={() => onChange(!checked)} disabled={disabled} />;

    if (noFormGroup) {
        return control;
    }

    return (
        <FormGroup row>
            <FormControlLabel classes={{ label: classes.label }} control={control} label={label} />
        </FormGroup>
    );
}
