import IconComment from '@material-ui/icons/CommentRounded';
import clsx from 'clsx';
import React from 'react';
import './StaffOrderStationListItemComment.scss';
import { UiFlex } from '@orderx/ui';

export function StaffOrderStationListItemComment({ order }) {
    return (
        <div className={clsx('StaffOrderStationListItemComment')}>
            {order.products
                .filter((product) => product.comment)
                .map((product) => (
                    <div key={product.id}>
                        <UiFlex spacing={2}>
                            <IconComment fontSize="small" />
                            <div>
                                <b>{product.name}</b> • {product.comment}
                            </div>
                        </UiFlex>
                    </div>
                ))}
        </div>
    );
}
