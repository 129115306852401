import clsx from 'clsx';
import React, { useState, useEffect } from 'react';
import './DeliveryStationOrderItem.scss';
import { UiFlex, UiButton, UiIconMaterial, UiGrid, UiText, UiToolbar, UiAlert, UiOverlay } from '@orderx/ui';
import groupBy from 'lodash/groupBy';
import { useDeliveryView } from '../../../../../hooks/delivery/view';
import uniq from 'lodash/uniq';
import { ProductStatus } from '../../../../../models/orders/product';
import { translate } from '../../../../../shared/translate';
import { useUser } from '../../../../../stores/user';
import { useApiQuery } from '@orderx/http';
import { putOrderProductsAction, MicroserviceOrders } from '../../../../../microservices/orders';
import { useSnackbar } from '../../../../../shared/snackbar';
import { toMoment } from '../../../../../shared/moment';
import truncate from 'lodash/truncate';
import { formatStaffName } from '../../../../../util/format-staff-name';
import { ToolbarTitle } from '../../../../../@v2/components/toolbar/title/ToolbarTitle';
import { UiButtonConfirmDialog } from '../../../../ui/UiButtonConfirmDialog';

interface Props {
    order: any;
}

export function DeliveryStationOrderItem({ order }: Props) {
    const {
        getBarById,
        loadData,
        config,
        bars,
        getNextOrderStatus,
        isStatusChangeAllowedForStatus,
    } = useDeliveryView();
    const [isActionsVisible, setIsActionsVisible] = useState(false);
    const { isTestUser } = useUser();
    const { showSuccess, showWarning } = useSnackbar();
    const [statusChangeProductsIds, setStatusChangeProductIds] = useState<any>([]);
    const statusChangeProducts = statusChangeProductsIds
        .map((id) => order.products.find((product) => product.id === id))
        .filter(Boolean);

    const statusChangeProductsLength = statusChangeProducts.length;
    const uniqProductStatuses = uniq(order.products.map((product) => product.status));
    const uniqProductStatus = uniqProductStatuses.length === 1 && uniqProductStatuses[0];
    const productsGroupedByLocationProductId = groupBy(order.products, (product) => product.locationProductId);
    const nextStatus = getNextOrderStatus(order);

    useEffect(() => {
        if (statusChangeProductsLength === 0) {
            closeOrderProductsOverlay();
        }
    }, [statusChangeProductsLength]);

    const { run: updateProductsToStatus, isLoading: isUpdatingProductStatuses } = useApiQuery(
        async ({ productIds, status }) => {
            await MicroserviceOrders.staffApp({
                action: 'UPDATE_PRODUCTS_STATUS',
                orderId: order.id,
                productIds: productIds || order.products.map((product) => product.id),
                status,
            });

            await loadData();
            setIsActionsVisible(false);
            //showSuccess('Order updated');
        },
    );

    const { run: archiveOrder, isLoading: isArchiving } = useApiQuery(async () => {
        await putOrderProductsAction(order.barId, order.id, { action: 'ARCHIVE_ORDER' });
        showSuccess('Order archived');
        loadData();
    });

    const { run: cancelOrder, isLoading: isCancelOrderLoading } = useApiQuery(async () => {
        if (window.confirm('Are you sure you want to cancel this order?')) {
            await MicroserviceOrders.staffApp({
                action: 'handlerAppStaffCancelOrder',
                orderId: order.id,
            });

            showWarning('Order has been cancelled');

            await loadData();
        }
    });

    const productsWithComments = order.products.filter((product) => product.comment);

    function getStatusButton(status, product: any = undefined) {
        const statusChangeForbidden = !isStatusChangeAllowedForStatus(order, status, product);

        if (statusChangeForbidden || uniqProductStatus === status) {
            return null;
        }

        const label = {
            [ProductStatus.Placed]: 'Not started',
            [ProductStatus.InPreparation]: 'Preparing',
            [ProductStatus.Ready]: 'Ready',
            [ProductStatus.Delivered]: 'Handed out',
        }[status];

        return (
            <UiButton
                color={product?.status === status ? 'primary' : 'default'}
                onClick={() =>
                    updateProductsToStatus({
                        status,
                        productIds: product && [product.id],
                    })
                }
                disabled={isUpdatingProductStatuses}
                variant="filled">
                {label}
            </UiButton>
        );
    }

    function closeOrderProductsOverlay() {
        setStatusChangeProductIds([]);
    }

    const elementProducts = (
        <div className="products-container">
            {Object.keys(productsGroupedByLocationProductId).map((locationProductId) => {
                const products = productsGroupedByLocationProductId[locationProductId];
                const product = products[0];
                const uniqStatuses = uniq(products.map((product) => product.status));
                const uniqStatus = uniqStatuses.length === 1 && uniqStatuses[0];
                const onClick = () => setStatusChangeProductIds(products.map((product) => product.id));

                return (
                    <UiFlex onClick={onClick} className={clsx('product-item', uniqStatus)} key={locationProductId}>
                        <UiButton size={-1} variant="filled">
                            <label>
                                {products.length}x{product.name}
                            </label>
                        </UiButton>

                        <UiFlex className="product-status-bar" flexChildren>
                            {uniqStatuses.map((status) => (
                                <div className="bar-container" key={status}>
                                    <div className={status} />
                                </div>
                            ))}
                        </UiFlex>

                        {products.find((product) => product.comment) && (
                            <UiIconMaterial className="button-comment">comment</UiIconMaterial>
                        )}
                    </UiFlex>
                );
            })}
        </div>
    );

    return (
        <UiGrid
            spacing={1}
            className={clsx(
                'DeliveryStationOrderItem',
                `status-${uniqProductStatus}`,
                !uniqProductStatus && 'status-mixed',
                config.compact && 'compact',
            )}>
            {uniqProductStatus === ProductStatus.InDelivery && <div className="order-in-delivery" />}

            <UiFlex className="content" justifyContent="space-between" spacing={2}>
                <UiFlex spacing={2}>
                    <UiFlex spacing={1}>
                        <div className="sequence-label-container">
                            <label>{order.sequence}</label>
                        </div>

                        <UiText size={-1} weight={2}>
                            <div>
                                {[order.group?.name, bars.length > 1 && getBarById(order.barId)?.name]
                                    .filter(Boolean)
                                    .join(' • ')}
                            </div>

                            <UiFlex spacing={1}>
                                <div>{toMoment(order.createdAt).format('HH:mm')}</div>

                                {order.isCustomerPlaced && <div className="badge">By customer</div>}

                                {!config.compact && order.placedByName && (
                                    <div className="badge">
                                        Staff:
                                        {truncate(order.placedByName || formatStaffName(order.placedByName), {
                                            length: 20,
                                            omission: '..',
                                        })}
                                    </div>
                                )}
                            </UiFlex>
                        </UiText>
                    </UiFlex>

                    {config.compact && elementProducts}
                </UiFlex>

                <div className="item-actions-container">
                    <UiFlex justifyContent="flex-end" spacing={1}>
                        {nextStatus && isStatusChangeAllowedForStatus(order, nextStatus?.value) && (
                            <UiButton
                                onClick={() => updateProductsToStatus({ status: nextStatus.value })}
                                isLoading={isUpdatingProductStatuses}
                                variant="filled"
                                color="primary">
                                {nextStatus.label}
                            </UiButton>
                        )}

                        {config.isCancelOrderMode && (
                            <UiButton
                                onClick={cancelOrder}
                                isLoading={isCancelOrderLoading}
                                color="error"
                                icon={<UiIconMaterial>delete_forever</UiIconMaterial>}
                                iconCircle
                            />
                        )}

                        <UiButton
                            onClick={() => setIsActionsVisible(true)}
                            icon={<UiIconMaterial>more_vert</UiIconMaterial>}
                            iconCircle
                        />
                    </UiFlex>
                </div>
            </UiFlex>

            {!config.compact && elementProducts}

            {config.commentsVisibleInListView && productsWithComments.length > 0 && (
                <UiAlert color="secondary">
                    <UiFlex spacing={2}>
                        <UiIconMaterial>comment</UiIconMaterial>

                        <UiGrid spacing={0}>
                            <UiText weight={2}>Comments</UiText>

                            {productsWithComments.map((product) => (
                                <UiText key={product.id}>
                                    {product.name}: {product.comment}
                                </UiText>
                            ))}
                        </UiGrid>
                    </UiFlex>
                </UiAlert>
            )}

            {statusChangeProducts.length > 0 && (
                <UiOverlay className="product-status-overlay" onClose={closeOrderProductsOverlay} transparent>
                    <UiToolbar>
                        <ToolbarTitle title={statusChangeProducts[0]?.name} />

                        <UiButton
                            onClick={closeOrderProductsOverlay}
                            icon={<UiIconMaterial>close</UiIconMaterial>}
                            size={1}
                        />
                    </UiToolbar>

                    <UiGrid outerSpacing={2}>
                        {statusChangeProducts.map((product) => (
                            <div key={product.id}>
                                <UiText weight={2} color="text-secondary">
                                    {product.name}
                                </UiText>

                                <UiFlex flexChildren>
                                    {getStatusButton(ProductStatus.Placed, product)}
                                    {getStatusButton(ProductStatus.InPreparation, product)}
                                    {getStatusButton(ProductStatus.Ready, product)}
                                    {getStatusButton(ProductStatus.Delivered, product)}
                                </UiFlex>

                                {product.comment && (
                                    <UiText weight={2} color="secondary">
                                        Comment: {product.comment}
                                    </UiText>
                                )}
                            </div>
                        ))}
                    </UiGrid>
                </UiOverlay>
            )}

            {isActionsVisible && (
                <div className="overlay-dark animated fadeIn">
                    <UiFlex spacing={1}>
                        <UiFlex spacing={1} flexChildren fullWidth>
                            {getStatusButton(ProductStatus.Placed)}
                            {getStatusButton(ProductStatus.InPreparation)}
                            {getStatusButton(ProductStatus.Ready)}
                            {getStatusButton(ProductStatus.Delivered)}

                            {isTestUser && (
                                <UiButton
                                    onClick={archiveOrder}
                                    isLoading={isArchiving}
                                    variant="filled"
                                    color="primary">
                                    {translate('Archive')}
                                </UiButton>
                            )}
                        </UiFlex>

                        <UiButton
                            onClick={() => setIsActionsVisible(false)}
                            icon={<UiIconMaterial>close</UiIconMaterial>}
                            iconCircle
                        />
                    </UiFlex>
                </div>
            )}
        </UiGrid>
    );
}
