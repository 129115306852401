import React from 'react';
import styled from 'styled-components';
import classNames from 'classnames';

const Wrapper = styled.div`
    padding: 0 ${props => props.theme.sizes.base}px;

    ${props => props.noHorizontalPadding && 'padding: 0;'};

    &:first-child {
        padding-top: ${props => props.theme.sizes.base}px;
    }

    &:not(:last-child) {
        padding-bottom: ${props => props.theme.sizes.base * 2}px;
    }

    &:last-child {
        padding-bottom: ${props => props.theme.sizes.base}px;
    }
`;

export function PageSection({ children, noHorizontalPadding, className }) {
    return (
        <Wrapper className={classNames('PageSection', className)} noHorizontalPadding={noHorizontalPadding}>
            {children}
        </Wrapper>
    );
}
