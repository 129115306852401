import { Column, Entity, BeforeInsert, Index, ManyToOne, OneToMany } from './typeorm';
import { BaseModel } from './base-model';
import { Outlet } from './outlet';
import { OutletZone } from './outlet-zone';
import { OrderProduct } from './order-product';
import { User } from './user';

export enum OrderStatus {
    PAYMENT_REQUIRED = 'PAYMENT_REQUIRED',
    OPEN = 'OPEN',
    REJECTED = 'REJECTED',
    CLOSED = 'CLOSED',
    ARCHIVED = 'ARCHIVED',
}

export enum OrderPlacedFrom {
    ZONE = 'ZONE',
    COUNTER = 'COUNTER',
}

export enum OrderPlacedBy {
    CUSTOMER = 'CUSTOMER',
    STAFF = 'STAFF',
}

@Entity()
export class Order extends BaseModel {
    @Column({ nullable: true })
    @Index()
    deviceFingerprint: string;

    @Column({ nullable: true })
    deviceFingerprintLastInteraction: string;

    @Column({ type: 'text', nullable: true })
    comment: string;

    @Column({ default: OrderStatus.OPEN })
    @Index()
    status: OrderStatus;

    @Column({ nullable: true })
    @Index()
    placedBy: OrderPlacedBy;

    @Column({ nullable: true })
    @Index()
    placedFrom: OrderPlacedFrom;

    @Column({ default: 1 })
    @Index()
    sequence: number;

    @Column()
    @Index({ unique: true })
    hash: string;

    @OneToMany(() => OrderProduct, (model) => model.order)
    orderProducts: OrderProduct[];

    @ManyToOne(() => User, (model) => model.orders)
    user: User;
    @Column({ nullable: true })
    userId: number;

    @ManyToOne(() => Outlet, (model) => model.orders)
    outlet: Outlet;
    @Column()
    outletId: number;

    @ManyToOne(() => OutletZone, (model) => model.orders)
    outletZone: OutletZone;
    @Column({ nullable: true })
    outletZoneId: number;
}
