import React from 'react';
import styled from 'styled-components';
import { InputLabel } from './InputLabel';
import Padding from './Padding';

const Wrapper = styled.div`
    display: flex;
    white-space: nowrap;
    overflow-x: auto;
    overflow-y: visible;
    -webkit-overflow-scrolling: touch;
    padding-top: 16px;
    padding-left: ${props => props.theme.sizes.base}px;
    padding-bottom: ${props => props.theme.sizes.base * 2}px;

    &::-webkit-scrollbar {
        display: none;
    }
`;

export default function Pills({ children, label }) {
    return (
        <>
            {label && (
                <Padding horizontal>
                    <InputLabel>{label}</InputLabel>
                </Padding>
            )}

            <Wrapper className="Pills">
                {children}
                &nbsp;
            </Wrapper>
        </>
    );
}
