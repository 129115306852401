import React, { useEffect } from 'react';
import { UiSwitch } from '../../components/ui/UiSwitch';
import { ProductStatus } from '../../models/orders/product';
import { routes } from '../../shared/router';
import { useDeviceConfig } from '../../stores/device-config';
import { useStaffOrderingBar } from '../../stores/staff-ordering-bar';
import without from 'lodash/without';
import { capitalCase } from '../../util/capital-case';
import { List } from '../../@v2/components/list/List';
import { ListItem } from '../../@v2/components/list/item/ListItem';
import { UiToolbar, UiGrid } from '@orderx/ui';
import { ToolbarTitle } from '../../@v2/components/toolbar/title/ToolbarTitle';
import { useDeliveryView } from '../../hooks/delivery/view';

export function StaffOrderConfigStationPage() {
    const { barId } = useStaffOrderingBar();
    const { config, updateConfig } = useDeviceConfig();
    const deliveryView = useDeliveryView();

    const { isCommentsVisibleInListView } = config;
    const statuses = [ProductStatus.Placed, ProductStatus.InPreparation, ProductStatus.Ready, ProductStatus.Delivered];

    function toggleAllowedStatus(key, status) {
        const allowedStatuses = config[key].includes(status) ? without(config[key], status) : [...config[key], status];

        updateConfig({ [key]: allowedStatuses });
    }

    useEffect(() => {
        deliveryView.configCache();
    }, [deliveryView.config]);

    return (
        <>
            <UiToolbar>
                <ToolbarTitle title="Station" buttonBackLink={routes.staffOrderBarConfig.replace(':barId', barId)} />
            </UiToolbar>

            <UiGrid>
                <ListItem
                    title="Dynamic categories"
                    onClick={() =>
                        deliveryView.configSetValue('isDynamicCategories', !deliveryView.config.isDynamicCategories)
                    }
                    iconEnd={
                        <UiSwitch
                            onChange={() =>
                                deliveryView.configSetValue(
                                    'isDynamicCategories',
                                    !deliveryView.config.isDynamicCategories,
                                )
                            }
                            checked={deliveryView.config.isDynamicCategories}
                            noFormGroup
                        />
                    }
                />

                <List title="Allowed food statuses">
                    {statuses.map((status) => (
                        <ListItem
                            key={status}
                            title={capitalCase(status)}
                            onClick={() => toggleAllowedStatus('allowedStationFoodStatuses', status)}
                            iconEnd={
                                <UiSwitch
                                    onChange={() => toggleAllowedStatus('allowedStationFoodStatuses', status)}
                                    checked={config.allowedStationFoodStatuses.includes(status)}
                                    noFormGroup
                                />
                            }
                        />
                    ))}
                </List>

                <List title="Allowed drink statuses">
                    {statuses.map((status) => (
                        <ListItem
                            key={status}
                            title={capitalCase(status)}
                            onClick={() => toggleAllowedStatus('allowedStationDrinkStatuses', status)}
                            iconEnd={
                                <UiSwitch
                                    onChange={() => toggleAllowedStatus('allowedStationDrinkStatuses', status)}
                                    checked={config.allowedStationDrinkStatuses.includes(status)}
                                    noFormGroup
                                />
                            }
                        />
                    ))}
                </List>

                <List title="Other">
                    <ListItem
                        title="Comments visible in list view"
                        onClick={() => updateConfig({ isCommentsVisibleInListView: !isCommentsVisibleInListView })}
                        iconEnd={
                            <UiSwitch
                                onChange={() =>
                                    updateConfig({ isCommentsVisibleInListView: !isCommentsVisibleInListView })
                                }
                                checked={isCommentsVisibleInListView}
                                noFormGroup
                            />
                        }
                    />
                </List>
            </UiGrid>
        </>
    );
}
