import React, { useEffect } from 'react';
import { SwitchRedirect } from '../components/SwitchRedirect';
import { loadVenueBalanceGroups, useMqttVenueBalanceGroups } from '../microservices/balance-groups';
import { useOnWindowFocus } from '../shared/browser';
import { routes, withParams } from '../shared/router';
import { showError } from '../shared/toasts';
import { useStaffActiveBar } from '../stores/staff-active-bar';
import StaffBarBalanceGroupsDetail from './StaffBarBalanceGroupsDetail';
import StaffBarBalanceGroupsList from './StaffBarBalanceGroupsList';

export default function StaffBarBalanceGroups() {
    const { id: barId, venueId } = useStaffActiveBar();
    useOnWindowFocus(updateBalanceGroups);

    useEffect(() => {
        updateBalanceGroups();
    }, []);

    useMqttVenueBalanceGroups(venueId);

    const links = [
        {
            path: withParams(routes.staffBarBalanceGroupsList, { barId }),
            component: StaffBarBalanceGroupsList,
        },
        {
            path: withParams(routes.staffBarBalanceGroupsDetail, { barId }),
            component: StaffBarBalanceGroupsDetail,
        },
    ];

    async function updateBalanceGroups() {
        try {
            await loadVenueBalanceGroups(venueId);
        } catch (error) {
            showError(error, 'Failed to load balance groups');
        }
    }

    return <SwitchRedirect routes={links} />;
}
