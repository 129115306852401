import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import React from 'react';
import styled from 'styled-components';

const Wrapper = styled.div`
    overflow-x: auto;
`;

export function V2Table({ headers = [], children, ...rest }) {
    return (
        <Wrapper>
            <Table {...rest}>
                {headers.length > 0 && (
                    <TableHead>
                        <TableRow>
                            {headers.map((label, i) => (
                                <TableCell key={label} align={i ? 'right' : 'left'}>
                                    {label}
                                </TableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                )}

                <TableBody>{children}</TableBody>
            </Table>
        </Wrapper>
    );
}

export function V2TableRow({ children, ...rest }) {
    return <TableRow {...rest}>{children}</TableRow>;
}

export function V2TableCell({ children, right, ...rest }) {
    return (
        <TableCell {...rest} align={right ? 'right' : 'left'}>
            {children}
        </TableCell>
    );
}
