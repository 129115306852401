import React, { useEffect } from 'react';
import { UiLoader } from '../../../../components/ui/loader/UiLoader';
import { usePosGroups } from '../../../../hooks/pos/groups';
import { useCreateOrder } from '../../../../stores/create-order';
import { StaffCreatePageDataResolver } from '../../../staff-create/StaffCreatePageDataResolver';

export function PosGroupCreatePage({ match }) {
    const { groups, loadGroups } = usePosGroups();
    const { groupId } = match.params;
    const group = groups.find(group => group.id === groupId);
    const { setPreSelectedGroup } = useCreateOrder();

    useEffect(() => {
        loadGroups();
    }, []);

    useEffect(() => {
        if (group) {
            setPreSelectedGroup(group);
            return () => setPreSelectedGroup(undefined);
        }
    }, [group]);

    if (!group?.barId) {
        return <UiLoader fullWidth />;
    }

    return <StaffCreatePageDataResolver match={{ params: { barId: group.barId } }} />;
}

PosGroupCreatePage.route = '/pos/:barId/group/:groupId/create';
