import React, { useEffect } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import { UiGrid, UiLoader, UiButton, UiToolbar } from '@orderx/ui';
import { useMe } from '../../hooks/me';
import { PosIdPage } from './id/PosIdPage';
import { useApiQuery } from '@orderx/http';
import { apiPosGet } from '../../../api/pos.[get]';
import { useOrderBubble } from '../../hooks/order-bubble';
import { ToolbarTitle } from '../../components/toolbar/title/ToolbarTitle';
import { SplashScreen } from '../../../pages/splash-screen/SplashScreen';

export function PosPage() {
    useOrderBubble().useTemporaryHide();
    const { isAuthenticated, loadUser } = useMe();

    useEffect(() => {
        loadUser();
    }, []);

    if (!isAuthenticated) {
        return <SplashScreen />;
    }

    return (
        <Switch>
            <Route path={PosPage.route} exact>
                <Index />
            </Route>

            <Route path={PosIdPage.route}>
                <PosIdPage />
            </Route>

            <Redirect to={PosPage.route} />
        </Switch>
    );
}

PosPage.route = '/v2/pos';

function Index() {
    const { run, response: bars = [] } = useApiQuery<any>(() => apiPosGet({}));

    useEffect(() => {
        run();
    }, []);

    return (
        <>
            <UiToolbar>
                <ToolbarTitle title="Choose bar" buttonBackLink="/" />
            </UiToolbar>

            <UiGrid outerSpacing={1}>
                <UiGrid minMax={160}>
                    {bars.map((bar) => (
                        <UiButton to={PosIdPage.route} toParams={{ locationId: bar.id }} key={bar.id} variant="filled">
                            {bar.name}
                        </UiButton>
                    ))}
                </UiGrid>
            </UiGrid>
        </>
    );
}
