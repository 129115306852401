import React from 'react';
import styled from 'styled-components';

const Wrapper = styled.div`
    padding-top: ${({ top, vertical, all }) => (top || vertical || all ? 16 : 0)}px;
    padding-right: ${({ right, horizontal, all }) => (right || horizontal || all ? 16 : 0)}px;
    padding-bottom: ${({ bottom, vertical, all }) => (bottom || vertical || all ? 16 : 0)}px;
    padding-left: ${({ left, horizontal, all }) => (left || horizontal || all ? 16 : 0)}px;
`;

function Padding({
    children,
    top = false,
    right = false,
    bottom = false,
    left = false,
    horizontal = false,
    vertical = false,
    all = false,
}) {
    return (
        <Wrapper {...{ top, right, bottom, left, horizontal, vertical, all }} className="Padding">
            {children}
        </Wrapper>
    );
}

export default Padding;
