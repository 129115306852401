import React from 'react';
import styled, { css } from 'styled-components';

const cssOverlay = props =>
    props.overlay &&
    css`
        background-color: rgba(0, 0, 0, 0.5);
        z-index: 2;
    `;

const cssCover = props =>
    props.cover &&
    css`
        position: absolute;
        left: 0;
        top: 0;
        height: 100%;
        width: 100%;
        z-index: 1;
    `;

const Wrapper = styled.div`
    align-items: center;
    display: flex;
    justify-content: center;
    margin: ${props => props.margin}px;

    ${cssCover};
    ${cssOverlay};

    .spinner {
        border-radius: 50%;
        position: absolute;
        height: 32px;
        width: 32px;
        left: 0;
        top: 0;
    }

    .spinner {
        font-size: 1rem;
        position: relative;
        text-indent: -9999em;
        border: 2px solid ${props => props.background};
        border-left-color: ${props => props.color};
        transform: translateZ(0);
        transition: border 300ms;
        animation: spin ${props => props.animationDurationMilliseconds}ms infinite linear;
    }
`;

export function CssLoader({
    size = 32,
    cover = false,
    overlay = false,
    margin = 16,
    color = 'white',
    background = '#666',
    animationDurationMilliseconds = 240,
    className,
}) {
    const style = {
        height: size,
        width: size,
    };

    return (
        <Wrapper
            className={className}
            size={size}
            cover={cover}
            overlay={overlay}
            margin={margin}
            color={color}
            background={background}
            animationDurationMilliseconds={animationDurationMilliseconds}>
            <div className="spinner" style={style} />
        </Wrapper>
    );
}
