import React, { Fragment, useEffect } from 'react';
import { isNative } from '../shared/device';
import { scan, stopScan } from '../shared/qr-scanner';
import { storageGet } from '../shared/storage';
import { useInput } from '../use/form';
import { Button } from './Button';
import { Flex } from './Flex';
import { Input } from './Input';

export function QrScanner({ onReceivedQr = () => {} }) {
    const inputQr = useInput(storageGet('rdxQr'));

    useEffect(() => {
        startScan();
        return () => stopScan();
    }, []);

    async function startScan() {
        try {
            const qr = await scan();

            if (qr) {
                onReceivedQr(qr);
            }
        } catch (error) {
            console.error(error);
        }
    }

    if (!isNative()) {
        return (
            <Flex>
                <Input {...inputQr} margin={0} />

                <Button primary onClick={() => onReceivedQr(inputQr.value)}>
                    <span>Emulate</span>
                </Button>
            </Flex>
        );
    }

    return <Fragment />;
}
