import { toast } from 'react-toastify';
import isString from 'lodash/isString';

export function showInfo(message) {
    toast.info(message, {
        position: toast.POSITION.TOP_RIGHT,
    });
}

export function showSuccess(message) {
    toast.success(message, {
        position: toast.POSITION.TOP_RIGHT,
    });
}

export function showError(error = {}, fallbackMessage) {
    console.error(error);
    const message = getErrorMessage(error, fallbackMessage);

    if (!message.includes('avbrutt') && !message.includes('cancelled')) {
        toast.error(message, {
            position: toast.POSITION.TOP_RIGHT,
        });
    }
}

function getErrorMessage(error, fallbackMessage) {
    if (isString(error)) {
        return error;
    } else if (error.message) {
        if (error.toString().includes('Failed to fetch')) {
            return fallbackMessage;
        } else {
            return error.message;
        }
    } else if (fallbackMessage) {
        return fallbackMessage;
    } else {
        return 'There has been an error';
    }
}
