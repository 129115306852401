import clsx from 'clsx';
import React from 'react';
import './PosFooter.scss';
import { UiFlex, UiButton, UiToolbar } from '@orderx/ui';

export function PosFooter() {
    return (
        <UiToolbar position="bottom">
            <UiFlex className={clsx('PosFooter')} flexChildren fullWidth>
                <UiButton>Create</UiButton>
                <UiButton to="/customer/profile">Exit</UiButton>
            </UiFlex>
        </UiToolbar>
    );
}
