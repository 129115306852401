import IconPrint from '@material-ui/icons/PrintRounded';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { format } from 'date-fns';
import React from 'react';
import { printPosTerminalOrderReceipt } from '../../../microservices/orderx';
import { useSnackbar } from '../../../shared/snackbar';
import { wait } from '../../../shared/util';
import { useApiQuery } from '@orderx/http';
import { Currency } from '../../Currency';
import { OrderStatusLight } from '../../OrderStatusLight';
import { UiButton } from '../../../@deprecated/UiButton';
import { ListItem } from '../../../@v2/components/list/item/ListItem';
import { UiFlex } from '@orderx/ui';

const useStyles = makeStyles({
    text: {
        textTransform: 'uppercase',
        fontWeight: 700,
        fontSize: '0.8rem',
    },
    listItem: {
        borderBottom: '1px solid',
        borderBottomColor: '#333',
    },
});

export function LegacyOrderRow({ order }) {
    const classes = useStyles();
    const terminalName = order.PosProfile?.name;
    const orderProducts = order.Data?.Products || [];
    const products = orderProducts.map((product) => `${product.Count} x ${product.Name}`).join(', ');
    const { showSuccess } = useSnackbar();

    const { run: print, isLoading } = useApiQuery(async () => {
        const { PosProfile } = order;
        const { pos: posFriendlyName, id: terminalId } = PosProfile;

        await printPosTerminalOrderReceipt({
            posFriendlyName,
            terminalId,
            orderId: order.Id,
        });

        await wait(300);
        showSuccess('Printing receipt');
    });

    return (
        <ListItem
            key={order.Id}
            className={classes.listItem}
            onClick={() => console.log(order)}
            title={
                <UiFlex spacing={2}>
                    <OrderStatusLight status={order.Status} />

                    <span className={classes.text}>{terminalName}</span>

                    {order.DatetimeStr && <span className={classes.text}>{format(order.DatetimeStr, 'HH:mm')}</span>}

                    <span className={classes.text}>
                        <Currency amount={order.PaymentAmount} />
                    </span>
                </UiFlex>
            }
            subtitle={products}
            iconEnd={<UiButton isLoading={isLoading} onClick={() => print(order)} icon={<IconPrint />} />}
        />
    );
}
