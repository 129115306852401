import TablePagination from '@material-ui/core/TablePagination';
import React from 'react';
import { getProductPaginationOptions, useCreateOrderBar } from '../../stores/create-order-bar';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { useCreateOrderTerminals } from '../../stores/create-order-terminals';
import { useDeviceConfig } from '../../stores/device-config';
import { theme as themeActive } from '../../themes/dark';

const useStyles = makeStyles(({ palette }) => ({
    pagination: ({ compact }) => ({
        backgroundColor: palette.background.default,
        borderRadius: 8,
        position: 'fixed',
        bottom: themeActive.tabs.height + 2 + (compact ? 50 : 75),
        right: 0,
        zIndex: 1000,
    }),
    toolbar: {
        minHeight: 32,
    },
}));

export function StaffCreateProductPagination() {
    const { hasPagination } = useDeviceConfig().config;
    const { products, productsPage, productsPerPage, setProductsPage, setProductsPerPage } = useCreateOrderBar();
    const { compact } = useCreateOrderTerminals();
    const classes = useStyles({ compact });

    const handleChangePage = (event, newPage) => {
        setProductsPage(newPage);
    };

    const handleChangeRowsPerPage = event => {
        setProductsPerPage(parseInt(event.target.value, 10));
        setProductsPage(0);
    };

    if (!hasPagination) {
        return null;
    }

    return (
        <>
            <TablePagination
                className={classes.pagination}
                classes={{ toolbar: classes.toolbar }}
                labelRowsPerPage={'Per page:'}
                rowsPerPageOptions={getProductPaginationOptions()}
                component="div"
                count={products.length}
                rowsPerPage={productsPerPage}
                page={productsPage}
                onChangePage={handleChangePage}
                onChangeRowsPerPage={handleChangeRowsPerPage}
            />
        </>
    );
}
