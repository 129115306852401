import React from 'react';
import { SplashScreen } from '../splash-screen/SplashScreen';
import { useRouter } from '../../@v2/hooks/router';

export function BamboraCallbackV2Page() {
    const {
        params: { type, id },
    } = useRouter();

    if (type === 'order') {
        window.location.href = `https://orderx.eu/order/${id}`;
    }

    if (type === 'zone') {
        window.location.href = `https://orderx.eu/${id}`;
    }

    return <SplashScreen />;
}

BamboraCallbackV2Page.route = '/bambora-callback/:type/:id';
