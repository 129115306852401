import clsx from 'clsx';
import React, { useEffect } from 'react';
import styled from 'styled-components';
import { UiBox, UiText, UiImage, UiGrid, UiIconMaterial } from '@orderx/ui';
import { UiButtonBottom } from '../ui/UiButtonBottom';
import { ButtonBottom } from '../../@v2/components/button/bottom/ButtonBottom';
import { CustomerBrowsePage } from '../../pages/customer/browse/CustomerBrowsePage';
import { useDiscount } from '../../stores/discount';

const Wrapper = styled(UiGrid)`
    height: 100%;
    width: 100%;
    display: grid;
    align-content: center;
    text-align: center;

    i {
        font-size: 64px;
        color: var(--color-success);
    }
`;

interface Props {
    tag: any;
}

export function Discount({ tag }: Props) {
    const { saveVenueAccount } = useDiscount();

    useEffect(() => {
        saveVenueAccount({
            venueId: tag.venue.id,
            account: tag.account,
        });
    }, [tag]);

    return (
        <Wrapper className={clsx('Discount')} padding={4}>
            <UiGrid>
                <UiIconMaterial>local_offer</UiIconMaterial>

                <UiText size={4} weight={1}>
                    <div>You have claimed</div>
                    <div>-{tag.account.percent}% discount at</div>
                    <UiText color="success" weight={2}>
                        {tag.venue.name}
                    </UiText>
                </UiText>
            </UiGrid>

            <ButtonBottom to={CustomerBrowsePage.route} color="primary">
                OK
            </ButtonBottom>
        </Wrapper>
    );
}
