import React from 'react';
import { Switch, Route } from 'react-router-dom';
import { AdminConfigurationPage } from './configuration/AdminConfigurationPage';

export function AdminPage() {
    return (
        <Switch>
            <Route path={AdminConfigurationPage.route} component={AdminConfigurationPage} />
        </Switch>
    );
}

AdminPage.route = '/admin';
