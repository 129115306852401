import MaterialMenu from '@material-ui/core/Menu';
import MaterialMenuItem from '@material-ui/core/MenuItem';
import React, { useState } from 'react';
import { printPosTerminalOrderReceipt } from '../microservices/orderx';
import { showSuccess } from '../shared/toasts';
import { translate } from '../shared/translate';
import { wait } from '../shared/util';
import { themeActive } from '../themes/dark';
import { Button } from './Button';
import { Flex } from './Flex';
import { Svg, SvgIcons } from './Svg';
import { V2Loader } from './v2/V2Loader';

export function OrderListItemMoreButton({ order, onClickDetails }) {
    const [isLoading, setIsLoading] = useState(false);
    const [anchor, setAnchor] = useState();

    const canPrint = order.Id && order.PosProfile && order.PosProfile.pos && order.PosProfile.id;
    const isFullOrder = Boolean(order.Data && order.Data.Products);

    function onClose() {
        setAnchor();
    }

    async function print() {
        setIsLoading(true);

        const { PosProfile } = order;
        const { pos: posFriendlyName, id: terminalId } = PosProfile;

        await printPosTerminalOrderReceipt({
            posFriendlyName,
            terminalId,
            orderId: order.Id,
        });
        await wait(500);
        showSuccess('Printing receipt...');

        setIsLoading(false);
        onClose();
    }

    return (
        <div>
            <div>
                <Button
                    onClick={event => setAnchor(event.currentTarget)}
                    icon={SvgIcons.More}
                    small
                    clear
                    iconFill={themeActive.colors.faded}
                />

                <MaterialMenu anchorEl={anchor} open={Boolean(anchor)} onClose={onClose}>
                    {isLoading && <V2Loader cover fixed />}

                    {isFullOrder &&
                        onClickDetails && (
                            <MaterialMenuItem
                                onClick={() => {
                                    onClose();
                                    onClickDetails();
                                }}>
                                <Flex>
                                    <Svg icon={SvgIcons.List} />
                                    <span>{translate('Show details')}</span>
                                </Flex>
                            </MaterialMenuItem>
                        )}

                    {canPrint && (
                        <MaterialMenuItem onClick={print}>
                            <Flex>
                                <Svg icon={SvgIcons.Print} />
                                <span>{translate('Print')}</span>
                            </Flex>
                        </MaterialMenuItem>
                    )}
                </MaterialMenu>
            </div>
        </div>
    );
}
