import React, { Fragment } from 'react';
import { useCurrency, Currency as CurrencyValue } from '../@v2/hooks/currency';

export function numberWithSpaces(number = 0) {
    const parts = number.toString().split('.');
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    return parts.join('.');
}

function currencyPrefix(code) {
    switch (code) {
        case 'EUR':
            return '€';
        case 'GBP':
            return '£';
        case 'NOK':
        case 'SEK':
        case 'DKK':
            return 'Kr';
        case 'US':
            return '$';
        default:
            return '';
    }
}

function currencySuffix(code) {
    switch (code) {
        case 'NOK':
        case 'SEK':
        case 'DKK':
            return ',-';
        default:
            return '';
    }
}

export function Currency({ currency = '', amount = 0 }) {
    const { currency: hookCurrency, formatMajor, formatMinor } = useCurrency();
    const currencyValue = currency || hookCurrency || 'NOK';
    return <>{formatMajor(amount)}</>;

    if ([CurrencyValue.EUR, CurrencyValue.DKK].includes(currencyValue)) {
        return <>{formatMajor(amount)}</>;
    }

    const prefix = currencyPrefix(currencyValue);
    const value = numberWithSpaces(amount);
    const suffix = currencySuffix(currencyValue);

    return (
        <Fragment>
            {prefix}
            {['NOK', 'DKK', 'SEK'].includes(currencyValue) && <>&nbsp;</>}
            {value}
            {suffix}
        </Fragment>
    );
}
