import throttle from 'lodash/throttle';
import { createStore, useStore } from '../create-store';
import { isAndroid, isIos, isNative } from './device';
import { storageGet, storageSet } from './storage';
import { translate } from './translate';

const store = createStore({
    isCheckingForUpdates: false,
    status: 0,
    version: '-',
    progress: undefined,
    statusLabel: '',
    updateLabel: '',
    isPending: false,
    isUpdateInstalled: false,
});

export function useCodePush() {
    const [
        { isCheckingForUpdates, status, version, progress, statusLabel, updateLabel, isPending, isUpdateInstalled },
    ] = useStore(store);
    return { isCheckingForUpdates, status, version, progress, statusLabel, updateLabel, isPending, isUpdateInstalled };
}

export function sync() {
    if (!isNative()) {
        return;
    }

    const { codePush, InstallMode, SyncStatus } = window;
    const { ON_NEXT_RESUME } = InstallMode;

    const syncStatusLabels = {
        [SyncStatus.UP_TO_DATE]: 'Up to date',
        [SyncStatus.UPDATE_INSTALLED]: 'Update installed',
        [SyncStatus.UPDATE_IGNORED]: 'Update ignored',
        [SyncStatus.ERROR]: 'Error',
        [SyncStatus.IN_PROGRESS]: 'In progress',
        [SyncStatus.CHECKING_FOR_UPDATE]: 'Checking for update',
        [SyncStatus.AWAITING_USER_ACTION]: 'Waiting user action',
        [SyncStatus.DOWNLOADING_PACKAGE]: 'Downloading package',
        [SyncStatus.INSTALLING_UPDATE]: 'Installing update',
    };

    const options = {
        installMode: ON_NEXT_RESUME,
        mandatoryInstallMode: ON_NEXT_RESUME,
        minimumBackgroundDuration: 0,
        deploymentKey: getDeploymentKey(),
    };

    const downloadProgress = ({ receivedBytes, totalBytes }) => {
        const byteValue = 100 / totalBytes;

        store.set((state) => {
            const rounded = Math.round(byteValue * receivedBytes);

            if (rounded === 100) {
                state.progress = '';
            } else {
                state.progress = `${rounded}/100%`;
            }
        });
    };

    const sync = throttle((status) => {
        store.set((state) => {
            state.status = status;
            state.statusLabel = syncStatusLabels[status];

            if (status === SyncStatus.DOWNLOADING_PACKAGE) {
                state.isPending = true;
                state.isUpdateInstalled = false;
                state.updateLabel = translate('Please wait... We are updating your application');
            } else if (status === SyncStatus.INSTALLING_UPDATE) {
                state.updateLabel = translate('Update in being installed...');
            } else if (status === SyncStatus.UPDATE_INSTALLED) {
                state.isPending = false;
                state.isUpdateInstalled = true;
                state.updateLabel = translate('Update installed! Click here to restart or re-open the app');
            }
        });
    }, 1000);

    codePush.sync(sync, options, downloadProgress);

    codePush.getCurrentPackage((update) => {
        if (!update) {
            return;
        }

        if (update) {
            store.set((state) => {
                state.version = update.label;
            });
        }
    });
}

export function getDeploymentKey() {
    const codePushIosStaging = 'NWkOAJGIyIM8yFQejZpGw0rFTfahSywYhXccz';
    const codePushIosProduction = 'nnCjXvS29uWPeLiv8Wzuppy6y-s5SyDY3Q5cz';
    const codePushAndroidStaging = '8bajbWQRb_GR5e_N6NEj2mxJQg7yHkBPX759f';
    const codePushAndroidProduction = 'MMVwwpCAFlKFk5OiTQp4KBK-34tIH1BwXXccf';

    if (isIos()) {
        return isCodePushDev() ? codePushIosStaging : codePushIosProduction;
    } else if (isAndroid()) {
        return isCodePushDev() ? codePushAndroidStaging : codePushAndroidProduction;
    } else {
        return undefined;
    }
}

export function switchToDev() {
    storageSet('codePushDev', '1');

    store.set((state) => {
        state.isDev = true;
    });

    sync();
}

export function switchToProd() {
    storageSet('codePushDev', '');

    store.set((state) => {
        state.isDev = false;
    });

    sync();
}

export function isCodePushDev() {
    return !!storageGet('codePushDev');
}

export function restartApp() {
    if (isNative()) {
        window.codePush.restartApplication();
    } else {
        window.location.reload();
    }
}
