import { css } from 'styled-components';

export const removeButtonStyles = css`
    background-color: transparent;
    border: 0;
    user-select: none;
    padding: 0;
    margin: 0;

    :focus,
    :hover {
        cursor: pointer;
        outline: none;
        text-decoration: none;
    }

    [role='button'],
    input[type='submit'],
    input[type='reset'],
    input[type='button'],
    button {
        -webkit-box-sizing: content-box;
        -moz-box-sizing: content-box;
        box-sizing: content-box;
    }

    input[type='submit'],
    input[type='reset'],
    input[type='button'],
    button {
        background: none;
        border: 0;
        color: inherit;
        font: inherit;
        line-height: normal;
        overflow: visible;
        padding: 0;
        -webkit-appearance: button;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
    }
    
    input::-moz-focus-inner,
    button::-moz-focus-inner {
        border: 0;
        padding: 0;
    }
`;
