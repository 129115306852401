import { useState } from 'react';
import { compareStrings } from '../../util/compare-strings';

export function useFilterList(models: any = []) {
    const [keyword, setKeyword] = useState('');

    return {
        uiInputProps: { value: keyword, onChangeValue: setKeyword, placeholder: 'Type to search', noBorder: true },
        items: models.filter((item) => !keyword || compareStrings(item.name, keyword)),
    };
}
