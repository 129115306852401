import React, { useEffect, useState } from 'react';
import { Redirect } from 'react-router-dom';
import { Connectivity } from '../components/Connectivity';
import { CssLoader } from '../components/CssLoader';
import { StaffRegisterDevice } from '../components/StaffRegisterDevice';
import { SwitchRedirect } from '../components/SwitchRedirect';
import { loadStaffBarById, loadStaffVenues } from '../microservices/legacy-app';
import { authenticate } from '../shared/aws-amplify';
import { routes } from '../shared/router';
import { stores } from '../stores';
import { getApiJwtToken } from '../stores/api-jwt-token';
import { setLocationTerminals } from '../stores/location-terminals';
import { useStaffActiveBar } from '../stores/staff-active-bar';
import { useStaffVenues } from '../stores/staff-venues';
import { useUser } from '../stores/user';
import { InventoryPage } from './inventory/InventoryPage';
import { SplashScreen } from './splash-screen/SplashScreen';
import StaffBar from './StaffBar';
import { StaffClients } from './StaffClients';
import { StaffOrderPage } from './staff-order/StaffOrderPage';
import StaffChooseBar from './StaffChooseBar';
import StaffChooseReportingVenue from './StaffChooseReportingVenue';
import { StaffQueue } from './StaffQueue';
import { StaffVenue } from './StaffVenue';

function WrapStaff({ match }) {
    const { barId } = match.params;

    const bar = useStaffActiveBar();

    useEffect(() => {
        loadStaffVenues();
    }, []);

    useEffect(() => {
        if (barId) {
            loadStaffBarById(barId);
        }
    }, [barId]);

    useEffect(() => {
        if (!bar) {
            return;
        }

        stores.staff.set((state) => {
            state.bar = bar;
            state.barId = barId;

            state.venue = bar.venue;
            state.venueId = bar.venue.id;

            state.location = bar.venue.location;
            state.locationId = bar.venue.location.id;
        });

        setLocationTerminals(barId, bar.bamboraTerminals);
    }, [bar]);

    return bar && bar.id === barId ? <StaffBar /> : <CssLoader />;
}

export default function Staff() {
    const venues = useStaffVenues();
    const { isStaff, rolesByLocationId } = useUser();
    const token = getApiJwtToken();
    const [isAuthenticated, setIsAuthenticated] = useState(true);

    useEffect(() => {
        refreshUser();
    }, []);

    useEffect(() => {
        loadStaffVenues();
    }, []);

    if (!isAuthenticated) {
        return <Redirect to={routes.auth} />;
    }

    if (!token || !isStaff || venues.length === 0) {
        return <SplashScreen />;
    }

    async function refreshUser() {
        try {
            await authenticate();
            setIsAuthenticated(true);
        } catch (error) {
            setIsAuthenticated(false);
        }
    }

    return (
        <div>
            <Connectivity />
            <StaffRegisterDevice />

            <SwitchRedirect
                routes={[
                    {
                        path: routes.staffBarChoose,
                        component: StaffChooseBar,
                    },
                    {
                        path: StaffOrderPage.route,
                        component: StaffOrderPage,
                    },
                    {
                        path: routes.staffInventory,
                        component: InventoryPage,
                    },
                    {
                        path: routes.staffInventoryId,
                        component: InventoryPage,
                    },
                    {
                        path: routes.staffVenueReportingChoose,
                        component: StaffChooseReportingVenue,
                    },
                    {
                        path: routes.staffVenue,
                        component: StaffVenue,
                    },
                    {
                        path: routes.staffQueue,
                        component: StaffQueue,
                    },
                    {
                        path: routes.staffClients,
                        component: StaffClients,
                    },
                    {
                        path: routes.staffBar,
                        component: WrapStaff,
                    },
                ]}
            />
        </div>
    );
}
