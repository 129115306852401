import { createStore } from '@orderx/store';
import { storageGet, storageSet } from '../../shared/storage';
import uniq from 'lodash/uniq';

const { useStore } = createStore(storageGet('orderIdsCached') || []);

export function useCustomerOrders() {
    const [orderIdsCached, setOrderIdsCached] = useStore();

    return {
        orderIdsCached,
        cacheOrderId(id) {
            const next = uniq([...orderIdsCached, id]);
            setOrderIdsCached(next);
            storageSet('orderIdsCached', next);
        },
    };
}
