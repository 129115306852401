import React, { useState, useEffect } from 'react';
import { PosPageHeader } from '../../../../components/pos/page/header/PosPageHeader';
import { UiLoader } from '../../../../components/ui/loader/UiLoader';
import './PosGroupIdPage.scss';
import { PosGroupPage } from '../PosGroupPage';
import { usePosBar } from '../../../../hooks/pos/bar';
import { PosGroupProducts } from '../../../../components/pos/group/products/PosGroupProducts';
import IconList from '@material-ui/icons/List';
import { UiIconDiscount } from '../../../../components/ui/icon/UiIconDiscount';
import { PosGroupFooter } from '../../../../components/pos/group/footer/PosGroupFooter';
import { PosGroupDiscount } from '../../../../components/pos/group/discount/PosGroupDiscount';
import { PosGroupProductActions } from '../../../../components/pos/group/product-actions/PosGroupProductActions';
import { PosGroupPaymentBalance } from '../../../../components/pos/group/payment-balance/PosGroupPaymentBalance';
import { PosGroupPaymentTerminal } from '../../../../components/pos/group/payment-terminal/PosGroupPaymentTerminal';
import { UiIconPosTerminal } from '../../../../components/ui/icon/UiIconPosTerminal';
import { usePosGroup } from '../../../../hooks/pos/group';
import { useApiQuery } from '@orderx/http';
import { PosGroupSplitBill } from '../../../../components/pos/group/split-bill/PosGroupSplitBill';
import { PosGroupOptions } from '../../../../components/pos/group/options/PosGroupOptions';
import IconMore from '@material-ui/icons/MoreVert';
import { UiButton } from '../../../../components/ui/button/UiButton';
import { UiIconBalance } from '../../../../components/ui/icon/UiIconBalance';
import { PosGroupPayments } from '../../../../components/pos/group/payments/PosGroupPayments';
import { UiIconPayment } from '../../../../components/ui/icon/UiIconPayment';
import { ToolbarTitle } from '../../../../@v2/components/toolbar/title/ToolbarTitle';
import { UiFlex } from '@orderx/ui';

export const VIEW = {
    OPTIONS: 'OPTIONS',
    DEFAULT: 'DEFAULT',
    DISCOUNT: 'DISCOUNT',
    TERMINAL: 'TERMINAL',
    PAY_WITH_BALANCE: 'PAY_WITH_BALANCE',
    PRODUCTS: 'PRODUCTS',
    PAYMENTS: 'PAYMENTS',
    SPLIT_BILL: 'SPLIT_BILL',
};

export function PosGroupIdPage({ match }) {
    const { barId } = usePosBar();
    const { groupId } = match.params;
    const [view, setView] = useState(VIEW.DEFAULT);
    const { group, loadGroup, reset } = usePosGroup();
    const { run } = useApiQuery((groupId) => loadGroup(groupId));

    useEffect(() => {
        if (groupId) {
            run(groupId);
            return () => reset();
        }
    }, [groupId]);

    if (groupId !== group?.id) {
        return <UiLoader fullWidth />;
    }

    const { name } = group;

    function toggleView(targetView) {
        setView(targetView === view ? VIEW.DEFAULT : targetView);
    }

    function getViewButton(targetView, icon) {
        return (
            <UiButton
                onClick={() => toggleView(targetView)}
                icon={icon}
                color={view === targetView ? 'primary' : 'inherit'}
            />
        );
    }

    return (
        <div className="PosGroupIdPage">
            <PosGroupFooter />

            <PosPageHeader>
                <ToolbarTitle title={name} buttonBackLink={PosGroupPage.route} buttonBackLinkParams={{ barId }} />

                <UiFlex>
                    {getViewButton(VIEW.PRODUCTS, <IconList />)}
                    {getViewButton(VIEW.DISCOUNT, <UiIconDiscount />)}
                    {getViewButton(VIEW.PAYMENTS, <UiIconPayment />)}
                    {getViewButton(VIEW.PAY_WITH_BALANCE, <UiIconBalance />)}
                    {getViewButton(VIEW.TERMINAL, <UiIconPosTerminal />)}
                    {getViewButton(VIEW.OPTIONS, <IconMore />)}
                </UiFlex>
            </PosPageHeader>

            {view === VIEW.OPTIONS && <PosGroupOptions onChangeView={setView} />}
            {view === VIEW.DEFAULT && <PosGroupProducts isGrouped />}
            {view === VIEW.PRODUCTS && <PosGroupProductActions />}
            {view === VIEW.PAYMENTS && <PosGroupPayments />}
            {view === VIEW.DISCOUNT && <PosGroupDiscount />}
            {view === VIEW.PAY_WITH_BALANCE && <PosGroupPaymentBalance />}
            {view === VIEW.TERMINAL && <PosGroupPaymentTerminal />}
            {view === VIEW.SPLIT_BILL && <PosGroupSplitBill />}
        </div>
    );
}

PosGroupIdPage.route = '/pos/:barId/group/:groupId';
