import DialogTitle from '@material-ui/core/DialogTitle';
import LinearProgress from '@material-ui/core/LinearProgress';
import makeStyles from '@material-ui/core/styles/makeStyles';
import clsx from 'clsx';
import React from 'react';

const useStyles = makeStyles({
    UiDialogTitle: (props) => ({
        textAlign: props.textAlign || 'center',
        textTransform: 'uppercase',
        letterSpacing: 1,
        fontWeight: 700,
        fontSize: 16,
        position: 'relative',
    }),
    linearProgress: {
        position: 'absolute',
        width: '100%',
        height: 2,
        bottom: 0,
        left: 0,
        animationDelay: '0.6s',
    },
});

export function UiDialogTitle({ children, isLoading = false, textAlign = 'center', ...props }) {
    const classes = useStyles({ textAlign });

    return (
        <DialogTitle className={props.className} classes={{ root: classes.UiDialogTitle }} disableTypography {...props}>
            {isLoading && <LinearProgress className={clsx(classes.linearProgress, 'animated fadeIn')} />}
            {children}
        </DialogTitle>
    );
}
