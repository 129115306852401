import React, { useEffect } from 'react';
import { withParams } from '../../../shared/router';
import { useBookXUser } from '../../../hooks/bookx/user';
import { UiTable } from '../../../components/ui/table/UiTable';
import { UiButton } from '../../../components/ui/button/UiButton';
import { UiText, UiFlex } from '@orderx/ui';
import { StaffQueueLocationPage } from '../../StaffQueueLocationPage';
import { Svg } from '../../../components/Svg';
import { UiPageHeader } from '../../../components/ui/UiPageHeader';
import { CustomerProfilePage } from '../../CustomerProfilePage';

export function SmartqChooseLocation() {
    const { user, load } = useBookXUser();

    useEffect(() => {
        load();
    }, []);

    return (
        <>
            <UiPageHeader spacingLeft={1}>
                <UiFlex spacing={1}>
                    <UiButton to={CustomerProfilePage.route} icon={<Svg icon="arrow-left" />} />
                    <h4>Choose location</h4>
                </UiFlex>
            </UiPageHeader>

            {user?.companies?.map((company) => (
                <UiTable regularFont key={company.id}>
                    <tbody>
                        <tr className="divider">
                            <td colSpan={2}>
                                <UiText weight={2} transform="uppercase" size={-1}>
                                    {company.name}
                                </UiText>
                            </td>
                        </tr>

                        {company.locations.map((location) => (
                            <tr key={location.id}>
                                <td>{location.name}</td>

                                <td align="right">
                                    <UiButton
                                        variant="filled"
                                        icon={<Svg icon="arrow-right" />}
                                        to={withParams(StaffQueueLocationPage.route, {
                                            locationId: location.id,
                                        })}
                                    />
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </UiTable>
            ))}
        </>
    );
}

SmartqChooseLocation.route = '/smartq/choose-location';
