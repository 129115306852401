import React, { useEffect } from 'react';
import { Subject } from 'rxjs';
import { useAwsIotSubscription } from '../providers/aws-iot';
import { useDevice } from './device';

const onNotification = new Subject(undefined);

export function useNotification(topic, callback) {
    const { isPwa } = useDevice();

    useEffect(() => {
        const subscription$ = onNotification.subscribe((notification) => {
            if (notification.type === topic) {
                callback(notification);
            }
        });

        return () => subscription$.unsubscribe();
    }, []);

    return isPwa ? (
        <>
            <PwaSubscription topic={topic} callback={callback} />
            <PwaLegacySubscription topic={topic} callback={callback} />
        </>
    ) : null;
}

function PwaSubscription({ topic, callback }) {
    useAwsIotSubscription(`pwa/${topic}`, callback);
    return null;
}

function PwaLegacySubscription({ topic, callback }) {
    useAwsIotSubscription(`PWA-${topic}`, callback);
    return null;
}

export function setPushNotification(notification) {
    onNotification.next(notification);
}
