import { Entity, PrimaryGeneratedColumn, CreateDateColumn, Index, UpdateDateColumn } from './typeorm';

@Entity()
export class BaseModel {
    @PrimaryGeneratedColumn()
    id: number;

    @CreateDateColumn({ select: false })
    @Index()
    createdAt: Date;

    @UpdateDateColumn({ select: false })
    updatedAt: Date;
}
