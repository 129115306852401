import makeStyles from '@material-ui/core/styles/makeStyles';
import React from 'react';
import { useStaffOrder } from '../../use/staff-order';
import { UiInput } from '../ui/UiInput';

const useStyles = makeStyles({
    StaffOrderViewComment: {
        padding: 16,
    },
});

export function StaffOrderViewComment() {
    const classes = useStyles();
    const { order, setComment } = useStaffOrder();

    return (
        <div className={classes.StaffOrderViewComment}>
            <UiInput
                label={'Comment'}
                placeholder={'Add comment to order'}
                value={order.comment}
                onChangeValue={setComment}
                multiline
                rows={12}
            />
        </div>
    );
}
