import clsx from 'clsx';
import React, { useState, Fragment } from 'react';
import { UiGrid, UiButton, UiIconMaterial } from '@orderx/ui';
import { PosGroupMoveProducts } from '../move-products/PosGroupMoveProducts';
import { Redirect } from 'react-router-dom';
import { withParams } from '../../../../shared/router';
import { PosGroupPage } from '../../../../pages/pos/group/PosGroupPage';
import { usePosBar } from '../../../../hooks/pos/bar';
import { VIEW } from '../../../../pages/pos/group/id/PosGroupIdPage';
import { UiIconPosTerminal } from '../../../ui/icon/UiIconPosTerminal';
import { UiIconDiscount } from '../../../ui/icon/UiIconDiscount';
import { BetaFeature } from '../../../beta-feature/BetaFeature';

export function PosGroupOptions({ onChangeView }) {
    const { barId } = usePosBar();
    const [isSuccess, setIsSuccess] = useState(false);

    const buttons = [
        {
            view: VIEW.SPLIT_BILL,
            label: 'Split bill',
            icon: <UiIconMaterial>call_split</UiIconMaterial>,
            isBeta: true,
        },
        {
            view: VIEW.TERMINAL,
            label: 'Pay with terminal',
            icon: <UiIconPosTerminal />,
        },
        {
            view: VIEW.PAY_WITH_BALANCE,
            label: 'Pay with balance',
            icon: <UiIconMaterial>redeem</UiIconMaterial>,
        },
        {
            view: VIEW.DISCOUNT,
            label: 'Add discount',
            icon: <UiIconDiscount />,
        },
        {
            view: VIEW.PRODUCTS,
            label: 'Other',
            icon: <UiIconMaterial>list</UiIconMaterial>,
        },
    ];

    if (isSuccess) {
        return <Redirect to={withParams(PosGroupPage.route, { barId })} />;
    }

    return (
        <div className={clsx('PosGroupOptions')}>
            <UiGrid minMax={140} outerSpacing={2}>
                <PosGroupMoveProducts onSuccess={() => setIsSuccess(true)} />

                {buttons.map(({ view, label, icon, isBeta }) => (
                    <Fragment key={view}>
                        {isBeta ? (
                            <BetaFeature>
                                <UiButton onClick={() => onChangeView(view)} icon={icon} variant="filled" size={1}>
                                    {label}
                                </UiButton>
                            </BetaFeature>
                        ) : (
                            <UiButton onClick={() => onChangeView(view)} icon={icon} variant="filled" size={1}>
                                {label}
                            </UiButton>
                        )}
                    </Fragment>
                ))}
            </UiGrid>
        </div>
    );
}
