import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import makeStyles from '@material-ui/core/styles/makeStyles';
import React, { useState } from 'react';
import { UiButton, UiFlex } from '@orderx/ui';

const useStyles = makeStyles({
    UiButtonConfirmDialog: {},
    paper: {
        maxWidth: 375,
    },
});

export function UiButtonConfirmDialog({
    buttonText,
    dialogContent,
    onConfirm,
    buttonProps,
    dialogTitle,
    confirmButtonText,
    cancelButtonText,
    onCancel,
}) {
    const classes = useStyles();
    const [open, setOpen] = useState(false);

    function cancel() {
        setOpen(false);

        if (onCancel) {
            onCancel();
        }
    }

    async function confirm() {
        try {
            await onConfirm();
        } catch (error) {
            console.error(error);
        }

        setOpen(false);
    }

    return (
        <>
            <UiButton color={'primary'} {...buttonProps} onClick={() => setOpen(true)}>
                {buttonText}
            </UiButton>

            <Dialog open={open} PaperProps={{ className: classes.paper }}>
                {dialogTitle && <DialogTitle>{dialogTitle}</DialogTitle>}
                <DialogContent>{dialogContent}</DialogContent>
                <DialogActions>
                    <UiFlex flexChildren spacing={1}>
                        <UiButton onClick={cancel}>{cancelButtonText || 'Cancel'}</UiButton>

                        <UiButton onClick={confirm} color={'primary'}>
                            {confirmButtonText || 'Confirm'}
                        </UiButton>
                    </UiFlex>
                </DialogActions>
            </Dialog>
        </>
    );
}
