import React from 'react';
import styled from 'styled-components';
import { themeActive } from '../themes/dark';
import { PortalBottom } from './PortalBottom';

const Wrapper = styled.div`
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    height: ${themeActive.tabs.height}px;
    background-color: ${themeActive.tabs.backgroundColor};

    display: flex;
`;

const Placeholder = styled.div`
    height: 50px;
`;

export function BottomTabs({ children }) {
    return (
        <>
            <Placeholder />

            <PortalBottom>
                <Wrapper>{children}</Wrapper>
            </PortalBottom>
        </>
    );
}
