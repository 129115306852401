import React, { useEffect } from 'react';
import { useDevice } from '../shared/device';
import { storageGet } from '../shared/storage';
import { stores, useStore } from '../stores';
import { NfcListener } from './NfcListener';
import styled from 'styled-components';
import { UiButton } from '../@deprecated/UiButton';
import IconNfc from '@material-ui/icons/ContactlessRounded';

const Wrapper = styled.div`
    position: fixed;
    bottom: 8rem;
    right: 1rem;
    height: 64px;
    transform: translateZ(0);
`;

export function NfcButton({ onTagRead }) {
    const { isAndroid } = useDevice();
    const [nfc, setNfc] = useStore(stores.nfc);

    useEffect(() => {
        return () => {
            setNfc(state => {
                state.scanOpen = false;
            });
        };
    }, []);

    if (isAndroid || nfc.scanOpen) {
        return (
            <Wrapper>
                <NfcListener onTagRead={onTagRead} />
            </Wrapper>
        );
    }

    return (
        <Wrapper>
            {!storageGet('hideNfc') && (
                <UiButton
                    onClick={() =>
                        setNfc(state => {
                            state.scanOpen = true;
                        })
                    }
                    icon={<IconNfc />}
                />
            )}
        </Wrapper>
    );
}
