import Dialog from '@material-ui/core/Dialog';
import IconButton from '@material-ui/core/IconButton';
import makeStyles from '@material-ui/core/styles/makeStyles';
import ReceiptRounded from '@material-ui/icons/ReceiptRounded';
import React, { useState } from 'react';

const useStyles = makeStyles({
    paper: {
        height: '100%',
    },
    iconButton: {
        padding: 8,
    },
});

export function UiDialogToggle({ children, icon, toggleButton, fullScreen = true }) {
    const [open, setOpen] = useState(false);
    const classes = useStyles();

    const toggle = (
        <IconButton onClick={() => setOpen(true)} className={classes.iconButton}>
            {icon || <ReceiptRounded />}
        </IconButton>
    );

    return (
        <>
            {toggleButton ? React.cloneElement(toggleButton, { onClick: () => setOpen(true) }) : toggle}

            <Dialog
                open={open}
                onClose={() => setOpen(false)}
                classes={{ paper: classes.paper }}
                fullScreen={fullScreen}>
                {React.cloneElement(children, { setOpen, close: () => setOpen(false) })}
            </Dialog>
        </>
    );
}
