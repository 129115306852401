import React, { useEffect, useState } from 'react';
import { CreateOrder } from '../../modules/create-order';
import { useCreateOrderBar } from '../../stores/create-order-bar';
import { useCreateOrderQuery } from '../../stores/create-order-query';
import { useCheckboxes } from '../../use/checkbox';
import { StaffCreateActiveOrderDiscount } from './StaffCreateActiveOrderDiscount';
import { StaffCreateActiveOrderPayWithBalance } from './StaffCreateActiveOrderPayWithBalance';
import { UiGrid, UiBox, UiCard, UiButton, UiFlex } from '@orderx/ui';
import { UiTable } from '../ui/table/UiTable';
import { StaffCreateActiveOrderProductRow } from './StaffCreateActiveOrderProductRow';
import { UiTableCellCheckbox } from '../ui/table/cell-checkbox/UiTableCellCheckbox';

export const TAB = {
    DISCOUNT: 0,
    BALANCE: 1,
};

export function StaffCreateActiveOrder() {
    const { placeOrder } = useCreateOrderQuery();
    const { orderProducts: products } = CreateOrder.useState();
    const { category } = useCreateOrderBar();
    const [activeTab, setActiveTab] = useState();

    const { isAllChecked, isChecked, toggleChecked, toggleCheckedAll, checkedModels, reset } = useCheckboxes({
        idMapper: (model) => model.id,
        models: products,
    });

    const checkedModelsLength = checkedModels.length;

    useEffect(() => {
        if (checkedModelsLength === 0) {
            setActiveTab(undefined);
        }
    }, [checkedModelsLength]);

    const getTabButton = (tab, label) => (
        <UiButton
            onClick={() => setActiveTab(activeTab === tab ? undefined : tab)}
            color={activeTab === tab ? 'secondary' : 'default'}>
            {label}
        </UiButton>
    );

    if (category !== '_ACTIVE' || products.length === 0) {
        return null;
    }

    const payWithBalance = (balance) => {
        placeOrder({ balance });
    };

    const hasCheckedProducts = checkedModelsLength > 0;

    return (
        <UiBox padding={2}>
            <UiCard
                footer={
                    <UiFlex spacing={1} padding={1}>
                        <StaffCreateActiveOrderPayWithBalance products={products} onPayWithBalance={payWithBalance} />
                        {hasCheckedProducts && getTabButton(TAB.DISCOUNT, 'Apply discount')}
                    </UiFlex>
                }>
                <UiGrid minMax={activeTab === TAB.DISCOUNT && 375 - 32}>
                    <UiTable>
                        <thead>
                            <tr>
                                <UiTableCellCheckbox checked={isAllChecked} onChange={toggleCheckedAll} />
                                <td>Toggle all</td>
                            </tr>
                        </thead>

                        <tbody>
                            {products.map((product) => (
                                <StaffCreateActiveOrderProductRow
                                    product={product}
                                    checked={isChecked(product.id)}
                                    setChecked={() => toggleChecked(product.id)}
                                    key={product.id}
                                />
                            ))}
                        </tbody>
                    </UiTable>
                    {activeTab === TAB.DISCOUNT && (
                        <UiBox padding={2}>
                            <StaffCreateActiveOrderDiscount products={checkedModels} onSuccess={reset} />
                        </UiBox>
                    )}
                </UiGrid>
            </UiCard>
        </UiBox>
    );
}
