import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import makeStyles from '@material-ui/core/styles/makeStyles';
import React from 'react';
import { translate } from '../../shared/translate';
import { useStaffOrder } from '../../use/staff-order';
import { CurrencyMinor } from '../CurrencyMinor';
import { UiDialogContent } from '../ui/dialog/UiDialogContent';
import { UiDialogTitle } from '../ui/dialog/UiDialogTitle';
import { UiButtonBottom } from '../ui/UiButtonBottom';
import { UiFlex, UiButton } from '@orderx/ui';

const useStyles = makeStyles({
    dialogContainer: {
        alignItems: 'flex-end',
    },
    dialogActions: {
        width: '100%',
    },
    dialogContent: {
        padding: 16,
        display: 'grid',
        gridGap: 16,
        gridTemplateColumns: 'repeat(auto-fill, minmax(60px, 1fr))',
        maxHeight: '50vh',
    },
});

export function StaffOrderPlaceDialog({ groups, isGroupsDialogOpen, setIsGroupsDialogOpen, hidePlaceOrderButton }) {
    const classes = useStyles();
    const { totalProductsAdded, getTotalAmount, currency, order, setOrderToPlace } = useStaffOrder();
    const orderCost = getTotalAmount();

    function placeOrder(groupId) {
        setOrderToPlace({
            ...order,
            groupId,
        });
    }

    function handleClickPlaceOrder() {
        if (groups.length === 1) {
            placeOrder(groups[0].id);
        } else if (groups.length > 1) {
            setIsGroupsDialogOpen(true);
        } else {
        }
    }

    function close() {
        setIsGroupsDialogOpen(false);
    }

    return (
        <>
            {totalProductsAdded > 0 && !hidePlaceOrderButton && (
                <UiButtonBottom position={'aboveTabs'} onClick={handleClickPlaceOrder}>
                    {translate('Place order of')} <CurrencyMinor amount={orderCost} currency={currency} />
                </UiButtonBottom>
            )}

            <Dialog open={isGroupsDialogOpen} onClose={close} classes={{ container: classes.dialogContainer }}>
                <UiDialogTitle>Choose group</UiDialogTitle>

                <UiDialogContent className={classes.dialogContent}>
                    {groups.map((group) => (
                        <UiButton
                            onClick={() => placeOrder(group.id)}
                            color={'default'}
                            variant={'contained'}
                            key={group.id}>
                            {group.name}
                        </UiButton>
                    ))}
                </UiDialogContent>

                <DialogActions>
                    <UiFlex flexChildren className={classes.dialogActions}>
                        <UiButton onClick={close} fullWidth>
                            Cancel
                        </UiButton>
                    </UiFlex>
                </DialogActions>
            </Dialog>
        </>
    );
}
